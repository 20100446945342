/* eslint-disable jsx-a11y/alt-text */
//import lib react
import './detail_peraturan.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import GridList from 'react-gridlist';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Button } from 'reactstrap';

//component
import { LogoKarantinaIkan, ImgImport, ImgEcommerce, ImgLogistik, ICIntrExporImpor } from '../../../../assets';
import * as Api from '../../../../constants/api';
import { ListSetting, LoadProgress, ListEmpty } from '../../../../components';
import { InputSearch } from '../../../../components/atoms';

const DetailPeraturan = () => {
    //variable constant
    const { t } = useTranslation();
    const location = useLocation();
    // const data_peraturan = location.state.data_peraturan;

    const [data_peraturan, setDataPeraturan] = useState({});
    const [status_berlaku, setStatusBerlaku] = useState('');

    //did mount
    useEffect(() => {
        let date_now = new Date();
        let date_expired = new Date(location.state.data_peraturan.berakhir_tanggal);
        // console.log(date_now);
        // console.log(date_expired);
        // console.log(moment(date_now).format('DD MMMM YYYY'));
        // console.log(moment(date_expired).format('DD MMMM YYYY'));
        if (date_now > date_expired) {
            setStatusBerlaku(t('tidak_berlaku'));
        } else {
            setStatusBerlaku(t('berlaku'));
        }
        setDataPeraturan(location.state.data_peraturan);
    }, []);

    //function
    const open_page = () => {
        var url_file = Api.IP_KONG_LNSW + data_peraturan.file.slice(1);
        const win = window.open(url_file, '_blank');
        if (win != null) {
            win.focus();
        }
    }

    return (
        <div className="detail-peraturan">
            <div className="detail-peraturan-text-title">{t('detail_regulations')}</div>
            <Button onClick={() => open_page()} className="detail-peraturan-button-download" size="mid">{t('download_regulations')}</Button>
            <div className="detail-peraturan-border">
                <div className="detail-peraturan-border-item">
                    <div className="detail-peraturan-border-item-title">{t('regulatory_field')}</div>
                    <div className="detail-peraturan-border-item-desc">{data_peraturan.bidang_peraturan}</div>
                </div>
                <div className="detail-peraturan-border-item">
                    <div className="detail-peraturan-border-item-title">{t('agency')}</div>
                    <div className="detail-peraturan-border-item-desc">{data_peraturan.instansi}</div>
                </div>
                <div className="detail-peraturan-border-item">
                    <div className="detail-peraturan-border-item-title">{t('title_regulation')}</div>
                    <div className="detail-peraturan-border-item-desc">{data_peraturan.judul_peraturan}</div>
                </div>
                <div className="detail-peraturan-border-item">
                    <div className="detail-peraturan-border-item-title">{t('regulatory_number')}</div>
                    <div className="detail-peraturan-border-item-desc">{data_peraturan.nomor_peraturan}</div>
                </div>
                <div className="detail-peraturan-border-item">
                    <div className="detail-peraturan-border-item-title">{t('determination_date')}</div>
                    <div className="detail-peraturan-border-item-desc">{moment(data_peraturan.tanggal_peraturan).format('DD MMMM YYYY')}</div>
                </div>
                <div className="detail-peraturan-border-item">
                    <div className="detail-peraturan-border-item-title">{t('kind_regulation')}</div>
                    <div className="detail-peraturan-border-item-desc">{data_peraturan.jenis_peraturan}</div>
                </div>
                <div className="detail-peraturan-border-item">
                    <div className="detail-peraturan-border-item-title">{t('effective_date')}</div>
                    <div className="detail-peraturan-border-item-desc">{data_peraturan.mulai_tanggal !== "" ? moment(data_peraturan.mulai_tanggal).format('DD MMMM YYYY') : "-"}</div>
                </div>
                <div className="detail-peraturan-border-item">
                    <div className="detail-peraturan-border-item-title">{t('end_date')}</div>
                    <div className="detail-peraturan-border-item-desc">{data_peraturan.berakhir_tanggal !== "" && data_peraturan.flag_seterusnya === false ? moment(data_peraturan.berakhir_tanggal).format('DD MMMM YYYY') : "-"}</div>
                </div>
                <div className="detail-peraturan-border-item">
                    <div className="detail-peraturan-border-item-title">Status</div>
                    <div className="detail-peraturan-border-item-desc">{status_berlaku}</div>
                </div>
                <div style={{ borderBottom: 'none' }} className="detail-peraturan-border-item">
                    <div className="detail-peraturan-border-item-title">{t('description')}</div>
                    <div className="detail-peraturan-border-item-desc">{data_peraturan.keterangan}</div>
                </div>
            </div>
        </div>
    )
}

export default DetailPeraturan;
