import React, { useState, useEffect, useRef } from 'react'
import './header.scss'
import {
   ICTelp,
   ICBurger,
   LogoOnlyINSW,
   LogoOnlyKemenkeu,
   ICArrowDownBlue,
   ICArrowRightBlue,
} from '../../../assets'
import { Link } from 'react-router-dom'
import BreadCrumbs from '../BreadCrumbs'
import axios from 'axios'
import * as Api from '../../../constants/api'
import publicip from 'public-ip'

const Header = () => {
   //variable constant
   const [header_data, setHeaderData] = useState([])
   const [is_menu, setMenu] = useState('nav-menu-check')
   const show_menu = useRef('nav-menu-check')
   const latitude = useRef('')
   const longitude = useRef('')
   const ip_local = useRef('')
   const location_menu = useRef('')
   const width = window.innerWidth

   //did mount
   useEffect(() => {
      console.log('header_data', header_data)
      if (localStorage.getItem('language') === null) {
         localStorage.setItem('language', 'id')
      }
      _get_data_menu()
      getCurrentAccess()
      setTimeout(() => {
         _post_local_ip()
         // console.log('ip_laptop', ip_local.current);
      }, 1000)
   }, [])

   const getCurrentAccess = () => {
      var lat = []
      var long = []
      var location_var = window.location.pathname.replace('/', '')
      if (location_var === '') {
         localStorage.setItem('location', 'Beranda')
      } else {
         localStorage.setItem('location', location_var)
      }

      navigator.geolocation.getCurrentPosition(function (position) {
         localStorage.setItem('lat', position.coords.latitude)
         localStorage.setItem('long', position.coords.longitude)
      })
      latitude.current = lat
      longitude.current = long

      // const localIpUrl = require('local-ip-url');
      // ip_local.current = localIpUrl('public', 'ipv4');

      publicip
         .v4()
         .then((result) => {
            // console.log('result data ip');
            // console.log(result);
            ip_local.current = result
            // console.log(ip_local.current)
         })
         .catch((error) => {
            // console.log('result language');
            console.log(error)
            // language.setI18nConfig('');
         })
   }

   // function
   const changeLanguage = (lng) => {
      localStorage.setItem('language', lng)
      window.location.reload()
   }

   const click_navbar = () => {
      // console.log("show_menu.current");
      // console.log(show_menu.current);
      if (show_menu.current === 'nav-menu-check') {
         setMenu('nav-menu-hide')
         show_menu.current = 'nav-menu-hide'
      } else if (show_menu.current === 'nav-menu-show') {
         setMenu('nav-menu-hide')
         show_menu.current = 'nav-menu-hide'
      } else {
         setMenu('nav-menu-show')
         show_menu.current = 'nav-menu-show'
      }
   }

   const _get_data_menu = () => {
      const url =
         Api.GET_HEADER_MENU +
         '?lang=' +
         localStorage.getItem('language').toUpperCase()
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            // console.log(response.data.data);
            if (response.data.code === '01') {
               setHeaderData(response.data.data)
            } else {
               setHeaderData([])
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setHeaderData([])
         })
   }

   const _post_local_ip = () => {
      let datas_submit = {
         menu: localStorage.getItem('location'),
         ip: ip_local.current,
         lat: localStorage.getItem('lat'),
         lon: localStorage.getItem('long'),
      }
      // console.log('data_submit', datas_submit);
      const url = Api.IP_LOCAL
      // console.log(url);
      axios
         .post(
            url,
            datas_submit,
            {
               headers: {
                  'Content-Type': 'application/json',
                  Authorization: Api.BASIC_AUTH,
               },
            },
            {
               mode: 'no-corse',
            },
         )
         .then((response) => {
            console.log('ip', response.data)
         })
         .catch((error) => {
            console.log('ip', error)
         })
   }

   const open_new_tab = (item) => {
      window.open(item, '_blank') //to open new page
      getCurrentAccess()
      setTimeout(() => {
         _post_local_ip()
         // console.log('ip_laptop', ip_local.current);
      }, 1000)
   }

   const set_menu = (id_menu) => {
      // console.log('setmenu');
      // console.log(id_menu);
      localStorage.setItem('menu_select', id_menu)
      getCurrentAccess()
      setTimeout(() => {
         _post_local_ip()
         // console.log('ip_laptop', ip_local.current);
      }, 1000)

      // Hide navbar setelah klik menu (Tampilan mobile)
      if (width < 600) {
         setMenu('nav-menu-hide')
      }
   }

   //

   return (
      <div>
         <div className="header-atas">
            <img className="logo" src={ICTelp} alt="Icon Telp" />
            <p className="menu-telp">
               150-679 |{' '}
               <a
                  className="view-sitemap"
                  href="/contact-center"
                  target="_blank"
               >
                  Contact Center
               </a>
            </p>
            <p onClick={() => changeLanguage('id')} className="menu-translete">ID</p>
            <p className="menu-telp"> | </p>
            <p onClick={() => changeLanguage('en')} className="menu-translete">EN</p>
            <a
               className="menu-login"
               href="https://home.insw.go.id"
               target="_blank"
            >
               LOGIN
            </a>
         </div>
         <div className="nav-container">
            <nav className="navbar nav-important">
               <div className="d-flex navbar-logo">
                  <img
                     className="logo"
                     id="navbar-logo"
                     src={LogoOnlyKemenkeu}
                     alt="Logo Kemenkeu"
                     onClick={() => {
                        window.open('https://www.kemenkeu.go.id', '_blank')
                     }}
                  />

                  <div className="logo-divider"></div>
                  <div className="d-flex w-100 justify-content-between">
                     <img
                        className="logo"
                        id="navbar-logo"
                        src={LogoOnlyINSW}
                        alt="Logo Kemenkeu"
                        onClick={() => {
                           window.open('https://insw.go.id', '_blank')
                        }}
                     />
                     <div className="menu-toggle px-3">
                        <img
                           style={{ marginLeft: '0.5vw' }}
                           src={ICBurger}
                           onClick={() => click_navbar()}
                        />
                     </div>
                  </div>
               </div>
               <div></div>
               <div className={is_menu}>
                  <ul className="nav-menu">
                     {header_data.map((item, index) =>
                        item.child.length === 0 ? (
                           item.menu === 'NLE' ? (
                              <li
                                 style={{ cursor: 'pointer' }}
                                 className="li-menu"
                              >
                                 <div
                                    onClick={() => open_new_tab(item.url)}
                                    className="nav-links"
                                 >
                                    {item.menu}
                                 </div>
                              </li>
                           ) : item.url ===
                              'https://nle.insw.co.id' ? null : ( // Ilangin button login
                              // <li style={{ cursor: 'pointer' }} className="li-menu"><div onClick={() => open_new_tab(item.url)} className="nav-links nav-links-btn">{item.menu}</div></li>
                              <li className="li-menu">
                                 <Link
                                    onClick={() => set_menu(item.id_menu)}
                                    to={item.url}
                                    className="nav-links"
                                 >
                                    {item.menu}
                                 </Link>
                              </li>
                           )
                        ) : (
                           <div className="dropdown">
                              <li className="li-menu dropdown">
                                 <Link to="#" className="nav-links">
                                    {item.menu}
                                 </Link>{' '}
                                 <img
                                    style={{ marginLeft: '0.5vw' }}
                                    src={ICArrowDownBlue}
                                 />
                              </li>
                              <div class="dropdown-content" style={{ zIndex: 1000 }}>
                                 {item.child.map((item_child) =>
                                    item_child.child.length === 0 ? (
                                       <li className="li-menu" >
                                          <Link
                                             onClick={() =>
                                                set_menu(item_child.id_menu)
                                             }
                                             to={item_child.url}
                                             className="nav-links"
                                          >
                                             {item_child.menu}
                                          </Link>
                                       </li>
                                    ) : (
                                       <div
                                          className="dropdown-content-header"
                                          style={{ display: 'flex' }}
                                       >
                                          <li className="li-menu dropdown-content-header li-dropdown-content-header">
                                             <div style={{ display: 'flex' }}>
                                                <Link
                                                   style={{ flex: 1 }}
                                                   to="#"
                                                   className="nav-links"
                                                >
                                                   {item_child.menu}
                                                </Link>
                                                <img
                                                   style={{
                                                      marginRight: '0.5vw',
                                                   }}
                                                   src={ICArrowRightBlue}
                                                />
                                             </div>
                                          </li>
                                          <div class="dropdown-content-child">
                                             {item_child.child.map(
                                                (item_child_child) => (
                                                   <li className="li-menu">
                                                      <Link
                                                         onClick={() =>
                                                            set_menu(
                                                               item_child_child.id_menu,
                                                            )
                                                         }
                                                         to={
                                                            item_child_child.url
                                                         }
                                                         className="nav-links"
                                                      >
                                                         {item_child_child.menu}
                                                      </Link>
                                                   </li>
                                                ),
                                             )}
                                          </div>
                                       </div>
                                    ),
                                 )}
                              </div>
                           </div>
                        ),
                     )}
                     {/* <li className="li-menu">
                        <Link to="/login" className="nav-links nav-links-btn">
                           Login
                        </Link>
                     </li>{' '} */}
                  </ul>
               </div>
            </nav>
         </div>
         <BreadCrumbs />
      </div>
   )
}

export default Header
