/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import './foto_document.scss';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import parse from 'html-react-parser';
import GridList from 'react-gridlist';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Ripples from 'react-ripples';

//component
import * as Api from '../../../constants/api';
import { LoadProgress, ListEmpty } from '../../../components';
import { ICCalendarGrayNews } from '../../../assets';
import { useHistory } from 'react-router-dom';

const FotoDocument = () => {

  // variable constant
  const { t } = useTranslation();
  const history = useHistory();
  const [is_progress, setisProgress] = useState(true);
  const [list_foto, setListFoto] = useState([]);
  const [list_foto_slice, setListFotoSlice] = useState([]);
  const [cover_foto, setCoverFoto] = useState({});
  const [error_message, setErrorMessage] = useState('');

  const id_foto = useRef('');

  const perPage = 8;
  const [pageCount, setPageCount] = useState('');

  //style
  let styles = {
    container: {
      flex: 1,
    },
  }

  // did mount
  useEffect(() => {
    setTimeout(() => {
      _get_data_foto();
    }, 500);
  }, []);

  //function
  const _get_data_foto = () => {
    setisProgress(true);
    const url = Api.GET_DATA_FOTO + '?lang=' + localStorage.getItem('language').toUpperCase();
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        setisProgress(false);
        if (response.data.code === '01') {
          if (response.data.data.length === 0) {
            setListFoto([]);
            setListFotoSlice([]);
            setCoverFoto({});
            setErrorMessage(t('empty_list'));
          } else {
            let list_foto_data = response.data.data;
            if (list_foto_data.length === 1) {
              setCoverFoto(list_foto_data[0]);
              setListFoto(list_foto_data);
              const slice = list_foto_data.slice(0, perPage);
              setPageCount(list_foto_data.length / perPage);
              setListFotoSlice(slice);
            } else {
              setCoverFoto(list_foto_data[0]);
              list_foto_data = list_foto_data.slice(1)
              setListFoto(list_foto_data);
              const slice = list_foto_data.slice(0, perPage);
              setPageCount(list_foto_data.length / perPage);
              setListFotoSlice(slice);
            }
          }
        } else {
          setListFoto([]);
          setListFotoSlice([]);
          setCoverFoto({});
          setErrorMessage(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgress(false);
        setListFoto([]);
        setListFotoSlice([]);
        setCoverFoto({});
        setErrorMessage(t('error_message'));
      });
  }

  const CallImage = (image_url) => {
    // console.log('url_image');
    // console.log(image_url);
    var url_image = '';
    if (image_url !== undefined) {
      url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    }
    // console.log('url_image');
    // console.log(url_image);
    return url_image;
  }

  const parseContent = (text) => {
    var text_html = '';
    if (text !== undefined) {
      text_html = parse(text)
    }
    // console.log('url_image');
    // console.log(text_html);
    return text_html;
  }

  const getGridGap = (elementWidth, windowHeight) => (elementWidth > 720 && windowHeight > 480) ? 10 : 5;

  const getColumnCount = (elementWidth) => Math.floor(elementWidth / 300); //pembagian column (dibagi untuk 4 column)

  const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.1);

  const getItemData = (list_peraturan, columnWidth) => {
    return {
      key: list_peraturan.id,
    }
  }
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    loadMoreData(offset, offset + perPage);
  };

  const loadMoreData = (of, op) => {
    const data = list_foto;

    const slice = data.slice(of, op);
    setPageCount(data.length / perPage);
    setListFotoSlice(slice);
  };

  const _click_detail = (item) => {
    // console.log(item);
    history.push('/foto/detail-foto', { id_foto :  item.id_photo});
  }

  return (
    <div className="foto-document-content row m-0">
      <div className="title-foto col-md-12 p-0 mb-5">{t('document_picture')}</div>
      <div className="col-md-12 p-0">
        {is_progress === true ?
          <div className="search-content-load col-md-12 p-0">
            <LoadProgress />
          </div>
          :
          list_foto.length === 0 ?
            <div className="search-content-load col-md-12 p-0">
              <ListEmpty message_error={error_message} />
            </div>
            :
            <div className="cover-foto row m-0">
              <div className="cover-foto-desc col-md-6 col-sm-12 p-0">
                <div className="cover-foto-desc-title" onClick={() => _click_detail(cover_foto)}>
                  {cover_foto.header}
                </div>
                <div className="cover-foto-desc-date">
                  <img src={ICCalendarGrayNews} style={{ marginRight: '0.7vw' }} />
                  {/* {moment(cover_foto.waktu_publish).format('DD MMMM YYYY') + ' , ' + t('by') + ' ' + cover_artikel.penulis} */}
                  {moment(cover_foto.waktu_publish).format('DD MMMM YYYY')}
                </div>
                {
                  cover_foto.berita < 600 ?
                    (
                      <div className="cover-foto-desc-desc">
                        {parseContent(cover_foto.berita.substring(0, 500))}
                      </div>
                    ) : (
                      <div className="cover-foto-desc-desc">
                        {parseContent(`${cover_foto.berita.substring(0, 500)}...`)}
                      </div>
                    )
                }
              </div>
              <div className="cover-foto-image col-md-6 col-sm-12 p-0">
                <img className="image-foto" src={CallImage(cover_foto.photo_detail)} />
              </div>
            </div>
        }
      </div>
      <div className="foto-other col-md-12 p-0">
        <div className="foto-other-title col-md-12 p-0">
          {t('other_photos')}
        </div>
        <div className="col-md-12 mt-3 p-0">
          {is_progress === true ?
            <div className="search-content-load col-md-12 p-0">
              <LoadProgress />
            </div>
            :
            list_foto_slice.length === 0 ?
              <div className="search-content-load col-md-12 p-0">
                <ListEmpty message_error={error_message} />
              </div>
              :
              <div className="row m-0">
                <div style={styles.container} className="col-md-12 p-0">
                  <GridList
                    items={list_foto_slice}
                    getGridGap={getGridGap}
                    getColumnCount={getColumnCount}
                    getWindowMargin={getWindowMargin}
                    getItemData={getItemData}
                    renderItem={(item) => {
                      return (
                        <Ripples
                          onClick={() => _click_detail(item)}
                        >
                          <div className="foto-other-item-grid">
                            <img className="foto-other-item-grid-image" src={CallImage(item.photo_detail)} />
                            <div className="foto-other-item-grid-title max-line-three">
                              {item.header}
                            </div>
                            <div className="foto-other-item-grid-desc max-line-four">
                              {parseContent(item.berita)}
                            </div>
                          </div>
                        </Ripples>
                      )
                    }}
                  />

                  <div className="foto-other-content-list">
                    <ReactPaginate
                      previousLabel={t('previous')}
                      nextLabel={t('next')}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick.bind(this)}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                      className="col-md-12 p-0"
                    />
                  </div>

                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default FotoDocument;
