import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../../constants/api';
import './index.scss';
import parse from 'html-react-parser';
// import { useTranslation } from 'react-i18next';
import { Col, Container, Image, Row } from 'react-bootstrap';

const ProsedurPermohonan = () => {
    // const { t } = useTranslation();
    const [dataInfoPublik, setDataInfoPublik] = useState([]);
    // const [data, setData] = useState([]);
    // const [dataForm, setDataForm] = useState([]);

    const getProsedurInformasiPublik = () => {
        const url = Api.PROSEDUR_INFORMASI_PUBLIK + '?lang='+ localStorage.getItem('language').toUpperCase();
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
        .then(response => setDataInfoPublik(response.data.data))
        .catch(error => console.log(error));
    }

    // const klik_data = item => {
    //     var url_image = Api.IP_KONG_LNSW + item.slice(1);
    //     const win = window.open(url_image, '_blank');
    //     if (win != null) {
    //         win.focus();
    //     }
    // }

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    // const _get_data_form = () => {
    //     const url = Api.FORM_PROSEDUR + '?lang=' + localStorage.getItem('language').toUpperCase();
    //     axios({
    //         method: 'get',
    //         url: url,
    //         headers: { 'Authorization': Api.BASIC_AUTH },
    //         timeout: 1000 * 10, // Wait for 10 seconds
    //     })
    //         .then(response => {
    //             if (response.data.code === '01') {
    //                 setDataForm(response.data.data);
    //             } else {
    //                 setDataForm([]);
    //             }
    //         })
    //         .catch(error => {
    //             console.log('error');
    //             console.log(error);
    //             setDataForm([]);
    //         });
    // }

    // const _get_data_data = () => {
    //     const url = Api.PROSEDUR + '?lang=' + localStorage.getItem('language').toUpperCase();
    //     axios({
    //         method: 'get',
    //         url: url,
    //         headers: { 'Authorization': Api.BASIC_AUTH },
    //         timeout: 1000 * 10, // Wait for 10 seconds
    //     })
    //         .then(response => {
    //             if (response.data.code === '01') {
    //                 setData(response.data.data);
    //             } else {
    //                 setData([]);
    //             }
    //         })
    //         .catch(error => {
    //             console.log('error');
    //             console.log(error);
    //             setData([]);
    //         });
    // }

    const callImage = (image_url) => {
        if (image_url !== null) {
            const full_image_url = Api.IP_KONG_LNSW + image_url.slice(1);
            console.log('full_image_url', full_image_url);
            return full_image_url;
        }
    }
    const callFile = (file_url) => {
        if (file_url !== null) {
            const full_file_url = Api.IP_KONG_LNSW + file_url?.slice(1);
            return full_file_url;
        }
    }

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }

        getProsedurInformasiPublik()
        // _get_data_data();
        // _get_data_form();
    }, []);
    
    return (
        <>

            <div className='judul-aju-sengketa-info d-flex justify-content-center'>
                <span className="sm-content">
                    <p className="text-center sm-title">Prosedur Permintaan Informasi Publik</p>
                </span>
            </div>
            {dataInfoPublik.map((item, index)=>
                <Container className="" key={index}>
                    <Row className="judul-aju-sengketa-info justify-content-center">
                            <span className="sm-content">
                                <h4 className="text-center text-secondary font-weight-bold">
                                    {/* {item.header ? item.header : "Prosedur Permintaan Informasi Publik"} */}
                                    {item.header && item.header }
                                </h4>
                            </span>     
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-10 justify-content-center">
                            <Row className="text-center">
                                <Col className="mt-3">
                                    <Image src={item.nama_img ? callImage(item.nama_img) : "http://placehold.it/900x1500"} thumbnail />           
                                </Col>
                            </Row>
                            <Row className="text-center">
                                <Col className="mt-3">
                                    <h5 className="float-left font-weight-bold">
                                        {item.url_image} 
                                    </h5>   
                                </Col>
                            </Row>
                            <Row >
                                <Col className="float-left col-lg-12 mb-2">
                                    <h5 className="text-secondary font-weight-bold">
                                        {item.ur ? parseHtml(item.ur) : ""}
                                    </h5>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                {item.file_doc &&
                                    <a className="float-left text-primary col-lg-8 m-0 p-0" href={callFile(item.file_doc)} target="_blank" download={"Prosedur Pengajuan Sengketa Informasi.pdf"}>
                                        {item.ur_doc ? item.ur_doc : "Unduh Dokumen Prosedur Pengajuan Sengketa Informasi (pdf)"}
                                    </a>
                                }
                                </Col>
                            </Row>
                            {/* <Row >
                                <Col className="float-left col-lg-8 col-12">
                                    <p className="text-primary font-weight-bold">
                                        {item.ur_doc ? item.ur_doc : ""}
                                    </p>
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>
                </Container>
        )}
            {/* <div className="prosedur-permohonan row my-5 mx-0 justify-content-center text-center">
                <div className='judul-aju-sengketa-info justify-content-center'>
                    <span className="sm-content">
                        <p className="text-center sm-title">
                            Prosedur Permohonan Informasi Publik
                        </p>
                    </span>     
                </div>

                <Row className="justify-content-center mx-5">
                    <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-10 justify-content-center">
                        <Row className="text-center">
                            <Col className="mt-3">
                                <div className="text-left pp-content-list ">
                                    {data.map((item, index) =>
                                        <div className="row m-0 pp-list-break mb-3 align-items-center justify-content-center">
                                            <div className="col-md-1 pp-list-titles">
                                                <img src={callImage(item.nama_img ? item.nama_img : '#' )} alt="img" />
                                            </div>
                                            <div className="col-md-11">
                                                {parseHtml(item?.ur)}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row m-0">
                                        {dataForm.map((item, index) =>
                                            <div className={index %2== 0 ? "col-md-6 p-0 part-1" : "col-md-6 p-0 part-2"}>
                                                <p>
                                                    {item?.header}
                                                </p>
                                                <button
                                                    className="btn btn-primary rounded-pill"
                                                    onClick={() => klik_data(item?.file)}
                                                    >{t('download')}
                                                </button>
                                            </div>
                                            // <div className="col-md-6 p-0 part-2">
                                            //     <p>
                                            //         Formulir Keberatan Informasi Publik
                                            //         <button className="btn btn-primary rounded-pill">Unduh</button>
                                            //     </p>
                                            // </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div> */}
        </>
    )
}

export default ProsedurPermohonan;
