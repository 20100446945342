import React, { useEffect, useState } from 'react';
import './contactcenter.scss';
import axios from 'axios';
import * as Api from '../../constants/api';
import { SitemapCollapse } from '../../components';
import parse from 'html-react-parser';

function ContactCenter() {
    const [detail_profil_data, setProfilData] = useState([]);

    //did mount
    useEffect(() => {
        _get_data_detail();
    }, []);

    const _get_data_detail = () => {
        const url = Api.PROFIL_PPID + '?lang=' + localStorage.getItem('language').toUpperCase();
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                // console.log("detail_profil", response.data.data);
                if (response.data.code === '01') {
                    setProfilData(response.data.data);
                } else {
                    setProfilData([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setProfilData([]);
            });
    }

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    return (
        <div className="contactcenter-container">
            <div className="contactcenter-content">
                <div className="contactcenter-content-header">
                    <div className="contactcenter-content-title">Contact Center LNSW</div>
                    {/* <div className="contactcenter-content-subtitle">Untuk mempermudah user yang mengakses website ini, kami sediakan map yang berisi struktur utama website ini. Dengan ini kami harapkan user dapat dengan mudah menemukan halaman untuk kontent yang ingin diakses.</div> */}
                </div>
                <div className="contactcenter-content-body">
                {detail_profil_data.map((item, index) =>
                    item.id_profil_ppid === 3 ?
                    <div>
                        {parseHtml(item.ur)}
                    </div>
                    :
                    null
                )}
                </div>
            </div>
        </div>
    )
}

export default ContactCenter
