/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './input_search_kurs.scss';
import { ICSearchGray } from '../../../assets';

const InputSearchKurs = ({placeholder, onChange, ...rest}) => {
    return (
        <div className="intr-input-sub-search-kurs">
            <img className="icon-search-kurs" src={ICSearchGray} />
            <input className="input-field-search-kurs" placeholder={placeholder} onChange={onChange} {...rest} />
        </div>
    )
}

export default InputSearchKurs;
