import React, { useState } from 'react'
import './collapse_comp.scss'
import { Collapse } from 'react-collapse'
import { ICCollapseDown, ICCollapseUp } from '../../../assets'
import { useTranslation } from 'react-i18next'
import * as Api from '../../../constants/api'
import parse from 'html-react-parser'
import { Link, useHistory } from 'react-router-dom'
import { Button, Label, Row } from 'reactstrap'

const CollapseComp = ({
   type,
   header,
   bagjel,
   babjel,
   bagjelEn,
   babjelEn,
   dataMfn,
   dataPrefTariff,
   dataImport,
   dataImportBorder,
   dataImportPostBorder,
   dataExport,
   dataHsOther,
   dataCatatan,
   dataKhususRegulasi,
   dataRefSatuan,
   dataKepabean,
   dataKepabeanPB
}) => {
   const [buka, setBuka] = useState(false)
   const [isOpen, setIsOpen] = useState(true)
   const [open, setOpen] = useState(true)
   const [isShowDokPabean, setIsDokPabean] = useState({});
   const [isShowDokPabeanPB, setIsDokPabeanPB] = useState({});
   const [dataImportBorders, setDataImportBorders] = useState({});
   const [dataImportPostBorders, setDataImportPostBorders] = useState({});
   const { t } = useTranslation()
   const history = useHistory()

   const toggleDokPabean = (item) => {
      // console.log('item', item)
      // console.log('dataImportBorder', dataImportBorder)
      const filterDokPabean = dataImportBorder.filter(x => x.dok_pabean.length > 0 && x.dok_pabean.includes(item.kd_dokumen));
      // console.log('filterDokPabean', filterDokPabean);
      setDataImportBorders(filterDokPabean);
      const data = dataKepabean.find(x => x.kd_dokumen == item.kd_dokumen)
      setIsDokPabean(data);
    };

    const toggleDokPabeanPB = (item) => {
      const filterDokPabean = dataImportPostBorder.filter(x => x.dok_pabean.length > 0 && x.dok_pabean.includes(item.kd_dokumen));
      setDataImportPostBorders(filterDokPabean);
      const data = dataKepabeanPB.find(x => x.kd_dokumen == item.kd_dokumen)
      setIsDokPabeanPB(data);
    };

   const klikCollapse = (buka) => {
      if (dataImportBorder && dataImportBorder.length>0) {
         const getDokPabean20 = dataImportBorder.filter(x => x.dok_pabean.length > 0 && x.dok_pabean.includes('20'));
         setDataImportBorders(getDokPabean20);
         const data = dataKepabean.find(x => x.kd_dokumen == '20')
         setIsDokPabean(data);
      }

      if (dataImportPostBorder && dataImportPostBorder.length>0) {
         const getDokPabean20 = dataImportPostBorder.filter(x => x.dok_pabean.length > 0 && x.dok_pabean.includes('20'));
         setDataImportPostBorders(getDokPabean20);
         const data = dataKepabeanPB.find(x => x.kd_dokumen == '20')
         setIsDokPabeanPB(data);
      }

      if (buka === false) {
         setBuka(true)
      } else {
         setBuka(false)
      }
   }

   const klikOpen = (isOpen) => {
      if (isOpen === false) {
         setIsOpen(true)
      } else {
         setIsOpen(false)
      }
   }

   const touchOpen = (open) => {
      if (open === false) {
         setOpen(true)
      } else {
         setOpen(false)
      }
   }

   const CallImage = (image_url) => {
      if (image_url !== null) {
         var url_image = Api.IP_KONG_LNSW + image_url.slice(1)
         // console.log(url_image);
         // return url_image;
         const win = window.open(url_image, '_blank')
         if (win != null) {
            win.focus()
         }
      }
   }

   const parseHtml = (html) => {
      // console.log('html');
      // console.log(html)
      var text_html = ''
      if (html !== null) {
         text_html = parse(html)
      }
      // var text_html = parse(html.toString());

      return text_html
   }

   const replace = (item) => {
      let regex = /Surat Keterangan Impor/gi

      if (item !== null) {
         if (item[item.search(regex)] === undefined) {
            return item.replace(/\/ Surat Keterangan|Surat Keterangan/gi, '')
         } else {
            return item
         }
      } else {
         return item
      }

   }

   const toDetail = (name, legal, idDokumen) => {
      history.push('/intr/detail-komoditas/detail-perizinan', {
         nama_izin: name,
         regulasi: legal,
         idDokumen: idDokumen,
      })
   }

   const TypeColl = () => {
      if (type === 'mfn' && header.hs_code.length === 8) {
         return (
            <>
               <div
                  className="collapse-header"
                  onClick={() => klikOpen(isOpen)}
               >
                  <p className="collapse-text-head">
                     Informasi Tarif
                  </p>
                  <div className="collapse-icon">
                     <img
                        src={isOpen === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body">
                  <Collapse isOpened={isOpen}>
                     {dataMfn.length !== 0 ? (
                        dataMfn?.map((item) => (
                           <div>
                              {/* <p className="collapse-text-headsub">Regulation {item.regulation}</p>
                                <ul className="coll-bull-mfn">
                                    <li className="collapse-text-mfn">Regulation</li>
                                    <p className="unduh-mfn" onClick={()=>CallImage(item.regulation)}>{t("download")}</p>
                                </ul> */}
                              <div className="mfn-header-container">
                                 <p className="mfn-name">{t('name_mfn')}</p>
                                 <p className="mfn-regulasi">
                                    {t('regulation_mfn')}
                                 </p>
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">BM MFN (Most Favored Nation)</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.bm[0].bm == '999' ? (
                                       'Baca Peraturan'
                                    ) : (
                                       <p>
                                          {item.bm[0].bm.replace('null', '')}
                                       </p>
                                    )}
                                 </p>
                                 {/* {
                                        item.bm[0].file !== null ?
                                        <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                        :
                                        <p className="unduh-mfn-not" >{t("download")}</p>
                                    } */}

                                 {!(
                                    item.bm[0].regulation === null ||
                                    item.bm[0].regulation === 'null' ||
                                    item.bm[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.bm[0].file)
                                       }
                                    >
                                       {item.bm[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 {item.bmkblbb[0].bmkblbb !== 'N/A' && (
                                    <>
                                       <p className="coll-mfn-judul">BM MFN Program KBLBB (<i>Battery Electric Vehicle</i>)</p>
                                       <p className="coll-mfn-tidu">:</p>
                                       <p className="coll-mfn-hasil">
                                          {item.bmkblbb[0].bmkblbb === '999'
                                             ? 'Baca Peraturan'
                                             : item.bmkblbb[0].bmkblbb.replace(
                                                '- null',
                                                '',
                                             )}
                                       </p>
                                    </>
                                 )}
                                 {/* {
                                        item.bm[0].file !== null ?
                                        <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                        :
                                        <p className="unduh-mfn-not" >{t("download")}</p>
                                    } */}
                                 {!(
                                    item.bmkblbb[0].regulation === null ||
                                    item.bmkblbb[0].regulation === 'null' ||
                                    item.bmkblbb[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.bmkblbb[0].file)
                                       }
                                    >
                                       {item.bmkblbb[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">PPN</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.ppn[0].ppn.replace('- null', '')}
                                 </p>
                                 {/* {
                                        item.bm[0].file !== null ?
                                        <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                        :
                                        <p className="unduh-mfn-not" >{t("download")}</p>
                                    } */}
                                 {!(
                                    item.ppn[0].regulation === null ||
                                    item.ppn[0].regulation === 'null' ||
                                    item.ppn[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.ppn[0].file)
                                       }
                                    >
                                       {item.ppn[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">PPnBM</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.ppnbm[0].ppnbm === '999'
                                       ? 'Baca Peraturan'
                                       : item.ppnbm[0].ppnbm.replace(
                                            '- null',
                                            '',
                                         )}
                                 </p>
                                 {/* {
                                        item.bm[0].file !== null ?
                                        <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                        :
                                        <p className="unduh-mfn-not" >{t("download")}</p>
                                    } */}
                                 {!(
                                    item.ppnbm[0].regulation === null ||
                                    item.ppnbm[0].regulation === 'null' ||
                                    item.ppnbm[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.ppnbm[0].file)
                                       }
                                    >
                                       {item.ppnbm[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">CUKAI</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.cukai[0].cukai ==
                                    'null 999.00 / null' ? (
                                       'Baca Peraturan'
                                    ) : (
                                       <p>
                                          {item.cukai[0].cukai.replace(
                                             '- null',
                                             '',
                                          )}
                                       </p>
                                    )}
                                 </p>
                                 {/* {
                                        item.bm[0].file !== null ?
                                        <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                        :
                                        <p className="unduh-mfn-not" >{t("download")}</p>
                                    } */}
                                 {!(
                                    item.cukai[0].regulation === null ||
                                    item.cukai[0].regulation === 'null' ||
                                    item.cukai[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.cukai[0].file)
                                       }
                                    >
                                       {item.cukai[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">BM AD</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.ad[0].ad === '999'
                                       ? 'Baca Peraturan'
                                       : item.ad[0].ad}
                                 </p>
                                 {/* {
                                        item.ad[0].file !== null ?
                                        <p className="unduh-mfn" onClick={()=>CallImage(item.ad[0].file)}>{t("download")}</p>
                                        :
                                        <p className="unduh-mfn-not" >{t("download")}</p>
                                    } */}
                                 {!(
                                    item.ad[0].regulation === null ||
                                    item.ad[0].regulation === 'null' ||
                                    item.ad[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.ad[0].regulation}</p>

                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.ad[0].file)
                                       }
                                    >
                                       {item.ad[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">BM TP</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.tp[0].tp == 'IDR 999.00 / PCE' ? (
                                       'Baca Peraturan'
                                    ) : (
                                       <p>
                                          {item.tp[0].tp.replace('- null', '')}
                                       </p>
                                    )}
                                 </p>
                                 {!(
                                    item.tp[0].regulation === null ||
                                    item.tp[0].regulation === 'null' ||
                                    item.tp[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.tp[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.tp[0].file)
                                       }
                                    >
                                       {item.tp[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">BM IM</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.im[0].im == '999' ? (
                                       'Baca Peraturan'
                                    ) : (
                                       <p>
                                          {item.im[0].im.replace('- null', '')}
                                       </p>
                                    )}
                                 </p>
                                 {/* {
                                        item.im[0].file !== null ?
                                        <p className="unduh-mfn" onClick={()=>CallImage(item.im[0].file)}>{t("download")}</p>
                                        :
                                        <p className="unduh-mfn-not" >{t("download")}</p>
                                    } */}
                                 {!(
                                    item.im[0].regulation === null ||
                                    item.im[0].regulation === 'null' ||
                                    item.im[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.im[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.im[0].file)
                                       }
                                    >
                                       {item.im[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              {item?.pph.length !== 0 &&
                              item?.pph[0]?.flag_api !== null ? (
                                 <>
                                    <div className="coll-mfn-container">
                                       <p className="coll-mfn-judul">PPH</p>
                                       <p className="coll-mfn-tidu">:</p>
                                       <p className="coll-mfn-hasil">
                                          {item?.pph[0]?.pph === '999'
                                             ? 'Baca Peraturan'
                                             : `${item?.pph[0]?.pph.replace(
                                                  '- null',
                                                  '',
                                               )} ${item?.pph[0]?.flag_api === "Y" ? "(API)" : "(NON-API)"}`}
                                       </p>
                                       {!(
                                          item?.pph[0]?.regulation === null ||
                                          item?.pph[0]?.regulation === 'null' ||
                                          item?.pph[0]?.regulation === '-'
                                       ) ? (
                                          <p
                                             className="reg-mfn unduh-mfn"
                                             onClick={() =>
                                                CallImage(item?.pph[0]?.file)
                                             }
                                          >
                                             {item?.pph[0]?.regulation}
                                          </p>
                                       ) : (
                                          <p className="unduh-mfn-kosong"></p>
                                       )}
                                    </div>
                                    <div className="coll-mfn-container">
                                       <p className="coll-mfn-judul">PPH</p>
                                       <p className="coll-mfn-tidu">:</p>
                                       <p className="coll-mfn-hasil">
                                          {item?.pph[1]?.pph === '999'
                                             ? 'Baca Peraturan'
                                             : `${item?.pph[1]?.pph.replace(
                                                  '- null',
                                                  '',
                                               )} ${item?.pph[1]?.flag_api === "Y" ? "(API)" : "(NON-API)"}`}
                                       </p>
                                       {!(
                                          item?.pph[1]?.regulation === null ||
                                          item?.pph[1]?.regulation === 'null' ||
                                          item?.pph[1]?.regulation === '-'
                                       ) ? (
                                          <p
                                             className="reg-mfn unduh-mfn"
                                             onClick={() =>
                                                CallImage(item?.pph[1]?.file)
                                             }
                                          >
                                             {item?.pph[1]?.regulation}
                                          </p>
                                       ) : (
                                          <p className="unduh-mfn-kosong"></p>
                                       )}
                                    </div>
                                 </>
                              ) : (
                                 <>
                                    <div className="coll-mfn-container">
                                       <p className="coll-mfn-judul">PPH</p>
                                       <p className="coll-mfn-tidu">:</p>
                                       <p className="coll-mfn-hasil">
                                          {item?.pph.length > 0
                                             ? item?.pph[0]?.pph === '999'
                                                ? 'Baca Peraturan'
                                                : `${item?.pph[0]?.pph.replace(
                                                     '- null',
                                                     '',
                                                  )}`
                                             : 'N/A'}
                                       </p>
                                       {!(
                                          item?.pph[0]?.regulation === null ||
                                          item?.pph[0]?.regulation === 'null' ||
                                          item?.pph[0]?.regulation === '-'
                                       ) ? (
                                          <p
                                             className="reg-mfn unduh-mfn"
                                             onClick={() =>
                                                CallImage(item?.pph[0]?.file)
                                             }
                                          >
                                             {item?.pph[0]?.regulation}
                                          </p>
                                       ) : (
                                          <p className="unduh-mfn-kosong"></p>
                                       )}
                                    </div>
                                 </>
                              )}
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">BK</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.ppnbk[0].ppnbk === '999'
                                       ? 'Baca Peraturan'
                                       : item.ppnbk[0].ppnbk.replace(
                                            '- null',
                                            '',
                                         )}
                                 </p>
                                 {/* {
                                        item.bm[0].file !== null ?
                                        <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                        :
                                        <p className="unduh-mfn-not" >{t("download")}</p>
                                    } */}
                                 {!(
                                    item.ppnbk[0].regulation === null ||
                                    item.ppnbk[0].regulation === 'null' ||
                                    item.ppnbk[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.ppnbk[0].file)
                                       }
                                    >
                                       {item.ppnbk[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">Tarif Dana Sawit</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.tarif_dana_sawit[0].tarif_dana_sawit}
                                 </p>
                                 {/* {
                                        item.bm[0].file !== null ?
                                        <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                        :
                                        <p className="unduh-mfn-not" >{t("download")}</p>
                                    } */}
                                 {!(
                                    item.tarif_dana_sawit[0].regulation === null ||
                                    item.tarif_dana_sawit[0].regulation === 'null' ||
                                    item.tarif_dana_sawit[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.tarif_dana_sawit[0].file)
                                       }
                                    >
                                       {item.tarif_dana_sawit[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">Wajib Lapor DHE-SDA</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">{item.flag_sda.flag_sda}</p>
                                 <p className="unduh-mfn-kosong">{item.flag_sda.no_skep}</p>
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">NOTE</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">{item.notes}</p>
                                 <p className="unduh-mfn-kosong"></p>
                              </div>
                           </div>
                        ))
                     ) : (
                        <div>
                           <p className="collapse-text-headsub">
                              {t('empty_data')}
                           </p>
                        </div>
                     )}
                  </Collapse>
               </div>
            </>
         )
      } else if (type === 'mfn') {
         return (
            <>
               <div
                  className="collapse-header"
                  onClick={() => klikCollapse(buka)}
               >
                  <p className="collapse-text-head">
                     Informasi Tarif
                  </p>
                  <div className="collapse-icon">
                     <img
                        src={buka === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body">
                  <Collapse isOpened={buka}>
                     {dataMfn.length !== 0 ? (
                        dataMfn.map((item) => (
                           <div>
                              {/* <p className="collapse-text-headsub">Regulation {item.regulation}</p>
                             <ul className="coll-bull-mfn">
                                 <li className="collapse-text-mfn">Regulation</li>
                                 <p className="unduh-mfn" onClick={()=>CallImage(item.regulation)}>{t("download")}</p>
                             </ul> */}
                              <div className="mfn-header-container">
                                 <p className="mfn-name">{t('name_mfn')}</p>
                                 <p className="mfn-regulasi">
                                    {t('regulation_mfn')}
                                 </p>
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">BM</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.bm[0].bm.replace('- null', '')}
                                 </p>
                                 {/* {
                                     item.bm[0].file !== null ?
                                     <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                     :
                                     <p className="unduh-mfn-not" >{t("download")}</p>
                                 } */}

                                 {!(
                                    item.bm[0].regulation === null ||
                                    item.bm[0].regulation === 'null' ||
                                    item.bm[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.bm[0].file)
                                       }
                                    >
                                       {item.bm[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">PPN</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.ppn[0].ppn.replace('- null', '')}
                                 </p>
                                 {/* {
                                     item.bm[0].file !== null ?
                                     <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                     :
                                     <p className="unduh-mfn-not" >{t("download")}</p>
                                 } */}
                                 {!(
                                    item.ppn[0].regulation === null ||
                                    item.ppn[0].regulation === 'null' ||
                                    item.ppn[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.ppn[0].file)
                                       }
                                    >
                                       {item.ppn[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">PPnBM</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.ppnbm[0].ppnbm === '999'
                                       ? 'Baca Peraturan'
                                       : item.ppnbm[0].ppnbm.replace(
                                            '- null',
                                            '',
                                         )}
                                 </p>
                                 {/* {
                                     item.bm[0].file !== null ?
                                     <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                     :
                                     <p className="unduh-mfn-not" >{t("download")}</p>
                                 } */}
                                 {!(
                                    item.ppnbm[0].regulation === null ||
                                    item.ppnbm[0].regulation === 'null' ||
                                    item.ppnbm[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.ppnbm[0].file)
                                       }
                                    >
                                       {item.ppnbm[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">CUKAI</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.cukai[0].cukai === '999'
                                       ? 'Baca Peraturan'
                                       : item.cukai[0].cukai.replace(
                                            '- null',
                                            '',
                                         )}
                                 </p>
                                 {/* {
                                     item.bm[0].file !== null ?
                                     <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                     :
                                     <p className="unduh-mfn-not" >{t("download")}</p>
                                 } */}
                                 {!(
                                    item.cukai[0].regulation === null ||
                                    item.cukai[0].regulation === 'null' ||
                                    item.cukai[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.cukai[0].file)
                                       }
                                    >
                                       {item.cukai[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">BM AD</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.ad[0].ad === '999'
                                       ? 'Baca Peraturan'
                                       : item.ad[0].ad}
                                 </p>
                                 {/* {
                                     item.ad[0].file !== null ?
                                     <p className="unduh-mfn" onClick={()=>CallImage(item.ad[0].file)}>{t("download")}</p>
                                     :
                                     <p className="unduh-mfn-not" >{t("download")}</p>
                                 } */}
                                 {!(
                                    item.ad[0].regulation === null ||
                                    item.ad[0].regulation === 'null' ||
                                    item.ad[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.ad[0].regulation}</p>

                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.ad[0].file)
                                       }
                                    >
                                       {item.ad[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">BM TP</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.tp[0].tp === '999'
                                       ? 'Baca Peraturan'
                                       : item.tp[0].tp.replace('- null', '')}
                                 </p>
                                 {/* {
                                     item.tp[0].file !== null ?
                                     <p className="unduh-mfn" onClick={()=>CallImage(item.tp[0].file)}>{t("download")}</p>
                                     :
                                     <p className="unduh-mfn-not" >{t("download")}</p>
                                 } */}
                                 {!(
                                    item.tp[0].regulation === null ||
                                    item.tp[0].regulation === 'null' ||
                                    item.tp[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.tp[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.tp[0].file)
                                       }
                                    >
                                       {item.tp[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">BM IM</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.im[0].im === '999'
                                       ? 'Baca Peraturan'
                                       : item.im[0].im.replace('- null', '')}
                                 </p>
                                 {/* {
                                     item.im[0].file !== null ?
                                     <p className="unduh-mfn" onClick={()=>CallImage(item.im[0].file)}>{t("download")}</p>
                                     :
                                     <p className="unduh-mfn-not" >{t("download")}</p>
                                 } */}
                                 {!(
                                    item.im[0].regulation === null ||
                                    item.im[0].regulation === 'null' ||
                                    item.im[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.im[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.im[0].file)
                                       }
                                    >
                                       {item.im[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">PPH</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.pph[0].pph.replace('- null', '')}
                                 </p>
                                 {/* {
                                     item.pph[0].file !== null ?
                                     <p className="unduh-mfn" onClick={()=>CallImage(item.pph[0].file)}>{t("download")}</p>
                                     :
                                     <p className="unduh-mfn-not" >{t("download")}</p>
                                 } */}
                                 {!(
                                    item.pph[0].regulation === null ||
                                    item.pph[0].regulation === 'null' ||
                                    item.pph[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.pph[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.pph[0].file)
                                       }
                                    >
                                       {item.pph[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">BK</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">
                                    {item.ppnbk[0].ppnbk === '999'
                                       ? 'Baca Peraturan'
                                       : item.ppnbk[0].ppnbk.replace(
                                            '- null',
                                            '',
                                         )}
                                 </p>
                                 {/* {
                                     item.bm[0].file !== null ?
                                     <p className="unduh-mfn" onClick={()=>CallImage(item.bm[0].file)}>{t("download")}</p>
                                     :
                                     <p className="unduh-mfn-not" >{t("download")}</p>
                                 } */}
                                 {!(
                                    item.ppnbk[0].regulation === null ||
                                    item.ppnbk[0].regulation === 'null' ||
                                    item.ppnbk[0].regulation === '-'
                                 ) ? (
                                    // <p className="reg-mfn" >{item.bm[0].regulation}</p>
                                    <p
                                       className="reg-mfn unduh-mfn"
                                       onClick={() =>
                                          CallImage(item.ppnbk[0].file)
                                       }
                                    >
                                       {item.ppnbk[0].regulation}
                                    </p>
                                 ) : (
                                    <p className="unduh-mfn-kosong"></p>
                                 )}
                              </div>
                              <div className="coll-mfn-container">
                                 <p className="coll-mfn-judul">NOTE</p>
                                 <p className="coll-mfn-tidu">:</p>
                                 <p className="coll-mfn-hasil">{item.notes}</p>
                                 <p className="unduh-mfn-kosong"></p>
                              </div>
                           </div>
                        ))
                     ) : (
                        <div>
                           <p className="collapse-text-headsub">
                              {t('empty_data')}
                           </p>
                        </div>
                     )}
                  </Collapse>
               </div>
            </>
         )
      }
      if (type === 'catatan') {
         return (
            <>
               <div
                  className="collapse-header"
                  onClick={() => klikCollapse(buka)}
               >
                  <p className="collapse-text-head">{header}</p>
                  <div className="collapse-icon">
                     <img
                        src={buka === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body">
                  <Collapse isOpened={buka}>
                     <div className="coll-bull-catatan">
                        <div className="catatan-header">
                           {dataCatatan.bab_id}
                        </div>
                        <div className="catatan-desc">
                           {dataCatatan.desc_id}
                        </div>
                        <div className="catatan-note-id">
                           {parseHtml(dataCatatan.note_id)}
                        </div>
                        <div className="catatan-header">
                           {dataCatatan.bab_en}
                        </div>
                        <div className="catatan-desc">
                           {dataCatatan.desc_en}
                        </div>
                        <div className="catatan-note">
                           {parseHtml(dataCatatan.note_en)}
                        </div>
                     </div>
                  </Collapse>
               </div>
            </>
         )
      }
      if (type === 'hscode' && header.hs_code.length === 8) {
         return (
            <>
               <div className="collapse-header" onClick={() => touchOpen(open)}>
                  <p className="collapse-text-head">
                     Uraian Barang / Description of
                  </p>
                  <div className="collapse-icon">
                     <img
                        src={open === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body">
                  <Collapse isOpened={open}>
                     <div style={{ display: 'flex' }}>
                        <div className="satuan-container-parent">
                           <div className="coll-satuan-desc">
                              <p className="title">Bahasa</p>
                              <div>
                                 <ul className="coll-bull">
                                    <li className="collapse-text">
                                       {' '}
                                       BAGIAN {header.bagian}
                                    </li>
                                 </ul>
                                 <div className="coll-bull-sub">
                                    {bagjel.map((item) => (
                                       <span className="collapse-text-sub">
                                          {item}
                                       </span>
                                    ))}
                                 </div>
                                 <ul className="coll-bull">
                                    <li className="collapse-text">
                                       {' '}
                                       BAB {header.bab}
                                    </li>
                                 </ul>
                                 <div className="coll-bull-sub">
                                    {babjel.map((item) => (
                                       <span className="collapse-text-sub">
                                          {item};
                                       </span>
                                    ))}
                                 </div>
                                 {dataHsOther.length !== 0
                                    ? dataHsOther.map((item) => (
                                         <div>
                                            <ul className="coll-bull">
                                               <li className="collapse-text">
                                                  {item.hs_code_format}
                                               </li>
                                            </ul>
                                            <p className="collapse-text-sub">
                                               {item.ur_id}
                                            </p>
                                            {/* <p className="collapse-text-sub-en">
                                   {item.ur_en}
                                </p> */}
                                         </div>
                                      ))
                                    : null}
                              </div>
                           </div>
                           <div className="coll-satuan-desc">
                              <p className="title">English</p>
                              <div>
                                 <ul className="coll-bull">
                                    <li className="collapse-text">
                                       {' '}
                                       SECTION {header.bagian}
                                    </li>
                                 </ul>
                                 <div className="coll-bull-sub">
                                    {bagjelEn.map((item) => (
                                       <span className="collapse-text-sub">
                                          {item};
                                       </span>
                                    ))}
                                 </div>
                                 <ul className="coll-bull">
                                    <li className="collapse-text">
                                       {' '}
                                       CHAPTER {header.bab}
                                    </li>
                                 </ul>
                                 <div className="coll-bull-sub">
                                    {babjelEn.map((item) => (
                                       <span className="collapse-text-sub">
                                          {item};
                                       </span>
                                    ))}
                                 </div>
                                 {dataHsOther.length !== 0
                                    ? dataHsOther.map((item) => (
                                         <div>
                                            <ul className="coll-bull">
                                               <li className="collapse-text">
                                                  {item.hs_code_format}
                                               </li>
                                            </ul>
                                            <p className="collapse-text-sub">
                                               {item.ur_en}
                                            </p>
                                            {/* <p className="collapse-text-sub-en">
                                   {item.ur_en}
                                </p> */}
                                         </div>
                                      ))
                                    : null}
                              </div>
                           </div>
                        </div>
                     </div>
                  </Collapse>
               </div>
            </>
         )
      } else if (type === 'hscode') {
         return (
            <>
               <div
                  className="collapse-header"
                  onClick={() => klikCollapse(buka)}
               >
                  <p className="collapse-text-head">
                     Uraian Barang / Description of
                  </p>
                  <div className="collapse-icon">
                     <img
                        src={buka === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body">
                  <Collapse isOpened={buka}>
                     <ul className="coll-bull">
                        <li className="collapse-text">
                           {' '}
                           BAGIAN {header.bagian}
                        </li>
                     </ul>
                     <p className="collapse-text-sub">
                        {bagjel.map((item) => item + '; ')}
                     </p>
                     <ul className="coll-bull">
                        <li className="collapse-text"> BAB {header.bab}</li>
                     </ul>
                     <p className="collapse-text-sub">
                        {babjel.map((item) => item + '; ')}
                     </p>
                     {/* {dataHsOther.length !== 0
                        ? dataHsOther.map((item) => (
                             <div>
                                <ul className="coll-bull">
                                   <li className="collapse-text">
                                      {item.hs_code}
                                   </li>
                                </ul>
                                <p className="collapse-text-sub">{item.ur}</p>
                                <p className="collapse-text-sub-en">
                                   {item.ur_en}
                                </p>
                             </div>
                          ))
                        : null} */}
                  </Collapse>
               </div>
            </>
         )
      }
      if (type === 'tarif') {
         return (
            <>
               <div
                  className="collapse-header"
                  onClick={() => klikCollapse(buka)}
               >
                  <p className="collapse-text-head">Tarif Preferensi</p>
                  <div className="collapse-icon">
                     <img
                        src={buka === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body">
                  <Collapse isOpened={buka}>
                     {/* <p className="collapse-text-headsub">Data Tarif Preferensi</p> */}
                     {dataPrefTariff.length !== 0 ? (
                        dataPrefTariff.map((item) => (
                           <div>
                              <ul className="coll-bull">
                                 <li className="collapse-text">
                                    {item.preference}
                                 </li>
                              </ul>
                              <>
                                 {item.file_path !== null ? (
                                    <p className="collapse-text-sub mt-3">
                                       Regulasi{' '}
                                       <span
                                          className="regulasi-sub-link"
                                          onClick={() =>
                                             CallImage(item.file_path)
                                          }
                                       >
                                          {item.no_skep}
                                       </span>
                                    </p>
                                 ) : (
                                    <p className="collapse-text-sub mt-3">
                                       Regulasi {item.no_skep}
                                    </p>
                                 )}
                              </>
                              {/* <p className="collapse-text-sub-link" onClick={()=>CallImage(item.no_skep)}>Regulation {item.no_skep}</p> */}
                              {/* <p className="collapse-text-sub">Tanggal {item.tgl_skep}</p> */}
                              <p className="collapse-text-sub mt-2">
                                 Berlaku mulai {item.berlaku}
                              </p>
                              {Array.isArray(item.years) ? (
                                 item.years.map((item_map) => (
                                    <div>
                                       <ul className="coll-bull-sub">
                                          <li className="collapse-text-sub mt-3">
                                             {item_map.tahun +
                                                '    : ' +
                                                item_map.nilai}
                                          </li>
                                          {/* <li className="collapse-text-sub">
                                             {item_map.seterusnya}
                                             
                                       </li> */}
                                       </ul>
                                    </div>
                                 ))
                              ) : (
                                 <div>
                                    <ul className="coll-bull-sub">
                                       <li className="collapse-text-sub mt-3">
                                          {item.years.tahun +
                                             '    : ' +
                                             item.years.nilai}
                                       </li>
                                       {/* <li className="collapse-text-sub">
                                             {item_map.seterusnya}
                                             
                                       </li> */}
                                    </ul>
                                 </div>
                              )}
                              <p className="collapse-text-sub mt-2">
                                 {item.seterusnya ? (
                                    <span> &#42; Tarif tahun berikutnya sesuai PMK yang berlaku</span>
                                 ) : (
                                    ''
                                 )}
                              </p>
                           </div>
                        ))
                     ) : (
                        <div>
                           <p className="collapse-text-headsub">
                              {t('empty_data')}
                           </p>
                        </div>
                     )}
                  </Collapse>
               </div>
            </>
         )
      }
      if (type === 'refSatuan') {
         return (
            <>
               <div
                  className="collapse-header"
                  onClick={() => klikCollapse(buka)}
               >
                  <p className="collapse-text-head">Satuan Wajib (Detil Satuan Lihat Pada Row Lartas)</p>
                  <div className="collapse-icon">
                     <img
                        src={buka === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body">
                  <Collapse isOpened={buka}>
                     <div className="satuan-container-parent">
                        <div className="coll-satuan">
                           <p className="title">Impor</p>
                           {Object.values(dataRefSatuan).length !== 0 &&
                           dataRefSatuan.impor.length !== 0 ? (
                              dataRefSatuan.impor.map((item, index) => (
                                 <p className="sub-title">{`${item.kd_satuan} (${item.ur_satuan})`}</p>
                              ))
                           ) : (
                              <div>
                                 <p className="collapse-text-headsub">
                                    {t('empty_data')}
                                 </p>
                              </div>
                           )}
                        </div>
                        <div className="coll-satuan">
                           <p className="title">Regulasi</p>
                           {Object.values(dataRefSatuan).length !== 0 &&
                           dataRefSatuan.impor.length !== 0 ? (
                              dataRefSatuan.impor.map((item, index) => (
                                 <p className="sub-title ">{item.no_skep}</p>
                              ))
                           ) : (
                              <div>
                                 <p className="collapse-text-headsub">
                                    {t('empty_data')}
                                 </p>
                              </div>
                           )}
                        </div>
                        <div className="coll-satuan">
                           <p className="title">Ekspor</p>
                           {Object.values(dataRefSatuan).length !== 0 &&
                           dataRefSatuan.ekspor.length !== 0 ? (
                              dataRefSatuan.ekspor.map((item, index) => (
                                 <p className="sub-title">{`${item.kd_satuan} (${item.ur_satuan})`}</p>
                              ))
                           ) : (
                              <div>
                                 <p className="collapse-text-headsub">
                                    {t('empty_data')}
                                 </p>
                              </div>
                           )}
                        </div>

                        <div className="coll-satuan">
                           <p className="title">Regulasi</p>
                           {Object.values(dataRefSatuan).length !== 0 &&
                           dataRefSatuan.ekspor.length !== 0 ? (
                              dataRefSatuan.ekspor.map((item, index) => (
                                 <p className="sub-title ">{item.no_skep}</p>
                              ))
                           ) : (
                              <div>
                                 <p className="collapse-text-headsub">
                                    {t('empty_data')}
                                 </p>
                              </div>
                           )}
                        </div>
                     </div>
                  </Collapse>
               </div>
            </>
         )
      }
      if (type === 'impor') {
         return (
            <>
               <div
                  className="collapse-header"
                  onClick={() => klikCollapse(buka)}
               >
                  <p className="collapse-text-head">
                     Regulasi Impor - Lartas Impor
                  </p>
                  <div className="collapse-icon">
                     <img
                        src={buka === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body">
                  <Collapse isOpened={buka}>
                     {/* <p className="collapse-text-headsub">Data Regulasi Impor - Lartas Impor</p> */}
                     {/* <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p> */}
                     {dataImport.length !== 0 ? (
                        dataImport.map((item, index) => (
                           <div
                              className={
                                 dataImport.length !== index + 1
                                    ? 'lartas-container-parent'
                                    : 'lartas-container-parent'
                              }
                           >
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Nama Izin</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p
                                    className="coll-lartas-hasil font-weight-bold"
                                    role={'button'}
                                    onClick={() =>
                                       toDetail(
                                          replace(item.name),
                                          item.legal,
                                          item.id_dokumen,
                                       )
                                    }
                                 >
                                    {/* {item.name.replace(
                                       /\/ Surat Keterangan|Surat Keterangan/gi,
                                       '',
                                    )} */}

                                    <u>{replace(item.name)}</u>
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">
                                    Kode Izin Kepabeanan
                                 </p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.modul}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Komoditi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.komoditi}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Regulasi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.legal}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Deskripsi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.deskripsi}
                                 </p>
                              </div>
                           </div>
                        ))
                     ) : (
                        <div>
                           <p className="collapse-text-headsub">
                              {t('empty_data')}
                           </p>
                        </div>
                     )}
                  </Collapse>
               </div>
            </>
         )
      }
      if (type === 'impor-border') {
         console.log('isShowDokPabean', isShowDokPabean)
         return (
            <>
               <div
                  className="collapse-header"
                  onClick={() => klikCollapse(buka)}
               >
                  <p className="collapse-text-head">
                     Regulasi Impor (Tataniaga Border(Lartas))
                  </p>
                  <div className="collapse-icon">
                     <img
                        src={buka === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body" style={{whiteSpace: 'break-spaces'}}>
                  <Collapse isOpened={buka}>
                     <Row style={{margin: '15px'}}>
                        <h6>Silahkan dapat mengeklik dokumen pemberitahuan pabean impor dibawah ini guna memperoleh informasi yang dibutuhkan</h6>
                     </Row>
                     <Row style={{margin: '5px'}}>
                        {dataKepabean.length !== 0 ? (
                           dataKepabean.map((item) => (
                              <div className={item.status_btn && isShowDokPabean && isShowDokPabean?.kd_dokumen != item.kd_dokumen ? 'custom-kotak-dokumen-pabean-skyblue' : (item.status_btn && isShowDokPabean && isShowDokPabean?.kd_dokumen == item.kd_dokumen ? 'custom-kotak-dokumen-pabean-skyblue-clicked' : (!item.status_btn && isShowDokPabean && isShowDokPabean?.kd_dokumen == item.kd_dokumen ? 'custom-kotak-dokumen-pabean-gray-clicked' : 'custom-kotak-dokumen-pabean-gray' ))} onClick={() => toggleDokPabean(item)}>
                                 {item.nm_dokumen}
                              </div>
                           ))
                        ) : ''}
                     </Row>
                     <Row style={{marginLeft: '15px'}}>
                        {isShowDokPabean && isShowDokPabean?.kd_dokumen && <h6>Dokumen : {isShowDokPabean.uraian_intr}</h6>}
                     </Row>
                     <Row style={{marginLeft: '15px'}}>
                        {isShowDokPabean && isShowDokPabean?.kd_dokumen && <p>{isShowDokPabean.keterangan_intr} {isShowDokPabean?.ket_link ? <a href={isShowDokPabean?.ket_link} target='_blank'>{isShowDokPabean?.ket_text_link}</a> : ''}</p>}
                     </Row>
                     <Row style={{marginLeft: '15px'}}>
                        {isShowDokPabean && isShowDokPabean?.kd_dokumen && <h6>Izin yang harus dipenuhi :</h6>}
                     </Row>
                     {dataImportBorders.length !== 0 && isShowDokPabean && isShowDokPabean?.status_btn ? (
                        dataImportBorders.map((item, index) => (
                           <div
                              className={
                                 dataImportBorders.length !== index + 1
                                    ? 'lartas-container-parent'
                                    : 'lartas-container-parent'
                              }
                           >
                              
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Nama Izin</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p
                                    className="coll-lartas-hasil font-weight-bold"
                                    role={'button'}
                                    onClick={() => item.kode_instansi === '01' &&
                                       toDetail(
                                          replace(item.name),
                                          item.legal,
                                          item.id_dokumen,
                                       )
                                    }
                                 >
                                    {/* {item.name.replace(
                                       /\/ Surat Keterangan|Surat Keterangan/gi,
                                       '',
                                    )} */}

                                    <u>{replace(item.name)}</u>
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">
                                    Kode Izin Kepabeanan
                                 </p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.kd_ijin}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Komoditi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.komoditi}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Regulasi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.legal}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Deskripsi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.deskripsi}
                                 </p>
                              </div>
                           </div>
                        ))
                     ) : (
                        <div>
                           <p className="collapse-text-headsub">
                              {/* {t('empty_data')} */}
                           </p>
                        </div>
                     )}
   
                     {isShowDokPabean && isShowDokPabean?.status_btn ? (
                        <div className="lartas-container-parent">
                           <div className="coll-lartas-container">
                              <p className="coll-lartas-judul">
                                 Satuan Barang
                              </p>
                              <p className="coll-lartas-tidu">:</p>
                              <ul>
                              {dataRefSatuan && dataRefSatuan.impor && dataRefSatuan.impor.length>0 && dataRefSatuan.impor.filter(item => item.dok_pabean.includes(isShowDokPabean.kd_dokumen)).length>0 ? (
                                 dataRefSatuan.impor.filter(item => item.dok_pabean.includes(isShowDokPabean.kd_dokumen)).map((item, index) => (
                                    <li>
                                       {item.ur_satuan} ({item.kd_satuan}) --- (Regulasi : <i>{item.no_skep}</i>)
                                    </li>
                                 ))
                              ) : (
                                 <li>
                                    -
                                 </li>
                              )}
                              </ul>
                           </div>
                        </div>
                     ) : (
                        <div>
                           <p className="collapse-text-headsub">
                              {/* {t('empty_data')} */}
                           </p>
                        </div>
                     )}
                  </Collapse>
               </div>
            </>
         )
      }
      if (type === 'impor-postborder') {
         return (
            <>
               <div
                  className="collapse-header"
                  onClick={() => klikCollapse(buka)}
               >
                  <p className="collapse-text-head">
                     Regulasi Impor (Tataniaga Post Border)
                  </p>
                  <div className="collapse-icon">
                     <img
                        src={buka === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body" style={{whiteSpace: 'break-spaces'}}>
                  <Collapse isOpened={buka}>
                     <Row style={{margin: '15px'}}>
                        <h6>Silahkan dapat mengeklik dokumen pemberitahuan pabean impor dibawah ini guna memperoleh informasi yang dibutuhkan</h6>
                     </Row>
                     <Row style={{margin: '5px'}}>
                        {dataKepabeanPB.length !== 0 ? (
                           dataKepabeanPB.map((item) => (
                              <div className={item.status_btn && isShowDokPabeanPB && isShowDokPabeanPB?.kd_dokumen != item.kd_dokumen ? 'custom-kotak-dokumen-pabean-skyblue' : (item.status_btn && isShowDokPabeanPB && isShowDokPabeanPB?.kd_dokumen == item.kd_dokumen ? 'custom-kotak-dokumen-pabean-skyblue-clicked' : (!item.status_btn && isShowDokPabeanPB && isShowDokPabeanPB?.kd_dokumen == item.kd_dokumen ? 'custom-kotak-dokumen-pabean-gray-clicked' : 'custom-kotak-dokumen-pabean-gray' ))} onClick={() => toggleDokPabeanPB(item)}>
                                 {item.nm_dokumen}
                              </div>
                           ))
                        ) : ''}
                     </Row>
                     <Row style={{marginLeft: '15px'}}>
                        {isShowDokPabeanPB && isShowDokPabeanPB?.kd_dokumen && <h6>Dokumen : {isShowDokPabeanPB.uraian_intr}</h6>}
                     </Row>
                     <Row style={{marginLeft: '15px'}}>
                        {isShowDokPabeanPB && isShowDokPabeanPB?.kd_dokumen && <p>{isShowDokPabeanPB.keterangan_intr} {isShowDokPabeanPB?.ket_link ? <a href={isShowDokPabeanPB?.ket_link} target='_blank'>{isShowDokPabeanPB?.ket_text_link}</a> : ''}</p>}
                     </Row>
                     <Row style={{marginLeft: '15px'}}>
                        {isShowDokPabeanPB && isShowDokPabeanPB?.kd_dokumen && <h6>Izin yang harus dipenuhi :</h6>}
                     </Row>
                     {/* <p className="collapse-text-headsub">Data Regulasi Impor - Lartas Impor</p> */}
                     {/* <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p> */}
                     {dataImportPostBorders.length !== 0 && isShowDokPabeanPB && isShowDokPabeanPB?.status_btn ? (
                        dataImportPostBorders.map((item, index) => (
                           <div
                              className={
                                 'lartas-container-parent'
                                 // dataImportPostBorders.length !== index + 1
                                 //    ? 'lartas-container-parent'
                                 //    : 'lartas-container-parent'
                              }
                           >
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Nama Izin</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p
                                    className="coll-lartas-hasil font-weight-bold"
                                    role={'button'}
                                    onClick={() =>
                                       toDetail(
                                          replace(item.nama_ijin),
                                          item.nomor_skep,
                                          item.id_dokumen,
                                       )
                                    }
                                 >
                                    {/* {item.nama_ijin.replace(
                                       /\/ Surat Keterangan|Surat Keterangan/gi,
                                       '',
                                    )} */}
                                    <u>{replace(item.nama_ijin)}</u>
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">
                                    Kode Izin Kepabeanan
                                 </p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.modul}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Komoditi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.ur_komoditi}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Regulasi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.nomor_skep}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Deskripsi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.desc}
                                 </p>
                              </div>
                           </div>
                        ))
                     ) : (
                        <div>
                           <p className="collapse-text-headsub">
                              {/* {t('empty_data')} */}
                           </p>
                        </div>
                     )}
                     {isShowDokPabeanPB && isShowDokPabeanPB?.status_btn ? (
                        <div className="lartas-container-parent">
                           <div className="coll-lartas-container">
                              <p className="coll-lartas-judul">
                                 Satuan Barang
                              </p>
                              <p className="coll-lartas-tidu">:</p>
                              <ul>
                              {dataRefSatuan && dataRefSatuan.impor && dataRefSatuan.impor.length>0 && dataRefSatuan.impor.filter(item => item.dok_pabean.includes(isShowDokPabeanPB.kd_dokumen)).length>0 ? (
                                 dataRefSatuan.impor.filter(item => item.dok_pabean.includes(isShowDokPabeanPB.kd_dokumen)).map((item, index) => (
                                    <li>
                                       {item.ur_satuan} ({item.kd_satuan}) --- (Regulasi : <i>{item.no_skep}</i>)
                                    </li>
                                 ))
                              ) : (
                                 <div>
                                    <li>
                                       -
                                    </li>
                                 </div>
                              )}
                              </ul>
                           </div>
                        </div>
                     ) : (
                        <div>
                           <p className="collapse-text-headsub">
                              {/* {t('empty_data')} */}
                           </p>
                        </div>
                     )}
                  </Collapse>
               </div>
            </>
         )
      }
      if (type === 'ekspor') {
         return (
            <>
               <div
                  className="collapse-header"
                  onClick={() => klikCollapse(buka)}
               >
                  <p className="collapse-text-head">
                     Regulasi Ekspor - Lartas Ekspor
                  </p>
                  <div className="collapse-icon">
                     <img
                        src={buka === true ? ICCollapseUp : ICCollapseDown}
                        alt="icon-collapse"
                     />
                  </div>
               </div>
               <div className="collapse-body">
                  <Collapse isOpened={buka}>
                     {/* <p className="collapse-text-headsub">Data Regulasi Ekspor - Lartas Ekspor</p> */}
                     {/* <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p>
                        <p className="collapse-text">Collapse Body</p> */}
                     {dataExport.length !== 0 ? (
                        dataExport.map((item, index) => (
                           <div
                              className={'lartas-container-parent'}
                              //    dataExport.length !== index + 1
                              //       ? 'lartas-container-parent'
                              //       : 'lartas-container-parent'
                              // }
                           >
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Nama Izin</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p
                                    className="coll-lartas-hasil font-weight-bold"
                                    role={'button'}
                                    onClick={() =>
                                       toDetail(
                                          replace(item.name),
                                          item.legal,
                                          item.id_dokumen,
                                       )
                                    }
                                 >
                                    {/* {item.name.replace(
                                       /\/ Surat Keterangan|Surat Keterangan/gi,
                                       '',
                                    )} */}
                                    <u>{replace(item.name)}</u>{' '}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">
                                    Kode Izin Kepabeanan
                                 </p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.modul}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Komoditi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.komoditi}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Regulasi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.legal}
                                 </p>
                              </div>
                              <div className="coll-lartas-container">
                                 <p className="coll-lartas-judul">Deskripsi</p>
                                 <p className="coll-lartas-tidu">:</p>
                                 <p className="coll-lartas-hasil">
                                    {item.deskripsi}
                                 </p>
                              </div>
                           </div>
                        ))
                     ) : (
                        <div>
                           <p className="collapse-text-headsub">
                              {t('empty_data')}
                           </p>
                        </div>
                     )}

                     <div className="lartas-container-parent">
                        <div className="coll-lartas-container">
                           <p className="coll-lartas-judul">
                              Satuan Barang
                           </p>
                           <p className="coll-lartas-tidu">:</p>
                           <ul>
                           {dataRefSatuan && dataRefSatuan.ekspor && dataRefSatuan.ekspor.length>0 ? (
                              dataRefSatuan.ekspor.map((item, index) => (
                                 <li>
                                    {item.ur_satuan} ({item.kd_satuan}) --- (Regulasi : <i>{item.no_skep}</i>)
                                 </li>
                              ))
                           ) : (
                              <div>
                                 <p className="collapse-text-headsub">
                                    -
                                 </p>
                              </div>
                           )}
                           </ul>
                        </div>
                     </div>
                  </Collapse>
               </div>
            </>
         )
      }
      if (type === 'regulasi-khusus') {
         return (""
            // <>
            //    <div
            //       className="collapse-header"
            //       onClick={() => klikCollapse(buka)}
            //    >
            //       <p className="collapse-text-head">Kondisi Khusus Regulasi</p>
            //       <div className="collapse-icon">
            //          <img
            //             src={buka === true ? ICCollapseUp : ICCollapseDown}
            //             alt="icon-collapse"
            //          />
            //       </div>
            //    </div>
            //    <div className="collapse-body">
            //       <Collapse isOpened={buka}>
            //          <div className="lartas-container-parent">
            //             {header.kondisiTertentu === false ? (
            //                <div className="coll-lartas-container">
            //                   <p className="coll-lartas-judul">
            //                      Instrumen Pengecualian
            //                   </p>
            //                   <p className="coll-lartas-tidu">:</p>
            //                   <p className="coll-lartas-hasil">Tidak Ada</p>
            //                </div>
            //             ) : (
            //                <div className="coll-lartas-container">
            //                   <p className="coll-lartas-judul">
            //                      Instrumen Pengecualian
            //                   </p>
            //                   <p className="coll-lartas-tidu">:</p>
            //                   <p className="coll-lartas-hasil">Ada</p>
            //                </div>
            //             )}
            //          </div>
            //       </Collapse>
            //    </div>
            // </>
         )
      }
   }

   return (
      <div className="collapse-container">
         <TypeColl />
      </div>
   )
}

export default CollapseComp
