import axios from 'axios'
import parse from 'html-react-parser'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Popup from 'reactjs-popup'
import {
   CardProfil,
   CardProfile,
   ListEmpty,
   LoadProgress,
} from '../../../components'
import * as Api from '../../../constants/api'
import './profil.scss'

const responsive = {
   desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
   },
   tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
   },
   mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
   },
}

const CustomDot = ({ onMove, index, onClick, active }) => {
   // onMove means if dragging or swiping in progress.
   // active is provided by this lib for checking if the item is active or not.
   return (
      <li
         className={active ? 'active-caro' : 'inactive-caro'}
         onClick={() => onClick()}
      >
         {/* {index + 1} */}
      </li>
   )
}

// const CustomRight = ({ onClick }) => (
//   <button className="arrow right" onClick={onClick}>
//     Right
//   </button>
// );
// const CustomLeft = ({ onClick }) => (
//   <button className="arrow left" onClick={onClick}>
//     Left
//   </button>
// );
//Also please improve the dot problem
// Here we used number to just know click event buthelp us with ui too

const Profil = ({ deviceType }) => {
   const [is_progress, setisProgress] = useState(true)
   const [error_message, setErrorMessage] = useState('')
   const [isOpen, setIsOpen] = useState(false)
   const [judul, setJudul] = useState([])
   const [open, setOpen] = useState(false)
   const closeModal = () => setOpen(false)

   const contentStyle = {
      maxWidth: '1000px',
      width: '100%',
      height: '100%',
   }

   const CallImage = (image_url) => {
      // var replace_url = image_url.substring(1);
      var url_image = Api.IP_KONG_LNSW + image_url.slice(1)
      // console.log(url_image);
      return url_image
   }
   //variable constant
   const [profil_data, setProfilData] = useState([])
   const [profil_detail, setProfilDetail] = useState([])

   const toggle = () => setIsOpen(!isOpen)
   const { t } = useTranslation()

   //did mount
   useEffect(() => {
      if (localStorage.getItem('language') === null) {
         localStorage.setItem('language', 'id')
      }
      _get_data_profil()
      _get_data_judul()
   }, [])

   const parseHtml = (html) => {
      // console.log('html');
      // console.log(html)
      var text_html = ''
      if (html !== undefined) {
         text_html = parse(html)
      }
      // var text_html = parse(html.toString());

      return text_html
   }

   const _get_data_judul = () => {
      let idMenu = localStorage.getItem('menu_select')
      if (idMenu === null || idMenu !== 24) {
         idMenu = 24
      }
      const url =
         Api.JUDUL +
         idMenu +
         '&lang=' +
         localStorage.getItem('language').toUpperCase()
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            if (response.data.code === '01') {
               setJudul(response.data.data)
            } else {
               setJudul([])
            }
         })
         .catch((error) => {
            setJudul([])
         })
   }

   const _get_data_profil = () => {
      setisProgress(true)
      const url =
         Api.DETAIL_PROFIL +
         '?lang=' +
         localStorage.getItem('language').toUpperCase()
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgress(false)
            // console.log("detail_profil", response.data.data);
            // console.log("detail_profil2", response.data.data[0].posisi_img);
            // console.log("detail_profil3", response.data.data[0]);
            if (response.data.code === '01') {
               if (response.data.data[0].posisi_img == '1') {
                  // console.log('datax')
                  setProfilData(response.data.data)
               } else {
                  setProfilDetail(response.data.data)
               }
            } else {
               setProfilData([])
               setProfilDetail([])
               setErrorMessage(t('empty_list'))
            }
         })
         .catch((error) => {
            setisProgress(false)
            console.log('error')
            console.log(error)
            setProfilData([])
            setProfilDetail([])
            setErrorMessage(t('error_message'))
         })
   }

   return (
      <div>
         <div className="profil-background col-md-12 p-0">
            {judul.map((item, index) => (
               <>
                  <div className="profil-background-part">
                     <img src={CallImage(item.nama_img)} alt="bg" />
                  </div>
                  <div className="profil-content-title">
                     <div className="profil-content-sub-title">
                        <div className="profil-text-title">
                           {parseHtml(item.header)}
                        </div>
                        <div className="profil-text-message">
                           {parseHtml(item.ur)}
                        </div>
                     </div>
                  </div>
               </>
            ))}
         </div>
         {is_progress === true ? (
            <div
               style={{ height: '25vw' }}
               className="search-content-load-propim col-md-12"
            >
               <LoadProgress />
            </div>
         ) : profil_data.length === 0 ? (
            <div className="search-content-load-propim col-md-12">
               <ListEmpty message_error={error_message} />
            </div>
         ) : (
            <>
               <div className="profil-sub-menu row justify-content-center m-0 py-3 mt-3">
                  {profil_data.slice(0, 1).map((item, index) => (
                     <Popup
                        trigger={
                           <div className="col-md-2 p-0">
                              <CardProfil
                                 key={index}
                                 gambar={CallImage(item.nama_img)}
                                 deskripsi={item.ur_jabatan}
                                 judul={item.nama_profil}
                              />
                           </div>
                        }
                        modal
                        position="center center"
                        closeOnDocumentClick
                        onClick={toggle}
                        contentStyle={contentStyle}
                     >
                        {(close) => (
                           <div className="sub-profil col-md-12">
                              <p
                                 className="close"
                                 style={{
                                    top: '-13%',
                                    right: '8%',
                                 }}
                                 onClick={close}
                              >
                                 &times;
                              </p>
                              <CardProfile
                                 gambar={CallImage(item.nama_img)}
                                 judul={item.nama_profil}
                                 header={item.ur_jabatan}
                                 deskripsi={parseHtml(item.ur_riwayat)}
                              />
                           </div>
                        )}
                     </Popup>
                  ))}
               </div>
               <div className="profil-sub-menu row justify-content-center m-0 py-3">
                  {profil_data.slice(1, 10).map((item, index) => (
                     <Popup
                        trigger={
                           <div className="col-md-2 p-0">
                              <CardProfil
                                 key={index}
                                 gambar={CallImage(item.nama_img)}
                                 deskripsi={item.ur_jabatan}
                                 judul={item.nama_profil}
                              />
                           </div>
                        }
                        modal
                        position="center center"
                        closeOnDocumentClick
                        onClick={toggle}
                        contentStyle={contentStyle}
                     >
                        {(close) => (
                           <div className="sub-profil col-md-12">
                              <p
                                 className="close"
                                 style={{
                                    top: '-18%',
                                    right: '8%',
                                 }}
                                 onClick={close}
                              >
                                 &times;
                              </p>
                              <CardProfile
                                 gambar={CallImage(item.nama_img)}
                                 judul={item.nama_profil}
                                 header={item.ur_jabatan}
                                 deskripsi={parseHtml(item.ur_riwayat)}
                              />
                           </div>
                        )}
                     </Popup>
                  ))}
               </div>
            </>
         )}
      </div>
   )
}

export default Profil
