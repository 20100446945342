import './index.scss';
import axios from 'axios';
import moment from 'moment';
import { Image } from 'react-bootstrap';
import * as Api from '../../../constants/api';
import React, { useState, useEffect } from 'react';
import { IconKalender, IconPesawatKertas } from '../../../assets';

const Agenda = () => {

    const [agenda, setAgenda] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        _get_data_agenda_lnsw();
    }, []);

    const sanitizeLink = (input)=> {
        const link1 = input.match("http://");
        const link2 = input.match("https://")
        const link = []
        
        if(!link1 || !link2){
            link.push(`https://${input}`)
        }else if(link1){
            link.push(`http://${input.split("//")[1]}`)
        }else if(link2){
            link.push(`https://${input.split("//")[1]}`)
        }

        console.log(link[0]);
    
        return link[0];
    }

    const _get_data_agenda_lnsw = () => {
        const url = Api.AGENDA_LNSW;
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    let data_agenda = response.data.data
                    setAgenda(data_agenda);
                } else {
                    setAgenda([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setAgenda([]);
            });
    }

    return (
        <>
            <div className="custom-container">
                <div className="col-md-12">
                    <div className="sm-content text-center">
                        <h3 className="sm-title">
                            Agenda
                        </h3>
                    </div>
                </div>
                <div className="text-left sm-content-list row m-0" >
                    <div className="col-md-12 part-agenda">
                        <div className="row-cols-1 d-flex flex-column">
                            {agenda.map((item) =>
                                <div className="card border border-warning py-5 px-5">
                                    <div className="content-title">
                                        {item.header}
                                    </div>
                                    <div className="content">
                                        {item.kegiatan}
                                    </div>
                                    <div className="content">
                                        <Image src={IconKalender} width={20} height={20} /> {moment(item.tgl_awal).format("DD MMMM YYYY")} {moment(item.tgl_akhir).format("DD MMMM YYYY") !== moment(item.tgl_awal).format("DD MMMM YYYY") ? "- " + moment(item.tgl_akhir).format("DD MMMM YYYY") : " "}
                                    </div>
                                    {
                                        item.is_online === true ?
                                            <div className="content pt-5">
                                                <a 
                                                    href={sanitizeLink(item.link_acara)} 
                                                    target='_blank' 
                                                    className='text-primary'
                                                >
                                                    <Image src={IconPesawatKertas} width={20} height={20} /> 
                                                    Link Acara
                                                </a>
                                            </div>
                                            : ""
                                    }
                                    {
                                        item.ur_negara === "INDONESIA" && item.is_online === false &&
                                        <div className="content">
                                            <Image src={IconPesawatKertas} width={20} height={20} /> {item.ur_provinsi}, {item.ibu_kota}
                                        </div>
                                    }
                                    {item.ur_negara !== "INDONESIA" && item.ur_negara !== "" && item.is_online === false &&
                                        <div className="content">
                                            <Image src={IconPesawatKertas} width={20} height={20} /> {item.ur_negara}
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Agenda;
