import React, { useEffect, useState } from 'react';
import './ketentuanKomoditas.scss';
import parse from 'html-react-parser';
import dataHtml from './dataHtml';

const KetentuanKomoditas = () => {
    const dataQ = dataHtml();

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    return (
        <div className="ketentuan-container">
            <div className="ketentuan-content">
                <div className="ketentuan-content-header">
                    <div className="ketentuan-content-title">Ketentuan Umum Untuk Menginterpretasi Harmonized System</div>
                </div>
                <div className="ketentuan-content-body">
                    {parseHtml(dataQ)}
                </div>
            </div>
        </div>
    )
}

export default KetentuanKomoditas;

