/* eslint-disable jsx-a11y/alt-text */
//import lib react
import './search_peraturan.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import GridList from 'react-gridlist';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

//component
import { LogoKarantinaIkan } from '../../../../assets';
import * as Api from '../../../../constants/api';
import { ListSetting, LoadProgress, ListEmpty } from '../../../../components';
import { InputSearch } from '../../../../components/atoms';

const SearchPeraturan = () => {
	//variable constant
	const { t } = useTranslation();
	const [is_progress_search_peraturan, setisProgressSearchPeraturan] =
		useState(true);
	const [search_list_peraturan, setSearchListPeraturan] = useState([]);
	const [search_list_peraturan_data, setSearchListPeraturanData] = useState([]);
	const [error_message_search_peraturan, setErrorMessageSearchPeraturan] =
		useState('');
	const location = useLocation();
	const history = useHistory();
	var route_search_pencarian = '';
	if (location.state === undefined) {
		route_search_pencarian = '';
	} else {
		route_search_pencarian = location.state.search_peraturan;
	}
	const [search_peraturan, setSearchPeraturan] = useState(
		route_search_pencarian
	);
	const perPage = 6;
	const [pageCount, setPageCount] = useState('');

	//style
	let styles = {
		container: {
			flex: 1,
		},
	};

	// did mount
	useEffect(() => {
		setTimeout(() => {
			_get_data_peraturan();
		}, 500);
	}, []);

	//function
	const filter_input = (input_text) => {
		let filter_text = input_text.replace(/\'|\"|\--/g, '');
		return filter_text;
	};

	const _get_data_peraturan = () => {
		setisProgressSearchPeraturan(true);
		const url = Api.PERATURAN_SEARCH + filter_input(search_peraturan);
		// console.log(url);
		axios({
			method: 'get',
			url: url,
			headers: { Authorization: Api.BASIC_AUTH },
			timeout: 1000 * 10, // Wait for 10 seconds
		})
			.then((response) => {
				setisProgressSearchPeraturan(false);
				if (response.data.code === '01') {
					if (response.data.data.length === 0) {
						setSearchListPeraturan([]);
						setSearchListPeraturanData([]);
						setErrorMessageSearchPeraturan(t('empty_list'));
					} else {
						// console.log(response.data);
						let data_list_peraturan = response.data.data;
						setSearchListPeraturanData(data_list_peraturan);
						const slice = data_list_peraturan.slice(0, perPage);
						setPageCount(data_list_peraturan.length / perPage);
						setSearchListPeraturan(slice);
					}
				} else {
					setSearchListPeraturan([]);
					setSearchListPeraturanData([]);
					setErrorMessageSearchPeraturan(t('empty_list'));
				}
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
				setisProgressSearchPeraturan(false);
				setSearchListPeraturan([]);
				setSearchListPeraturanData([]);
				setErrorMessageSearchPeraturan(t('error_message'));
			});
	};

	const getGridGap = (elementWidth, windowHeight) =>
		elementWidth > 720 && windowHeight > 480 ? 10 : 5;

	const getColumnCount = (elementWidth) => {
		const width = window.innerWidth;
		if (width < 600) {
			return Math.floor(elementWidth / 300); //pembagian column (dibagi untuk 4 column)
		} else {
			return Math.floor(elementWidth / 500); //pembagian column (dibagi untuk 2 column)
		}
	};

	const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.1);

	const getItemData = (list_peraturan, columnWidth) => {
		// let adjustedHeight = Math.round(columnWidth * 0.25)
		return {
			key: list_peraturan.id,
			// height: adjustedHeight,
		};
	};

	const handlePageClick = (e) => {
		const selectedPage = e.selected;
		const offset = selectedPage * perPage;

		loadMoreData(offset, offset + perPage);
	};

	const loadMoreData = (of, op) => {
		const data = search_list_peraturan_data;

		const slice = data.slice(of, op);
		setPageCount(data.length / perPage);
		setSearchListPeraturan(slice);
	};

	// const handleKeyDown = (event) => {
	//     if (event.key === 'Enter') {
	//         // console.log('peraturan', event.target.value);
	//         _get_data_peraturan();
	//     }
	// }

	return (
		<div>
			<div className='search-peraturan-background'>
				<div className='search-peraturan-content-title'>
					<div className='search-peraturan-content-sub-title'>
						<div className='search-peraturan-text-title'>
							{t('title_cover_peraturan')}
						</div>
						<div className='search-peraturan-text-message'>
							{t('desc_cover_peraturan')}
						</div>
						<div className='search-peraturan-input-search'>
							<div className='col-md-6'>
								<InputSearch
									value={search_peraturan}
									placeholder={t('look_the_rules')}
									onChange={(e) => {
										setSearchPeraturan(e.target.value);
									}}
									onClick={() => _get_data_peraturan()}
									onKeyDown={() => _get_data_peraturan()}
								// onKeyDown={handleKeyDown}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='search-peraturan-content'>
				<div className='search-peraturan-content-title'>
					<div>{t('search_result')}</div>
				</div>

				<div style={{ display: 'flex' }}>
					{is_progress_search_peraturan === true ? (
						<div
							style={{ height: '25vw' }}
							className='search-peraturan-content-load'
						>
							<LoadProgress />
						</div>
					) : search_list_peraturan.length === 0 ? (
						<div className='search-peraturan-content-load'>
							<ListEmpty message_error={error_message_search_peraturan} />
						</div>
					) : (
						<div style={{ flex: 1 }}>
							<div style={styles.container}>
								<GridList
									items={search_list_peraturan}
									getGridGap={getGridGap}
									getColumnCount={getColumnCount}
									getWindowMargin={getWindowMargin}
									getItemData={getItemData}
									renderItem={(item) => {
										return (
											<div className='search-peraturan-content-item-grid'>
												<ListSetting
													onClick={() =>
														history.push(
															'/intr/peraturan/cari-peraturan/detail-peraturan',
															{ data_peraturan: item }
														)
													}
													image={item.ga_logo}
													nomor={item.nomor_peraturan}
													bidang={item.bidang_peraturan}
													title={item.judul_peraturan}
													desc={item.keterangan}
													date={moment(item.tanggal_peraturan).format(
														'DD MMMM YYYY'
													)}
												/>
											</div>
										);
									}}
								/>
							</div>
							<div className='search-peraturan-content-list'>
								<ReactPaginate
									previousLabel={t('previous')}
									nextLabel={t('next')}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={pageCount}
									marginPagesDisplayed={2}
									pageRangeDisplayed={5}
									onPageChange={handlePageClick.bind(this)}
									containerClassName={'pagination'}
									subContainerClassName={'pages pagination'}
									activeClassName={'active'}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default SearchPeraturan;
