import React, { useState, useEffect } from 'react';
import './index.scss';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const FAQDetail = () => {
    const [data, setData] = useState([]);
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        setData(location.state)
    }, []);

    const parseHtml = (html) => {
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    return (
        <>
            <div className="pusat-bantuan-faq-detail row m-0">
                <div className="col-md-12">
                    <div className="pb-content">
                        <h3 className="pb-title">
                            {t("pusat-bantuan-faq")}

                        </h3>
                        <div className="row m-0 justify-content-center">
                            <p className="col-md-6">
                                {t("pusat-bantuan-faq-sub")}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="pb-content-list-faq col-md-12">
                    <div className="card">
                        <div className="border-bottom">
                            <div className="titles">
                                {parseHtml(data.header)}
                            </div>
                            <div className="content">
                                {parseHtml(data.ur)}
                            </div>
                        </div>
                        <div className="pt-3">
                            <div className="titles">
                                Jawaban
                            </div>
                            <div className="content">
                                {parseHtml(data.jawaban)}

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default FAQDetail;
