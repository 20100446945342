/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './list_empty_detail.scss';
import { ICListData2, ImageNotFound } from '../../../assets';
import { useTranslation } from 'react-i18next';

const ListEmpty = ({message_error}) => {
    const { t } = useTranslation();
    
    return (<div style={{ marginTop: "20px"}}></div>
        // <div className="list-empty-detail">
        //     <div className="list-empty-content">
        //         <img className="list-empty-content-image" src={ICListData2} />
        //         <div className="list-empty-content-text">{message_error}</div>
        //         {/* <div className="list-empty-content-text">{t('data_not_found')}</div> */}
        //     </div>
        // </div> 
    )
}

export default ListEmpty;
