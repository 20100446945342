/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import './input_date_round.scss';
import { ICCalendarGray } from '../../../assets';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const InputDateRound = ({Date, isDate, onChange, placeHolder, maxDate}) => {

    return (
        <div className="input-date-round">
            <DatePicker 
                className="input-date-round-field"
                maxDate={maxDate}
                selected={Date}
                disabled={isDate}
                onChange={onChange} 
                dateFormat="dd MMM yyyy"
                placeholderText={placeHolder}
            />
            <div className="input-date-round-icon">
                <img src={ICCalendarGray} />
            </div>
        </div>
    )
}

export default InputDateRound;
