import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Col, FormGroup, Input, Label, Row } from 'reactstrap'
import ReactPaginate from 'react-paginate'
import * as Api from '../../../../constants/api'
import {
   dataDummy,
   dokumenSyarat,
   jenisApi,
   komoditiDiatur,
   Pelabuhan,
   Satuan,
   tipeTrader,
   valueJenisApi,
   valueTipeTrader,
} from './data'
import './perizinan.scss'

const DetailPerizinan = () => {
   const location = useLocation()
   const history = useHistory()
   const { t } = useTranslation()
   const perPage = 5
   // Data
   const [dataDokumenSyarat, setDataDokumenSyarat] = useState([])
   const [dataReg, setDataReg] = useState([])
   const [valKomoditi, setValKomoditi] = useState([])
   const [valKomoditiSlice, setValKomoditiSlice] = useState([])

   // Value
   const [valApi, setValApi] = useState([])
   const [valTipe, setValTipe] = useState([])
   const [valSatuan, setValSatuan] = useState([])
   const [valPelabuhan, setValPelabuhan] = useState([])
   const [valReg, setValReg] = useState(null)
   const [valRow, setValRow] = useState(null)

   // Pagination
   const [pageCountKomoditi, setPageCountKomoditi] = useState('')

   let nameRegulasi = ''
   let legal = ''
   let idDokumen = ''
   if (location.state.regulasi) {
      nameRegulasi = location.state.nama_izin
      legal = location.state.regulasi
      idDokumen = location.state.idDokumen
   } else {
      history.push('/intr/detail-komoditas', {
         data: {
            lartas: '',
         },
      })
   }
   // Fetches data from the server
   useEffect(() => {
      if (idDokumen) {
         let url = Api.KOMODITAS_DETAIL_PERIZINAN + idDokumen
         axios({
            method: 'GET',
            url: url,
            headers: { Authorization: Api.BASIC_AUTH },
            timeout: 1000 * 10, //10 seconds
         })
            .then((response) => {
               if (response.data.data.length > 0) {
                  setDataReg(response.data.data)
               } else {
                  setDataReg([])
               }
            })
            .catch((error) => {
               console.log(error)
            })
      }
   }, [])

   useEffect(() => {
      if (valReg) {
         getJenisApiTipeTrader()
         getKdSyarat()
         getKomoditiDiatur()
      }
   }, [valReg])

   const getJenisApiTipeTrader = () => {
      let url = Api.DETAIL_PERIZINAN_JENIS_API_TIPE_TRADER + valReg
      axios({
         method: 'GET',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, //10 seconds
      })
         .then((response) => {
            if (response.data.code === '01') {
               setValApi(
                  response.data.data.dataJenisApi.map(
                     (item) => item.kdJenisApi,
                  ),
               )
               setValTipe(
                  response.data.data.dataTipeTrader.map(
                     (item) => item.kdJenisTrader,
                  ),
               )
            }
         })
         .catch((error) => {
            return error
         })
   }
   const getKdSyarat = () => {
      let url = Api.DETAIL_PERIZINAN_KD_SYARAT + valReg
      axios({
         method: 'GET',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, //10 seconds
      })
         .then((response) => {
            if (response.data.code === '01') {
               setDataDokumenSyarat(response.data.data)
            }
         })
         .catch((error) => {
            return error
         })
   }
   const getKomoditiDiatur = () => {
      let url =
         Api.DETAIL_PERIZINAN_KOMODITI_DIATUR + valReg + '&size=200&from=0'
      axios({
         method: 'GET',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, //10 seconds
      })
         .then((response) => {
            if (response.data.code === '01') {
               let data_list = response.data.data
               setValKomoditi(data_list)
               const slice = data_list.slice(0, perPage)
               setPageCountKomoditi(data_list.length / perPage)
               setValKomoditiSlice(slice)
            }
         })
         .catch((error) => {
            return error
         })
   }

   const getSatuan = (hs) => {
      let url =
         Api.DETAIL_PERIZINAN_SATUAN +
         `kdHs=${hs}&jnsPerijinan=1&kd_layanan=${valReg}`
      axios({
         method: 'GET',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, //10 seconds
      })
         .then((response) => {
            if (response.data.code === '01') {
               setValSatuan(response.data.data)
            }
         })
         .catch((error) => {
            return error
         })
   }

   // Formatting data for the table

   const formatHs = (cell, row) => {
      return (
         <div className="d-flex justify-content-center">
            <div
               style={{
                  backgroundColor: '#ffb300',
                  borderRadius: '50px',
                  width: '140px',
                  height: '39px',
                  textAlign: 'center',
                  cursor: 'pointer',
               }}
               onClick={() => {
                  history.push('/intr/detail-komoditas', {
                     data: {
                        lartas: row.hs_code,
                     },
                  })
               }}
            >
               <p
                  className="pt-2"
                  style={{
                     color: '#000',
                     fontWeight: 'bold',
                  }}
               >
                  {row.hs_code}
               </p>
            </div>
         </div>
      )
   }

   // Function all component

   const handleRadio = (e) => {
      setValReg(e.target.value)
      setValKomoditi([])
      setValKomoditiSlice([])
   }
   const handlePageClick = (e) => {
      const selectedPage = e.selected
      const offset = selectedPage * perPage

      loadMoreData(offset, offset + perPage)
   }
   const loadMoreData = (of, op) => {
      const data = valKomoditi

      const slice = data.slice(of, op)
      setPageCountKomoditi(data.length / perPage)
      setValKomoditiSlice(slice)
   }
   const isExpandableRow = (row) => {
      if (row.hs_code === valRow) {
         return true
      } else {
         return false
      }
   }

   const expandComponent = () => {
      return (
         <Row>
            <Col xs={12} md={6}>
               <div className="border p-4 rounded border-luar">
                  <p className="font-weight-bold">Satuan</p>
                  <p className="border-sub-judul" />
                  <div className="px-4">
                     <BootstrapTable
                        data={valSatuan}
                        tableHeaderClass="table-header-perizinan"
                     >
                        <TableHeaderColumn
                           dataField="kd_satuan"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           tdStyle={{
                              whiteSpace: 'normal',
                              textAlign: 'center',
                              fontWeight: 'bold',
                           }}
                           isKey
                        >
                           Kode Satuan
                        </TableHeaderColumn>
                        <TableHeaderColumn
                           dataField="ur_satuan"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           tdStyle={{
                              whiteSpace: 'normal',
                              textAlign: 'center',
                           }}
                        >
                           Uraian Satuan
                        </TableHeaderColumn>
                     </BootstrapTable>
                  </div>
               </div>
            </Col>
            <Col xs={12} md={6}>
               <div className="border p-4 rounded border-luar">
                  <p className="font-weight-bold">Pelabuhan</p>
                  <p className="border-sub-judul" />
                  <div className="px-4">
                     <BootstrapTable
                        data={valPelabuhan}
                        tableHeaderClass="table-header-perizinan"
                     >
                        <TableHeaderColumn
                           dataField="kode"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           tdStyle={{
                              whiteSpace: 'normal',
                              textAlign: 'center',
                              fontWeight: 'bold',
                           }}
                           isKey
                        >
                           Kode Pelabuhan
                        </TableHeaderColumn>
                        <TableHeaderColumn
                           dataField="ur"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           tdStyle={{ whiteSpace: 'normal' }}
                        >
                           Uraian Satuan
                        </TableHeaderColumn>
                     </BootstrapTable>
                  </div>
               </div>
            </Col>
         </Row>
      )
   }
   const funcExpand = (value, isExpand) => {
      if (isExpand) {
         setValRow(value)
         getSatuan(value)
      }
   }
   const options = {
      onExpand: funcExpand,
   }
   return (
      <div className="perizinan-wrapper">
         <div>
            <p className="perizinan-title mb-5">{t('detail-perizinan')}</p>
            <p className="perizinan-sub-title ml-5">{nameRegulasi}</p>
            <hr />
            <p className="perizinan-title-desc mt-5">Regulasi : </p>
            <p className="mb-5">{legal}</p>
         </div>
         <Row className={'mb-5'}>
            <Col xs={12} md={6}>
               <div className="border p-4 rounded border-luar mb-4">
                  <p className="font-weight-bold">{nameRegulasi}</p>
                  <p className="border-sub-judul" />
                  {dataReg.map((item, index) => (
                     <>
                        <div className="px-4">
                           <FormGroup>
                              <Label check>
                                 <Input
                                    type="radio"
                                    name={nameRegulasi}
                                    value={item.idDokumen}
                                    className="perizinan-radio"
                                    onClick={(e) => {
                                       handleRadio(e)
                                    }}
                                 />{' '}
                                 {item.urDokumen}
                              </Label>
                           </FormGroup>
                        </div>
                        {index !== dataReg.length - 1 && <hr />}
                     </>
                  ))}
               </div>
               <div className="border p-4 rounded border-luar">
                  <p className="font-weight-bold">Dokumen Syarat</p>
                  <p className="border-sub-judul" />
                  {dataDokumenSyarat.map((item, index) => (
                     <>
                        <div className="px-4">
                           <FormGroup>
                              <Label check>{item.uraian_dokumen}</Label>
                           </FormGroup>
                        </div>
                        {index !== dataDokumenSyarat.length - 1 && <hr />}
                     </>
                  ))}
               </div>
            </Col>
            <Col xs={12} md={1} />
            <Col xs={12} md={5}>
               <div className="border p-4 rounded border-luar mb-4">
                  <p className="font-weight-bold">Jenis API</p>
                  <p className="border-sub-judul" />
                  <div className="px-4">
                     {jenisApi.map((item) => (
                        <FormGroup>
                           <Label key={item.id} check>
                              <Input
                                 type="checkbox"
                                 name={nameRegulasi}
                                 checked={
                                    valApi.find((val) => val === item.id) ===
                                    item.id
                                 }
                                 className="perizinan-checkbox"
                              />
                              {item.id} - {item.content}
                           </Label>
                        </FormGroup>
                     ))}
                  </div>
               </div>
               <div className="border p-4 rounded border-luar">
                  <p className="font-weight-bold">Tipe Trader</p>
                  <p className="border-sub-judul" />
                  <div className="px-4">
                     {tipeTrader.map((item) => (
                        <FormGroup>
                           <Label key={item.id} check>
                              <Input
                                 type="checkbox"
                                 name={nameRegulasi}
                                 checked={
                                    valTipe.find((val) => val === item.id) ===
                                    item.id
                                 }
                                 className="perizinan-checkbox"
                              />
                              {item.id} - {item.content}
                           </Label>
                        </FormGroup>
                     ))}
                  </div>
               </div>
            </Col>
         </Row>
         <Row className={'mb-5'}>
            <Col xs={12} md={12}>
               <div className="border p-4 rounded border-luar">
                  <p className="font-weight-bold">Komoditi yang diatur</p>
                  <p className="border-sub-judul" />
                  <div className="px-4">
                     <BootstrapTable
                        data={valKomoditiSlice}
                        tableHeaderClass="table-header-perizinan"
                        options={options}
                        expandableRow={isExpandableRow}
                        expandComponent={expandComponent}
                     >
                        <TableHeaderColumn
                           dataField="hs_code"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           dataFormat={formatHs}
                           tdStyle={{ whiteSpace: 'normal' }}
                           isKey
                        >
                           HS Code
                        </TableHeaderColumn>
                        <TableHeaderColumn
                           dataField="uraian_btki"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           tdStyle={{ whiteSpace: 'normal' }}
                        >
                           Uraian BTKI
                        </TableHeaderColumn>
                        <TableHeaderColumn
                           dataField="uraian_barang_peraturan"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           tdStyle={{ whiteSpace: 'normal' }}
                        >
                           Uraian Barang Peraturan
                        </TableHeaderColumn>
                     </BootstrapTable>
                     {valKomoditiSlice.length > 0 && (
                        <div className="komoditas-hasil-konten-bawah">
                           <ReactPaginate
                              previousLabel={'Sebelumnya'}
                              nextLabel={'Selanjutnya'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={pageCountKomoditi}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageClick.bind(this)}
                              containerClassName={'pagination'}
                              subContainerClassName={'pages pagination'}
                              activeClassName={'active'}
                           />
                        </div>
                     )}
                  </div>
               </div>
            </Col>
         </Row>
         {/* <Row>
            <Col xs={12} md={6}>
               <div className="border p-4 rounded border-luar">
                  <p className="font-weight-bold">Satuan</p>
                  <p className="border-sub-judul" />
                  <div className="px-4">
                     <BootstrapTable
                        data={valSatuan}
                        tableHeaderClass="table-header-perizinan"
                     >
                        <TableHeaderColumn
                           dataField="kode"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           tdStyle={{
                              whiteSpace: 'normal',
                              textAlign: 'center',
                              fontWeight: 'bold',
                           }}
                           isKey
                        >
                           Kode Satuan
                        </TableHeaderColumn>
                        <TableHeaderColumn
                           dataField="ur"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           tdStyle={{ whiteSpace: 'normal' }}
                        >
                           Uraian Satuan
                        </TableHeaderColumn>
                     </BootstrapTable>
                  </div>
               </div>
            </Col>
            <Col xs={12} md={6}>
               <div className="border p-4 rounded border-luar">
                  <p className="font-weight-bold">Pelabuhan</p>
                  <p className="border-sub-judul" />
                  <div className="px-4">
                     <BootstrapTable
                        data={valPelabuhan}
                        tableHeaderClass="table-header-perizinan"
                     >
                        <TableHeaderColumn
                           dataField="kode"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           tdStyle={{
                              whiteSpace: 'normal',
                              textAlign: 'center',
                              fontWeight: 'bold',
                           }}
                           isKey
                        >
                           Kode Pelabuhan
                        </TableHeaderColumn>
                        <TableHeaderColumn
                           dataField="ur"
                           thStyle={{
                              textAlign: 'center',
                           }}
                           tdStyle={{ whiteSpace: 'normal' }}
                        >
                           Uraian Satuan
                        </TableHeaderColumn>
                     </BootstrapTable>
                  </div>
               </div>
            </Col>
         </Row> */}
      </div>
   )
}

export default DetailPerizinan
