import cookie from 'react-cookies'

import {
   _doEncryptObj,
   _doDecryptObj,
   doEncryptId,
   doDecryptId,
} from '../encryption/encryption'
const assign = require('lodash/assign')
const _ = require('lodash')

export const getBasicToken = ()=>getCookie("bAuth","token");

export const setAclMenu = (obj) => {
   const aclCookies = _.assign({
      [`app_acl_menu`]: obj,
   })
   setCookie('aclMenu', aclCookies, 32400)
}

export const setRefreshToken = (obj) => {
   const isLogin = _.assign({
      [`app_auth_authenticated`]: true,
      [`app_auth_refresher_orb`]: obj.refresh_token,
   })
   const authCookies = _.assign({
      [`app_auth_observer_ward`]: obj.access_token,
      [`app_account_sentri`]: obj.token_type,
   })

   setCookie('auth', authCookies, 31536000)
   setCookie('isLogin', isLogin, 31536000)
}

export const setBasicAuthCookies = (obj) => {
   const expire = new Date(obj.tokenExpiresAt)
   setCookieNew("bAuth", obj, expire);
 }

export const setCookie = (cookieName, objCookies, expired) => {
   const enc = doEncryptId(objCookies)
   const opt = { path: '/', maxAge: expired }
   cookie.save(cookieName, enc, opt)
   return cookie
}

export const setCookieNew = (cookieName, objCookies, expires) => {
   const enc = doEncryptId(objCookies)
   const opt = { path: '/', expires }
   cookie.save(cookieName, enc, opt)
   return cookie
}

export const getCookie = (cookieName, str = '') => {
   if (cookie.load(cookieName) !== undefined) {

      const decryptedData = doDecryptId(cookie.load(cookieName))
      if (str !== '') {
         return decryptedData[str]
      } else {
         return decryptedData
      }
   }
   return null
}

export const clearCookie = (cookieName) =>
   cookie.remove(cookieName, { path: '/' })

export const removeDataUser = () => {
   clearCookie('auth')
   clearCookie('account')
   clearCookie('isLogin')
   clearCookie('aclMenu')
   clearCookie('photo_profile')
   clearCookie('fcm')
   localStorage.clear()
}

export const setAuthCookies = (obj) => {
   const isLogin = _.assign({
      [`app_auth_authenticated`]: true,
      [`app_auth_refresher_orb`]: obj.refresh_token,
   })
   const authCookies = _.assign({
      [`app_auth_observer_ward`]: obj.access_token,
      [`app_account_sentri`]: obj.token_type,
   })
   setCookie('auth', authCookies, 31536000 )
   setCookie('isLogin', isLogin, 31536000 )
}

export const setCookieNonEncrypt = (cookieName, cookieValue, expired) => {
   cookie.save(cookieName, cookieValue.toString(), {
      path: '/',
      maxAge: expired,
   })
   return cookie
}

export const setProfileCookies = (obj) => {
   const profileAccount = _.assign({
      [`app_account_bracer`]: obj.id_user,
      [`app_account_dominator`]: obj.user_role,
      [`app_account_whoami`]: obj.fullname,
      [`app_account_whatami`]: obj.name_role,
      [`photo_profile`]: obj.photo_profile,
   })
   setCookie('account', profileAccount, 32400)
}

export const SESSION_ID = () => {
   return cookie.load('_id')
}
