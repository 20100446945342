/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Ripples from 'react-ripples'
import './card_kemen.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CardKemen = ({image, title, onClick}) => {

    const klik_data = item => {
        window.open(item, "_blank") //to open new page
    }

    return (
        <Ripples className="card-kemen-item" onClick={() => klik_data(onClick)}>
            <div>
                {/* <img src={image} className="image-card" /> */}
                <LazyLoadImage
                    // delayMethod='throttle'
                    // delayTime={300}
                    // threshold={100}
                    alt="kemen_img"
                    effect="blur"
                    className="image-card"
                    src={image}
                />
                <div className="content-title">
                    <p className="title">{title}</p>
                </div>
            </div>
        </Ripples>
    )
}

export default CardKemen;
