/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './load_progress_stat.scss';
import { Spinner } from 'reactstrap';

const LoadProgressStat = () => {

    return (
        <div className="load-progress-stat">
            <div className="load-progress-spinner">
                <Spinner color="primary" />
            </div>
        </div>
    )
}

export default LoadProgressStat;
