/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import './detail_video.scss';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import parse from 'html-react-parser';
import GridList from 'react-gridlist';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Ripples from 'react-ripples';
import { useHistory, useLocation } from 'react-router-dom';
import ReactPlayer from "react-player"

//component
import * as Api from '../../../../constants/api';
import { LoadProgress, ListEmpty} from '../../../../components';
import { ICCalendarGrayNews } from '../../../../assets';

const DetailVideo = () => {

  // variable constant
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [is_progress, setisProgress] = useState(true);
  const [list_video, setListVideo] = useState([]);
  const [list_video_slice, setListVideoSlice] = useState([]);
  const [error_message, setErrorMessage] = useState('');

  const [is_progress_cover, setisProgressCover] = useState(true);
  const [cover_video, setCoverVideo] = useState({});
  const [error_message_cover, setErrorMessageCover] = useState('');

  const perPage = 8;
  const [pageCount, setPageCount] = useState('');

  //style
  let styles = {
    container: {
        flex: 1,
    },
}

  // did mount
  useEffect(() => {
    // console.log('id_video');
    // console.log(location.state.id_video);
    setTimeout(() => {
      _get_detail_video(location.state.id_video);
      _get_data_video();
    }, 500);
  }, []);

  //function
  const _get_detail_video = (id_video) => {
    // console.log(id_video)
    setisProgressCover(true);
    const url = Api.DETAIL_VIDEO + id_video + '?lang=' + localStorage.getItem('language').toUpperCase();;
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        setisProgressCover(false);
        if (response.data.code === '01') {
          if (response.data.data.length === 0) {
              setCoverVideo({});
              setErrorMessageCover(t('empty_list'));
          } else {
            let list_video_data = response.data.data;
            setCoverVideo(list_video_data[0]);
          } 
        } else {
          setCoverVideo({});
          setErrorMessageCover(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgressCover(false);
        setCoverVideo({});
        setErrorMessageCover(t('error_message'));
      });
  }

  const _get_data_video = () => {
    setisProgress(true);
    const url = Api.GET_DATA_VIDEO + '?lang=' + localStorage.getItem('language').toUpperCase();;
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        setisProgress(false);
        if (response.data.code === '01') {
          if (response.data.data.length === 0) {
              setListVideo([]);
              setListVideoSlice([]);
              setErrorMessage(t('empty_list'));
          } else {
            let list_video_data = response.data.data;
            setListVideo(list_video_data);
            const slice = list_video_data.slice(0, perPage);
            setPageCount(list_video_data.length / perPage);
            setListVideoSlice(slice);
          } 
        } else {
          setListVideo([]);
          setListVideoSlice([]);
          setErrorMessage(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgress(false);
        setListVideo([]);
        setListVideoSlice([]);
        setErrorMessage(t('error_message'));
      });
  }

  const CallVideo = (image_url) => {
    // console.log('url_image');
    // console.log(image_url);
    // var url_image = '';
    // if (image_url !== undefined) {
    //   url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    // }
    // console.log('url_image');
    // console.log(url_image);
    // return url_image + '#t=0.5';
    // console.log('url_image');
    // console.log(image_url);
    var url_image = '';
    if (image_url !== null) {
      if (image_url.slice(0, 1) === '.') {
        if (image_url !== undefined && image_url !== null) {
          url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        }
      } else {
        url_image = image_url;
      }
    }
    // console.log('url_video');
    // console.log(url_image);
    return url_image + '#t=0.5';
  }

  const parseContent = (text) => {
    var text_html = '';
    if (text !== undefined) {
      text_html = parse(text)
    }
    // console.log('url_image');
    // console.log(text_html);
    return text_html;
  }

  const getGridGap = (elementWidth, windowHeight) => (elementWidth > 720 && windowHeight > 480) ? 10 : 5;

  const getColumnCount = (elementWidth) => Math.floor(elementWidth / 300); //pembagian column (dibagi untuk 4 column)

  const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.1);

  const getItemData = (list_peraturan, columnWidth) => {
      return {
          key: list_peraturan.id,
      }
  }
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    loadMoreData(offset, offset + perPage);
  };

  const loadMoreData = (of, op) => {
    const data = list_video;

    const slice = data.slice(of, op);
    setPageCount(data.length / perPage);
    setListVideoSlice(slice);
  };

  const _click_detail = (item) => {
    // console.log(item)
    window.scrollTo(0,0);
    _get_detail_video(item.id_video);
    _get_data_video();
  }

    return (
      <div className="detail-video-content">
        <div className="title-foto">{t('document_videos')}</div>
        <div style={{display: 'flex'}}>
          {is_progress_cover === true ?
            <div style={{height: '25vw'}} className="search-content-load">
                <LoadProgress />
            </div> 
            : 
            JSON.stringify(cover_video) === '{}' ?
            <div className="search-content-load">
                <ListEmpty message_error={error_message}/>
            </div>
            :
            <div className="cover-foto">
              <div className="cover-foto-image">
                {cover_video.file === null ? 
                  <ReactPlayer className="image-foto" url={CallVideo(cover_video.url_link)} controls={true} />
                  :
                  <video className="image-foto" controls>
                    <source src={CallVideo(cover_video.file)} type="video/mp4" />
                    <source src={CallVideo(cover_video.file)} type="video/webm" />
                    <source src={CallVideo(cover_video.file)} type="video/ogg" />
                      Your browser does not support the video tag.
                  </video>
                }
              </div>
              <div className="cover-foto-desc">
                <div className="cover-foto-desc-title">
                  {cover_video.header}
                </div>
                <div className="cover-foto-desc-date">
                  <img src={ICCalendarGrayNews} style={{marginRight: '0.7vw'}} />
                  {moment(cover_video.waktu_publish).format('DD MMMM YYYY')}
                </div>
                <div className="cover-foto-desc-desc">
                  {parseContent(cover_video.berita)}
                </div>
              </div>
            </div>
          }
        </div>
        <div className="foto-other">
          <div className="foto-other-title">
            {t('other_videos')}
          </div>
          <div style={{display: 'flex', marginTop: '1vw'}}>
            {is_progress === true ?
              <div style={{height: '25vw'}} className="search-content-load">
                  <LoadProgress />
              </div> 
              : 
              list_video_slice.length === 0 ?
              <div className="search-content-load">
                  <ListEmpty message_error={error_message}/>
              </div>
              :
              <div className="row m-0">
                <div style={styles.container} className="col-md-12 p-0">
                    <GridList
                        items={list_video_slice}
                        getGridGap={getGridGap}
                        getColumnCount={getColumnCount}
                        getWindowMargin={getWindowMargin}
                        getItemData={getItemData}
                        renderItem={(item) => {
                            return (
                              <Ripples 
                                onClick={() => _click_detail(item)}
                                >
                                <div className="foto-other-item-grid">
                                  {item.file === null ? 
                                    <ReactPlayer className="foto-other-item-grid-image" url={CallVideo(item.url_link)} controls={true} />
                                    :
                                    <video className="foto-other-item-grid-image" controls>
                                      <source src={CallVideo(item.file)} type="video/mp4" />
                                      <source src={CallVideo(item.file)} type="video/webm" />
                                      <source src={CallVideo(item.file)} type="video/ogg" />
                                        Your browser does not support the video tag.
                                    </video>
                                  }
                                  <div className="foto-other-item-grid-title max-line-three">
                                    {item.header}
                                  </div>
                                  <div className="foto-other-item-grid-desc max-line-four">
                                    {parseContent(item.berita)}
                                  </div>
                                </div>
                              </Ripples>
                            )
                        }}
                    />
                
                  <div className="foto-other-content-list">
                    <ReactPaginate
                        previousLabel={t('previous')}
                        nextLabel={t('next')}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick.bind(this)}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
}

export default DetailVideo;
