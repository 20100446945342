export const alertSuccessTextHTML = (ticketNumber) => {
   return {
      id: `
          <p>
            Pertanyaan berhasil dibuat dengan nomor referensi : 
            <div style="padding: 30px;" >
              <div style="background: rgb(2, 39, 93); padding: 5px; border-radius: 7px; color: white">
                ${ticketNumber}
              </div>
            </div>
  
            Cek nomor referensi melalui email yang kami kirim ke kotak masuk email
            anda atau simpan nomor referensi dengan menekan tombol salin di bawah.
            Nomor referensi digunakan untuk melacak status pertanyaan.
          </p>
        `,
      en: `
          <p>
            Enquiry successfully created with reference number:
            <div style="padding: 30px;" >
              <div style="background: rgb(2, 39, 93); padding: 5px; border-radius: 7px; color: white">
                ${ticketNumber}
              </div>
            </div>
  
            Check the reference number via email that we sent to your email inbox
            or save the reference number by pressing the copy button below.
            The reference number is used to track the status of an inquiry.
          </p>
        `,
   }
}

export const alertErrorTextHTML = (errorMessage) => {
   return `<p>${errorMessage}</p>`
}
