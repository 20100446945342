import React, { useEffect, useState } from "react";
import {
    Button, Card, CardBody, CardSubtitle, CardTitle, CardText, Label, Input, FormGroup, Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import axios from 'axios';
import * as Api from '../../../constants/api';
import ReactPaginate from "react-paginate";
import './new2.scss';


export default function InfoSetiapSaat2() {
    let getCurrentLanguage = localStorage.getItem('language').toUpperCase()
    const [dataPeraturan, setDataPeraturan] = useState([])
    const [dataPeraturanForUi, setDataPeraturanForUi] = useState([])
    let [jumlahHalaman, setJumlahHalaman] = useState('')
    let dataPerPage = 4
    const [searchTerm, setSearchTerm] = useState('');
    let a ='b'

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [isShowAll, setIsShowAll] = useState(false)


    



    // const getPeraturanp = async () => {
    //     try {
    //         let res = await axios.get(`${Api.SETIAP_SAAT}?lang=${getCurrentLanguage}&jenis=peraturan`, {
    //             headers: {
    //                 Authorization: `${Api.BASIC_AUTH}`
    //             }
    //         })
    //         console.log(res);
    //         if (res.status === 200) {
    //             let dataPeraturanFromRes = res?.data.data
    //             setDataPeraturan(res.data?.data)
    //             let slice = dataPeraturanFromRes.slice(0, dataPerPage)
    //             setJumlahHalaman(dataPeraturanFromRes.length / dataPerPage)
    //             setDataPeraturanForUi(slice)
    //         } else {
    //             setDataPeraturanForUi([])
    //             setDataPeraturan([])
    //         }

    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const getPeraturan = async () => {
        try {
            let res = await axios.get(`${Api.SETIAP_SAAT}?lang=${getCurrentLanguage}&jenis=peraturan`, {
                headers: {
                    Authorization: `${Api.BASIC_AUTH}`
                }
            })
            if (res.status === 200) {
                // let dataPeraturanFromRes = res?.data.data
                // setDataPeraturan(res.data?.data)
                // let slice = dataPeraturanFromRes.slice(0, dataPerPage)
                // setJumlahHalaman(dataPeraturanFromRes.length / dataPerPage)
                // setDataPeraturanForUi(slice)
                return res.data?.data
            } else {
                // setDataPeraturanForUi([])
                // setDataPeraturan([])
            }

        } catch (error) {
            console.log(error);
        }
    }


    const getPerjanjian = async () => {
        try {
            let res = await axios.get(`${Api.SETIAP_SAAT}?lang=${getCurrentLanguage}&jenis=perjanjian`, {
                headers: {
                    Authorization: `${Api.BASIC_AUTH}`
                }
            })
            console.log(res);
            if (res.status === 200) {
                // let dataPeraturanFromRes = res?.data.data
                // setDataPeraturan(res.data?.data)
                // let slice = dataPeraturanFromRes.slice(0, dataPerPage)
                // setJumlahHalaman(dataPeraturanFromRes.length / dataPerPage)
                // setDataPeraturanForUi(slice)
                return res.data?.data
            } else {
                // setDataPeraturanForUi([])
                // setDataPeraturan([])
            }

        } catch (error) {
            console.log(error);
        }
    }


    const getPerjanjianAndGetPeraturanCombine = async () => {
        try {
            const dataPeraturan1 = await getPeraturan()
            const dataPerjanjian2 = await getPerjanjian()
            const combineData = [...dataPeraturan1, ...dataPerjanjian2]
            setDataPeraturan(combineData)
            let slice = combineData.slice(0, dataPerPage)
            setJumlahHalaman(combineData.length / dataPerPage)
            setDataPeraturanForUi(slice)
        } catch (error) {
            console.log(error);
        }
    }



    const CallImage = (image_url) => {
        if (image_url !== null) {
            // var replace_url = image_url.substring(1);
            var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
            // console.log(url_image);
            return url_image;
        }
    }

    const handlePaginatePeraturan = (e) => {
        let selectedPage = e.selected
        const offset = selectedPage * dataPerPage;
        loadMoreDataForPeraturan(offset, offset + dataPerPage)
    }

    const loadMoreDataForPeraturan = (of, op) => {
        let dataOriginal = dataPeraturan
        const slice = dataOriginal.slice(of, op);
        setJumlahHalaman(dataOriginal.length / dataPerPage)
        setDataPeraturanForUi(slice)
    }



    const handleSearch = () => {
        if (searchTerm !== '') {
            const filteredData = dataPeraturan.filter((item) =>
                item.header.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setDataPeraturanForUi(filteredData);
            // setJumlahHalaman(filteredData.length / dataPerPage);
        } else {
            let slice = dataPeraturan.slice(0, dataPerPage);
            setDataPeraturanForUi(slice);
            setJumlahHalaman(dataPeraturan.length / dataPerPage);
        }
    };


    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        // getPeraturanp()
        getPerjanjianAndGetPeraturanCombine()
    }, [])

    useEffect(() => {
        handleSearch();
    }, [searchTerm, dataPeraturan]);

    return (
        <div style={{ minHeight: '80vh' }}>
            <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', margin: '40px' }} >
                <h1>Informasi Publik Tersedia Setiap Saat</h1>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0 10px 0 10px' }} >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }} >
                    <Input
                        placeholder="Cari Peraturan"
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <div style={{ margin: '10px' }} >
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}  >
                        <DropdownToggle caret style={{ backgroundColor: '#1600a5', border: 'none' }} >
                            Kategori
                        </DropdownToggle>
                        <DropdownMenu >
                            <DropdownItem onClick={() => setSearchTerm('')} >Semua Kategori</DropdownItem>
                            <DropdownItem onClick={() => setSearchTerm('Peraturan presiden')} >Peraturan Presiden</DropdownItem>
                            <DropdownItem onClick={() => setSearchTerm('Instruksi Presiden')} >Instruksi Presiden</DropdownItem>
                            <DropdownItem onClick={() => setSearchTerm('Peraturan Menteri Keuangan')} >Peraturan Menteri Keuangan</DropdownItem>
                            <DropdownItem onClick={() => setSearchTerm('Peraturan Kepala')} >Peraturan Kepala</DropdownItem>
                            <DropdownItem onClick={() => setSearchTerm('Standar Operasional Prosedur')} >Standar Operasional Prosedur</DropdownItem>
                            <DropdownItem onClick={() => setSearchTerm('Nota Kesepahaman')} >Nota Kesepahaman / Mou</DropdownItem>
                            <DropdownItem onClick={() => setSearchTerm('Perjanjian Kerja Sama')} >Perjanjian Kerja Sama</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10px' }} >
                    <Button onClick={() => setIsShowAll(!isShowAll)} style={{ backgroundColor: '#1600a5', border: 'none' }} >
                        {isShowAll ? 'Sembunyikan' : 'Tampilkan Semua'}
                    </Button>
                </div>


            </div>

            <div className="container" style={{ padding: '10px', display: 'flex', flexWrap: 'wrap' }} >

                {isShowAll ?
                    <>
                        {dataPeraturan?.map((item, index) => {
                            let pulu = ''
                            if (item.logo) {
                                pulu = 'flex'
                            }
                            return (
                                <Card
                                    style={{
                                        width: '50%',
                                        padding: '10px',
                                        border: 'none',
                                        height: '200px'
                                    }}
                                >
                                    <CardBody style={{ display: `${pulu}`, border: '1px solid #d1d1d1', borderRadius: '5px' }}>
                                        {item.logo &&
                                            <div style={{ width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                <img
                                                    alt="Sample"
                                                    src={CallImage(item?.logo)}
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        }
                                        <div style={{ margin: '10px', width: '80%' }} >
                                            {/* <CardTitle tag="h5">
                                    {item.jenis}
                                </CardTitle> */}
                                            {/* <CardSubtitle
                                    className="mb-2 text-muted"
                                    tag="h6"
                                >
                                    Card subtitle
                                </CardSubtitle> */}
                                            <CardText>
                                                {
                                                    item.header.length > 80 ?
                                                        (
                                                            <div className="">
                                                                {item.header.slice(0, 80)}...
                                                            </div>
                                                        ) : (
                                                            <div className="">
                                                                {item.header}
                                                            </div>
                                                        )
                                                }
                                            </CardText >
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                                <Button onClick={() => window.open(CallImage(item.file))} style={{ backgroundColor: '#1600a5', border: 'none' }} >
                                                    Unduh
                                                </Button>
                                            </div>

                                        </div>

                                    </CardBody>
                                </Card>
                            )

                        })}
                    </> :
                    <>
                        {dataPeraturanForUi?.map((item, index) => {

                            let pulu = ''
                            if (item.logo) {
                                pulu = 'flex'
                            }
                            return (
                                <Card
                                    style={{
                                        width: '50%',
                                        padding: '10px',
                                        border: 'none',
                                        height: '200px'
                                    }}
                                >
                                    <CardBody style={{ display: `${pulu}`, border: '1px solid #d1d1d1', borderRadius: '5px' }}>
                                        {item.logo &&
                                            <div style={{ width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                <img
                                                    alt="Sample"
                                                    src={CallImage(item?.logo)}
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        }

                                        <div style={{ margin: '10px', width: '80%' }} >
                                            <CardText>
                                                {
                                                    item.header.length > 80 ?
                                                        (
                                                            <div className="">
                                                                {item.header.slice(0, 80)}...
                                                            </div>
                                                        ) : (
                                                            <div className="">
                                                                {item.header}
                                                            </div>
                                                        )
                                                }
                                            </CardText >
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                                <Button onClick={() => window.open(CallImage(item.file))} style={{ backgroundColor: '#1600a5', border: 'none' }}  >
                                                    Unduh
                                                </Button>
                                            </div>

                                        </div>

                                    </CardBody>
                                </Card>
                            )

                        })}
                    </>}




            </div>

            <div>

            </div>

            <div>
                {isShowAll ?
                    <>
                    </>
                    :
                    <>
                        {searchTerm === '' &&
                            <ReactPaginate
                                previousLabel={'Sebelumnya'}
                                nextLabel={'Selanjutnya'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={jumlahHalaman}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePaginatePeraturan}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                            />
                        }

                    </>

                }

            </div>
            <div>

            </div>
        </div>
    )
}