import React from 'react'
import './cardprofil2.scss'

const CardProfile = ({ gambar, judul, deskripsi, header }) => {
   return (
      <div className="card-profile-item">
         <div className="item">
            <img src={gambar} alt={judul} className="gambar-profile" />
            <div className="titles">{judul}</div>
            <div className="subtitles">{header}</div>
            <hr />
            <div className="sub-titles">{deskripsi}</div>
         </div>
      </div>
   )
}

export default CardProfile
