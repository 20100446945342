import React from 'react';

const Publikasi = () => {
    return (
        <div>
            <p>Publikasi Page</p>
        </div>
    )
}

export default Publikasi;
