import React, { useEffect, useState, useRef } from 'react'
import {
   CollapseComp,
   InputSearch,
   KomoditasKonten,
   ListEmpty,
   ListEmptyDetail,
   ListFirst,
   LoadProgress,
} from '../../../components'
import './komoditas.scss'
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import * as Api from '../../../constants/api'
import { Button } from 'reactstrap'

const Komoditas = () => {
   // const location = useLocation();
   // const data_komoditas = location.state.data_komoditas; //orgtabledata
   const { t } = useTranslation()

   const location = useLocation()
   const match = useRouteMatch()
   var route_data = ''
   if (location.state === undefined) {
      const params = new URLSearchParams(location.search);
      const lartasParams = params.get('lartas')
      if (lartasParams){
         route_data = {
            lartas: lartasParams,
         }
      } else {
         route_data = {
            lartas: match.params.lartas,
         }
      }
   } else {
      route_data = location.state.data
   }
   // console.log('route_data');
   // console.log(route_data);
   const [data_lartas, setDataLartasAsal] = useState(route_data)
   // const [dataLartas, SetDataLartas] = useState([]);
   const [lartas, setLartas] = useState('')
   const [axiosTimer, setAxiosTimer] = useState('')
   const [is_first_load, setisFirstLoad] = useState(true)

   const [is_progress, setisProgress] = useState(false)
   const [error_message, setErrorMessage] = useState('')

   const [is_progressKanan, setisProgressKanan] = useState(false)
   const [error_messageKanan, setErrorMessageKanan] = useState(
      t('atr_me_title'),
   )
   const [dataKhususRegulasi, setDataKhususRegulasi] = useState(false)
   const [dataCatatan, setDataCatatan] = useState([])
   const [dataDetail, setDataDetail] = useState([])
   const [bagjel, setBagjel] = useState([])
   const [babjel, setBabjel] = useState([])
   const [bagjelEn, setBagjelEn] = useState([])
   const [babjelEn, setBabjelEn] = useState([])
   const [dataMfn, setDataMfn] = useState([])
   const [dataRefSatuan, setDataRefSatuan] = useState({})
   const [dataPrefTariff, setDataPrefTariff] = useState([])
   const [dataLartasImport, setDataLartasImport] = useState([])
   const [dataLartasImportBorder, setDataLartasImportBorder] = useState([])
   const [dataLartasImportPostBorder, setDataLartasImportPostBorder] = useState(
      [],
   )
   const [dataLartasExport, setDataLartasExport] = useState([])
   const [dataHsOther, setDataHsOther] = useState([])
   const [dataDokumenKepabean, setDataDokumenKepabean] = useState([])
   const [dataDokumenKepabeanPB, setDataDokumenKepabeanPB] = useState([])

   const axiosTimerFunc = (startTime) => {
      let now = Date.now()
      let seconds = Math.floor((now - startTime) / 1000)
      let milliseconds = Math.floor((now - startTime) % 1000)
      setAxiosTimer(`${seconds}.${milliseconds}`)
   }

   const bahasa = localStorage.getItem('language')

   const perPage = 10
   const [pageCount, setPageCount] = useState('')

   const [list_lartas, setListLartas] = useState([])
   const [list_lartas_slice, setListLartasSlice] = useState([])

   const handlePageClick = (e) => {
      const selectedPage = e.selected
      const offset = selectedPage * perPage

      loadMoreData(offset, offset + perPage)
   }

   const loadMoreData = (of, op) => {
      const data = list_lartas

      const slice = data.slice(of, op)
      setPageCount(data.length / perPage)
      setListLartasSlice(slice)
   }

   const searchLartas = (data) => {
      // console.log(data);
      // console.log('lartas');
      // console.log(data.lartas);

      setisFirstLoad(true)
      setisProgress(true)
      setDataDetail([])

      console.log('searh', data);

      let lartas = data.lartas

      let url = Api.KOMODITAS_SEARCH + filter_input(lartas) + '&size=200&from=0'
      // console.log('url search', url);
      let startTime = Date.now()

      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgress(false)
            if (response.data.code === '01') {
               if (response.data.data.length === 0) {
                  // console.log('length === 0', response.data);
                  setListLartas([])
                  setListLartasSlice([])
                  setErrorMessage(t('empty_list'))
               } else {
                  // console.log('length !== 0', response.data.data)
                  let data_list = response.data.data[0].result
                  setListLartas(data_list)
                  const slice = data_list.slice(0, perPage)
                  setPageCount(data_list.length / perPage)
                  setListLartasSlice(slice)
                  axiosTimerFunc(startTime)
                  console.log(data_list)
               }
            } else {
               // console.log('code 02');
               // console.log(response.data.code);
               setListLartas([])
               setListLartasSlice([])
               setErrorMessage(t('empty_list'))
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgress(false)
            setListLartas([])
            setListLartasSlice([])
            setErrorMessage(t('error_message'))
         })
   }

   const filter_input = (input_text) => {
      let filter_text = input_text.replace(/\'|\"|\--/g, '')
      return filter_text
   }

   const getDetail = (item) => {
      const itemHs = item._source.hs_code_format
      const url = Api.KOMODITAS_DETAIL + itemHs
      const excludeHSCodeInDokPabean = ["22030011",
         "22030011",
         "22030019",
         "22030019",
         "22030091",
         "22030091",
         "22030099",
         "22030099",
         "22041000",
         "22041000",
         "22042111",
         "22042111",
         "22042113",
         "22042113",
         "22042114",
         "22042114",
         "22042121",
         "22042121",
         "22042122",
         "22042122",
         "22042211",
         "22042211",
         "22042212",
         "22042212",
         "22042213",
         "22042213",
         "22042221",
         "22042221",
         "22042222",
         "22042222",
         "22042911",
         "22042911",
         "22042912",
         "22042912",
         "22042921",
         "22042921",
         "22042922",
         "22042922",
         "22043010",
         "22043010",
         "22043020",
         "22043020",
         "22051010",
         "22051010",
         "22051020",
         "22051020",
         "22059010",
         "22059010",
         "22059020",
         "22059020",
         "22060010",
         "22060010",
         "22060020",
         "22060020",
         "22060031",
         "22060031",
         "22060039",
         "22060039",
         "22060041",
         "22060041",
         "22060049",
         "22060049",
         "22060050",
         "22060050",
         "22060060",
         "22060060",
         "22060091",
         "22060091",
         "22060099",
         "22060099",
         "22082050",
         "22082050",
         "22082090",
         "22082090",
         "22083010",
         "22083010",
         "22083090",
         "22083090",
         "22084000",
         "22084000",
         "22085000",
         "22085000",
         "22086000",
         "22086000",
         "22087010",
         "22087010",
         "22087090",
         "22087090",
         "22089010",
         "22089010",
         "22089020",
         "22089020",
         "22089030",
         "22089030",
         "22089040",
         "22089040",
         "22089050",
         "22089050",
         "22089060",
         "22089060",
         "22089070",
         "22089070",
         "22089091",
         "22089091",
         "22089099",
         "22089099"]
      // const excludeKodeDokPabean = ["20", "23", "25", "513", "621", "632"]
      const excludeKodeDokPabean = []

      // console.log('url detail', url);
      // console.log('api.basic_auth', Api.BASIC_AUTH);
      // setisFirstLoad(true);
      setisProgressKanan(true)
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            // setisFirstLoad(false);
            setisProgressKanan(false)
            // console.log('length 200', response.status);
            if (response.data.code === '01') {
               if (response.data.data[0].length === 0) {
                  setDataDetail([])
                  setErrorMessageKanan(t('empty_list'))
               } else {
                  let data_catatan = response.data.data[0].catatan[0]
                  let data_detail = response.data.data[0]
                  let data_bagjel =
                     response.data.data[0].bagian_penjelasan !== ''
                        ? response.data.data[0].bagian_penjelasan
                        : []
                  let data_babjel =
                     response.data.data[0].bab_penjelasan !== ''
                        ? response.data.data[0].bab_penjelasan
                        : []
                  let data_bagjel_en =
                     response.data.data[0].bagian_penjelasan !== ''
                        ? response.data.data[0].bagian_penjelasan_en
                        : []
                  let data_babjel_en =
                     response.data.data[0].bab_penjelasan !== ''
                        ? response.data.data[0].bab_penjelasan_en
                        : []
                  // let data_mfn = response.data.data[0].mfn
                  let data_mfn = response.data.data[0].new_mfn
                  // let data_tariff = response.data.data[0].preferential_tariff
                  let data_tariff =
                     response.data.data[0].preferential_tariff_new
                  let data_import = response.data.data[0].import_regulation
                  let data_import_border =
                     response.data.data[0].import_regulation_border
                  let data_import_postborder =
                     response.data.data[0].import_regulation_post_border
                  let data_export = response.data.data[0].export_regulation
                  let data_hsother = response.data.data[0].hsParent
                  let data_khusus_regulasi =
                     response.data.data[0].kondisiTertentu
                  let data_ref_satuan = response.data.data[0].refSatuan
                  const dok_kepabean_import_border = response.data.data[0].dok_kepabean_import_border
                  const dok_kepabean_import_post_border = response.data.data[0].dok_kepabean_import_post_border

                  
                  const hscode = response.data.data[0].hs_code
                  const cekExcludingHSCode = excludeHSCodeInDokPabean.includes(hscode)
                  for (const dok of dok_kepabean_import_border) {
                     if (cekExcludingHSCode) {
                        for (const data of data_import) {
                           if (data.kd_ijin == "01A39" || data.kd_ijin == "01A37") {
                              data.dok_pabean = ["16", "28"]
                           }
                        }
                        if (excludeKodeDokPabean.includes(dok.kd_dokumen)) {
                           dok.status_btn = false
                        }
                     }
                  }

                  for (const dok of dok_kepabean_import_post_border) {
                     if (cekExcludingHSCode) {
                        if (excludeKodeDokPabean.includes(dok.kd_dokumen)) {
                           dok.status_btn = false
                        }
                     }
                  }
                  setDataKhususRegulasi(data_khusus_regulasi)
                  setDataCatatan(data_catatan)
                  setDataDetail(data_detail)
                  setBagjel(data_bagjel)
                  setBabjel(data_babjel)

                  setBagjelEn(data_bagjel_en)
                  setBabjelEn(data_babjel_en)
                  if (itemHs.length === 6) {
                     setDataMfn([])
                  } else {
                     setDataMfn(data_mfn)
                  }
                  setDataRefSatuan(data_ref_satuan)
                  setDataPrefTariff(data_tariff)
                  setDataLartasImport(data_import)
                  setDataLartasImportBorder(data_import_border)
                  setDataLartasImportPostBorder(data_import_postborder)
                  setDataLartasExport(data_export)
                  setDataHsOther(data_hsother)
                  setDataDokumenKepabean(dok_kepabean_import_border)
                  setDataDokumenKepabeanPB(dok_kepabean_import_post_border)
               }
            } else {
               // console.log('length else', response.data.data[0]);
               if (itemHs.length === 8) {
                  setDataDetail([])
                  setErrorMessageKanan(t('data_not_found'))
               } else {
                  setDataDetail([])
                  setErrorMessageKanan(t('error_message_hs4'))
               }
            }
            // console.log('length luar');
         })
         .catch((error) => {
            // setisFirstLoad(false);
            setisProgressKanan(false)
            console.log('length', error)
            setDataDetail([])
            setErrorMessageKanan(t('error_message'))
         })
   }

   const articleRef = useRef()

   //did mount
   useEffect(() => {
      console.log('>>>>>', data_lartas);
      searchLartas(data_lartas)
      window.scroll(0, 0)
   }, [])

   const tampil_data = (item) => {
      // setisFirstLoad(false);
      // console.log('length detail', dataDetail.length);
      getDetail(item)
      // window.scrollTo({
      // 	top: 180,
      // 	left: 100,
      // 	behavior: 'smooth',
      // });
      // articleRef.current.scrollIntoView({
      //    behavior: 'smooth',
      // })
   }

   // const handleKeyDown = (event) => {
   //     if (event.key === 'Enter') {
   //         //   console.log('do validate');
   //         //   console.log({lartas: event.target.value})
   //         searchLartas({lartas: event.target.value});
   //     }
   // }

   const klikKetentuan = () => {
      const win = window.open(
         '/intr/ketentuan-umum-untuk-menginterpretasi-harmonized-system',
         '_blank',
      )
      win.focus()
   }

   return (
      <div>
         <div className="komoditas-wrapper">
            <p className="komoditas-title">{t('your_search')}</p>
            <div className="komoditas-search-input">
               <div className="komoditas-search-inner">
                  <InputSearch
                     placeholder={t('find_something_intr')}
                     onChange={(e) => setLartas(e.target.value)}
                     onClick={() =>
                        searchLartas({
                           lartas: lartas,
                        })
                     }
                     onKeyDown={() =>
                        searchLartas({
                           lartas: lartas,
                        })
                     }
                     // onKeyDown={handleKeyDown}
                  />
               </div>
               <div className="komoditas-detail">
                  <Button
                     className="komoditas-detail-ketentuan"
                     onClick={() => klikKetentuan()}
                  >
                     Ketentuan Umum Untuk Menginterpretasi Harmonized System
                  </Button>
               </div>
            </div>
            <div className="komoditas-konten">
               {is_progress === true ? (
                  <div
                     style={{ height: '25vw' }}
                     className="detail-content-load"
                  >
                     <LoadProgress />
                  </div>
               ) : list_lartas_slice.length === 0 ? (
                  <div className="detail-content-load">
                     <ListEmpty message_error={error_message} />
                  </div>
               ) : (
                  <>
                     <div className="komoditas-hasil">
                        <p className="komoditas-hasil-text">
                           Sekitar {list_lartas.length} hasil pencarian (
                           {axiosTimer} detik)
                        </p>
                        <div className="komoditas-hasil-konten">
                           <div className="komoditas-hasil-konten-atas">
                              <KomoditasKonten
                                 data={list_lartas_slice}
                                 tampilData={tampil_data}
                                 CollapseComp={() => {
                                    return (
                                       <div className="komoditas-detail-konten">
                                          {is_progressKanan === true ? (
                                             <div
                                                style={{
                                                   height: '25vw',
                                                }}
                                                className="detail-kanan-load"
                                             >
                                                <LoadProgress />
                                             </div>
                                          ) : dataDetail.length === 0 ? (
                                             <div className="detail-kanan-load">
                                                <ListEmptyDetail
                                                   message_error={
                                                      error_messageKanan
                                                   }
                                                />
                                             </div>
                                          ) : (
                                             <>
                                                <CollapseComp
                                                   type={'hscode'}
                                                   header={dataDetail}
                                                   bagjel={bagjel}
                                                   babjel={babjel}
                                                   dataHsOther={dataHsOther}
                                                   bagjelEn={bagjelEn}
                                                   babjelEn={babjelEn}
                                                />
                                                {dataMfn.length !== 0 ? (
                                                   <CollapseComp
                                                      type={'mfn'}
                                                      header={dataDetail}
                                                      dataMfn={dataMfn}
                                                   />
                                                ) : null}

                                                {Object.values(dataRefSatuan)
                                                   .length !== 0 ? (
                                                   <CollapseComp
                                                      type={'refSatuan'}
                                                      header={dataDetail}
                                                      dataRefSatuan={
                                                         dataRefSatuan
                                                      }
                                                   />
                                                ) : null}

                                                {dataPrefTariff.length !== 0 ? (
                                                   <CollapseComp
                                                      type={'tarif'}
                                                      header={dataDetail}
                                                      dataPrefTariff={
                                                         dataPrefTariff
                                                      }
                                                   />
                                                ) : null}
                                                {/* {
                                    dataLartasImport.length !== 0 ?
                                    <CollapseComp type={'impor'} header={dataDetail} dataImport={dataLartasImport} />
                                    :
                                    null
                                } */}
                                                {dataLartasImport.length !==
                                                0 ? (
                                                   <CollapseComp
                                                      type={'impor-border'}
                                                      header={dataDetail}
                                                      dataImportBorder={
                                                         dataLartasImport
                                                      }
                                                      dataKepabean={dataDokumenKepabean}
                                                      dataRefSatuan={
                                                         dataRefSatuan
                                                      }
                                                   />
                                                ) : null}
                                                {dataLartasImportPostBorder.length !==
                                                0 ? (
                                                   <CollapseComp
                                                      type={'impor-postborder'}
                                                      header={dataDetail}
                                                      dataImportPostBorder={
                                                         dataLartasImportPostBorder
                                                      }
                                                      dataKepabeanPB={dataDokumenKepabeanPB}
                                                      dataRefSatuan={
                                                         dataRefSatuan
                                                      }
                                                   />
                                                ) : null}
                                                {dataLartasExport.length !==
                                                0 ? (
                                                   <CollapseComp
                                                      type={'ekspor'}
                                                      header={dataDetail}
                                                      dataExport={
                                                         dataLartasExport
                                                      }
                                                      dataRefSatuan={
                                                         dataRefSatuan
                                                      }
                                                   />
                                                ) : null}
                                                {dataKhususRegulasi.length !==
                                                0 ? (
                                                   <CollapseComp
                                                      type={'regulasi-khusus'}
                                                      header={dataDetail}
                                                      dataExport={
                                                         dataKhususRegulasi
                                                      }
                                                   />
                                                ) : null}
                                                {dataCatatan.length !== 0 ? (
                                                   <CollapseComp
                                                      type={'catatan'}
                                                      header={'Catatan'}
                                                      dataCatatan={dataCatatan}
                                                   />
                                                ) : null}
                                             </>
                                          )}
                                       </div>
                                    )
                                 }}
                              />
                           </div>
                           <div className="komoditas-hasil-konten-bawah">
                              <ReactPaginate
                                 previousLabel={'Sebelumnya'}
                                 nextLabel={'Selanjutnya'}
                                 breakLabel={'...'}
                                 breakClassName={'break-me'}
                                 pageCount={pageCount}
                                 marginPagesDisplayed={2}
                                 pageRangeDisplayed={5}
                                 onPageChange={handlePageClick.bind(this)}
                                 containerClassName={'pagination'}
                                 subContainerClassName={'pages pagination'}
                                 activeClassName={'active'}
                              />
                           </div>
                        </div>
                     </div>
                  </>
               )}
            </div>
         </div>
      </div>
   )
}

export default Komoditas
