/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './input_search.scss';
import { ICSearch } from '../../../assets';
import Ripples from 'react-ripples';
import { InputGroup, InputGroupText, InputGroupAddon, Input } from 'reactstrap';

const InputSearch = ({placeholder, onClick, onKeyDown, ...rest}) => {

  const handleKeyDown = (event) => {
    // console.log('event')
    // console.log(event)
    if (event.key === 'Enter') {
      // console.log('event enter')
      if (onKeyDown) {
        return onKeyDown();
      }
    }
  }

    return (
        // <div className="intr-input-sub-search">
        //     <input className="input-field" placeholder={placeholder} {...rest}/>
        //     {/* <Ripples className="intr-icon">
        //         <img className="intr-icon-image" src={ICSearch} onClick={onClick} />
        //     </Ripples> */}
        // </div>

        <InputGroup className="intr-input-sub-search">
        <Input className="input-field" placeholder={placeholder} onKeyDown={handleKeyDown} {...rest}/>
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <img className="intr-icon-image" src={ICSearch} onClick={onClick} />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    )
}

export default InputSearch;
