// Server
const URL_SERVER = process.env.REACT_APP_API_URL_SERVER;
const PORT_SERVER = process.env.REACT_APP_API_PORT_SERVER;
//const APIS_SERVER = URL_SERVER + ":" + PORT_SERVER;
const APIS_SERVER = URL_SERVER;

// Local
const URL_LOCAL = process.env.REACT_APP_API_URL_LOCAL;
const PORT_LOCAL = process.env.REACT_APP_API_PORT_LOCAL;
const APIS_LOCAL = URL_LOCAL + ":" + PORT_LOCAL;

export default {
  _hashing: {
    _salt: "INSWGEN2",
  },

  _localMenu: {
    _baseUrl: APIS_LOCAL + "/menus",
    _autocomplete: APIS_LOCAL + "/autocomplet",
    _datavisitor: APIS_LOCAL + "/datavisitor",
    _nib: APIS_SERVER + "/nib",
    _login_akses: APIS_LOCAL + "/login",
    _menu: APIS_LOCAL + "/menu",
  },

  // Please if you want declare url, you can direct made in store.js without have to declare in here
  // Just for server
  _server: {
    _login: APIS_SERVER + "/api/login/post-data",
    _menu: APIS_SERVER + "/api/menu/get-all-menu",
    _riwayat_pengajuan: APIS_SERVER + "/api/pg/filter-masterlist",
    _nib: APIS_SERVER + "/api/nib",
    _pelaku_usaha: APIS_SERVER + "/api/masterList/post-data-form",
    _save_master_list: APIS_SERVER + "/api/masterList/updateData",
  },

  _kongMenu: {
    _baseUrl: APIS_SERVER + "/api/role",
  },

  _cekUserAccount: {
    _baseUrl: "https://virtserver.swaggerhub.com/peupl/INSW/1.0.0/login",
    _baseUrlKong: APIS_SERVER + "/api/login",
    _acccesToken: "kgpht7Di2ADGblQx3wlQWPrfmKWZTPn",
  },

  _googleCaptcha: {
    _siteKey: process.env.REACT_APP_CAPTCHA_SITE_KEY,
    _sicretKey: process.env.REACT_APP_CAPTCHA_SECRET_KEY,
  },

  // API TEL-U
  _userRoleMenu: {
    _baseUrl: "https://virtserver.swaggerhub.com/peupl/INSW/1.0.0/role",
  },

  _barang: {
    _baseUrl: APIS_SERVER,
    // _baseUrl: "http://10.8.3.73",
  },
};
