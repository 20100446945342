import React, { useState, useEffect, } from 'react';
import axios from 'axios';
import { InputSearch, InputCapsule} from '../../../components/atoms'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import Select from 'react-dropdown-select'
import { ICSearch } from '../../../assets';
import { Penelusuran_BG } from '../../../assets'
import * as Api from '../../../constants/api'
import AsyncSelect from 'react-select/async';
import { clearConfig } from 'dompurify';
import './kuotaTRQ.scss'


const KuotaTRQ = () => {
    const [dataTRQ, setDataTRQ] = useState([]);
    const [hsCode, setHsCode] = useState('');
    const [dropdown, setDropdown] = useState(false);
    const [id_group, setIdGroup] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [resMessage, setResMessage] = useState('')

    const { t } = useTranslation()

    const options = [
        { id: 'TRQ', name: 'Australia' },
        { id: 'IE', name: 'EFTA' },
        { id: 'UAE', name: 'UAE' },
    ];

    const [searchHs, setSearchHs] = useState({
        label: "Ketik 8 digit kode HS/uraian HS",
        value: ""
    })

    const searchTRQ = async() => {
        const url = `${Api.IP_LNSW_CMS}kuotatrq?id_group=${id_group}&hs_code_format=${searchHs.value}`;
        axios.get(url, {
          headers: {'Authorization': Api.BASIC_AUTH}
        }) 
          .then((response) => {
          if (response.status == 200) {
            setDataTRQ(response.data.data)
           console.log("status ok",response)  
        } 
        
    })
          .catch((error) => {
            console.log(error.response)
            setResMessage(error.response.data.message)
            console.log("res error asli", error.response.data.message)
            console.log("RES MESSAGE",resMessage)
          });
      };

    const keyword = (e) => {
        //dynamic search from https://api.insw.go.id/api/cms/hscode?keyword=8486&size=200&from=0
        const url = `https://api.insw.go.id/api/cms/hscode?keyword=${searchValue}&size=200&from=0`;
        axios.get(url) 
            .then((response) => {
                setSearchValue(response.data.data)
                console.log(response.data);

            })
            .catch((error) => {
                console.log(error);
            });
    };

    const cariHsCode = async (keywords) =>{
        let result = []
        const url = `${Api.IP_LNSW_CMS}hskuota?keyword=${keywords}`
        await axios.get(url, {
            headers: {'Authorization': Api.BASIC_AUTH}
        })
            .then(
            (response) => {
                result = response.data
            }).catch((error) => {
                result = error
            });
        return result
    }




    //select dropdown
    const [selected_ref, setSelectedRef] = useState({
        label: 'Australia',
        value: 'TRQ',
    })

    const [selected_ref_label, setSelectedRefLabel] = useState('Jenis Fasilitas TRQ')
    const [list_select_ref, setListSelectRef] = useState([
        {
            label: 'IA-CEPA (Australia)',
            value: 'TRQ',
        },
        {
            label: 'IE-CEPA (EFTA)',
            value: 'IE',
        },
        {
            label: 'IUAE-CEPA (UAE)',
            value: 'UAE',
        },
    ])
    const [place_holder_ref, setPlaceHolderRef] = useState(
        'penelusuran-dropdown-remove-placeholder',
    )

    //style dropdown
    const StyledItem = styled.div`
        color: #555;
        padding: 10px;
        border-radius: 3px;
        margin: 3px;
        cursor: pointer;
        > div {
           display: flex;
           align-items: center;
        }
        :hover {
           background: #f2f2f2;
        }
     `
        const handleSearchHS = async (value, callback) => {
            if(value !== "" && value.length >=3) {

                await cariHsCode(value).then((res)=>{
                    if(res.code === '01' && res.data.length >0){
                       
                        const mappingData = res.data.map((value) => {
                            return{
                                label: value.gabungan,
                                value: value.hs_code
                            }
                        })
                        callback(mappingData)
                    }
                })
                .catch((error)=> {
                    console.log("error",error)
                })
            }

        }


    return (
        <div className='penelusuran-pibpeb-container'>
            <div className="penelusuran-background">
                <img
                    // src={CallImage(item.nama_img)}
                    src={Penelusuran_BG}
                    className="penelusuran-background-img"
                    alt="bg"
                />
                <div className="penelusuran-content-title">
                    <div className="penelusuran-content-sub-title">
                        <div className="penelusuran-text-title1">
                            Cek Kuota Preferensi Tarif TRQ 
                     </div>
                    </div>
                </div>

                <div className='penelusuran-content-title'>
                        <div className="penelusuran-input-search m-0 justify-content-center mt-5 mt-5 col-md-2 mb-2 ">
                        <AsyncSelect
                            name="cari_hs"
                            id="cari_hs"
                            value={searchHs}
                            loadOptions={handleSearchHS}
                            placeholder="Ketik 8 digit kode HS/uraian HS"
                            noOptionsMessage={()=>"Data Not Found"}
                            onChange={(e)=>setSearchHs(e)}
                        />
                        </div>
                    <div className="penelusuran-input-search row m-0 justify-content-center mt-5 mt-5 mb-2">
                        <Select
                            multi={false}
                            searchable={false}
                            options={list_select_ref}
                            values={[]}
                            style={{
                                borderColor: '#808080',
                                background: '#FFFFFF',
                                borderRadius: '30px',
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginRight: 10,
                            }}
                            className={place_holder_ref}
                            onChange={(value) => {
                                setSelectedRef(value[0])
                                setIdGroup(value[0].value)
                                setSelectedRefLabel(value[0].label)
                                setPlaceHolderRef(
                                    'penelusuran-dropdown-remove-placeholder',
                                )
                            }}
                            contentRenderer={({ props, state }) => (
                                <div>
                                    {selected_ref_label ===
                                        t('choose_cif_fob') ? (
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                            }}
                                        >
                                            <div className="penelusuran-dropdown-placeholder">
                                                {selected_ref_label}
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                            }}
                                        >
                                            <div className="penelusuran-input-dropdown">
                                                {selected_ref_label}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            itemRenderer={({ item, methods }) => (
                                <StyledItem>
                                    <div onClick={() => methods.addItem(item)}>
                                        <div className="penelusuran-dropdown-item">
                                            {item.label}
                                        </div>
                                    </div>
                                </StyledItem>
                            )}
                        />
                    </div>
                    <div className="penelusuran-input-search row justify-content-center mt-5 col-md-1 mb-2" >
                        <button

                            style={{
                                borderRadius: '30px',
                                borderColor: '#808080',
                                color: '#FFFFFF',
                                width: 50,
                                height: 40,
                            }}
                            onClick={searchTRQ}
                        >
                            <img className="intr-icon-image" src={ICSearch} ></img>

                        </button>
                    </div>

                </div>




            </div>
            {dataTRQ && (
                <div>
                    {/* container */}
                    <div
                        className="row m-0 justify-content-center mt-5"
                        style={{
                            fontSize: 60,
                            fontWeight: 'bold',
                            color: '#000000',
                            textAlign: 'center',
                            marginTop: 20,
                        }}
                    >
                        {dataTRQ.map((item, index) => (
                        <div>
                            <div>
                                Kuota
                            </div>
                            <div key={index}
                                style={{display: 'block'}}>
                                {item.saldo.toLocaleString('ID')} &nbsp;
                                {item.satuan.toLocaleString('ID')}
                            </div>
                        </div>
                        ))}
                    </div>
                    

                    {/* container */}
                </div>
            )}
            { dataTRQ.length > 0 ? (
                <div style={{
                    fontSize: 20,
                    color: '#000000',
                    textAlign: 'center',
                    marginTop: 20,
                    paddingBottom: 50
                }}>
                <div className="row m-0 justify-content-center mt-2"
                    style={{
                        fontSize: 20,
                        color: '#000000',
                        textAlign: 'center',
                    }}
                >
                    Berdasarkan Pencarian Pada :
                    {dataTRQ.map((item, index) => (


                    <div key={index}
                    style={{
                        fontWeight : 'bold',
                    }}> 
                        {item.waktu_cari} WIB
                    </div>
                    )
                    )}
                    <br></br>
                
                </div>
                <div className="row m-0 justify-content-center mt-2"> *) Titik penanda ribuan</div>
                </div>
            ) : 
            <h1 className="row m-0 justify-content-center mt-5"
            style={{
                fontSize: 40,
                fontWeight: 'bold',
                color: '#000000',
                textAlign: 'center',
                paddingTop: 5,
                paddingBottom: 40
            }}>{resMessage}</h1>
}



        </div>
    );
};

export default KuotaTRQ;