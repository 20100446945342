import React, { useEffect, useState } from 'react';
import './dropdown_atr.scss';
import Select from "react-dropdown-select";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as Api from '../../../constants/api';

const DropdownAtr = ({id_ortu, id_anak, index, onChange}) => {
    //variable constant
    const { t } = useTranslation();
    // const [selected, setSelected] = useState({
    //     label: 'SATUAN',
    //     value: 'satuan'
    // });
    const [selected, setSelected] = useState('');
    const [selected_label, setSelectedLabel] = useState(t('select_subtopic'));
    // const [list_select, setListSelect] = useState(
    //     [
    //         {
    //             label: 'SATUAN',
    //             value: 'satuan'
    //         },
    //         {
    //             label: 'KEMASAN',
    //             value: 'kemasan'
    //         },
    //         {
    //             label: 'PELABUHAN',
    //             value: 'pelabuhan'
    //         }
    //     ]
    // );
    const [list_select, setListSelect] = useState();
    const [place_holder, setPlaceHolder] = useState('dropdown-atr-dropdown-auto-placeholder');
    const [isProgress, setisProgress] = useState(false);

    const [data_id, setDataId] = useState(id_ortu);
    const [ortu_id, setOrtuId] = useState(id_anak);

    const width = window.innerWidth;
    let lebarSelect = '600px';

    if (width < 600) {
        lebarSelect = '256px';
    }

    //style dropdown
    const StyledItem = styled.div`
        color: #555;
        padding: 10px;
        border-radius: 3px;
        margin: 3px;
        cursor: pointer;
        > div {
            display: flex;
            align-items: center;
        }
        :hover {
            background: #f2f2f2;
        }
    `;

    //did mount
    useEffect(() => {
        // console.log('parent_id component');
        // console.log(index);
        // console.log('ortu id dropdown comp');
        // console.log(ortu_id);
        _get_data_dropdown();
    }, []);

    //function
    const _get_data_dropdown = () => {
        setisProgress(true);
        const url = Api.ATR + data_id + '&parent_id=' + ortu_id + '&title=';
        // console.log(url);
        axios({
          method: 'get',
          url: url,
          headers: { 'Authorization': Api.BASIC_AUTH },
          timeout: 1000 * 10, // Wait for 10 seconds
        })
          .then(response => {
            // console.log('data dropdown');
            // console.log(response.data);
            setisProgress(false);
            if (response.data.code === '01') {
              let data_mfn = [];
              for (var i = 0; i < response.data.data.length; i++) {
                // if (response.data.data[i].status === 4){
                    let data_mfn_obj = {};
                    data_mfn_obj = response.data.data[i];
                    
                    data_mfn_obj.label = response.data.data[i].title;
                    data_mfn_obj.value = response.data.data[i].title;

                    data_mfn.push(data_mfn_obj);
                    // console.log('data_mfn_obj');
                    // console.log(data_mfn_obj);
                // }
              }
                // console.log('data_mfn');
                // console.log(data_mfn);
              setListSelect(data_mfn);
            } else {
              setListSelect([]);
            }
          })
          .catch(error => {
            console.log('error');
            console.log(error);
            setisProgress(false);
            setListSelect([]);
          });
    }

    return (
        <div className="dropdown-atr">
            <Select
                multi={false}
                searchable={false}
                options={list_select}
                values={[]}
                style={{borderColor: '#808080',background: '#FFFFFF', borderRadius: '30px', width: lebarSelect, minHeight:'45px', height: 'auto', marginBottom: '20px'}}
                className={place_holder}
                loading={isProgress}
                onChange={(value) => {
                    // console.log('klik aq');
                    // console.log(value);
                    setSelected(value[0]);
                    setSelectedLabel(value[0].label);
                    // console.log('parent_id');
                    // console.log(value[0].id);
                    // setOrtuId(value[0].id);
                    setPlaceHolder('dropdown-atr-dropdown-remove-placeholder');
                    onChange(value, index);
                }}
                contentRenderer={({ props, state }) => (
                    <div>
                        {selected_label === t('select_subtopic') ? 
                            <div style={{ cursor: 'pointer', display: "flex" }}>
                                <div className="dropdown-atr-dropdown-placeholder">{selected_label}</div>
                            </div>
                            : 
                            <div style={{ cursor: 'pointer', display: "flex" }}>
                                <div className="dropdown-atr-input-dropdown">{selected_label}</div>
                            </div>
                        }
                    </div>
                )}
                itemRenderer={({ item, methods }) => (
                    <StyledItem>
                        <div onClick={() => methods.addItem(item)}>
                            <div className="dropdown-atr-dropdown-item">
                                {item.label}
                            </div>
                        </div>
                    </StyledItem>
                )}
            />
        </div>
    )
}

export default DropdownAtr;
