import React, { useState, useEffect, useHistory, useRef } from 'react'
import './detail_nib.scss'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, InputCapsule, InputSearch } from '../../../../components/atoms'
import axios from 'axios'
import * as Api from '../../../../constants/api'
import { ListEmpty, LoadProgress, TimeLineNib } from '../../../../components'
import moment from 'moment'

const DetailNib = () => {
   const location = useLocation()
   var route_data = ''
   if (location.state === undefined) {
      route_data = ''
   } else {
      route_data = location.state.data
   }
   const [data_nib, setDataNibAsal] = useState(route_data)

   const { t } = useTranslation()
   const [no_npwp, SetNpwp] = useState('')
   const data_npwp = useRef('')
   const [no_nib, SetNib] = useState('')
   const [is_progress, setisProgress] = useState(false)
   const [dataNib, SetDataNib] = useState([])
   const [dataNibHead, SetDataNibHead] = useState([])
   const [dataNibHistory, SetDataNibHistory] = useState([])
   const [dataIC, SetDataIC] = useState()
   console.log(dataIC)
   const [error_message, setErrorMessage] = useState('')

   const stripNull = (ah) => {
      if (ah === null) {
         ah = '-'
      }
      return ah
   }

   // console.log('dataNibHistory', dataKategori)

   //function
   const searchEcoo = (data) => {
      // console.log(data);
      // console.log('no npwp');
      // console.log(data.no_npwp);
      // console.log('nib_detail');
      // console.log(data.nomor);

      setisProgress(true)

      let nomorNpwp = data.no_npwp
      let nomorData = filter_input(data.nomor)

      // let url = Api.TRACKING_DETAIL + 'nib?nib=' + nomorData + '&npwp=' + nomorNpwp; // Mobile Api
      let url = Api.IP_LNSW_CMS + 'nib?nib=' + nomorData + '&npwp=' + nomorNpwp //web api
      // console.log(url);

      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgress(false)
            if (response.data.code === '01') {
               if (response.data.data.length === 0) {
                  SetDataNibHead([])
                  SetDataNib([])
                  SetDataNibHistory([])
                  setErrorMessage(t('empty_list'))
               } else {
                  // console.log('length !== 0');
                  console.log('response-utama', response.data.data)
                  let data_list = response.data.data
                  let data_head = response.data.data.data_header[0]
                  SetDataNibHead(data_head)
                  SetDataNib(data_list)
                  let data_history = data_list.data_history
                  // console.log('history', data_history);
                  // console.log('kategori', data_head);
                  // for (let i = 0; i < data_history.length; i++) {
                  //     let beforeTime = '-';
                  //     let nextTime = '-';
                  //     if (i === 0) {
                  //         beforeTime = '-';
                  //         nextTime = stripNull(data_history[i + 1].waktu_oss);
                  //         console.log('history nextTime 0', nextTime);
                  //     } else if (i === 2) {
                  //         beforeTime = stripNull(data_history[i - 1].waktu_oss);
                  //         nextTime = '-';
                  //         console.log('history nextTime 2', nextTime);
                  //     } else {
                  //         beforeTime = stripNull(data_history[i - 1].waktu_oss);
                  //         nextTime = stripNull(data_history[i + 1].waktu_oss);
                  //         console.log('history nextTime 3', nextTime);
                  //     }
                  //     data_history[i].beforeTime = beforeTime;
                  //     data_history[i].nextTime = nextTime;
                  // }
                  SetDataNibHistory(data_history)
                  // SetDataIC(data_history.find(item => item.status) === data_head.kategori)
                  SetDataIC(
                     data_history
                        .map((item) => {
                           return item.status
                        })
                        .indexOf(data_head.kategori) > 1
                        ? true
                        : false,
                  )
                  // SetDataKategori(data_history.find(item => item.waktu_oss === null))
               }
            } else {
               // console.log('code 02');
               // console.log(response.data.code);
               SetDataNib([])
               SetDataNibHead([])
               SetDataNibHistory([])
               setErrorMessage(t('empty_list'))
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgress(false)
            SetDataNib([])
            SetDataNibHead([])
            SetDataNibHistory([])
            setErrorMessage(t('error_message'))
         })
   }

   const filter_input = (input_text) => {
      let filter_text = input_text.replace(/\'|\"|\--/g, '')
      return filter_text
   }

   const RegexNPWP = (value) => {
      // console.log(value);
      var regex_npwp = value.replace(/[^0-9,]/g, '')
      data_npwp.current = regex_npwp
      if (regex_npwp.length > 15) {
         regex_npwp = regex_npwp.replace(
            /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/,
            '$1.$2.$3.$4-$5.$6',
         )
      } else if (regex_npwp.length > 12) {
         regex_npwp = regex_npwp.replace(
            /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})/,
            '$1.$2.$3.$4-$5.',
         )
      } else if (regex_npwp.length > 9) {
         regex_npwp = regex_npwp.replace(
            /(\d{2})(\d{3})(\d{3})(\d{1})/,
            '$1.$2.$3.$4-',
         )
      } else if (regex_npwp.length > 8) {
         regex_npwp = regex_npwp.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2.$3.')
      } else if (regex_npwp.length > 5) {
         regex_npwp = regex_npwp.replace(/(\d{2})(\d{3})/, '$1.$2.')
      } else if (regex_npwp.length > 2) {
         regex_npwp = regex_npwp.replace(/(\d{2})/, '$1.')
      }
      // console.log(regex_npwp);
      SetNpwp(regex_npwp)
   }

   //did mount
   useEffect(() => {
      // console.log('data_pibpeb_detail_didmount');
      // console.log(data_pibpeb);

      searchEcoo(data_nib)
   }, [])

   return (
      <div className="detail-nib-container">
         <div className="penelusuran-content-title">
            <div className="penelusuran-content-sub-title">
               <div className="penelusuran-text-title">{t('track_nib')}</div>
               <div className="penelusuran-input-search row m-0 justify-content-center">
                  <div className="col-md-3 mb-2">
                     <InputCapsule
                        placeholder={t('enter_npwp')}
                        value={no_npwp}
                        onChange={(e) => RegexNPWP(e.target.value)}
                     />
                  </div>
                  <div className="col-md-3 mb-2">
                     <InputSearch
                        placeholder={t('look_the_nib')}
                        onChange={(e) => SetNib(e.target.value)}
                        onKeyDown={() =>
                           searchEcoo({
                              no_npwp: data_npwp.current,
                              nomor: no_nib,
                           })
                        }
                        onClick={() =>
                           searchEcoo({
                              no_npwp: data_npwp.current,
                              nomor: no_nib,
                           })
                        }
                     />
                  </div>
               </div>
            </div>
         </div>
         <div className="detail-pibpeb-content">
            {is_progress === true ? (
               <div style={{ height: '25vw' }} className="detail-content-load">
                  <LoadProgress />
               </div>
            ) : dataNibHead.length === 0 ? (
               <div className="detail-content-load">
                  <ListEmpty message_error={error_message} />
               </div>
            ) : (
               <>
                  <div className="detail-title">
                     <p className="detail-title-text">{t('detail-nib')}</p>
                  </div>
                  <div className="detail-atas">
                     <div className="detail-container">
                        <p className="detail-judul">{t('nib')}</p>
                        <p className="detail-hasil">
                           {dataNibHead.nib === '' ? '-' : dataNibHead.nib}
                        </p>
                     </div>
                     <div className="detail-container">
                        <p className="detail-judul">{t('npwp')}</p>
                        <p className="detail-hasil">
                           {dataNibHead.npwp === '' ? '-' : dataNibHead.npwp}
                        </p>
                     </div>
                     <div className="detail-container">
                        <p className="detail-judul">{t('company-name')}</p>
                        <p className="detail-hasil">
                           {dataNibHead.nama_perusahaan === ''
                              ? '-'
                              : dataNibHead.nama_perusahaan}
                        </p>
                     </div>
                     <div className="detail-container-last">
                        <p className="detail-judul">{t('category')}</p>
                        <p className="detail-hasil">
                           {/* {dataNibHistory.find(item => item.waktu_oss === null) !== undefined ? dataNibHistory[1].status : dataNibHistory.length !== 0 ? dataNibHistory[2].status : '-'} */}
                           {dataNibHead.kategori === ''
                              ? '-'
                              : dataNibHead.kategori}
                        </p>
                     </div>
                     {/* <div className="detail-container">
                                <p className="detail-judul">{t('time-oss')}</p>
                                <p className="detail-hasil">{dataNib.data_header.waktu_oss === '' ? '-' : moment(dataNib.data_header.waktu_oss).format('DD MMMM YYYY HH:mm')}</p>
                            </div>
                            <div className="detail-container-last">
                                <p className="detail-judul">{t('time-ciesa')}</p>
                                <p className="detail-hasil">{dataNib.data_header.waktu_ceisa === '' ? '-' : moment(dataNib.data_header.waktu_ceisa).format('DD MMMM YYYY HH:mm')}</p>
                            </div> */}
                  </div>
                  <div className="detail-title">
                     <p className="detail-title-text">{t('process_history')}</p>
                  </div>
                  <div className="detail-bawah">
                     <div className="detail-bawah-container">
                        {dataNibHistory.map((item, index) => (
                           <div className="detail-bawah-content">
                              <TimeLineNib
                                 status={item.status}
                                 keterangan={item.ket}
                                 time={
                                    item.waktu_oss !== null
                                       ? moment(
                                            item.waktu_oss,
                                            'DD-MM-YYYY HH:mm:ss',
                                         ).format('DD MMMM YYYY HH:mm')
                                       : '-'
                                 }
                                 choiceIC={dataIC}
                                 index={index}
                                 totalRow={dataNibHistory.length}
                                 beforeTime={item.beforeTime}
                                 nextTime={item.nextTime}
                              />
                           </div>
                        ))}
                     </div>
                     {/* <div className="detail-bawah-content">
                                <TimeLineNib status={'status'} keterangan={'keterangan'} time={'-'} index={0} beforeTime={'-'} nextTime={'#'} />
                            </div>
                            <div className="detail-bawah-content">
                                <TimeLineNib status={'status'} keterangan={'keterangan'} time={'-'} index={1} beforeTime={'#'} nextTime={'#'} />
                            </div>
                            <div className="detail-bawah-content">
                                <TimeLineNib status={'status'} keterangan={'keterangan'} time={'-'} index={2} beforeTime={'#'} nextTime={'-'} />
                            </div> */}
                  </div>
               </>
            )}
         </div>
      </div>
   )
}

export default DetailNib
