import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";

import { Form, Button, FormGroup, Col, Container, Row } from "react-bootstrap";
import * as Api from "../../../constants/api";
import { Input, Alert } from "reactstrap";
//import { Button } from "bootstrap";
import Footer from "../../KNFP/Footer";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import {
  getLanguageStorageUpperCaseKNFP,
  setLanguageStorage,
} from "../../../constants/storage";
import "./kontak.scss";
import ReCAPTCHA from 'react-google-recaptcha';
import { select } from "react-cookies";
import Select from 'react-select';
import { Modal, Badge, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import AsyncSelect from 'react-select/async';
import { clearConfig } from "dompurify";
import { alertSuccessCreateEnquiry, alertErrorCreateEnquiry } from "./helper/CreateEnquiryAlert";
import { useEnquiry } from "./hooks/useEnquiry";

const BasicForms = () => {
  const isBahasa = getLanguageStorageUpperCaseKNFP() == "ID";
  const { sendEmail, parseSubOrSubSubTopics } = useEnquiry();

  const [Pengirim, setPengirim] = useState(null);
  const [Instansi, setInstansi] = useState(null);
  const [Email, setEmail] = useState(null);
  const [Negara, setNegara] = useState(null);
  const [Topik, setTopik] = useState([]);
  const [TopikValue, setTopikValue] = useState([]);
  const [NoTelp, setNoTelp] = useState([]);
  const [EmailKl, setEmailKl] = useState('');
  const [SubTopik, setSubTopik] = useState('');
  const [SubSubTopik, setSubSubTopik] = useState('');
  const [Question, setQuestion] = useState('');
  const [_SubTopik, setVSubTopik] = useState([]); // for value (id)
  const [hideQuestion, setHideQuestion] = useState("");
  const [Pesan, setPesan] = useState(null);
  const [File, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [data5, setData5] = useState([]);
  const [dataError, setDataError] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const { t } = useTranslation();
  const [visible, setVisible] = useState("hidden");
  const [Answer, setAnswer] = useState("hidden");
  const [disableForm, setDisableForm] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState(false);
  const [show, setShow] = useState(false);
  const [UserRating, setUserRating] = useState(5);
  const [copyCaption, setCopyCaption] = useState(false);
  const toggle = () => setShow(!show);

  const getOption = () => {
    const url =
      Api.KNFP + "enquiry/ref?lang=" + getLanguageStorageUpperCaseKNFP();
    axios({
      method: "get",
      url: url,
      headers: { 'insw-key': Api.KNFP_AUTH_KEY, 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then((response) => {
        let sesuatu = response.data.result.data;
        if (getLanguageStorageUpperCaseKNFP() == "ID") {
          sesuatu.unshift({
            id_tp: 0,
            topic: "-------- Pilih Topik --------",
            isDisabled: true
          });
        } else {
          sesuatu.unshift({
            id_tp: 0,
            topic: "-------- Choose Topic --------",
            isDisabled: true
          });
        }
        // console.log("detail_kontak", response.data.data);
        let reMapData = sesuatu.map(newTopik => ({
          value: newTopik.id_tp,
          label: newTopik.topic,
          noTelp: newTopik.no_telp,
          email: newTopik.email,
          isDisabled: newTopik.isDisabled || false
        }))
        if (response.data.result.code === 200) {
          setData(reMapData);
          console.log(reMapData);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setData([]);
      });
  };

  const getSubtopic = (topik) => {
    setSubTopik('')
    const url =
      Api.KNFP +
      `enquiry/ref?id_tp=${topik}&lang=` +
      getLanguageStorageUpperCaseKNFP();
    axios({
      method: "get",
      url: url,
      headers: { 'insw-key': Api.KNFP_AUTH_KEY, 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then((response) => {
        let subTopics = response.data.result.data;
        // if (getLanguageStorageUpperCaseKNFP() == "ID") {
        //   subTopics.unshift({
        //     id_stp: 0,
        //     subtopic: "-------- Pilih Subtopik --------",
        //     isDisabled: true
        //   });
        // } else {
        //   subTopics.unshift({
        //     id_stp: 0,
        //     subtopic: "-------- Choose Subtopic --------",
        //     isDisabled: true
        //   });
        // }
        // console.log("detail_kontak", response.data.data);
        // let reMapData = subTopics.map(newTopik => ({
        //   value: newTopik.id_stp,
        //   label: newTopik.subtopic,
        //   isDisabled: newTopik.isDisabled || false
        // }))
        // // Group label into one
        // let newSubTopic = [];
        // reMapData.forEach(function (item) {
        //   var existing = newSubTopic.filter(function (v, i) {
        //     return v.label == item.label;
        //   });
        //   if (existing.length) {
        //     var existingIndex = newSubTopic.indexOf(existing[0]);
        //     newSubTopic[existingIndex].value = newSubTopic[existingIndex].value.concat(item.value);
        //   } else {
        //     if (typeof item.value == 'number')
        //       item.value = [item.value];
        //     newSubTopic.push(item);
        //   }
        // });
        // const formmattedSubTopics = subTopics.map(subtopic => ({
        //   value: subtopic.id_stp,
        //   label: subtopic.subtopic,
        //   isDisabled: false
        // }));

        if (response.data.result.code === 200) {
          parseSubOrSubSubTopics(subTopics, "subtopic",(formattedSubOrSubSubTopics) => {
            setData2(formattedSubOrSubSubTopics);
          })
        } else {
          setData2([]);
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setData2([]);
      });
  };

  const getSubSubtopic = (subtopik) => {
    console.log("getsub", subtopik);
    console.log(
      Api.KNFP +
      `enquiry/ref?id_stp=${subtopik}&lang=` +
      getLanguageStorageUpperCaseKNFP()
    );
    const url =
      Api.KNFP +
      `enquiry/ref?id_stp=${subtopik}&lang=` +
      getLanguageStorageUpperCaseKNFP();
    axios({
      method: "get",
      url: url,
      headers: { 'insw-key': Api.KNFP_AUTH_KEY, 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then((response) => {
        let subSubTopics = response.data.result.data;

        // if (getLanguageStorageUpperCaseKNFP() == "ID") {
        //   subSubTopics.unshift({
        //     id_sstp: 0,
        //     subsubtopic: "-------- Pilih Subsubtopik --------",
        //     isDisabled: true
        //   });
        // } else {
        //   subSubTopics.unshift({
        //     id_sstp: 0,
        //     subsubtopic: "-------- Choose Subsubtopic --------",
        //     isDisabled: true
        //   });
        // }
        // console.log("detail_kontak", response.data.data);
        // let reMapData = subSubTopics.map(newTopik => ({
        //   value: newTopik.id_sstp,
        //   label: newTopik.subsubtopic,
        //   isDisabled: newTopik.isDisabled || false
        // }))
        // // Group label into one
        // let newSubTopic = [];
        // reMapData.forEach(function (item) {
        //   var existing = newSubTopic.filter(function (v, i) {
        //     return v.label == item.label;
        //   });
        //   if (existing.length) {
        //     var existingIndex = newSubTopic.indexOf(existing[0]);
        //     newSubTopic[existingIndex].value = newSubTopic[existingIndex].value.concat(item.value);
        //   } else {
        //     if (typeof item.value == 'number')
        //       item.value = [item.value];
        //     newSubTopic.push(item);
        //   }
        // });

        // const formmattedSubSubTopics = subSubTopics.map(subsubtopic => ({
        //   value: subsubtopic.id_sstp,
        //   label: subsubtopic.subsubtopic,
        //   isDisabled: false
        // }));

        if (response.data.result.code === 200) {
          parseSubOrSubSubTopics(subSubTopics, "subsubtopic", (formattedSubOrSubSubTopics) => {
            setData3(formattedSubOrSubSubTopics);
          })
        } else {
          setData3([]);
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setData3([]);
      });
  };

  const getQuestionAnswer = (subsubtopic) => {
    console.log(
      Api.KNFP +
      `enquiry/question-answer?id_sstp=${subsubtopic}&lang=` +
      getLanguageStorageUpperCaseKNFP()
    );
    const url =
      Api.KNFP +
      `enquiry/question-answer?id_sstp=${subsubtopic}&lang=` +
      getLanguageStorageUpperCaseKNFP();
    axios({
      method: "get",
      url: url,
      headers: { 'insw-key': Api.KNFP_AUTH_KEY, 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then((response) => {
        let tambahan = response.data.result.data;
        if (getLanguageStorageUpperCaseKNFP() == "ID") {
          tambahan.unshift({
            id_qa: 0,
            questioner: "-------- Pilih Pertanyaan --------",
            isDisabled: true
          });
        } else {
          tambahan.unshift({
            id_qa: 0,
            questioner: "-------- Choose Question --------",
            isDisabled: true
          });
        }
        // console.log("detail_kontak", response.data.data);
        let newTambahan = tambahan.map(dataQuestion => ({
          label: dataQuestion.questioner,
          value: dataQuestion.id_qa,
          isDisabled: dataQuestion.isDisabled || false
        }))
        if (response.data.result.code === 200) {
          setData4(tambahan);
          setData5(newTambahan);
        } else {
          setData4([]);
          setData5([]);
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setData4([]);
      });
  };

  const getAnswer = (question) => {
    if (
      Pengirim != "" && Pengirim != null &&
      Email != "" && Email != null &&
      Negara != "" && Negara != null &&
      Topik != "" && Topik != null &&
      Question != "" && Question != null
    ) {
      setAnswer("");
    } else {
      Swal.fire({
        text: "Mohon melengkapi data yang bertanda (*)",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Tutup",
      })
    }
  };

  const setNewFormQuestion = (e) => {
    setSelectedOpt(e)
    if (e == "2") {
      setVisible("");
      setAnswer("hidden");
      setDisableForm(true);
      setHideQuestion("hidden");
    } else if (e == "1") {
      toggle()
    }
  };

  const resetAnswer = (tg) => {
    setVisible("hidden");
    setAnswer("hidden");
    setDisableForm(false);
    setHideQuestion("");
    setSelectedOpt("true");
    setSelectedOpt("0");
    if (tg == "closeToggle") {
      toggle();
    }
  };

  const handleChangeRC = (value) => {
    setIsVerified(true)
  }

  useEffect(() => {
    getOption();
  }, []);

  const handleOnChangeTopic = (event) => {
    console.log("event", event)
    console.log("event", event)
    setTopik(event.value)
    setTopikValue(event.label)
    setNoTelp(event.noTelp)
    console.log("event", event)
    setEmailKl(event.email)
    getSubtopic(event.value);
    setSubTopik('');
    setSubSubTopik('');
    setQuestion('');
  }

  const handleOnChangeSubTopic = (event) => {
    setSubTopik(event.label);
    setVSubTopik(event.value);
    getSubSubtopic(event.value);
    setSubSubTopik('');
    setQuestion('');
  }

  const handleOnChangeSubSubTopic = (event) => {
    setSubSubTopik(event.value);
    getQuestionAnswer(event.value);
    setQuestion('');
  }

  const handleOnChangeQuestion = (event) => {
    setQuestion(event.value);
  }

  const handleOnChangeNewForm = (event) => {
    setNewFormQuestion(event.value);
  }

  const verifyAnswer = [
    { value: '0', label: '-' },
    { value: '1', label: isBahasa ? 'Ya' : 'Yes' },
    { value: '2', label: isBahasa ? 'Tidak' : 'No' }
  ]

  const handleChangeFile = (e) => {
    const file = e.target.files[0];
    const fileSize = file.size;

    
          setFile(file);
    
  };

  const sendWhatsAppMessage = async (phoneNumber, topic, question) => {
    const url = `${Api.KNFP}enquiry/send_whatsapp_by_knfp`;
            
    const headers = {
      'Authorization': 'Basic aW5zd18yOmJhYzJiYXM2',
      'Origin': 'https://insw.go.id',
      'Content-Type': 'application/json',
    };
    
    const body = JSON.stringify({
      phone_number: phoneNumber,
      topic: topic,
      question: question,
    });

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: body,
      });

      if (response.ok) {
        const jsonResponse = await response.json();
        console.log('WhatsApp message sent successfully:', jsonResponse);
      } else {
        console.error('Error sending WhatsApp message:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };



  const submitData = (e) => {
    

    if (isVerified) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("nama_pengirim", Pengirim);
      formData.append("instansi_pengirim", Instansi);
      formData.append("email_pengirim", Email);
      formData.append("negara_pengirim", Negara);
      formData.append("kd_topic", Topik);
      formData.append("kd_subtopic", _SubTopik);
      formData.append("kd_subsubtopic", SubSubTopik);
      formData.append("pesan", Pesan);
      formData.append("file", File);
      formData.append("rating", UserRating);
      formData.append("status_pertanyaan", "contact_point");

      // if (isBahasa) {
      Swal.fire({
        text: isBahasa ? "Apakah anda yakin?" : "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: isBahasa ? "Kirim" : "Send",

      }).then((result) => {
        if (result.isConfirmed) {
          try {
            // Get the file from formData (assuming the file input name is 'file')
            const file = formData.get('file');

            // Check if the file exists, is a PDF, and is under 2MB
            if (file && file.name) { // Check if the file is not empty
              if (file.type !== 'application/pdf') {
                alertErrorCreateEnquiry( isBahasa ? "Hanya file PDF yang diperbolehkan." : "Only PDF files are allowed.", isBahasa);
                return;
              }

              if (file.size > 2 * 1024 * 1024) { // 2MB in bytes
                alertErrorCreateEnquiry(isBahasa ? "Ukuran file harus dibawah 2MB." :  "The file size should be under 2MB.", isBahasa);
                return;
              }
            }

            axios
              .post(`${Api.KNFP}enquiry/fowward`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  'insw-key': Api.KNFP_AUTH_KEY,
                  'Authorization': Api.BASIC_AUTH
                },
              })
              .then(async (res) => {

                if (res.data.code === 200) {
                  const response = res.data.data[0];
                  const ticketNumber = response.enquiry_id_new;
                  await sendEmail({
                    address: response.email_pengirim,
                    subject: 'Notifikasi KNFP: Pertanyaan Diterima',
                    text: `   <h1 style="margin: 10px 0px;">Pertanyaan Baru Diterima</h1>
                              <div style="text-align: left"> 
                              Anda telah mengajukan pertanyaan di website KNFP. 
                              <br />
                              Berikut adalah detail pertanyaan yang diajukan:
                              <br /><br />
                              Topik: ${TopikValue} <br />
                              Pertanyaan: ${response.pesan} <br />
                              Nomor Tiket : ${ticketNumber} <br />
                              <br /><br />
                              Mohon menunggu jawaban atas pertanyaan ini. 
                              <br />
                              Terima kasih atas kerjasamanya.
                              <br /><br />
                              untuk informasi lengkap silahkan klik link di bawah ini
                              <br /><br />
                              https://insw.go.id/knfp
                              </div>

                            `
                  })

                  await sendEmail({
                    address: "sandipermana88@gmail.com",
                    subject: 'Notifikasi KNFP: Pertanyaan Diterima',
                    text: `   <h1 style="margin: 10px 0px;">Pertanyaan Baru Diterima</h1>
                              <div style="text-align: left"> 
                              Anda telah mengajukan pertanyaan di website KNFP. 
                              <br />
                              Berikut adalah detail pertanyaan yang diajukan:
                              <br /><br />
                              Topik: ${TopikValue} <br />
                              Pertanyaan: ${response.pesan} <br />
                              Nomor Tiket : ${ticketNumber} <br />
                              <br /><br />
                              Mohon menunggu jawaban atas pertanyaan ini. 
                              <br />
                              Terima kasih atas kerjasamanya.
                              <br /><br />
                              untuk informasi lengkap silahkan klik link di bawah ini
                              <br /><br />
                              https://insw.go.id/knfp
                              </div>

                            `
                  })

                  // untuk contact point yang bersangkutan
                  await sendEmail({
                    address: EmailKl,
                    subject: 'Notifikasi KNFP: Pertanyaan Diterima',
                    text: `   <h1 style="margin: 10px 0px;">Pertanyaan Diterima</h1>
                              <div style="text-align: left"> 
                              Anda telah menerima pertanyaan baru di website KNFP. 
                              <br />
                              Berikut adalah detail pertanyaan yang diajukan:
                              <br /><br />
                              Topik: ${TopikValue} <br />
                              Pertanyaan: ${response.pesan} <br />
                              Nomor Tiket : ${ticketNumber} <br />
                              <br /><br />
                              Mohon segera meninjau dan memberikan jawaban atas pertanyaan ini. 
                              <br />
                              Terima kasih atas kerjasamanya.
                              <br /><br />
                              untuk informasi lengkapnya silakan klik link di bawah ini
                              <br /><br />
                              https://home.insw.go.id/cms-knfp/enquiry
                              </div>
                            `
                  })

                  // untuk contact point yang bersangkutan M. Rizki Baidillah
                  await sendEmail({
                    address: "m.rizki@kemenkeu.go.id",
                    subject: 'Notifikasi KNFP: Pertanyaan Baru Diterima',
                    text: `   <h1 style="margin: 10px 0px;">Pertanyaan Diterima</h1>
                              <div style="text-align: left"> 
                              Anda telah menerima pertanyaan baru di website KNFP. 
                              <br />
                              Berikut adalah detail pertanyaan yang diajukan:
                              <br /><br />
                              Topik: ${TopikValue} <br />
                              Pertanyaan: ${response.pesan} <br />
                              Nomor Tiket : ${ticketNumber} <br />
                              <br /><br />
                              Mohon segera meninjau dan memberikan jawaban atas pertanyaan ini. 
                              <br />
                              Terima kasih atas kerjasamanya.
                              <br /><br />
                              untuk informasi lengkapnya silakan klik link di bawah ini
                              <br /><br />
                              https://home.insw.go.id/cms-knfp/enquiry
                              </div>
                            `
                  })

                  // untuk contact point yang bersangkutan Noor Afif Fauzi
                  await sendEmail({
                    address: "arsip.asdep2@gmail.com",
                    subject: 'Notifikasi KNFP: Pertanyaan Baru Diterima',
                    text: `   <h1 style="margin: 10px 0px;">Pertanyaan Diterima</h1>
                              <div style="text-align: left"> 
                              Anda telah menerima pertanyaan baru di website KNFP. 
                              <br />
                              Berikut adalah detail pertanyaan yang diajukan:
                              <br /><br />
                              Topik: ${TopikValue} <br />
                              Pertanyaan: ${response.pesan} <br />
                              Nomor Tiket : ${ticketNumber} <br />
                              <br /><br />
                              Mohon segera meninjau dan memberikan jawaban atas pertanyaan ini. 
                              <br />
                              Terima kasih atas kerjasamanya.
                              <br /><br />
                              untuk informasi lengkapnya silakan klik link di bawah ini
                              <br /><br />
                              https://home.insw.go.id/cms-knfp/enquiry
                              </div>
                            `
                  })

                  // untuk contact point yang bersangkutan Yuliawati Cahyaningrum
                  await sendEmail({
                    address: "dpopd@kemendag.go.id",
                    subject: 'Notifikasi KNFP: Pertanyaan Baru Diterima',
                    text: `   <h1 style="margin: 10px 0px;">Pertanyaan Diterima</h1>
                              <div style="text-align: left"> 
                              Anda telah menerima pertanyaan baru di website KNFP. 
                              <br />
                              Berikut adalah detail pertanyaan yang diajukan:
                              <br /><br />
                              Topik: ${TopikValue} <br />
                              Pertanyaan: ${response.pesan} <br />
                              Nomor Tiket : ${ticketNumber} <br />
                              <br /><br />
                              Mohon segera meninjau dan memberikan jawaban atas pertanyaan ini. 
                              <br />
                              Terima kasih atas kerjasamanya.
                              <br /><br />
                              untuk informasi lengkapnya silakan klik link di bawah ini
                              <br /><br />
                              https://home.insw.go.id/cms-knfp/enquiry
                              </div>
                            `
                  })

                  // untuk contact point yang bersangkutan Rindang Mawardani 
                  await sendEmail({
                    address: "rindangmawardani.setkab@gmail.com",
                    subject: 'Notifikasi KNFP: Pertanyaan Baru Diterima',
                    text: `   <h1 style="margin: 10px 0px;">Pertanyaan Diterima</h1>
                              <div style="text-align: left"> 
                              Anda telah menerima pertanyaan baru di website KNFP. 
                              <br />
                              Berikut adalah detail pertanyaan yang diajukan:
                              <br /><br />
                              Topik: ${TopikValue} <br />
                              Pertanyaan: ${response.pesan} <br />
                              Nomor Tiket : ${ticketNumber} <br />
                              <br /><br />
                              Mohon segera meninjau dan memberikan jawaban atas pertanyaan ini. 
                              <br />
                              Terima kasih atas kerjasamanya.
                              <br /><br />
                              untuk informasi lengkapnya silakan klik link di bawah ini
                              <br /><br />
                              https://home.insw.go.id/cms-knfp/enquiry
                              </div>
                            `
                  })

                  // untuk contact point yang bersangkutan SEKRETARIAT KNFP Melly Anggraini
                  await sendEmail({
                    address: "sekretariat.knfp@kemendag.go.id",
                    subject: 'Notifikasi KNFP: Pertanyaan Baru Diterima',
                    text: `   <h1 style="margin: 10px 0px;">Pertanyaan Diterima</h1>
                              <div style="text-align: left"> 
                              Anda telah menerima pertanyaan baru di website KNFP. 
                              <br />
                              Berikut adalah detail pertanyaan yang diajukan:
                              <br /><br />
                              Topik: ${TopikValue} <br />
                              Pertanyaan: ${response.pesan} <br />
                              Nomor Tiket : ${ticketNumber} <br />
                              <br /><br />
                              Mohon segera meninjau dan memberikan jawaban atas pertanyaan ini. 
                              <br />
                              Terima kasih atas kerjasamanya.
                              <br /><br />
                              untuk informasi lengkapnya silakan klik link di bawah ini
                              <br /><br />
                              https://home.insw.go.id/cms-knfp/enquiry
                              </div>
                            `
                  })

                  await sendEmail({
                    address: "sandipermana88@gmail.com",
                    subject: 'Notifikasi KNFP: Pertanyaan Baru Diterima',
                    text: `   <h1 style="margin: 10px 0px;">Pertanyaan Diterima</h1>
                              <div style="text-align: left"> 
                              Anda telah menerima pertanyaan baru di website KNFP. 
                              <br />
                              Berikut adalah detail pertanyaan yang diajukan:
                              <br /><br />
                              Topik: ${TopikValue} <br />
                              Pertanyaan: ${response.pesan} <br />
                              Nomor Tiket : ${ticketNumber} <br />
                              <br /><br />
                              Mohon segera meninjau dan memberikan jawaban atas pertanyaan ini. 
                              <br />
                              Terima kasih atas kerjasamanya.
                              <br /><br />
                              untuk informasi lengkapnya silakan klik link di bawah ini
                              <br /><br />
                              https://home.insw.go.id/cms-knfp/enquiry
                              </div>
                            `
                  })

                  // Send WhatsApp message untuk contact point yang bersangkutan
                  await sendWhatsAppMessage(NoTelp, TopikValue, response.pesan);

                  // Send WhatsApp message untuk M. Rizki Baidillah
                  await sendWhatsAppMessage("+62816105557", TopikValue, response.pesan);

                  // Send WhatsApp message untuk Noor Afif Fauzi
                  await sendWhatsAppMessage("+628170434263", TopikValue, response.pesan);

                  // Send WhatsApp message untuk Yuliawati Cahyaningrum
                  await sendWhatsAppMessage("+6281351287128", TopikValue, response.pesan);

                  // Send WhatsApp message untuk Rindang Mawardani
                  await sendWhatsAppMessage("+6281212901983", TopikValue, response.pesan);

                  // Send WhatsApp message untuk SEKRETARIAT KNFP Melly Anggraini
                  await sendWhatsAppMessage("+6281295840580", TopikValue, response.pesan);
                  
                  await sendWhatsAppMessage("+6282142796339", TopikValue, response.pesan);
                  
                  alertSuccessCreateEnquiry(ticketNumber, isBahasa)
                } else {
                  const swalParams = [
                    isBahasa ? "Gagal" : "Failed",
                    isBahasa ? "Gagal dalam mengirimkan data" : "Failed to send data",
                  ]

                  Swal.fire(swalParams[0], swalParams[1]);
                }
              });
            } catch (error){
              alertErrorCreateEnquiry(isBahasa ? "Silakan Periksa file Anda, hanya pdf dibawah 2mb yang dapat dilampirkan" : "Please Check your file, only pdf under 2mb can be attachment", isBahasa)
            }
        }
      });

    }
    else {
      alert("Mohon verifikasi CAPTCHA")
    }
  };

  const feedbackSend = () => {
    const payloadFeedback = new FormData();
    payloadFeedback.append("nama_pengirim", Pengirim);
    payloadFeedback.append("instansi_pengirim", Instansi);
    payloadFeedback.append("email_pengirim", Email);
    payloadFeedback.append("negara_pengirim", Negara);
    payloadFeedback.append("kd_topic", Topik);
    payloadFeedback.append("kd_subtopic", _SubTopik);
    payloadFeedback.append("kd_subsubtopic", SubSubTopik);
    payloadFeedback.append("pesan", Pesan);
    payloadFeedback.append("file", File);
    payloadFeedback.append("rating", UserRating);
    payloadFeedback.append("status_pertanyaan", "");

    axios.post(`${Api.KNFP}enquiry/fowward`, payloadFeedback, {
      headers: {
        "Content-Type": "multipart/form-data",
        'insw-key': Api.KNFP_AUTH_KEY,
        'Authorization': Api.BASIC_AUTH
      },
    })
      .then((res) => {
        if (res.data.code === 200) {
          toggle();
          window.location.reload();

          Swal.fire({
            title: "Berhasil",
            text: res.data.result.message,
            icon: "success",
            focusConfirm: true,
            customClass: { confirmButton: "sweetalert-ok" },
          }).then((res) => {
            window.location.reload();
          });
        } else {
          Swal.fire("Gagal", "Gagal dalam mengirimkan data");
        }
      });
  }


  const bahasaAfaNich = getLanguageStorageUpperCaseKNFP()

  console.log(bahasaAfaNich);

  return (
    <div>



      <Container className="mt-5 mb-5">

        <div className="sm-content text-center">
          <h3 className="sm-title">{t("enquiry")}</h3>
        </div>
        {/* <h3 className="sm-title">Enquiry Form</h3> */}
        <Row className="justify-content-center">
          <Col xs={12} md={6} lg={6}>
            {dataError && <Alert color="danger">{dataError}</Alert>}
            <>
              <form onSubmit={submitData}>
                <Form.Group>
                  <Form.Label class="required">{t("nama")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    required
                    disabled={disableForm}
                    onChange={(e) => {
                      setPengirim(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label >{t("perusahaan")}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder=""
                    disabled={disableForm}
                    onChange={(e) => {
                      setInstansi(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label class="required">{t("negara")}</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    //placeholder="Masukkan Negara Asal"
                    disabled={disableForm}
                    onChange={(e) => {
                      setNegara(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label class="required">{t("email")}</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    //placeholder="Masukan Email"
                    disabled={disableForm}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  {/* <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text> */}
                </Form.Group>


                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label class="required">{t("Topik")}</Form.Label>
                  <Select
                    placeholder={t("Pilih Topik")}
                    options={data}
                    onChange={handleOnChangeTopic}  
                    isLoading={!data?.length}
                  />
                </Form.Group>

                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label >{t("subtopik")}</Form.Label>
                  {/* <h1>{JSON.stringify(data2?.map(data => ({value: data.subtopic})))}</h1> */}
                  <Select
                    placeholder={t("Pilih SubTopic")}
                    options={data2}
                    value={data2?.length && SubTopik
                      ? data2.find(({ subtopic }) => subtopic === SubTopik)
                      : null
                    }
                    // value={_SubTopik}
                    onChange={handleOnChangeSubTopic}
                    isLoading={!data2?.length}
                  />
                </Form.Group>

                <Form.Group controlId="exampleForm.SelectCustomSizeSm">
                  <Form.Label >{t("SubSubTopik")}</Form.Label>
                  <Select
                    placeholder={t("Pilih SubSubTopik")}
                    options={data3}
                    value={data3?.length && SubSubTopik
                      ? data3.find(({ subsubtopic }) => subsubtopic === SubSubTopik)
                      : null}
                    onChange={handleOnChangeSubSubTopic}
                    isLoading={!data3?.length}
                  />
                </Form.Group>



                <Form.Group controlId="exampleForm.ControlTextarea1" hidden={visible}>
                  <Form.Label class="required">{t("pesan")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    required
                    onChange={(e) => {
                      setPesan(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form hidden={visible}>
                  {
                    File ? (
                      <div>
                        <Form.Label role="button" htmlFor="formcheck-api-regular" className="p-2 mr-2 text-light bg-primary">{isBahasa ? "Ubah file" : "Change file"}</Form.Label>
                        <Form.Label htmlFor="formcheck-api-regular">{File.name}</Form.Label>
                      </div>
                    ) : (
                      <div>
                        <Form.Label role="button" htmlFor="formcheck-api-regular" className="p-2 mr-2 text-light bg-primary">{isBahasa ? "Unggah file" : "Upload file"}</Form.Label>
                        <Form.Label htmlFor="formcheck-api-regular">{isBahasa ? "Tidak ada file yang dipilih" : "No file choosen"}</Form.Label>
                      </div>
                    )
                  }
                  <Form.File
                    // onChange={(e) => setFile(e.target.files[0])}
                    onChange={handleChangeFile}
                    id="formcheck-api-regular"
                    type="file"
                    hidden={true}
                    // accept="image/jpeg,image/jpg,image/png,application/pdf,.doc,.docx,.xls,.xlsx"
                    accept="pdf"
                  />
                  <Form.Text className="text-muted">{t("pemberitahuan")}</Form.Text>
                </Form>

                <div className="App mt-4" hidden={visible}>
                  <ReCAPTCHA
                    sitekey="6LfT26kcAAAAAOfTE3J3eivs1wXmgSrbt6aEqV-Q"
                    onChange={handleChangeRC}
                  />
                </div>



                <Form.Group controlId="exampleForm.SelectCustomSizeSm" hidden={hideQuestion}>
                  <Form.Label >{t("Pertanyaan")}</Form.Label>
                  <Select
                    placeholder={t("Pilih Pertanyaan")}
                    options={data5}
                    value={data5?.length && Question
                      ? data5.find(({ label }) => label === Question)
                      : null}
                    onChange={handleOnChangeQuestion}
                    isLoading={!data5?.length}
                  />
                </Form.Group>

                {/* <Form.Group controlId="exampleForm.SelectCustomSizeSm" hidden={hideQuestion}>
                  <Form.Label >{t("Pertanyaan")}</Form.Label>
                  <Select
                    placeholder="Pilih Pertanyaan"
                    options={data5}
                    value={data5?.length && Question
                      ? data5.find(({ label }) => label === Question)
                      : null}
                    onChange={handleOnChangeQuestion}
                    isLoading={!data5?.length}
                  />
                </Form.Group> */}


                <div class="bintang">
                  <h6>{t("bintang")}</h6>
                </div>

                <Row hidden={Answer}>
                  <Col>
                    <h6>{isBahasa ? "Jawaban: " : "Answer: "}</h6>
                    <h6>{data4 ? (data4[1] ? data4[1].answer : "") : ""}</h6>
                  </Col>
                </Row>

                <Form.Group controlId="exampleForm.SelectCustomSizeSm" hidden={Answer}>
                  <Form.Label >{t("alert_before_send_enquiry")}</Form.Label>
                  <Select
                    options={verifyAnswer}
                    onChange={handleOnChangeNewForm}
                  />
                  {/* </Form.Control> */}
                </Form.Group>

                <div className="mt-3">
                  {visible == "hidden" && Answer != "" ?
                    <Button className="btn btn-succes" onClick={() => getAnswer(hideQuestion)} >
                      Submit
                    </Button>
                    :
                    <Button disabled={!isVerified} className="btn btn-succes" type="submit">
                      Submit
                    </Button>
                  }

                  {/* Reset Button */}
                  {visible == "hidden" ? ""
                    :
                    <Button className="btn btn-succes ml-3" onClick={() => resetAnswer()}>
                      Reset
                    </Button>
                  }
                </div>

                <Modal isOpen={show} toggle={toggle} size="lg" >
                  <ModalHeader toggle={toggle} cssModule={{ 'modal-title': 'modal-title-tracking', 'close': 'modal-close-button' }}>Feedback</ModalHeader>
                  <ModalBody>
                    <Stack spacing={1}>
                      <Rating
                        name="half-rating"
                        defaultValue={5}
                        precision={1}
                        size="large"
                        onChange={(event, rating) => {
                          setUserRating(rating);
                        }} />
                    </Stack>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={() => feedbackSend()}>
                      Submit
                    </Button>
                  </ModalFooter>
                </Modal>

              </form>
            </>

          </Col>
        </Row>
      </Container>

      <Footer />
    </div>
  );
};

export default BasicForms;
