import React, { useEffect, useState } from 'react';
import './sitemap.scss';
import axios from 'axios';
import * as Api from '../../constants/api';
import { SitemapCollapse } from '../../components';

function SiteMap() {
    const [header_data, setHeaderData] = useState([]);

    //did mount
    useEffect(() => {
        _get_data_menu();
    }, []);

    const _get_data_menu = () => {
        const url = Api.GET_HEADER_MENU + '?lang=' + localStorage.getItem('language').toUpperCase();
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                // console.log('data menu peta situs');
                // console.log(response.data.data);
                if (response.data.code === '01') {
                    setHeaderData(response.data.data);
                } else {
                    setHeaderData([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setHeaderData([]);
            });
    }

    return (
        <div className="sitemap-container">
            <div className="sitemap-content">
                <div className="sitemap-content-header">
                    <div className="sitemap-content-title">Peta Situs</div>
                    <div className="sitemap-content-subtitle">Untuk mempermudah user yang mengakses website ini, kami sediakan map yang berisi struktur utama website ini. Dengan ini kami harapkan user dapat dengan mudah menemukan halaman untuk kontent yang ingin diakses.</div>
                </div>
                <div className="sitemap-content-body">
                {header_data.map((item) =>
                    item.id_menu !== '12' && item.id_menu !== '49' ?
                    <SitemapCollapse data={item} />
                    :
                    null
                )}
                </div>
            </div>
        </div>
    )
}

export default SiteMap
