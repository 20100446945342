import React from 'react';
import './input_round.scss';
import { Spinner } from 'reactstrap';

const InputRound = ({is_error, error_message, is_loading, image_icon, ...rest}) => {
    return (
        <div className="input-round-component">
            <div style={{borderColor: is_error === true ? 'red' : '#808080'}} className="input-round">
                <input className="input-round-field" {...rest} />
                <div className="input-round-icon">
                    <img src={image_icon} />
                    {is_loading === true ?
                        <Spinner size="sm" color="primary" />
                        : 
                        null
                    }
                </div>
            </div>
            {is_error === true ?
                <div className="input-round-text-error">{error_message}</div>
                :
                null
            }
        </div>
    )
}

export default InputRound;
