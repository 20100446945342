import image1 from '../../../assets/image/KnfpNews/news-1/Picture1.png'
import image2 from '../../../assets/image/KnfpNews/news-1/Picture2.png'
import image3 from '../../../assets/image/KnfpNews/news-1/Picture3.png'
import image4 from '../../../assets/image/KnfpNews/news-2/Picture4.png'
import image5 from '../../../assets/image/KnfpNews/news-2/Picture5.png'
import image6 from '../../../assets/image/KnfpNews/news-2/Picture6.png'
import image7 from '../../../assets/image/KnfpNews/news-2/Picture7.png'
import image8 from '../../../assets/image/KnfpNews/news-4/Picture8.png'
import image9 from '../../../assets/image/KnfpNews/news-4/Picture9.png'
import image10 from '../../../assets/image/KnfpNews/news-4/Picture10.png'
import image11 from '../../../assets/image/KnfpNews/news-4/Picture11.png'
import image12 from '../../../assets/image/KnfpNews/news-5/Picture12.png'
import image13 from '../../../assets/image/KnfpNews/news-5/Picture13.png'
import image14 from '../../../assets/image/KnfpNews/news-5/Picture14.png'
import image15 from '../../../assets/image/KnfpNews/news-6/Picture15.png'
import image16 from '../../../assets/image/KnfpNews/news-6/Picture16.png'
import image17 from '../../../assets/image/KnfpNews/news-6/Picture17.png'
import image18 from '../../../assets/image/KnfpNews/news-6/Picture18.png'
import image19 from '../../../assets/image/KnfpNews/news-7/Picture19.png'
import image20 from '../../../assets/image/KnfpNews/news-7/Picture20.png'
import image21 from '../../../assets/image/KnfpNews/news-7/Picture21.png'
import image22 from '../../../assets/image/KnfpNews/news-7/Picture22.png'
import image23 from '../../../assets/image/KnfpNews/news-8/Picture23.png'
import image24 from '../../../assets/image/KnfpNews/news-8/Picture24.png'
import image25 from '../../../assets/image/KnfpNews/news-8/Picture25.png'
import image26 from '../../../assets/image/KnfpNews/news-8/Picture26.png'

const knfpNews = [
   // BERITA 1
   {
      images: [image1, image2, image3],
      title: 'Rapat Koordinasi Komite Nasional Fasilitasi Perdagangan (KNFP)',
      postdate: 'Jakarta, 30 November 2023',
      contents: [
         `Direktur Fasilitasi Ekspor dan Impor selaku koordinator Sekretariat KNFP melaksanakan
          rapat koordinasi KNFP hybrid di Hotel Borobudur Jakarta yang dpimpin secara langsung
          oleh Plt. Deputi Bidang Koordinasi Perniagaan dan Industri selaku Ketua
          Pelaksana Harian KNFP. Dalam rapat ini diawali sambutan oleh Kepala Pengelola Portal INSW 
          serta dihadiri perwakilan Kemenko Bidang Perekonomian, Kementerian Keuangan, INSW, Kementerian 
          Perhubungan, Kementerian Pertanian, Kementerian Kesehatan, Kementerian Perindustrian, Kementerian 
          Lingkungan Hidup dan Kehutanan, Kementerian Luar Negeri, Kementerian Kelautan dan Perikanan, 
          Badan Standarisasi Nasional, Kementerian Investasi dan Badan Pengawas Obat dan Makanan.`,
         `
          Tujuan Pelaksanaan rapat kali ini bertujuan untuk mendapatkan masukan dari seluruh stakeholders 
          terkait (a) Finalisasi revisi Kepmenko No 199 Tahun 2018 jo Kepmenko No 284 Tahun 2018 tentang 
          KNFP dan Kepmendag No 406 Tahun 2020 tentang Sekretariat KNFP, (b) Dukungan dan komitmen atas peran 
          dan tugas masing-masing sesuai dengan Kepmenko dan Kepmendag tentang KNFP dan Program Kerja KNFP 
          pada 2023, serta (c) Pengembangan enquiry points untuk penyempurnaan komitmen artikel 1 Publikasi.
          “segera memperkuat kelembagaan melalui revisi peraturan terkait sekaligus mendukung usulan program 
          kerja KNFP tahun 2023, meningkatkan awareness sekaligus engagement dari seluruh stakeholders KNFP, 
          pengembangan website yang transparan, dan dukungan penganggaran di K/L” jelas Plt. 
          Deputi Bidang Koordinasi Perniagaan dan Industri selaku Ketua Pelaksana Harian KNFP.
          `,
         `
          Dalam rapat ini disepakati bahwa seluruh peserta rapat sepakat untuk memberikan dukungan dan komitmen 
          untuk menjadi bagian dari KNFP sesuai dengan peran dan tugas masing-masing sesuai dengan Kepmenko 
          tentang KNFP. Selanjutnya juga menyetujui untuk melaksanakan program kerja KNFP pada tahun 2023 yang 
          diusulkan oleh Sekretariat KNFP sebagai berikut: (i) Penguatan Kelembagaan Komite Nasional Fasilitasi 
          Perdagangan (KNFP) melalui Optimalisasi Mekanisme Kerja KNFP dan Implementasi Peraturan Terkait sesuai 
          dengan mandat pada artikel 23.2 WTO TFA; (ii) Pengembangan website KNFP sesuai dengan mandat pada 
          article 1 dan notifikasi transparansi; (iii) Monitoring dan Evaluasi Kesesuaian Implementasi TFA WTO; 
          dan (iv) Sosialisasi terkait dengan Implementasi TFA WTO.
          `,
      ],
   },
   //  BERITA 2
   {
      images: [image4, image5, image6, image7],
      title: 'Rapat Pembahasan Proses Bisnis Pengelolaan Enquiry Points TFA-WTO pada Portal KNFP',
      postdate: 'Bandung, 15 Februari 2023',
      contents: [
         `Direktur Fasilitasi Ekspor dan Impor selaku Koordinator Sekretariat KNFP melaksanakan 
         rapat rapat pembahasan proses bisnis pengelolaan enquiry points Trade Facilitation Agreement 
         World Trade Organization (TFA-WTO) pada portal Komite Nasional Fasilitasi Perdagangan (KNFP) 
         secara hybrid di Bandung.  Rapat dihadiri perwakilan dari Pelaksana Harian KNFP yang terdiri dari Dit. 
         Kerja Sama Internasional Kepabeanan dan Cukai – Ditjen Bea dan Cukai, Dit. Perundingan Organisasi 
         Perdagangan Dunia (DPOD) – Kementerian Perdagangan, Asisten Deputi Bidang Perdagangan, Perindustrian, 
         dan Ketenagakerjaan - Sekretariat Kabinet, serta anggota Sekretariat KNFP yaitu Dit. 
         Pengelolaan Layanan Data dan Kemitraan – LNSW, Dit. Teknologi Informasi – LNSW dan Pusat Data dan Informasi – 
         Kementerian Perdagangan.`,
         `
          ”Kami menekankan bahwa urgensi penyempurnaan enquiry points pada laman website KNFP yang merupakan 
          mandat article 1.3 TFA WTO, dimana masing – masing anggota WTO diminta untuk menetapkan satu atau lebih 
          “pusat informasi” untuk menjawab pertanyaan wajar atas aspek substansi yang tercantum dalam artikel 1.1 
          WTO – TFA tentang publikasi sekaligus hal ini menjadi salah satu program kerja KNFP tahun 2023”, 
          jelas Bambang Jaka Setiawan selaku selaku Koordinator Sekretariat KNFP 
          `,
         `
         Pada rapat ini setiap perwakilan K/L memberikan masukan terkait konsep proses bisnis pengelolaann enquiry 
         points di portal KNFP serta membahas detail status contact point, topic, sub topic dan sub-sub topic.
          `,
         `
         Rapat sepakat untuk untuk menggunakan proses bisnis opsi 2 dimana penyampaian enquiry points didahului 
         dengan daftar pertanyaan umum, namun jika membutuhkan informasi lebih lanjut dapat dilanjutkan dengan 
         mekanisme teks bebas. Terkait SLA, peserta rapat mengusulkan tanggapan untuk: (i) pertanyaan yang 
         bersifat umum maksimal 1 hari kerja, (ii) pertanyaan teks bebas yang bersifat low risk maksimal 5 hari 
         kerja dan (iii) pertanyaan yang bersifat high risk dengan rentang waktu 10 - 15 hari kerja.
          `,
      ],
   },
   //  BERITA 3
   {
      images: [],
      title: 'Rapat Pembahasan Proses Bisnis Pengelolaan Enquiry Points TFA-WTO pada Portal KNFP',
      postdate: 'Cikarang, 14 Maret 2023',
      contents: [
         `Direktur Fasilitasi Ekspor dan Impor selaku Koordinator Sekretariat KNFP melaksanakan rapat koordinasi Pembahasan Proses Bisnis Pengelolaan Enquiry Points TFA-WTO pada Portal KNFP yang dilaksanakan secara hybrid dan dihadiri perwakilan dari Pelaksana Harian KNFP dan seluruh contact points yang menjadi perwakilan masing-masing Koordinator Bidang yang terdiri dari 12 Kementerian/Lembaga.`,
         `Rapat bertujuan untuk mendapatkan masukan teknis dari KL terkait (Pelaksana Harian KNFP serta seluruh contact points yang menjadi perwakilan Koordinator Bidang KNFP) terkait dengan usulan konsep proses bisnis pengelolaan enquiry points di portal KNFP`,
         `“Menekankan urgensi penyempurnaan enquiry points pada laman website KNFP yang merupakan mandat article 1.3 TFA WTO, dimana masing – masing anggota WTO diminta untuk menetapkan satu atau lebih “pusat informasi” untuk menjawab pertanyaan wajar atas aspek substansi yang tercantum dalam artikel 1.1 WTO – TFA tentang publikasi sekaligus hal ini menjadi salah satu program kerja KNFP tahun 2023.” jelas Bambang Jaka Setiawan selaku selaku Koordinator Sekretariat KNFP.`,
         `Sekretariat KNFP telah menyusun 2 (dua) konsep usulan skema proses bisnis pengelolaan enquiry points yaitu (i) skema penyampaian enquiry yang hanya melibatkan 1 K/L (non-cross cutting) dan (ii) skema penyampaian enquiry yang hanya melibatkan lebih dari 1 K/L (cross cutting). Kedua skema tersebut didahului dengan daftar pertanyaan umum, namun jika membutuhkan informasi lebih lanjut dapat dilanjutkan dengan mekanisme teks bebas. Usulan skema tersebut telah dibahas bersama anggota pelaksana harian KNFP pada pertemuan tanggal 15 Februari dan 13 Maret 2023. Pada usulan tersebut juga akan dilengkapi dengan kolom feedback sebagai umpan balik dari penanya atas respon yang diberikan oleh contact points, layanan tracking untuk menelusuri status posisi pertanyaan, dan serta SLA internal sebagai komitmen bersama KNFP untuk menindaklanjuti pertanyaan yang masuk.`,
         `Secara umum perwakilan contact point dari masing-masing K/L telah memahami usulan skema proses bisnis tersebut dan mendukung implementasi enquiry points sebagai saluran informasi dan komunikasi para stakeholders dengan KNFP sebagai perwujudan komitmen artikel TFA 1.3 WTO. Memperhatikan kebutuhan fitur yang berbeda antar anggota KNFP dalam proses enquiry points tersebut sesuai dengan peran masing – masing, maka LNSW sepakat untuk menyediakan hak akses untuk mengakses fitur yang sesuai kebutuhan.`,
      ],
   },
   //  BERITA 4
   {
      images: [image8, image9, image10, image11],
      title: 'Rapat Koordinasi Dalam Rangka Updating Progress Pengembangan Sistem Enquiry Points pada Laman Website KNFP',
      postdate: 'Bogor, 17 Mei 2023',
      contents: [
         `Direktur Fasilitasi Ekspor dan Impor selaku Koordinator Sekretariat KNFP melaksanakan Rapat Koordinasi Dalam Rangka Updating Progress Pengembangan Sistem Enquiry Points pada Laman Website KNFP. Rapat dihadiri oleh perwakilan dari anggota Pelaksana Harian KNFP, Lembaga Nasional Single Window, dan Pusat Data Sistem Informasi (PDSI) Kementerian Perdagangan. Tujuan rapat untuk mendapatkan masukan atau tanggapan dari anggota Pelaksana Harian atas updating progress pengembangan sistem enquiry points pada laman website KNFP yang telah koordinasikan bersama dengan LNSW.`,
         `“Berdasarkan rapat koordinasi pada tahun 2022, KNFP sepakat untuk mengubah contact point Indonesia menjadi 1 pintu di bawah KNFP melalui portal website https://insw.go.id/knfp dan email sekretariat.knfp.go.id. Hal tersebut ditindaklanjuti dengan LNSW telah menyiapkan portal KNFP dimaksud, namun demikian masih perlu dilakukan penyempurnaan khususnya pada kolom enquiry points. Dalam hal penyempurnaan tersebut, Kami selaku Koordinator Sekretariat KNFP telah menyusun rencana aksi pengembangan enquiry points. Dalam hal ini juga mencakup penyusunan proses bisnis enquiry points,” Jelas Bambang Jaka Setiawan selaku Koordinator Sekretariat KNFP.`,
         `Beberapa poin hasil diskusi bahwa disepakati tidak mengkategorikan enquiry yang masuk menjadi high risk dan low risk. Terkait dengan peran pelaksana harian yang cukup vital dalam penanganan enquiry points yaitu menindaklanjuti pertanyaan yang perlu koordinasi lanjutan, perwakilan Asdep Fasilitasi Perdagangan selaku anggota Pelaksana Harian menyampaikan usulan agar hal tersebut sebaiknya menjadi tugas Sekretariat KNFP. Terhadap hal tersebut, kami menyampaikan bahwa usulan dimaksud tidak sejalan dengan tugas Sekretariat KNFP sebagaimana diatur pada Pasal 5 Kepmenko 168 Tahun 2023, dimana Sekretariat KNFP memilki tugas menindaklanjuti arahan Ketua Pelaksana Harian dalam pelaksanaan kebijakan yang terkait dengan isu di bidang fasilitasi perdagangan dan memberikan dukungan kesekretariatan kepada Pengarah, Pelaksana Harian, dan Koordinator Bidang dalam pelaksanaan koordinasi yang terkait dengan isu di bidang fasilitasi perdagangan. `,
      ],
   },
   //  BERITA 5
   {
      images: [image12, image13, image14],
      title: 'Rapat Koordinasi Finalisasi Proses Bisnis Enquiry Point pada Portal Website KNFP dan Pembahasan Lanjutan Penyusunan Revisi Kepmendag No. 406 Tahun 2020 Tentang Sekretariat KNFP',
      postdate: 'Tangerang, 16 Juni 2023',
      contents: [
         'Direktur Fasilitasi Ekspor dan Impor selaku Koordinator Sekretariat KNFP melaksanakan Rapat Koordinasi Finalisasi Proses Bisnis Enquiry Point pada Portal Website KNFP dan Pembahasan Lanjutan Penyusunan Revisi Kepmendag No. 406 Tahun 2020 Tentang Sekretariat KNFP, yang dihadiri oleh perwakilan anggota Pelaksana Harian KNFP, perwakilan dari Koordinator Bidang yang menjadi Contact Points KNFP, Lembaga Nasional Single Window (Dit. Teknologi Informasi), Kementerian Perdagangan (PDSI, Biro Hukum dan Rovodag).',
         'Tujuan rapat adalah untuk mendapatkan masukan/tanggapan yang konstruktif atas update proses bisnis sekaligus progress pengembangan sistem enquiry points pada laman website KNFP, dan konsep revisi Kepmendag No 406 Tahun 2020 tentang Sekretariat KNFP.',
         '“Penegasan komitmen dari semua anggota KNFP untuk tetap mendukung pelaksanaan seluruh program kerja KNFP. Tanpa komitmen dan sense of belonging dari seluruh anggota KNFP, maka implementasi setiap program kerja KNFP dikhawatirkan tidak dapat berjalan dengan optimal. Pengembangan enquiry points merupakan salah satu program kerja KNFP Tahun 2023 dimana sebagai upaya penyempurnaan laman website KNFP, kami telah menyusun konsep skema pengembangan enquiry points, untuk selanjutnya LNSW akan melakukan penyesuaian/updating ke dalam system berdasarkan masukan dari anggota KNFP”, tegas Bambang Jaka Setiawan selaku Koordinator Sekretariat KNFP.',
         'Reviu Kepmenko No 199 Tahun 2018 jo Kepmenko No 284 Tahun 2018 tentang KNFP dan Kepmendag No 406 Tahun 2020 tentang Sekretariat KNFP merupakan salah satu program kerja prioritas KNFP tahun 2023 yang juga telah diupayakan sejak tahun 2022. Selanjutnya sebagai tindak lanjut penerbitan Kepmenko No 168 Tahun 2023 tentang KNFP sebagai revisi Kepmenko No 199 Tahun 2018 jo Kepmenko No 284 Tahun 2018 tentang KNFP pada 27 Februari 2023, maka dipandang perlu untuk menyusun revisi Kepmendag No 406 Tahun 2020 tentang Sekretariat KNFP dengan tujuan mengoptimalisasi peran, tugas dan fungsi Sekretariat KNFP.',
         'Seluruh peserta rapat menyepakati konsep proses bisnis pengembangan sistem enquiry points pada laman website KNFP.  Perwakilan LNSW akan mulai mengembangkan sistem enquiry points pada laman website KNFP untuk kebutuhan uji sistem bagi masing-masing stakeholders KNFP sesuai dengan perannya dalam proses bisnis.',
      ],
   },
   //  BERITA 6
   {
      images: [image15, image16, image17, image18],
      title: 'Rapat Koordinasi KNFP terkait Pembahasan Finalisasi Daftar Pertanyaan dan Jawaban Umum pada Kolom Enquiry Point di Laman Website KNFP dan Pendaftaran Hak Akses Pengelola Enquiry Point',
      postdate: 'Bandung, 24 Juli 2023',
      contents: [
         'Direktur Fasilitasi Ekspor dan Impor selaku Koordinator Sekretariat KNFP melaksanakan Rapat Koordinasi KNFP terkait Pembahasan Finalisasi Daftar Pertanyaan dan Jawaban Umum pada Kolom Enquiry Point di Laman Website KNFP dan Pendaftaran Hak Akses Pengelola Enquiry Point yang dihadiri oleh perwakilan dari anggota Pelaksana Harian KNFP, 13 (tiga belas) K/L selaku Contact Point KNFP serta Direktorat Teknologi Informasi – LNSW. ',
         'Tujuan pelaksanaan rapat untuk mengkonfirmasi seluruh daftar pertanyaan dan jawaban umum pada kolom enquiry point sebagaimana permohonan masukan/tanggapan yang telah kami sampaikan sebelumnya melalui surat resmi pada 26 Mei 2023 dan 21 Juni 2023. Selain itu, untuk mempercepat pelaksanan uji sistem yang ditargetkan untuk uji sistem pada awal semester 2 tahun 2023, pada kesempatan yang sama kami juga mengingatkan kembali agar pelaksana harian dan contact point dapat segera menyampaikan nama pegawai yang akan ditunjuk sebagai pemilik hak akses di kolom enquiry point di laman website KNFP untuk selanjutnya melakukan registrasi akun LNSW.',
         '“Apresiasi kepada 6 (enam) CP yaitu Dit Perundingan Organisasi Perdagangan Dunia – Kementerian Perdagangan; Sekretaris Ditjen Perdagangan Luar Negeri – Kemendag; Dit. Kerja Sama Internasional Kepabeanan dan Cukai – Kementerian Keuangan; Dit. Pengelolaan Layanan, Data dan Kemitraan - LNSW; Dit Pelayanan Perizinan Berusaha Sektor Industri, BKPM; Biro Kerjasama dan Hubungan Masyarakat, BPOM atas penyampaian daftar pertanyaan dan jawaban umum sesuai dengan masing-masing topik dan sub topik kepada Koordinator Sekretariat KNFP. Terhadap 7 contact point lainnya yang belum menyampaikan, kami menegaskan perlunya dukungan seluruh K/L untuk mendukung implementasi pengembangan kolom enquiry point pada laman website KNFP. Kami juga menyampaikan bahwa daftar pertanyaan dan jawaban umum tersebut diprioritaskan terlebih dahulu untuk subtansi yang bersifat teknis, sementara untuk topik KNFP dimana contact pointnya adalah Direktorat Fasilitasi Ekspor dan Impor dapat dipertimbangkan untuk pengembangan sistem tahap berikutnya.” Jelas Jelas Bambang Jaka Setiawan selaku Koordinator Sekretariat KNFP.',
      ],
   },
   //  BERITA 7
   {
      images: [image19, image20, image21, image22],
      title: 'Rapat Koordinasi Pembahasan Finalisasi Penyusunan Revisi Kepmendag Nomor 406 Tahun 2020',
      postdate: 'Tangerang, 10 Agustus 2023',
      contents: [
         'Direktur Fasilitasi Ekspor dan Impor selaku Koordinator Sekretariat KNFP melaksanakan rapat Koordinasi Pembahasan Finalisasi Penyusunan Revisi Kepmendag Nomor 406 Tahun 2020 yang dihadiri oleh Asisten Deputi Bidang Perdagangan, Perindustrian dan Ketenagakerjaan, Sekretariat Kabinet serta seluruh perwakilan anggota Pelaksana Harian KNFP, seluruh perwakilan dari Koordinator Bidang KNFP yang menjadi Contact Points, serta Biro Hukum - Kemendag. Tujuan rapat adalah untuk memfinalisasi Konsep Revisi Kepmendag No 406 Tahun 2020 tentang Sekretariat KNFP baik batang tubuh maupun lampiran.',
         '“Dalam rangka penguatan kelembagaan telah dilakukan Revisi Kepmenko No 199 Tahun 2018 jo Kepmenko No 284 Tahun 2018 sebagaimana telah diubah menjadi Kepmenko No 168 Tahun 2023 tentang KNFP serta penyusunan revisi Kepmendag No 406 Tahun 2020 tentang Sekretariat KNFP yang saat ini tengah dilaksanakan. Revisi terhadap Kepmendag No 406 Tahun 2020 ini ditujukan untuk mengoptimalisasi peran, tugas dan fungsi KNFP, yang juga sejalan dengan salah satu program kerja KNFP pada tahun 2023. Selanjutnya juga telah dilaksanakan beberapa kali rapat koordinasi terkait pembahasan penyusunan revisi Kepmendag No 406/2020 pada tahun 2023 ini dengan menghimpun masukan dari seluruh KIL terkait dalam keanggotaan Sekretariat KNFP. Revisi Kepmendag ini ditargetkan dapat diselesaikan paling lambat pada akhir Agustus 2023”, Jelas Bambang Jaka Setiawan selaku Koordinator Sekretariat KNFP.',
         'Beberapa hal yang menjadi hasil diskusi rapat bahwa telah menyepakati beberapa poin perubahan pada batang tubuh Revisi Kepmendag dimaksud, selanjutnya akan dikoordinasikan kembali dengan Biro Hukum dalam rangka penyempumaan baik narasi maupun substansi materi. Tugas Sekretariat KNFP tetap mengacu pada pasal 5 Kepmenko No 168 Tahun 2023. Susunan keanggotaan Sekretariat KNFP terdiri dari koordinator, anggota dan tim teknis. Disepakati bahwa Anggota dan Tim Teknis Sekretariat KNFP dapat terdiri dari contact point dan non-contact point yang merupakan kepanjangan tangan dari masing-masing Koordinator Bidang KNFP.',
      ],
   },
   //  BERITA 8
   {
      images: [image23, image24, image25, image26],
      title: 'Rapat Koordinasi terkait Uji Sistem Enquiry Points pada Website KNFP',
      postdate: 'Bandung, 29 Agustus 2023',
      contents: [
         'Direktur Fasilitasi Ekspor dan Impor selaku Koordinator Sekretariat KNFP melaksanakan rapat koordinasi uji sistem  enquiry points pada website KNFP secara hybrid di Hotel Holiday Inn Bandung. Rapat menghadirkan seluruh pemilik hak akses yang merupakan perwakilan stakeholders KNFP (Anggota Pelaksana Harian, Contact Points dan Sekretariat KNFP) serta Direktorat Teknologi Informasi, LNSW. ',
         'Pelaksanaan rapat kali ini bermaksud untuk dilakukan Uji Sistem (Trial and Error) Pengembangan Enquiry Points pada laman website KNFP bagi seluruh Pemilik Hak Akses, dalam hal mengidentifikasi kendala teknis yang dihadapi oleh masing-masing stakeholders KNFP.',
         'Pada rapat ini yang menjadi hasil pembahasan bahwa terdapat beberapa catatan teknis untuk penyempurnaan sistem, dan mekanisme perumusan teknis pertanyaan yang bersifat cross cutiing serta aksi yang dilakukan oleh anggota pelaksana harian yang perlu disepakati dan perkenaan arahan lebih lanjut dari Ketua Pelaksana Harian. Beberapa hal yang menjadi catatan dimaksud akan ditindaklanjuti bersama dengan seluruh pemilik hak akses serta tim Direktorat Teknologi Informasi LNSW. ',
      ],
   },
]

export default knfpNews
