import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../../constants/api';
import './index.scss';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

const SetiapSaat = () => {
    const [peraturan, setPeraturan] = useState([]);
    const [perjanjian, setPerjanjian] = useState([]);
    const [perjanjianSlice, setPerjanjianSlice] = useState([]);
    const [peraturanSlice, setPeraturanSlice] = useState([]);

    const history = useHistory();
    const [pageCountPeraturan, setPageCountPeraturan] = useState('');
    const perPage = 4;
    const [pageCount, setPageCount] = useState('');

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        _get_data_peraturan();
        _get_data_perjanjian();
    }, []);

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;
        loadMoreData(offset, offset + perPage);
    };

    const loadMoreData = (of, op) => {
        const data = perjanjian;
        const slice = data.slice(of, op);
        setPageCount(data.length / perPage);
        setPerjanjianSlice(slice);
    };

    const handlePageClicks = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;
        loadMoreDatas(offset, offset + perPage);
    };

    const loadMoreDatas = (of, op) => {
        const data = peraturan;
        const slice = data.slice(of, op);
        setPageCountPeraturan(data.length / perPage);
        setPeraturanSlice(slice);
    };

    const CallImage = (image_url) => {
        if (image_url !== null) {
            // var replace_url = image_url.substring(1);
            var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
            // console.log(url_image);
            return url_image;
        }
    }

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    const _get_data_peraturan = () => {
        const url = Api.SETIAP_SAAT + '?lang=' + localStorage.getItem('language').toUpperCase() + '&jenis=peraturan';
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    // setPeraturan(response.data.data);
                    let data_peraturan = response.data.data
                    setPeraturan(data_peraturan);
                    const slice = data_peraturan.slice(0, perPage);
                    setPageCountPeraturan(data_peraturan.length / perPage);
                    setPeraturanSlice(slice);
                } else {
                    setPeraturan([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setPeraturan([]);
            });
    }

    const _get_data_perjanjian = () => {
        const url = Api.SETIAP_SAAT + '?lang=' + localStorage.getItem('language').toUpperCase() + '&jenis=perjanjian';
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    let data_perjanjian = response.data.data
                    setPerjanjian(data_perjanjian);
                    const slice = data_perjanjian.slice(0, perPage);
                    setPageCount(data_perjanjian.length / perPage);
                    setPerjanjianSlice(slice);
                } else {
                    setPerjanjian([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setPerjanjian([]);
            });
    }

    return (
        <>
            <div className="setiap-saat row m-0">
                <div className="col-md-12">
                    <div className="sm-content text-center">
                        <h3 className="sm-title">
                            Informasi Publik Tersedia Setiap Saat
                        </h3>
                    </div>
                </div>

                <div className="text-left sm-content-list row m-0">
                    <div className="col-md-12 part-peraturan">
                        <div className="titles p-2">Informasi Tentang Peraturan Yang Berkaitan Dengan LNSW</div>
                        <div className="row m-0">
                            {peraturanSlice.map((item, index) =>
                                <div className="col-md-6 p-2">
                                    <div className="card p-3">
                                        <div className="row m-0">
                                            <div className="col-md-2">
                                                <img src={CallImage(item.logo)} alt="img" />
                                            </div>
                                            <div className="content col-md-10">
                                                {parseHtml(item.header)}
                                            </div>
                                        </div>
                                        <a role="button" rel="noopener noreferrer" className="btn btn-primary rounded-pill float-right" href={CallImage(item.file)} target="_blank">
                                            Unduh
                                    </a>
                                    </div>
                                </div>
                            )}
                        </div>
                        <ReactPaginate
                            previousLabel={'Sebelumnya'}
                            nextLabel={'Selanjutnya'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCountPeraturan}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClicks.bind(this)}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>

                    <div style={{
                        backgroundColor: '#e8e8e8', 
                        width: '100%', 
                        height: '1px',
                        marginLeft: '20px',
                        marginRight: '20px',
                        marginBottom: '20px'
                        }}>
                    </div>

                    <div className="col-md-12 part-perjanjian">
                        <div className="titles p-2">Perjanjian Badan Publik</div>
                        <div className="row m-0">
                            {perjanjianSlice.map((item, index) =>
                                <div className="col-md-3 p-2">
                                    <div className="card p-3">
                                        <div className="content-list">
                                            <div className="titles-card">
                                                {
                                                    item.header.length > 80 ?
                                                        (
                                                            <div className="">
                                                                {parseHtml(item.header.slice(0, 80))}...
                                                            </div>
                                                        ) : (
                                                            <div className="">
                                                                {parseHtml(item.header)}
                                                            </div>
                                                        )
                                                }
                                            </div>
                                            <div className="contents">
                                                {
                                                    item.ur.length > 100 ?
                                                        (
                                                            <div className="">
                                                                {parseHtml(item.ur.slice(0, 100))}...
                                                            </div>
                                                        ) : (
                                                            <div className="">
                                                                {parseHtml(item.ur)}
                                                            </div>
                                                        )
                                                }
                                            </div>
                                            <a role="button" rel="noopener noreferrer" className="btn btn-primary rounded-pill float-right" href={CallImage(item.file)} target="_blank">
                                                Lihat
                                        </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                        <ReactPaginate
                            previousLabel={'Sebelumnya'}
                            nextLabel={'Selanjutnya'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick.bind(this)}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>

                    <div className="col-md-12 part mb-5">
                        <div className="titles p-2">Indonesia Nasional Repository</div>
                        <div className="row m-0 align-items-center">
                            <div className="col-md-6 p-2">
                                Seluruh informasi yang tertera di Indonesia National Trade Repository (INTR)
                            </div>
                            <div className="col p-2">
                                <Link to={{ pathname: "/intr" }} className="nav-link font-weight-bold">
                                    Lihat
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default SetiapSaat;
