import React, { useEffect, useState } from 'react'
import { IconPeta } from '../../../assets'

const Pin = ({ name, pinSize, x, y, bgColor }) => {
   return (
      <div
         style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: x,
            top: y,
         }}
      >
         <div
            style={{
               borderRadius: pinSize / 2,
               background: bgColor,
               width: pinSize,
               height: pinSize,
               marginRight: '4px',
            }}
         />
         <span
            style={{
               fontSize: 18,
               color: 'white',
            }}
         >
            {name}
         </span>
      </div>
   )
}

const MapMarkers = ({ showPin, location, bgColor, pinSize }) => {
   const [timePin, setTimePin] = useState(0)

   useEffect(() => {
      if (showPin) {
         setTimePin(timePin + 1)
      } else {
         setTimePin(0)
      }
   }, [showPin, bgColor])

   useEffect(() => {
      if (timePin < location.length && showPin) {
         setTimeout(() => {
            setTimePin(timePin + 1)
         }, 100)
      }
   }, [timePin])

   useEffect(() => {
      if (timePin === location.length || timePin > 0) {
         setTimePin(0)
      }
   }, [bgColor])

   return (
      <div
         style={{
            width: '100%',
            height: '100%',
            background: 'transparent',
            position: 'relative',
         }}
      >
         <img src={IconPeta} style={{ width: '100%', height: '100%' }} />
         {showPin &&
            location &&
            location.map((el, index) => {
               if (timePin >= index) {
                  return (
                     <Pin
                        key={index}
                        x={el.cordinate_x}
                        y={el.cordinate_y}
                        name={el.port_name}
                        pinSize={pinSize}
                        bgColor={bgColor}
                     />
                  )
               }
            })}
      </div>
   )
}

export default MapMarkers
