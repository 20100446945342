import React, { useState, useEffect } from 'react'
import './ntpn.scss'
import { useTranslation } from 'react-i18next'
import { InputCapsule, InputSearch } from '../../../components/atoms'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import * as Api from '../../../constants/api'
import parse from 'html-react-parser'
import { ListEmpty, LoadProgress } from '../../../components'
import { Penelusuran_BG } from '../../../assets'

const NptnSimbara = () => {
   const { t } = useTranslation()
   const history = useHistory()
   const [no_ntpn, SetNtpn] = useState('')
   const [judul, setJudul] = useState([])
   const [is_progress, setisProgress] = useState(false)
   const [error_message, setErrorMessage] = useState('')

   const searchNtpn = () => {
      history.push('/ntpn-simbara/detail', {
         data: {
            ntpn: no_ntpn,
         },
      })
   }

   // did mount
   useEffect(() => {
      _get_data_judul()
   }, [])

   const _get_data_judul = () => {
      setisProgress(true)
      let idMenu = localStorage.getItem('menu_select')
      if (idMenu === null || idMenu !== 40) {
         idMenu = 40
      }
      const url =
         Api.JUDUL +
         idMenu +
         '&lang=' +
         localStorage.getItem('language').toUpperCase()
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgress(false)
            if (response.data.code === '01') {
               setJudul(response.data.data)
            } else {
               setErrorMessage(t('empty_list'))
               setJudul([])
            }
         })
         .catch((error) => {
            setisProgress(false)
            setJudul([])
            setErrorMessage(t('error_message'))
         })
   }

   const CallImage = (image_url) => {
      var url_image = Api.IP_KONG_LNSW + image_url.slice(1)
      // console.log(url_image);
      return url_image
   }

   //function
   const parseHtml = (html) => {
      // console.log('html');
      // console.log(html)
      var text_html = ''
      if (html !== undefined) {
         text_html = parse(html)
      }
      // var text_html = parse(html.toString());

      return text_html
   }

   return (
      <div className="penelusuran-ntpn-container">
         {is_progress === true ? (
            <div style={{ height: '25vw' }} className="detail-content-load">
               <LoadProgress />
            </div>
         ) : judul.length === 0 ? (
            // <div className="detail-content-load">
            //    <ListEmpty message_error={error_message} />
            // </div>
            <div className="penelusuran-background">
               <img
                  //   src={CallImage(item.nama_img)}
                  src={Penelusuran_BG}
                  className="penelusuran-background-img"
                  alt="bg"
               />
               <div className="penelusuran-content-title">
                  <div className="penelusuran-content-sub-title">
                     <div className="penelusuran-text-title">
                        {/* {parseHtml(item.header)} */}
						Penelusuran NTPN Simbara
                     </div>
                     <div className="penelusuran-text-message">
                        {/* {parseHtml(item.ur)} */}
                         Penelusuran NTPN Simbara
                        berdasarkan Satu Nomor NTPN atau lebih dari satu Nomor
                        NTPN (Nomor NTPN dengan pemisah koma (,) antara satu
                        sama lain) di Lembaga National Single Window
                     </div>
                     <div className="row m-0 justify-content-center mt-5">
                        <div className="col-md-5">
                           <InputSearch
                              placeholder={t('enter_ntpn')}
                              onChange={(e) => SetNtpn(e.target.value)}
                              onKeyDown={() => searchNtpn()}
                              onClick={() => searchNtpn()}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         ) : (
            judul.map((item, index) => (
               <div className="penelusuran-background">
                  <img
                     src={CallImage(item.nama_img)}
                     className="penelusuran-background-img"
                     alt="bg"
                  />
                  <div className="penelusuran-content-title">
                     <div className="penelusuran-content-sub-title">
                        <div className="penelusuran-text-title">
                           {parseHtml(item.header)}
                        </div>
                        <div className="penelusuran-text-message">
                           {parseHtml(item.ur)}
                        </div>
                        <div className="row m-0 justify-content-center mt-5">
                           <div className="col-md-5">
                              <InputSearch
                                 placeholder={t('enter_ntpn')}
                                 onChange={(e) => SetNtpn(e.target.value)}
                                 onKeyDown={() => searchNtpn()}
                                 onClick={() => searchNtpn()}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            ))
         )}
      </div>
   )
}

export default NptnSimbara
