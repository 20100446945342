import axios from 'axios';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Listkontak from '../../../components/molecules/ListKontak';
import * as Api from '../../../constants/api';
import './kontak.scss';

const Profil = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const { t } = useTranslation();


  //variable constant
  const [detail_kontak_data, setKontakData] = useState([]);
  const [kontak_data, setKontak] = useState([]);

  const CallImage = (image_url) => {
    // var replace_url = image_url.substring(1);
    var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    // console.log(url_image);
    return url_image;
  }

  const parseHtml = (html) => {
    // console.log('html');
    // console.log(html)
    var text_html = '';
    if (html !== undefined) {
      text_html = parse(html);
    }
    // var text_html = parse(html.toString());

    return text_html;
  }

  //did mount
  useEffect(() => {
    if (localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'id');
    }
    _get_kontak();
    _get_data_kontak();
  }, []);

  const _get_kontak = () => {
    const url = Api.JUDUL + localStorage.getItem('menu_select') + '&lang=' + localStorage.getItem('language').toUpperCase();
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        // console.log("kontak", response.data.data);
        if (response.data.code === '01') {
          setKontak(response.data.data);
        } else {
          setKontak([]);
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setKontak([]);
      });
  }

  const _get_data_kontak = () => {
    const url = Api.DETAIL_KONTAK + '?lang=' + localStorage.getItem('language').toUpperCase();
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        // console.log("detail_kontak", response.data.data);
        if (response.data.code === '01') {
          setKontakData(response.data.data);
        } else {
          setKontakData([]);
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setKontakData([]);
      });
  }


  return (
    <div className="container">
      <div className="kontak-background col-md-12">
        <div className="kontak-content-title row justify-content-center">

          <div className="kontak-content-sub-title">
            <div className="kontak-text-title col-md-12">
              {/* {t(items.header)} */}
              {t('kontak-kami')}
            </div>
            <div className="kontak-text-message col-md-12">
              {/* {parseHtml(items.ur)} */}
              {t('kontak-kami-sub')}
            </div>
            <div className="col-md-12">
              <div className="kontak-sub-menu row justify-content-center">
                {detail_kontak_data.map((item, index) =>
                  <div className="col-md-4 p-0">
                    <Listkontak gambar={CallImage(item.nama_img)} judul={parseHtml(item.header)} header={parseHtml(item.ur)} />
                  </div>
                )}
              </div>
              {/* <div className="row justify-content-center profil-ppid-contact-container">
                {detail_kontak_data.map((item, index) =>
                    <div className="profil-ppid-contact col-md-3 col-sm-6" key={index}>
                        <div className="profil-contact row m-0">
                            <img className="profil-contact-img col-md-3 col-xs-12 p-1" src={CallImage(item.nama_img)} alt={`${index}`} />
                            <div className="col-md-9 col-xs-12 p-1">
                                <h6 className="profil-contact-title">
                                    {parseHtml(item.header)}
                                </h6>
                                <p className="profil-contact-desc">
                                    {parseHtml(item.ur)}
                                </p>
                            </div>
                        </div>
                    </div>
                  )}
              </div> */}
            </div>

          </div>

        </div>
        {/* <br></br> */}
      </div>
    </div>

  );
}


export default Profil;
