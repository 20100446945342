import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Api from "../../../constants/api";
import { getLanguageStorageUpperCase } from "../../../constants/storage";
import "./index.scss";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import GridList from "react-gridlist";
import { useHistory } from "react-router-dom";
import { ListEmpty, LoadProgress } from "../../../components";
import Footer from "../../KNFP/Footer";

const FacilityRepo = () => {


  const [katFAQ, setKatFAQ] = useState([]);


  const [is_progress, setisProgress] = useState(false);
  const [error_message, setErrorMessage] = useState("");

  // Grid List
  const width = window.innerWidth;

  const getGridGap = (elementWidth, windowHeight) =>
    elementWidth > 720 && windowHeight > 480 ? 10 : 30;

  let getColumnCount = (elementWidth) => Math.floor(elementWidth / 350); //pembagian column (dibagi untuk 2 column) // awal 400 laptop hilman 377

  if (width < 600) {
    getColumnCount = (elementWidth) => Math.floor(elementWidth / 300);
  }

  const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.5);

  const getItemData = (katFAQ, columnWidth) => {
    // let adjustedHeight = Math.round(columnWidth * 0.4)
    return {
      key: katFAQ.id,
      // height: adjustedHeight,
    };
  };

  ////////////

  

  const { t } = useTranslation();

  const CallImage = (image_url) => {
    var url_image = Api.IP_KONG_LNSW + "facility/get-file/" + image_url.slice(1);
    return url_image;
  };

  // const parseHtml = (html) => {
  //   var text_html = "";
  //   var text_slice = "";
  //   if (html !== undefined) {
  //     text_html = parse(html);
  //   }

  //   if (text_html.length > 60) {
  //     text_slice = text_html.substring(0, 40);
  //     return text_slice + "...";
  //   } else {
  //     text_slice = text_html;
  //     return text_slice;
  //   }
  // };


  const _get_data_kat_faq = () => {
    setisProgress(true);
    const url = Api.KNFP + "facility/web?lang=" + getLanguageStorageUpperCase();
    axios({
      method: "get",
      url: url,
      headers: { 'insw-key': Api.KNFP_AUTH_KEY, 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then((response) => {
        setisProgress(false);
        if (response.data.result.code === 200) {
          // console.log('katfaq', response.data.result.data);
          setKatFAQ(response.data.result.data);
        } else {
          setErrorMessage(t("empty_list"));
          setKatFAQ([]);
        }
      })
      .catch((error) => {
        setisProgress(false);
        setErrorMessage(t("error_message"));
        setKatFAQ([]);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("language") === null) {
      localStorage.setItem("language", "id");
    }

    _get_data_kat_faq();
  }, []);

  // const CallVideo = (image_url) => {
  //   var url_image = "";
  //   if (image_url !== undefined) {
  //     url_image = Api.IP_KONG_LNSW + image_url.slice(1);
  //   }
  //   return url_image + "#t=0.5";
  // };

  const removeDot = (item) => {
    if (item != undefined) {
      var imageUrl = Api.KNFP_IMG + item.icon;
      console.log(imageUrl, '<<<<')
    } else {
      var imageUrl = "";
    }
    //console.log(imageUrl);
    return imageUrl;
  };

  const klik_data = (item) => {
    const win = window.open(item, "_blank");
    if (win != null) {
      win.focus();
    }
  };


  return (
    <>
      <div>
        <div className="pusat-bantuan">
          <div className="pb-content">
            <h3 className="pb-title">{t("facility-repository")}</h3>
            <div className="row m-0 justify-content-center">
              <p className="col-md-6">{t("knfp-trade-facility-subtitle")}</p>
            </div>
          </div>

          <div className="pb-content-list-kat-faq">
            <div className="title-faq">
              <h5>{t("facility-repository")}</h5>

            </div>
            <div className="kat-faq-container">
              {is_progress === true ? (
                <div style={{ height: "25vw" }} className="detail-content-load">
                  <LoadProgress />
                </div>
              ) : katFAQ.length === 0 ? (
                <div className="detail-content-load">
                  <ListEmpty message_error={error_message} />
                </div>
              ) : (
                <GridList
                  items={katFAQ}
                  getGridGap={getGridGap}
                  getColumnCount={getColumnCount}
                  getWindowMargin={getWindowMargin}
                  getItemData={getItemData}
                  renderItem={(item) => {
                    return (
                      <div className="card-kat-faq">
                        <div className="container-kat-img">
                          <img src={removeDot(item)} className="kat-img" />
                        </div>
                        <div
                          className="container-kat-title"
                          onClick={() => klik_data(item.url)}

                        >
                          <div className="kat-title">{parse(item.title)}</div>
                        </div>
                      </div>
                    );
                  }}
                />
              )}
            </div>
          </div>
          {/* <hr /> */}

          <hr />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default FacilityRepo;
