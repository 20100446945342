import Axios from 'axios'
import React from 'react'
import { BASIC_AUTH, IP_KONG_LNSW, LHKPN_PIMPINAN } from '../../../constants/api'

export default function LHKPN() {
    const [data, setData] = React.useState([])

    const getDataPimpinan = async () => {
        try {
            const { data } = await Axios.get(LHKPN_PIMPINAN, {
                headers: { Authorization: BASIC_AUTH }
            })
            setData(data.data)
        } catch (error) {
            setData([])
            console.log(error)
        }
    }

    React.useEffect(() => {
        getDataPimpinan()
    }, [])

    return (
        <div className="container">
            <div className="kontak-background col-md-12 mb-5">
                <div className="kontak-content-title row justify-content-center mt-4 mb-5">
                    <h2>LHKPN Pimpinan</h2>

                </div>
                {data.map((item) => (
                    <div class="row justify-content-md-center ">
                        <div class="col col-lg-3 " style={{ wordWrap: "break-word" }}>
                            <div className='d-flex justify-content-center align-items-center w-100 h-100' >
                                <img style={{ height: "200px", maxWidth: "300px", objectFit: "contain" }}
                                    src={`${IP_KONG_LNSW}/${item.foto_profil.replace("./assets", "assets")}`} alt="" />
                            </div>
                        </div>
                        <div class="col col-lg-7" style={{ wordWrap: "break-word" }}>
                            <p className='m-0'>{item.jabatan}</p>
                            <h4 className='fw-bold'>{item.nama_lengkap}</h4>
                            {item.masa_jabatan.split("\r\n").reverse().map((v) => (
                                <p className='m-0 mt-1' style={{ fontSize: "12px" }}>{v}</p>
                            ))}
                            <div className='mt-3'>

                                {item.files_lhkpn?.map((v, i) => (

                                    <div className='my-2' >
                                        {i !== 0 && (
                                            <span>LHKPN Sebelumnya </span>
                                        )}
                                        <a target="_blank" href={`${IP_KONG_LNSW}/${v?.path?.replace("./assets", "assets")}`}> { i === 0 ? v.header_file : v.tahun} </a> <br />
                                    </div>
                                ))}
                                <div>
                                    LHKPN Tahun Sebelumnya <a target="_blank" href='https://elhkpn.kpk.go.id/portal/user/pengumuman_lhkpn/TDFSck1tOXNURXBPVmxjd1NWTTJVRU5HUXpoUE9EaEZMMk5DY0dKNE5rSXJLMkV4Vm5KUFVFdHVjbEZUY1hWM2JEWTVNMnMxY0VVM01tRktabmxhZEE9PQ==#announ' >e-announcement LHKPN KPK</a>
                                </div>
                            </div>
                        </div>
                        <div className='w-100 my-5' style={{margin:"10 0", border:"none", borderTop:"1px solid gray", opacity:"50%"}}> </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
