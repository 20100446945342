import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Api from "../../../../constants/api";
import "../index.scss";
import parse from "html-react-parser";
import { Link, useLocation } from "react-router-dom";
import { getLanguageStorageUpperCaseKNFP, setLanguageStorage } from "../../../../constants/storage";
import { useTranslation } from "react-i18next";

const DetailRepo = (props) => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const { t } = useTranslation();

  // console.log('daskdfmklf')

  useEffect(() => {
    if (getLanguageStorageUpperCaseKNFP() === null) {
      setLanguageStorage('id');
    }

    setData(location.state);
  }, []);

  const CallImage = (image_url) => {
    // var replace_url = image_url.substring(1);
    var url_image = Api.IP_KONG_LNSW + image_url.slice(0);
    // console.log(url_image);
    return url_image;
  };

  // const parseHtml = (html) => {
  //     // console.log('html');
  //     // console.log(html)
  //     var text_html = '';
  //     if (html !== undefined) {
  //         text_html = parse(html);
  //     }
  //     // var text_html = parse(html.toString());

  //     return text_html;
  // }

  return (
    <>
      <div className="serta-merta row m-0">
        <div className="col-md-12">
          <div className="sm-content text-center">
            <h3 className="sm-title">{t('facility-repository')}</h3>
          </div>
        </div>

        <div className="text-left sm-content-list">
          <div className="col-md-12 mb-5">
            <div className="titles-detail">{data.title}</div>
            <div className="content-detail">{data.content}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailRepo;
