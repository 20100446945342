/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './list_setting.scss';
import Ripples from 'react-ripples'
import { ICEye, LogoKarantinaIkan, GarudaLogo, GarudaLogoUKM } from '../../../assets';
import { useTranslation } from 'react-i18next';
import * as Api from '../../../constants/api';

const ListSetting = ({ image, nomor, bidang, title, desc, date, onClick }) => {
    //variable constant
    const { t } = useTranslation();

    //function
    const CallImage = (image_url) => {
        // var replace_url = image_url.substring(1);
        var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        // console.log('url_image');
        // console.log(url_image);
        return url_image;
    }

    return (
        <Ripples className="list-setting" onClick={onClick}>
            <div className="list-setting-item">
                <div style={{ padding: '15px' }}>
                    {image !== null ?
                        <img src={CallImage(image)} className="list-setting-item-img" />
                        // : <img src={LogoKarantinaIkan} className="list-setting-item-img" />}
                        : <img src={GarudaLogoUKM} className="list-setting-item-img" />}
                </div>
                <div style={{ flex: 1 }}>
                    <div className="list-setting-item-name">{bidang}</div>
                    {/* <div className="list-setting-item-title">
                        {
                            nomor.length > 90
                                ? title.substring(0, 90) + '...'
                                : title.substring(0, 90)
                        }
                    </div> */}
                    <div className="list-setting-item-title">{nomor}</div>
                    <div className="list-setting-item-message">
                        {
                            title.length > 110
                                ? title.substring(0, 110) + '...'
                                : title.substring(0, 110)
                        }
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div className="list-setting-item-date">{date}</div>
                        {/* <div style={{display: "flex", marginLeft: '1vw'}}>
                            <img style={{marginRight: '0.2vw'}} src={ICEye}></img>
                            <div className="list-setting-item-date">{'122 x ' + t('seen') }</div>
                        </div> */}
                    </div>

                </div>
            </div>
        </Ripples>
    )
}

export default ListSetting;
