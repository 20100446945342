import React from 'react'
import './timeline_nib.scss'
import { useTranslation } from 'react-i18next'
import { ICCentang, ICKosong, ICSilang } from '../../../assets'

const TimeLine = ({
   status,
   keterangan,
   index,
   totalRow,
   onClick,
   time,
   beforeTime,
   nextTime,
   choiceIC,
}) => {
   const { t } = useTranslation()

   const Lines = () => {
      if (index === 2 && choiceIC !== true) {
         return (
            <>
               <div className="timeline-border-white" />
               <img src={ICKosong} alt="ic_stat" />
               <div className="timeline-border-white" />
            </>
         )
      } else {
         if (index === 0) {
            return (
               <>
                  <div className="timeline-border-white" />
                  <img src={ICCentang} alt="ic_stat" />
                  <div className="timeline-border-white" />
               </>
            )
         } else if (index === 1) {
            if (nextTime !== '-') {
               return (
                  <>
                     <div className="timeline-border-white" />
                     <img src={ICCentang} alt="ic_stat" />
                     <div className="timeline-border-white" />
                  </>
               )
            } else {
               return (
                  <>
                     <div className="timeline-border-white" />
                     <img src={ICCentang} alt="ic_stat" />
                     <div className="timeline-border-white" />
                  </>
               )
            }
         } else {
            if (nextTime !== '-') {
               return (
                  <>
                     <div className="timeline-border-white" />
                     <img src={ICCentang} alt="ic_stat" />
                     <div className="timeline-border-white" />
                  </>
               )
            } else {
               return (
                  <>
                     <div className="timeline-border-white" />
                     <img src={ICCentang} alt="ic_stat" />
                     <div className="timeline-border-white" />
                  </>
               )
            }
         }
      }
   }

   return (
      <div className="timelinenib-container">
         <div className="timeline-icon">
            <Lines />
         </div>
         <div className="timeline-content">
            {index === 0 ||
            index === 1 ||
            (index === 2 && choiceIC === true) ? (
               <p className="timeline-status">{status}</p>
            ) : (
               <p className="timeline-status-abu">{status}</p>
            )}
            <p className="timeline-keterangan">{keterangan}</p>
            <p className="timeline-time">{time}</p>
         </div>
      </div>
   )
}

export default TimeLine
