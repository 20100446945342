import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationID from './locales/id/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  id: {
    translation: translationID
  }
};

const allowedLanguages = ['en', 'id'];

const defaultLng = 'id';
let lng = defaultLng;

const storageLanguage = localStorage.getItem('language');
// console.log('storageLanguage');
// console.log(storageLanguage);
if (storageLanguage && allowedLanguages.indexOf(storageLanguage) > -1) {
    lng = storageLanguage;
  }

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lng,
    fallbackLng: "id", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;