export const dataDummy = [
   {
      id: 1,
      content:
         'Surat Keterangan Pengecualian PE dan LS Produk Pertambangan Tidak Untuk Kegiatan Usaha - Re-ekspor Bahan Baku Impor yang Tidak Sesuai Spesifikasi dan/atau Tidak Habis Terpakai',
   },
   {
      id: 2,
      content:
         'Surat Keterangan Pengecualian PE dan LS Produk Pertambangan Untuk Kegiatan Usaha - Untuk pemegang izin usaha lain, bukan produk pertambangan hasil pengolahan dan/atau pemurnian, HS yang sama',
   },
   {
      id: 3,
      content:
         'Surat Keterangan Pengecualian PE dan LS Produk Pertambangan Untuk Kegiatan Usaha - Ekspor Produk Industri Kategori Produk Pertambangan, Bahan Baku Utama Impor dan/atau Skrap khusus logam, Mitra API-P',
   },
   {
      id: 4,
      content:
         'Surat Keterangan Pengecualian PE dan LS Produk Pertambangan Untuk Kegiatan Usaha - Ekspor Produk industri Kategori Produk Pertambangan, Bahan Baku Utama dari Impor dan/atau Skrap khusus logam, API-P',
   },
   {
      id: 5,
      content:
         'LS Ekspor Produk Pertambangan Hasil Pengolahan dan/atau Pemurnian - Mineral Logam dan Bukan Logam, Batuan',
   },
]

export const jenisApi = [
   {
      id: '00',
      content: 'Tidak memerlukan API',
   },
   {
      id: '01',
      content: 'API-U',
   },
   {
      id: '02',
      content: 'API-P',
   },
]

export const valueJenisApi = ['00', '02']

export const tipeTrader = [
   {
      id: '01',
      content: 'Pelaku Usaha Perusahaan',
   },
   {
      id: '02',
      content: 'Pelaku Usaha Perseorangan',
   },
   {
      id: '03',
      content: 'BUMN',
   },
   {
      id: '04',
      content: 'Perorangan',
   },
   {
      id: '05',
      content: 'Yayasan',
   },
   {
      id: '06',
      content: 'K/ L/ D',
   },
   {
      id: '07',
      content: 'Kedutaan Asing/ Org International',
   },
]
export const valueTipeTrader = ['01', '03', '05', '07']

export const dokumenSyarat = [
   {
      id: 1,
      content:
         'LS Ekspor Produk Pertambangan Hasil Pengolahan dan/atau Pemurnian - Silika dan Kuarsa, Konsentrat, Lumpur Anoda, Bauksit',
   },
   {
      id: 2,
      content:
         'Surat Keterangan Pengecualian PE dan LS Produk Pertambangan Tidak Untuk Kegiatan Usaha - Keperluan Penelitian dan Pengembangan',
   },
   {
      id: 3,
      content:
         'Surat Keterangan Pengecualian PE dan LS Produk Pertambangan Tidak Untuk Kegiatan Usaha - Barang Contoh',
   },
   {
      id: 4,
      content:
         'Surat Keterangan Pengecualian PE dan LS Produk Pertambangan Tidak Untuk Kegiatan Usaha - Keperluan Pameran',
   },
   {
      id: 5,
      content:
         'Surat Keterangan Pengecualian PE dan LS Produk Pertambangan Untuk Kegiatan Usaha - Benda Seni atau Kerajinan Berbahan Dasar Batuan yang Telah Melalui Proses Pengolahan Diproduksi oleh UMKM',
   },
]

export const komoditiDiatur = [
   {
      hsCode: '07112010',
      ur_btki: 'Diawetkan dengan gas belerang dioksida',
      ur_peraturan: ' Preserved by sulphur dioxide gas',
   },
   {
      hsCode: '07119040',
      ur_btki: 'Bawang bombay, diawetkan dengan gas belerang dioksida',
      ur_peraturan: 'Onions, preserved by sulphur dioxide gas',
   },
   {
      hsCode: '1518',
      ur_btki:
         'Lemak dan minyak hewani, nabati atau mikroba serta fraksinya, dipanaskan, dioksidasi, didehidrasi, disulfurisasi, ditiup, dipolimerisasi dengan panas dalam hampa udara atau dalam gas inert atau dimodifikasi secara kimia lainnya, tidak termasuk dari pos 15.16; olahan atau campuran yang tidak dapat dimakan dari lemak atau minyak hewani, nabati atau mikroba atau dari fraksi lemak atau minyak yang berbeda dalam Bab ini, tidak dirinci atau termasuk dalam pos lainnya.',
      ur_peraturan:
         'Animal, vegetable or microbial fats and oils and their fractions, boiled, oxidised, dehydrated, sulphurised, blown, polymerised by heat in vacuum or in inert gas or otherwise chemically modified, excluding those of heading 15.16; inedible mixtures or preparations of animal, vegetable or microbial fats or oils or of fractions of different fats or oils of this Chapter, not elsewhere specified or included.',
   },
]

export const Satuan = [
   {
      kode: 'KGM',
      ur: 'Kilogram',
   },
   {
      kode: 'J53',
      ur: 'coulomb square metre per kilogram',
   },
   {
      kode: 'KTM',
      ur: 'Kilometre',
   },
]
export const Pelabuhan = [
   {
      kode: 'IDSRI',
      ur: 'Samarinda / Temindung (u)',
   },
   {
      kode: 'IDMDC',
      ur: 'Menado / Sam Ratulangi (u)',
   },
   {
      kode: 'IDMAK',
      ur: 'Soekarno Hatta Makassar',
   },
]
