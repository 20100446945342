/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import './detail_press_release.scss';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from 'html-react-parser';
import GridList from 'react-gridlist';
import ReactPaginate from 'react-paginate';
import moment from 'moment';

//component
import * as Api from '../../../../constants/api';
import { LoadProgress, ListEmpty, InputSearch, ListBerita} from '../../../../components';
import { ICCalendarGrayNews, NewsDefault } from '../../../../assets';
import { useLocation, useRouteMatch } from 'react-router-dom';

const DetailPressRelease = () => {

  // variable constant
  const { t } = useTranslation();
  const location = useLocation();
  const match = useRouteMatch();
  const [is_progress, setisProgress] = useState(true);
  const [detail_berita, setDetailBerita] = useState({});
  const [error_message, setErrorMessage] = useState('');
  const id_berita = useRef('');

  // did mount
  useEffect(() => {
    // console.log('uf match', match);
    // console.log('uf location', location);
    // console.log('uf location state', location.state);
    if (location.state === undefined) {
      // console.log('uf match', match);
      id_berita.current = match.params.id_berita;
      console.log(match.params.id_berita);
    } else {
      id_berita.current = location.state.id_berita;
    }
    // console.log('uf id_berita', id_berita);
    _get_detail_berita();
  }, []);

  //function
  const _get_detail_berita = () => {
    setisProgress(true);
    const url = Api.PR_DETAIL + id_berita.current + '?lang=' + localStorage.getItem('language').toUpperCase();
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        setisProgress(false);
        if (response.data.code === '01') {
          if (response.data.data.length === 0) {
              setDetailBerita({});
              setErrorMessage(t('empty_list'));
          } else {
              let detail_berita = response.data.data[0];
              setDetailBerita(detail_berita)  
          } 
        } else {
          setDetailBerita([]);
          setErrorMessage(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgress(false);
        setDetailBerita([]);
        setErrorMessage(t('error_message'));
      });
  }

  const CallImage = (image_url) => {
    // console.log('url_image');
    // console.log(image_url);
    var url_image = '';
    if (image_url !== undefined) {
      url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    }
    // console.log('url_image');
    // console.log(url_image);
    return url_image;
  }

  const parseContent = (text) => {
    var text_html = '';
    if (text !== undefined) {
      text_html = parse(text)
    }
    // console.log('url_image');
    // console.log(text_html);
    return text_html;
  }

  const unduh_data = item => {
    var imageUrl = Api.IP_KONG_LNSW + item.slice(1);
    // console.log(imageUrl);
    // window.open(imageUrl, "_blank"); //to open new page
    const win = window.open(imageUrl, '_blank');
    if (win != null) {
        win.focus();
    }
  }

  return (
    <div className="detail-press-release-content">
      <div style={{display: "flex"}}>
        {is_progress === true ?
          <div style={{height: '25vw'}} className="search-content-load">
              <LoadProgress />
          </div> 
          : 
          detail_berita.length === 0 ?
          <div className="search-content-load">
              <ListEmpty message_error={error_message}/>
          </div>
          :
          <div className="berita-terkini-cover">
            <LazyLoadImage
              // delayMethod='throttle'
              // delayTime={300}
              // threshold={100}
              effect="blur"
              className="berita-terkini-cover-image"
              src={
                detail_berita.file_detail !== null ?
                CallImage(detail_berita.file_detail)
                :
                NewsDefault
              }
            />
            <div className="berita-terkini-group-text">
              <div className="berita-terkini-cover-title">
                {detail_berita.header}
              </div>
              <div className="berita-terkini-cover-date">
                <img src={ICCalendarGrayNews} style={{marginRight: '0.7vw'}} />
                {moment(detail_berita.waktu_publish).format('DD MMMM YYYY') + ' , ' + t('by') + ' ' + detail_berita.penulis}
              </div>
              <div className="berita-terkini-cover-desc">
              {parseContent(detail_berita.berita)}
              </div>
              {
                detail_berita.pdf !== null || detail_berita.pdf !== undefined ?
                <div className="press-release-file-container">
                  <div className="press-release-file-title">
                    SP {detail_berita.header}
                  </div>
                  <div className="press-release-file-pdf-container">
                    <div className="press-release-file-pdf"
                      onClick={() => unduh_data(detail_berita.pdf)}>{t('download')}
                    </div>
                  </div>
                </div>
                :
                null
              }
            </div>
          </div>
        }   
      </div>
    </div>
  )
}

export default DetailPressRelease;
