import React, { useState, useEffect, useHistory, useRef } from 'react';
import './detail_ntpn.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Button,
	InputCapsule,
	InputSearch,
} from '../../../../components/atoms';
import axios from 'axios';
import * as Api from '../../../../constants/api';
import {
	ListEmpty,
	LoadProgress,
	TimeLineNib,
	CardPerizinan,
	ListFirst,
	CardNtpn,
} from '../../../../components';
import moment from 'moment';

const DetailNib = () => {
	const location = useLocation();
	var route_data = '';
	if (location.state === undefined) {
		route_data = '';
	} else {
		route_data = location.state.data;
	}
	const [data_ntpn, setDataNibAsal] = useState(route_data);

	const { t } = useTranslation();
	const [no_ntpn, SetNtpn] = useState('');
	const [is_progress, setisProgress] = useState(false);
	const [is_progress2, setisProgress2] = useState(false);
	const [dataNtpn, SetDataNtpn] = useState([]);
	const [error_message, setErrorMessage] = useState('');
	const [dataKiri, setDataKiri] = useState('');

	// const searchNtpn = data => {
	//     // console.log(data);
	//     // console.log('no ntpn');
	//     // console.log(data.ntpn);
	//     // console.log('codebill_detail');
	//     // console.log(data.nomor);

	//     setisProgress(true);

	//     let nomorNtpn = data.ntpn;
	//     // let nomorData = data.nomor;

	//     let url = Api.IP_LNSW_CMS + 'ntpn?ntpn=' + nomorNtpn;
	//     // console.log(url);

	//     axios({
	//         method: 'get',
	//         url: url,
	//         headers: { 'Authorization': Api.BASIC_AUTH },
	//         timeout: 1000 * 10, // Wait for 10 seconds
	//     })
	//         .then(response => {
	//             setisProgress(false);
	//             if (response.data.code === '01') {
	//                 if (response.data.data.length === 0) {
	//                     SetDataNtpn([]);
	//                     setErrorMessage(t('empty_list'));
	//                 } else {
	//                     // console.log('length !== 0');
	//                     // console.log(response.data.data);
	//                     let data_list = response.data.data;
	//                     SetDataNtpn(data_list);
	//                 }

	//             } else {
	//                 // console.log('code 02');
	//                 // console.log(response.data.code);
	//                 SetDataNtpn([]);
	//                 setErrorMessage(t('empty_list'));
	//             }
	//         })
	//         .catch(error => {
	//             console.log('error');
	//             console.log(error);
	//             setisProgress(false);
	//             SetDataNtpn([]);
	//             setErrorMessage(t('error_message'));
	//         });
	// }

	const searchNtpn = (data) => {
		// console.log('data', data);

		let dataBody = '';

		if (data.ntpn !== '') {
			let _data_ntpn = filter_input(data.ntpn);
			let dataSplit = _data_ntpn.split(',');
			// console.log('datasplit', dataSplit);
			dataBody = dataSplit;
		}

		setisProgress(true);
		setisProgress2(true);

		// let nomorNtpn = data.ntpn;
		// let nomorData = data.nomor;

		let url = Api.IP_LNSW_CMS + 'ntpn';
		// console.log(url);

		let data_input = {
			ntpn: dataBody,
		};
		console.log('data input', data_input);

		axios({
			method: 'post',
			url: url,
			headers: { Authorization: Api.BASIC_AUTH },
			timeout: 1000 * 10, // Wait for 10 seconds
			data: data_input,
		})
			.then((response) => {
				setisProgress(false);
				setisProgress2(false);
				// console.log('data response', response.data.data);
				if (response.data.code === '01') {
					if (response.data.data.length === 0) {
						SetDataNtpn([]);
						setErrorMessage(t('empty_list'));
					} else {
						// console.log('length !== 0');
						// console.log('data response', response.data.data);
						let data_list = response.data.data;
						SetDataNtpn(data_list);
					}
				} else {
					// console.log('code 02');
					// console.log(response.data.code);
					SetDataNtpn([]);
					setErrorMessage(t('empty_list'));
				}
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
				setisProgress(false);
				setisProgress2(false);
				SetDataNtpn([]);
				setErrorMessage(t('error_message'));
			});
	};

	const filter_input = (input_text) => {
		let filter_text = input_text.replace(/\'|\"|\--/g, '');
		return filter_text;
	};

	//did mount
	useEffect(() => {
		// console.log('data_ntpn', data_ntpn);

		searchNtpn(data_ntpn);
	}, []);

	const see_detail = (item, index) => {
		setisProgress2(true);
		// console.log('detail item', item);
		// console.log('detail index', index);
		setDataKiri(item);
	};

	return (
		<div className='detail-ntpn-container'>
			<div className='penelusuran-content-title'>
				<div className='penelusuran-content-sub-title'>
					<div className='penelusuran-text-title'>{t('track_ntpn')}</div>
					<div className='penelusuran-input-search row justify-content-center m-0 mt-3'>
						<div className='col-md-5 mb-2'>
							<InputSearch
								placeholder={t('enter_ntpn')}
								onChange={(e) => SetNtpn(e.target.value)}
								onKeyDown={() =>
									searchNtpn({
										ntpn: no_ntpn,
									})
								}
								onClick={() =>
									searchNtpn({
										ntpn: no_ntpn,
									})
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='detail-pibpeb-content'>
				{is_progress === true ? (
					<div style={{ height: '25vw' }} className='detail-content-load'>
						<LoadProgress />
					</div>
				) : dataNtpn.length === 0 ? (
					<div className='detail-content-load'>
						<ListEmpty message_error={error_message} />
					</div>
				) : (
					<>
						<div className='detail-kiri'>
							{dataNtpn.map((item, index) => (
								<CardNtpn
									no_izin={item.ntpn}
									onClick={() => see_detail(item, index)}
								/>
							))}
						</div>
						<div className='detail-kanan'>
							<div className='detail-atas'>
								<div className='detail-title'>
									<p className='detail-title-text'>{t('detail-ntpn')}</p>
								</div>
							</div>
							<div className='detail-bawah'>
								{is_progress2 === false ? (
									<ListFirst
										message_error={t('atr_me_title')}
										message_error2={t('atr_me_subtitle')}
									/>
								) : (
									<>
										<div className='detail-container'>
											<p className='detail-judul'>NTPN</p>
											<p className='detail-hasil'>
												{dataKiri.ntpn === '' ? '-' : dataKiri.ntpn}
											</p>
										</div>
										{/* <div className="detail-container">
                                                        <p className="detail-judul">{t('ga_number')}</p>
                                                        <p className="detail-hasil">{dataKiri.no_ga === '' ? '-' : dataKiri.no_ga}</p>
                                                    </div>
                                                    <div className="detail-container">
                                                        <p className="detail-judul">NPWP</p>
                                                        <p className="detail-hasil">{dataKiri.npwp === '' ? '-' : dataKiri.npwpb}</p>
                                                    </div>
                                                    <div className="detail-container">
                                                        <p className="detail-judul">{t('company-name')}</p>
                                                        <p className="detail-hasil">{dataKiri.company_name === '' ? '-' : dataKiri.company_name}</p>
                                                    </div>
                                                    <div className="detail-container">
                                                        <p className="detail-judul">{t('ntpn-date')}</p>
                                                        <p className="detail-hasil">{dataKiri.ntpn_date === '' ? '-' : moment(dataKiri.ntpn_date).format('DD MMMM YYYY HH:mm')}</p>
                                                    </div> */}
										<div className='detail-container-last'>
											<p className='detail-judul'>{t('status')}</p>
											<p className='detail-hasil'>
												{dataKiri.message === '' ? '-' : dataKiri.message}
											</p>
										</div>
									</>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default DetailNib;
