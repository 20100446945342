import React from 'react';
import './card_ntpn.scss';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const CardNtpn = ({no_izin, onClick, jenis, judul}) => {
    const { t } = useTranslation();
    return (
        <div className="ntpncard-container">
            <p className="ntpncard-title">{window.location.href.includes('lhv') ? "Nomor LHV" : "NTPN"}</p>
            <div className="ntpncard-content">
                <div className="ntpncard-kiri">
                    <p className="ntpncard-no-ntpn">{no_izin}</p>
                </div>
                <div className="ntpncard-kanan">
                    <Button className="button-lihat" onClick={onClick}>{t('look')}</Button>
                </div>
            </div>
        </div>
    )
}

export default CardNtpn
