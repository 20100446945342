/* eslint-disable jsx-a11y/alt-text */
//import lib react
import React, { useEffect, useRef, useState } from "react";
import "./intr.scss";
// import { Chart } from 'react-charts';

import axios from "axios";

import { useTranslation } from "react-i18next";
import moment from "moment";

import { InputSearch } from "../../components/atoms";

import CarouselContentKNFP from "../../components/molecules/CarouselContentKNFP/index.js";
import CarouselKL from "../../components/molecules/CarouselKL/index.js";
import * as Api from "../../constants/api";
import { useHistory } from "react-router-dom";
import Footer from "../KNFP/Footer";
import {
  getLanguageStorageUpperCaseKNFP,
  setLanguageStorage,
} from "../../constants/storage";
import { ICAS, ICGP, IMKL } from "../../assets";
import { Modal, Button, Badge, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { KnfpNewsSection } from "./components/KnfpNewsSection";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

const TradeFacilitation = () => {
  //variable constant
  const { t } = useTranslation();

  const [userRating, setUserRating] = useState(null);

  const history = useHistory();
  const [lartas, setLartas] = useState("");

  const [idTracking, setIdTracking] = useState("")
  const [enquiryResult, setEnquiryResult] = useState(null)
  // const [topic, setTopic] = useState(null)
  const [show, setShow] = useState(false);
  const toggle = () => setShow(!show);
  

  const removeDot = (item) => {
    var imageUrl = Api.IP_KONG_LNSW + item.slice(1);
    // console.log(imageUrl);
    return imageUrl;
  };

  const UpdateRating = async ({ userRating, id }) => {
      const url = `https://api.insw.go.id/api/cms/knfp/enquery/update_rating/${id}`;
      const data = { rating: userRating.toString() };
      try {
        const response = await axios.put(url, data, {
          headers: {
            'Authorization': Api.BASIC_AUTH,
          }
        });
        console.log('Response:', response.data);
      } catch (error) {
        console.error('Error:', error);
      }
  };

  const findEnquiryByUUID = async () => {
    try {
      const response = await axios.get(`${Api.KNFP_TRACK_ENQUIRY}/${idTracking}`, {
        headers: {
          'Authorization': Api.BASIC_AUTH
        }
      })

      const enquiry = response.data.result.data[0]

      console.log("enquiry: ", enquiry)

      setEnquiryResult(enquiry)
    } catch (error) {
      setEnquiryResult(null)
      console.log("error search enquiry: ", error)
    }
  }

  return (
    <>
      <div className="row m-0 intr-break">
        {/* <BreadCrumbs /> */}
        <div className="section">
          <div className="intr-background col-md-12 p-0">
            <div className="intr-content-title">
              <div className="intr-content-sub-title">
                <div className="intr-text-title">{t("knfp-trade-facility")}</div>
                <div className="intr-text-message">
                  {t("knfp-trade-facility-subtitle")}
                </div>

                {/* {getLanguageStorageUpperCaseKNFP() === "ID" ? (
                  <a
                    onClick={() => {
                      setLanguageStorage("en");
                      window.location.reload();
                    }}
                    className="switch-lang"
                  >

                    Switch To English Language
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      setLanguageStorage("id");
                      window.location.reload();
                    }}
                    className="switch-lang"
                  >
                    Ubah Ke Bahasa Indonesia
                  </a>
                )} */}

              </div>
            </div>
          </div>
          <div className="intr-menu col-md-12">
            <CarouselContentKNFP />
          </div>
        </div>

        <div className="intr-kurs col-md-12"></div>

        <div className="test col-md-12">
          <div className="home-tracking">
            <div className="row m-0 justify-content-center">
              <div className="col-md-6">
                <div className="intr-input-search" style={{ marginBottom: '2vw' }}>
                  <InputSearch
                    placeholder={t("initValueInputPlaceHolder")}
                    onChange={e => setIdTracking(e.target.value)}
                    onClick={async () => {
                      await findEnquiryByUUID();
                      toggle()
                    }}
                    onKeyDown={() => toggle()}
                  // onKeyDown={handleKeyDown}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="test col-md-12">
          <div className="home-kementrian">
            <div className="home-kementrian-content">
              <KnfpNewsSection />
              <div class="latest-news-container">
                <Button
                  style={{ background: "#02275d" }}
                  onClick={() => history.push("/knfp/berita")}
                >
                  {t("Lihat Semua Berita")}
                </Button>
              </div>
            </div>
            <div className="home-kementrian-content">

              <p className="kementrian-title">{t("knfp_foot_name")}</p>

              <div className="kementrian-carousel">
                <CarouselKL />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <Modal
        isOpen={show}
        toggle={toggle}
        size="lg"
        centered
        scrollable
        style={{
          display: "flex",
          height: "500px",
          flexDirection: "column",
          flexWrap: "wrap"
        }}
      >
        <ModalHeader toggle={toggle} cssModule={{ 'modal-title': 'modal-title-tracking' }}>Tracking Enquiry</ModalHeader>
        <ModalBody

        // style={{
        //   display:"flex",
        //   height:"500px",
        //   flexDirection:"column",
        //   flexWrap: "wrap"
        // }}
        >
          {
            !enquiryResult?.id ? (
              <>
                <h3>Data enquiry dengan nomor tiket {idTracking} tidak ditemukan</h3>
                <p>Periksa kembali nomor tiket yang anda masukkan</p>
              </>
            ) : (
              <>
                <div>
                  <h5>{t("referensi-tiket")}</h5>
                  <p>{enquiryResult?.enquiry_id_new}</p>
                </div>

                <div
                  style={{
                    width: "350px"
                  }}
                >
                  <h5>Status Pertanyaan</h5>
                  <p>{
                    enquiryResult?.status_pertanyaan === "pelaksana_harian"
                      ? "Pertanyaan anda sedang diproses"
                      : enquiryResult?.status_pertanyaan === "penanya"
                        ? "Pertanyaan anda sudah dijawab dan dikirimkan ke email anda"
                        : "Pertanyaan anda sudah diterima"
                  }
                  </p>
                  {/* <Badge
                    pill
                    style={{
                      padding: "10px",
                      maxWidth: "300px",
                      width: "fit-content",
                      color: "white",
                      backgroundColor: "#02275d",
                    }}
                  >{
                      enquiryResult?.status_pertanyaan === "pelaksana_harian"
                        ? "Pertanyaan anda sedang diproses"
                        : enquiryResult?.status_pertanyaan === "penanya"
                          ? "Pertanyaan anda sudah dijawab dan dikirimkan ke email anda"
                          : "Pertanyaan anda sudah diterima"
                    }
                  </Badge> */}
                </div>

                <div>
                  <h5>Kategori</h5>
                  <p>{
                    enquiryResult?.status === "cc"
                      ? "Crosscuting"
                      : enquiryResult?.status === "nwa"
                        ? "Not Within My Authority"
                        : "-"
                  }</p>
                </div>

                <div>
                  <h5>Pesan</h5>
                  <p>{enquiryResult?.pesan}</p>
                </div>

                <div>
                  <h5>Waktu Dibuat</h5>
                  <p>{enquiryResult?.created_date}</p>
                </div>

                <div>
                  <h5>Email Pengirim</h5>
                  <p>{enquiryResult?.email_pengirim}</p>
                </div>

                <div>
                  <h5>Topik</h5>
                  <p>{enquiryResult?.topic}</p>
                </div>
                <div>
                  <h5>SubTopik</h5>
                  <p>{enquiryResult?.subtopic}</p>
                </div>
                <div>
                  <h5>Subsubtopik</h5>
                  <p>{enquiryResult?.subsubtopic}</p>
                </div>
                { enquiryResult?.rated }
                {enquiryResult?.status_pertanyaan === "penanya" && (
                  <div>
                    <h5>Jawaban Pesan</h5>
                    <p>{enquiryResult.comments[enquiryResult.comments.length - 1].isi_comment}</p>
                    
                    {enquiryResult.comments[enquiryResult.comments.length - 1].isi_comment && !enquiryResult?.rated && (
                      <div>
                        <h5>Rating</h5>
                        <Stack spacing={1}>
                          <Rating
                            name="half-rating"
                            defaultValue={enquiryResult?.rating}
                            precision={1}
                            size="large"
                            onChange={(event, rating) => {
                              setUserRating(rating);
                              UpdateRating({ userRating: rating, id: enquiryResult?.id });
                            }}
                          />
                        </Stack>
                      </div>
                    )}

                    {enquiryResult.comments[enquiryResult.comments.length - 1].isi_comment && enquiryResult?.rated && (
                      <div>
                        <h5>Terimakasih Telah Memberikan Penilaian </h5>
                      </div>
                    )}
                  </div>
                )}

                    
              </>
            )
          }
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Tutup
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </>

  );
};

export default TradeFacilitation;
