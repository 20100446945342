import React, { useEffect, useState } from 'react'
import { Row, Col, Popover, PopoverBody } from 'reactstrap'
import ApiGet from './Services/api_get'
import * as Api from '../../constants/api'
import 'react-multi-carousel/lib/styles.css'
import './index.scss'
import { IconNLE } from '../../assets'
import MapMarkers from './Components/MapMarkers'
import BannerMenu from './Components/BannerMenu'

const IconMenu = ({ img, link_sosmed }) => {
   return (
      <div className="icon-wrapper">
         <a target="_blank" href={link_sosmed}>
            <img
               className="img-wrapper"
               src={`${Api.APP_IMG}${img}.png`}
               alt="icon"
            />
         </a>
      </div>
   )
}

const AplikasiLnsw = () => {
   const [showPin, setShowPin] = useState(false)
   const [pinColor, setPinColor] = useState('')
   const [bannerMenu, setBannerMenu] = useState([])
   const [headerMenu, setheaderMenu] = useState([])
   const [location, setLocation] = useState([])

   useEffect(() => {
      ApiGet.getGroupMenu()
         .then((response) => {
            if (response.code === '01') {
               setBannerMenu(response.data)
            }
         })
         .catch((error) => {
            console.log({ error })
         })
      ApiGet.getMenuNle()
         .then((response) => {
            if (response.code === '01') {
               setheaderMenu(response.data)
            }
         })
         .catch((error) => {
            console.log({ error })
         })
   }, [])

   return (
      <div className="container m-0 p-0">
         <div className="nle-banner">
            <Row className="nle-header">
               <Col className="nle-icon" xs={3}>
                  <a
                     target="_blank"
                     href="https://nle.kemenkeu.go.id/"
                     style={{ color: 'white', textDecoration: 'none' }}
                  >
                     <Row>
                        <img className="nle-img" src={IconNLE} />
                        <span className="nle-desc">
                           Aplikasi yang digunakan untuk kegiatan logistik
                           nasional
                        </span>
                     </Row>
                  </a>
               </Col>
               <Col>
                  <Row style={{ flexWrap: 'nowrap' }}>
                     {headerMenu &&
                        headerMenu.map((item, index) => (
                           <>
                              <Col
                                 id={item.icon_name + item.id}
                                 key={index}
                                 className="menu-header"
                                 onMouseEnter={() => {
                                    setShowPin(true)
                                    setPinColor(item.addon_icon)
                                    setLocation(item.pelabuhan_detail || [])
                                 }}
                                 onMouseLeave={() => setShowPin(false)}
                              >
                                 <IconMenu
                                    img={item.icon_name}
                                    link_sosmed={item.address_link}
                                 />
                                 <div className="menu-btn">
                                    <div
                                       className="menu-pin"
                                       style={{
                                          backgroundColor: item.addon_icon,
                                       }}
                                    />
                                    <span className="menu-name">
                                       {item.modul_name}
                                    </span>
                                 </div>
                              </Col>
                              {item.addon_icon === pinColor && (
                                 <Popover
                                    target={item.icon_name + item.id}
                                    isOpen={showPin}
                                    toggle={() => setShowPin(false)}
                                    placement="bottom"
                                    trigger="focus"
                                 >
                                    <PopoverBody>
                                       {item.description}
                                    </PopoverBody>
                                 </Popover>
                              )}
                           </>
                        ))}
                  </Row>
               </Col>
            </Row>

            <div className="map-content">
               {headerMenu && (
                  <MapMarkers
                     showPin={showPin}
                     location={location}
                     bgColor={pinColor}
                     pinSize={14}
                  />
               )}
            </div>
         </div>

         {bannerMenu &&
            bannerMenu.map((item, index) => (
               <BannerMenu
                  idx={index}
                  bgColor={item.color || 'orange'}
                  title={item.group_short_name}
                  subTitle={item.group_complete_name}
                  desc={item.description}
                  menu={item.group_detail}
                  maxItem={bannerMenu.length}
               />
            ))}
      </div>
   )
}

export default AplikasiLnsw
