import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
//component
import { ICAS, ICGP, IMKL } from '../../assets';
import { CarouselBeritaTerkini, CarouselComp, CarouselKemen, CarouselStatistik, ListBerita, ListEmpty } from '../../components';
import * as Api from '../../constants/api';
import './home.scss';
import { isAndroid, isIOS } from 'react-device-detect';

const Icon = ({ img, link_sosmed }) => {
    return (
        <div className="icon-wrapper" >
            <a target='_blank' href={link_sosmed}>
                <img className="icon-medsos" src={img} alt="icon" />
            </a>
        </div>
    )
}

const Home = () => {
    //variable constant berita
    const { t } = useTranslation();
    const history = useHistory();
    const [list_berita, setListBerita] = useState([]);
    const [isProgressBerita, setisProgressBerita] = useState(false);
    const [error_message_Berita, setErrorMessageBerita] = useState(false);
    const [statistik, setStatistik] = useState(false);

    // did mount
    useEffect(() => {
        _get_data_berita();
    }, []);

    const CallImage = (image_url) => {
        // var replace_url = image_url.substring(1);
        var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        // console.log('url_image');
        // console.log(url_image);
        return url_image;
    }

    const _get_data_berita = () => {
        setisProgressBerita(true);
        const url = Api.BERITA_POPULER + '&lang=' + localStorage.getItem('language').toUpperCase();
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                // console.log('Get Berita');
                // console.log(response.data.data);
                setisProgressBerita(false);
                if (response.status === 200) {
                    setListBerita(response.data.data);
                } else {
                    setListBerita([]);
                    setErrorMessageBerita('Data Kosong');
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setisProgressBerita(false);
                setListBerita([]);
                setErrorMessageBerita('Terjadi masalah, silahkan coba lagi nanti');
            });
    }

    const scrollStatistik = () => {
        // console.log(window.scrollY);
        // console.log(statistik);
        if (window.scrollY >= 1800) {
            setStatistik(true);
        } else {
            setStatistik(false);
        }
    };

    const _click_detail_berita = (id_berita_click) => {
        // history.push('/detail-berita', { id_berita: id_berita_click });
        history.push('/detail-berita/' + id_berita_click);
    }

    window.addEventListener('scroll', scrollStatistik);

    return (
        <div>
            <div className="home-carousel">
                <CarouselComp />
            </div>
            <div className="home-video">
                <div className="home-content row m-0">
                    <div className="home-content-text col-md-6">
                        <p>{t('lnsw')}</p>
                        <p className="home-content-text-sub">{t('home_video')}</p>
                        <p>{t('home_video_unduh')}</p>
                        <div className="row m-0">
                            {isAndroid ? (
                                <div className="col-xs-6 col-md-3 p-0 mb-1">
                                    <Icon img={ICGP} link_sosmed="https://play.google.com/store/apps/details?id=com.mobile_insw" />
                                </div>
                            ) : isIOS ? (
                                <div className="col-xs-6 col-md-3 p-0 mb-1">
                                    <Icon img={ICAS} link_sosmed="https://apps.apple.com/id/app/insw-mobile/id1571066113" />
                                </div>
                            ) : (
                                <>
                                    <div className="col-xs-6 col-md-3 p-0 mb-1">
                                        <Icon img={ICGP} link_sosmed="https://play.google.com/store/apps/details?id=com.mobile_insw" />
                                    </div>
                                    <div className="col-xs-6 col-md-3 p-0 mb-1">
                                        <Icon img={ICAS} link_sosmed="https://apps.apple.com/id/app/insw-mobile/id1571066113" />
                                    </div>
                                </>
                            )}


                        </div>
                    </div>
                    <div className="home-content-video col-md-6">
                        {/* <p>Video</p> */}
                        <div className="video-wrapper">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/g0VB2T8q-kk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-berita">
                <div className="home-berita-content row m-0">
                    <div className="berita-terkini col-md-6 p-0">
                        <p className="berita-title">{t('latest_news')}</p>
                        <CarouselBeritaTerkini />
                    </div>
                    <div className="berita-populer col-md-6 p-0">
                        <p className="berita-title">{t('popular_news')}</p>
                        {isProgressBerita === true ?
                            <div className="berita-scroll" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner color="primary" />
                            </div> :
                            list_berita.length === 0 ?
                                <div className="berita-scroll" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="detail-content-load">
                                        <ListEmpty message_error={'error_message'} />
                                    </div>
                                </div>
                                :
                                <div className="berita-scroll">
                                    {list_berita.map((item) =>
                                        <ListBerita
                                            onClick={() => _click_detail_berita(item.id_berita)}
                                            gambar={CallImage(item.file)}
                                            alt="Berita"
                                            judul={item.header}
                                            header={item.cuplikan}
                                        />
                                    )}
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className="home-statistik">
                <CarouselStatistik />
            </div>
            <div className="home-kementrian">
                <div className="home-kementrian-content">
                    <p className="kementrian-title">{t('kementrian')}</p>
                    <div className="kementrian-image">
                        <img src={IMKL} alt="Kementrian" />
                    </div>
                    <div className="kementrian-carousel">
                        <CarouselKemen />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
