import React, { useState, useEffect } from 'react';
import CardBerita from '../CardBerita';
import * as Api from '../../../constants/api';
import axios from 'axios';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import './carousel_berita_terkini.scss';
import { CarouselItems } from '..';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import ListEmpty from '../ListEmpty';

const CarouselBeritaTerkini = () => {
    //variable constant berita
    const history = useHistory();
    const [list_berita, setListBerita] = useState([]);
    const [list_content, setListContent] = useState([]);
    const [dua_berita, setDuaBerita] = useState([]);
    const [isProgressBerita, setisProgressBerita] = useState(false);
    const [error_message_Berita, setErrorMessageBerita] = useState(false);

    // did mount
    useEffect(() => {
        _get_data_berita();
    }, []);

    const CallImage = (image_url) => {
        // var replace_url = image_url.substring(1);
        var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        // console.log('url_image');
        // console.log(url_image);
        return url_image;
    }

    const _click_detail_berita = (id_berita_click) => {
        // history.push('/detail-berita', { id_berita: id_berita_click });
        history.push('/detail-berita/' + id_berita_click);
    }

    const _get_data_berita = () => {
      setisProgressBerita(true);
      const url = Api.BERITA_TERKINI + '&lang=' + localStorage.getItem('language').toUpperCase();
      // console.log(url);
      axios({
        method: 'get',
        url: url,
        headers: { 'Authorization': Api.BASIC_AUTH },
        timeout: 1000 * 10, // Wait for 10 seconds
      })
        .then(response => {
          // console.log('Get Berita home');
          // console.log(response.data);
          setisProgressBerita(false);
          if (response.status === 200) {
              let data_berita = response.data.data;
              for (var i = 0; i < data_berita.length; i++) {
                // data_berita[i].file = CallImage(data_berita[i].file);
                var data_image = Api.IP_KONG_LNSW + data_berita[i].file.slice(1)
                data_berita[i].file = data_image;
              }
              // console.log('data_berita');
              // console.log(data_berita);
              setListBerita(data_berita);
              duaBerita(data_berita);
          } else {
              setListBerita([]);
              setErrorMessageBerita('Data Kosong');
          }
        })
        .catch(error => {
          console.log('error');
          console.log(error);
          setisProgressBerita(false);
          setListBerita([]);
          setErrorMessageBerita('Terjadi masalah, silahkan coba lagi nanti');
        });
    }

    const duaBerita = (item) => {
      let content_double = [];
      let content = [];
      var count = 1;
      for (let i = 0; i < item.length; i++) {
          // console.log(count);
          content_double.push(item[i]);
          // console.log(item[i].title);
          if (count === 2) {
              let data = {
                  data : content_double
              }
              content_double = [];
              content.push(data);    
              count = 1;
          } else {
            // console.log(item.length - i);
            if (item.length - i === 1) {
              let data = {
                  data : content_double
              }
              content_double = [];
              content.push(data);
              count = count + 1 
            } else {
              count = count + 1 
            }
          }
      }
      // console.log(content);
    
      setListContent(content);
    }

    const slides = list_content.map((item) => {
        return (
            <div className="berita-terkini-content">
              <div className="berita-terkini-item">
                {item.data.map((item_data) => 
                    <CardBerita 
                      onClick={
                        () => _click_detail_berita(item_data.id_berita)
                      }
                      gambar={item_data.file} 
                      alt= "Berita" 
                      judul={item_data.header} 
                    />
                )}
                </div>
            </div>
        );
    });

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: {
          max: 3000,
          min: 1024
        },
        items: 1
      },
      mobile: {
        breakpoint: {
          max: 464,
          min: 0
        },
        items: 1
      },
      tablet: {
        breakpoint: {
          max: 1024,
          min: 464
        },
        items: 1
      }
    };

    const CustomDot = ({ onMove, index, onClick, active }) => {
      // onMove means if dragging or swiping in progress.
      // active is provided by this lib for checking if the item is active or not.
      return (
        <li
          className={active ? "active-caro" : "inactive-caro"}
          onClick={() => onClick()}
        >
          {/* {index + 1} */}
        </li>
      );
    };
    
    // const CustomRight = ({ onClick }) => (
    //   <button className="arrow right" onClick={onClick}>
    //     Right
    //   </button>
    // );
    // const CustomLeft = ({ onClick }) => (
    //   <button className="arrow left" onClick={onClick}>
    //     Left
    //   </button>
    // );
    //Also please improve the dot problem
    // Here we used number to just know click event buthelp us with ui too

    return (
      <div className="berita-terkini-custom-tag">
        {
          isProgressBerita === true ?
            <div className="berita-terkini-spinner" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                <Spinner color="primary" />
            </div> :
            list_berita.length === 0 ?
                <div className="berita-terkini-spinner" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <div className="detail-content-load">
                        <ListEmpty message_error={'error_message'} />
                    </div>
                </div>
                :
                <Carousel
                  additionalTransfrom={0}
                  arrows={false}
                  autoPlay={true}
                  // ssr={true}
                  autoPlaySpeed={3000}
                  centerMode={false}
                  className=""
                  containerClass="container"
                  customDot={<CustomDot />}
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  itemClass=""
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={responsive}
                  showDots
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable
                >
                  {slides}            
                </Carousel>
      }
      </div>
    )
}

export default CarouselBeritaTerkini;
