import React from 'react';
import './App.css';
import { Routes } from '../config';
import {Helmet} from "react-helmet";

function App() {
  const judulWeb = 'Official Website INSW';
  const inswEnv = "INSW-DC"
  console.log(inswEnv);

  return (
    <div>
      <header>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{judulWeb}</title>
          <meta name="description" content="Official Website of Indonesia National Single Window (INSW) providing integrated services for import and export processes." />
          <meta name="keywords" content="INSW, Indonesia National Single Window, import, export, trade, customs" />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href="https://insw.go.id" />
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
      </header>
      <Routes />
    </div>
  );
}

export default App;
