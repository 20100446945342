import React, { useState, useEffect, useRef } from 'react'
import './nib.scss'
import { useTranslation } from 'react-i18next'
import { InputCapsule, InputSearch } from '../../../components/atoms'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import * as Api from '../../../constants/api'
import parse from 'html-react-parser'
import { ListEmpty, LoadProgress } from '../../../components'
import { Penelusuran_BG } from '../../../assets'

const Nib = () => {
   const { t } = useTranslation()
   const history = useHistory()
   const [no_npwp, SetNpwp] = useState('')
   const data_npwp = useRef('')
   const [no_nib, SetNoNib] = useState('')
   const [judul, setJudul] = useState([])
   const [is_progress, setisProgress] = useState(false)
   const [error_message, setErrorMessage] = useState('')

   const searchNib = () => {
      history.push('/nib/detail', {
         data: {
            no_npwp: data_npwp.current,
            nomor: no_nib,
         },
      })
   }

   // did mount
   useEffect(() => {
      _get_data_judul()
   }, [])

   const _get_data_judul = () => {
      setisProgress(true)
      let idMenu = localStorage.getItem('menu_select')
      if (idMenu === null || idMenu !== 39) {
         idMenu = 39
      }

      const url =
         Api.JUDUL +
         idMenu +
         '&lang=' +
         localStorage.getItem('language').toUpperCase()
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgress(false)
            if (response.data.code === '01') {
               setJudul(response.data.data)
            } else {
               setErrorMessage(t('empty_list'))
               setJudul([])
            }
         })
         .catch((error) => {
            setisProgress(false)
            setJudul([])
            setErrorMessage(t('error_message'))
         })
   }

   const CallImage = (image_url) => {
      var url_image = Api.IP_KONG_LNSW + image_url.slice(1)
      // console.log(url_image);
      return url_image
   }

   //function
   const parseHtml = (html) => {
      // console.log('html');
      // console.log(html)
      var text_html = ''
      if (html !== undefined) {
         text_html = parse(html)
      }
      // var text_html = parse(html.toString());

      return text_html
   }
   const RegexNPWP = (value) => {
      // console.log(value);
      var regex_npwp = value.replace(/[^0-9,]/g, '')
      data_npwp.current = regex_npwp
      if (regex_npwp.length > 15) {
         regex_npwp = regex_npwp.replace(
            /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/,
            '$1.$2.$3.$4-$5.$6',
         )
      } else if (regex_npwp.length > 12) {
         regex_npwp = regex_npwp.replace(
            /(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})/,
            '$1.$2.$3.$4-$5.',
         )
      } else if (regex_npwp.length > 9) {
         regex_npwp = regex_npwp.replace(
            /(\d{2})(\d{3})(\d{3})(\d{1})/,
            '$1.$2.$3.$4-',
         )
      } else if (regex_npwp.length > 8) {
         regex_npwp = regex_npwp.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2.$3.')
      } else if (regex_npwp.length > 5) {
         regex_npwp = regex_npwp.replace(/(\d{2})(\d{3})/, '$1.$2.')
      } else if (regex_npwp.length > 2) {
         regex_npwp = regex_npwp.replace(/(\d{2})/, '$1.')
      }
      // console.log(regex_npwp);
      SetNpwp(regex_npwp)
   }

   return (
      <div className="penelusuran-nib-container">
         {is_progress === true ? (
            <div style={{ height: '25vw' }} className="detail-content-load">
               <LoadProgress />
            </div>
         ) : judul.length === 0 ? (
            // <div className="detail-content-load">
            //     <ListEmpty message_error={error_message} />
            // </div>
            <div className="penelusuran-background">
               {/* ganti src nya pakai source background  */}
               <img
                  // src={CallImage(item.nama_img)}
                  src={Penelusuran_BG}
                  className="penelusuran-background-img"
                  alt="bg"
               />
               <div className="penelusuran-content-title">
                  <div className="penelusuran-content-sub-title">
                     <div className="penelusuran-text-title">
                        {/* {parseHtml(item.header)} */}
                        {/* hardcode ganti sama penulusuran nib */}
                        Penelusuran NIB
                     </div>
                     <div className="penelusuran-text-message">
                        {/* {parseHtml(item.ur)} */}
                        {/* hard subtitle */}
                        Penelusuran NIB berdasarkan NPWP dan Nomor Induk
                        Berusaha di Lembaga National Single Window khusus NIB
                        yang memiliki registrasi kepabeanan untuk kegiatan
                        ekspor dan/atau impor
                     </div>
                     <div className="row m-0 justify-content-center mt-5">
                        <div className="col-md-4 mb-2">
                           <div className="penelusuran-input-search">
                              <InputCapsule
                                 placeholder={t('enter_npwp')}
                                 value={no_npwp}
                                 onChange={(e) => RegexNPWP(e.target.value)}
                              />
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div className="penelusuran-input-search">
                              <InputSearch
                                 placeholder={t('look_the_nib')}
                                 onChange={(e) => SetNoNib(e.target.value)}
                                 onKeyDown={() => searchNib()}
                                 onClick={() => searchNib()}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         ) : (
            judul.map((item, index) => (
               <div className="penelusuran-background">
                  <img
                     src={CallImage(item.nama_img)}
                     className="penelusuran-background-img"
                     alt="bg"
                  />
                  <div className="penelusuran-content-title">
                     <div className="penelusuran-content-sub-title">
                        <div className="penelusuran-text-title">
                           {parseHtml(item.header)}
                        </div>
                        <div className="penelusuran-text-message">
                           {parseHtml(item.ur)}
                        </div>
                        <div className="row m-0 justify-content-center mt-5">
                           <div className="col-md-4 mb-2">
                              <div className="penelusuran-input-search">
                                 <InputCapsule
                                    placeholder={t('enter_npwp')}
                                    value={no_npwp}
                                    onChange={(e) => RegexNPWP(e.target.value)}
                                 />
                              </div>
                           </div>
                           <div className="col-md-4">
                              <div className="penelusuran-input-search">
                                 <InputSearch
                                    placeholder={t('look_the_nib')}
                                    onChange={(e) => SetNoNib(e.target.value)}
                                    onKeyDown={() => searchNib()}
                                    onClick={() => searchNib()}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            ))
         )}
      </div>
   )
}

export default Nib
