import Axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-bootstrap';

export default function InfoWajibBerkalaNew() {
   let currentLanguage = localStorage.getItem('language')
   const [dataCms, setDataCms] = useState([])
   const [openIndex, setOpenIndex] = useState({});
   const USERNAME_AUTH = process.env.REACT_APP_BASIC_AUTH_USERNAME;
   const PASSWORD_AUTH = process.env.REACT_APP_BASIC_AUTH_PASSWORD;

   const initializeOpenIndex = (data) => {
      let initialState = {};
      data.forEach((item, index) => {
         if (item.child) {
            item.child.forEach((subItem, subIndex) => {
               if (subItem.fl_dropdown) {
                  const path = `${index}-${subIndex}`;
                  initialState[path] = true;
               }
            });

         }
      });
      return initialState;
   };

   const handleClick = (path, item) => {
      if (item?.url !== "" || item?.files !== null) {
         goTo(item)
      } else {
         setOpenIndex((prevState) => ({
            ...prevState,
            [path]: !prevState[path],
         }));
      }



   };

   const getDataForLandingPage = async () => {
      try {
         const landingPage = await Axios.get('https://api.insw.go.id/api/cms/ppidweb', {
            auth: {
               username: USERNAME_AUTH,
               password: PASSWORD_AUTH,
            },
         }
         )
         if (landingPage?.status === 200) {
            setDataCms(landingPage?.data?.data)
         }
      } catch (error) {
         console.log(error);
      }
   }

   const goTo = (isiList) => {
      if (isiList?.url !== null && isiList?.url.length > 0) {
         window.open(isiList?.url, '_blank')
      } else if (isiList?.files !== null) {
         let eliminate = isiList.files
         let url = eliminate.replaceAll('./assets', 'assets');
         window.open(`https://api.insw.go.id/${url}`)
      }
   }


   dataCms.sort((a, b) => {
      if (a.no_urut < b.no_urut) return -1;
      if (a.no_urut > b.no_urut) return 1;
      return 0;
   });

   const handleGoIfThereLink = (item) => {
      if (item?.url !== "" || item?.files !== null) {
         goTo(item)
      }
   }


   dataCms.forEach(obj => {

      if (obj.child && obj.child.length > 0) {
         obj.child.sort((childA, childB) => {
            return childA.no_urut - childB.no_urut;
         });

         obj.child.forEach(child => {

            if (child.child && child.child.length > 0) {
               child.child.sort((grandChildA, grandChildB) => {
                  if (grandChildA.no_urut < grandChildB.no_urut) return -1;
                  if (grandChildA.no_urut > grandChildB.no_urut) return 1;
                  return 0;
               });

               child.child.forEach(grandChild => {
                  if (grandChild.child && grandChild.child.length > 0) {
                     grandChild.child.sort((greatGrandChildA, greatGrandChildB) => {
                        return greatGrandChildA.no_urut - greatGrandChildB.no_urut;
                     });
                  }
               });
            }
         });
      }
   });



   useEffect(() => {
      if (dataCms && dataCms.length > 0) {
         setOpenIndex(initializeOpenIndex(dataCms));
      }
   }, [dataCms]);


   useEffect(() => {
      getDataForLandingPage()
      if (localStorage.getItem('language') === null) {
         localStorage.setItem('language', 'id');
      }
   }, [])

   return (
      <>
         <div className=" row m-0 " style={{ padding: '40px' }}>
            <div className="col-md-12">
               <div style={{ margin: '30px' }} >
                  <h3 className='text-center ' style={{ color: '#02275d', padding: '40px' }}  >
                     Informasi Publik Wajib Berkala
                  </h3>
               </div>
            </div>

            <div className="text-left container">
               <div className="col-md-12">
                  <ol>
                     {dataCms.map((item, index) => (
                        <div key={index}>
                           <li key={index} style={{ listStyle: 'none', marginBottom: '40px', color: '#02275d', }}  >
                              <h5 style={{ cursor: 'pointer', fontSize: '20px', fontWeight: '700', }} onClick={() => handleGoIfThereLink(item)}  >
                                 <div style={{ display: 'flex' }} >
                                    <span >
                                       {item?.no_urut}.
                                    </span>
                                    <span>&nbsp;&nbsp;</span>
                                    <div  >
                                       <span  >
                                          {currentLanguage === 'id' ? item?.judul_ppid_id : item?.judul_ppid_en}
                                       </span>
                                    </div>
                                 </div>
                              </h5>
                              <ol style={{ listStyle: 'none', margin: '10px' }}>
                                 {item.child && item.child.map((subItem, subIndex) => {
                                    const path = `${index}-${subIndex}`;
                                    return (
                                       <div key={path} style={{ marginLeft: 20 }}>
                                          <li
                                             style={{ cursor: 'pointer', borderBottom: '1px solid #d0d0d0' }}
                                             onClick={() => handleClick(path, subItem)}
                                             aria-controls={`example-collapse-text-${path}`}
                                             aria-expanded={!!openIndex[path]}
                                          >
                                             <div style={{ display: 'flex' }} >
                                                <span>
                                                   {subItem.no_urut}.
                                                </span>
                                                <span>&nbsp;&nbsp;</span>
                                                <div>
                                                   {currentLanguage === 'id' ? subItem?.judul_ppid_id : subItem?.judul_ppid_en}
                                                </div>

                                             </div>
                                          </li>
                                          <Collapse in={!!openIndex[path]}>
                                             <div id={`example-collapse-text-${path}`} style={{ marginLeft: 20 }} className=''>
                                                {subItem.child && subItem.child.map((nestedItem, nestedIndex) => {
                                                   const path = `${subIndex}-${nestedIndex}`;
                                                   return (
                                                      <>
                                                         <div
                                                            //  key={nestedIndex}
                                                            key={path}
                                                         >
                                                            <li
                                                               style={{ cursor: 'pointer', listStyle: 'none', borderBottom: '1px solid #d0d0d0' }}
                                                               onClick={() => handleClick(path, nestedItem)}
                                                            >
                                                               <div style={{ display: 'flex' }}  >
                                                                  <span>
                                                                     {nestedItem?.no_urut}.
                                                                  </span>
                                                                  <span>&nbsp;&nbsp;</span>
                                                                  <div>
                                                                     {currentLanguage === 'id' ? nestedItem?.judul_ppid_id : nestedItem?.judul_ppid_en}
                                                                  </div>

                                                               </div>
                                                            </li>
                                                         </div>

                                                         <Collapse in={true}>
                                                            <div id={`example-collapse-text-${path}`} style={{ marginLeft: 20 }} className=''>
                                                               {nestedItem.child && nestedItem.child.map((furtherItem, furtherIndex) => {
                                                                  const path = `${subIndex}-${nestedIndex}`;
                                                                  return (
                                                                     <div
                                                                        //  key={nestedIndex}
                                                                        key={path}

                                                                     >
                                                                        <li
                                                                           style={{ cursor: 'pointer', listStyle: 'none', borderBottom: '1px solid #d0d0d0' }}
                                                                           onClick={() => handleClick(path, furtherItem)}
                                                                        >
                                                                           <div
                                                                              style={{ display: 'flex' }}
                                                                           >
                                                                              <span>
                                                                                 {furtherItem?.no_urut}.
                                                                              </span>

                                                                              <span>&nbsp;&nbsp;</span>
                                                                              <div>
                                                                                 {currentLanguage === 'id' ? furtherItem?.judul_ppid_id : furtherItem?.judul_ppid_en}
                                                                              </div>

                                                                           </div>
                                                                        </li>
                                                                     </div>
                                                                  )
                                                               })}
                                                            </div>
                                                         </Collapse>
                                                      </>
                                                   )
                                                })}
                                             </div>
                                          </Collapse>
                                       </div>

                                    );
                                 })}
                              </ol>

                           </li>
                        </div>
                     ))}
                  </ol>
               </div>
            </div>

         </div >
      </>
   )
}
