import axios from 'axios';
import cookie from 'react-cookies';
import { clearCookie, getBasicToken, getCookie, removeDataUser, setAuthCookies, setCookie, setProfileCookies, setRefreshToken } from '../helper/cookies';
import { generatePKCE } from '../helper/generatePkce';

export const BASE_URL_RSE =
   process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_RSE_BASE_URL_API
      : process.env.REACT_APP_RSE_BASE_URL_API_DEV

export const SSO_API = process.env.REACT_APP_API_URL_SSO;
export const USERNAME_AUTH = process.env.REACT_APP_BASIC_AUTH_USERNAME
export const PASSWORD_AUTH = process.env.REACT_APP_BASIC_AUTH_PASSWORD

//SSO
export const CLIENT_ID  = process.env.REACT_APP_CLIENT_ID
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
export const CLIENT_APP_KEY = process.env.REACT_APP_CLIENT_API_KEY
export const INIT_REGIST = process.env.REACT_APP_INIT_REGIST_ACCESS_TOKEN

export const ID_USER = getCookie('account', 'app_account_bracer')
export const TOKEN_ACCESS = getCookie('auth', 'app_auth_observer_ward')
export const IS_LOGIN = getCookie('isLogin', 'app_auth_authenticated')
export const getWay = () => {
   return getCookie('auth', `app_auth_observer_ward`)
}
//END SSO   

const baseUrl = '.insw.go.id'

export const setLanguageCookie = (cookieValue) => {
    cookie.save('lang', cookieValue, { path: '/', domain: baseUrl, });
};

export const getLanguageCookie = () => {
    const cookies = cookie.load('lang');
    return cookies;
};

export const getLanguageStorageUpperCase = () => {
    const storage = 'ID';
    return storage;
}
export const getLanguageStorage = () => {
    const storage = localStorage.getItem('language');
    return storage;
}

export const getLanguageStorageUpperCaseKNFP = () => {
    const storage = localStorage.getItem('language').toUpperCase();
    return storage;
}

export const setLanguageStorage = (lang) => {
    const storage = localStorage.setItem('language', lang);
    return storage;
}

export default{
 //SSO STRORE
   sso: {
    authorizeApp() {
       const pkce = generatePKCE();
       setCookie('pkce', pkce, 32400)

       const params = new URLSearchParams({
          client_id: CLIENT_ID,
          response_type: 'code',
          scope: 'openid profile organization role roles_assigned notification_preferences integration_access',
          redirect_uri: window.location.origin,
          code_challenge_method: 'S256',
          code_challenge: pkce.code_challenge,
          state: pkce.state
        })

        window.location.href = `${SSO_API}/connect/authorize?${params}`
     },
    exchangeToken(payload) {
       const pkce = getCookie('pkce')
       const params = new URLSearchParams({
          code: payload.code,
          redirect_uri: window.location.origin,
          grant_type: 'authorization_code',
          code_verifier: pkce.code_verifier,
          state: pkce.state,
       })
       return new Promise((resolve, reject) => {
          axios
             .post( SSO_API + 'connect/token', params.toString(), {
                headers: {
                   'content-type': 'application/x-www-form-urlencoded',
                   Authorization : `Basic ${getBasicToken()}`
                },
             })
             .then(async (response) => {
                setAuthCookies(response.data)
                resolve(response.data)
                clearCookie('pkce')
             })
             .catch((error) => {
                reject(error.response)
             })
       })
    },
    removeSession() {
       removeDataUser()
       const redirectUrl = window.location.origin;
       const url = new URL( SSO_API + `connect/session/end?client_id=${CLIENT_ID}&attempt=ask&post_logout_redirect_uri=${redirectUrl}`);
       window.location.href = url
    },
    checkSession() {
       const accessToken = getCookie('auth', 'app_auth_observer_ward');
       return new Promise((resolve, reject) => {
          axios
             .get(
                SSO_API + 'api/v1/auth/check', 
                { 
                   headers: {
                      'Authorization':`Bearer ${accessToken}`,
                   },
                },
             )
             .then((response) => {
                setRefreshToken(response.data)
                resolve(response.data)
             })
             .catch((error) => {
                reject(error)
             })
       })
    }, 
    refreshToken() {
       const token_refresher = getCookie('isLogin', 'app_auth_refresher_orb');

       const params = new URLSearchParams({
          refresh_token: token_refresher,
          redirect_uri: window.location.origin,
          grant_type: 'refresh_token',
       })
       return new Promise((resolve, reject) => {
          axios
             .post(
                SSO_API + `connect/token`, params.toString(),
                { 
                   headers: {
                      'content-type': 'application/x-www-form-urlencoded',
                      Authorization : `Basic ${getBasicToken()}`
                   },
                },
             )
             .then((response) => {
                setRefreshToken(response.data)
                resolve(response.data)
             })
             .catch((error) => {
                reject(error)
             })
       })
    },
    getUserInfo(){
       return new Promise((resolve, reject) => {
          const accessToken = getCookie('auth', 'app_auth_observer_ward');
          axios
             .get(
                SSO_API + 'connect/userinfo', 
                {
                   headers: {
                      'Authorization':`Bearer ${accessToken}`,
                   },
                },
             )
             .then((response) => {
                setProfileCookies({
                   id_user: '', 
                   user_role: response.data.role.name,
                   name_role: response.data.role.name,
                   fullname: response.data.profile.details.full_name,
                   photo_profile: response.data.profile.details.photo_profile,
                });
                resolve(response.data)
             })
             .catch((error) => {
                reject(error)
             })
       })
    }, 
    verifyUserLogin(){
       return new Promise((resolve, reject) => {
          const accessToken = getCookie('auth', 'app_auth_observer_ward');
          axios
             .get(
                BASE_URL_RSE + 'cms/auth', 
                {
                   withCredentials: true, 
                   headers: {
                      'Authorization':`Bearer ${accessToken}`,
                   },
                },
             )
             .then((response) => {
                resolve(response.data)
             })
             .catch((error) => {
                reject(error)
             })
       })
    }, 
 }
}