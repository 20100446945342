import Swal from 'sweetalert2'
import { alertSuccessTextHTML, alertErrorTextHTML } from './utils'

export const alertSuccessCreateEnquiry = (ticketNumber, isBahasa = true) => {
   Swal.fire({
      title: isBahasa ? 'Berhasil' : 'Success',
      icon: 'success',
      html: isBahasa
         ? alertSuccessTextHTML(ticketNumber).id
         : alertSuccessTextHTML(ticketNumber).en,
      focusConfirm: true,
      showCancelButton: true,
      customClass: { confirmButton: 'sweetalert-ok' },
      cancelButtonText: isBahasa ? 'Tutup' : 'Close',
      confirmButtonText: isBahasa
         ? 'Salin nomor tiket'
         : 'Copy the ticket number',
      confirmButtonColor: '#02275d',
   }).then((res) => {
      if (res.isConfirmed) {
         // copy to clipboard
         navigator.clipboard.writeText(ticketNumber)

         Swal.fire({
            title: isBahasa
               ? 'Nomor tiket telah disalin!'
               : 'Ticket number has been copied!',
            confirmButtonText: isBahasa ? 'Tutup' : 'Close',
         }).then(() => window.location.reload())

         return
      }

      window.location.reload()
   })
}

export const alertErrorCreateEnquiry = (errorMessage, isBahasa = true) => {
   Swal.fire({
      title: isBahasa ? 'Gagal membuat tiket!' : 'Failed to create ticket!',
      icon: 'error',
      html: alertErrorTextHTML(errorMessage),
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: isBahasa ? 'Tutup' : 'Close',
   })
}
