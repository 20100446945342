import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../../constants/api';
import './index.scss';
import parse from 'html-react-parser';
import ReactPaginate from 'react-paginate';

const Tingkat1 = () => {

    const [peraturan, setPeraturan] = useState([]);
    const [peraturanSlice, setPeraturanSlice] = useState([]);
    const [pageCountPeraturan, setPageCountPeraturan] = useState('');
    const perPage = 4;

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        _get_data_ppid_tingkat1();
    }, []);

    const handlePageClicks = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;
        loadMoreDatas(offset, offset + perPage);
    };

    const loadMoreDatas = (of, op) => {
        const data = peraturan;
        const slice = data.slice(of, op);
        setPageCountPeraturan(data.length / perPage);
        setPeraturanSlice(slice);
    };

    const CallImage = (image_url) => {
        if (image_url !== null) {
            // var replace_url = image_url.substring(1);
            var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
            // console.log(url_image);
            return url_image;
        }
    }

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    const _get_data_ppid_tingkat1 = () => {
        const url = Api.PPID_TINGKAT1;
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    
                    let data_peraturan = response.data.data
                    setPeraturan(data_peraturan);
                    console.log('ujidata', data_peraturan[0]);

                    const slice = data_peraturan.slice(0, perPage);
                    setPeraturanSlice(slice);
                    console.log("debug 1", slice[0].file.slice(10));

                    setPageCountPeraturan(data_peraturan.length / perPage);

                } else {
                    setPeraturan([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setPeraturan([]);
            });
    }

    return (
        <>
            <div className="setiap-saat row m-0">
                <div className="col-md-12">
                    <div className="sm-content text-center">
                        <h3 className="sm-title">
                            Informasi Publik PPID Tingkat I LNSW
                        </h3>
                    </div>
                </div>
                <div className="text-left sm-content-list row m-0">
                    <div className="col-md-12 part-peraturan">
                        {/* <div className="titles p-2">Daftar Informasi Publik PPID Tingkat I LNSW</div> */}
                        <div className="row m-0">
                            {peraturanSlice.map((item, index) =>
                                <div className="col-md-6 p-2">
                                    <div className="card p-3">
                                        <div className="row m-0">
                                            <div className="content-title col-md-12">
                                                {parseHtml(item.header_id)}
                                            </div>
                                            <div className="content col-md-12">
                                                {parseHtml(item.ur_id)}
                                            </div>
                                        </div>
                                        <a role="button" rel="noopener noreferrer" className="btn btn-primary rounded-pill float-right" href={CallImage(item.file)} target="_blank">
                                            Unduh
                                    </a>
                                    </div>
                                </div>
                            )}
                        </div>
                        <ReactPaginate
                            previousLabel={'Sebelumnya'}
                            nextLabel={'Selanjutnya'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCountPeraturan}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClicks.bind(this)}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>

            </div>
        </>
    )
}

export default Tingkat1;
