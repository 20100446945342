import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../../constants/api';
import './video.scss';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from '../../../assets/icon/ic_calendar_gray_news.svg'
import { InputSearch } from '../../../components/atoms';
import { useTranslation } from 'react-i18next';
import ReactPlayer from "react-player"

const Tutorial = () => {
  const [data, setData] = useState([]);
  const [judul, setJudul] = useState([]);
  const [video, setVideo] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'id');
    }
    _get_data_video();
  }, []);

  const { t } = useTranslation();

  const CallImage = (image_url) => {
    // var replace_url = image_url.substring(1);
    var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    // console.log(url_image);
    return url_image;
  }

  const parseHtml = (html) => {
    // console.log('html');
    // console.log(html)
    var text_html = '';
    if (html !== undefined) {
      text_html = parse(html);
    }
    // var text_html = parse(html.toString());

    return text_html;
  }


  const _get_data_video = () => {
    const url = Api.PUSAT_BANTUAN_VIDEO + '&lang=' + localStorage.getItem('language').toUpperCase();
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        if (response.data.code === '01') {
          setVideo(response.data.data);
        } else {
          setVideo([]);
        }
      })
      .catch(error => {
        setVideo([]);
      });
  }

  const CallVideo = (image_url) => {
    var url_image = '';
    if (image_url !== undefined) {
      url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    }
    return url_image + '#t=0.5';
  }

  const searchData = (e) => {
    if (e.length < 2) {
      _get_data_video();
    } else {
      const url = Api.PUSAT_BANTUAN_SEARCH + '?lang=' + localStorage.getItem('language').toUpperCase() + '&keyword=' + e;
      axios({
        method: 'get',
        url: url,
        headers: { 'Authorization': Api.BASIC_AUTH },
        timeout: 1000 * 10, // Wait for 10 seconds
      })
        .then(response => {
          if (response.data.code === '01') {
            try {
              var faq_new_list = [];
              let data_res = response.data.data;
              for (let i = 0; i < data_res.length; i++) {
                if (data_res[i].kategori === 'video') {
                  faq_new_list.push(data_res[i]);
                }
              }
              setVideo(faq_new_list);
            } catch {
              setVideo([]);
            }
          } else {
            setVideo([]);
          }
        })
        .catch(error => {
          setVideo([]);
        });
    }
  }

  return (
    <>
      <div className="pusat-bantuan-video row m-0">
        <div className="col-md-12">

          <div className="pb-content">
            <h3 className="pb-title">
              {t('pusat-bantuan-tutor')}
            </h3>
            <div className="row m-0 justify-content-center">
              <p className="col-md-6">
                {t('pusat-bantuan-tutor-sub')}
              </p>
            </div>
            <div className="row m-0 justify-content-center">
              <p className="col-md-6 search-bar">
                <InputSearch placeholder={t('find_something')}
                  onChange={e => searchData(e.target.value)} onClick={(e) => searchData(e)}
                />
              </p>
            </div>

          </div>
        </div>

        <div className="pb-content-list-video row m-0 mb-5">
          {
            video.length > 0 ?
              <div className="row m-0">
                {video.map((item, index) =>
                  <div className="container-card-video" key={index}>
                    <div className="card">
                      <div className="video">
                        <ReactPlayer url={item.url} width="100%" height="200px" controls={true} />
                      </div>
                      <div className="title-data">
                        {parseHtml(item.header)}
                      </div>
                      <div className="titles align-items-center">
                        <p>
                          <CalendarIcon className="mr-2" />
                          {
                            item.ur.length > 60 ?
                              (
                                <span>
                                  {parseHtml(item.ur).substring(0, 60) + '...'}
                                </span>
                              ) : (
                                <span>
                                  {parseHtml(item.ur)}
                                </span>
                              )
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div> :
              (
                <div className="row m-0">
                  <div className="col">
                    DATA PENCARIAN TIDAK DITEMUKAN
                  </div>
                </div>
              )
          }
        </div>

      </div>
    </>
  )
}

export default Tutorial;
