import React, { useState, useEffect, useHistory, useRef } from 'react'
import './detail_korelasiHS.scss'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InputSearch } from '../../../../components/atoms'
import axios from 'axios'
import * as Api from '../../../../constants/api'
import {
   ListEmpty,
   LoadProgress,
   ListFirst,
   CardNtpn,
} from '../../../../components'

const DetailLhv = () => {
   const location = useLocation()
   var route_data = ''
   if (location.state === undefined) {
      route_data = ''
   } else {
      route_data = location.state.data
   }
   const [data_kodeHS, setDataLhv] = useState(route_data)

   const { t } = useTranslation()
   const [kodeHS, setKodeHS] = useState()
   const [is_progress, setIsProgress] = useState(false)
   const [is_progress2, setIsProgress2] = useState(false)
   const [_dataKorelasiHS, set_DataKorelasiHS] = useState([])
   const [error_message, setErrorMessage] = useState('')
   const [dataKiri, setDataKiri] = useState('')

   const searchKodeHs = (data) => {
      setIsProgress(true)
      setIsProgress2(true)
      let getData = data.kode_hs
      if (!getData) {
         getData = data
      }

      axios
         .get(`${Api.GET_KODE_HS}${getData}`, {
            headers: {
               Authorization: Api.BASIC_AUTH,
            },
            timeout: 1000 * 10,
         })
         .then((response) => {
            setIsProgress(false)
            set_DataKorelasiHS(response.data.data)
         })
         .catch((error) => {
            setIsProgress(false)
            set_DataKorelasiHS([])
            // set_DataKorelasiHS([
            //    {
            //       KorelasiHS: error.response.data.KorelasiHS,
            //       status: 404,
            //       message: 'Nomor KorelasiHS Tidak Valid',
            //       valid: false,
            //    },
            // ])
         })
   }

   //did mount
   useEffect(() => {
      searchKodeHs(data_kodeHS)
   }, [])

   const see_detail = (item, index) => {
      setIsProgress2(true)
      setDataKiri(item)
   }

   return (
      <div className="detail-korelasi-container">
         <div className="penelusuran-content-title">
            <div className="penelusuran-content-sub-title">
               <div className="penelusuran-text-title">{t('track_kodeHS')}</div>
               <div className="penelusuran-input-search row justify-content-center m-0 mt-3">
                  <div className="col-md-5 mb-2">
                     <InputSearch
                        placeholder={t('enter_kode2017')}
                        onChange={(e) => setKodeHS(e.target.value)}
                        onKeyDown={() => searchKodeHs(kodeHS)}
                        onClick={() => searchKodeHs(kodeHS)}
                        value={kodeHS}
                     />
                  </div>
               </div>
            </div>
         </div>
         <div className="detail-pibpeb-content">
            {is_progress === true ? (
               <div style={{ height: '25vw' }} className="detail-content-load">
                  <LoadProgress />
               </div>
            ) : _dataKorelasiHS.length === 0 ? (
               <div className="detail-content-load">
                  <ListEmpty message_error={error_message} />
               </div>
            ) : (
               <>
                  <div className="detail-kanan">
                     <div className="detail-atas">
                        <div className="detail-title">
                           <p className="detail-title-text">
                              {t('detail-korelasiHS')}
                           </p>
                        </div>
                     </div>
                     <div className="scroll-on">
                        {is_progress2 === false ? (
                           <ListFirst
                              message_error={t('atr_me_title')}
                              message_error2={t('atr_me_subtitle')}
                           />
                        ) : (
                           <>
                              {_dataKorelasiHS.map((item, index) => (
                                 <>
                                    <div className="detail-bawah">
                                       <div className="detail-container">
                                          <p className="detail-judul">
                                             Kode HS
                                          </p>
                                          <p className="detail-hasil">
                                             {item.hsCodeAfter === ''
                                                ? '-'
                                                : item.hsCodeAfter}
                                          </p>
                                       </div>
                                       {/* <div className="detail-container-last">
                                          <p className="detail-judul">Bab dan Bagian</p>
                                          <p className="detail-hasil">
                                             {item.treeId}
                                          </p>
                                       </div>
                                       <div className="detail-container-last">
                                          <p className="detail-judul">Chapter and section</p>
                                          <p className="detail-hasil-english">
                                             {item.treeEn}
                                          </p>
                                       </div> */}
                                       <div className="detail-container-last">
                                          <p className="detail-judul">
                                             Uraian Barang
                                          </p>
                                          <p className="detail-hasil">
                                             {item.uraianId}
                                          </p>
                                       </div>
                                       <div className="detail-container-uraian-en">
                                          <p className="detail-judul">
                                             Description of good
                                          </p>
                                          <p className="detail-hasil-uraian-en">
                                             {item.uraianEn}
                                          </p>
                                       </div>
                                    </div>
                                 </>
                              ))}
                           </>
                        )}
                     </div>
                  </div>
               </>
            )}
         </div>
      </div>
   )
}

export default DetailLhv
