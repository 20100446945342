import React from 'react'
import './cardprofil.scss'

const CardProfil = ({ gambar, judul, deskripsi }) => {
   return (
      <div className="card-item-custom">
         <img src={gambar} alt={judul} className="gambar-profil" />
         <div className="content-titles">
            <p className="title">{judul}</p>
         </div>
         <p className="sub-title">{deskripsi}</p>
      </div>
   )
}

export default CardProfil
