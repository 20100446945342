import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import './breadcrumbs.scss'
import { useTranslation } from 'react-i18next'
import { useLastLocation } from 'react-router-last-location'

const BreadCrumbs = (props) => {
   const {
      history,
      location: { pathname },
   } = props
   const lastLocation = useLastLocation()
   const pathnames = pathname.split('/').filter((x) => x)
   const { t } = useTranslation()
   if (
      pathnames.includes('detail-berita') ||
      pathnames.includes('detail-press-release') ||
      pathnames.includes('detail-faq') ||
      pathnames.includes('detail')
   ) {
      pathnames.pop()
      // } else if (history.length === 2) {
      //     if (pathnames.length > 1) {
      //         for (let i = 0; i < pathnames.length; i++) {
      //             if (i !== pathname.length -1) {
      //                 pathnames.splice(i, 1);
      //             }
      //         }
      //     }
   } else if (lastLocation === null) {
      if (pathnames.length > 1) {
         for (let i = 0; i < pathnames.length; i++) {
            if (i !== pathnames.length - 1) {
               pathnames.splice(i, 1)
            }
         }
      }
      // } else {
      //     console.log('last_location');
      //     console.log(lastLocation);
      //     if (lastLocation !== null) {
      //         let path_last = lastLocation.pathname;
      //         let path_last_location = path_last.split('/').filter(x => x);
      //         let last_path = '';
      //         if (path_last_location.length > 1) {
      //             for (let i = 0; i < path_last_location.length; i++) {
      //                 if (i === path_last_location.length -1) {
      //                     last_path = path_last_location[i];
      //                 }
      //             }
      //         } else {
      //             last_path = path_last_location[0];
      //         }
      //         console.log(last_path);
      //         if (pathnames.length > 1) {
      //             console.log(last_path + ' vs ' + pathnames[pathnames.length -2]);
      //             if (pathnames[pathnames.length -2] !== last_path) {
      //                 for (let i = 0; i < pathnames.length; i++) {
      //                     if (i !== pathname.length -1) {
      //                         pathnames.splice(i, 1);
      //                     }
      //                 }
      //             }
      //         }
      //     } else {
      //         for (let i = 0; i < pathnames.length; i++) {
      //             if (i !== pathname.length -1) {
      //                 pathnames.splice(i, 1);
      //             }
      //         }
      // }
   }

   const Nama = (name) => {
      // return (name.charAt(0).toUpperCase() + name.slice(1)).replace('-', ' ');
      // let name_route = name.split('-').filter(x => x);
      // var names = "";
      // for (var i = 0; i < name_route.length; i++) {
      //     if (i == 0) {
      //         names = name_route[i].charAt(0).toUpperCase() + name_route[i].slice(1);
      //     } else {
      //         names = names + ' ' + name_route[i].charAt(0).toUpperCase() + name_route[i].slice(1);
      //     }
      // }
      return t(name)
   }
   console.log(pathname, 'pathname')
   return pathname !== '/' ? (
      <div className="bc-container">
         <Link onClick={() => history.push('/')} className="bc-links">
            {t('home')}
         </Link>
         {pathnames.map((name, index) => {
            console.log(name, index, 'pathname')
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
            const isLast = index === pathnames.length - 1
            return isLast ? (
               // <Link className="bc-links-active">{' > '}{Nama (name)}</Link>
               <div style={{ marginLeft: 5 }} className="bc-links-active">
                  {' > '}
                  {Nama(name)}
               </div>
            ) : (
               // <Link
               //     style={{marginLeft: 5}}
               //     onClick={() => {
               //         history.go(index - (pathnames.length-1));
               //     }}
               //     className="bc-links">
               //         {' > '}{Nama (name)}
               // </Link>
               <div
                  style={{ marginLeft: 5, cursor: 'pointer' }}
                  onClick={() => {
                     history.go(index - (pathnames.length - 1))
                  }}
                  className="bc-links"
               >
                  {' > '}
                  {Nama(name)}
               </div>
            )
         })}
      </div>
   ) : null
}

export default withRouter(BreadCrumbs)
