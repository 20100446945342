import React, { useEffect, useState } from "react";
import {
  ICAdminRulings,
  ICIntrAseanTrade,
  ICNationalTrade,
  ICListAuth,
} from "../../../assets";

import "./carousel_content.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardIntr from "../CardIntr";
import { useHistory } from "react-router-dom";

const itemsID = [
  {
    data: [
      {
        image: ICNationalTrade,
        title: "Tentang KNFP",
        message: "Penjelasan terkait Komite Nasional Fasilitasi Perdagangan",
        path: "/knfp/tentang",
      },
      {
        image: ICAdminRulings,
        title: "Fasilitas Repository",
        message: "Repository terkait Komite Nasional Fasilitasi Perdagangan",
        path: "/knfp/facility-repository",
      },
      {
        image: ICIntrAseanTrade,
        title: "Berita",
        message:
          "Berita terkait aktivitas Komite Nasional Fasilitasi Perdagangan",
        path: "/knfp/berita",
      },
      {
        image: ICListAuth,
        title: "Kontak dan Enquiry",
        message:
          "Saluran komunikasi untuk pertanyaan terkait Fasilitas Perdagangan",
        path: "/knfp/enquiry",
      },
    ],
  },
];

const itemsEN = [
  {
    data: [
      {
        image: ICNationalTrade,
        title: "About KNFP",
        message:
          "Explanation regarding the National Trade Facilitation Committee",
        path: "/knfp/tentang",
      },
      {
        image: ICAdminRulings,
        title: "Facility Repository",
        message:
          "Facility Repository regarding the National Trade Facilitation Committee",
        path: "/knfp/facility-repository",
      },
      {
        image: ICIntrAseanTrade,
        title: "News Update",
        message:
          "News Update regarding the National Trade Facilitation Committee",
        path: "/knfp/berita",
      },
      {
        image: ICListAuth,
        title: "Contact Point & Enquiry",
        message:
          "Communication channel for inquiries regarding the Trade Facility",
        path: "/knfp/enquiry",
      },
    ],
  },
];

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 1,
    partialVisibilityGutter: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 1,
  },
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li
      className={active ? "active-caro" : "inactive-caro"}
      onClick={() => onClick()}
    ></li>
  );
};

const CarouselContentKNFP = (props) => {
  const [list_partial, setListPartial] = useState([]);

  const history = useHistory();
  const width = window.innerWidth;

  // did mount
  useEffect(() => {
    if (localStorage.getItem("language") === "id") {
      dataPartial(itemsID[0].data);
    } else {
      dataPartial(itemsEN[0].data);
    }
  }, [localStorage.getItem("language")]);

  const dataPartial = (item) => {
    let content_partial = [];
    let content = [];
    var count = 1;
    var jmlData = 4;
    if (width < 600) {
      jmlData = 1;
    }

    for (let i = 0; i < item.length; i++) {
      // console.log(count);
      content_partial.push(item[i]);
      // console.log(item[i].title);
      if (count === jmlData) {
        let data = {
          data: content_partial,
        };
        content_partial = [];
        content.push(data);
        count = 1;
      } else {
        // console.log(item.length - i);
        if (item.length - i === 1) {
          let data = {
            data: content_partial,
          };
          content_partial = [];
          content.push(data);
          count = count + 1;
        } else {
          count = count + 1;
        }
      }
    }
    // console.log('content intr');
    // console.log(content);

    setListPartial(content);
  };

  const ClickCardIntr = (item) => {
    // console.log('click react intr')
    if (item === "/intr/peraturan") {
      history.push(item, { search_peraturan: "" });
    } else {
      history.push(item);
    }
  };

  const slides = list_partial.map((item) => {
    return (
      <div className="custom-tag-intr-item">
        <div className="intr-sub-menu">
          {item.data.map((item_data) => {
            return (
              <CardIntr
                image={item_data.image}
                title={item_data.title}
                message={item_data.message}
                onClick={() => ClickCardIntr(item_data.path)}
              />
            );
          })}
        </div>
      </div>
    );
  });

  return (
    <div className="custom-tag-intr">
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        centerMode={false}
        customDot={<CustomDot />}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        containerClass={{ width: "100%" }}
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {slides}
      </Carousel>
    </div>
  );
};

export default CarouselContentKNFP;
