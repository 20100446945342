import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../../constants/api';
import './profilPPID.scss';
import parse from 'html-react-parser';

const ProfilPPID = () => {

    //variable constant
    const [detail_kontak_data, setKontakData] = useState([]);
    const [detail_profil_data, setProfilData] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        _get_data_kontak();
        _get_data_detail();
    }, []);

    const CallImage = (image_url) => {
        // var replace_url = image_url.substring(1);
        var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        // console.log(url_image);
        return url_image;
    }

    const parseHtml = (html) => {
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }

        return text_html;
    }

    const _get_data_kontak = () => {
        const url = Api.DETAIL_KONTAK_PPID + '?lang=' + localStorage.getItem('language').toUpperCase();
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    setKontakData(response.data.data);
                } else {
                    setKontakData([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setKontakData([]);
            });
    }

    const _get_data_detail = () => {
        const url = Api.PROFIL_PPID + '?lang=' + localStorage.getItem('language').toUpperCase();
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    setProfilData(response.data.data);
                } else {
                    setProfilData([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setProfilData([]);
            });
    }


    return (
        <>
            {detail_profil_data.map((item, index) =>
                item.id_profil_ppid === 1 ?
                <div className="profil-ppid row m-0 bg-primary" key={index}>
                    <div style={{flex: 1}}>
                        <div className="profil-ppid-content">
                            <h3 className="profil-ppid-title">
                                {parseHtml(item.header)}
                            </h3>
                            <p className="profil-ppid-desc">
                                {parseHtml(item.ur)}
                            </p>
                        </div>
                    </div>
                    <div style={{flex: 1}}>
                        <div className="border-image">
                            <img
                                className="profil-ppid-image"
                                src={CallImage(item.nama_img)} alt={`${index}`}
                            />
                        </div>
                    </div>

                </div>
                :
                null
            )}

            <div className="row justify-content-center profil-ppid-contact-container">
                {detail_kontak_data.map((item, index) =>
                    <div className="profil-ppid-contact col-md-3 col-sm-6" key={index}>
                        <div className="profil-contact row m-0">
                            <img className="profil-contact-img col-md-3 col-xs-12 p-1" src={CallImage(item.nama_img)} alt={`${index}`} />
                            <div className="col-md-9 col-xs-12 p-1">
                                <h6 className="profil-contact-title">
                                    {parseHtml(item.header)}
                                </h6>
                                <p className="profil-contact-desc">
                                    {parseHtml(item.ur)}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default ProfilPPID;
