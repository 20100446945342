import React, { useState, useEffect } from 'react'
import axios from 'axios'
import * as Api from '../../../constants/api'
import './index.scss'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import { InputSearch } from '../../../components/atoms'
import { useTranslation } from 'react-i18next'

const Panduan = () => {
   const [data, setData] = useState([])
   const [panduan, setPanduan] = useState([])

   useEffect(() => {
      if (localStorage.getItem('language') === null) {
         localStorage.setItem('language', 'id')
      }
      _get_data_panduan()
   }, [])

   const { t } = useTranslation()

   const CallImage = (image_url) => {
      // var replace_url = image_url.substring(1);
      var url_image = Api.IP_KONG_LNSW + image_url.slice(1)
      // console.log(url_image);
      return url_image
   }

   const parseHtml = (html) => {
      // console.log('html');
      // console.log(html)
      var text_html = ''
      if (html !== undefined) {
         text_html = parse(html)
      }
      // var text_html = parse(html.toString());

      return text_html
   }

   const _get_data_panduan = () => {
      const url =
         Api.PUSAT_BANTUAN_PANDUAN +
         '&lang=' +
         localStorage.getItem('language').toUpperCase()
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            if (response.data.code === '01') {
               setPanduan(response.data.data)
            } else {
               setPanduan([])
            }
         })
         .catch((error) => {
            setPanduan([])
         })
   }

   const CallVideo = (image_url) => {
      var url_image = ''
      if (image_url !== undefined) {
         url_image = Api.IP_KONG_LNSW + image_url.slice(1)
      }
      return url_image + '#t=0.5'
   }

   const searchData = (e) => {
      if (e.length < 2) {
         _get_data_panduan()
      } else {
         const url =
            Api.PUSAT_BANTUAN_SEARCH +
            '?lang=' +
            localStorage.getItem('language').toUpperCase() +
            '&keyword=' +
            e
         axios({
            method: 'get',
            url: url,
            headers: { Authorization: Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
         })
            .then((response) => {
               if (response.data.code === '01') {
                  try {
                     var faq_new_list = []
                     let data_res = response.data.data
                     for (let i = 0; i < data_res.length; i++) {
                        if (data_res[i].kategori === 'panduan') {
                           faq_new_list.push(data_res[i])
                        }
                     }
                     setPanduan(faq_new_list)
                  } catch {
                     setPanduan([])
                  }
               } else {
                  setPanduan([])
               }
            })
            .catch((error) => {
               setPanduan([])
            })
      }
   }

   return (
      <>
         <div className="pusat-bantuan-panduan-list-all row m-0">
            <div className="col-md-12">
               <div className="pb-content">
                  <h3 className="pb-title">{t('pusat-bantuan-panduan')}</h3>
                  <div className="row m-0 justify-content-center">
                     <p className="col-md-6">
                        {t('pusat-bantuan-panduan-sub')}
                     </p>
                  </div>
                  <div className="row m-0 justify-content-center">
                     <p className="col-md-6 search-bar">
                        <InputSearch
                           placeholder={t('find_something')}
                           onChange={(e) => searchData(e.target.value)}
                           onClick={(e) => searchData(e)}
                        />
                     </p>
                  </div>
               </div>
            </div>

            <div className="pb-content-list-faq row m-0">
               {panduan.length > 0 ? (
                  <div className="row m-0">
                     {panduan.map((item, index) => (
                        <div className="col-md-3" key={index}>
                           <div className="card">
                              <div className="titles">
                                 {/* {parseHtml(item.header)} */}
                                 {item.header.length > 40 ? (
                                    <div>
                                       {parseHtml(
                                          item.header.substring(0, 40),
                                       ) + '...'}
                                    </div>
                                 ) : (
                                    <div>{parseHtml(item.header)}</div>
                                 )}
                              </div>
                              <div className="content">
                                 {item.ur.length > 100 ? (
                                    <div>
                                       {parseHtml(item.ur.substring(0, 100)) +
                                          '...'}
                                    </div>
                                 ) : (
                                    <div>{parseHtml(item.ur)}</div>
                                 )}
                              </div>
                              <div className="row m-0 align-items-center subs">
                                 <div className="col p-0 sub-sub-title">
                                    {/* Panduan */}
                                 </div>
                                 <div className="col p-0">
                                    <Link
                                       to={{
                                          pathname:
                                             '/pusat-bantuan/panduan/detail',
                                          state: item,
                                       }}
                                    >
                                       <button className="btn btn-primary rounded-pill">
                                          Lihat
                                       </button>
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     ))}
                  </div>
               ) : (
                  <div className="row m-0">
                     <div className="col">DATA PENCARIAN TIDAK DITEMUKAN</div>
                  </div>
               )}
            </div>
         </div>
      </>
   )
}

export default Panduan
