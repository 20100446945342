import React from 'react';
import './CardPengumuman.scss';
import * as Api from '../../../constants/api';
import parse from 'html-react-parser';

const CardPengumuman = ({ judul, gambar, link, desc }) => {
	const open_new_tab = (item) => {
		if (item !== null && item !== '') {
			// var imageUrl = Api.IP_KONG_LNSW + item.slice(1);
			console.log(item);
			// const win = window.open(imageUrl, '_blank');
			const win = window.open(item, '_blank'); //to open new page
			if (win != null) {
				win.focus();
			}
		}
	};

	const parseHtml = (html) => {
		// console.log('html');
		// console.log(html)
		var text_html = '';
		if (html !== undefined) {
			text_html = parse(html);
		}
		// var text_html = parse(html.toString());

		return text_html;
	};

	return (
		<div className='card-pengu-container'>
			<p className='card-pengu-judul'>{judul}</p>
			{gambar !== null ? (
				<img
					className='card-pengu-image'
					src={gambar}
					alt='pengumuman'
					style={
						link !== null && link !== ''
							? { cursor: 'pointer' }
							: { cursor: 'default' }
					}
					onClick={() => open_new_tab(link)}
				/>
			) : (
				<div style={{ overflowY: 'scroll' }}>
					<p className='card-pengu-desc'>{parseHtml(desc)}</p>
				</div>
			)}
			{/* <img
				className='card-pengu-image'
				src={gambar}
				alt='pengumuman'
				onClick={() => open_new_tab(link)}
			/>
			<p className='card-pengu-desc'>{desc}</p> */}
		</div>
	);
};

export default CardPengumuman;
