import React, { useState, useEffect } from 'react';
import './sejarah.scss';
import { useTranslation } from 'react-i18next';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from 'html-react-parser';

import * as Api from '../../../constants/api';
import { LoadProgress, ListEmpty, HorizontalHistory } from '../../../components';

const StrukturOrganisasi = () => {

  // variable constant
  const { t } = useTranslation();
  const [is_progress, setisProgress] = useState(true);
  const [list_data_sejarah, setListDataSejarah] = useState([]);
  const [error_message, setErrorMessage] = useState('');
  const [is_progress_cover, setisProgressCover] = useState(true);
  const [data_cover_sejarah, setCoverDataSejarah] = useState({});
  const [judul, setJudul] = useState([]);
  const [error_message_cover, setErrorMessageCover] = useState('');

  // did mount
  useEffect(() => {
    _get_data_judul();
    _get_data_sejarah();
  }, []);

  //function
  const parseHtml = (html) => {
    // console.log('html');
    // console.log(html)
    var text_html = '';
    if (html !== undefined) {
      text_html = parse(html);
    }
    // var text_html = parse(html.toString());

    return text_html;
  }

  const _get_data_judul = () => {
    let idMenu = localStorage.getItem('menu_select')
      if (idMenu === null || idMenu !== 3) {
         idMenu = 3
      }
    const url = Api.JUDUL + idMenu + '&lang=' + localStorage.getItem('language').toUpperCase();
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        if (response.data.code === '01') {
          setJudul(response.data.data);
        } else {
          setJudul([]);
        }
      })
      .catch(error => {
        setJudul([]);
      });
  }

  const _get_data_sejarah = () => {
    setisProgress(true);
    const url = Api.GET_LIST_SEJARAH + 'lang=' + localStorage.getItem('language').toUpperCase();
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        setisProgress(false);
        if (response.data.code === '01') {
          if (response.data.data.length === 0) {
            setListDataSejarah([]);
            setErrorMessage(t('empty_list'));
          } else {
            // console.log(response.data);
            let data_list_peraturan = response.data.data;
            setListDataSejarah(data_list_peraturan);
          }

        } else {
          setListDataSejarah([]);
          setErrorMessage(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgress(false);
        setListDataSejarah([]);
        setErrorMessage(t('error_message'));
      });
  }

  const CallImage = (image_url) => {
    var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    // console.log('url_image');
    // console.log(url_image);
    return url_image;
  }

  return (
    <div className="sejarah-content">
      {judul.map((item, index) =>
        <div className="sejarah-organisasi-background col-md-12 p-0">
          <div className="sejarah-organisasi-background-part">
            <img src={CallImage(item.nama_img)} alt="bg" />
          </div>
          <div className="sejarah-organisasi-content-title">
            <div className="sejarah-organisasi-title">
              {parseHtml(item.header)}
            </div>
            <div className="sejarah-organisasi-subtitle">
              {parseHtml(item.ur)}
            </div>
          </div>
        </div>
      )}

      <div className="horizontal-history-container">
        {is_progress === true ?
          <div style={{ height: '25vw' }} className="search-content-load">
            <LoadProgress />
          </div>
          :
          list_data_sejarah.length === 0 ?
            <div className="search-content-load">
              <ListEmpty message_error={error_message} />
            </div>
            :
            <HorizontalHistory data={list_data_sejarah} />
        }
      </div>
    </div>
  )
}

export default StrukturOrganisasi;
