import React, { useState, useEffect, useHistory, useRef } from 'react'
import './detail_pibpeb.scss'
import { useLocation } from 'react-router-dom'
import Select from 'react-dropdown-select'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { InputSearch } from '../../../../components/atoms'
import axios from 'axios'
import * as Api from '../../../../constants/api'
import {
   ListEmpty,
   ListEmptyDetail,
   LoadProgress,
   TimeLine,
   TimeLinePibPeb,
} from '../../../../components'
import moment from 'moment'

const DetailPibPeb = () => {
   const location = useLocation()
   // const data_pibpeb = location.state.data;
   var route_data = ''
   if (location.state === undefined) {
      route_data = ''
   } else {
      route_data = location.state.data
   }
   const [data_pibpeb, setDataPibPebAsal] = useState(route_data)

   const { t } = useTranslation()
   const [pibpeb, SetPibPeb] = useState('')
   const [is_progress, setisProgress] = useState(false)
   const [is_progressKanan, setisProgressKanan] = useState(false)
   const [dataDetailPibPeb, SetDataDetailPibPeb] = useState([])
   const [dataPibPeb, SetDataPibPeb] = useState([])
   const [error_message, setErrorMessage] = useState('')
   const [error_messageKanan, setErrorMessageKanan] = useState(
      t('first_detail'),
   )

   //    console.log('dataPIB', dataPibPeb)

   const [jenis, setJenis] = useState('')

   // Detail State
   const [no_detail, setNoDetail] = useState('')
   const [detail, setDetail] = useState('Detail')
   const [detail_desc, setDetailDesc] = useState(t('first_detail'))

   //Select Referensi
   const [selected_ref, setSelectedRef] = useState({
      label: 'PIB',
      value: 'pib',
   })
   const [selected_ref_label, setSelectedRefLabel] = useState('PIB')
   const [list_select_ref, setListSelectRef] = useState([
      {
         label: 'PIB',
         value: 'pib',
      },
      {
         label: 'PEB',
         value: 'peb',
      },
   ])
   const [place_holder_ref, setPlaceHolderRef] = useState(
      'penelusuran-dropdown-remove-placeholder',
   )

   //style dropdown
   const StyledItem = styled.div`
      color: #555;
      padding: 10px;
      border-radius: 3px;
      margin: 3px;
      cursor: pointer;
      > div {
         display: flex;
         align-items: center;
      }
      :hover {
         background: #f2f2f2;
      }
   `

   const searchPibPeb = (data) => {
      // console.log(data);
      // console.log('jenis_detail');
      // console.log(data.jenis);
      // console.log('pibpeb_detail');
      // console.log(data.nomor);

      setisProgress(true)
      SetDataDetailPibPeb([])
      setDetail('Detail')
      setNoDetail('')

      let jenisData = data.jenis
      setJenis(data.jenis)
      let nomorData = data.nomor

      // let url = Api.TRACKING_DETAIL + 'pib?dokumen=' + jenisData + '&no_pengajuan=' + nomorData; //mobile api
      let url = Api.IP_LNSW_CMS + jenisData + '?no_pengajuan=' + nomorData //web api
      // console.log(url);

      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgress(false)
            if (response.data.code === '01') {
               if (response.data.data.length === 0) {
                  SetDataPibPeb([])
                  setErrorMessage(t('empty_list'))
                  setDetail('Detail')
                  setNoDetail('')
                  setDetailDesc('Belum ada detail yang ditampilkan')
               } else {
                  // console.log('length !== 0');
                  // console.log(response.data.data);
                  let data_list = response.data.data
                  SetDataPibPeb(data_list)
               }
            } else {
               // console.log('code 02');
               // console.log(response.data.code);
               SetDataPibPeb([])
               setErrorMessage(t('empty_list'))
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgress(false)
            SetDataPibPeb([])
            setErrorMessage(t('error_message'))
         })
   }

   //did mount
   useEffect(() => {
      // console.log('data_pibpeb_detail_didmount');
      // console.log(data_pibpeb);

      searchPibPeb(data_pibpeb)
   }, [])

   const see_detail = (item, index) => {
      // console.log(item.remarks);
      // if (item.ur_respon === 'BC - REJECT' || item.ur_respon === 'NSW - REJECT') {
      //     setDetail(t('decline_detail'));
      //     setNoDetail(' : ' + item.ur_respon);
      //     setDetailDesc(t('decline_detail_desc'));
      // } else {
      //     setDetail('Detail');
      //     setNoDetail(' : ' + item.ur_respon);
      //     setDetailDesc(t('done'));
      // }

      setisProgressKanan(true)

      let noDetail = item.ur_respon
      setDetail('Detail')
      setNoDetail(noDetail)
      let seqres = item.seqres
      let seq = item.seq
      let url = ''

      if (jenis === 'peb') {
         url = Api.IP_LNSW_CMS + 'detail-peb/seqres?seqres=' + seqres //web api
         // console.log(url);
      } else {
         url = Api.IP_LNSW_CMS + 'detail-pib/seq?seq=' + seq //web api
         // console.log(url);
      }

      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgressKanan(false)
            if (response.data.code === '01') {
               if (response.data.data.length === 0) {
                  SetDataDetailPibPeb([])
                  setErrorMessageKanan(t('empty_list'))
               } else {
                  // console.log('length !== 0');
                  // console.log(response.data.data);
                  let data_list = response.data.data
                  SetDataDetailPibPeb(data_list)
               }
            } else {
               // console.log('code 02');
               // console.log(response.data.code);
               SetDataDetailPibPeb([])
               setErrorMessageKanan(t('data_not_found'))
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgressKanan(false)
            SetDataDetailPibPeb([])
            setErrorMessageKanan(t('error_message'))
         })
   }

   return (
      <div className="detail-pibpeb-container">
         <div className="penelusuran-content-title">
            <div className="penelusuran-content-sub-title">
               <div className="penelusuran-text-title">{t('track_pibpeb')}</div>
               <div className="penelusuran-input-search row m-0 mt-3 justify-content-center">
                  <div className="col-md-2 mb-2">
                     <Select
                        multi={false}
                        searchable={false}
                        options={list_select_ref}
                        values={[]}
                        style={{
                           borderColor: '#808080',
                           background: '#FFFFFF',
                           borderRadius: '30px',
                        }}
                        className={place_holder_ref}
                        onChange={(value) => {
                           // console.log(value);
                           setSelectedRef(value[0])
                           setSelectedRefLabel(value[0].label)
                           setPlaceHolderRef(
                              'penelusuran-dropdown-remove-placeholder',
                           )
                        }}
                        contentRenderer={({ props, state }) => (
                           <div>
                              {selected_ref_label === t('choose_cif_fob') ? (
                                 <div
                                    style={{
                                       cursor: 'pointer',
                                       display: 'flex',
                                    }}
                                 >
                                    <div className="penelusuran-dropdown-placeholder">
                                       {selected_ref_label}
                                    </div>
                                 </div>
                              ) : (
                                 <div
                                    style={{
                                       cursor: 'pointer',
                                       display: 'flex',
                                    }}
                                 >
                                    <div className="penelusuran-input-dropdown">
                                       {selected_ref_label}
                                    </div>
                                 </div>
                              )}
                           </div>
                        )}
                        itemRenderer={({ item, methods }) => (
                           <StyledItem>
                              <div onClick={() => methods.addItem(item)}>
                                 <div className="penelusuran-dropdown-item">
                                    {item.label}
                                 </div>
                              </div>
                           </StyledItem>
                        )}
                     />
                  </div>
                  <div className="col-md-6 mb-2">
                     <InputSearch
                        placeholder={t('look_the_pib_peb')}
                        onChange={(e) => SetPibPeb(e.target.value)}
                        onKeyDown={() =>
                           searchPibPeb({
                              jenis: selected_ref.value,
                              nomor: pibpeb,
                           })
                        }
                        onClick={() =>
                           searchPibPeb({
                              jenis: selected_ref.value,
                              nomor: pibpeb,
                           })
                        }
                     />
                  </div>
               </div>
            </div>
         </div>
         <div className="detail-pibpeb-content">
            {is_progress === true ? (
               <div style={{ height: '25vw' }} className="detail-content-load">
                  <LoadProgress />
               </div>
            ) : dataPibPeb.length === 0 ? (
               <div className="detail-content-load">
                  <ListEmpty message_error={error_message} />
               </div>
            ) : (
               <>
                  <div className="detail-title">
                     <p className="detail-title-text">
                        {jenis === 'pib' ? t('detail-pib') : t('detail-peb')}
                     </p>
                  </div>
                  <div className="detail-atas">
                     {jenis === 'pib' ? (
                        <>
                           <div className="detail-container">
                              <p className="detail-judul">{t('no_aju')}</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].no_pengajuan === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].no_pengajuan}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">KPBC</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].kd_kantor === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].kd_kantor +
                                      ' - ' +
                                      dataPibPeb.data_header[0].nama_kantor}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">
                                 Petugas Analyzing Point
                              </p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0]
                                    .analyzing_point_name == null
                                    ? ' '
                                    : dataPibPeb.data_header[0].nip +
                                      ' - ' +
                                      dataPibPeb.data_header[0]
                                         .analyzing_point_name}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">{t('name_impor')}</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].nm_importir === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].nm_importir}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">{t('name_ppjk')}</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].ppjk === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].ppjk}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">{t('description')}</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].deskripsi === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].deskripsi}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">{t('last_stat')}</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].status === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].status}
                              </p>
                           </div>
                           <div className="detail-container-last">
                              <p className="detail-judul">Tanggal Respon</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].tgl_respon === ''
                                    ? '-'
                                    : moment(
                                         dataPibPeb.data_header[0].tgl_respon,
                                         'DD-MM-YYYY HH:mm:ss',
                                      ).format('DD MMMM YYYY HH:mm')}
                              </p>
                           </div>
                        </>
                     ) : (
                        <>
                           <div className="detail-container">
                              <p className="detail-judul">{t('no_aju')}</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].no_pengajuan === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].no_pengajuan}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">KPBC</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].kd_kantor === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].kd_kantor}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">{t('name_expor')}</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].nm_exportir === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].nm_exportir}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">{t('name_ppjk')}</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].ppjk === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].ppjk}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">{t('description')}</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].deskripsi === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].deskripsi}
                              </p>
                           </div>
                           <div className="detail-container">
                              <p className="detail-judul">{t('last_stat')}</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].status === ''
                                    ? '-'
                                    : dataPibPeb.data_header[0].status}
                              </p>
                           </div>
                           <div className="detail-container-last">
                              <p className="detail-judul">Tanggal Respon</p>
                              <p className="detail-hasil">
                                 {dataPibPeb.data_header[0].tgl_respon === ''
                                    ? '-'
                                    : moment(
                                         dataPibPeb.data_header[0].tgl_respon,
                                         'DD-MM-YYYY HH:mm:ss',
                                      ).format('DD MMMM YYYY HH:mm')}
                              </p>
                           </div>
                        </>
                     )}
                  </div>
                  <div className="detail-title">
                     <p className="detail-title-text">{t('process_history')}</p>
                  </div>
                  <div className="detail-histori">
                     <div className="detail-histori-container">
                        {dataPibPeb.data_detail.map((item, index) => (
                           <div className="detail-histori-content">
                              <TimeLinePibPeb
                                 status={item.remarks}
                                 time={
                                    item.tanggal !== null
                                       ? moment(
                                            item.tanggal,
                                            'DD-MM-YYYY HH:mm:ss',
                                         ).format('DD MMMM YYYY HH:mm')
                                       : '-'
                                 }
                              />
                           </div>
                        ))}
                     </div>
                  </div>
                  <div className="detail-title">
                     <p className="detail-title-text">{t('detail')}</p>
                  </div>
                  <div className="detail-bawah">
                     <div className="detail-kiri">
                        {dataPibPeb.data_detail_proses.map((item, index) => (
                           <TimeLine
                              status={item.ur_respon}
                              keterangan={
                                 item.tgl_respon !== null
                                    ? moment(
                                         item.tgl_respon,
                                         'DD-MM-YYYY HH:mm:ss',
                                      ).format('DD MMMM YYYY HH:mm')
                                    : '-'
                              }
                              index={index}
                              totalRow={dataPibPeb.data_detail_proses.length}
                              onClick={() => see_detail(item, index)}
                              type={'kiri'}
                           />
                        ))}
                     </div>
                     <div className="detail-kanan">
                        <p className="detail-kanan-title">
                           {detail} : {no_detail !== null ? no_detail : '-'}
                        </p>
                        {/* <p className="detail-kanan-desc">{detail_desc}</p> */}
                        <div className="detail-kanan-list">
                           {is_progressKanan === true ? (
                              <div
                                 style={{ height: '25vw' }}
                                 className="detail-kanan-load"
                              >
                                 <LoadProgress />
                              </div>
                           ) : dataDetailPibPeb.length === 0 ? (
                              <div className="detail-kanan-load">
                                 <ListEmptyDetail
                                    message_error={error_messageKanan}
                                 />
                              </div>
                           ) : (
                              <div className="detail-kanan-list-content">
                                 <div className="detail-kanan-list-row">
                                    <div className="kanan-list-judul">CAR</div>
                                    <div className="kanan-list-tidu">:</div>
                                    <div className="kanan-list-hasil">
                                       {dataDetailPibPeb.car}
                                    </div>
                                 </div>
                                 <div className="detail-kanan-list-row">
                                    <div className="kanan-list-judul">KPBC</div>
                                    <div className="kanan-list-tidu">:</div>
                                    <div className="kanan-list-hasil">
                                       {dataDetailPibPeb.ur_kpbc}
                                    </div>
                                 </div>
                                 <div className="detail-kanan-list-row">
                                    <div className="kanan-list-judul">
                                       TGL. RESPON
                                    </div>
                                    <div className="kanan-list-tidu">:</div>
                                    <div className="kanan-list-hasil">
                                       {dataDetailPibPeb.respon_date !== null
                                          ? moment(
                                               dataDetailPibPeb.respon_date,
                                               'DD-MM-YYYY HH:mm:ss',
                                            ).format('DD MMMM YYYY HH:mm')
                                          : '-'}
                                    </div>
                                 </div>
                                 <div className="detail-kanan-list-row">
                                    <div className="kanan-list-judul">
                                       DESKRIPSI
                                    </div>
                                    <div className="kanan-list-tidu">:</div>
                                    <div className="kanan-list-hasil-list">
                                       {dataDetailPibPeb.ur_res.length !== 0
                                          ? dataDetailPibPeb.ur_res.map(
                                               (item, index) => (
                                                  <div className="kanan-list">
                                                     {index + 1}. {item}
                                                  </div>
                                               ),
                                            )
                                          : '-'}
                                    </div>
                                 </div>
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
               </>
            )}
         </div>
      </div>
   )
}

export default DetailPibPeb
