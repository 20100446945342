import React, { useState } from 'react';
import './subkatcollapse.scss';
import {Collapse} from 'react-collapse';
import { ICBlueCollapseDown, ICBlueCollapseUp } from '../../../assets';
import { useTranslation } from 'react-i18next';
import * as Api from '../../../constants/api';
import parse from 'html-react-parser';

const CollapseKnfp = ({data}) => {
    const [buka, setBuka] = useState(false);
    const { t } = useTranslation();

    const klikCollapse = buka => {
        if (buka === false) {
            setBuka(true);
        } else {
            setBuka(false);
        }
    }

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    const TypeColl = () => {
            return (
                <>
                <div className={buka === false ? "collapse-header" : "collapse-header-open"} onClick={() => klikCollapse(buka)} >
                    <div className="collapse-text-head">{data.title}</div>
                    <div className="collapse-icon">
                        {
                            data.length !== 0 ?
                            <img src={buka === true ? ICBlueCollapseUp : ICBlueCollapseDown} alt ="icon-collapse" />
                            :
                            null
                        }
                    </div>
                </div>
                <div className="collapse-body">
                    <Collapse isOpened={buka}>
                        <>
                            <div className="coll-bull">
                                <div className="collapse-text">{parseHtml(data.content)}</div>
                            </div>
                        </>
                    </Collapse>
                </div>
                </>
            )
    }

    return (
        <div className="question-collapse-container">
            <TypeColl />
        </div>
    )
}

export default CollapseKnfp;
