import React, {useEffect, useState }  from 'react';
import './carousel_kemen.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardKemen from '../CardKemen';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as Api from '../../../constants/api';
import LoadProgress from '../LoadProgress';
import ListEmpty from '../ListEmpty';
import { ICBi, ICBkpm, ICBpom, ICBptn, ICEsdm, ICKemendag, ICKemenhan, ICKemenhub, ICKemenkes, ICKemenprin, ICKementan, ICKemkominfo, ICKkkp, ICKlhk, ICPolri } from '../../../assets';

// const items = [
//     {
//         data: [
//             {
//                 image: ICKemendag,
//                 title: 'Kementerian Perdagangan',
//                 link: 'https://www.kemendag.go.id/',
//             },
//             {
//                 image: ICKemenprin,
//                 title: 'Kementerian Perindustrian',
//                 link: 'https://www.kemenperin.go.id/',
//             },
//             {
//                 image: ICKemenhub,
//                 title: 'Kementerian Perhubungan',
//                 link: 'http://dephub.go.id/',
//             },
//             {
//                 image: ICKementan,
//                 title: 'Kementerian Pertanian',
//                 link: 'https://www.pertanian.go.id/',
//             },
//             {
//                 image: ICKemkominfo,
//                 title: 'Kementerian Komunikasi dan Informasi',
//                 link: 'https://www.kominfo.go.id/',
//             },
//         ],
//     },
//     {
//         data: [
//             {
//                 image: ICKkkp,
//                 title: 'Kementerian Kelautan dan Perikanan',
//                 link: 'https://kkp.go.id/',
//             },
//             {
//                 image: ICKemenkes,
//                 title: 'Kementerian Kesehatan',
//                 link: 'https://www.kemkes.go.id/',
//             },
//             {
//                 image: ICKlhk,
//                 title: 'Kementerian Lingkungan Hidup dan Kehutanan',
//                 link: 'https://www.menlhk.go.id/',
//             },
//             {
//                 image: ICEsdm,
//                 title: 'Kementerian Energi dan Sumber Daya Mineral',
//                 link: 'https://www.esdm.go.id/',
//             },
//             {
//                 image: ICKemenhan,
//                 title: 'Kementerian Pertahanan',
//                 link: 'https://www.kemhan.go.id/',
//             },
//         ],
//     },
//     {
//         data: [
//             {
//                 image: ICPolri,
//                 title: 'Kepolisian Negara Republik Indonesia',
//                 link: 'https://www.polri.go.id/',
//             },
//             {
//                 image: ICBpom,
//                 title: 'Badan Pengawas Obat dan Makanan',
//                 link: 'https://www.pom.go.id/',
//             },
//             {
//                 image: ICBptn,
//                 title: 'Badan Pengawas Tenaga Nuklir',
//                 link: 'https://www.bapeten.go.id/',
//             },
//             {
//                 image: ICBkpm,
//                 title: 'Badan Koordinasi Penanaman Modal',
//                 link: 'https://www.bkpm.go.id/',
//             },
//             {
//                 image: ICBi,
//                 title: 'Bank Indonesia',
//                 link: 'https://www.bi.go.id/',
//             },
//         ],
//     },
//   ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      // slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      // slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      // slidesToSlide: 1 // optional, default to 1.
    }
  };

const CustomDot = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li
      className={active ? "active-caro" : "inactive-caro"}
      onClick={() => onClick()}
    >
    </li>
  );
};

const CarouselStatistik = (props) => {
  const { t } = useTranslation();
  const [is_progress, setisProgress] = useState(true);
  const [list_kemen, setListKemen] = useState([]);
  const [list_kemen_partial, setListKemenPartial] = useState([]);
  const [error_message_banner, setErrorMessageBanner] = useState('');

  const width = window.innerWidth;

  //didmount
  useEffect(() => {
    get_data();
    // const width = window.innerWidth;
    // console.log('width');
    // console.log(width);
  }, []);

  //function
  const get_data = () => {
    setisProgress(true);
    const url = Api.KEMEN_SLIDER;
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        // console.log('kemen');
        // console.log(response.data.data);
        setisProgress(false);
        if (response.data.code === '01') {
            // console.log('kemen');
            // console.log(response.data.data);
            // var dataArray = [];
            // dataArray.push(response.data);
            // setListKemen(dataArray);
            let data_kemen = response.data.data;
            dataPartial(data_kemen);
        } else {
            setListKemenPartial([]);
            setErrorMessageBanner(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgress(false);
        setListKemenPartial([]);
        setErrorMessageBanner(t('error_message'));
      });
  }

  const dataPartial = (item) => {
    let content_partial = [];
    let content = [];
    var count = 1;
    var jmlData = 5;
    if (width < 600) {
      jmlData = 1;
    }

    for (let i = 0; i < item.length; i++) {
        // console.log(count);
        content_partial.push(item[i]);
        // console.log(item[i].title);
        if (count === jmlData) {
            let data = {
                data : content_partial
            }
            content_partial = [];
            content.push(data);    
            count = 1;
        } else {
          // console.log(item.length - i);
          if (item.length - i === 1) {
            let data = {
                data : content_partial
            }
            content_partial = [];
            content.push(data);
            count = count + 1 
          } else {
            count = count + 1 
          }
        }
    }
    // console.log('content');
    // console.log(content);
  
    setListKemenPartial(content);
  }

  // Dummy Data
  // const slides = items.map((item) => {
  //   return (
  //     <div className="home-kemen-content">
  //       <div className="kemen-item">
  //         {item.data.map((item_data) => 
  //           <CardKemen image={item_data.image} title={item_data.title} onClick={item_data.link} />
  //         )}
  //       </div>
  //     </div>
  //   );
  // });

  const removeDot = item => {
    var imageUrl = Api.IP_KONG_LNSW + item.slice(1);
    // console.log (imageUrl);
    return imageUrl;
  }

  const slides = list_kemen_partial.map((item) => {
    return (
      <div className="home-kemen-content">
        <div className="kemen-item">
          {item.data.map((item_data) => 
              <CardKemen image={removeDot(item_data.nama_img)} title={item_data.nama} onClick={item_data.url_link} />
          )}
        </div>
      </div>
    );
  });

  return (
    <div>
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={3000}
        centerMode={false}
        customDot={<CustomDot />}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        containerClass={{width: '100%'}}
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable>
        {slides}
        {/* <div className="home-kemen-content">
          <div className="kemen-item">
            {list_kemen.map((item_data) => 
              <CardKemen image={removeDot(item_data.nama_img)} title={item_data.nama} onClick={item_data.url_link} />
            )}
          </div>
        </div>        */}
      </Carousel>
    </div>
  );
}

export default CarouselStatistik;