/* eslint-disable jsx-a11y/alt-text */
//import lib react
import './peraturan.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import GridList from 'react-gridlist';
import moment from 'moment';
import Ripples from 'react-ripples';
import { useHistory } from 'react-router-dom';

//component
import { LogoKarantinaIkan, ImgImport, ImgEcommerce, ImgLogistik, ICIntrExporImpor, ICPeraturanUMKM, GarudaLogo } from '../../../assets';
import * as Api from '../../../constants/api';
import { ListSetting, LoadProgress, ListEmpty } from '../../../components';
import { InputSearch } from '../../../components/atoms';

const Peraturan = () => {
    //variable constant
    const { t } = useTranslation();
    const [is_progress_peraturan, setisProgressPeraturan] = useState(true);
    const [list_peraturan, setListPeraturan] = useState([]);
    const [error_message_peraturan, setErrorMessagePeraturan] = useState('');
    const history = useHistory();
    const search_peraturan = useRef('');

    //style
    let styles = {
        container: {
            flex: 1,
        },
    }

    // did mount
    useEffect(() => {
        _get_data_peraturan();
    }, []);

    //function
    const _get_data_peraturan = () => {
        setisProgressPeraturan(true);
        const url = Api.PERATURAN_HOME;
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                // console.log('peraturan', response.data);
                setisProgressPeraturan(false);
                if (response.data.code === '01') {
                    setListPeraturan(response.data.data);
                } else {
                    setListPeraturan([]);
                    setErrorMessagePeraturan(t('empty_list'));
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setisProgressPeraturan(false);
                setListPeraturan([]);
                setErrorMessagePeraturan(t('error_message'));
            });
    }

    const getGridGap = (elementWidth, windowHeight) => (elementWidth > 720 && windowHeight > 480) ? 10 : 5;

    const getColumnCount = (elementWidth) => {
        const width = window.innerWidth;
        if (width < 600) {
            return Math.floor(elementWidth / 300); //pembagian column (dibagi untuk 4 column)
        } else {
            return Math.floor(elementWidth / 550); //pembagian column (dibagi untuk 2 column)
        }

    }

    const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.5);


    const getItemData = (list_peraturan, columnWidth) => {
        // let adjustedHeight = Math.round(columnWidth * 0.25)
        return {
            key: list_peraturan.id,
            // height: adjustedHeight,
        }
    }

    const searchPeraturan = () => {
        history.push('/intr/peraturan/cari-peraturan', { search_peraturan: search_peraturan.current });
    }

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         // console.log('peraturan', event.target.value);
    //         searchPeraturan();
    //     }
    // }

    return (
        <div className="peraturan-page">
            <div className="peraturan-background">
                <div className="peraturan-content-title">
                    <div className="peraturan-content-sub-title">
                        <div className="peraturan-text-title">
                            {t('title_cover_peraturan')}
                        </div>
                        <div className="peraturan-text-message">
                            {t('desc_cover_peraturan')}
                        </div>
                        <div className="row m-0 justify-content-center">
                            <div className="col-md-6">
                                <div className="peraturan-input-search">
                                    <InputSearch
                                        placeholder={t('look_the_rules')}
                                        onChange={e => search_peraturan.current = e.target.value}
                                        onClick={() => searchPeraturan()}
                                        onKeyDown={() => searchPeraturan()}
                                    // onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="peraturan-content">
                <div className="peraturan-content-title">
                    <div>{t('most_popular')}</div>
                </div>

                <div>
                    {is_progress_peraturan === true ?
                        // <div style={{ height: '25vw' }} className="peraturan-content-load col-md-12 p-0">
                        <div className="peraturan-content-load">
                            <LoadProgress />
                        </div>
                        :
                        list_peraturan.length === 0 ?
                            <div className="peraturan-content-load">
                                <ListEmpty message_error={error_message_peraturan} />
                            </div>
                            :
                            <div style={styles.container}>
                                <GridList
                                    items={list_peraturan}
                                    getGridGap={getGridGap}
                                    getColumnCount={getColumnCount}
                                    getWindowMargin={getWindowMargin}
                                    getItemData={getItemData}
                                    renderItem={(item) => {
                                        return (
                                            <div className="peraturan-content-item-grid">
                                                <ListSetting
                                                    onClick={() => history.push("/intr/peraturan/detail-peraturan", { data_peraturan: item })}
                                                    // image={LogoKarantinaIkan}
                                                    // image={GarudaLogo}
                                                    image={item.ga_logo}
                                                    nomor={item.nomor_peraturan}
                                                    bidang={item.bidang_peraturan}
                                                    title={item.judul_peraturan}
                                                    desc={item.keterangan}
                                                    date={moment(item.tanggal_peraturan).format('DD MMMM YYYY')}
                                                />
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                    }
                </div>
                <div style={{ marginTop: '3vw' }} className="peraturan-content-title">
                    <div>{t('thematic_options')}</div>
                </div>
                <div className="peraturan-content-tematik">
                    <Ripples onClick={() => history.push('/intr/peraturan/tematik-pilihan', { tematik: 'ekspor', id_tematik: '1' })}>
                        <div className="peraturan-content-tematik-item">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img className="peraturan-content-tematik-item-image" src={ICIntrExporImpor} />
                            </div>
                            <div className="peraturan-content-tematik-item-text-title">{t('export')}</div>
                            <div className="peraturan-content-tematik-item-text-desc">{t('collection_related_rules') + ' ' + t('export_activities')}</div>
                        </div>
                    </Ripples>
                    <Ripples onClick={() => history.push('/intr/peraturan/tematik-pilihan', { tematik: 'impor', id_tematik: '2' })}>
                        <div className="peraturan-content-tematik-item">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img className="peraturan-content-tematik-item-image" src={ImgImport} />
                            </div>
                            <div className="peraturan-content-tematik-item-text-title">{t('import')}</div>
                            <div className="peraturan-content-tematik-item-text-desc">{t('collection_related_rules') + ' ' + t('import_activities')}</div>
                        </div>
                    </Ripples>
                    <Ripples onClick={() => history.push('/intr/peraturan/tematik-pilihan', { tematik: 'logistik', id_tematik: '16' })}>
                        <div className="peraturan-content-tematik-item">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img className="peraturan-content-tematik-item-image" src={ImgLogistik} />
                            </div>
                            <div className="peraturan-content-tematik-item-text-title">{t('logistics')}</div>
                            <div className="peraturan-content-tematik-item-text-desc">{t('collection_related_rules') + ' ' + t('logistics')}</div>
                        </div>
                    </Ripples>
                    <Ripples onClick={() => history.push('/intr/peraturan/tematik-pilihan', { tematik: 'umkm', id_tematik: '3' })}>
                        <div className="peraturan-content-tematik-item">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img className="peraturan-content-tematik-item-image" src={ICPeraturanUMKM} />
                            </div>
                            <div className="peraturan-content-tematik-item-text-title">SMEs</div>
                            <div className="peraturan-content-tematik-item-text-desc">{t('collection_related_rules') + ' ' + 'UMKM (SMEs)'}</div>
                        </div>
                    </Ripples>
                    <Ripples onClick={() => history.push('/intr/peraturan/tematik-pilihan', { tematik: 'ecommerce', id_tematik: '4' })}>
                        <div className="peraturan-content-tematik-item">
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img className="peraturan-content-tematik-item-image" src={ImgEcommerce} />
                            </div>
                            <div className="peraturan-content-tematik-item-text-title">E - Commerce</div>
                            <div className="peraturan-content-tematik-item-text-desc">{t('collection_related_rules') + ' E-Commerce'}</div>
                        </div>
                    </Ripples>
                </div>
            </div>
        </div>
    )
}

export default Peraturan;
