/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import './detail_foto.scss';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import parse from 'html-react-parser';
import GridList from 'react-gridlist';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Ripples from 'react-ripples';
import { useHistory, useLocation } from 'react-router-dom';

//component
import * as Api from '../../../../constants/api';
import { LoadProgress, ListEmpty} from '../../../../components';
import { ICCalendarGrayNews } from '../../../../assets';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const DetailFoto = () => {

  // variable constant
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [is_progress, setisProgress] = useState(true);
  const [list_foto, setListFoto] = useState([]);
  const [list_foto_slice, setListFotoSlice] = useState([]);
  const [error_message, setErrorMessage] = useState('');

  const [is_progress_cover, setisProgressCover] = useState(true);
  const [cover_foto, setCoverFoto] = useState({});
  const [error_message_cover, setErrorMessageCover] = useState('');

  const perPage = 8;
  const [pageCount, setPageCount] = useState('');

  //style
  let styles = {
    container: {
        flex: 1,
    },
}

  // did mount
  useEffect(() => {
    // console.log('id_foto');
    // console.log(location.state.id_foto);
    setTimeout(() => {
      _get_detail_foto(location.state.id_foto);
      _get_data_foto();
    }, 500);
  }, []);

  //function
  const _get_detail_foto = (id_foto) => {
    // console.log('id foto',id_foto)
    setisProgressCover(true);
    const url = Api.DETAIL_FOTO + id_foto + '?lang=' + localStorage.getItem('language').toUpperCase();;
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        setisProgressCover(false);
        if (response.data.code === '01') {
          if (response.data.data.length === 0) {
              setCoverFoto({});
              setErrorMessageCover(t('empty_list'));
          } else {
            let list_foto_data = response.data.data;
            setCoverFoto(list_foto_data);
          } 
        } else {
          setCoverFoto({});
          setErrorMessageCover(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgressCover(false);
        setCoverFoto({});
        setErrorMessageCover(t('error_message'));
      });
  }

  const _get_data_foto = () => {
    setisProgress(true);
    const url = Api.GET_DATA_FOTO + '?lang=' + localStorage.getItem('language').toUpperCase();;
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        setisProgress(false);
        if (response.data.code === '01') {
          if (response.data.data.length === 0) {
              setListFoto([]);
              setListFotoSlice([]);
              setErrorMessage(t('empty_list'));
          } else {
            let list_foto_data = response.data.data;
            // console.log('data foto', list_foto_data);
            setListFoto(list_foto_data);
            const slice = list_foto_data.slice(0, perPage);
            setPageCount(list_foto_data.length / perPage);
            setListFotoSlice(slice);
          } 
        } else {
          setListFoto([]);
          setListFotoSlice([]);
          setErrorMessage(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgress(false);
        setListFoto([]);
        setListFotoSlice([]);
        setErrorMessage(t('error_message'));
      });
  }

  const CallImage = (image_url) => {
    // console.log('url_image');
    // console.log(image_url);
    var url_image = '';
    if (image_url !== undefined) {
      url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    }
    // console.log('url_image');
    // console.log(url_image);
    return url_image;
  }

  const parseContent = (text) => {
    var text_html = '';
    if (text !== undefined) {
      text_html = parse(text)
    }
    // console.log('url_image');
    // console.log(text_html);
    return text_html;
  }

  const getGridGap = (elementWidth, windowHeight) => (elementWidth > 720 && windowHeight > 480) ? 10 : 5;

  const getColumnCount = (elementWidth) => Math.floor(elementWidth / 300); //pembagian column (dibagi untuk 4 column)

  const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.1);

  const getItemData = (list_peraturan, columnWidth) => {
      return {
          key: list_peraturan.id,
      }
  }
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    loadMoreData(offset, offset + perPage);
  };

  const loadMoreData = (of, op) => {
    const data = list_foto;

    const slice = data.slice(of, op);
    setPageCount(data.length / perPage);
    setListFotoSlice(slice);
  };

  const _click_detail = (item) => {
    // console.log(item)
    window.scrollTo(0,0);
    _get_detail_foto(item.id_photo);
    _get_data_foto();
  }

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1,
      partialVisibilityGutter: 1,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  };
  
  const CustomDot = ({ onMove, index, onClick, active }) => {
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <li
        className={active ? "active-caro" : "inactive-caro"}
        onClick={() => onClick()}
      >
      </li>
    );
  };

  const slides = (item) => {
    return (
      item.detail.map((item_data) =>
        <img className="image-foto" src={CallImage(item_data.file_photo_detail)} />
      )
    );
  };

    return (
      <div className="detail-foto-content">
        <div className="title-foto">{t('document_picture')}</div>
        <div style={{display: 'flex'}}>
          {is_progress_cover === true ?
            <div style={{height: '25vw'}} className="search-content-load">
                <LoadProgress />
            </div> 
            : 
            JSON.stringify(cover_foto) === '{}' ?
            <div className="search-content-load">
                <ListEmpty message_error={error_message}/>
            </div>
            :
            <div className="cover-foto">
              <div className="cover-foto-image">
                <Carousel
                  additionalTransfrom={0}
                  arrows
                  autoPlay={true}
                  autoPlaySpeed={5000}
                  transitionDuration={500}
                  customTransition="transform 500ms ease-in-out"
                  centerMode={false}
                  customDot={<CustomDot />}
                  dotListClass=""
                  draggable
                  focusOnSelect={false}
                  infinite
                  containerClass={{ width: '100%' }}
                  keyBoardControl
                  minimumTouchDrag={80}
                  renderButtonGroupOutside={false}
                  renderDotsOutside={false}
                  responsive={responsive}
                  showDots={false}
                  sliderClass=""
                  slidesToSlide={1}
                  swipeable>
                  {
                    slides(cover_foto)
                  }
                </Carousel>
              </div>
              <div className="cover-foto-desc">
                <div className="cover-foto-desc-title">
                  {cover_foto.header.header}
                </div>
                <div className="cover-foto-desc-date">
                  <img src={ICCalendarGrayNews} style={{marginRight: '0.7vw'}} />
                  {moment(cover_foto.header.waktu_publish).format('DD MMMM YYYY')}
                </div>
                <div className="cover-foto-desc-desc">
                  {parseContent(cover_foto.header.berita)}
                </div>
              </div>
            </div>
          }
        </div>
        <div className="foto-other">
          <div className="foto-other-title">
            {t('other_photos')}
          </div>
          <div style={{display: 'flex', marginTop: '1vw'}}>
            {is_progress === true ?
              <div style={{height: '25vw'}} className="search-content-load">
                  <LoadProgress />
              </div> 
              : 
              list_foto_slice.length === 0 ?
              <div className="search-content-load">
                  <ListEmpty message_error={error_message}/>
              </div>
              :
              <div style={{flex: 1}}>
                <div style={styles.container}>
                    <GridList
                        items={list_foto_slice}
                        getGridGap={getGridGap}
                        getColumnCount={getColumnCount}
                        getWindowMargin={getWindowMargin}
                        getItemData={getItemData}
                        renderItem={(item) => {
                            return (
                              <Ripples 
                                onClick={() => _click_detail(item)}
                                >
                                <div className="foto-other-item-grid">
                                  <img className="foto-other-item-grid-image" src={CallImage(item.photo_detail)} />
                                  <div className="foto-other-item-grid-title max-line-three">
                                    {item.header}
                                  </div>
                                  <div className="foto-other-item-grid-desc max-line-four">
                                    {parseContent(item.berita)}
                                  </div>
                                </div>
                              </Ripples>
                            )
                        }}
                    />
                </div>
                <div className="foto-other-content-list">
                  <ReactPaginate
                      previousLabel={t('previous')}
                      nextLabel={t('next')}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick.bind(this)}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                  />
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
}

export default DetailFoto;
