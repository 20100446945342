import React, { useState, useEffect } from 'react';
import './atr.scss';
import { useTranslation } from 'react-i18next';
import { CardAtr, ListEmpty, LoadProgress } from '../../../components';
import GridList from 'react-gridlist';
import {
    ICTariffNomen, 
    ICMfnPt, 
    ICRulesOrigin, 
    ICNonTariff, 
    ICNationalTrade, 
    ICProDoc, 
    ICAdminRulings, 
    ICBestPractice, 
    ICListAuth
  } from '../../../assets';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as Api from '../../../constants/api';

const AseanTradeRepo = () => {
    //variable constant
    const { t } = useTranslation();
    const history = useHistory();
    const [is_progress_atr, setisProgressAtr] = useState(false);
    const [error_message_atr, setErrorMessageAtr] = useState('');
    // const [data, setData] = useState([]);

    const width = window.innerWidth;

    const [list_atr, SetList_Atr] = useState([
        {
            image: ICTariffNomen,
            title: 'Tariff Nomenclature',
            id: 0,
            path: '/intr/asean-trade-repository/tariff-nomenclature',
        },
        {
            image: ICMfnPt,
            title: 'MFN and Prefential Tariff',
            id: 0,
            path: '/intr/asean-trade-repository/mfn-pt',
        },
        {
            image: ICRulesOrigin,
            title: 'Rules Of Origin',
            id: 0,
            path: '/intr/asean-trade-repository/rules-of-origin',
        },
        {
            image: ICNonTariff,
            title: 'Non-tariff Measures',
            id: 0,
            path: '/intr/asean-trade-repository/non-tariff-measures',
        },
        {
            image: ICNationalTrade,
            title: 'National Trade And Customs Laws And Rules',
            id: 0,
            path: '/intr/asean-trade-repository/national-trade',
        },
        {
            image: ICProDoc,
            title: 'Procedure and Documentary Requirements',
            id: 0,
            path: '/intr/asean-trade-repository/procedures-doc-req',
        },
        {
            image: ICAdminRulings,
            title: 'Administrative Rulings',
            id: 0,
            path: '/intr/asean-trade-repository/administrative-rulings',
        },
        {
            image: ICBestPractice,
            title: 'Best Practice in Trade Facilitation',
            id: 0,
            path: '/intr/asean-trade-repository/best-practice',
        },
        {
            image: ICListAuth,
            title: 'List of Authorized Traders of AMSs',
            id: 0,
            path: '/intr/asean-trade-repository/list-of-authorized',
        },
      ]);

    const getGridGap = (elementWidth, windowHeight) => (elementWidth > 720 && windowHeight > 480) ? 10 : 5;
    
    let getColumnCount = (elementWidth) => Math.floor(elementWidth / 377); //pembagian column (dibagi untuk 2 column) // awal 400 laptop hilman 377

    if (width < 600) {
        getColumnCount = (elementWidth) => Math.floor(elementWidth / 300);
    }

    const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.5);

    useEffect(() => {
        getListAtr();
    }, []);

    const getListAtr = () => {
        setisProgressAtr(true);
        const url = Api.LIST_ATR;
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                setisProgressAtr(false);
                if (response.data.code === '01') {
                    // console.log(response.data.data);
                    let dataList = response.data.data;
                    let dataListLokal = list_atr;
                    for (let i = 0; i < dataListLokal.length; i++) {
                        let namaAtrLokal = dataListLokal[i].title;
                        for (let j = 0; j < dataList.length; j++) {
                            let namaAtr = dataList[j].title;
                            if (namaAtrLokal === namaAtr) {
                                dataListLokal[i].id = dataList[j].id;
                            }
                        }
                    }
                    // console.log('atr data', dataListLokal);
                    SetList_Atr(dataListLokal);
                } else {
                    SetList_Atr([]);
                    setErrorMessageAtr(t('empty_list'));
                }
            })
            .catch(error => {
                setisProgressAtr(false);
                console.log('error');
                console.log(error);
                setErrorMessageAtr(t('error_message'));
                SetList_Atr([]);
            });
    }

    const getItemData = (list_atr, columnWidth) => {
        // let adjustedHeight = Math.round(columnWidth * 0.25)
        return {
            key: list_atr.id,
            // height: adjustedHeight,
        }
    }

    const ClickCardAtr = item => {
        console.log('click react atr')
       
        history.push(item.path, { id: item.id });
        // console.log('id');
        // console.log(item.id);

      };


    return (
        <div>
            <div className="atr-background">
                <div className="atr-content-title">
                    <div className="atr-content-sub-title">
                        <div className="atr-text-title">
                            {t('title_cover_atr')}
                        </div>
                        <div className="atr-text-message">
                            {t('desc_cover_atr')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="atr-container">
            {is_progress_atr === true ?
                        <div style={{height: '25vw'}} className="peraturan-content-load">
                            <LoadProgress />
                        </div> 
                        : 
                        list_atr.length === 0 ?
                        <div className="peraturan-content-load">
                            <ListEmpty message_error={error_message_atr}/>
                        </div>
                        :
                        <div className="atr-list">
                            <GridList
                                items={list_atr}
                                getGridGap={getGridGap}
                                getColumnCount={getColumnCount}
                                getWindowMargin={getWindowMargin}
                                getItemData={getItemData}
                                renderItem={(item_data) => {
                                    return (
                                        <div className="peraturan-content-item-grid">
                                            <CardAtr
                                                image={item_data.image}
                                                title={item_data.title}
                                                message={item_data.message}
                                                onClick={() => ClickCardAtr(item_data)}
                                            />
                                        </div>
                                    )
                                }}
                            />
                        </div>
                    }   
            </div>
        </div>
    )
}

export default AseanTradeRepo;
