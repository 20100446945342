import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Header, Footer, ModalPengumuman, ScrollTop } from '../../components'
import { LastLocationProvider } from 'react-router-last-location'
import './mainApp.scss'
import Home from '../Home'
import ProfilPimpinan from '../Profil/profil-pimpinan/profil-pimpinan'
import ProfilPejabat from '../Profil/profil-pejabat'
import ProfilTugasfungsi from '../Profil/tugas-fungsi/tugas-fungsi'
import ProfilEtik from '../Profil/kode-etik/kode_etik'
import ProfilKontak from '../Profil/kontak_kami/kontak'
import LHKPN from '../Profil/lhkpn-pimpinan/lhkpn'
import Intr from '../Intr'
import Publikasi from '../Publikasi'
import AplikasiLnsw from '../AplikasiLnsw'
import Penelusuran from '../Penelusuran'
import PusatBantuan from '../PusatBantuan'
import FAQ from '../PusatBantuan/FAQ'
import Panduan from '../PusatBantuan/Panduan'
import PanduanDetail from '../PusatBantuan/Panduan/PanduanDetail'
import FAQDetail from '../PusatBantuan/FAQ/FAQDetail'
import InfoPublik from '../InfoPublik'
import ProfilPPID from '../InfoPublik/ProfilPPID'
import MaklumatPelayanan from '../InfoPublik/MaklumatPelayanan'
import ProsedurPermohonan from '../InfoPublik/ProsedurPermohonan'
import StatistikLayanan from '../InfoPublik/StatistikLayanan'
import InfoWajibBerkala from '../InfoPublik/InfoWajibBerkala'
import InfoSertaMerta from '../InfoPublik/InfoSertaMerta'
import DetailInfoSertaMerta from '../InfoPublik/InfoSertaMerta/DetailData'
import SetiapSaat from '../InfoPublik/InfoSetiapSaat'
import Simulasi from '../Intr/Simulasi'
import Komoditas from '../Intr/Komoditas'
import Referensi from '../Intr/Referensi'
import { spring, AnimatedSwitch } from 'react-router-transition'
import Peraturan from '../Intr/Peraturan'
import SearchPeraturan from '../Intr/Peraturan/SearchPeraturan'
import DetailPeraturan from '../Intr/Peraturan/DetailPeraturan'
import CategoryPeraturan from '../Intr/Peraturan/CategoryPeraturan'
import AseanTradeRepo from '../Intr/ATR'
import {
   AdminRulings,
   DetailNtpn,
   DetailPressRelease,
   InformasiPublikPPID,
   ProsedurPengajuanKeberatan,
   ProsedurPermohonanSengketaInformasi,
   KetentuanKomoditas,
   PressRelease,
   ProceduresDoc,
   SearchPressRelease,
   SubFaq,
   TarifNomen,
   Agenda,
} from '..'
import MfnPt from '../Intr/ATR/MfnPt'
import RulesOfOrigin from '../Intr/ATR/RulesOfOrigin'
import NonTariff from '../Intr/ATR/NonTariff'
import NationalTrade from '../Intr/ATR/NationalTrade'
import BestPractice from '../Intr/ATR/BestPractice'
import ListAuth from '../Intr/ATR/ListAuth'
import VisiMisi from '../Profil/visi-misi'
import StrukturOrganisasi from '../Profil/StrukturOrganisasi'
import Sejarah from '../Profil/Sejarah'
import Lhv from '../Penelusuran/Lhv'
import DetailLhv from '../Penelusuran/Lhv/Detail'
import KorelasiHS from '../Penelusuran/KorelasiHS'
import DetailKorelasiHS from '../Penelusuran/KorelasiHS/Detail'
import PibPeb from '../Penelusuran/PibPeb'
import Perijinan from '../Penelusuran/Perijinan'
import Ecoo from '../Penelusuran/Ecoo'
import Rekomendasi from '../Penelusuran/Rekomendasi'
import Nib from '../Penelusuran/Nib'
import NtpnSimbara from '../Penelusuran/NtpnSimbara'
import Berita from '../Publikasi/Berita'
import AllBerita from '../Publikasi/Berita/AllBerita'
import DetailBerita from '../Publikasi/Berita/DetailBerita'
import DetailPibPeb from '../Penelusuran/PibPeb/Detail'
import Majalah from '../Publikasi/MajalahElektronik'
import LaporanTahunan from '../Publikasi/LaporanTahunan'
import Artikel from '../Publikasi/Artikel'
import SearchArtikel from '../Publikasi/Artikel/SearchArtikel'
import DetailArtikel from '../Publikasi/Artikel/DetailArtikel'
import FotoDocument from '../Publikasi/FotoDocument'
import DetailFoto from '../Publikasi/FotoDocument/DetailFoto'
import DetailEcoo from '../Penelusuran/Ecoo/Detail'
import DetailPerijinan from '../Penelusuran/Perijinan/Detail'
import DetailRekom from '../Penelusuran/Rekomendasi/Detail'
import VideoDocument from '../Publikasi/VideoDocument'
import DetailVideo from '../Publikasi/VideoDocument/DetailVideo'
import DetailNib from '../Penelusuran/Nib/Detail'
import Tutorial from '../PusatBantuan/Video'
import CallCenter from '../../components/molecules/Callcenter'
import PageNotFound from '../PageNotFound'
import SiteMap from '../SiteMap'
import ContactCenter from '../ContactCenter'
import AboutKnfp from '../KNFP/About'
import TradeFacilitation from '../KNFP'
import FacilityRepository from '../KNFP/FacilityRepository'
import NewsUpdate from '../KNFP/NewsUpdate/index'
import NewsUpdateAll from '../KNFP/NewsUpdate/allBerita/index'
import NewsUpdateDetail from '../KNFP/NewsUpdate/detailBerita/index'
import BasicForms from '../KNFP/ContactPoint/Enquery'
import Detail from '../KNFP/About/Detail'
import DetailRepo from '../KNFP/FacilityRepository/Detail'
import DetailPerizinan from '../Intr/Komoditas/DetailPerizinan'
import KuotaTRQ from '../Penelusuran/KuotaTRQ'
import RiwayatPenghargaan from '../InfoPublik/RiwayatPenghargaan'
import InfoWajibBerkalaNew from '../InfoPublik/infoWajibBerkalaNew'
import InfoSetiapSaat2 from '../InfoPublik/infoSetiapSaatNew2'

const MainApp = () => {
   const [showModal, setShowModal] = useState(false)

   const openModal = () => {
      setShowModal((prev) => !prev)
   }

   // did mount
   useEffect(() => {
      if (window.location.pathname === '/') {
         openModal()
      }
   }, [])

   const mapStyles = (styles) => {
      return {
         opacity: styles.opacity,
         transform: `scale(${styles.scale})`,
      }
   }

   const bounce = (val) => {
      return spring(val, {
         stiffness: 330,
         damping: 22,
      })
   }

   const bounceTransition = {
      // start in a transparent, upscaled state
      atEnter: {
         opacity: 0,
         scale: 1.2,
      },
      // leave in a transparent, downscaled state
      atLeave: {
         opacity: bounce(0),
         scale: bounce(0.8),
      },
      // and rest at an opaque, normally-scaled state
      atActive: {
         opacity: bounce(1),
         scale: bounce(1),
      },
   }

   return (
      <div className="main-app-wrapper">
         <ModalPengumuman showModal={showModal} setShowModal={setShowModal} />
         <Router>
            <LastLocationProvider>
               <ScrollTop>
                  <div className="header-wrapper">
                     <Header />
                  </div>
                  <div className="content-wrapper">
                     {/* <CallCenter></CallCenter> */}
                     <AnimatedSwitch
                        atEnter={bounceTransition.atEnter}
                        atLeave={bounceTransition.atLeave}
                        atActive={bounceTransition.atActive}
                     // mapStyles={mapStyles}
                     >
                        <Route exact path="/">
                           <Home />
                        </Route>
                        <Route exact path="/visi-misi">
                           <VisiMisi />
                        </Route>
                        <Route path="/struktur-organisasi">
                           <StrukturOrganisasi />
                        </Route>
                        <Route path="/sejarah">
                           <Sejarah />
                        </Route>
                        <Route exact path="/profil-pimpinan">
                           <ProfilPimpinan />
                        </Route>
                        <Route exact path="/profil-pejabat">
                           <ProfilPejabat />
                        </Route>
                        <Route exact path="/tugas-fungsi">
                           <ProfilTugasfungsi />
                        </Route>
                        <Route exact path="/pengaduan-kodeetik">
                           <ProfilEtik />
                        </Route>
                        <Route exact path="/kontak-kami">
                           <ProfilKontak />
                        </Route>
                        <Route exact path="/lhkpn-pimpinan">
                           <LHKPN />
                        </Route>
                        <Route exact path="/intr">
                           <Intr />
                        </Route>
                        <Route path="/intr/detail-peraturan">
                           <DetailPeraturan />
                        </Route>
                        <Route path="/intr/simulasi">
                           <Simulasi />
                        </Route>
                        <Route path="/intr/detail-komoditas/detail-perizinan">
                           <DetailPerizinan />
                        </Route>
                        <Route path="/intr/detail-komoditas">
                           <Komoditas />
                        </Route>
                        <Route
                           path="/detail-komoditas/:lartas"
                           render={({ match }) => (
                              <Komoditas lartas={match.params.lartas} />
                           )}
                        />
                        {/* <Route path="/detail-komoditas">
                                    <Komoditas />
                                </Route> */}
                        {/* <Route path="/press-release/detail-press-release/:id_berita" render={({match}) => (
                                    <DetailPressRelease
                                        id_berita={match.params.id_berita}
                                    />
                                )}/> */}
                        <Route path="/intr/referensi">
                           <Referensi />
                        </Route>
                        <Route exact path="/intr/peraturan">
                           <Peraturan />
                        </Route>
                        <Route path="/intr/peraturan/detail-peraturan">
                           <DetailPeraturan />
                        </Route>
                        <Route exact path="/intr/peraturan/tematik-pilihan">
                           <CategoryPeraturan />
                        </Route>
                        <Route path="/intr/peraturan/tematik-pilihan/detail-peraturan">
                           <DetailPeraturan />
                        </Route>
                        <Route exact path="/intr/peraturan/cari-peraturan">
                           <SearchPeraturan />
                        </Route>
                        <Route path="/intr/peraturan/cari-peraturan/detail-peraturan">
                           <DetailPeraturan />
                        </Route>
                        <Route exact path="/intr/asean-trade-repository">
                           <AseanTradeRepo />
                        </Route>
                        <Route
                           exact
                           path="/intr/asean-trade-repository/tariff-nomenclature"
                        >
                           <TarifNomen />
                        </Route>
                        <Route exact path="/intr/asean-trade-repository/mfn-pt">
                           <MfnPt />
                        </Route>
                        <Route
                           exact
                           path="/intr/asean-trade-repository/rules-of-origin"
                        >
                           <RulesOfOrigin />
                        </Route>
                        <Route
                           exact
                           path="/intr/asean-trade-repository/non-tariff-measures"
                        >
                           <NonTariff />
                        </Route>
                        <Route
                           exact
                           path="/intr/asean-trade-repository/national-trade"
                        >
                           <NationalTrade />
                        </Route>
                        <Route
                           exact
                           path="/intr/asean-trade-repository/procedures-doc-req"
                        >
                           <ProceduresDoc />
                        </Route>
                        <Route
                           exact
                           path="/intr/asean-trade-repository/administrative-rulings"
                        >
                           <AdminRulings />
                        </Route>
                        <Route
                           exact
                           path="/intr/asean-trade-repository/best-practice"
                        >
                           <BestPractice />
                        </Route>
                        <Route
                           exact
                           path="/intr/asean-trade-repository/list-of-authorized"
                        >
                           <ListAuth />
                        </Route>
                        <Route
                           exact
                           path="/intr/ketentuan-umum-untuk-menginterpretasi-harmonized-system"
                        >
                           <KetentuanKomoditas />
                        </Route>
                        <Route path="/informasi-publik">
                           <InfoPublik />
                        </Route>
                        <Route exact path="/profil-ppid">
                           <ProfilPPID />
                        </Route>
                        <Route exact path="/maklumat-pelayanan">
                           <MaklumatPelayanan />
                        </Route>
                        <Route exact path="/agenda">
                           <Agenda />
                        </Route>
                        <Route exact path="/riwayat-penghargaan">
                           <RiwayatPenghargaan />
                        </Route>
                        <Route exact path="/prosedur-permohonan">
                           <ProsedurPermohonan />
                        </Route>
                        <Route exact path="/statistik-layanan">
                           <StatistikLayanan />
                        </Route>
                        <Route exact path="/informasi-publik-ppid">
                           <InformasiPublikPPID />
                        </Route>
                        <Route exact path="/prosedur-pengajuan-keberatan">
                           <ProsedurPengajuanKeberatan />
                        </Route>
                        <Route exact path="/prosedur-sengketa-informasi">
                           <ProsedurPermohonanSengketaInformasi />
                        </Route>
                        <Route exact path="/informasi-publik-wajib-berkala">
                           {/* <InfoWajibBerkala /> */}
                           <InfoWajibBerkalaNew />
                        </Route>
                        {/* <Route exact path="/informasi-publik-wajib-berkala-new">
                           <InfoWajibBerkalaNew />
                        </Route> */}
                        <Route exact path="/wajib-serta-merta">
                           <InfoSertaMerta />
                        </Route>
                        <Route exact path="/detail-wajib-serta-merta">
                           <DetailInfoSertaMerta />
                        </Route>
                        {/* <Route exact path="/tersedia-setiap-saat">
                           <SetiapSaat />
                        </Route> */}

                        <Route exact path="/tersedia-setiap-saat">
                           {/* <SetiapSaat /> */}
                           <InfoSetiapSaat2 />
                        </Route>
                        <Route path="/publikasi">
                           <Publikasi />
                        </Route>
                        <Route exact path="/berita">
                           <Berita />
                        </Route>
                        <Route exact path="/berita/all-berita">
                           <AllBerita />
                        </Route>
                        {/* <Route path="/detail-berita">
                                    <DetailBerita />
                                </Route> */}
                        <Route
                           path="/detail-berita/:id_berita"
                           render={({ match }) => (
                              <DetailBerita
                                 id_berita={match.params.id_berita}
                              />
                           )}
                        />
                        {/* <Route path="/berita/detail-berita">
                                    <DetailBerita />
                                </Route>
                                <Route path="/berita/all-berita/detail-berita">
                                    <DetailBerita />
                                </Route> */}
                        <Route
                           path="/berita/detail-berita/:id_berita"
                           render={({ match }) => (
                              <DetailBerita
                                 id_berita={match.params.id_berita}
                              />
                           )}
                        />
                        <Route
                           path="/berita/all-berita/detail-berita/:id_berita"
                           render={({ match }) => (
                              <DetailBerita
                                 id_berita={match.params.id_berita}
                              />
                           )}
                        />
                        <Route path="/majalah-elektronik">
                           <Majalah />
                        </Route>
                        <Route path="/laporan-tahunan-ppid">
                           <LaporanTahunan />
                        </Route>
                        <Route exact path="/artikel-opini">
                           <Artikel />
                        </Route>
                        <Route path="/artikel-opini/search-artikel">
                           <SearchArtikel />
                        </Route>
                        <Route path="/artikel-opini/detail-artikel">
                           <DetailArtikel />
                        </Route>
                        <Route exact path="/foto">
                           <FotoDocument />
                        </Route>
                        <Route path="/foto/detail-foto">
                           <DetailFoto />
                        </Route>
                        <Route exact path="/video">
                           <VideoDocument />
                        </Route>
                        <Route path="/video/detail-video">
                           <DetailVideo />
                        </Route>
                        <Route path="/aplikasi-lnsw">
                           <AplikasiLnsw />
                        </Route>
                        {/* <Route path="/penelusuran">
                                    <Penelusuran />
                                </Route> */}
                        <Route exact path="/lhv">
                           <Lhv />
                        </Route>
                        <Route exact path="/lhv/detail">
                           <DetailLhv />
                        </Route>
                        <Route exact path="/korelasi-btki">
                           <KorelasiHS />
                        </Route>
                        <Route exact path="/korelasi-btki/detail">
                           <DetailKorelasiHS />
                        </Route>
                        <Route exact path="/pib-peb">
                           <PibPeb />
                        </Route>
                        <Route exact path="/pib-peb/detail">
                           <DetailPibPeb />
                        </Route>
                        <Route exact path="/perijinan">
                           <Perijinan />
                        </Route>
                        <Route exact path="/perijinan/detail">
                           <DetailPerijinan />
                        </Route>
                        <Route exact path="/e-COO">
                           <Ecoo />
                        </Route>
                        <Route exact path="/e-COO/detail">
                           <DetailEcoo />
                        </Route>
                        <Route exact path="/rekomendasi">
                           <Rekomendasi />
                        </Route>
                        <Route exact path="/rekomendasi/detail">
                           <DetailRekom />
                        </Route>
                        <Route exact path="/nib">
                           <Nib />
                        </Route>
                        <Route exact path="/nib/detail">
                           <DetailNib />
                        </Route>
                        <Route exact path="/ntpn-simbara">
                           <NtpnSimbara />
                        </Route>
                        <Route exact path="/ntpn-simbara/detail">
                           <DetailNtpn />
                        </Route>
                        <Route exact path="/cek-kuota-trq">
                           <KuotaTRQ />
                        </Route>
                        <Route exact path="/pusat-bantuan">
                           <PusatBantuan />
                        </Route>
                        {/* <Route exact path='/pusat-bantuan/detail'>
									<SubFaq />
								</Route> */}
                        <Route
                           path="/pusat-bantuan/detail-faq/:id_kategori"
                           render={({ match }) => (
                              <SubFaq id_kategori={match.params.id_kategori} />
                           )}
                        />
                        <Route exact path="/pusat-bantuan/faq">
                           <FAQ />
                        </Route>
                        <Route exact path="/pusat-bantuan/faq/detail">
                           <FAQDetail />
                        </Route>
                        <Route exact path="/pusat-bantuan/panduan">
                           <Panduan />
                        </Route>
                        <Route exact path="/pusat-bantuan/panduan/detail">
                           <PanduanDetail />
                        </Route>
                        {/* <Route
                           path="/pusat-bantuan/panduan/detail/:id_panduan"
                           render={({ match }) => (
                              <PanduanDetail
                                 id_panduan={match.params.id_panduan}
                              />
                           )}
                        /> */}
                        {/* <Route
                           path="/pusat-bantuan/panduan/detail-panduan/:id_panduan"
                           render={({ match }) => (
                              <PanduanDetail
                                 id_panduan={match.params.id_panduan}
                              />
                           )}
                        /> */}
                        <Route exact path="/pusat-bantuan/tutorial">
                           <Tutorial />
                        </Route>
                        <Route exact path="/site-map">
                           <SiteMap />
                        </Route>
                        <Route exact path="/contact-center">
                           <ContactCenter />
                        </Route>
                        <Route exact path="/press-release">
                           <PressRelease />
                        </Route>
                        <Route path="/press-release/search-press-release">
                           <SearchPressRelease />
                        </Route>
                        {/* <Route path="/press-release/detail-press-release">
                                    <DetailPressRelease />
                                </Route> */}
                        <Route
                           path="/press-release/detail-press-release/:id_berita"
                           render={({ match }) => (
                              <DetailPressRelease
                                 id_berita={match.params.id_berita}
                              />
                           )}
                        />

                        <Route exact path="/knfp">
                           <TradeFacilitation />
                        </Route>
                        <Route exact path="/knfp/tentang">
                           <AboutKnfp />
                        </Route>
                        {/* <Route exact path="/knfp/tentang/tentang-detail">
                           <Detail />
                        </Route> */}
                        <Route exact path="/knfp/facility-repository">
                           <FacilityRepository />
                        </Route>
                        <Route exact path="/knfp/facility-detail">
                           <DetailRepo />
                        </Route>

                        <Route exact path="/knfp/berita">
                           <NewsUpdate />
                        </Route>
                        <Route exact path="/knfp/berita/all">
                           <NewsUpdateAll />
                        </Route>
                        <Route exact path="/knfp/berita/detail">
                           <NewsUpdateDetail />
                        </Route>
                        <Route exact path="/knfp/enquiry">
                           <BasicForms />
                        </Route>

                        <Route exact path="404">
                           <PageNotFound />
                        </Route>
                        <Route exact path="*">
                           <PageNotFound />
                        </Route>

                     </AnimatedSwitch>
                  </div>
                  <div className="footer-wrapper">
                     <Footer />
                  </div>
               </ScrollTop>
            </LastLocationProvider>
         </Router>
      </div>
   )
}

export default MainApp
