import React, { useState, useEffect, useHistory, useRef } from 'react'
import './detail_lhv.scss'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InputSearch } from '../../../../components/atoms'
import axios from 'axios'
import * as Api from '../../../../constants/api'
import {
   ListEmpty,
   LoadProgress,
   ListFirst,
   CardNtpn,
} from '../../../../components'

const DetailLhv = () => {
   const location = useLocation()
   var route_data = ''
   if (location.state === undefined) {
      route_data = ''
   } else {
      route_data = location.state.data
   }
   const [data_lhv, setDataLhv] = useState(route_data)

   const { t } = useTranslation()
   const [no_lhv, setLhv] = useState()
   const [is_progress, setIsProgress] = useState(false)
   const [is_progress2, setIsProgress2] = useState(false)
   const [_dataLhv, set_DataLhv] = useState([])
   const [error_message, setErrorMessage] = useState('')
   const [dataKiri, setDataKiri] = useState('')

   const searchLhv = (data) => {
      setIsProgress(true)
      setIsProgress2(true)
      let getData = data.lhv
      if (!getData) {
         getData = data
      }

      axios
         .get(`${Api.GET_NO_LHV}${getData}`, {
            headers: {
               Authorization: Api.BASIC_AUTH,
               'insw-key': Api.KNFP_AUTH_KEY,
            },
            timeout: 1000 * 10,
         })
         .then((response) => {
            setIsProgress(false)
            set_DataLhv([response.data])
         })
         .catch((error) => {
             setIsProgress(false)
            set_DataLhv([
               {
                  lhv: error.response.data.lhv,
                  status: 404,
                  message: 'Nomor LHV Tidak Valid',
                  valid: false,
               },
            ])
         })
   }

   //did mount
   useEffect(() => {
      searchLhv(data_lhv)
   }, [])

   const see_detail = (item, index) => {
      setIsProgress2(true)
      setDataKiri(item)
   }

   return (
      <div className="detail-lhv-container">
         <div className="penelusuran-content-title">
            <div className="penelusuran-content-sub-title">
               <div className="penelusuran-text-title">{t('track_lhv')}</div>
               <div className="penelusuran-input-search row justify-content-center m-0 mt-3">
                  <div className="col-md-5 mb-2">
                     <InputSearch
                        placeholder={t('enter_lhv')}
                        onChange={(e) => setLhv(e.target.value)}
                        onKeyDown={() => searchLhv(no_lhv)}
                        onClick={() => searchLhv(no_lhv)}
                        value={no_lhv}
                     />
                  </div>
               </div>
            </div>
         </div>
         <div className="detail-pibpeb-content">
            {is_progress === true ? (
               <div style={{ height: '25vw' }} className="detail-content-load">
                  <LoadProgress />
               </div>
            ) : _dataLhv.length === 0 ? (
               <div className="detail-content-load">
                  <ListEmpty message_error={error_message} />
               </div>
            ) : (
               <>
                  <div className="detail-kiri">
                     {_dataLhv.map((item, index) => (
                        <CardNtpn
                           no_izin={item.lhv}
                           onClick={() => see_detail(item, index)}
                        />
                     ))}
                  </div>
                  <div className="detail-kanan">
                     <div className="detail-atas">
                        <div className="detail-title">
                           <p className="detail-title-text">
                              {t('detail-lhv')}
                           </p>
                        </div>
                     </div>
                     <div className="detail-bawah">
                        {is_progress2 === false ? (
                           <ListFirst
                              message_error={t('atr_me_title')}
                              message_error2={t('atr_me_subtitle')}
                           />
                        ) : (
                           <>
                              <div className="detail-container">
                                 <p className="detail-judul">LHV</p>
                                 <p className="detail-hasil">
                                    {dataKiri.lhv === '' ? '-' : dataKiri.lhv}
                                 </p>
                              </div>
                              <div className="detail-container-last">
                                 <p className="detail-judul">{t('status')}</p>
                                 <p className="detail-hasil">
                                    {dataKiri.valid === true
                                       ? 'Nomor LHV Anda Valid'
                                       : dataKiri.message}
                                 </p>
                              </div>
                           </>
                        )}
                     </div>
                  </div>
               </>
            )}
         </div>
      </div>
   )
}

export default DetailLhv
