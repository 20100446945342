import React from 'react';
import './input_capsule.scss';

const InputCapsule = ({ image_icon, ...rest}) => {
    return (
        <div className="input-round-capsule">
            <input className="input-round-field" {...rest} />
        </div>
    )
}

export default InputCapsule;
