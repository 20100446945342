import React from 'react';
import './listkontak.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ListKontak = ({gambar, deskripsi, judul, header}) => {
    return (
        <div className="list-item-kontak">
            <div className="list-item-wrapper">
                {/* <img src={gambar} alt={deskripsi} className="gambar-kontak" /> */}
                <LazyLoadImage
                    alt={deskripsi}
                    effect="blur"
                    className="gambar-kontak"
                    src={gambar}
                />
                <div className="content-title">
                    <p className="title">{judul}</p>
                    <p className="subtitle">{header}</p>
                </div>
            </div>
        </div>
    )
}

export default ListKontak;
