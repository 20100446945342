/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import './search_artikel.scss';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from 'html-react-parser';
import GridList from 'react-gridlist';
import ReactPaginate from 'react-paginate';
import { useLocation, useHistory } from 'react-router-dom';
import Ripples from 'react-ripples';

//component
import * as Api from '../../../../constants/api';
import { LoadProgress, ListEmpty, InputSearch } from '../../../../components';
import { NewsDefaultPotrait } from '../../../../assets';

const SearchArtikel = () => {
	// variable constant
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const [is_progress, setisProgress] = useState(true);
	const [list_artikel, setListArtikel] = useState([]);
	const [list_artikel_slice, setListArtikelSlice] = useState([]);
	const [error_message, setErrorMessage] = useState('');

	const search_artikel = useRef('');
	const [search_artikel_data, setSearchArtikelData] = useState('');

	const perPage = 8;
	const [pageCount, setPageCount] = useState('');

	//style
	let styles = {
		container: {
			flex: 1,
		},
	};

	// did mount
	useEffect(() => {
		setTimeout(() => {
			if (location.state.search_artikel !== '') {
				search_artikel.current = location.state.search_artikel;
				setSearchArtikelData(location.state.search_artikel);
				_search_data_artikel();
			} else {
				_get_data_all_artikel();
			}
		}, 500);
	}, []);

	//function
	const _get_data_all_artikel = () => {
		setisProgress(true);
		const url =
			Api.ALL_ARTIKEL +
			'lang=' +
			localStorage.getItem('language').toUpperCase();
		// console.log(url);
		axios({
			method: 'get',
			url: url,
			headers: { Authorization: Api.BASIC_AUTH },
			timeout: 1000 * 10, // Wait for 10 seconds
		})
			.then((response) => {
				setisProgress(false);
				if (response.data.code === '01') {
					if (response.data.data.length === 0) {
						setListArtikel([]);
						setListArtikelSlice([]);
						setErrorMessage(t('empty_list'));
					} else {
						let list_artikel_data = response.data.data;
						// console.log(list_artikel_data);
						setListArtikel(list_artikel_data);
						const slice = list_artikel_data.slice(0, perPage);
						setPageCount(list_artikel_data.length / perPage);
						setListArtikelSlice(slice);
					}
				} else {
					setListArtikel([]);
					setListArtikelSlice([]);
					setErrorMessage(t('empty_list'));
				}
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
				setisProgress(false);
				setListArtikel([]);
				setListArtikelSlice([]);
				setErrorMessage(t('error_message'));
			});
	};

	const _search_data_artikel = () => {
		setisProgress(true);
		// console.log('search_artikel')
		// console.log(search_artikel.current)
		const url =
			Api.SEARCH_ARTIKEL +
			'lang=' +
			localStorage.getItem('language').toUpperCase() +
			'&keyword=' +
			filter_input(search_artikel.current);
		// console.log(url);
		axios({
			method: 'get',
			url: url,
			headers: { Authorization: Api.BASIC_AUTH },
			timeout: 1000 * 10, // Wait for 10 seconds
		})
			.then((response) => {
				setisProgress(false);
				if (response.data.code === '01') {
					if (response.data.data.length === 0) {
						setListArtikel([]);
						setListArtikelSlice([]);
						setErrorMessage(t('empty_list'));
					} else {
						let list_artikel_data = response.data.data;
						// console.log(list_artikel_data);
						setListArtikel(list_artikel_data);
						const slice = list_artikel_data.slice(0, perPage);
						setPageCount(list_artikel_data.length / perPage);
						setListArtikelSlice(slice);
					}
				} else {
					setListArtikel([]);
					setListArtikelSlice([]);
					setErrorMessage(t('empty_list'));
				}
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
				setisProgress(false);
				setListArtikel([]);
				setListArtikelSlice([]);
				setErrorMessage(t('error_message'));
			});
	};

	const filter_input = (input_text) => {
		let filter_text = input_text.replace(/\'|\"|\--/g, '');
		return filter_text;
	};

	const CallImage = (image_url) => {
		var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
		// console.log('url_image search');
		// console.log(url_image);
		return url_image;
	};

	const _click_view_more = (id_artikel_click) => {
		// history.push('/berita/all-berita/detail-berita', { id_berita :  id_artikel_click});
		history.push('/berita/all-berita/detail-berita/' + id_artikel_click);
	};

	const getGridGap = (elementWidth, windowHeight) =>
		elementWidth > 720 && windowHeight > 480 ? 10 : 5;

	const getColumnCount = (elementWidth) => Math.floor(elementWidth / 300); //pembagian column (dibagi untuk 4 column)

	const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.1);

	const getItemData = (list_peraturan, columnWidth) => {
		return {
			key: list_peraturan.id,
		};
	};
	const handlePageClick = (e) => {
		const selectedPage = e.selected;
		const offset = selectedPage * perPage;

		loadMoreData(offset, offset + perPage);
	};

	const loadMoreData = (of, op) => {
		const data = list_artikel;

		const slice = data.slice(of, op);
		setPageCount(data.length / perPage);
		setListArtikelSlice(slice);
	};

	return (
		<div className='search-artikel-content'>
			<div className='col-md-6'>
				<InputSearch
					placeholder={t('search_article_here')}
					value={search_artikel_data}
					onChange={(e) => {
						search_artikel.current = e.target.value;
						setSearchArtikelData(e.target.value);
					}}
					onKeyDown={() => {
						if (search_artikel.current === '') {
							_get_data_all_artikel();
						} else {
							_search_data_artikel();
						}
					}}
					onClick={() => {
						if (search_artikel.current === '') {
							_get_data_all_artikel();
						} else {
							_search_data_artikel();
						}
					}}
				/>
			</div>
			<div className='berita-line'></div>
			<div className='berita-terpopuler'>
				<div className='berita-terpopuler-title'>{t('all-berita')}</div>
				<div style={{ display: 'flex', marginTop: '1vw' }}>
					{is_progress === true ? (
						<div style={{ height: '25vw' }} className='search-content-load'>
							<LoadProgress />
						</div>
					) : list_artikel.length === 0 ? (
						<div className='search-content-load'>
							<ListEmpty message_error={error_message} />
						</div>
					) : (
						<div style={{ flex: 1 }}>
							<div style={styles.container}>
								<GridList
									items={list_artikel_slice}
									getGridGap={getGridGap}
									getColumnCount={getColumnCount}
									getWindowMargin={getWindowMargin}
									getItemData={getItemData}
									renderItem={(item) => {
										return (
											<Ripples onClick={() => _click_view_more(item.id_berita)}>
												<div className='berita-content-item-grid'>
													<LazyLoadImage
														effect='blur'
														className='berita-content-item-grid-image'
														src={
															item.file !== null
																? CallImage(item.file)
																: NewsDefaultPotrait
														}
													/>
													{/* <img className="berita-content-item-grid-image" src={CallImage(item.file)} /> */}
													<div className='berita-content-item-grid-title max-line-three'>
														{item.header}
													</div>
													<div className='berita-content-item-grid-desc max-line-four'>
														{parse(item.cuplikan)}
													</div>
												</div>
											</Ripples>
										);
									}}
								/>
							</div>
							<div className='berita-paging-content-list'>
								<ReactPaginate
									previousLabel={t('previous')}
									nextLabel={t('next')}
									breakLabel={'...'}
									breakClassName={'break-me'}
									pageCount={pageCount}
									marginPagesDisplayed={2}
									pageRangeDisplayed={5}
									onPageChange={handlePageClick.bind(this)}
									containerClassName={'pagination'}
									subContainerClassName={'pages pagination'}
									activeClassName={'active'}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default SearchArtikel;
