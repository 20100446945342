/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Ripples from 'react-ripples'
import './cardintr.scss';

const CardIntr = ({image, title, message, onClick}) => {
    return (
        <Ripples className="card-item-intr" onClick={onClick}>
            <div>
                <img src={image} className="image-card" />
                <div className="content-title">
                    <p className="title">{title}</p>
                    <p className="message">{message}</p>
                </div>
            </div>
        </Ripples>
    )
}

export default CardIntr;
