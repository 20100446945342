import React, { useState } from 'react';
import './sitemapcollapse.scss';
import {Collapse} from 'react-collapse';
import { ICBlueCollapseDown, ICBlueCollapseUp } from '../../../assets';
import { useTranslation } from 'react-i18next';
import * as Api from '../../../constants/api';

const CollapseComp = ({data}) => {
    const [buka, setBuka] = useState(false);
    const { t } = useTranslation();

    const klikCollapse = buka => {
        if (buka === false) {
            setBuka(true);
        } else {
            setBuka(false);
        }
    }

    const openMap = (item) => {
        localStorage.setItem('menu_select', item.id_menu);
        // window.open(item, "_blank") //to open new page
        window.open(item.url, "_blank") //to open new page
    }

    const TypeColl = () => {
            return (
                <>
                <div className="collapse-header" onClick={() => klikCollapse(buka)} >
                    {
                        data.child.length !== 0 ?
                        <div className="collapse-text-head">{data.menu}</div>
                        :
                        <div className="collapse-text-head-link" onClick={() => openMap(data)}>{data.menu}</div>
                    }
                    <div className="collapse-icon">
                        {
                            data.child.length !== 0 ?
                            <img src={buka === true ? ICBlueCollapseUp : ICBlueCollapseDown} alt ="icon-collapse" />
                            :
                            null
                        }
                    </div>
                </div>
                <div className="collapse-body">
                    <Collapse isOpened={buka}>
                        {
                            data.child.length !== 0 ?
                            data.child.map((item_child) =>
                            <>
                                <ul className="coll-bull">
                                    <li className="collapse-text" onClick={() => openMap(item_child)}>{item_child.menu}</li>
                                </ul>
                            </>
                        )
                        :
                            null
                    }
                    </Collapse>
                </div>
                </>
            )
    }

    return (
        <div className="sitemap-collapse-container">
            <TypeColl />
        </div>
    )
}

export default CollapseComp;
