import React from 'react'
import axios from 'axios'
import * as Api from '../../../constants/api'
import knfpNews from '../utils/knfpNews'
import defaultNewsThumbnail from '../../../assets/image/Logo_LNSW.png'
import { ICCalendarGrayNews } from '../../../assets'
import { getLanguageStorageUpperCaseKNFP } from '../../../constants/storage'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

export const KnfpNewsSection = () => {
   const { t } = useTranslation()
   const history = useHistory()
   const [news, setNews] = React.useState([])

   const callImage = React.useCallback((image_url) => {
      const url_image = `https://api.insw.go.id/assets/upload/knfp/` + image_url
      return url_image
   }, [])

   const handleDetailNews = React.useCallback((id_berita) => {
      history.push({
         pathname: '/knfp/berita/detail',
         state: { id_berita },
      })
   }, [history])

   const getNews = React.useCallback(async () => {
      try {
         const url =
            Api.KNFP +
            'news/web?kategori=top&lang=' +
            getLanguageStorageUpperCaseKNFP()
         const response = await axios({
            method: 'get',
            url: url,
            headers: {
               'insw-key': Api.KNFP_AUTH_KEY,
               Authorization: Api.BASIC_AUTH,
            },
            timeout: 1000 * 10, // Wait for 10 seconds
         })

         setNews(response.data.result.data)
      } catch (error) {
         console.log('error retrieve news: ', error)
      }
   }, [])

   React.useEffect(() => {
      getNews()
   }, [getNews])

   return (
      <>
         <p class="kementrian-title">{t('Berita Terkini')}</p>
         <div 
            className="latest-news-container p-2"
            style={{
               overflowX:"auto"
            }}
         >
            {news?.slice(0, 3).map((item) => (
               <div
                  key={item.id_berita}
                  class="latest-news-card"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDetailNews(item.id_berita)}
               >
                  {/* <div> */}

                  <img
                     alt="news-thumbnail"
                     className="news-thumbnail"
                     src={
                        item.file ? callImage(item.file) : defaultNewsThumbnail
                     }
                  />
                  <div class="card-title" style={{ marginTop: '10px' }}>
                     <h6
                        style={{
                           width: '290px',
                           whiteSpace: 'nowrap',
                           overflow: 'hidden',
                           textOverflow: 'ellipsis',
                        }}
                     >
                        {item.header}
                     </h6>
                     <div
                        style={{
                           display: 'flex',
                           alignItems: 'center',
                           gap: '5px',
                           width: '100%',
                        }}
                     >
                        <img alt="calendar-icon" src={ICCalendarGrayNews} />
                        <p style={{ margin: 0 }}>
                           {moment(item.waktu_rekam).format('DD MMMM yyyy')}
                        </p>
                     </div>
                  </div>
               </div>
            ))}
         </div>
      </>
   )
}
