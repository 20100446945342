/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import "./berita.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import parse from "html-react-parser";
import GridList from "react-gridlist";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Ripples from "react-ripples";
import { useHistory } from "react-router-dom";
import Footer from "../../KNFP/Footer/index";

//component
import * as Api from "../../../constants/api";
import {
  LoadProgress,
  ListEmpty,
  InputSearch,
  ListBerita,
} from "../../../components";
import { getLanguageStorageUpperCaseKNFP } from "../../../constants/storage";
import { ICCalendarGrayNews } from "../../../assets";
import knfpNews from "../utils/knfpNews";

const NewsUpdate = () => {
  // variable constant
  const { t } = useTranslation();
  const history = useHistory();
  const [is_progress, setisProgress] = useState(true);
  const [list_berita_terkini, setListBeritaTerkini] = useState([]);
  const [cover_berita_terkini, setCoverBeritaTerkini] = useState({});
  const [error_message, setErrorMessage] = useState("");

  const id_berita = useRef("");
  // did mount
  useEffect(() => {
    _get_data_berita();
  }, []);

  //function
  const _get_data_berita = () => {
    setisProgress(true);
    const url =
      Api.KNFP +
      "news/web?&lang=" +
      getLanguageStorageUpperCaseKNFP();
    axios({
      method: "get",
      url: url,
      headers: { 'insw-key': Api.KNFP_AUTH_KEY, 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then((response) => {

        setisProgress(false);
        if (response.data.result.code === 200) {
          if (response.data.result.data.length === 0) {
            setListBeritaTerkini([]);
            setErrorMessage(t("empty_list"));
          } else {
            let list_berita_terkini = response.data.result.data;
            console.log(list_berita_terkini)
            // console.log(list_berita_terkini);
            // console.log(list_berita_terkini[0]);
            // console.log(list_berita_terkini.slice(1))
            if (list_berita_terkini.length === 1) {
              setCoverBeritaTerkini(list_berita_terkini[0]);
              id_berita.current = list_berita_terkini[0].id_berita;
              setListBeritaTerkini(list_berita_terkini);
            } else {
              setCoverBeritaTerkini(list_berita_terkini[0]);
              id_berita.current = list_berita_terkini[0].id_berita;
              setListBeritaTerkini(list_berita_terkini.slice(1));
            }
          }
        } else {
          setListBeritaTerkini([]);
          setErrorMessage(t("empty_list"));
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setisProgress(false);
        setListBeritaTerkini([]);
        setErrorMessage(t("error_message"));
      });
  };

  const CallImage = (image_url) => {
    const url_image = `https://api.insw.go.id/assets/upload/knfp/` + image_url;
    return url_image;
  };



  const _click_more_news = () => {
    history.push("/knfp/berita/all");
  };

  const _click_view_more = (id_berita_click) => {
    history.push({
      pathname: "/knfp/berita/detail",
      state: { id_berita: id_berita_click },
    });
  };

  return (
    <>
      <div className="berita-content">
        <div className="row">
          <div className="berita-title col-md-12 p-0">
            {t("knfp_latest_news")}
          </div>

          <div className="col-md-12 p-0">
            {is_progress === true ? (
              <div
                style={{ height: "25vw" }}
                className="search-content-load col-md-12"
              >
                <LoadProgress />
              </div>
            ) : list_berita_terkini.length === 0 ? (
              <div className="search-content-load col-md-12">
                <ListEmpty message_error={error_message} />
              </div>
            ) : (
              <div className="row">
                <div className="berita-terkini-cover col-md-6 col-sm-12">
                  <img
                    className="berita-terkini-cover-image"
                    src={CallImage(cover_berita_terkini.file)}
                  />
                  <div className="berita-terkini-cover-title">
                    {cover_berita_terkini.header}
                  </div>
                  <div className="berita-terkini-cover-date">
                    <img
                      src={ICCalendarGrayNews}
                      style={{ marginRight: "0.7vw" }}
                    />
                    {moment(cover_berita_terkini.waktu_rekam).format(
                      "DD MMMM YYYY"
                    ) +
                      " , " +
                      t("by") +
                      " " +
                      cover_berita_terkini.id_user}

                  </div>
                  <div className="berita-terkini-cover-desc">
                    {parse(cover_berita_terkini.cuplikan)}
                  </div>
                  <div
                    onClick={() => _click_view_more(id_berita.current)}
                    className="berita-terkini-cover-more"
                  >
                    {t("knfp_read_more")}
                  </div>
                </div>

                <div className="berita-terkini-list col-md-6 col-sm-12 p-0">
                  {list_berita_terkini.slice(0, 5).map((item) => (
                    <ListBerita
                      onClick={() => _click_view_more(item.id_berita)}
                      gambar={CallImage(item.file)}
                      judul={item.header}
                      header={item.cuplikan}
                    />
                  ))}
                  <div
                    onClick={() => _click_more_news()}
                    className="berita-terkini-list-more"
                  >
                    {t("knfp_see_all_news")}
                  </div>
                </div>
              </div>
            )}


            {/* dummy */}
            {/* <div class="container-latest-news">
              {
                knfpNews.map(news => {
                  return (
                    <div class="news-container">
                      <h1>{news.title}</h1>
                      <p>{news.postdate}</p>

                      {
                        news.contents.map(content => {
                          return <p>{content}</p>
                        })
                      }

                      <div className="news-resources-container">
                        {
                          news.images.map(image => {
                            return (
                              <img 
                                  alt="news-resources" 
                                  className="news-resources" 
                                  src={image} 
                              />
                            )
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsUpdate;
