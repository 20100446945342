import React, { useEffect, useState } from 'react';
import { 
  ICSimulasiExport, 
  ICPeraturan, 
  ICIntrExporImpor,
  ICIntrPeraturan,
  ICIntrAseanTrade,
  ICIntrReferensi,
} from '../../../assets';

import './carousel_content.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CardIntr from '../CardIntr';
import { useHistory } from 'react-router-dom';

const itemsID = [
  {
    data: [
      {
        image: ICIntrExporImpor,
        title: 'Simulasi Ekspor Impor',
        message: "Simulasi Perhitungan Biaya atas Ekspor Impor Barang",
        path: '/intr/simulasi',
      },
      {
        image: ICIntrPeraturan,
        title: 'Peraturan',
        message: "Peraturan - peraturan dari berbagai macam tematik pilihan",
        path: '/intr/peraturan',
      },
      {
        image: ICIntrAseanTrade,
        title: 'ATR Indonesia',
        message: "Bagian dari Trade Repository di ASEAN yang dikelola NTR Indonesia berisikan informasi perdagangan Indonesia",
        path: '/intr/asean-trade-repository',
      },
      {
        image: ICIntrReferensi,
        title: 'Referensi',
        message: "Referensi - referensi dari berbagai macam jenis",
        path: '/intr/referensi',
      },
    ],
  },
];

const itemsEN = [
  {
    data: [
      {
        image: ICIntrExporImpor,
        title: 'Export Import Simulation',
        message: "Simulation of Cost Calculation for Export and Import of Goods",
        path: '/intr/simulasi',
      },
      {
        image: ICIntrPeraturan,
        title: 'Regulations',
        message: "Regulations covering various thematic options",
        path: '/intr/peraturan',
      },
      {
        image: ICIntrAseanTrade,
        title: 'Indonesia ASEAN Trade Repository',
        message: "Part of the ASEAN Trade Repository managed by NTR Indonesia containing information on Indonesia's trade",
        path: '/intr/asean-trade-repository',
      },
      {
        image: ICIntrReferensi,
        title: 'References',
        message: "References of various types",
        path: '/intr/referensi',
      },
    ],
  },
];

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 1,
    partialVisibilityGutter: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    items: 1
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464
    },
    items: 1
  }
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li
      className={active ? "active-caro" : "inactive-caro"}
      onClick={() => onClick()}
    >
    </li>
  );
};

const CarouselContentIntr = (props) => {
  const [list_partial, setListPartial] = useState([]);

  const history = useHistory();
  const width = window.innerWidth;

  // did mount
  // useEffect(() => {
  //   dataPartial(items[0].data);
  // }, []);

  useEffect(() => {
    if (localStorage.getItem("language") === "id") {
      dataPartial(itemsID[0].data);
    } else {
      dataPartial(itemsEN[0].data);
    }
  }, [localStorage.getItem("language")]);

  const dataPartial = (item) => {
    let content_partial = [];
    let content = [];
    var count = 1;
    var jmlData = 4;
    if (width < 600) {
      jmlData = 1;
    }

    for (let i = 0; i < item.length; i++) {
        // console.log(count);
        content_partial.push(item[i]);
        // console.log(item[i].title);
        if (count === jmlData) {
            let data = {
                data : content_partial
            }
            content_partial = [];
            content.push(data);    
            count = 1;
        } else {
          // console.log(item.length - i);
          if (item.length - i === 1) {
            let data = {
                data : content_partial
            }
            content_partial = [];
            content.push(data);
            count = count + 1 
          } else {
            count = count + 1 
          }
        }
    }
    // console.log('content intr');
    // console.log(content);

    setListPartial(content);
  }

  const ClickCardIntr = item => {
    // console.log('click react intr')
    if (item === '/intr/peraturan') {
      history.push(item, { search_peraturan : "" });
    } else {
      history.push(item);
    }
  };

  const slides = list_partial.map((item) => {
    return (
      <div className="custom-tag-intr-item">
        <div className="intr-sub-menu">
          {item.data.map((item_data) => 
            <CardIntr image={item_data.image} title={item_data.title} message={item_data.message} onClick={() => ClickCardIntr(item_data.path)} />
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="custom-tag-intr">
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={3000}
        centerMode={false}
        customDot={<CustomDot />}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        containerClass={{width: '100%'}}
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable>
        {slides}           
      </Carousel>
    </div>
  );
}

export default CarouselContentIntr;