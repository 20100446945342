import React, { useEffect, useState } from 'react';
import './carousel_statistik.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RadarStat from '../RadarStat';
import axios from 'axios';
import * as Api from '../../../constants/api';
import moment from 'moment';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 1,
    partialVisibilityGutter: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    items: 1
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464
    },
    items: 1
  }
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li
      className={active ? "active-caro" : "inactive-caro"}
      onClick={() => onClick()}
    >
    </li>
  );
};

const CarouselStatistik = (props) => {
  const [list_partial, setListPartial] = useState([]);
  const width = window.innerWidth;

  const [data_stats, setDataStats] = useState(
    [
      {
        nilai: '0', //15.287
        title: 'Dokumen Pabean yang diproses',
        type: 'nominal',
        bulan: '-',
        tahun: '-',
        baris: 1,
        proses: true
      },
      {
        nilai: '0.0', //2.57
        title: 'Dwelling Time',
        type: 'index',
        bulan: '-',
        tahun: '-',
        baris: 1,
        proses: true
      },
      {
        nilai: '0', //417.756
        title: 'Perizinan yang diproses',
        type: 'nominal',
        bulan: '-',
        tahun: '-',
        baris: 1,
        proses: true
      },
      {
        nilai: '0', //187.634
        title: 'Jumlah Visitor',
        type: 'nominal',
        period: '-',
        baris: 1,
        proses: true
      },
      {
        nilai: '0.0', //4.20
        title: 'Logistic Performance Index',
        type: 'index',
        bulan: '-',
        tahun: '-',
        baris: 2,
        proses: true
      },
      {
        nilai: '0.0', //69.6
        title: 'Ease of Doing Business',
        type: 'index',
        bulan: '-',
        tahun: '-',
        baris: 2,
        proses: true
      },
      {
        nilai: '0.0', //4.20
        title: 'Global Competitiveness Index',
        type: 'index',
        bulan: '-',
        tahun: '-',
        baris: 2,
        proses: true
      }
    ]
  );

  // did mount
  useEffect(() => {
    _get_data_visit();
    _get_data_dt();
    _get_data_lpi();
    _get_data_edb();
    _get_data_gci();
    _get_data_totDok();
    _get_data_totIzin();
    dataPartial(data_stats);
  }, []);

  const _get_data_totIzin = () => {
    const url = Api.IP_LNSW_CMS + 'totalPerijinan';
    // console.log('total izin',url);
    let dataTotIzin = data_stats[2];
    dataTotIzin.proses = true;
    axios({
        method: 'get',
        url: url,
        headers: { 'Authorization': Api.BASIC_AUTH },
        timeout: 1000 * 10, // Wait for 10 seconds
    })
        .then(response => {
            dataTotIzin.proses = false;
            // console.log('izin data', response.data);
            // console.log('izin status', response.status);
            if (response.status === 200) {
                let jmlTotIzin = response.data.data[0].dt;
                let blnTotIzin = response.data.data[0].bulan;
                let thnTotIzin = response.data.data[0].tahun;

                dataTotIzin.nilai = jmlTotIzin;
                dataTotIzin.bulan = blnTotIzin;
                dataTotIzin.tahun = thnTotIzin;

                setDataStats(dataTotIzin);
            } else {
                setDataStats([]);
            }
        })
        .catch(error => {
          setDataStats([]);
        });
  }

  const _get_data_totDok = () => {
    const url = Api.IP_LNSW_CMS + 'totalDokumen';
    // console.log('statistik pabean url', url);
    axios({
        method: 'get',
        url: url,
        headers: { 'Authorization': Api.BASIC_AUTH },
        timeout: 1000 * 10, // Wait for 10 seconds
    })
        .then(response => {
            // console.log('statistik pabean 1', response.data);
            // console.log(response.status);
            if (response.status === 200) {
                let jmlTotDok = response.data.data[0].dt;
                let blnTotDok = response.data.data[0].bulan;
                let thnTotDok = response.data.data[0].tahun;
                let dataTotDok = data_stats;
                for (let i = 0; i < dataTotDok.length; i++) {
                  let totDok = dataTotDok[i].title;
                  if (totDok === 'Dokumen Pabean yang diproses') {
                    dataTotDok[i].nilai = jmlTotDok;
                    dataTotDok[i].bulan = blnTotDok;
                    dataTotDok[i].tahun = thnTotDok;
                    dataTotDok[i].proses = false;
                  }
                }
                // console.log('statistik pabean', dataTotDok);
                setDataStats(dataTotDok);
            } else {
                setDataStats([]);
            }
        })
        .catch(error => {
          setDataStats([]);
        });
  }

  const _get_data_gci = () => {
    const url = Api.IP_LNSW_CMS + 'gci';
    // console.log(url);
    axios({
        method: 'get',
        url: url,
        headers: { 'Authorization': Api.BASIC_AUTH },
        timeout: 1000 * 10, // Wait for 10 seconds
    })
        .then(response => {
            // console.log(response.data);
            // console.log(response.status);
            if (response.status === 200) {
                let jmlGci = response.data.data[0].dt;
                let blnGci = response.data.data[0].bulan;
                let thnGci = response.data.data[0].tahun;
                let dataGci = data_stats;
                for (let i = 0; i < dataGci.length; i++) {
                  let gci = dataGci[i].title;
                  if (gci === 'Global Competitiveness Index') {
                    dataGci[i].nilai = jmlGci;
                    dataGci[i].bulan = blnGci;
                    dataGci[i].tahun = thnGci;
                    dataGci[i].proses = false;
                  }
                }
                setDataStats(dataGci);
            } else {
                setDataStats([]);
            }
        })
        .catch(error => {
          setDataStats([]);
        });
  }

  const _get_data_edb = () => {
    const url = Api.IP_LNSW_CMS + 'edb';
    // console.log(url);
    axios({
        method: 'get',
        url: url,
        headers: { 'Authorization': Api.BASIC_AUTH },
        timeout: 1000 * 10, // Wait for 10 seconds
    })
        .then(response => {
            // console.log(response.data);
            // console.log(response.status);
            if (response.status === 200) {
                let jmlEdb = response.data.data[0].dt;
                let blnEdb = response.data.data[0].bulan;
                let thnEdb = response.data.data[0].tahun;
                let dataEdb = data_stats;
                for (let i = 0; i < dataEdb.length; i++) {
                  let easeDb = dataEdb[i].title;
                  if (easeDb === 'Ease of Doing Business') {
                    dataEdb[i].nilai = jmlEdb;
                    dataEdb[i].bulan = blnEdb;
                    dataEdb[i].tahun = thnEdb;
                    dataEdb[i].proses = false;
                  }
                }
                setDataStats(dataEdb);
            } else {
                setDataStats([]);
            }
        })
        .catch(error => {
          setDataStats([]);
        });
  }

  const _get_data_lpi = () => {
    const url = Api.IP_LNSW_CMS + 'lpi';
    // console.log(url);
    axios({
        method: 'get',
        url: url,
        headers: { 'Authorization': Api.BASIC_AUTH },
        timeout: 1000 * 10, // Wait for 10 seconds
    })
        .then(response => {
            // console.log(response.data);
            // console.log(response.status);
            if (response.status === 200) {
                let jmlLpi = response.data.data[0].dt;
                let blnLpi = response.data.data[0].bulan;
                let thnLpi = response.data.data[0].tahun;
                let dataLpi = data_stats;
                for (let i = 0; i < dataLpi.length; i++) {
                  let logisticPi = dataLpi[i].title;
                  if (logisticPi === 'Logistic Performance Index') {
                    dataLpi[i].nilai = jmlLpi;
                    dataLpi[i].bulan = blnLpi;
                    dataLpi[i].tahun = thnLpi;
                    dataLpi[i].proses = false;
                  }
                }
                setDataStats(dataLpi);
            } else {
                setDataStats([]);
            }
        })
        .catch(error => {
          setDataStats([]);
        });
  }

  const _get_data_dt = () => {
    const url = Api.IP_LNSW_CMS + 'dwellingTime';
    // console.log(url);
    axios({
        method: 'get',
        url: url,
        headers: { 'Authorization': Api.BASIC_AUTH },
        timeout: 1000 * 10, // Wait for 10 seconds
    })
        .then(response => {
            // console.log(response.data);
            // console.log(response.status);
            if (response.status === 200) {
                let jmlDt = response.data.data[0].dt;
                let blnDt = response.data.data[0].bulan;
                let thnDt = response.data.data[0].tahun;
                let dataDt = data_stats;
                for (let i = 0; i < dataDt.length; i++) {
                  let dwellingTime = dataDt[i].title;
                  if (dwellingTime === 'Dwelling Time') {
                    dataDt[i].nilai = jmlDt;
                    dataDt[i].bulan = blnDt;
                    dataDt[i].tahun = thnDt;
                    dataDt[i].proses = false;
                  }
                }
                setDataStats(dataDt);
            } else {
                setDataStats([]);
            }
        })
        .catch(error => {
          setDataStats([]);
        });
  }

  const _get_data_visit = () => {
    const url = Api.IP_LNSW_CMS + '/visit';
    let dateVisit = new Date();
    // console.log(url);
    axios({
        method: 'get',
        url: url,
        headers: { 'Authorization': Api.BASIC_AUTH },
        timeout: 1000 * 10, // Wait for 10 seconds
    })
        .then(response => {
            // console.log(response.data);
            // console.log(response.status);
            if (response.status === 200) {
                let jmlVisit = response.data.data;
                // console.log('data visit', jmlVisit);
                let dataVisist = data_stats;
                for (let i = 0; i < dataVisist.length; i++) {
                  let visit = dataVisist[i].title;
                  if (visit === 'Jumlah Visitor') {
                    dataVisist[i].nilai = jmlVisit;
                    // dataVisist[i].period = moment(dateVisit).format('DD MMMM YYYY');
                    dataVisist[i].period = moment(dateVisit).format('MMMM - YYYY');
                    dataVisist[i].proses = false;
                  }
                }
                setDataStats(dataVisist);
            } else {
                setDataStats([]);
            }
        })
        .catch(error => {
          setDataStats([]);
        });
  }

  const dataPartial = (item) => {
    console.log('statistik', item);
    let content_partial = [];
    let content = [];
    var count = 1;
    var jmlData = 4;
    if (width < 600) {
      jmlData = 1;
    }

    for (let i = 0; i < item.length; i++) {
        console.log('statistik count', count);
        content_partial.push(item[i]);
        // console.log(item[i].title);
        if (count === jmlData) {
          if (item[i].baris === 1) {
            let dataID = {
              judul: 'Statistik Lembaga National Single Window',
              data : content_partial
            }
            let dataEN = {
              judul: 'National Single Window Statistics',
              data: content_partial
            };
            content_partial = [];
            if (localStorage.getItem("language") === "id") {
              content.push(dataID); 
            } else {
              content.push(dataEN); 
            } 
            count = 1;
            // console.log('satu');
          } else {
            let dataID = {
              judul: 'Statistik Performa Indonesia',
              data : content_partial
            }
            let dataEN = {
              judul: 'Performance Statistics Indonesia',
              data : content_partial
            }
            content_partial = [];
            if (localStorage.getItem("language") === "id") {
              content.push(dataID); 
            } else {
              content.push(dataEN); 
            }
            count = 1;
          }
            
        } else {
          // console.log(item.length - i);
          if (item.length - i === 1) {
            let dataID = {
              judul: 'Statistik Performa Indonesia',
              data : content_partial
            }
            let dataEN = {
              judul: 'Performance Statistics Indonesia',
              data : content_partial
            }
            content_partial = [];
            if (localStorage.getItem("language") === "id") {
              content.push(dataID); 
            } else {
              content.push(dataEN); 
            }
            count = count + 1 
          } else {
            count = count + 1 
          }
          // console.log('dua');
        }
    }
    console.log('statistik content', content);

    setListPartial(content);
  }

  const slides = list_partial.map((item) => {
    return (
      <div className="home-statistik-content">
        <p className="statistik-title">{item.judul}</p>
        <div className="statistik-radar">
          {
            item.data.map((item_data) =>
            <RadarStat
              stat={item_data.nilai}
              title={item_data.title}
              type={item_data.type}
              bulan={item_data.bulan}
              tahun={item_data.tahun}
              period={item_data.period}
              proses={item_data.proses}
            />
          )}
        </div>
      </div>
    );
  });

  return (
    <div>
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay
        autoPlaySpeed={5000}
        centerMode={false}
        customDot={<CustomDot />}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        containerClass={{width: '100%'}}
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        showDots
        sliderClass=""
        slidesToSlide={1}
        swipeable>
        {slides}         
      </Carousel>
    </div>
  );
}

export default CarouselStatistik;