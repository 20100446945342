import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../../../constants/api';
import '../index.scss';
import parse from 'html-react-parser';
import { Link, useLocation } from 'react-router-dom';

const DetailInfoSertaMerta = (props) => {
    const [data, setData] = useState([]);
    const location = useLocation();

    // console.log('daskdfmklf')

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }

        setData(location.state)
    }, []);

    const CallImage = (image_url) => {
        // var replace_url = image_url.substring(1);
        var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        // console.log(url_image);
        return url_image;
    }

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    return (
        <>
            <div className="serta-merta row m-0">
                <div className="col-md-12">
                    <div className="sm-content text-center">
                        <h3 className="sm-title">
                            Informasi Publik Wajib Serta Merta
                        </h3>
                    </div>
                </div>

                <div className="text-left sm-content-list">
                    <div className="col-md-12 mb-5">
                        <div className="titles-detail">
                            {parseHtml(data.header)}
                        </div>
                        <div className="content-detail">
                            {parseHtml(data.konten)}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default DetailInfoSertaMerta;
