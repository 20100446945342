import React from 'react';
import './card_perizinan.scss';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const CardPerizinan = ({no_izin, onClick, jenis, judul}) => {
    const { t } = useTranslation();
    return (
        <div className="izin-container">
            <p className="izin-title">{judul === 'impor' ? t('import') : t('export')}</p>
            <div className="izin-content">
                <div className="izin-kiri">
                    <p className="izin-subtitle">{jenis === "perizinan" ? t('no_perijinan') : t('no_rekom')}</p>
                    <p className="izin-no-izin">{no_izin}</p>
                </div>
                <div className="izin-kanan">
                    <Button className="button-lihat" onClick={onClick}>{t('look')}</Button>
                </div>
            </div>
        </div>
    )
}

export default CardPerizinan
