import * as React from 'react'
import * as Api from '../../../../constants/api'
import axios from 'axios'
import { getLanguageStorageUpperCaseKNFP } from '../../../../constants/storage'

export const useEnquiry = () => {
   const sendEmail = React.useCallback(async (payload) => {
      try {
         const responseSendEmail = await axios.post(
            Api.ENQUIRY_SEND_EMAIL,
            payload,
            {
               headers: {
                  'Content-Type': 'application/json',
                  'insw-key': Api.KNFP_AUTH_KEY,
                  Authorization: Api.BASIC_AUTH,
               },
            },
         )

         console.log('responseSendEmail: ', responseSendEmail)
      } catch (error) {
         console.log('error send email: ', error)
      }
   }, [])

   const parseSubOrSubSubTopics = React.useCallback(
      (subOrSubSubTopics, type, cb) => {
         if (getLanguageStorageUpperCaseKNFP() == 'ID') {
            subOrSubSubTopics.unshift({
               [type === 'subtopic' ? 'id_stp' : 'id_sstp']: 0,
               subsubtopic: `-------- Pilih ${
                  type === 'subtopic' ? 'Subtopik' : 'Subsubtopik'
               } --------`,
               isDisabled: true,
            })
         } else {
            subOrSubSubTopics.unshift({
               [type === 'subtopic' ? 'id_stp' : 'id_sstp']: 0,
               subsubtopic: `-------- Choose ${
                  type === 'subtopic' ? 'Subtopic' : 'Subsubtopic'
               } --------`,
               isDisabled: true,
            })
         }

         const formattedSubOrSubSubTopics = subOrSubSubTopics.map(
            (subOrSubSubTopics) => ({
               value:
                  type === 'subtopic'
                     ? subOrSubSubTopics.id_stp
                     : subOrSubSubTopics.id_sstp,
               label:
                  type === 'subtopic'
                     ? subOrSubSubTopics.subtopic
                     : subOrSubSubTopics.subsubtopic,
               isDisabled: false,
            }),
         )

         return cb(formattedSubOrSubSubTopics)
      },
      [],
   )

   return {
      sendEmail,
      parseSubOrSubSubTopics,
   }
}
