import React, { useState, useEffect, useHistory, useRef } from 'react';
import './detail_ecoo.scss';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Button,
	InputSearch,
	InputCapsule,
} from '../../../../components/atoms';
import axios from 'axios';
import * as Api from '../../../../constants/api';
import { ListEmpty, LoadProgress, TimeLine } from '../../../../components';
import moment from 'moment';
import Swal from 'sweetalert2';

// import Cryptr from 'cryptr';
// const CryptoJS = require('crypto-js');
const crypto = require('crypto');
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const DetailEcoo = () => {
	const location = useLocation();
	var route_data = '';
	if (location.state === undefined) {
		route_data = '';
	} else {
		route_data = location.state.data;
	}
	const [data_ecoo, setDataEcooAsal] = useState(route_data);

	const { t } = useTranslation();
	const [ecoo, SetEcoo] = useState('');
	const [is_progress, setisProgress] = useState(false);
	const [dataEcoo, SetDataEcoo] = useState([]);
	const [error_message, setErrorMessage] = useState('');
	const [no_npwp, SetNpwp] = useState('');
	const data_npwp = useRef('');

	const searchEcoo = (data) => {
		// console.log(data);
		// console.log('ecoo_detail');
		// console.log(data.nomor);

		setisProgress(true);

		let nomorNpwp = data.no_npwp;
		let nomorData = filter_input(data.nomor);

		let url =
			Api.IP_LNSW_CMS + 'coo?keyword=' + nomorData + '&npwp=' + nomorNpwp;
		// console.log(url);

		axios({
			method: 'get',
			url: url,
			headers: { Authorization: Api.BASIC_AUTH },
			timeout: 1000 * 10, // Wait for 10 seconds
		})
			.then((response) => {
				setisProgress(false);
				if (response.data.code === '01') {
					if (response.data.data.length === 0) {
						SetDataEcoo([]);
						setErrorMessage(t('empty_list'));
					} else {
						// console.log('length !== 0');
						// console.log(response.data.data);
						let data_list = response.data.data;
						SetDataEcoo(data_list);
					}
				} else {
					// console.log('code 02');
					// console.log(response.data.code);
					SetDataEcoo([]);
					setErrorMessage(t('empty_list'));
				}
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
				setisProgress(false);
				SetDataEcoo([]);
				setErrorMessage(t('error_message'));
			});
	};

	const filter_input = (input_text) => {
		let filter_text = input_text.replace(/\'|\"|\--/g, '');
		return filter_text;
	};

	const RegexNPWP = (value) => {
		// console.log(value);
		var regex_npwp = value.replace(/[^0-9,]/g, '');
		data_npwp.current = regex_npwp;
		if (regex_npwp.length > 15) {
			regex_npwp = regex_npwp.replace(
				/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/,
				'$1.$2.$3.$4-$5.$6'
			);
		} else if (regex_npwp.length > 12) {
			regex_npwp = regex_npwp.replace(
				/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})/,
				'$1.$2.$3.$4-$5.'
			);
		} else if (regex_npwp.length > 9) {
			regex_npwp = regex_npwp.replace(
				/(\d{2})(\d{3})(\d{3})(\d{1})/,
				'$1.$2.$3.$4-'
			);
		} else if (regex_npwp.length > 8) {
			regex_npwp = regex_npwp.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2.$3.');
		} else if (regex_npwp.length > 5) {
			regex_npwp = regex_npwp.replace(/(\d{2})(\d{3})/, '$1.$2.');
		} else if (regex_npwp.length > 2) {
			regex_npwp = regex_npwp.replace(/(\d{2})/, '$1.');
		}
		// console.log(regex_npwp);
		SetNpwp(regex_npwp);
	};

	//did mount
	useEffect(() => {
		// console.log('data_pibpeb_detail_didmount');
		// console.log(data_pibpeb);

		searchEcoo(data_ecoo);
	}, []);

	const see_detail = () => {
		let url = Api.LINK_ESKA;
		window.open(url + '/home.php/custom', '_blank'); //to open new page
	};

	const data_not = () => {
		Swal.fire({
			text: "E-COO Dengan Status NOT Tidak Dapat Dicetak",
			icon: "warning",
			confirmButtonColor: "#3085d6",
			confirmButtonText: "Tutup",
		  })
	};

	const algorithm = 'aes-256-cbc';
	const secretKey = SECRET_KEY;
	const iv = crypto.randomBytes(16);

	const encrypt = (text) => {
		let key = crypto.createHash('sha256').update(String(secretKey)).digest('base64').substr(0, 32);

		const cipher = crypto.createCipheriv(algorithm, key, iv);

		const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

		return {
			iv: iv.toString('hex'),
			content: encrypted.toString('hex')
		};
	};

	const print_pdf = (id_header) => {
		// console.log('id_header', id_header)
		if (id_header.length > 20) {
			let url = Api.IP_LNSW_CETAKAN + '?idHeader=' + id_header;
				
		    window.open(url, '_blank');
		} else {
			let url = Api.CETAK_ECOO;
			
			const encryptedString = encrypt(id_header);
			
			// console.log("data ciphertext", encryptedString)
	
			window.open(url + encryptedString.iv + '&content=' + encryptedString.content, '_blank'); //to open new page
		}
	};

	return (
		<div className='detail-ecoo-container'>
			<div className='penelusuran-content-title'>
				<div className='penelusuran-content-sub-title'>
					<div className='penelusuran-text-title'>{t('track_ecoo')}</div>
					<div className='penelusuran-input-search row m-0 justify-content-center'>
						<div className='col-md-4 mb-2'>
							<InputCapsule
								placeholder={t('enter_npwp_type')}
								value={no_npwp}
								onChange={(e) => RegexNPWP(e.target.value)}
							/>
						</div>
						<div className='col-md-4 mb-2'>
							<InputSearch
								placeholder={t('look_the_ecoo')}
								onChange={(e) => SetEcoo(e.target.value)}
								onKeyDown={() =>
									searchEcoo({
										no_npwp: data_npwp.current,
										nomor: ecoo,
									})
								}
								onClick={() =>
									searchEcoo({
										no_npwp: data_npwp.current,
										nomor: ecoo,
									})
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='detail-ecoo-content'>
				{is_progress === true ? (
					<div style={{ height: '25vw' }} className='detail-content-load'>
						<LoadProgress />
					</div>
				) : dataEcoo.length === 0 ? (
					<div className='detail-content-load'>
						<ListEmpty message_error={error_message} />
					</div>
				) : (
					<>
						<div className='detail-title'>
							<p className='detail-title-text'>{t('detail-ecoo')}</p>
						</div>
						<div className='detail-atas'>
							<div className='detail-container'>
								<p className='detail-judul'>{t('no-ecoo')}</p>
								<p className='detail-hasil'>
									{dataEcoo[0].no_coo === '' ? '-' : dataEcoo[0].no_coo}
								</p>
							</div>
							<div className='detail-container'>
								<p className='detail-judul'>{t('deliv_time')}</p>
								<p className='detail-hasil'>
									{dataEcoo[0].tgl_pengiriman === ''
										? '-'
										: dataEcoo[0].tgl_pengiriman}
								</p>
							</div>
							<div className='detail-container'>
								<p className='detail-judul'>{t('date-ecoo')}</p>
								<p className='detail-hasil'>
									{dataEcoo[0].tgl_coo === '' ? '-' : dataEcoo[0].tgl_coo}
								</p>
							</div>
							<div className='detail-container'>
								<p className='detail-judul'>{t('send-country')}</p>
								<p className='detail-hasil'>
									{dataEcoo[0].negara_pengirim === ''
										? '-'
										: dataEcoo[0].negara_pengirim}
								</p>
							</div>
							<div className='detail-container'>
								<p className='detail-judul'>{t('receiving-country')}</p>
								<p className='detail-hasil'>
									{dataEcoo[0].negara_penerima === ''
										? '-'
										: dataEcoo[0].negara_penerima}
								</p>
							</div>
							<div className='detail-container'>
								<p className='detail-judul'>{t('name_impor')}</p>
								<p className='detail-hasil'>
									{dataEcoo[0].importir === '' ? '-' : dataEcoo[0].importir}
								</p>
							</div>
							<div className='detail-container-last'>
								<p className='detail-judul'>{t('status')}</p>
								<p className='detail-hasil'>
									{dataEcoo[0].status === '' ? '-' : dataEcoo[0].status}
								</p>
							</div>
						</div>
						<div className='detail-bawah'>
							{	dataEcoo[0].state === 'NOT' ? (
								<Button
									title="Cetak"
									onClick={() => data_not()}
								/>
							) : (
								dataEcoo[0].negara_pengirim === 'INDONESIA' ? (
									<Button
										title={t('more_details')}
										onClick={() => see_detail()}
									/>
								) : (
									<Button
										title={t('print')}
										onClick={() => print_pdf(dataEcoo[0].id_header)}
									/>
								)
							)
								// null
							}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default DetailEcoo;
