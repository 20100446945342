/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import './berita.scss';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from 'html-react-parser';
import GridList from 'react-gridlist';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Ripples from 'react-ripples';
import { useHistory } from 'react-router-dom';

//component
import * as Api from '../../../constants/api';
import {
	LoadProgress,
	ListEmpty,
	InputSearch,
	ListBerita,
} from '../../../components';
import { ICCalendarGrayNews } from '../../../assets';

const Berita = () => {
	// variable constant
	const { t } = useTranslation();
	const history = useHistory();
	const [is_progress, setisProgress] = useState(true);
	const [list_berita_terkini, setListBeritaTerkini] = useState([]);
	const [cover_berita_terkini, setCoverBeritaTerkini] = useState({});
	const [error_message, setErrorMessage] = useState('');

	const [is_progress_populer, setisProgressPopuler] = useState(true);
	const [list_berita_populer, setListBeritaPopuler] = useState([]);
	const [list_berita_populer_slice, setListBeritaPopulerSlice] = useState([]);
	const [error_message_populer, setErrorMessagePopuler] = useState('');

	const search_berita = useRef('');
	const id_berita = useRef('');

	const perPage = 8;
	const [pageCount, setPageCount] = useState('');

	const current_page_data = useRef(0);
	const total_page = useRef(0);
	const [intial_page, setInitialPage] = useState(0);

	//style
	let styles = {
		container: {
			flex: 1,
		},
	};

	// did mount
	useEffect(() => {
		_get_data_berita();
		_get_data_berita_populer();
		// setTimeout(() => {
		// 	_get_data_berita_populer();
		// }, 1000);
	}, []);

	//function
	const _get_data_berita = () => {
		setisProgress(true);
		const url =
			Api.BERITA_TERKINI +
			'&lang=' +
			localStorage.getItem('language').toUpperCase();
		// console.log(url);
		axios({
			method: 'get',
			url: url,
			headers: { Authorization: Api.BASIC_AUTH },
			timeout: 1000 * 10, // Wait for 10 seconds
		})
			.then((response) => {
				setisProgress(false);
				if (response.data.code === '01') {
					if (response.data.data.length === 0) {
						setListBeritaTerkini([]);
						setErrorMessage(t('empty_list'));
					} else {
						let list_berita_terkini = response.data.data;
						// console.log(list_berita_terkini);
						// console.log(list_berita_terkini[0]);
						// console.log(list_berita_terkini.slice(1))
						if (list_berita_terkini.length === 1) {
							setCoverBeritaTerkini(list_berita_terkini[0]);
							id_berita.current = list_berita_terkini[0].id_berita;
							setListBeritaTerkini(list_berita_terkini);
						} else {
							setCoverBeritaTerkini(list_berita_terkini[0]);
							id_berita.current = list_berita_terkini[0].id_berita;
							setListBeritaTerkini(list_berita_terkini.slice(1));
						}
					}
				} else {
					setListBeritaTerkini([]);
					setErrorMessage(t('empty_list'));
				}
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
				setisProgress(false);
				setListBeritaTerkini([]);
				setErrorMessage(t('error_message'));
			});
	};

	const _get_data_berita_populer = () => {
		// setisProgressPopuler(false);
		// let list_berita_populer = data_berita;
		// console.log(list_berita_populer);
		// setListBeritaPopuler(list_berita_populer);
		// const slice = list_berita_populer.slice(0, perPage);
		// setPageCount(list_berita_populer.length / perPage);
		// setListBeritaPopulerSlice(slice);
		let page = 0;
		setisProgressPopuler(true);
		page = parseInt(current_page_data.current) + 1;
		const url =
			Api.BERITA_POPULER_PAGING +
			'page=' + page +
			'&limit=8' +
			'&keyword=' + '' +
			'&kategori=2' +
			'&lang=' +
			localStorage.getItem('language').toUpperCase();
		// console.log(url);
		axios({
			method: 'get',
			url: url,
			headers: { Authorization: Api.BASIC_AUTH },
			timeout: 1000 * 10, // Wait for 10 seconds
		})
			.then((response) => {
				setisProgressPopuler(false);
				if (response.data.code === '01') {
					if (response.data.data.length === 0) {
						setListBeritaPopuler([]);
						setListBeritaPopulerSlice([]);
						setErrorMessagePopuler(t('empty_list'));
					} else {
						total_page.current = response.data.total_page;
						// console.log('berita media total', total_page.current);
						let list_berita_populer = response.data.data;
						console.log(list_berita_populer_slice)
						console.log(list_berita_populer)
						// console.log('berita media', list_berita_populer);
						// setListBeritaPopuler(list_berita_populer);
						// const slice = list_berita_populer.slice(0, perPage);
						// setPageCount(list_berita_populer.length / perPage);
						// setListBeritaPopulerSlice(slice);
						if (list_berita_populer.length === 0) {
							if (current_page_data.current === 1) {
								setListBeritaPopulerSlice([]);
								setErrorMessagePopuler(t('empty_list'));
							}
						} else {
							// console.log('berita media hai');
							setListBeritaPopulerSlice(list_berita_populer);
						}
					}
				} else {
					if (current_page_data.current === 1) {
						setListBeritaPopuler([]);
						setListBeritaPopulerSlice([]);
						setErrorMessagePopuler(t('empty_list'));
					}
					// setListBeritaPopuler([]);
					// setListBeritaPopulerSlice([]);
					// setErrorMessagePopuler(t('empty_list'));
				}
			})
			.catch((error) => {
				if (current_page_data.current === 1) {
					setListBeritaPopuler([]);
					setListBeritaPopulerSlice([]);
					setErrorMessagePopuler(t('empty_list'));
				}
				console.log('error');
				console.log(error);
				setisProgressPopuler(false);
				setListBeritaPopuler([]);
				setListBeritaPopulerSlice([]);
				setErrorMessagePopuler(t('error_message'));
			});
	};

	const CallImage = (image_url) => {
		var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
		// console.log('url_image');
		// console.log(url_image);
		return url_image;
	};

	const getGridGap = (elementWidth, windowHeight) =>
		elementWidth > 720 && windowHeight > 480 ? 10 : 5;

	const getColumnCount = (elementWidth) => Math.floor(elementWidth / 300); //pembagian column (dibagi untuk 4 column)

	const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.1);

	const getItemData = (list_peraturan, columnWidth) => {
		// let adjustedHeight = Math.round(columnWidth * 0.25)
		return {
			key: list_peraturan.id,
			// height: adjustedHeight,
		};
	};
	const handlePageClick = (e) => {
		// const selectedPage = e.selected;
		// const offset = selectedPage * perPage;

		// loadMoreData(offset, offset + perPage);
		const selectedPage = e.selected;
		console.log(selectedPage);
		current_page_data.current = selectedPage;
		setInitialPage(selectedPage);
		// setSliceData([]);
		_get_data_berita_populer();
	};

	const loadMoreData = (of, op) => {
		const data = list_berita_populer;

		const slice = data.slice(of, op);
		setPageCount(data.length / perPage);
		setListBeritaPopulerSlice(slice);
	};

	const _click_more_news = () => {
		history.push('/berita/all-berita', { search_berita: '' });
	};

	const _click_view_more = (id_berita_click) => {
		// history.push('/berita/detail-berita', { id_berita: id_berita_click });
		history.push('/berita/detail-berita/' + id_berita_click);
	};

	return (
		<div className='berita-content'>
			<div className='row'>
				<div className='col-md-5 p-0'>
					<InputSearch
						placeholder={t('search_news_here')}
						onChange={(e) => (search_berita.current = e.target.value)}
						onKeyDown={() =>
							history.push('/berita/all-berita', {
								search_berita: search_berita.current,
							})
						}
						onClick={() =>
							history.push('/berita/all-berita', {
								search_berita: search_berita.current,
							})
						}
					/>
				</div>

				<div className='berita-line col-md-12 p-0'></div>

				<div className='berita-title col-md-12 p-0'>{t('latest_news')}</div>

				{/* <div style={{ display: "flex" }}> */}
				<div className='col-md-12 p-0'>
					{is_progress === true ? (
						<div
							style={{ height: '25vw' }}
							className='search-content-load col-md-12'
						>
							<LoadProgress />
						</div>
					) : list_berita_terkini.length === 0 ? (
						<div className='search-content-load col-md-12'>
							<ListEmpty message_error={error_message} />
						</div>
					) : (
						<div className='row'>
							<div className='berita-terkini-cover col-md-6 col-sm-12'>
								<img
									className='berita-terkini-cover-image'
									src={CallImage(cover_berita_terkini.file)}
								/>
								<div className='berita-terkini-cover-title'>
									{cover_berita_terkini.header}
								</div>
								<div className='berita-terkini-cover-date'>
									<img
										src={ICCalendarGrayNews}
										style={{ marginRight: '0.7vw' }}
									/>
									{moment(cover_berita_terkini.waktu_publish).format(
										'DD MMMM YYYY'
									) +
										' , ' +
										t('by') +
										' ' +
										cover_berita_terkini.penulis}
								</div>
								<div className='berita-terkini-cover-desc'>
									{parse(cover_berita_terkini.cuplikan)}
								</div>
								<div
									onClick={() => _click_view_more(id_berita.current)}
									className='berita-terkini-cover-more'
								>
									{t('read_more')}
								</div>
							</div>

							<div className='berita-terkini-list col-md-6 col-sm-12 p-0'>
								{list_berita_terkini.slice(0, 5).map((item) => (
									<ListBerita
										onClick={() => _click_view_more(item.id_berita)}
										gambar={CallImage(item.file)}
										judul={item.header}
										header={item.cuplikan}
									/>
								))}
								<div
									onClick={() => _click_more_news()}
									className='berita-terkini-list-more'
								>
									{t('see_all_news')}
								</div>
							</div>
						</div>
					)}
				</div>
				<div className='berita-terpopuler col-md-12 p-0'>
					<div className='berita-terpopuler-title col-md-12 p-0'>
						{t('most_popular_news')}
					</div>
					<div className='col-md-12 col-sm-12 p-0'>
						{is_progress_populer === true ? (
							<div
								style={{ height: '25vw' }}
								className='search-content-load col-md-12'
							>
								<LoadProgress />
							</div>
						) : list_berita_populer_slice.length === 0 ? (
							<div className='search-content-load col-md-12'>
								<ListEmpty message_error={error_message_populer} />
							</div>
						) : (
							<div className='row'>
								<div style={styles.container} className='col-md-12'>
									<GridList
										items={list_berita_populer_slice}
										getGridGap={getGridGap}
										getColumnCount={getColumnCount}
										getWindowMargin={getWindowMargin}
										getItemData={getItemData}
										renderItem={(item) => {
											return (
												<Ripples
													onClick={() => _click_view_more(item.id_berita)}
												>
													<div className='berita-content-item-grid'>
														<img
															className='berita-content-item-grid-image'
															src={CallImage(item.file)}
														/>
														<div className='berita-content-item-grid-title max-line-three'>
															{item.header}
														</div>
														<div className='berita-content-item-grid-desc max-line-four'>
															{parse(item.cuplikan)}
														</div>
													</div>
												</Ripples>
											);
										}}
									/>
								</div>
								<div className='berita-paging-content-list col-md-12'>
									{/* <ReactPaginate
										previousLabel={t('previous')}
										nextLabel={t('next')}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={pageCount}
										marginPagesDisplayed={2}
										pageRangeDisplayed={5}
										onPageChange={handlePageClick.bind(this)}
										containerClassName={'pagination'}
										subContainerClassName={'pages pagination'}
										activeClassName={'active'}
									/> */}
									<ReactPaginate
										previousLabel={'Sebelumnya'}
										nextLabel={'Selanjutnya'}
										breakLabel={'...'}
										breakClassName={'break-me'}
										pageCount={total_page.current}
										forcePage={intial_page}
										marginPagesDisplayed={2}
										pageRangeDisplayed={5}
										onPageChange={handlePageClick.bind(this)}
										containerClassName={'pagination'}
										subContainerClassName={'pages pagination'}
										activeClassName={'active'}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Berita;
