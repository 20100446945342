import React, { useEffect, useState } from 'react'
import {
   ICCoin,
   ICGlobe,
   ICRoket,
   IMVMDua,
   IMVMSatu,
   IMVMTiga,
} from '../../../assets'
import './visi_misi.scss'
import axios from 'axios'
import * as Api from '../../../constants/api'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import { ListEmpty, LoadProgress } from '../../../components'

import 'animate.css/animate.min.css'

import AOS from 'aos'
import 'aos/dist/aos.css'

const VisiMisi = () => {
   const { t } = useTranslation()
   const [is_progress, setisProgress] = useState(false)
   const [is_progress_judul, setisProgressJudul] = useState(false)
   const [error_message_banner, setErrorMessageBanner] = useState('')
   const [judul, setJudul] = useState([])
   const [list_Data, setListData] = useState([])
   const [list_Data1, setListData1] = useState([])
   const [list_Data2, setListData2] = useState([])
   const [list_Data3, setListData3] = useState([])
   const [list_Data4, setListData4] = useState([])
   const [list_Data5, setListData5] = useState([])
   const [list_Data6, setListData6] = useState([])

   //didmount
   useEffect(() => {
      get_data()
      _get_data_judul()
      setTimeout(() => {
         AOS.init({ duration: 1200 })
         AOS.refresh()
         AOS.refreshHard()
      }, 500)

      // AOS.init();
   }, [])

   const _get_data_judul = () => {
      setisProgressJudul(true)
      let id_menu = localStorage.getItem('menu_select')

      // if (id_menu === null || id_menu !== 4) {
      //    id_menu = 4
      // }
      let url
      if (id_menu === null || id_menu !== 4) {
         url =
            Api.JUDUL +
            4 +
            '&lang=' +
            localStorage.getItem('language').toUpperCase()
      } else {
         url =
            Api.JUDUL +
            id_menu +
            '&lang=' +
            localStorage.getItem('language').toUpperCase()
      }
      // if (id_menu === 4) {
      // url =
      //    Api.JUDUL +
      //    id_menu +
      //    '&lang=' +
      //    localStorage.getItem('language').toUpperCase()
      // } else {
      // url = Api.JUDUL + 4 + '&lang=' + localStorage.getItem('language').toUpperCase();
      // }
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgressJudul(false)
            if (response.data.code === '01') {
               setJudul(response.data.data)
            } else {
               setJudul([])
            }
         })
         .catch((error) => {
            setisProgressJudul(false)
            setJudul([])
         })
   }

   //function
   const get_data = () => {
      setisProgress(true)
      const url =
         Api.VISI_MISI +
         '?lang=' +
         localStorage.getItem('language').toUpperCase()
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            // console.log(response.data.code);
            setisProgress(false)
            if (response.data.code === '01') {
               // console.log(response.data.data);

               var dataVM = response.data.data

               for (let index = 0; index < dataVM.length; index++) {
                  var imageData =
                     Api.IP_KONG_LNSW + dataVM[index].nama_img.slice(1)
                  dataVM[index].nama_img = imageData
               }
               // console.log('dataVM');
               // console.log(dataVM);
               setListData(dataVM)
               // setListData1(dataVM[0])
               // setListData2(dataVM[1])
               // setListData3(dataVM[2])
               // setListData4(dataVM[3])
               // setListData5(dataVM[4])
               // setListData6(dataVM[5])
            } else {
               setListData([])
               // setListData1([])
               // setListData2([])
               // setListData3([])
               // setListData4([])
               // setListData5([])
               // setListData6([])
               setErrorMessageBanner(t('empty_list'))
            }
         })
         .catch((error) => {
            setisProgress(false)
            console.log('error')
            console.log(error)
            setisProgress(false)
            setListData([])
            // setListData1([])
            // setListData2([])
            // setListData3([])
            // setListData4([])
            // setListData5([])
            // setListData6([])
            setErrorMessageBanner(t('error_message'))
         })
   }

   const CallImage = (image_url) => {
      // console.log('url_image');
      // console.log(image_url);
      var url_image = ''
      if (image_url !== undefined) {
         url_image = Api.IP_KONG_LNSW + image_url.slice(1)
      }
      // console.log('url_image');
      // console.log(url_image);
      return url_image
   }

   const parseHtml = (html) => {
      // console.log('html');
      // console.log(html)
      var text_html = ''
      if (html !== undefined) {
         text_html = parse(html)
      }
      // var text_html = parse(html.toString());

      return text_html
   }

   return (
      <div className="visi-misi-page row m-0">
         {is_progress_judul === true ? (
            <div
               style={{ height: '25vw' }}
               className="search-content-load col-md-12"
            >
               <LoadProgress />
            </div>
         ) : judul.length === 0 ? (
            <div className="search-content-load col-md-12">
               <ListEmpty message_error={error_message_banner} />
            </div>
         ) : (
            judul.map((item, index) => (
               <div className="visi-misi-background col-md-12 p-0">
                  <div className="visi-misi-background-part">
                     <img src={CallImage(item.nama_img)} alt="bg" />
                  </div>
                  <div className="visi-misi-content-title">
                     <div className="visi-misi-title">
                        {parseHtml(item.header)}
                     </div>
                     <div className="visi-misi-subtitle">
                        {parseHtml(item.ur)}
                     </div>
                  </div>
               </div>
            ))
         )}
         {is_progress === true ? (
            <div
               style={{ height: '25vw' }}
               className="search-content-load col-md-12"
            >
               <LoadProgress />
            </div>
         ) : list_Data.length === 0 ? (
            <div className="search-content-load col-md-12">
               <ListEmpty message_error={error_message_banner} />
            </div>
         ) : (
            <div className="visi-misi-content col-md-12 p-0">
               <div className="misi-container">
                  <div className="misi-text">{t('misi_lnsw')}</div>
               </div>
               {list_Data.map((item, index) =>
                  index % 2 === 0 ? (
                     <div className="left-content row m-0">
                        <div
                           className="title col-md-5 col-sm-12 p-0"
                           data-aos="fade-right"
                        >
                           <div className="title-text">
                              <div className="icon">
                                 <img src={ICCoin} alt="coin" />
                              </div>
                              <div className="text-title">
                                 {parseHtml(item.header)}
                              </div>
                              <div className="text-subtitle">
                                 {parseHtml(item.ur)}
                              </div>
                           </div>
                        </div>
                        <div
                           className="image col-md-7 col-sm-12 p-0"
                           data-aos="fade-left"
                        >
                           <img
                              src={item.nama_img}
                              alt={item.nama_img}
                              className="gambar"
                           />
                        </div>
                     </div>
                  ) : (
                     <div className="right-content row m-0">
                        <div
                           className="image col-md-7 col-sm-12 p-0"
                           data-aos="fade-right"
                        >
                           <img
                              src={item.nama_img}
                              alt={item.nama_img}
                              className="gambar"
                           />
                        </div>
                        <div
                           className="title col-md-5 col-sm-12 p-0"
                           data-aos="fade-left"
                        >
                           <div className="title-text">
                              <div className="icon">
                                 <img src={ICRoket} alt="coin" />
                              </div>
                              <div className="text-title">
                                 {parseHtml(item.header)}
                              </div>
                              <div className="text-subtitle">
                                 {parseHtml(item.ur)}
                              </div>
                           </div>
                        </div>
                     </div>
                  ),
               )}

               {/* <div className="left-content row m-0">
                  <div className="title col-md-5 col-sm-12 p-0">
                     <div className="title-text">
                        <div className="icon">
                           <img src={ICCoin} alt="coin" />
                        </div>
                        <div className="text-title">
                           {parseHtml(list_Data1.header)}
                        </div>
                        <div className="text-subtitle">
                           {parseHtml(list_Data1.ur)}
                        </div>
                     </div>
                  </div>
                  <div className="image col-md-7 col-sm-12 p-0">
                     <img
                        src={list_Data1.nama_img}
                        alt={list_Data1.nama_img}
                        className="gambar"
                     />
                  </div>
               </div>
               <div className="right-content row m-0">
                  <div className="image col-md-7 col-sm-12 p-0">
                     <img
                        src={list_Data2.nama_img}
                        alt={list_Data2.nama_img}
                        className="gambar"
                     />
                  </div>
                  <div className="title col-md-5 col-sm-12 p-0">
                     <div className="title-text">
                        <div className="icon">
                           <img src={ICRoket} alt="coin" />
                        </div>
                        <div className="text-title">
                           {parseHtml(list_Data2.header)}
                        </div>
                        <div className="text-subtitle">
                           {parseHtml(list_Data2.ur)}
                        </div>
                     </div>
                  </div>
               </div>
               <div className="left-content row m-0">
                  <div className="title col-md-5 col-sm-12 p-0">
                     <div className="title-text">
                        <div className="icon">
                           <img src={ICCoin} alt="coin" />
                        </div>
                        <div className="text-title">
                           {parseHtml(list_Data3.header)}
                        </div>
                        <div className="text-subtitle">
                           {parseHtml(list_Data3.ur)}
                        </div>
                     </div>
                  </div>
                  <div className="image col-md-7 col-sm-12 p-0">
                     <img
                        src={list_Data3.nama_img}
                        alt={list_Data3.nama_img}
                        className="gambar"
                     />
                  </div>
               </div>
               <div className="right-content row m-0">
                  <div className="image col-md-7 col-sm-12 p-0">
                     <img
                        src={list_Data4.nama_img}
                        alt={list_Data4.nama_img}
                        className="gambar"
                     />
                  </div>
                  <div className="title col-md-5 col-sm-12 p-0">
                     <div className="title-text">
                        <div className="icon">
                           <img src={ICRoket} alt="coin" />
                        </div>
                        <div className="text-title">
                           {parseHtml(list_Data4.header)}
                        </div>
                        <div className="text-subtitle">
                           {parseHtml(list_Data4.ur)}
                        </div>
                     </div>
                  </div>
               </div>
               <div className="left-content row m-0">
                  <div className="title col-md-5 col-sm-12 p-0">
                     <div className="title-text">
                        <div className="icon">
                           <img src={ICCoin} alt="coin" />
                        </div>
                        <div className="text-title">
                           {parseHtml(list_Data5.header)}
                        </div>
                        <div className="text-subtitle">
                           {parseHtml(list_Data5.ur)}
                        </div>
                     </div>
                  </div>
                  <div className="image col-md-7 col-sm-12 p-0">
                     <img
                        src={list_Data5.nama_img}
                        alt={list_Data5.nama_img}
                        className="gambar"
                     />
                  </div>
               </div>
               <div className="right-content row m-0">
                  <div className="image col-md-7 col-sm-12 p-0">
                     <img
                        src={list_Data6.nama_img}
                        alt={list_Data6.nama_img}
                        className="gambar"
                     />
                  </div>
                  <div className="title col-md-5 col-sm-12 p-0">
                     <div className="title-text">
                        <div className="icon">
                           <img src={ICRoket} alt="coin" />
                        </div>
                        <div className="text-title">
                           {parseHtml(list_Data6.header)}
                        </div>
                        <div className="text-subtitle">
                           {parseHtml(list_Data6.ur)}
                        </div>
                     </div>
                  </div>
               </div> */}
               {/* <div className="background-content col-md-12 p-0">
                    <div className="image">
                        <img src={list_Data3.nama_img} alt={list_Data3.nama_img} className="gambar" />
                    </div>
                    <div className="title">
                        <div className="title-text">
                            <div className="icon">
                                <img src={ICGlobe} alt="coin" />
                            </div>
                            <div className="text-title">
                                {parseHtml(list_Data3.header)}
                            </div>
                            <div className="text-subtitle">
                                {parseHtml(list_Data3.ur)}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tiga-content col-md-12">
                    <div className="row m-0">
                        <div className="visi-content col-md-4 col-sm-12">
                            <img src={list_Data4.nama_img} alt={list_Data4.nama_img} className="gambar" />
                            <div className="text-title">
                                {parseHtml(list_Data4.header)}
                            </div>
                            <div className="text-subtitle">
                                {parseHtml(list_Data4.ur)}
                            </div>
                        </div>
                        <div className="visi-content col-md-4 col-sm-12">
                            <img src={list_Data5.nama_img} alt={list_Data5.nama_img} className="gambar" />
                            <div className="text-title">
                                {parseHtml(list_Data5.header)}
                            </div>
                            <div className="text-subtitle">
                                {parseHtml(list_Data5.ur)}
                            </div>
                        </div>
                        <div className="visi-content col-md-4 col-sm-12">
                            <img src={list_Data6.nama_img} alt={list_Data6.nama_img} className="gambar" />
                            <div className="text-title">
                                {parseHtml(list_Data6.header)}
                            </div>
                            <div className="text-subtitle">
                                {parseHtml(list_Data6.ur)}
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
         )}
      </div>
   )
}

export default VisiMisi
