import React, { useEffect, useState } from 'react';
import './carouselitems.scss';
// import { Button } from '../../atoms';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CarouselItems = ({gambar, judul, onClick, data, jml}) => {
    const { t } = useTranslation();
    const [cs_front, setCsFront] = useState('cs-front');

    const width = window.innerWidth;

    const klik_data = item => {
        window.open(item, "_blank") //to open new page
    }

    // did mount
    useEffect(() => {
        // console.log('data item', data.length);
        if (data.length === 1) {
            setCsFront('cs-front-satu');
        }
        if (data.length === 3) {
            setCsFront('cs-front-tiga');
        }
    }, []);

    const TypeView = () => {
        if (width < 600) {
            return (
                <>
                <img
                    src={gambar}
                    className={
                        judul !== '' && jml === 3 ? "cs-image"
                        : judul !== '' && jml === 2 ? "cs-image-mobile"
                        : judul !== '' && jml === 1 ? "cs-image-mobile"
                        : judul === '' && jml === 3 ? "cs-image-null"
                        : judul === '' && jml === 2 ? "cs-image-null-mobile"
                        : "cs-image-null-mobile"
                    }
                    alt="banner_img"
                />
                <div className={judul !== '' && jml === 1 ? "cs-front-satu-mobile" : "cs-front-satu"}> 
                    <p className="cs-text">{judul}</p>
                    {
                        onClick !== '' ?
                        <Button className="button-more-ci" onClick={() => klik_data(onClick)}>{t('more_details')}</Button>
                        :
                        null
                    }
                </div>
                </>
            );
        } else {
            return (
                <>
                <img src={gambar} className={judul !== '' ? "cs-image" : "cs-image-null"} alt="banner_img" />
                <div className={cs_front}> 
                    <p className="cs-text">{judul}</p>
                    {
                        onClick !== '' ?
                        <Button className="button-more-ci" onClick={() => klik_data(onClick)}>{t('more_details')}</Button>
                        :
                        null
                    }
                </div>
                </>
            );
        }

    }

    return (
        <div className="cs-content">     
            <TypeView />
        </div>
    )
}

export default CarouselItems;
