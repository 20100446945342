import axios from 'axios';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Api from '../../../constants/api';
import './etik.scss';

const Profil = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const { t } = useTranslation();
  const [etik_data, setEtik] = useState([]);

  const CallImage = (image_url) => {
    // var replace_url = image_url.substring(1);
    var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    // console.log(url_image);
    return url_image;
  }

  //did mount
  useEffect(() => {
    if (localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'id');
    }

    _get_data_etik();
  }, []);

  const parseHtml = (html) => {
    // console.log('html');
    // console.log(html)
    var text_html = '';
    if (html !== undefined) {
      text_html = parse(html);
    }

    return text_html;
  }

  const _get_data_etik = () => {
    const url = Api.KODE_ETIK + '?lang=' + localStorage.getItem('language').toUpperCase();
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        // console.log("kode_etik", response.data.data);
        if (response.data.code === '01') {
          setEtik(response.data.data);
        } else {
          setEtik([]);
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setEtik([]);
      });
  }

  const klik_data = item => {

    const emailCC = '';
    const emailSub = 'Pengaduan Kode Etik';
    const emailBody = 'Isi Email disini...';

    // window.open("mailto:" + item + '?cc=' + emailCC + ' &subject=' + emailSub + '&body=' + emailBody) //to open new page email
    window.open("mailto:" + item + '?cc=&subject=' + emailSub + '&body=' + emailBody) //to open new page email
  }

  return (
    <div>
      <div className="etik-background">
        {etik_data.map((item, index) =>
          <div className="row m-0">
            <div className="col-md-6 p-0">
              <div className="etik-content">
                <div className="etik-text-title col mb-3">
                  {t(item.header)}
                </div>
                <div className="etik-sub-text col mb-3">
                  <ul className="ml-3">
                    <li>
                      {/* {t(item.ur)} */}
                      {parseHtml(`${t(item.ur)}`)}
                    </li>
                  </ul>
                </div>
                <div className="etik-text-message col mb-3">
                  {t('etik')}
                </div>
                <div className="etik-email-button col mb-3">
                  <button className="btn btn-warning text-dark font-weight-bold rounded-pill mr-2" onClick={() => klik_data(item.url)}>{t('Email UKI')}</button>
                  <button className="btn btn-warning text-dark font-weight-bold rounded-pill mr-2" onClick={() => window.open("https://www.wise.kemenkeu.go.id")}>{t('WISE Kemenkeu')}</button>
                  <button className="btn btn-warning text-dark font-weight-bold rounded-pill mr-2" onClick={() => window.open("https://www.lapor.go.id")}>{t('SP4N Lapor')}</button>
                  <button className="btn btn-warning text-dark font-weight-bold rounded-pill mr-2" onClick={() => window.open("https://wa.me/62816630242")}>{t('WA')}</button>
                </div>
                <div className="etik-text-message col mb-3 mt-4">
                  Atau melalui SMS ke 0816630242
                </div>
              </div>
            </div>
            <div className="col-md-6 p-0">
              <img className="imeg" src={CallImage(item.nama_img)} alt='kode etik'></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}


export default Profil;
