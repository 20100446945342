import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Api from "../../../constants/api";
import "./index.scss";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Footer from "../../KNFP/Footer/index";
import {
  getLanguageStorageUpperCaseKNFP,
  setLanguageStorage,
} from "../../../constants/storage";
import "./sub_faq.scss"
import "../../../components/molecules/SubKatCollapseknfp"
import { SubKatCollapse } from "../../../components";
import CollapseKnfp from "../../../components/molecules/SubKatCollapseknfp";


const AboutKnfp = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (getLanguageStorageUpperCaseKNFP() === null) {
      setLanguageStorage("id");
    }
    _get_data_about_knfp();
  }, []);

  // const CallImage = (image_url) => {
  //   // var replace_url = image_url.substring(1);
  //   var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
  //   // console.log(url_image);
  //   return url_image;
  // };

  // const parseHtml = (html) => {
  //   // console.log('html');
  //   // console.log(html)
  //   var text_html = "";
  //   if (html !== undefined) {
  //     text_html = parse(html);
  //   }
  //   // var text_html = parse(html.toString());
  // };

  const _get_data_about_knfp = () => {
    const url =
      Api.KNFP + "about/web?lang=" + getLanguageStorageUpperCaseKNFP();
    axios({
      method: "get",
      url: url,
      headers: { 'insw-key': Api.KNFP_AUTH_KEY, 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then((response) => {
        // console.log("detail_kontak", response.data.data);
        if (response.data.result.code === 200) {
          setData(response.data.result.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setData([]);
      });
  };



  const dataSementara = [
    {
      id: 39,
      title: "Struktur Organisasi",
      content: ` <p><strong>Dewan Pengarah</strong></p>
  <ul>
    <li>Ketua: Menteri Koordinator Bidang Perekonomian</li>
    <li>Wakil Ketua I: Menteri Perdagangan</li>
    <li>Wakil Ketua II: Menteri Keuangan</li>
    <li>Anggota: Menteri Hukum dan Hak Asasi Manusia, Menteri Pertanian, Menteri Perindustrian, Menteri Perhubungan,
      Menteri Luar Negeri, Menteri Kelautan dan Perikanan, Menteri Kesehatan, Menteri Lingkungan Hidup dan Kehutanan,
      Menteri Investasi/Kepala Badan Koordinasi Penanaman Modal, Sekretaris Kabinet, Kepala Badan Pengawas Obat dan
      Makanan, dan Kepala Badan Standardisasi Nasional.</li>
  </ul>
  <p><br></p>
  <p><strong>Pelaksana Harian</strong></p>
  <ul>
    <li>Ketua: Deputi Bidang Koordinasi Perniagaan dan Industri, Kementerian Koordinator Bidang Perekonomian.</li>
    <li>Wakil Ketua I: Direktur Jenderal Bea dan Cukai Kementerian Keuangan</li>
    <li>Wakil Ketua II: Direktur Jenderal Perdagangan Luar Negeri, Kementerian Perdagangan</li>
    <li>Wakil Ketua III: Deputi Bidang Perekonomian, Sekretariat Kabinet</li>
    <li>Anggota:</li>
  </ul>
  <ol>
    <li class=\"ql-indent-1\">Direktur Kerja Sama Internasional Kepabeanan dan Cukai, Kementerian Keuangan.</li>
    <li class=\"ql-indent-1\">Asisten Deputi Bidang Peningkatan Ekspor dan Fasilitasi Perdagangan Internasional,
      Kementerian Koordinator Bidang Perekonomian.</li>
    <li class=\"ql-indent-1\">Direktur Perudingan Organisasi Perdagangan Dunia, Kementerian Perdagangan.</li>
    <li class=\"ql-indent-1\">Asisten Deputi Bidang Perdagangan, Perindustrian, dan Ketenagakerjaan, Sekretariat Kabinet.
    </li>
  
  </ol>
  <p><br></p>
  <p><strong>Koordinator Bidang</strong></p>
  <ol>
    <li>Bidang Regulasi: Direktur Jenderal Peraturan Perundang-undangan, Kementerian Hukum dan Hak Asasi Manusia.</li>
    <li>Bidang Kepabeanan: Direktur Jenderal Bea dan Cukai, Kementerian Keuangan.</li>
    <li>Bidang Transportasi: Sekretaris Jenderal, Kementerian Perhubungan.</li>
    <li>Bidang Perdagangan: Direktur Jenderal Perdagangan Luar Negeri, Kementerian Perdagangan.</li>
    <li>Bidang Pertanian: Sekretaris Jenderal, Kementerian Pertanian.</li>
    <li>Bidang Kesehatan: Direktur Jenderal Kefarmasian dan Alat Kesehatan, Kementerian Kesehatan.</li>
    <li>Bidang Industri: Sekretaris Jenderal, Kementerian Perindustrian.</li>
    <li>Bidang Lingkungan Hidup dan Kehutanan: Sekretaris Jenderal, Kementerian Lingkungan Hidup dan Kehutanan.</li>
    <li>Bidang Luar Negeri: Direktur Jenderal Kerjasama Multilateral, Kementerian Luar Negeri.</li>
    <li>Bidang Standar: Deputi Bidang Penerapan Standar dan Penilaian Kesesuaian, Badan Standardisasi Nasional.</li>
    <li>Bidang Investasi: Deputi Bidang Pelayanan Penanaman Modal, Kementerian Investasi/Badan Koordinasi Penanaman Modal.
    </li>
    <li>Bidang Obat dan Makanan: Sekretaris Utama, Badan Pengawas Obat dan Makanan.
    </li>
    <li>Bidang Perikanan: Kepala Badan Karantina Ikan, Pengendalian Mutu, dan Keamanan Hasil Perikanan, Kementerian
      Kelautan dan Perikanan.
    </li>
    <li>Bidang Informasi Publikasi: Kepala Lembaga National Single Window.
    </li>
  
  </ol>`,
      status: true,
      created_date: "2021-04-26T16:01:12.561Z",
      updated_date: "2021-10-24T16:59:05.701Z",
      created_by: "D8A3RA",
    },
    {
      id: 38,
      title: "Tujuan",
      content: `<p>KNFP bertujuan untuk meningkatkan transparansi dan simplifikasi prosedur ekspor dan impor untuk
    mempercepat pergerakan pelepasan dan pembebasan (release and clearance)
  </p>`,
      status: true,
      created_date: "2021-04-26T15:52:16.679Z",
      updated_date: "2021-10-24T16:57:43.495Z",
      created_by: "D8A3RA",
    },
    {
      id: 38,
      title: "Sasaran",
      content: `<p>
        Peningkatan daya saing produk ekspor Indonesia
  </p>`,
      status: true,
      created_date: "2021-04-26T15:52:16.679Z",
      updated_date: "2021-10-24T16:57:43.495Z",
      created_by: "D8A3RA",
    },
    {
      id: 38,
      title: "Manfaat",
      content: `<ul>
      <li>	Meningkatkan kelancaran arus barang dan jasa melalui penyederhanaan prosedur ekspor – impor </li>
      <li>	Efisiensi waktu dan biaya perdagangan </li>
      <li>	Meningkatkan akses ekspor, khususnya keterlibatan UMKM </li>
      <li>	Mendorong pertumbuhan ekonomi </li>
      <li>	Meningkatkan pendapatan negara dan investasi </li>
      <li>	Meningkatkan volume perdagangan </li>
      <li>	Mendorong kelancaran industry </li>
      
  </ul>`,
      status: true,
      created_date: "2021-04-26T15:52:16.679Z",
      updated_date: "2021-10-24T16:57:43.495Z",
      created_by: "D8A3RA",
    },
    {
      id: 38,
      title: "Visi",
      content: `<p>
        -
  </p>`,
      status: true,
      created_date: "2021-04-26T15:52:16.679Z",
      updated_date: "2021-10-24T16:57:43.495Z",
      created_by: "D8A3RA",
    },
    //   {
    //     id: 37,
    //     title: "Visi",
    //     content: `<p>Terciptanya efisiensi dan kecepatan waktu proses ekspor dan impor yang berkontribusi pada peningkatan
    //   ekspor Indonesia</p>
    // <p class=\"ql-align-center\"><br></p>`,
    //     status: true,
    //     created_date: "2021-04-26T15:51:26.286Z",
    //     updated_date: "2021-10-24T16:56:20.527Z",
    //     created_by: "D8A3RA",
    //   },
    //   {
    //     id: 36,
    //     title: "Misi",
    //     content: `<ol>
    //   <li>Meningkatkan Kemudahan Berbisnis: Memperbaiki lingkungan bisnis Indonesia dengan menyederhanakan prosedur
    //     perizinan, mengurangi birokrasi, dan mempercepat pelayanan kepada pelaku usaha untuk meningkatkan investasi dan daya
    //     saing.</li>
    //   <li>Mengurangi Hambatan Perdagangan: Mengidentifikasi, mengevaluasi, dan mengurangi hambatan perdagangan seperti
    //     tarif, kuota, dan regulasi yang mempengaruhi ekspor dan impor produk Indonesia.</li>
    //   <li>Pengembangan Infrastruktur Perdagangan: Menginvestasikan dalam infrastruktur transportasi, pelabuhan, dan
    //     logistik untuk meningkatkan konektivitas dan efisiensi dalam rantai pasokan perdagangan.</li>
    //   <li>Penyederhanaan Prosedur Bea Cukai: Mempercepat proses impor dan ekspor dengan penyederhanaan prosedur bea cukai,
    //     penggunaan teknologi, dan perbaikan tata kelola bea cukai.</li>
    //   <li>Penguatan Pengawasan dan Keamanan Perdagangan: Memastikan keamanan dan integritas barang-barang yang
    //     diperdagangkan untuk melindungi konsumen dan mencegah aktivitas ilegal dalam perdagangan internasional.</li>
    //   <li>Peningkatan Kapasitas Pelaku Usaha: Memberikan pelatihan dan dukungan kepada pelaku usaha Indonesia untuk
    //     meningkatkan kualitas produk, manajemen, dan akses ke pasar global.</li>
    //   <li>Promosi Kebijakan Pro-Perdagangan: Mengadvokasi kebijakan pro-perdagangan dalam negosiasi perjanjian perdagangan
    //     internasional dan memastikan bahwa kepentingan Indonesia diwakili dengan baik.</li>
    //   <li>Kerja Sama Internasional: Bekerja sama dengan organisasi perdagangan internasional dan negara-negara mitra
    //     dagang untuk mempromosikan kerja sama yang saling menguntungkan dan akses pasar yang lebih besar untuk produk
    //     Indonesia.</li>
    // </ol>`,
    //     status: true,
    //     created_date: "2021-04-26T15:50:37.626Z",
    //     updated_date: "2021-12-24T07:41:23.094Z",
    //     created_by: "D8A3RA",
    //   },
    {
      id: 35,
      title: "Misi",
      content: `<ol>
    <li class=\"ql-align-justify\">Meningkatkan Kemudahan Berbisnis: Memperbaiki lingkungan bisnis Indonesia dengan menyederhanakan prosedur perizinan, mengurangi birokrasi, dan mempercepat pelayanan kepada pelaku usaha untuk meningkatkan investasi dan daya saing.</em></li>
    <li class=\"ql-align-justify\">Mengurangi Hambatan Perdagangan: Mengidentifikasi, mengevaluasi, dan mengurangi hambatan perdagangan seperti tarif, kuota, dan regulasi yang mempengaruhi ekspor dan impor produk Indonesia.</em></li>
    <li class=\"ql-align-justify\">Pengembangan Infrastruktur Perdagangan: Menginvestasikan dalam infrastruktur transportasi, pelabuhan, dan logistik untuk meningkatkan konektivitas dan efisiensi dalam rantai pasokan perdagangan.</li>
    <li class=\"ql-align-justify\">Penyederhanaan Prosedur Bea Cukai: Mempercepat proses impor dan ekspor dengan penyederhanaan prosedur bea cukai, penggunaan teknologi, dan perbaikan tata kelola bea cukai</li>
    <li class=\"ql-align-justify\">Penguatan Pengawasan dan Keamanan Perdagangan: Memastikan keamanan dan integritas barang-barang yang diperdagangkan untuk melindungi konsumen dan mencegah aktivitas ilegal dalam perdagangan internasional.</li>
    <li class=\"ql-align-justify\">Peningkatan Kapasitas Pelaku Usaha: Memberikan pelatihan dan dukungan kepada pelaku usaha Indonesia untuk meningkatkan kualitas produk, manajemen, dan akses ke pasar global.</li>
    <li class=\"ql-align-justify\">Promosi Kebijakan Pro-Perdagangan: Mengadvokasi kebijakan pro-perdagangan dalam negosiasi perjanjian perdagangan internasional dan memastikan bahwa kepentingan Indonesia diwakili dengan baik.</li>
    <li class=\"ql-align-justify\">Kerja Sama Internasional: Bekerja sama dengan organisasi perdagangan internasional dan negara-negara mitra dagang untuk mempromosikan kerja sama yang saling menguntungkan dan akses pasar yang lebih besar untuk produk Indonesia.</li>
  </ol>
  <p class=\"ql-align-justify\"><br></p>
  <p class=\"ql-align-justify\"><br></p>`,
      status: true,
      created_date: "2021-04-26T15:49:42.522Z",
      updated_date: "2021-10-24T16:55:54.924Z",
      created_by: "D8A3RA",
    },
    {
      id: 35,
      title: "Dasar Hukum Pembentukan",
      content: `<ol>
    <li class=\"ql-align-justify\">Undang-Undang Nomor 7 Tahun 1994 tentang pengesahan <em>Agreement Establishing the
        World Trade Organization</em></li>
    <li class=\"ql-align-justify\">Undang-Undang Nomor 17 Tahun 2017 tentang Pengesahan <em>Protocol Amending The
        Marrakesh Agreement Establishing The World Trade Organization</em></li>
    <li class=\"ql-align-justify\">Peraturan Menteri Koordinator Bidang Perekonomian Republik Indonesia Nomor 199 Tahun
      2018 tentang Komite Nasional Fasilitasi Perdagangan</li>
    <li class=\"ql-align-justify\">Peraturan Menteri Koordinator Bidang Perekonomian Republik Indonesia Nomor 284 Tahun
      2018 tentang Perubahan atas Keputusan Menteri Koordinator Bidang Perekonomian Nomor 199 Tahun 2018 tentang
      Komite Nasional Fasilitasi Perdagangan</li>
    <li class=\"ql-align-justify\">Keputuran Menteri Koordinator Bidang Perekonomian Republik Indonesia Nomor 168 Tahun
      2023 tentang Komite Nasional Fasilitasi Perdagangan</li>
  </ol>
  <p class=\"ql-align-justify\"><br></p>
  <p class=\"ql-align-justify\"><br></p>`,
      status: true,
      created_date: "2021-04-26T15:49:42.522Z",
      updated_date: "2021-10-24T16:55:54.924Z",
      created_by: "D8A3RA",
    },
    {
      id: 34,
      title: "Penjelasan Singkat",
      content: `<p>Komite Nasional Fasilitasi Perdagangan (KNFP) Indonesia adalah badan yang bertugas mengkoordinasikan
    upaya pemerintah dalam memfasilitasi dan meningkatkan perdagangan internasional di Indonesia. Tugas utamanya adalah
    mengurangi hambatan perdagangan, memperbaiki proses ekspor dan impor, serta mempromosikan iklim bisnis yang lebih
    kondusif. KNFP bekerja sama dengan berbagai kementerian, lembaga, dan pemangku kepentingan lainnya untuk mencapai
    tujuannya dalam mendukung pertumbuhan ekonomi dan meningkatkan akses pasar global bagi produk Indonesia.</p>`,
      status: true,
      created_date: "2021-04-26T15:45:49.494Z",
      updated_date: "2021-10-24T16:54:22.102Z",
      created_by: "D8A3RA",
    },
    // {
    //   id: 38,
    //   title: "Kementerian",
    //   content: `<ul>
    //     <li>Kementerian Kelautan dan Perikanan</li>
    //     <li>Kementerian Lingkungan Hidup dan Kehutanan</li>
    //     <li>Kementerian Keuangan</li>
    //     <li>Kementerian Perindustrian</li>
    //     <li>Badan Pengawas Obat dan Makanan</li>
    //     <li>Kementerian Luar Negeri</li>
    //     <li>Badan Koordinasi Penanaman Modal</li>
    //     <li>Kementerian Perhubungan</li>
    //     <li>Badan Standardisasi Nasional</li>
    //     <li>Kementerian Kesehatan</li>
    //     <li>Kementerian Pertanian</li>
    //     <li>Kementerian Perdagangan</li>
    //   </ul>`,
    //   status: true,
    //   created_date: "2021-04-26T15:52:16.679Z",
    //   updated_date: "2021-10-24T16:57:43.495Z",
    //   created_by: "D8A3RA",
    // }
  ];



  return (
    <>
      <div className="serta-merta row m-0">
        <div className="col-md-12">
          <div className="sm-content text-center">
            <h3 className="sm-title">{t("tentang")}</h3>
            <div className="row m-0 justify-content-center">
              <p className="col-md-6">{t("knfp-trade-facility-subtitle")}</p>
            </div>
          </div>
        </div>

        <div className="sub-faq-container">
          <div className="sub-faq-content">
            <div className="sub-faq-list-question">
              {data.map((item) =>
                <CollapseKnfp data={item} />)}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutKnfp;
