import React, { useState, useEffect } from 'react';
import './riwayat_penghargaan.scss';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import * as Api from '../../../constants/api';
import { BootstrapTable, ExportCSVButton as DownloadButton, TableHeaderColumn } from 'react-bootstrap-table';
import { Button } from 'reactstrap';

const RiwayatPenghargaan = () => {
    const { t } = useTranslation();
    const [dataRiwayatPenghargaan, setDataRiwayatPenghargaan] = useState([]);
    
    const _get_data_riwayat_penghargaan = () => {
        const url = Api.RIWAYAT_PENGHARGAAN;
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
        .then(response => {
            if (response.data.code === '01') {
                let data_agenda = response.data.data.map((item, index) => ({ ...item, num: index + 1 }));
                setDataRiwayatPenghargaan(data_agenda);
            } else {
                setDataRiwayatPenghargaan([]);
            }
        })
        .catch(error => {
            console.log(error);
            setDataRiwayatPenghargaan([]);
        });
    }
    const handleDownloadButtonClick = () => {
        window.open('https://api.insw.go.id/api/reports/riwayat-penghargaan');
    }

    const createCustomDownloadButton = () => {
        return (
            <Button className="button-unduh" onClick={() => handleDownloadButtonClick()}>{ t('download') }</Button>
        );
    }

    const tableOptions = {
        exportCSVBtn: createCustomDownloadButton
    }

    useEffect(() => {
        _get_data_riwayat_penghargaan();
    }, []);

	return (
		<div className='riwayat-penghargaan-content'>
			<div className='riwayat-penghargaan-content-title'>
				<div>{t('riwayat_penghargaan_lnsw')}</div>
			</div>
			<div className='riwayat-penghargaan-table-container'>
                <BootstrapTable
                    data={dataRiwayatPenghargaan || []}
                    hover
                    pagination
                    search
                    options={tableOptions}
                    exportCSV
                >
                    <TableHeaderColumn
                        isKey
                        dataAlign="center"
                        dataField="num"
                        dataSort
                        className="tbl"
                        width="50"
                    >
                        No
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="center"
                        dataField="tahun"
                        dataSort
                        className="tbl"
                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                        Tahun
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="center"
                        dataField="nm_penghargaan"
                        dataSort
                        className="tbl"
                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                        Penghargaan
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="center"
                        dataField="institusi"
                        dataSort
                        className="tbl"
                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                    >
                        Institusi
                    </TableHeaderColumn>
                    <TableHeaderColumn
                        dataAlign="center"
                        dataField="link_berita"
                        dataSort
                        className="tbl"
                        tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        thStyle={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                        dataFormat={(cell) => (<a href={cell} target='_blank' rel="noopener noreferrer">{ cell}</a>)}
                    >
                        Link Berita
                    </TableHeaderColumn>
                </BootstrapTable>
			</div>
		</div>
	);
};

export default RiwayatPenghargaan;
