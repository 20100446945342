import React, { useEffect, useState } from "react";
import "./footer.scss";
import { LogoKemenKeu, ICFb, ICYt, ICIg, ICGP, ICAS } from "../../../assets";
//import { Gap } from "../../atoms";
import { useTranslation } from "react-i18next";

const Icon = ({ img, link_sosmed }) => {
  return (
    <div className="icon-wrapper">
      <a target="_blank" href={link_sosmed}>
        <img className="icon-medsos" src={img} alt="icon" />
      </a>
    </div>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const [year, setYear] = useState("");

  //did mount
  useEffect(() => {
    get_year();
  }, []);

  const get_year = () => {
    let year = new Date().getFullYear();
    // console.log('year', year);
    setYear(year);
  };

  return (
    <>
      <div className="footer">
        <div className="copyright-knfp">
          <p>
            {t("knfp_foot_name")} <br /> {t("knfp_foot_email")}
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
