/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import './input_date.scss';
import { ICDate } from '../../../assets';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const InputDate = ({Date, isDate, onChange, placeHolder}) => {

    return (
        <div style={{borderWidth: '0px', borderBottomWidth: '1px'}} className="intr-input-sub-date">
            <img className="icon" src={ICDate} />
            <DatePicker 
                className="input-field"
                selected={Date}
                disabled={isDate}
                onChange={onChange} 
                dateFormat="dd MMM yyyy"
                placeholderText={placeHolder}
            />
        </div>
    )
}

export default InputDate;
