/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './list_kurs.scss';
import { ICArrowUp, ICArrowDown } from '../../../assets';
import { useTranslation } from 'react-i18next';

const ListKurs = ({image, title, nilai_kurs, nilai_perubahan, status, kd_kurs}) => {
    //variable constant
    const { t } = useTranslation();

    // function
    const formatRp = value => {
        var angka = value.replace('.', ',');
        var split = angka.split(',');
        var sisa = split[0].length % 3;
        var rupiah = split[0].substr(0, sisa);
        var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
    
        if (ribuan) {
          var separator = sisa ? '.' : '';
          rupiah += separator + ribuan.join('.');
        }
    
        var rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        var prefix = undefined ? rupiah : rupiah ? 'Rp. ' + rupiah : '';
    
        return prefix;
      };

    const Note = () => {
    if (kd_kurs === 'JPY') {
        return <p className="note-kurs">{t('note_jpy')}</p>;
    } else {
        return null;
    }
    };

    return (
        <div className="list-kurs-item">
            <div className="list-kurs-item-content">
                <img src={image} className="list-kurs-item-img" />
                <p className="list-kurs-item-title">{title}</p>
            </div>
            <div style={{display: "flex", marginTop: '1vw'}}>
                <div className={status === 'up' ? 'list-kurs-item-text' : 'list-kurs-item-text-down'} style={{display: "flex", flex: 1}}>
                    <div style={{marginRight: '0.5vw'}}>{t('score')}</div>
                    <img style={{marginRight: '0.5vw'}} src={status === 'up' ? ICArrowUp : ICArrowDown} className="list-kurs-item-img" />
                    <div>{formatRp(nilai_kurs)}</div>
                </div>
                <div className={status === 'up' ? 'list-kurs-item-text' : 'list-kurs-item-text-down'} style={{display: "flex", flex: 1, justifyContent: "flex-end"}}>
                    <div style={{marginRight: '0.5vw'}}>{t('change')}</div>
                    <img style={{marginRight: '0.5vw'}} src={status === 'up' ? ICArrowUp : ICArrowDown} className="list-kurs-item-img" />
                    <div>{nilai_perubahan}</div>
                </div>
            </div>
            <Note />
            <div style={{width: '100%', height: '1px', backgroundColor: '#C4C4C4', marginTop: '1vw'}}></div>
        </div>
    )
}

export default ListKurs;
