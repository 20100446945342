import React, { useState, useEffect } from 'react';
import './radarstat.scss';
import CountUp from 'react-countup';
import { LoadProgressStat } from '..';

const RadarStat = ({stat, title, mulai, type, bulan, tahun, period, proses}) => {
    
    return (
        <div className="radar">
            {
                proses === true ?
                <div style={{height: '25vw'}} className="detail-content-load">
                    <LoadProgressStat />
                </div> 
                :
                <>
                <div className="radar-count">
                <CountUp
                    start={0}
                    end={stat}
                    delay={0}
                    duration={10}
                    separator="."
                    decimals={type === "index" ? 2 : 0}
                    decimal="."
                    className="radar-text"
                    >
                        {({ countUpRef, start }) => (
                            <div>
                                <span ref={countUpRef} className="radar-text" />
                            </div>
                        )}
                    </CountUp>
                </div>
                {/* <p className="stat-text">{stat}</p> */}
                <p className="stat-text">{title}</p>
                {
                    bulan && tahun !== undefined ?
                    <p className="stat-text-period">{'( ' + bulan + ' - ' + tahun + ' )'}</p>
                    :
                    null ||
                    period !== undefined ?
                    <p className="stat-text-period">{'( ' + period + ' )'}</p>
                    :
                    null
                }
                </>
            }
        </div>
    )
}

export default RadarStat;
