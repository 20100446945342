import base64url from "base64url";
import CryptoJS from 'crypto-js';

// import crypto from 'crypto';

export const generatePKCE = () => {

  const sjcl = require('sjcl');
  let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_-~.';

  function randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) result +=
      chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
  const code_verifier = randomString(64, chars);
  const state_verifier = randomString(32, chars);

  // const hash = CryptoJS.SHA256(code_verifier);
  // const base64Digest = hash.toString(CryptoJS.enc.Base64);

  // const code_challenge = base64url.fromBase64(base64Digest);

  const hashBits = sjcl.hash.sha256.hash(code_verifier);
  const base64Digest2 = sjcl.codec.base64url.fromBits(hashBits);
  const codeChallenge = base64Digest2.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

  return {
    code_verifier: code_verifier,
    code_challenge: codeChallenge,
    state: state_verifier,
  }
}