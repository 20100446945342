/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './list_empty.scss';
import { ICListData2, ImageNotFound } from '../../../assets';
import { useTranslation } from 'react-i18next';

const ListEmpty = ({message_error}) => {
    const { t } = useTranslation();
    
    return (
        <div className="list-empty">
            <div className="list-empty-content">
                <img src={ICListData2} />
                {/* <div className="list-empty-content-text">{message_error}</div> */}
                <div className="list-empty-content-text">{t('data_not_found')}</div>
            </div>
        </div> 
    )
}

export default ListEmpty;
