import React from 'react';
import './timeline_pibpeb.scss';
import { useTranslation } from 'react-i18next';
import { ICCentang, ICKosong, ICSilang } from '../../../assets';

const TimeLinePibPeb = ({ status, time }) => {
    const { t } = useTranslation();

    const Lines = () => {
        return (
            <>
                <div className="timeline-border-white" />
                {
                    status !== null ?
                        status.toLowerCase().match('reject') ?
                            <img src={ICSilang} alt="ic_stat" />
                            :
                            <img src={ICCentang} alt="ic_stat" />
                        :
                        <img src={ICSilang} alt="ic_stat" />
                }
                <div className="timeline-border-white" />
            </>
        );
    };

    return (
        <div className="timelinepibpeb-container">
            <div className="timeline-icon">
                <Lines />
            </div>
            <div className="timeline-content">
                <p className="timeline-status">{status}</p>
                <p className="timeline-time">{time}</p>
            </div>
        </div>
    )
}

export default TimeLinePibPeb
