/* eslint-disable jsx-a11y/alt-text */
//import lib react
import React, { useEffect, useRef, useState } from 'react';
import './intr.scss';
// import { Chart } from 'react-charts';
import { Bar, Line } from 'react-chartjs-2';
import axios from 'axios';
import { Spinner, Button, ButtonGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Select from "react-dropdown-select";
import styled from '@emotion/styled';
import Ripples from 'react-ripples'
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";

//import local
import {
    ICSimulasiExport,
    ICPeraturan,
    ICPDF,
    ICDownload,
    ICAmerika,
    LogoBPOM,
    ImgFooterKurs,
    LogoKesdm,
    LogoKarantinaIkan,
    LogoKemenperin,
    ImageNotFound,
    ICArrowRightIntr,
} from '../../assets';
import { InputDate, InputSearch, InputSearchKurs } from '../../components/atoms';
import { CardIntr, ListKurs, ListSetting, ContentIntr, CarouselContentIntr, ListEmpty, LoadProgress } from '../../components';
import * as Api from '../../constants/api';
import { useHistory } from 'react-router-dom';
import BreadCrumbs from '../../components/molecules/BreadCrumbs';

const Intr = () => {

    //variable constant
    const { t } = useTranslation();
    const [list_kurs, setListKurs] = useState([]);
    const [file_kmk, setFileKmk] = useState('');
    const [no_kmk, setNoKmk] = useState('');
    const [filter_list_kurs, setFilterListKurs] = useState([]);
    const [isProgressKurs, setisProgressKurs] = useState(false);
    const [error_message_kurs, setErrorMessageKurs] = useState('');
    const [color_button_1, setColorButton_1] = useState('#F5F5F5');
    const [color_button_2, setColorButton_2] = useState('#F5F5F5');
    const [color_button_3, setColorButton_3] = useState('#F5F5F5');
    const [color_button_4, setColorButton_4] = useState('#F5F5F5');
    const [color_button_text_1, setColorButtonText_1] = useState('#2196F3');
    const [color_button_text_2, setColorButtonText_2] = useState('#2196F3');
    const [color_button_text_3, setColorButtonText_3] = useState('#2196F3');
    const [color_button_text_4, setColorButtonText_4] = useState('#2196F3');
    const [start_periode, setStartPeriode] = useState('');
    const [end_periode, setEndPeriode] = useState('');
    const [selected_currency, setSelectedCurrency] = useState('');
    const selected_currency_data = useRef([]);
    const [selected_currency_label, setSelectedCurrencyLabel] = useState('Select Currency..');
    const [selected_currency_flag, setSelectedCurrencyFlag] = useState('Select Currency..');
    const [list_select_currency, setListSelectCurrency] = useState([]);
    const [isProgressCurrency, setisProgressCurrency] = useState(false);
    // const [place_holder_currency, setPlaceHolderCurrency] = useState('dropdown-auto-placeholder');
    const [place_holder_currency, setPlaceHolderCurrency] = useState('dropdown-remove-placeholder');
    const [from_date, setFromDate] = useState('');
    const [to_date, setToDate] = useState('');
    const [periode, setPeriode] = useState('');
    const [is_graffik_kurs, setIsGraffikKurs] = useState(false);
    const [is_progress_graffik, setisProgressGraffik] = useState(false);
    const [data_graffik, setDataGraffik] = useState({});
    const [is_progress_peraturan, setisProgressPeraturan] = useState(false);
    const [list_peraturan, setListPeraturan] = useState([]);
    const [error_message_peraturan, setErrorMessagePeraturan] = useState('');
    const history = useHistory();
    const [lartas, setLartas] = useState('');

    // did mount
    useEffect(() => {
        ChangeColor(1);
        _get_data_kurs();
        _get_data_peraturan();
    }, []);

    //function

    //style dropdown
    const StyledItem = styled.div`
        color: #555;
        padding: 10px;
        border-radius: 3px;
        margin: 3px;
        cursor: pointer;
        > div {
            display: flex;
            align-items: center;
        }
        :hover {
            background: #f2f2f2;
        }
    `;

    const open_page = () => {
        const win = window.open(file_kmk, '_blank');
        if (win != null) {
            win.focus();
        }
    }

    const formatRp = value => {
        var angka = value.replace('.', ',');
        var split = angka.split(',');
        var sisa = split[0].length % 3;
        var rupiah = split[0].substr(0, sisa);
        var ribuan = split[0].substr(sisa).match(/\d{3}/gi);

        if (ribuan) {
            var separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }

        var rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        var prefix = undefined ? rupiah : rupiah ? 'Rp. ' + rupiah : '';

        return prefix;
    };


    const ChangeColor = (button_change) => {
        if (button_change === 1) {
            setPeriode(1);
            setFromDate('');
            setToDate('');

            setColorButton_1('#2196F3');
            setColorButton_2('#F5F5F5');
            setColorButton_3('#F5F5F5');
            setColorButton_4('#F5F5F5');

            setColorButtonText_1('#FFFFFF');
            setColorButtonText_2('#2196F3');
            setColorButtonText_3('#2196F3');
            setColorButtonText_4('#2196F3');
        } else if (button_change === 2) {
            setPeriode(3);
            setFromDate('');
            setToDate('');

            setColorButton_1('#F5F5F5');
            setColorButton_2('#2196F3');
            setColorButton_3('#F5F5F5');
            setColorButton_4('#F5F5F5');

            setColorButtonText_1('#2196F3');
            setColorButtonText_2('#FFFFFF');
            setColorButtonText_3('#2196F3');
            setColorButtonText_4('#2196F3');
        } else if (button_change === 3) {
            setPeriode(6);
            setFromDate('');
            setToDate('');

            setColorButton_1('#F5F5F5');
            setColorButton_2('#F5F5F5');
            setColorButton_3('#2196F3');
            setColorButton_4('#F5F5F5');

            setColorButtonText_1('#2196F3');
            setColorButtonText_2('#2196F3');
            setColorButtonText_3('#FFFFFF');
            setColorButtonText_4('#2196F3');
        } else if (button_change === 4) {
            setPeriode(12);
            setFromDate('');
            setToDate('');

            setColorButton_1('#F5F5F5');
            setColorButton_2('#F5F5F5');
            setColorButton_3('#F5F5F5');
            setColorButton_4('#2196F3');

            setColorButtonText_1('#2196F3');
            setColorButtonText_2('#2196F3');
            setColorButtonText_3('#2196F3');
            setColorButtonText_4('#FFFFFF');
        }
    }

    const _get_data_kurs_by_periode = (from_date_data, to_date_data) => {
        setisProgressGraffik(true);
        var from_date_convert = moment(from_date_data).format('YYYY-MM-DD');
        var to_date_convert = moment(to_date_data).format('YYYY-MM-DD');
        // const url = Api.KURS_BY_PERIODE + selected_currency[0].value + '&start_period='+from_date_convert+'&end_period='+to_date_convert;
        const url = Api.KURS_BY_PERIODE + selected_currency_data.current[0].value + '&start_period=' + from_date_convert + '&end_period=' + to_date_convert;
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                setisProgressGraffik(false);
                // console.log(response.status);
                if (response.status === 200) {
                    // console.log('get periode');
                    // console.log(response.data);
                    if (response.data.code === '01') {
                        let data_label = [];
                        let data_sets = [];
                        for (var i = 0; i < response.data.data.length; i++) {
                            data_label.push(response.data.data[i].tanggalAwal);
                            data_sets.push(response.data.data[i].nilai);
                        }
                        setDataGraffik(
                            {
                                labels: data_label,
                                datasets: [
                                    {
                                        // label: selected_currency[0].label,
                                        label: selected_currency_data.current[0].value,
                                        backgroundColor: 'rgba(255,99,132,0.2)',
                                        borderColor: 'rgba(255,99,132,1)',
                                        borderWidth: 1,
                                        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                                        hoverBorderColor: 'rgba(255,99,132,1)',
                                        data: data_sets
                                    }
                                ]
                            }
                        )
                    } else {
                        setDataGraffik({});
                    }
                } else {
                    setDataGraffik({});
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setisProgressGraffik(false);
                setDataGraffik({});
            });
    }

    const _get_data_kurs = () => {
        setisProgressKurs(true);
        setisProgressCurrency(true);
        const url = Api.KURS_HOME;
        // console.log('kurs', url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                // console.log('kurs data', response.data.data);
                setisProgressKurs(false);
                setisProgressCurrency(false);
                if (response.data.code === '01') {
                    setNoKmk(response.data.nomorKmk)
                    setListKurs(response.data.data);
                    setFilterListKurs(response.data.data);
                    setFileKmk(response.data.fileKmk);
                    setStartPeriode(response.data.start_period);
                    setEndPeriode(response.data.end_period);
                    let data_currency = [];
                    let set_default_currency = [];
                    for (var i = 0; i < response.data.data.length; i++) {
                        let currency = {
                            label: response.data.data[i].keterangan + ' - ' + response.data.data[i].kdKurs,
                            value: response.data.data[i].kdKurs,
                            flag: response.data.data[i].flag,
                        };
                        if (response.data.data[i].kdKurs === 'USD') {
                            set_default_currency.push(currency);
                        }
                        data_currency.push(currency);
                    }
                    setListSelectCurrency(data_currency);

                    selected_currency_data.current = set_default_currency;
                    setSelectedCurrency(set_default_currency);
                    setSelectedCurrencyLabel(set_default_currency[0].label);
                    setSelectedCurrencyFlag(removeDot(set_default_currency[0].flag));
                    setIsGraffikKurs(true);
                    let date_now = new Date();
                    date_now.setMonth(date_now.getMonth() - 1);
                    setFromDate(date_now);
                    let to_date = new Date(date_now);
                    to_date.setMonth(to_date.getMonth() + 1);
                    setToDate(to_date);
                    _get_data_kurs_by_periode(date_now, to_date);
                } else {
                    setListKurs([]);
                    setErrorMessageKurs(t('empty_list'));
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setisProgressKurs(false);
                setisProgressCurrency(false);
                setListKurs([]);
                setErrorMessageKurs(t('error_message'));
            });
    }

    const _get_data_peraturan = () => {
        setisProgressPeraturan(true);
        const url = Api.PERATURAN_HOME;
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                // console.log(response.data);
                setisProgressPeraturan(false);
                if (response.data.code === '01') {
                    setListPeraturan(response.data.data);
                } else {
                    setListPeraturan([]);
                    setErrorMessagePeraturan(t('empty_list'));
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setisProgressPeraturan(false);
                setListPeraturan([]);
                setErrorMessagePeraturan(t('error_message'));
            });
    }

    const filterList = (filterText) => {
        return list_kurs.filter(function (item) {
            return item.keterangan.toLowerCase().includes(filterText.toLowerCase());
        });
    }

    const changeToDate = (from_date) => {
        let to_date = new Date(from_date);
        to_date.setMonth(to_date.getMonth() + periode);
        setToDate(to_date);
        _get_data_kurs_by_periode(from_date, to_date);
    }

    const searchComodity = item => {
        history.push('/intr/detail-komoditas', {
            data: {
                lartas: item
            }
        });
    }

    // const handleKeyDown = (event) => {
    //     if (event.key === 'Enter') {
    //         //   console.log('do validate');
    //         //   console.log({lartas: event.target.value})
    //         searchComodity(event.target.value);
    //     }
    // }

    const removeDot = item => {
        var imageUrl = Api.IP_KONG_LNSW + item.slice(1);
        // console.log(imageUrl);
        return imageUrl;
    }

    return (
        <div className="row m-0 intr-break">
            {/* <BreadCrumbs /> */}
            <div className="section">

                <div className="intr-background col-md-12 p-0">
                    <div className="intr-content-title">
                        <div className="intr-content-sub-title">
                            <div className="intr-text-title">
                                Indonesia National Trade Repository
                            </div>
                            <div className="intr-text-message">
                                {t('content_title_intr')}
                            </div>
                            <div className="row m-0 justify-content-center">
                                <div className="col-md-6">
                                    <div className="intr-input-search">
                                        <InputSearch
                                            placeholder={t('find_something_intr')}
                                            onChange={e => setLartas(e.target.value)}
                                            onClick={() => searchComodity(lartas)}
                                            onKeyDown={() => searchComodity(lartas)}
                                        // onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="intr-menu col-md-12">
                    <CarouselContentIntr />
                </div>
            </div>
            <div className="intr-kurs col-md-12">
                <div className="intr-kurs-title col-md-12 mb-5">
                    <div className="intr-kurs-text-title">
                        {t('tax_exchange_rates')}
                    </div>
                </div>
                <div className="intr-kurs-content row m-0">
                    <div className="intr-kurs-graffik col-md-8 col-sm-12 col-xs-12 mb-3">
                        <div className="col-md-12 p-0">
                            <div className="row m-0">
                                <div className="col-md-12 p-0">
                                    <div className="intr-graffik-title-text mb-2">{t('period_range')}</div>
                                    <div style={{ display: "flex", alignItems: 'center' }}>
                                        <ButtonGroup>
                                            <Button className="intr-graffik-button-text" style={{ color: color_button_text_1, backgroundColor: color_button_1, borderColor: color_button_1 }} onClick={() => ChangeColor(1)}>{"1 " + t('month')}</Button>
                                            <Button className="intr-graffik-button-text" style={{ color: color_button_text_2, backgroundColor: color_button_2, borderColor: color_button_2 }} onClick={() => ChangeColor(2)}>{"3 " + t('month')}</Button>
                                            <Button className="intr-graffik-button-text" style={{ color: color_button_text_3, backgroundColor: color_button_3, borderColor: color_button_3 }} onClick={() => ChangeColor(3)}>{"6 " + t('month')}</Button>
                                            {/* <Button className="intr-graffik-button-text" style={{ color: color_button_text_4, backgroundColor: color_button_4, borderColor: color_button_4 }} onClick={() => ChangeColor(4)}>{"12 " + t('month')}</Button> */}
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-2 p-1">
                                    <div className="intr-graffik-currency_text mb-2">{t('select_currency')}</div>
                                    <Select
                                        multi={false}
                                        searchable={false}
                                        options={list_select_currency}
                                        disabled={periode === '' ? true : false}
                                        values={[]}
                                        // style={{ borderWidth: '0px', borderBottomWidth: '1px', borderColor: '#666666' }}
                                        className={place_holder_currency}
                                        loading={isProgressCurrency}
                                        onChange={(value) => {
                                            console.log(value);
                                            selected_currency_data.current = value;
                                            setSelectedCurrency(value);
                                            setSelectedCurrencyLabel(value[0].label);
                                            setSelectedCurrencyFlag(removeDot(value[0].flag));
                                            setPlaceHolderCurrency('dropdown-remove-placeholder');
                                            setIsGraffikKurs(true);
                                            setFromDate('');
                                            setToDate('');
                                        }}
                                        contentRenderer={({ props, state }) => (
                                            <div>
                                                {selected_currency_label === 'Select Currency..' ?
                                                    <div style={{ cursor: 'pointer', display: "flex" }} className="col-md-12 p-0">
                                                        <div className="intr-graffik-input-placeholder">{selected_currency_label}</div>
                                                    </div>
                                                    :
                                                    <div style={{ cursor: 'pointer', display: "flex" }} className="col-md-12 p-0">
                                                        <div className="intr-graffik-input-dropdown">{selected_currency_label}</div>
                                                        <img style={{ height: '17px', width: '25px', marginLeft: '7px' }} src={selected_currency_flag} />
                                                    </div>
                                                }
                                            </div>
                                        )}
                                        itemRenderer={({ item, methods }) => (
                                            <StyledItem>
                                                <div onClick={() => methods.addItem(item)}>
                                                    <div className="intr-graffik-text-dropdown">
                                                        {item.label}
                                                    </div>
                                                    <img style={{ height: '17px', width: '25px', marginLeft: '7px' }} src={removeDot(item.flag)} />
                                                </div>
                                            </StyledItem>
                                        )}
                                    />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-6 mb-2 p-1">
                                    <div className="intr-graffik-currency_text mb-2">{t('from')}</div>
                                    <InputDate
                                        Date={from_date}
                                        onChange={(date) => {
                                            setFromDate(date);
                                            changeToDate(date);
                                        }}
                                        isDate={is_graffik_kurs === false ? true : false}
                                        placeHolder={t('from_date')}
                                    />
                                </div>
                                <div className="col-md-3 col-sm-6 col-xs-6 mb-2 p-1">
                                    <div className="intr-graffik-currency_text mb-2">{t('to')}</div>
                                    <InputDate
                                        Date={to_date}
                                        onChange={date => setToDate(date)}
                                        isDate={true}
                                        placeHolder={t('to_date')}
                                    // className="disabled-input-date"
                                    // disabled={isDate}
                                    />
                                </div>
                            </div>
                        </div>
                        <div height={180} className="row m-1 border p-3">
                            <Line
                                className="col-md-12 p-0"
                                data={data_graffik}
                                height={180}
                                options={{
                                    tooltips: {
                                        bodySpacing: 10,
                                        titleMarginBottom: 15,
                                        callbacks: {
                                            label: function (tooltipItem, data) {
                                                var label = data.datasets[tooltipItem.datasetIndex].label || '';
                                                if (label) {
                                                    label += ' : ';
                                                }
                                                var value = formatRp(tooltipItem.yLabel.toString())
                                                label += value;
                                                return label;
                                            },
                                            title: function (tooltipItem, data) {
                                                var value = moment(tooltipItem[0].xLabel).format('MMMM');
                                                return value;
                                            }
                                        }
                                    },
                                    legend: {
                                        display: false
                                    },
                                    scales: {
                                        xAxes: [{
                                            ticks: {
                                                // Include a dollar sign in the ticks
                                                callback: function (value, index, values) {
                                                    return moment(value).format('DD MMMM');
                                                },
                                            }
                                        }],
                                        yAxes: [{
                                            ticks: {
                                                //   beginAtZero: true,
                                                // Return an empty string to draw the tick line but hide the tick label
                                                // Return `null` or `undefined` to hide the tick line entirely
                                                userCallback: function (value, index, values) {
                                                    // Convert the number to a string and splite the string every 3 charaters from the end
                                                    value = value.toString();
                                                    value = value.split(/(?=(?:...)*$)/);

                                                    // Convert the array to a string and format the output
                                                    value = value.join('.');
                                                    return 'Rp. ' + value;
                                                }
                                            }
                                        }]
                                    },
                                    responsive: true,
                                }}
                            />
                            {is_progress_graffik === true ?
                                <LoadProgress />
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="intr-kurs-list col-md-4 col-sm-12 col-xs-12 mb-3">
                        <div style={{ height: '100px' }}>
                            <div className="intr-kurs-title-text">{t('effective_date') + ' ' + moment(start_periode).format('DD MMMM YYYY') + ' - ' + moment(end_periode).format('DD MMMM YYYY')}</div>
                            <div style={{ display: "flex", marginTop: '1vw' }} className="align-items-center">
                                <div className="intr-kurs-info-text">{t('copy_of_decree')}</div>
                                <div onClick={() => open_page()} style={{ display: "flex", marginLeft: '1vw', cursor: 'pointer' }} className="btn btn-outline-dark">
                                    <img src={ICPDF} className="intr-icon" />
                                    <div style={{ marginLeft: '0.2vw' }} className="intr-graffik-link-text">{t('read')}</div>
                                </div>
                                {/* <div style={{display: "flex", marginLeft: '1vw'}}>
                                    <img src={ICDownload} className="intr-icon" />
                                    <div style={{marginLeft: '0.2vw'}} className="intr-graffik-link-text">{t('download')}</div>
                                </div> */}
                            </div>
                        </div>
                        <div className="intr-kurs-list-content col-md-12 p-0">
                            <div style={{ padding: '1vw', textAlign: 'center' }}>
                                <div className="intr-kurs-list-title">{t('tax_exchange_rates') + ' ' + no_kmk}</div>
                                <div className="intr-kurs-list-title-line"></div>
                                <div style={{ display: "flex", justifyContent: 'center', marginTop: '1.5vw' }}>
                                    <InputSearchKurs
                                        placeholder={t('search_currency')}
                                        onChange={event => {
                                            setFilterListKurs(filterList(event.target.value));
                                        }} />
                                </div>
                            </div>
                            {isProgressKurs === true ?
                                <div className="intr-kurs-list-scroll">
                                    <Spinner color="primary" />
                                </div> :
                                list_kurs.length === 0 ?
                                    <ListEmpty message_error={error_message_kurs} />
                                    :
                                    <div className="intr-kurs-list-scroll">
                                        {filter_list_kurs.map((item) =>
                                            <ListKurs image={removeDot(item.flag)} title={item.keterangan} nilai_kurs={item.nilai} nilai_perubahan={item.percentage_change} status={item.direction} kd_kurs={item.kdKurs} />
                                        )}
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className="intr-setting col-md-12 mb-3">
                <div className="intr-setting-title col-md-12 p-0">
                    <div className="intr-setting-text-title">
                        {t('regulatory_updates')}
                    </div>
                </div>
                <div className="intr-setting-content row m-0">
                    {is_progress_peraturan === true ?
                        <div className="intr-setting-list-load col-md-6">
                            <LoadProgress />
                        </div>
                        :
                        list_peraturan.length === 0 ?
                            <div className="intr-setting-list-load col-md-6">
                                <ListEmpty message_error={error_message_peraturan} />
                            </div>
                            :
                            <div className="intr-setting-list col-md-6">
                                <div className="intr-setting-list-content">
                                    <div>
                                        {list_peraturan.map((item) =>
                                            <ListSetting
                                                onClick={() => { history.push("/intr/detail-peraturan", { data_peraturan: item }) }}
                                                image={item.ga_logo}
                                                nomor={item.nomor_peraturan}
                                                bidang={item.bidang_peraturan}
                                                title={item.judul_peraturan}
                                                desc={item.keterangan}
                                                date={moment(item.tanggal_peraturan).format('DD MMMM YYYY')}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="intr-setting-view-more">
                                    <Ripples onClick={() => history.push("/intr/peraturan")}>
                                        <div style={{ alignItems: 'center', display: 'flex', cursor: 'pointer' }}>
                                            <div className="intr-setting-text-view-more">{t('view_more')}</div>
                                            <img style={{ marginLeft: '0.5vw' }} src={ICArrowRightIntr} />
                                        </div>
                                    </Ripples>
                                </div>
                            </div>
                    }
                    <div className="intr-setting-image col-md-6">
                        <img style={{ width: '35vw' }} src={ImgFooterKurs} className="intr-icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intr;
