import React from 'react'
import { IcDotCaro } from '../../../assets'

const DotCaro = ({ onClick, ...rest }) => {
    return (
        <div onClick={onClick}>
            <img src={IcDotCaro} alt="dot" />
        </div>
    )
}

export default DotCaro
