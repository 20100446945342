import React, { useState, useEffect, useRef } from 'react'
import './listprofil.scss'

const ListProfil = ({ gambar, deskripsi, judul, header }) => {
   const [open, setOpen] = useState(false)
   const closeModal = () => setOpen(false)
   return (
      <div className="list-item">
         <div className="list-item-wrapper m-0">
            <img
               src={gambar}
               alt={deskripsi}
               className="gambar-profil col-md-6"
            />
            <div className="content-title col-md-6">
               <p className="title">{judul}</p>
               <p className="subtitle">{header}</p>
               <p className="subtitle"></p>
               <p className="subtitle">{deskripsi}</p>
               {/* <a className="close" onClick={closeModal}>
            &times;
          </a> */}
            </div>
         </div>
      </div>
   )
}

export default ListProfil
