import React, { useState, useEffect } from 'react'
import './korelasiHS.scss'
import { useTranslation } from 'react-i18next'
import { InputSearch } from '../../../components/atoms'
import { useHistory } from 'react-router-dom'
import { LoadProgress } from '../../../components'
import { Penelusuran_BG } from '../../../assets'

const KorelasiHS = () => {
   const { t } = useTranslation()
   const [kodeHS, setKodeHS] = useState()
   const [is_progress, setisProgress] = useState(false)
   const history = useHistory()

   const searchKodeHs = (kodeHS) => {
      history.push('/korelasi-btki/detail', {
         data: {
            kode_hs: kodeHS,
         },
      })
   }

   return (
      <div className="penelusuran-korelasi-container">
         {
            is_progress === true ? (
               <div style={{ height: '25vw' }} className="detail-content-load">
                  <LoadProgress />
               </div>
            ) : (
               <>
                  <div className="penelusuran-background">
                     <img
                        //   src={CallImage(item.nama_img)}
                        src={Penelusuran_BG}
                        className="penelusuran-background-img"
                        alt="bg"
                     />
                     <div className="penelusuran-content-title">
                        <div className="penelusuran-content-sub-title">
                           <div className="penelusuran-text-title">
                              Korelasi BTKI 2022
                           </div>
                           <div className="penelusuran-text-message">
                              Korelasi BTKI 2022 disusun berdasarkan Tabel
                              Korelasi AHTN 2022. Mohon masukkan 8 (delapan)
                              digit kode pos tarif BTKI 2017 tanpa tanda titik
                              (.)
                           </div>
                           <div className="row m-0 justify-content-center mt-5">
                              <div className="col-md-5">
                                 <InputSearch
                                    placeholder={t('enter_kode2017')}
                                    onChange={(e) => setKodeHS(e.target.value)}
                                    onKeyDown={() => searchKodeHs(kodeHS)}
                                    onClick={(e) => searchKodeHs(kodeHS)}
                                 />
                              </div>
                           </div>
                           <div className="disclaimer-text-message">
                              Disclaimer: Korelasi pos tarif bersifat referensi.
                              Informasi terkait sistem klasifikasi barang maupun
                              pembebanan tarif Bea Masuk mengacu pada Peraturan
                              Menteri Keuangan Nomor 26/PMK.010/2022 tentang
                              Penetapan Sistem Klasifikasi Barang dan Pembebanan
                              Tarif Bea Masuk atas Barang Impor (BTKI 2022)
                           </div>
                        </div>
                     </div>
                  </div>
               </>
            )
            //  ) : (
            //     judul.map((item, index) => (
            //        <div className="penelusuran-background">
            //           <img
            //              src={CallImage(item.nama_img)}
            //              className="penelusuran-background-img"
            //              alt="bg"
            //           />
            //           <div className="penelusuran-content-title">
            //              <div className="penelusuran-content-sub-title">
            //                 <div className="penelusuran-text-title">
            //                    {parseHtml(item.header)}
            //                 </div>
            //                 <div className="penelusuran-text-message">
            //                    {parseHtml(item.ur)}
            //                 </div>
            //                 <div className="row m-0 justify-content-center mt-5">
            //                    <div className="col-md-5">
            //                       <InputSearch
            //                          placeholder={t('enter_ntpn')}
            //                          onChange={(e) => SetNtpn(e.target.value)}
            //                          onKeyDown={() => searchNtpn()}
            //                          onClick={() => searchNtpn()}
            //                       />
            //                    </div>
            //                 </div>
            //              </div>
            //           </div>
            //        </div>
            //     ))
            //  )
         }
      </div>
   )
}

export default KorelasiHS
