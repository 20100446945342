import React, { useEffect, useState } from 'react';
import './footer.scss';
import { LogoKemenKeu, ICFb, ICYt, ICIg, ICGP, ICAS, ICISO } from '../../../assets';
import { Gap } from '../../atoms';
import { useTranslation } from 'react-i18next';
import { isAndroid, isIOS } from 'react-device-detect';

const Icon = ({ img, link_sosmed }) => {
    return (
        <div className="icon-wrapper" >
            <a target='_blank' href={link_sosmed}>
                <img className="icon-medsos" src={img} alt="icon" />
            </a>
        </div>
    )
}

const Footer = () => {
    const { t } = useTranslation();
    const [year, setYear] = useState('');

    //did mount
    useEffect(() => {
        get_year();
    }, []);

    const get_year = () => {
        let year = new Date().getFullYear();
        // console.log('year', year);
        setYear(year);
    };

    return (
        <>
            <div className="footer">
                <footer className="site-footer">
                    {/* <div className="container-fluid"> */}
                    <div className="row">
                        <div className="col-sm-12 col-md-4">
                            <h4 className="mb-3">{t('foot_info1')} <span className="font-weight-bold">{t('foot_info2')}</span></h4>
                            <ul className="footer-links">
                                {/* <li><div className="title-foot-subject">{t('phone')}</div></li> */}
                                {/* <li><a>021-21480000, 021-21480001, 021-21480002</a></li> */}
                                {/* <li><a>021-21480007</a></li> */}
                                {/* <li><a className="title-foot-subject">Fax</a></li>
                                <li><a>021-21480005, 021-21480008</a></li> */}
                                <li><div className="title-foot-subject">Email</div></li>
                                <li><a>info@insw.go.id</a></li>
                                <li className="mt-3"><img className="logo" src={LogoKemenKeu} width="280px" alt="Logo Kemenkeu" /></li>
                                <li className="mt-2 pr-3">{t('foot_address')}</li>
                            </ul>
                        </div>

                        <div className="col-sm-12 col-md-5">
                            <div className="col-md-10 p-0">
                                {/* <h4 className="mb-3">{t('foot_lartas1')} <span className="font-weight-bold">{t('foot_lartas2')}</span></h4>
                                <ul className="footer-links-arrow">
                                    <li className="footer-center-list">{t('foot_lartas3')}</li>
                                    <li className="footer-center-list">{t('foot_lartas4')}</li>
                                    <li className="footer-center-list">{t('foot_lartas5')}</li>
                                    <li className="footer-center-list">{t('foot_lartas6')}</li>
                                </ul> */}
                                <h4 className="mb-3">{t('foot_sitemap1')} <span className="font-weight-bold">{t('foot_sitemap2')}</span></h4>
                                <ul className="footer-links-arrow">
                                    <li className="footer-center-list">{t('foot_sitemap3')} <a className="view-sitemap" href="/site-map" target="_blank">{t('foot_sitemap4')}</a></li>
                                </ul>
                                <h4 className="mb-3">Contact <span className="font-weight-bold">Center</span></h4>
                                <ul className="footer-links-arrow">
                                    <li className="footer-center-list">{t('foot_contact_center')} <a className="view-sitemap" href="/contact-center" target="_blank">{t('foot_lihat_contact_center')}</a></li>
                                </ul>
                                <h4 className="mb-3"><span className="font-weight-bold">{t('foot_pengaduan_masyarakat')}</span></h4>
                                <ul className="footer-links-arrow">
                                    <li className="footer-center-list">{t('foot_terkait_disiplin')} <a className="view-sitemap" href="https://wise.kemenkeu.go.id" target="_blank">Wise Kemenkeu</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-xs-6 col-md-3">
                            <div className="mb-3 detail-footer">
                                <div>
                                    <a href="https://www.bsigroup.com/en-IE/validate-bsi-issued-certificates/client-directory-profile/LEMBAG-0047829406-000" target="_blank">
                                        <img src={ICISO} className="iso-footer" />
                                    </a>
                                </div>
                                <h4 className="font-weight-bold mb-3">{t('home_video_unduh')}</h4>
                                <div className="row">
                                    {isAndroid ? (
                                        <div className="col-4 m-0 mb-1">
                                            <Icon img={ICGP} link_sosmed="https://play.google.com/store/apps/details?id=com.mobile_insw" />
                                        </div>
                                    ) : isIOS ? (
                                        <div className="col-4 m-0 mb-1">
                                            <Icon img={ICAS} link_sosmed="https://apps.apple.com/id/app/insw-mobile/id1571066113" />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="col-4 m-0 mb-1">
                                                <Icon img={ICGP} link_sosmed="https://play.google.com/store/apps/details?id=com.mobile_insw" />
                                            </div>
                                            <div className="col-4 m-0 mb-1">
                                                <Icon img={ICAS} link_sosmed="https://apps.apple.com/id/app/insw-mobile/id1571066113" />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="mt-3 detail-footer">
                                <h4 className="font-weight-bold mb-3">{t('sosmed')}</h4>
                                <div className="row row-custom">
                                    <div className="col-2">
                                        <Icon img={ICFb} link_sosmed="https://www.facebook.com/LNSWKemenkeu" />
                                    </div>
                                    <div className="col-2">
                                        <Icon img={ICYt} link_sosmed="https://www.youtube.com/channel/UCy9522hCwnH3_e8yEZWMV5Q" />
                                    </div>
                                    <div className="col-2">
                                        <Icon img={ICIg} link_sosmed="https://www.instagram.com/officialinsw/?hl=en" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}

                    </div>
                </footer>
                <div className="copyright">
                    <p>{t('foot_copyright')} LNSW {year}. {t('foot_all_right_reserved')}</p>
                </div>
            </div>
        </>
    )
}

export default Footer;
