import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../../constants/api';
import './index.scss';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const InfoSertaMerta = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        _get_data_sertamerta();
    }, []);

    const CallImage = (image_url) => {
        // var replace_url = image_url.substring(1);
        var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        // console.log(url_image);
        return url_image;
    }

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    const _get_data_sertamerta = () => {
        const url = Api.SERTA_MERTA + '?lang=' + localStorage.getItem('language').toUpperCase();
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                // console.log("detail_kontak", response.data.data);
                if (response.data.code === '01') {
                    setData(response.data.data);
                } else {
                    setData([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setData([]);
            });
    }

    return (
        <>
            <div className="serta-merta row m-0">
                <div className="col-md-12">
                    <div className="sm-content text-center">
                        <h3 className="sm-title">
                            Informasi Publik Wajib Serta Merta
                        </h3>
                    </div>
                </div>

                <div className="text-left sm-content-list">
                    {data.map((item, index) =>

                        <div className="col-md-12" key={index}>
                            <Link to={{
                                pathname: "/detail-wajib-serta-merta",
                                state: item
                            }}>
                                <div className="card col-md-12 p-3">
                                    <div className="titles">
                                        {parseHtml(item.header)}
                                    </div>
                                    <div className="content">
                                        {
                                            item.konten.length > 300 ?
                                                (
                                                    <div className="">
                                                        {parseHtml(item.konten.slice(0, 300))}...
                                                    </div>
                                                ) : (
                                                    <div className="">
                                                        {parseHtml(item.konten)}
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            </Link>
                        </div>
                    )}
                </div>

            </div>
        </>
    )
}

export default InfoSertaMerta;
