//BASIC AUTH
let username = process.env.REACT_APP_BASIC_AUTH_USERNAME
let password = process.env.REACT_APP_BASIC_AUTH_PASSWORD
let basicAuth = 'Basic ' + btoa(username + ':' + password)
export const BASIC_AUTH = basicAuth
//=====================================================================================================================================
// HOST
// export const IP_INSW = 'https://virtserver.swaggerhub.com/peupl/INSW/1.0.0/'; //IP INSW
// export const IP_LNSW_BACKEND_TEST = 'http://10.8.3.73/api/';
// export const IP_LNSW_BACKEND = 'http://10.8.3.198:3003/api/mobile/';
// export const IP_DEV_TEL_U = 'http://62.210.208.244:8000/api/';
// export const IP_LNSW_HAIPEDIA = 'http://10.242.231.176/haipedia/api/v1/';
//Kong
// export const IP_LNSW_KONG = 'http://10.8.3.198:8000/api/mobile/';
// export const IP_KONG_LNSW = 'https://insw-dev.ilcs.co.id';
export const IP_KONG_LNSW_DEV = process.env.REACT_APP_API_URL_SERVER_DEV
export const IP_KONG_LNSW = process.env.REACT_APP_API_URL_SERVER

export const IP_LNSW_KONG = IP_KONG_LNSW + '/api/mobile/'
export const IP_LNSW_KONG_LOCAL = process.env.REACT_APP_API_URL_KONG_LOCAL
export const IP_LNSW_CMS_DEV = IP_KONG_LNSW_DEV + '/api/cms/'
export const IP_LNSW_CMS_PROD = IP_KONG_LNSW + '/api/cms/'
export const IP_LNSW_CMS = process.env.REACT_APP_ENV_MODE === "DEV" ? IP_LNSW_CMS_DEV : IP_LNSW_CMS_PROD
// export const IP_LNSW_CMS = process.env.REACT_APP_ENV_MODE === "DEV" ? IP_LNSW_CMS_DEV : IP_LNSW_CMS_PROD

// ------------ API Local localhost:3000 ----------- //
// export const IP_LNSW_CMS = IP_KONG_LNSW + 'api/cms/'
// ------------------------------------------------ //

export const IP_LNSW_KONG_WEB = process.env.REACT_APP_ENV_MODE === "DEV" ? IP_KONG_LNSW_DEV+'/api/' : IP_KONG_LNSW + '/api-prod-ba/'
// export const IP_LNSW_KONG = 'https://insw-dev.ilcs.co.id/api/mobile/';
// export const IP_BERITA = 'http://10.8.3.125:3001/';
//link eska
export const LINK_ESKA = process.env.REACT_APP_API_URL_LINK_ESKA
//=====================================================================================================================================
// LHV
export const GET_NO_LHV = IP_KONG_LNSW + '/api-prod-ba/simbara/getLHV?noLhv='
// Korelasi HS
export const GET_KODE_HS =
   IP_KONG_LNSW + '/api-prod/ref/korelasi-hs/search?keyword='
// =====================================================================================================================================
//INTR
export const KURS_HOME = IP_LNSW_KONG_WEB + 'ref/kurs-bkf' // kurs halaman depan
export const KURS_BY_DATE = IP_LNSW_KONG_WEB + 'ref/kurs' // kurs by date
export const KURS_BY_PERIODE =
   IP_LNSW_KONG_WEB + 'ref/kurs/search-period?kdKurs=' // kurs by periode
export const PERATURAN_HOME = IP_LNSW_KONG_WEB + 'ref/peraturan-home' // peraturan halaman depan
//=====================================================================================================================================
//BERITA
export const BERITA_TERKINI = IP_LNSW_CMS + 'berita?kategori=1' // berita terkini
export const BERITA_POPULER = IP_LNSW_CMS + 'berita?kategori=2' // berita populer -> berita media
export const BERITA_POPULER_PAGING = IP_LNSW_CMS + 'berita_by_category?'
export const BERITA_DETAIL = IP_LNSW_CMS + 'berita/' // detail berita
export const ALL_BERITA = IP_LNSW_CMS + 'berita/all?' // all berita
export const SEARCH_BERITA = IP_LNSW_CMS + 'berita/search?' // all berita
//=====================================================================================================================================
//ARTIKEL
export const GET_DATA_ARTIKEL = IP_LNSW_CMS + 'berita?kategori=3' // get data artikel
export const ARTIKEL_DETAIL = IP_LNSW_CMS + 'berita/' // detail artikel
export const SEARCH_ARTIKEL = IP_LNSW_CMS + 'artikel/search?' // search berita
export const ALL_ARTIKEL = IP_LNSW_CMS + 'artikel?' // all artikel
//Press Release
export const GET_DATA_PR = IP_LNSW_CMS + 'berita?kategori=4' // get data Press Release
export const PR_DETAIL = IP_LNSW_CMS + 'pressRelease/' // detail Pr
export const SEARCH_PR = IP_LNSW_CMS + 'pressRelease/search?' // search pr
export const ALL_PR = IP_LNSW_CMS + 'pressRelease?' // all pr
//=====================================================================================================================================
//HSCODE
export const HSCODE_SEARCH = IP_KONG_LNSW + '/api/ref/hscode/search?keyword=' // Hs code search
//=====================================================================================================================================
//=====================================================================================================================================
//HEADER MENU
export const GET_HEADER_MENU = IP_KONG_LNSW + '/api-prod-ba/cms/menu' // berita highlight halaman depan
//=====================================================================================================================================
//PERATURAN
export const PERATURAN_SEARCH =
   IP_LNSW_KONG_WEB + 'ref/peraturan/search?keyword=' // kurs halaman depan
export const GET_TOPIC = IP_LNSW_KONG_WEB + 'ref/jenisPeraturan'
export const GET_SUBTOPIC = IP_LNSW_KONG_WEB + 'ref/subtopic/get/'
export const GET_PERATURAN_BY_TEMATIC =
   IP_LNSW_KONG_WEB + 'ref/peraturan/searchJenis?'
//REFERENSI
export const KEMASAN_SEARCH = IP_LNSW_KONG_WEB + 'ref/'
// LIST AUTH TRADERS
export const GET_TRADERS = IP_LNSW_KONG_WEB + 'registrasi/getAllNibHdr?'
export const GET_AMS = IP_LNSW_KONG_WEB + 'ref/ams'
// ATR
export const LIST_ATR = IP_LNSW_KONG_WEB + 'ref/topicAtr'
export const ATR = IP_LNSW_KONG_WEB + 'ref/atr/web?bidang='
//Banner Slider Home
export const BANNER_SLIDER = IP_LNSW_KONG_WEB + 'cms/slider'
//Kemen Slider Home
export const KEMEN_SLIDER = IP_LNSW_KONG_WEB + 'cms/sliderFooter'
//PROFIL
export const DETAIL_PROFIL = IP_LNSW_CMS + 'detailProfil'
export const TUFUNG = IP_LNSW_CMS + 'tugasFungsi'
export const DETAIL_TUFUNG = IP_LNSW_CMS + 'detailTugasFungsi'
export const KODE_ETIK = IP_LNSW_CMS + 'kodeEtik'
export const DETAIL_KONTAK = IP_LNSW_CMS + 'detailKontakKami'
export const KONTAK = IP_LNSW_CMS + 'kontakKami'
export const VISI_MISI = IP_LNSW_CMS + 'visimisi'
//=====================================================================================================================================
//KOMODITAS
export const KOMODITAS_SEARCH = IP_LNSW_CMS + 'hscode?keyword='
export const KOMODITAS_DETAIL =
   IP_LNSW_KONG_WEB + 'ref/hscode/komoditas?hs_code='

export const KOMODITAS_DETAIL_PERIZINAN = IP_KONG_LNSW + '/api-prod/selaras/informasi/izin/get?idDokumen='
export const DETAIL_PERIZINAN_JENIS_API_TIPE_TRADER = IP_KONG_LNSW + '/api-prod/selaras/informasi/ijin/slot/find?kdSlotIjin='
export const DETAIL_PERIZINAN_KD_SYARAT = IP_KONG_LNSW + '/api-prod/selaras/ruleset/dokumenRuleset/get?idDokumen='
export const DETAIL_PERIZINAN_KOMODITI_DIATUR = IP_KONG_LNSW + '/api-prod/selaras/ruleset/izin/hs/get?idDokumen='
export const DETAIL_PERIZINAN_SATUAN = IP_KONG_LNSW + '/api-prod-ba/ref/satuan/lartas?'
//SEJARAH
export const GET_COVER_SEJARAH = IP_LNSW_CMS + 'sejarah?' // cover sejarah
export const GET_LIST_SEJARAH = IP_LNSW_CMS + 'detailSejarah?' // list sejarah
//=====================================================================================================================================
//Tracking
export const TRACKING_DETAIL = IP_LNSW_KONG + 'tracking/' // path root tracking
export const CETAK_ECOO = IP_KONG_LNSW + '/api-prod/reports/print_skp?iv='
//=====================================================================================================================================
//FOTO DOCUMENT
export const GET_DATA_FOTO = IP_LNSW_CMS + 'photo' // get data foto
export const DETAIL_FOTO = IP_LNSW_CMS + 'photo/' // get data foto
//=====================================================================================================================================
//VIDEO DOCUMENT
export const GET_DATA_VIDEO = IP_LNSW_CMS + 'video' // get data foto
export const DETAIL_VIDEO = IP_LNSW_CMS + 'video/' // get data foto
//=====================================================================================================================================
//SIMULASI
export const GET_TARIF_BK =
   IP_KONG_LNSW + '/api-prod-ba/ref/btbmiTarifBk/getByHsCode?' // get data tarif BK
export const GET_TARIF_BK_BY_HSCODE =
   IP_KONG_LNSW + '/api-prod-ba/ref/btbmiTarifBk/getByHsCodeOnly?' // get data tarif BK param hscode only
export const GET_TARIF_BM =
   IP_KONG_LNSW + '/api-prod-ba/ref/btbmiHs/search?keyword=' // get data tarif BM
export const GET_NEGARA = IP_KONG_LNSW + '/api-prod-ba/ref/negara' // get data Negara
export const GET_MODA = IP_KONG_LNSW + '/api-prod-ba/ref/moda' // get data moda
export const GET_PREFERENCE = IP_KONG_LNSW + '/api-prod-ba/ref/btbmiPreference' // get data preference
export const GET_PREFERENCE_COUNTRY =
   IP_KONG_LNSW + '/api-prod-ba/ref/btbmiPreference' // get data preference by negara
export const SEARCH_FTA =
   IP_KONG_LNSW + '/api-prod-ba/ref/btbmiPreference/searchFta?' // search fta
// export const CHECK_LARTAS_SELARAS = IP_KONG_LNSW + '/api-prod-ba/selaras/ruleset/getRulesetHs?keyword='; // check lartas
export const CHECK_LARTAS =
   IP_KONG_LNSW + '/api-prod-ba/ref/hscode/ImporEksporKomoditas?hs_code=' // check lartas
//=====================================================================================================================================
//=====================================================================================================================================
//INFO PUBLIK
export const PROFIL_PPID = IP_LNSW_CMS + 'profilPpid'
export const DETAIL_KONTAK_PPID = IP_LNSW_CMS + 'detailKontakPpid'
export const SERTA_MERTA = IP_LNSW_CMS + 'informasiPublikSertaMerta'
export const STATISTIK = IP_LNSW_CMS + 'statistikInformasiPublik'
export const SETIAP_SAAT = IP_LNSW_CMS + 'informasiPublikSetiapSaat'
export const BERKALA = IP_LNSW_CMS + 'informasiPublikWajibBerkala'
export const PROSEDUR = IP_LNSW_CMS + 'prosedurInformasiPublik'
export const FORM_PROSEDUR = IP_LNSW_CMS + 'formulir'
export const PPID_TINGKAT1 = IP_LNSW_CMS + 'informasiPublikTingkat1'
export const AGENDA_LNSW = IP_LNSW_CMS + 'agendaLnsw'
export const RIWAYAT_PENGHARGAAN = IP_LNSW_CMS + 'riwayatPenghargaan'
export const PROSEDUR_PENGAJUAN_KEBERATAN = IP_LNSW_CMS + 'prosedurKeberatan'
export const PROSEDUR_PENGAJUAN_SENGKETA_INFORMASI = IP_LNSW_CMS + 'prosedurSengketaInformasi'
export const PROSEDUR_INFORMASI_PUBLIK = IP_LNSW_CMS + 'prosedurInformasiPublik'
export const LHKPN_PIMPINAN = IP_LNSW_CMS + 'lhkpnPimpinanCMS'

//=====================================================================================================================================
//STRUKTUR ORGANISASI
export const GET_STRUKTUR = IP_LNSW_CMS + 'detailStrukturOrganisasi?'
//=====================================================================================================================================
//JUDUL
export const JUDUL = IP_LNSW_CMS + 'header?id_menu='
//=====================================================================================================================================
//PUSAT BANTUAN
export const PUSAT_BANTUAN_VIDEO = IP_LNSW_CMS + 'pusatBantuan?kategori=video'
export const PUSAT_BANTUAN_FAQ = IP_LNSW_CMS + 'pusatBantuan?kategori=faq'
export const PUSAT_BANTUAN_PANDUAN =
   IP_LNSW_CMS + 'pusatBantuan?kategori=panduan'
export const PUSAT_BANTUAN_SEARCH = IP_LNSW_CMS + 'pusatBantuan/search'
export const PUSAT_BANTUAN_KAT_FAQ = IP_LNSW_CMS + 'kategoriFaq'
export const KAT_FAQ_QUESTION =
   IP_LNSW_CMS + 'pusatBantuan/faq?subkategori_faq='
export const PUSAT_BANTUAN_KAT_FAQ_QUESTION =
   IP_LNSW_CMS + 'kategoriFaq/search/'
//=====================================================================================================================================
//MAJALAH
export const MAJALAH = IP_LNSW_CMS + 'magazine?'
export const LAPTAHUNAN = IP_LNSW_CMS + 'laporantahunanPPID'
export const EDISI = IP_LNSW_CMS + 'edisi'
export const PER_EDISI = IP_LNSW_CMS + 'magazine/edisi'
//=====================================================================================================================================
//LOCAL API
export const IP_LOCAL = IP_LNSW_CMS + 'menuPopular'
//=====================================================================================================================================
// export const KNFP = 'http://10.239.5.124/api/knfp-cms/'
export const KNFP = IP_LNSW_CMS + 'knfp/'
export const KNFP_DEV_TEST = process.env.REACT_APP_API_URL_SERVER_DEV_TEST + '/api/cms/knfp/'
export const KNFP_IMG = IP_KONG_LNSW + '/assets/upload/knfp/'
export const APP_IMG = IP_KONG_LNSW + '/assets/upload/cms/'
export const KNFP_TRACK_ENQUIRY = "https://api.insw.go.id/api/cms/knfp/enquiry/get_by_uuid"
export const ENQUIRY_SEND_EMAIL = "https://api.insw.go.id/api/cms/knfp/enquiry/send_email_by_knfp"
export const KNFP_AUTH_KEY = process.env.REACT_APP_KNFP_AUTH_KEY

//==Kuota TRQ
export const SEARCH_TRQ = IP_KONG_LNSW + '/kuotatrq'  

export const IP_LNSW_CETAKAN_DEV = IP_KONG_LNSW_DEV + "/api-prod/reports/print_skp_gen2"
export const IP_LNSW_CETAKAN_PROD = IP_KONG_LNSW + "/api-prod/reports/print_skp_gen2"
export const IP_LNSW_CETAKAN = process.env.REACT_APP_ENV_MODE === "DEV" ? IP_LNSW_CETAKAN_DEV : IP_LNSW_CETAKAN_PROD

 
