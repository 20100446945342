/* eslint-disable jsx-a11y/alt-text */
import styled from '@emotion/styled';
import axios from 'axios';
import parse from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import { useTranslation } from 'react-i18next';
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { ListEmpty, LoadProgress } from '../../../components';
//component
import * as Api from '../../../constants/api';
import './majalah.scss';


const LaporanTahunan = () => {

    // variable constant
    const { t } = useTranslation();
    const history = useHistory();
    const [dataMajalah, setDataMajalah] = useState([]);
    const [dataMajalahSlice, setDataMajalahSlice] = useState([]);
    // const [dataSearch, setDataSearch] = useState([]);
    const [dataEdisi, setDataEdisi] = useState([]);
    const [is_progress, setisProgress] = useState(false);
    const [error_message, setErrorMessage] = useState('');

    const bahasa = localStorage.getItem('language');

    //style
    // let styles = {
    //     container: {
    //         flex: 1,
    //     },
    // }

    // did mount
    useEffect(() => {
        _get_data_majalah();
        _get_data_edisi();
    }, []);

    const CallImage = (image_url) => {
        var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        // console.log(url_image);
        return url_image;
    }

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        return text_html;
    }

    const _get_data_majalah = () => {
        setisProgress(true);
        const url = Api.LAPTAHUNAN + '?lang=' + localStorage.getItem('language').toUpperCase();
        // console.log('majalah', url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10,
        })
            .then(response => {
                setisProgress(false);
                if (response.data.code === '01') {
                    let data_majalah = response.data.data;
                    setDataMajalah(data_majalah);
                    const slice = data_majalah.slice(0, perPage);
                    setPageCount(data_majalah.length / perPage);
                    setDataMajalahSlice(slice);
                } else {
                    setDataMajalah([]);
                }
            })
            .catch(error => {
                setisProgress(false);
                console.log('error', error);
                setDataMajalah([]);
            });
    }

    const _get_data_edisi = () => {
        setisProgress(true);
        const url = Api.EDISI;
        // console.log('edisi', url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10,
        })
            .then(response => {
                setisProgress(false);
                // console.log('edisi res', response.data);
                if (response.data.code === '01') {
                    setDataEdisi(response.data.data);
                    let data_edisi = [{
                        label: bahasa === 'id' ? 'Semua Edisi' : 'All Editions',
                        value: 'all'
                    }];
                    for (var i = 0; i < response.data.data.length; i++) {
                        let edisi = {
                            label: bahasa === 'id' ? 'Edisi ' + response.data.data[i].ur_edisi : response.data.data[i].ur_edisi + ' Edition',
                            value: response.data.data[i].id_edisi
                        };
                        data_edisi.push(edisi);
                    }
                    setListSelectRef(data_edisi);
                } else {
                    setDataEdisi([]);
                }
            })
            .catch(error => {
                setisProgress(false);
                console.log('error', error);
                setDataEdisi([]);
            });
    }

    const _get_data_peredisi = item => {
        setisProgress(true);
        const url = Api.PER_EDISI + '?lang=' + localStorage.getItem('language').toUpperCase() + '&id_edisi=' + item;
        // console.log('peredisi', url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10,
        })
            .then(response => {
                setisProgress(false);
                if (response.data.code === '01') {
                    let data_majalah = response.data.data;
                    setDataMajalah(data_majalah);
                    const slice = data_majalah.slice(0, perPage);
                    setPageCount(data_majalah.length / perPage);
                    setDataMajalahSlice(slice);
                } else {
                    setDataMajalahSlice([]);
                }
            })
            .catch(error => {
                setisProgress(false);
                console.log('error', error);
                setDataMajalahSlice([]);
            });
    }

    // const options = [
    //     { value: 1, label: '1' },
    //     { value: 2, label: '2' },
    // ]

    // const filterList = (filterText) => {
    //     return data.filter(function (item) {
    //         return item.header.toLowerCase().includes(filterText.toLowerCase());
    //     });
    // }

    // Select state
    // const [selected_ref, setSelectedRef] = useState({
    //     label: 'Semua Edisi',
    //     value: 'all'
    // });
    const [selected_ref_label, setSelectedRefLabel] = useState(bahasa === 'id' ? 'Semua Edisi' : 'All Editions');
    const [list_select_ref, setListSelectRef] = useState(
        [
            // {
            //     label: 'Semua Edisi',
            //     value: 'all'
            // }
        ]
    );
    const [place_holder_ref, setPlaceHolderRef] = useState('penelusuran-dropdown-remove-placeholder');
    //style dropdown
    const StyledItem = styled.div`
        color: #555;
        padding: 10px;
        border-radius: 3px;
        margin: 3px;
        cursor: pointer;
        > div {
            display: flex;
            align-items: center;
        }
        :hover {
            background: #f2f2f2;
        }
    `;

    // Paginate State
    const perPage = 8;
    const [pageCount, setPageCount] = useState('');

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;

        loadMoreData(offset, offset + perPage);
    };

    const loadMoreData = (of, op) => {
        const data = dataMajalah;

        const slice = data.slice(of, op);
        setPageCount(data.length / perPage);
        setDataMajalahSlice(slice);
    };

    return (
        <div className="majalah-content">
            <div className="row m-0 justify-content-center title-majalah">
                <h3 className="al-title">
                    {t('laporan_tahunan')}
                </h3>
            </div>

            {/* <div className="filter row m-0 justify-content-center">
                <div className="col-md-5 mb-5">
                    <InputSearch
                        placeholder={t('search_magazine_here')}
                        onChange={event => {
                            setData(filterList(event.target.value));
                        }} />
                </div>
            </div> */}

            {/* <div className="majalah-select-container">
                <div className="majalah-choose">
                    {t('choose_magazine')}
                </div>
                <div className="col-md-3 mb-5">
                    <Select
                        multi={false}
                        searchable={false}
                        options={list_select_ref}
                        values={[]}
                        style={{ borderColor: '#808080', background: '#FFFFFF', borderRadius: '30px', paddingLeft: 10, paddingRight: 10, marginRight: 10 }}
                        className={place_holder_ref}
                        onChange={(value) => {
                            // console.log(value);
                            // setSelectedRef(value[0]);
                            setSelectedRefLabel(value[0].label);
                            setPlaceHolderRef('penelusuran-dropdown-remove-placeholder');
                            if (value[0].value !== 'all') {
                                _get_data_peredisi(value[0].value);
                            } else {
                                _get_data_majalah();
                            }
                        }}
                        contentRenderer={({ props, state }) => (
                            <div>
                                {selected_ref_label === t('choose_magazine') ?
                                    <div style={{ cursor: 'pointer', display: "flex" }}>
                                        <div className="penelusuran-dropdown-placeholder">{selected_ref_label}</div>
                                    </div>
                                    :
                                    <div style={{ cursor: 'pointer', display: "flex" }}>
                                        <div className="penelusuran-input-dropdown">{selected_ref_label}</div>
                                    </div>
                                }
                            </div>
                        )}
                        itemRenderer={({ item, methods }) => (
                            <StyledItem>
                                <div onClick={() => methods.addItem(item)}>
                                    <div className="penelusuran-dropdown-item">
                                        {item.label}
                                    </div>
                                </div>
                            </StyledItem>
                        )}
                    />
                </div>
            </div> */}

            <div className="row m-0">
                {
                    is_progress === true ?
                    <div style={{ height: '25vw' }} className="detail-content-load">
                        <LoadProgress />
                    </div>
                    :
                    dataMajalah.length === 0 ?
                        <div className="detail-content-load">
                            <ListEmpty message_error={error_message} />
                        </div>
                        :
                        dataMajalahSlice.map((item, index) =>
                        <div className="col-md-3 mb-3" key={index}>
                            <div className="card card-majalah">
                                <img src={CallImage(item.nama_img)} alt="Avatar" />
                                <div class="card-text">
                                    <p>
                                        {
                                            item.header.length > 60 ?
                                                (
                                                    <div>
                                                        {parseHtml(item.header.substring(0, 60)) + '...'}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {parseHtml(item.header)}
                                                    </div>
                                                )
                                        }
                                    </p>
                                    <a role="button" rel="noopener noreferrer" className="btn btn-primary rounded-pill" href={CallImage(item.file_doc)} target="_blank">
                                        {t('download')}
                                    </a>
                                </div>
                            </div>
                        </div>
                )}
            </div>
            <div className="majalah-paging-content-list col-md-12">
                <ReactPaginate
                    previousLabel={t('previous')}
                    nextLabel={t('next')}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick.bind(this)}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                />
            </div>
        </div>
    )
}

export default LaporanTahunan;
