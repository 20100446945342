import React, { useState, useEffect, useRef } from 'react'
import './struktur_organisasi.scss'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import parse from 'html-react-parser'

//component
import * as Api from '../../../constants/api'
import { LoadProgress, ListEmpty } from '../../../components'

const StrukturOrganisasi = () => {
   // variable constant
   const { t } = useTranslation()
   const [is_progress, setisProgress] = useState(true)
   const [list_struktur, setListStruktur] = useState([])
   const [error_message, setErrorMessage] = useState('')
   const [struktur_image, setStrukturImage] = useState('')
   const [judul, setJudul] = useState([])

   // did mount
   useEffect(() => {
      if (localStorage.getItem('language') === null) {
         localStorage.setItem('language', 'id')
      }

      _get_data_struktur()
      _get_data_judul()
   }, [])

   const parseHtml = (html) => {
      // console.log('html');
      // console.log(html)
      var text_html = ''
      if (html !== undefined) {
         text_html = parse(html)
      }
      // var text_html = parse(html.toString());

      return text_html
   }

   const _get_data_judul = () => {
      let idMenu = localStorage.getItem('menu_select')
      if (idMenu === null || idMenu !== 25) {
         idMenu = 25
      }
      const url =
         Api.JUDUL +
         idMenu +
         '&lang=' +
         localStorage.getItem('language').toUpperCase()
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            if (response.data.code === '01') {
               setJudul(response.data.data)
            } else {
               setJudul([])
            }
         })
         .catch((error) => {
            setJudul([])
         })
   }

   //function
   const _get_data_struktur = () => {
      setisProgress(true)
      const url =
         Api.GET_STRUKTUR +
         'lang=' +
         localStorage.getItem('language').toUpperCase()
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgress(false)
            if (response.data.code === '01') {
               if (response.data.data.length === 0) {
                  setListStruktur([])
                  setErrorMessage(t('empty_list'))
               } else {
                  let list_data_struktur = response.data.data
                  for (var i = 0; i < list_data_struktur.length; i++) {
                     if (i === 0) {
                        list_data_struktur[i].active = 1
                        setStrukturImage(list_data_struktur[i].nama_img)
                     } else {
                        list_data_struktur[i].active = 0
                     }
                  }
                  // console.log('list_data_struktur');
                  // console.log(list_data_struktur);
                  setListStruktur(list_data_struktur)
               }
            } else {
               setListStruktur([])
               setErrorMessage(t('empty_list'))
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgress(false)
            setListStruktur([])
            setErrorMessage(t('error_message'))
         })
   }

   const button_table = (item, index) => {
      // console.log('items')
      // console.log(item)
      let structure_name = item.ur
      // console.log(structure_name.toLowerCase())
      if (
         structure_name.toLowerCase() !== 'kepala' &&
         structure_name.toLowerCase() !== 'head'
      ) {
         return (
            <div
               className={
                  item.active === 1
                     ? 'table-content-round-blue'
                     : 'table-content-round'
               }
               onClick={() => {
                  let list_data_struktur_button = list_struktur
                  for (var a = 0; a < list_data_struktur_button.length; a++) {
                     if (a === index) {
                        list_data_struktur_button[a].active = 1
                        setStrukturImage(list_data_struktur_button[a].nama_img)
                     } else {
                        list_data_struktur_button[a].active = 0
                     }
                  }
                  console.log('clicked')
                  console.log(list_data_struktur_button)
                  setisProgress(true)
                  setListStruktur([])
                  setTimeout(() => {
                     setisProgress(false)
                     setListStruktur(list_data_struktur_button)
                  }, 100)
               }}
            >
               {item.ur}
            </div>
         )
      }
   }

   const CallImage = (image_url) => {
      // console.log('url_image');
      // console.log(image_url);
      var url_image = ''
      if (image_url !== undefined) {
         url_image = Api.IP_KONG_LNSW + image_url.slice(1)
      }
      // console.log('url_image');
      // console.log(url_image);
      return url_image
   }

   return (
      <div className="struktur-organisasi-page row m-0">
         {judul.map((item, index) => (
            <div className="struktur-organisasi-background col-md-12 p-0">
               <div className="struktur-organisasi-background-part">
                  <img src={CallImage(item.nama_img)} alt="bg" />
               </div>
               <div className="struktur-organisasi-content-title">
                  <div className="struktur-organisasi-content-sub-title">
                     <div className="struktur-organisasi-text-title">
                        {parseHtml(item.header)}
                     </div>
                     <div className="struktur-organisasi-text-message">
                        {parseHtml(item.ur)}
                     </div>
                  </div>
               </div>
            </div>
         ))}
         <>
            {is_progress === true ? (
               <div style={{ height: '25vw' }} className="search-content-load">
                  <LoadProgress />
               </div>
            ) : is_progress === false && list_struktur.length === 0 ? (
               <div className="search-content-load">
                  <ListEmpty message_error={error_message} />
               </div>
            ) : (
               <div className="struktur-organisasi-content">
                  <div className="struktur-organisasi-content-diagram">
                     <img
                        className="struktur-diagram-image"
                        src={CallImage(struktur_image)}
                     />
                  </div>
                  <div className="struktur-organisasi-content-table">
                     <div className="struktur-table-header">
                        {t('see_more_for_caps')}
                     </div>
                     <div className="struktur-table-content">
                        {list_struktur.map((item, index) =>
                           button_table(item, index),
                        )}
                     </div>
                  </div>
               </div>
            )}
         </>
      </div>
   )
}

export default StrukturOrganisasi
