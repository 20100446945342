import React, { useState, useEffect, useRef } from 'react';
import './list_auth.scss';
import { Table } from 'reactstrap';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import moment from 'moment';

import * as Api from '../../../../constants/api';
import { ListEmpty, InputSearch } from '../../../../components';

const ListAuth = () => {
	//variable constant
	const { t } = useTranslation();
	// const [reference, SetReference] = useState('');
	const [is_progress, setisProgress] = useState(true);
	const [dataReference, SetDataReference] = useState([]);
	const [dataReferenceTemp, SetDataReferenceTemp] = useState([]);
	const [error_message, setErrorMessage] = useState('');
	//paging
	const [offset, setOffset] = useState(0);
	const [sliceData, setSliceData] = useState([]); //tabledata
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageCount, setPageCount] = useState('');
	const [intial_page, setInitialPage] = useState(0);

	//did mount
	useEffect(() => {
		searchReference();
	}, []);

	// function
	const searchReference = () => {
		setisProgress(true);

		let url = Api.GET_AMS;

		axios({
			method: 'get',
			url: url,
			headers: { Authorization: Api.BASIC_AUTH },
			timeout: 1000 * 10, // Wait for 10 seconds
		})
			.then((response) => {
				setisProgress(false);
				if (response.data.code === '01') {
					if (response.data.data.length === 0) {
						SetDataReferenceTemp([]);
						SetDataReference([]);
						setErrorMessage(t('empty_list'));
					} else {
						// console.log(response.data.data);
						let data_list = response.data.data;
						SetDataReferenceTemp(data_list);
						SetDataReference(data_list);
						sliceDataDua(data_list);
					}
				} else {
					SetDataReferenceTemp([]);
					SetDataReference([]);
					setSliceData([]);
					setErrorMessage(t('empty_list'));
				}
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
				setisProgress(false);
				SetDataReferenceTemp([]);
				SetDataReference([]);
				setSliceData([]);
				setErrorMessage(t('empty_list'));
			});
	};

	const filterList = (filterText) => {
		console.log('filterText');
		console.log(filterText);
		return dataReferenceTemp.filter(function (item) {
			// console.log('filter looop');
			if (
				item.tax_number.toLowerCase().includes(filterText.toLowerCase()) ===
				true
			) {
				return true;
			} else if (
				item.company_name.toLowerCase().includes(filterText.toLowerCase()) ===
				true
			) {
				return true;
			} else if (
				item.type_of_operator
					.toLowerCase()
					.includes(filterText.toLowerCase()) === true
			) {
				return true;
			} else if (
				item.ref_number.toLowerCase().includes(filterText.toLowerCase()) ===
				true
			) {
				return true;
			} else if (
				item.decree_date.toLowerCase().includes(filterText.toLowerCase()) ===
				true
			) {
				return true;
			} else if (
				item.exp_date.toLowerCase().includes(filterText.toLowerCase()) === true
			) {
				return true;
			} else {
				return false;
			}
			// return item.company_name.toLowerCase().includes(filterText.toLowerCase());
		});
	};

	const sliceDataDua = (item) => {
		console.log(item);
		// const slice = item.slice(offset, offset + perPage);
		const slice = item.slice(0, 0 + perPage);
		console.log('slice');
		console.log(slice);
		setCurrentPage(0);
		setOffset(0);

		setPageCount(item.length / perPage);
		setSliceData(slice);
	};

	const handlePageClick = (e) => {
		const selectedPage = e.selected;
		const offset = selectedPage * perPage;

		// console.log(offset);
		// console.log(offset + perPage);
		// console.log(selectedPage);

		setCurrentPage(selectedPage);
		setOffset(offset);
		setInitialPage(selectedPage);

		loadMoreData(offset, offset + perPage);
	};

	const loadMoreData = (of, op) => {
		const data = dataReference;

		const slice = data.slice(of, op);
		setPageCount(data.length / perPage);
		setSliceData(slice);

		// console.log("slice");
		// console.log(slice);
	};

	return (
		<div className='list-auth-content'>
			<div className='list-auth-content-title'>
				<div>{t('list_of_authorized')}</div>
			</div>
			<div className='list-auth-table-container'>
				<div className='search-data-border'>
					<div className='search-data'>
						<InputSearch
							placeholder={t('search_companies_here')}
							onChange={(e) => {
								// console.log(e.target.value);
								let data_filter = filterList(e.target.value);
								// console.log(data_filter);
								setInitialPage(0);
								SetDataReference(data_filter);
								sliceDataDua(data_filter);
							}}
						/>
					</div>
				</div>
				<Table bordered className='list-auth-table'>
					<thead className='list-auth-table-header'>
						<tr>
							<th className='list-auth-table-no'>No</th>
							<th className='list-auth-table-npwp'>
								{t('tax_identification')}
							</th>
							<th className='list-auth-table-name'>{t('company-name')}</th>
							<th className='list-auth-table-type-operator'>
								{t('type_operator')}
							</th>
							<th className='list-auth-table-keputusan'>
								{t('decree_Number')}
							</th>
							<th className='list-auth-table-date'>{t('date_decree')}</th>
							<th className='list-auth-table-date'>{t('expired_date')}</th>
						</tr>
					</thead>
					<tbody className='list-auth-table-body'>
						{sliceData.map((item, i) => (
							<tr>
								<th scope='row'>
									{currentPage === 0 ? i + 1 : currentPage * perPage + (i + 1)}
								</th>
								<td>{item.tax_number}</td>
								<td>{item.company_name}</td>
								<td>{item.type_of_operator}</td>
								<td>{item.ref_number}</td>
								<td>{item.decree_date}</td>
								<td>{item.exp_date}</td>
							</tr>
						))}
					</tbody>
				</Table>
				{is_progress === true ? (
					<div
						style={{
							width: '100%',
							flexDirection: 'row',
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<Spinner color='#02275D' />
						<div className='load-progress-text'>
							<div>{t('please_wait')}</div>
						</div>
					</div>
				) : null}
				{is_progress === false ? (
					sliceData.length === 0 ? (
						<ListEmpty message_error={error_message} />
					) : null
				) : null}
				<ReactPaginate
					previousLabel={'Sebelumnya'}
					nextLabel={'Selanjutnya'}
					breakLabel={'...'}
					breakClassName={'break-me'}
					pageCount={pageCount}
					marginPagesDisplayed={2}
					forcePage={intial_page}
					pageRangeDisplayed={5}
					onPageChange={handlePageClick.bind(this)}
					containerClassName={'pagination'}
					subContainerClassName={'pages pagination'}
					activeClassName={'active'}
				/>
			</div>
		</div>
	);
};

export default ListAuth;
