import global from '../global/global'
import CryptoJS from 'crypto-js'
let crypto = require('crypto')

export const _doEncrypt = (...datas) => {
   if (datas != null) {
      let key = datas[0][0].key
      let dataUser = datas[0][0].data

      let encryptKey = crypto.createCipher('aes-128-cbc', global._hashing._salt)
      let dataEncrypt = encryptKey.update(dataUser.toString(), 'utf8', 'hex')

      localStorage.setItem(key, (dataEncrypt += encryptKey.final('hex')))
      // localStorage.setItem(window.btoa(key), dataEncrypt += encryptKey.final('hex'));
   } else {
      return false
   }
}

export const _doEncryptObj = (obj) => {
   let encryptKey = crypto.createCipher('aes-128-cbc', global._hashing._salt)
   let dataEncrypt = encryptKey.update(JSON.stringify(obj), 'utf8', 'hex')

   dataEncrypt += encryptKey.final('hex')

   return dataEncrypt
}

export const _doDecrypt = (data) => {
   if (data != null) {
      let bycryptKey = crypto.createDecipher(
         'aes-128-cbc',
         global._hashing._salt,
      )
      let dataBycrypt = bycryptKey.update(data.toString(), 'hex', 'utf8')

      dataBycrypt += bycryptKey.final('utf8')

      return dataBycrypt
   } else {
      return false
   }
}
export const _doDecryptObj = (data) => {
   if (data != null) {
      let bycryptKey = crypto.createDecipher(
         'aes-128-cbc',
         global._hashing._salt,
      )
      let dataBycrypt = bycryptKey.update(data, 'hex', 'utf8')

      dataBycrypt += bycryptKey.final('utf8')

      return dataBycrypt
   } else {
      return false
   }
}

// Start Deploy SSO
export const _encryptSSO = (data) => {
   if (data !== null) {
      var mykey = crypto.createCipher('aes-128-cbc', global._hashing._salt)
      var mystr = mykey.update(JSON.stringify(data), 'utf8', 'hex')
      mystr += mykey.final('hex')
      return mystr
   } else {
      return false
   }
}

export const _decryptSSO = (encrypted) => {
   if (encrypted !== null || encrypted !== undefined) {
      var mykey = crypto.createDecipher('aes-128-cbc', global._hashing._salt)
      var mystr = mykey.update(encrypted, 'hex', 'utf8')
      mystr += mykey.final('utf8')
      return mystr
   } else {
      return false
   }
}

export const _parseJwt = (token) => {
   var base64Url = token.split('.')[1]
   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
   var jsonPayload = decodeURIComponent(
      atob(base64)
         .split('')
         .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
         })
         .join(''),
   )
   return JSON.parse(jsonPayload)
}

export const doEncryptId = (data) => {
   let enc = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      global._hashing._salt,
   ).toString()
   return enc
}

export const doDecryptId = (data) => {
   // console.log('data_encypt', data)
   if (
      data !== null &&
      data !== '' &&
      data !== undefined &&
      data !== 'undefined'
   ) {
      // console.log('masuk decrypt')
      let bytes = CryptoJS.AES.decrypt(data, global._hashing._salt)
      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
   } else {
      return ''
   }
}
