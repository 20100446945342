import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../../../constants/api';
import './index.scss';
import parse from 'html-react-parser';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PanduanDetail = () => {
    const [data, setData] = useState([]);
    const [urls, setUrl] = useState([]);
    const location = useLocation();
    const { t } = useTranslation();

    const CallImage = (url) => {
        // var replace_url = image_url.substring(1);
        var url = Api.IP_KONG_LNSW + url;
        // console.log(url);
        return url;
    }

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        setData(location.state);
        setUrl(location.state.file)
    }, []);

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        return text_html;
    }

    return (
        <>
            <div className="pusat-bantuan-panduan-detail row m-0">
                <div className="col-md-12">
                    <div className="pb-content">
                        <h3 className="pb-title">
                            {t("pusat-bantuan-panduan")}
                        </h3>
                        <div className="row m-0 justify-content-center">
                            <p className="col-md-6">
                                {t("pusat-bantuan-panduan-sub")}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="pb-content-list-faq col-md-12">
                    <div className="card">
                        <div className="titles">
                            {data.header}
                        </div>
                        <div className="content">
                            {data.ur}
                        </div>
                        <a role="button" rel="noopener noreferrer" className="btn btn-primary rounded-pill" href={CallImage(urls.slice(1))} target="_blank">
                            Unduh
                        </a>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PanduanDetail;
