import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Login, Register, MainApp } from '../../pages';

const Routes = () => {

    // did mount
  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

    return (
        <Router>
            <Switch>
                {/* <Route path="/login">
                    <Login />
                </Route>
                <Route path="/register">
                    <Register />
                </Route> */}
                <Route path="/">
                    <MainApp />
                </Route>
            </Switch>
        </Router>
    )
}

export default Routes;
