import React, { useState, useEffect } from 'react'
import './pib_peb.scss'
import Select from 'react-dropdown-select'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { InputSearch } from '../../../components/atoms'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import * as Api from '../../../constants/api'
import parse from 'html-react-parser'
import { ListEmpty, LoadProgress } from '../../../components'
import { Penelusuran_BG } from '../../../assets'

const PibPeb = () => {
   const { t } = useTranslation()
   const history = useHistory()
   const [pibpeb, SetPibPeb] = useState('')
   const [judul, setJudul] = useState([])
   const [is_progress, setisProgress] = useState(false)
   const [error_message, setErrorMessage] = useState('')

   //Select
   const [selected_ref, setSelectedRef] = useState({
      label: 'PIB',
      value: 'pib',
   })
   const [selected_ref_label, setSelectedRefLabel] = useState('PIB')
   const [list_select_ref, setListSelectRef] = useState([
      {
         label: 'PIB',
         value: 'pib',
      },
      {
         label: 'PEB',
         value: 'peb',
      },
   ])
   const [place_holder_ref, setPlaceHolderRef] = useState(
      'penelusuran-dropdown-remove-placeholder',
   )

   //style dropdown
   const StyledItem = styled.div`
      color: #555;
      padding: 10px;
      border-radius: 3px;
      margin: 3px;
      cursor: pointer;
      > div {
         display: flex;
         align-items: center;
      }
      :hover {
         background: #f2f2f2;
      }
   `

   const searchPibPeb = () => {
      // console.log('jenis');
      // console.log(selected_ref.value);
      // console.log('pibpeb');
      // console.log(pibpeb);

      const jenisRef = selected_ref.value

      history.push('/pib-peb/detail', {
         data: {
            jenis: jenisRef,
            nomor: pibpeb,
         },
      })
   }

   // did mount
   useEffect(() => {
      _get_data_judul()
   }, [])

   const _get_data_judul = () => {
      setisProgress(true)
      let idMenu = localStorage.getItem('menu_select')
      if (idMenu === null || idMenu !== 35) {
         idMenu = 35
      }
      const url =
         Api.JUDUL +
         idMenu +
         '&lang=' +
         localStorage.getItem('language').toUpperCase()
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgress(false)
            if (response.data.code === '01') {
               setJudul(response.data.data)
            } else {
               setErrorMessage(t('empty_list'))
               setJudul([])
            }
         })
         .catch((error) => {
            setisProgress(false)
            setJudul([])
            setErrorMessage(t('error_message'))
         })
   }

   const CallImage = (image_url) => {
      var url_image = Api.IP_KONG_LNSW + image_url.slice(1)
      // console.log(url_image);
      return url_image
   }

   //function
   const parseHtml = (html) => {
      // console.log('html');
      // console.log(html)
      var text_html = ''
      if (html !== undefined) {
         text_html = parse(html)
      }
      // var text_html = parse(html.toString());

      return text_html
   }

   return (
      <div className="penelusuran-pibpeb-container">
         {is_progress === true ? (
            <div style={{ height: '25vw' }} className="detail-content-load">
               <LoadProgress />
            </div>
         ) : judul.length === 0 ? (
            // <div className="detail-content-load">
            //    <ListEmpty message_error={error_message} />
            // </div>
            <div className="penelusuran-background">
               <img
                  // src={CallImage(item.nama_img)}
                  src={Penelusuran_BG}
                  className="penelusuran-background-img"
                  alt="bg"
               />
               <div className="penelusuran-content-title">
                  <div className="penelusuran-content-sub-title">
                     <div className="penelusuran-text-title">
                        {/* {parseHtml(item.header)} */}
                        Penelusuran Dokumen PIB/PEB
                     </div>
                     <div className="penelusuran-text-message">
                        {/* {parseHtml(item.ur)} */}
                        Penelusuran Dokumen PIB/PEB berdasarkan nomor aju di
                        Lembaga National Single Window
                     </div>
                     <div className="penelusuran-input-search row m-0 justify-content-center mt-5">
                        <div className="col-md-2 mb-2">
                           <Select
                              multi={false}
                              searchable={false}
                              options={list_select_ref}
                              values={[]}
                              style={{
                                 borderColor: '#808080',
                                 background: '#FFFFFF',
                                 borderRadius: '30px',
                                 paddingLeft: 10,
                                 paddingRight: 10,
                                 marginRight: 10,
                              }}
                              className={place_holder_ref}
                              onChange={(value) => {
                                 // console.log(value);
                                 setSelectedRef(value[0])
                                 setSelectedRefLabel(value[0].label)
                                 setPlaceHolderRef(
                                    'penelusuran-dropdown-remove-placeholder',
                                 )
                              }}
                              contentRenderer={({ props, state }) => (
                                 <div>
                                    {selected_ref_label ===
                                    t('choose_cif_fob') ? (
                                       <div
                                          style={{
                                             cursor: 'pointer',
                                             display: 'flex',
                                          }}
                                       >
                                          <div className="penelusuran-dropdown-placeholder">
                                             {selected_ref_label}
                                          </div>
                                       </div>
                                    ) : (
                                       <div
                                          style={{
                                             cursor: 'pointer',
                                             display: 'flex',
                                          }}
                                       >
                                          <div className="penelusuran-input-dropdown">
                                             {selected_ref_label}
                                          </div>
                                       </div>
                                    )}
                                 </div>
                              )}
                              itemRenderer={({ item, methods }) => (
                                 <StyledItem>
                                    <div onClick={() => methods.addItem(item)}>
                                       <div className="penelusuran-dropdown-item">
                                          {item.label}
                                       </div>
                                    </div>
                                 </StyledItem>
                              )}
                           />
                        </div>
                        <div className="col-md-6 mb-2">
                           <InputSearch
                              placeholder={t('look_the_pib_peb')}
                              onKeyDown={() => searchPibPeb()}
                              onChange={(e) => SetPibPeb(e.target.value)}
                              onClick={() => searchPibPeb()}
                           />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         ) : (
            judul.map((item, index) => (
               <div className="penelusuran-background">
                  <img
                     src={CallImage(item.nama_img)}
                     className="penelusuran-background-img"
                     alt="bg"
                  />
                  <div className="penelusuran-content-title">
                     <div className="penelusuran-content-sub-title">
                        <div className="penelusuran-text-title">
                           {parseHtml(item.header)}
                        </div>
                        <div className="penelusuran-text-message">
                           {parseHtml(item.ur)}
                        </div>
                        <div className="penelusuran-input-search row m-0 justify-content-center mt-5">
                           <div className="col-md-2 mb-2">
                              <Select
                                 multi={false}
                                 searchable={false}
                                 options={list_select_ref}
                                 values={[]}
                                 style={{
                                    borderColor: '#808080',
                                    background: '#FFFFFF',
                                    borderRadius: '30px',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    marginRight: 10,
                                 }}
                                 className={place_holder_ref}
                                 onChange={(value) => {
                                    // console.log(value);
                                    setSelectedRef(value[0])
                                    setSelectedRefLabel(value[0].label)
                                    setPlaceHolderRef(
                                       'penelusuran-dropdown-remove-placeholder',
                                    )
                                 }}
                                 contentRenderer={({ props, state }) => (
                                    <div>
                                       {selected_ref_label ===
                                       t('choose_cif_fob') ? (
                                          <div
                                             style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                             }}
                                          >
                                             <div className="penelusuran-dropdown-placeholder">
                                                {selected_ref_label}
                                             </div>
                                          </div>
                                       ) : (
                                          <div
                                             style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                             }}
                                          >
                                             <div className="penelusuran-input-dropdown">
                                                {selected_ref_label}
                                             </div>
                                          </div>
                                       )}
                                    </div>
                                 )}
                                 itemRenderer={({ item, methods }) => (
                                    <StyledItem>
                                       <div
                                          onClick={() => methods.addItem(item)}
                                       >
                                          <div className="penelusuran-dropdown-item">
                                             {item.label}
                                          </div>
                                       </div>
                                    </StyledItem>
                                 )}
                              />
                           </div>
                           <div className="col-md-6 mb-2">
                              <InputSearch
                                 placeholder={t('look_the_pib_peb')}
                                 onKeyDown={() => searchPibPeb()}
                                 onChange={(e) => SetPibPeb(e.target.value)}
                                 onClick={() => searchPibPeb()}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            ))
         )}
      </div>
   )
}

export default PibPeb
