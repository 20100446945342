/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import './press_release.scss';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import parse from 'html-react-parser';
import GridList from 'react-gridlist';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Ripples from 'react-ripples';

//component
import * as Api from '../../../constants/api';
import { LoadProgress, ListEmpty, InputSearch, ListBerita } from '../../../components';
import { ICCalendarGrayNews } from '../../../assets';
import { useHistory } from 'react-router-dom';

const PressRelease = () => {

  // variable constant
  const { t } = useTranslation();
  const history = useHistory();
  const [is_progress, setisProgress] = useState(true);
  const [list_press_release, setListPressRelease] = useState([]);
  const [list_press_release_slice, setListPressReleaseSlice] = useState([]);
  const [cover_press_release, setCoverPressRelease] = useState({});
  const [error_message, setErrorMessage] = useState('');

  const search_press_release = useRef('');
  const id_press_release = useRef('');

  const perPage = 5;
  const [pageCount, setPageCount] = useState('');

  //style
  let styles = {
    container: {
      flex: 1,
    },
  }

  // did mount
  useEffect(() => {
    _get_data_pr();
  }, []);

  //function
  const _get_data_pr = () => {
    setisProgress(true);
    const url = Api.ALL_PR + '&lang=' + localStorage.getItem('language').toUpperCase();
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        setisProgress(false);
        if (response.data.code === '01') {
          if (response.data.data.length === 0) {
            setListPressRelease([]);
            setListPressReleaseSlice([]);
            setCoverPressRelease({});
            setErrorMessage(t('empty_list'));
          } else {
            let list_press_release_data = response.data.data;
            if (list_press_release_data.length === 1) {
              setCoverPressRelease(list_press_release_data[0]);
              id_press_release.current = list_press_release_data[0].id_berita;
              setListPressRelease(list_press_release_data);
              const slice = list_press_release_data.slice(0, perPage);
              setPageCount(list_press_release_data.length / perPage);
              setListPressReleaseSlice(slice);
            } else {
              setCoverPressRelease(list_press_release_data[0]);
              id_press_release.current = list_press_release_data[0].id_berita;
              // list_press_release_data = list_press_release_data.slice(1)
              setListPressRelease(list_press_release_data);
              const slice = list_press_release_data.slice(0, perPage);
              setPageCount(list_press_release_data.length / perPage);
              setListPressReleaseSlice(slice.slice(1));
            }
          }
        } else {
          setListPressRelease([]);
          setListPressReleaseSlice([]);
          setCoverPressRelease({});
          setErrorMessage(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgress(false);
        setListPressRelease([]);
        setListPressReleaseSlice([]);
        setCoverPressRelease({});
        setErrorMessage(t('error_message'));
      });
  }

  const CallImage = (image_url) => {
    var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    // console.log('url_image');
    // console.log(url_image);
    return url_image;
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    loadMoreData(offset, offset + perPage);
  };

  const loadMoreData = (of, op) => {
    const data = list_press_release;

    const slice = data.slice(of, op);
    setPageCount(data.length / perPage);
    setListPressReleaseSlice(slice);
  };

  const _click_view_more = (id_berita_click) => {
    // history.push('/press-release/detail-press-release', { id_berita: id_berita_click });
    history.push('/press-release/detail-press-release/' + id_berita_click );
  }

  return (
    <div className="press-release-content">
      <div className="row">
        <div className="col-md-6 p-0">
          <InputSearch
            placeholder={t('search_press_release_here')}
            onChange={e => search_press_release.current = e.target.value}
            onKeyDown={() => history.push('/press-release/search-press-release', { search_press_release: search_press_release.current })}
            onClick={() => history.push('/press-release/search-press-release', { search_press_release: search_press_release.current })}
          />
        </div>
        <div className="berita-line col-md-12 p-0"></div>
        <div className="berita-title col-md-12 p-0">{t('latest_press_release')}</div>
        <div className="col-md-12 p-0">
          {is_progress === true ?
            <div style={{ height: '25vw' }} className="search-content-load">
              <LoadProgress />
            </div>
            :
            list_press_release.length === 0 ?
              <div className="search-content-load col-md-12 p-0">
                <ListEmpty message_error={error_message} />
              </div>
              :
              <div className="row">
                <div className="berita-terkini-cover col-md-6 col-sm-12">
                  <img className="berita-terkini-cover-image" src={CallImage(cover_press_release.file)} />
                  <div className="berita-terkini-cover-title">
                    {cover_press_release.header}
                  </div>
                  <div className="berita-terkini-cover-date">
                    <img src={ICCalendarGrayNews} style={{ marginRight: '0.7vw' }} />
                    {moment(cover_press_release.waktu_publish).format('DD MMMM YYYY') + ' , ' + t('by') + ' ' + cover_press_release.penulis}
                  </div>
                  <div className="berita-terkini-cover-desc">
                    {parse(cover_press_release.cuplikan)}
                  </div>
                  <div onClick={() => _click_view_more(id_press_release.current)} className="berita-terkini-cover-more">
                    {t('read_more')}
                  </div>
                </div>
                <div className="berita-terkini-list col-md-6 col-sm-12 p-0">
                  {list_press_release_slice.map((item) =>
                    <ListBerita
                      onClick={() => _click_view_more(item.id_berita)}
                      gambar={CallImage(item.file)}
                      judul={item.header}
                      header={item.cuplikan}
                    />
                  )}
                  <div className="berita-paging-content-list col-md-12 p-0">
                    <ReactPaginate
                      previousLabel={t('previous')}
                      nextLabel={t('next')}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick.bind(this)}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default PressRelease;
