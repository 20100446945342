/* eslint-disable jsx-a11y/alt-text */
//import lib react
import './category_peraturan.scss';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import GridList from 'react-gridlist';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Button, ButtonGroup } from 'reactstrap';

//component
import { LogoKarantinaIkan } from '../../../../assets';
import * as Api from '../../../../constants/api';
import { ListSetting, LoadProgress, ListEmpty } from '../../../../components';
import { InputSearch } from '../../../../components/atoms';

const CategoryPeraturan = () => {
    //variable constant
    const { t } = useTranslation();
    const [is_progress_search_peraturan, setisProgressSearchPeraturan] = useState(true);
    const [search_list_peraturan, setSearchListPeraturan] = useState([]);
    const [search_list_peraturan_data, setSearchListPeraturanData] = useState([]);
    const [error_message_search_peraturan, setErrorMessageSearchPeraturan] = useState('');
    const location = useLocation();
    const history = useHistory();
    const perPage = 6;
    const [pageCount, setPageCount] = useState('');
    // const [data_topic, setDataTopic] = useState([
    //     {
    //         id: '1',
    //         name: 'SEMUA',
    //         count: '15'
    //     },
    //     {
    //         id: '2',
    //         name: 'UU',
    //         count: '1'
    //     },
    //     {
    //         id: '3',
    //         name: 'PERPU',
    //         count: '2'
    //     },
    //     {
    //         id: '4',
    //         name: 'PP',
    //         count: '5'
    //     },
    //     {
    //         id: '5',
    //         name: 'PEPRES',
    //         count: '1'
    //     },
    //     {
    //         id: '6',
    //         name: 'KEPRES',
    //         count: '2'
    //     },
    //     {
    //         id: '7',
    //         name: 'PERMEN',
    //         count: '2'
    //     }
    // ])

    // const [data_sub_topic, setDataSubTopic] = useState([
    //     {
    //         id: '1',
    //         name: 'Tata Niaga',
    //     },
    //     {
    //         id: '2',
    //         name: 'Tarif',
    //     },
    //     {
    //         id: '3',
    //         name: 'Lainnya',
    //     }
    // ])
    const [data_topic, setDataTopic] = useState([]);
    const [data_sub_topic, setDataSubTopic] = useState([]);
    const [select_topic, setSelectTopic] = useState(0);
    const select_data_topic = useRef({});
    const [select_sub_topic, setSelectSubTopic] = useState(null);
    const select_data_sub_topic = useRef({});
    const [type_category, setTypeCategory] = useState('');
    const [title_category, setTitleCategory] = useState('');
    const [desc_category, setDescCategory] = useState('');
    const id_tematik = useRef('');

    //style
    let styles = {
        container: {
            flex: 1,
        },
    }

    // did mount
    useEffect(() => {

        var category_title = '';
        var category_desc = '';
        if (location.state.tematik === 'ekspor') {
            category_title = t('export');
            category_desc = t('collection_related_rules') + ' ' + t('export_activities');
        } else if (location.state.tematik === 'impor') {
            category_title = t('import');
            category_desc = t('collection_related_rules') + ' ' + t('import_activities');
        } else if (location.state.tematik === 'logistik') {
            category_title = t('logistics');
            category_desc = t('collection_related_rules') + ' ' + t('logistics');
        } else if (location.state.tematik === 'ecommerce') {
            category_title = 'E - Commerce';
            category_desc = t('collection_related_rules') + ' E-Commerce';
        } else if (location.state.tematik === 'umkm') {
            category_title = 'UMKM (SMEs)';
            category_desc = t('collection_related_rules') + ' UMKM (SMEs)';
        }
        setTitleCategory(category_title);
        setDescCategory(category_desc);
        id_tematik.current = location.state.id_tematik;

        setTimeout(() => {
            _get_topic();
            _get_data_peraturan();
        }, 500);
    }, []);

    //function
    const _get_topic = () => {
        setisProgressSearchPeraturan(true);
        const url = Api.GET_TOPIC;
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    if (response.data.data.length === 0) {
                        setSelectTopic(null);
                        setDataTopic([]);
                        setisProgressSearchPeraturan(false);
                        setErrorMessageSearchPeraturan(t('empty_list'));
                    } else {
                        setDataTopic(response.data.data);
                        _get_sub_topic();
                    }

                } else {
                    setSelectTopic(null);
                    setDataTopic([]);
                    setisProgressSearchPeraturan(false);
                    setErrorMessageSearchPeraturan(t('empty_list'));
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setSelectTopic(null);
                setDataTopic([]);
                setisProgressSearchPeraturan(false);
                setErrorMessageSearchPeraturan(t('error_message'));
            });
    }

    const _get_sub_topic = () => {
        const url = Api.GET_SUBTOPIC + id_tematik.current;
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    if (response.data.data.length === 0) {
                        setDataSubTopic([]);
                        setSelectSubTopic(null);
                        setisProgressSearchPeraturan(false);
                        setErrorMessageSearchPeraturan(t('empty_list'));
                    } else {
                        setisProgressSearchPeraturan(false);
                        setDataSubTopic(response.data.data);
                    }

                } else {
                    setDataSubTopic([]);
                    setSelectSubTopic(null);
                    setisProgressSearchPeraturan(false);
                    setErrorMessageSearchPeraturan(t('empty_list'));
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setDataSubTopic([]);
                setSelectSubTopic(null);
                setisProgressSearchPeraturan(false);
                setErrorMessageSearchPeraturan(t('error_message'));
            });
    }

    const _get_data_peraturan = () => {
        setisProgressSearchPeraturan(true);
        // console.log('get perarturan')
        // console.log(JSON.stringify(select_data_topic.current));
        // console.log(select_data_sub_topic.current);
        var url = Api.GET_PERATURAN_BY_TEMATIC + 'bidang=' + id_tematik.current;
        if (JSON.stringify(select_data_topic.current) !== '{}') {
            url = url + '&jenis=' + select_data_topic.current.id;
        }
        if (JSON.stringify(select_data_sub_topic.current) !== '{}') {
            url = url + '&subbidang=' + select_data_sub_topic.current.id;
        }
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                setisProgressSearchPeraturan(false);
                if (response.data.code === '01') {
                    if (response.data.data.length === 0) {
                        setSearchListPeraturan([]);
                        setSearchListPeraturanData([]);
                        setErrorMessageSearchPeraturan(t('empty_list'));
                    } else {
                        // console.log(response.data);
                        let data_list_peraturan = response.data.data;
                        setSearchListPeraturanData(data_list_peraturan);
                        const slice = data_list_peraturan.slice(0, perPage);
                        setPageCount(data_list_peraturan.length / perPage);
                        setSearchListPeraturan(slice);
                    }

                } else {
                    setSearchListPeraturan([]);
                    setSearchListPeraturanData([]);
                    setErrorMessageSearchPeraturan(t('empty_list'));
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setisProgressSearchPeraturan(false);
                setSearchListPeraturan([]);
                setSearchListPeraturanData([]);
                setErrorMessageSearchPeraturan(t('error_message'));
            });
    }

    const getGridGap = (elementWidth, windowHeight) => (elementWidth > 720 && windowHeight > 480) ? 10 : 5;

    const getColumnCount = (elementWidth) => {
        const width = window.innerWidth;
        if (width < 600) {
            return Math.floor(elementWidth / 300); //pembagian column (dibagi untuk 4 column)
        } else {
            return Math.floor(elementWidth / 500); //pembagian column (dibagi untuk 2 column)
        }

    }

    const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.1);


    const getItemData = (list_peraturan, columnWidth) => {
        return {
            key: list_peraturan.id,
        }
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * perPage;

        loadMoreData(offset, offset + perPage);
    };

    const loadMoreData = (of, op) => {
        const data = search_list_peraturan_data;

        const slice = data.slice(of, op);
        setPageCount(data.length / perPage);
        setSearchListPeraturan(slice);
    };

    const _click_topic = (item, index) => {
        setSelectTopic(index);
        select_data_topic.current = item;
        _get_data_peraturan();
    }

    const _click_subtopic = (item, index) => {
        setSelectSubTopic(index);
        select_data_sub_topic.current = item;
        _get_data_peraturan();
    }

    return (
        <div>
            <div className="category-peraturan-content">
                <div className="category-peraturan-content-title">
                    <div>{title_category}</div>
                </div>
                <div className="category-peraturan-content-desc">
                    <div>{desc_category}</div>
                </div>
                {is_progress_search_peraturan === false ?
                    <div>
                        <ButtonGroup style={{ marginTop: '2vw' }} className="category-peraturan-content-button-group">
                            <Button active={select_topic === 0} onClick={() => _click_topic({}, 0)} className="category-peraturan-content-button-group-list" >{t('all')}</Button>
                            {data_topic.map((item, index) =>
                                <Button active={select_topic === index + 1} onClick={() => _click_topic(item, index + 1)} className="category-peraturan-content-button-group-list" >{item.nama}</Button>
                            )}
                        </ButtonGroup>

                        <ButtonGroup style={{ marginTop: '1vw', marginBottom: '3vw' }} className="category-peraturan-content-button-group">
                            {data_sub_topic.map((item, index) =>
                                <Button active={select_sub_topic === index} onClick={() => _click_subtopic(item, index)} className="category-peraturan-content-button-group-list" >{item.title}</Button>
                            )}
                        </ButtonGroup>
                    </div>
                    : null}

                <div style={{ display: "flex" }}>
                    {is_progress_search_peraturan === true ?
                        <div style={{ height: '25vw' }} className="category-peraturan-content-load">
                            <LoadProgress />
                        </div>
                        :
                        search_list_peraturan.length === 0 ?
                            // is_progress_search_peraturan === false && search_list_peraturan.length === 0 ?
                            <div className="category-peraturan-content-load">
                                <ListEmpty message_error={error_message_search_peraturan} />
                            </div>
                            :
                            <div style={{ flex: 1 }}>
                                <div style={styles.container}>
                                    <GridList
                                        items={search_list_peraturan}
                                        getGridGap={getGridGap}
                                        getColumnCount={getColumnCount}
                                        getWindowMargin={getWindowMargin}
                                        getItemData={getItemData}
                                        renderItem={(item) => {
                                            return (
                                                <div className="category-peraturan-content-item-grid">
                                                    <ListSetting
                                                        onClick={() => history.push("/intr/peraturan/tematik-pilihan/detail-peraturan", { data_peraturan: item })}
                                                        image={item.ga_logo}
                                                        nomor={item.nomor_peraturan}
                                                        bidang={item.bidang_peraturan}
                                                        title={item.judul_peraturan}
                                                        desc={item.keterangan}
                                                        date={moment(item.tanggal_peraturan).format('DD MMMM YYYY')}
                                                    />
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                                <div className="category-peraturan-content-list">
                                    <ReactPaginate
                                        previousLabel={t('previous')}
                                        nextLabel={t('next')}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick.bind(this)}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default CategoryPeraturan;
