import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../../constants/api';
import './index.scss';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { ListEmpty, LoadProgress } from '../../../components';

const MaklumatPelayanan = () => {
    const { t } = useTranslation();
    const [is_progress, setisProgress] = useState(false);
    const [dataMaklumat, setDataMaklumat] = useState([]);
    const [dataMaklumat1, setDataMaklumat1] = useState([]);
    const [dataMaklumat2, setDataMaklumat2] = useState([]);
    const [error_message, setErrorMessage] = useState('');

    //did mount
    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        maklumat();
        // _get_maklumat();
    }, []);

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    const unduh_data = item => {
        var imageUrl = Api.IP_KONG_LNSW + item.slice(1);
        // console.log(imageUrl);
        // window.open(imageUrl, "_blank"); //to open new page
        const win = window.open(imageUrl, '_blank');
        if (win != null) {
            win.focus();
        }
    }

    const maklumat = () => {
        
        setisProgress(true);

        let url = Api.IP_LNSW_CMS + 'maklumat?lang=' + localStorage.getItem('language').toUpperCase(); //web api
        // console.log(url);

        axios({
          method: 'get',
          url: url,
          headers: { 'Authorization': Api.BASIC_AUTH },
          timeout: 1000 * 10, // Wait for 10 seconds
        })
          .then(response => {
            setisProgress(false);
            if (response.data.code === '01') {
                if (response.data.data.length === 0) {
                    setDataMaklumat([]);
                    // setDataMaklumat1([]);
                    // setDataMaklumat2([]);
                    setErrorMessage(t('empty_list'));
                } else {
                    let data_list = response.data.data;
                    setDataMaklumat(data_list);
                    // setDataMaklumat1(data_list[0]);
                    // setDataMaklumat2(data_list[1]);
                    // console.log('length !== 0');
                    // console.log(response.data.data);
                }
                
            } else {
                // console.log('code 02');
                // console.log(response.data.code);
                setDataMaklumat([]);
                // setDataMaklumat1([]);
                // setDataMaklumat2([]);
                setErrorMessage(t('empty_list'));
            }
          })
          .catch(error => {
            console.log('error');
            console.log(error);
            setisProgress(false);
            setDataMaklumat([]);
            // setDataMaklumat1([]);
            // setDataMaklumat2([]);
            setErrorMessage(t('error_message'));
          });
    }

    return (
        <div className="maklumat">
            <div className="col-md-7">
                <div className="maklumat-content">
                    <h3 className="maklumat-title">
                        Maklumat Pelayanan dan Biaya/Tarif Layanan
                    </h3>
                    <h3 className="maklumat-title">
                        Lembaga National Single Window
                    </h3>
                </div>
            </div>
            {
                is_progress === true ?
                <div style={{ height: '25vw' }} className="detail-content-load">
                    <LoadProgress />
                </div>
                :
                dataMaklumat.length === 0 ?
                    <div className="detail-content-load">
                        <ListEmpty message_error={error_message} />
                    </div>
                    :
                    dataMaklumat.map((item, index) =>
                    <div className="col-md-12 col-sm-12 maklumat-pelayanan">
                        <div className="mp">
                            <p className="mp-title">
                                {item.header}
                            </p>
                            <p className="mp-content">
                                {parseHtml(item.ur)}
                            </p>
                            {
                                item.file !== 'null' ?
                                <button className="btn btn-primary rounded-pill" onClick={() => unduh_data(item.file)}>{t('download')}</button>
                                :
                                null
                            }
                        </div>
                    </div>
                    // <div className="col-md-12 col-sm-12 maklumat-pelayanan">
                    //     <div className="mp border-bottom-0">
                    //         <p className="mp-title">
                    //             {dataMaklumat2.header}
                    //         </p>
                    //         <p className="mp-content">
                    //             {parseHtml(dataMaklumat2.ur)}
                    //         </p>
                    //     </div>
                    // </div>
                )}
        </div>
    )
}

export default MaklumatPelayanan;
