/* eslint-disable jsx-a11y/alt-text */
//import lib react
import styled from '@emotion/styled'
import './simulasi.scss'
import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-dropdown-select'
import axios from 'axios'
import { Button } from 'reactstrap'
import Swal from 'sweetalert2'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

//component
import { ICPercent } from '../../../assets'
import * as Api from '../../../constants/api'
import { InputRound, InputDateRound } from '../../../components'

const Simulasi = () => {
   //variable constant
   const { t } = useTranslation()
   //select import export
   const ref_activity = useRef(null)
   const [selected_activity, setSelectedActivity] = useState({
      label: t('import'),
      value: 'import',
   })
   const [selected_activity_label, setSelectedActivityLabel] = useState(
      t('import'),
   )
   const [list_select_activity, setListSelectActivity] = useState([
      {
         label: t('import'),
         value: 'import',
      },
      {
         label: t('export'),
         value: 'export',
      },
   ])
   const [isProgressActivity, setisProgressActivity] = useState(false)
   const [place_holder_activity, setPlaceHolderActivity] = useState(
      'simulasi-dropdown-remove-placeholder',
   )
   //select country
   const [selected_country, setSelectedCountry] = useState({})
   // const [selected_country_label, setSelectedCountryLabel] = useState(t('select_country_origin'));
   const [selected_country_label, setSelectedCountryLabel] = useState('')
   const [list_select_country, setListSelectCountry] = useState([])
   const [list_select_country_temp, setListSelectCountryTemp] = useState([])
   const [isProgressCountry, setisProgressCountry] = useState(false)
   const [place_holder_country, setPlaceHolderCountry] = useState(
      'simulasi-dropdown-auto-placeholder',
   )
   const [is_error_country, setIsErrorCountry] = useState(false)
   //select kurs
   const [selected_kurs, setSelectedKurs] = useState({})
   const [selected_kurs_label, setSelectedKursLabel] = useState(
      t('select_exchange_rate'),
   )
   const [list_select_kurs, setListSelectKurs] = useState([])
   const [isProgressKurs, setisProgressKurs] = useState(false)
   const [place_holder_kurs, setPlaceHolderKurs] = useState(
      'simulasi-dropdown-auto-placeholder',
   )
   const [is_error_kurs, setIsErrorKurs] = useState(false)
   //select jenis tarif
   // const [selected_tarif, setSelectedTarif] = useState({});
   const [selected_tarif, setSelectedTarif] = useState({
      label: 'Advalorum',
      value: 1,
   })
   const [selected_tarif_label, setSelectedTarifLabel] = useState('Advalorum')
   // const [selected_tarif_label, setSelectedTarifLabel] = useState(t('select_type_rates'));
   const [list_select_tarif, setListSelectTarif] = useState([
      {
         label: 'Advalorum',
         value: 1,
      },
      {
         label: 'Spesifik',
         value: 2,
      },
   ])
   const [isProgressTarif, setisProgressTarif] = useState(false)
   const [place_holder_tarif, setPlaceHolderTarif] = useState(
      'simulasi-dropdown-remove-placeholder',
   )
   const [is_error_tarif, setIsErrorTarif] = useState(false)
   //select hscode
   const [selected_hscode, setSelectedHscode] = useState({})
   const [selected_hscode_label, setSelectedHscodeLabel] = useState('')
   const [list_select_hscode, setListSelectHscode] = useState([])
   const [isProgressHscode, setisProgressHscode] = useState(false)
   const [is_error_hscode, setIsErrorHSCode] = useState(false)
   const [error_message_hscode, setErrorMessageHSCode] = useState('')
   const [is_lartas, setIsLartas] = useState(false)
   const [desc_lartas, setDescLartas] = useState('')
   //select cif fob
   const [selected_cif_fob, setSelectedCifFob] = useState({
      label: 'CIF',
      value: 'cif',
   })
   const [selected_cif_fob_label, setSelectedCifFobLabel] = useState('CIF')
   const [list_select_cif_fob, setListSelectCifFob] = useState([
      {
         label: 'CIF',
         value: 'cif',
      },
      {
         label: 'FOB',
         value: 'fob',
      },
   ])
   const [isProgressCifFob, setisProgressCifFob] = useState(false)
   const [place_holder_cif_fob, setPlaceHolderCifFob] = useState(
      'simulasi-dropdown-remove-placeholder',
   )
   //select preference
   const [selected_preference, setSelectedPreference] = useState('')
   const [selected_preference_label, setSelectedPreferenceLabel] = useState(
      t('pilih_preferensi'),
   )
   const [list_select_preference, setListSelectPreference] = useState([])
   const [isProgressPreference, setisProgressPreference] = useState(false)
   const [place_holder_preference, setPlaceHolderPreference] = useState(
      'simulasi-dropdown-auto-placeholder',
   )

   //select moda
   const [selected_moda, setSelectedModa] = useState({
      label: t('laut'),
      value: 2,
   })
   const [selected_moda_label, setSelectedModaLabel] = useState(t('laut'))
   const [list_select_moda, setListSelectModa] = useState([
      {
         label: t('darat'),
         value: 1,
      },
      {
         label: t('laut'),
         value: 2,
      },
      {
         label: t('udara'),
         value: 3,
      },
   ])
   const [isProgressModa, setisProgressModa] = useState(false)
   const [place_holder_moda, setPlaceHolderModa] = useState(
      'simulasi-dropdown-remove-placeholder',
   )
   const [is_error_moda, setIsErrorModa] = useState(false)

   const [plan_date, setPlanDate] = useState(new Date())
   const plan_date_data = useRef(new Date())
   const value_goods = useRef('')
   const [is_error_value_goods_import, setIsErrorValueGoodsImport] =
      useState(false)
   const [value_goods_label, setValueGoodsLabel] = useState('')
   const shipping_cost = useRef('')
   const [shipping_cost_label, setShippingCostLabel] = useState('')
   const shipping_fee = useRef('')
   const [shipping_fee_label, setShippingFeeLabel] = useState('')
   const [is_error_shipping_fee, setIsErrorShippingFee] = useState('')

   // const[tarif_preferensi, setTarifPreferensi] = useState('');
   // const[is_loading_preferensi, setIsLoadingPreferensi] = useState(false);
   // const[is_error_preferensi, setIsErrorPreferensi] = useState(false);
   // const[error_message_preferensi, setErrorMessagePreferensi] = useState('');
   const [bea_masuk, setBeaMasuk] = useState('')
   const bea_masuk_data = useRef('')
   const [is_error_bea_masuk, setIsErrorBeaMasuk] = useState(false)
   const [is_loading_bea_masuk, setIsLoadingBeaMasuk] = useState(false)
   const [ppn, setPPN] = useState('')
   const [ppnbm, setPPNBM] = useState('')
   const [pph, setPPH] = useState('')
   console.log(pph)

   // const[harga_referensi_label, setHargaReferensiLabel] = useState('');
   // const harga_referensi = useRef('');
   // const[is_harga_referensi, setIsHargaReferensi] = useState(false);
   // const[error_harga_referensi, setErrorHargaReferensi] = useState(false);
   // const[error_message_harga_referensi, setErrorMessageHargaReferensi] = useState('');
   const [amount_goods_label, setAmountGoodsLabel] = useState('')
   const amount_value_goods = useRef('')
   const [error_amount_value_goods, setErrorAmountValueGoods] = useState(false)
   const [total_value_goods_label, setTotalValueGoodsLabel] = useState('')
   const total_value_goods = useRef('')
   const [error_value_goods, setErrorValueGoods] = useState(false)
   const [export_duty_rate, setExportDutyRate] = useState('')
   const export_duty_rate_data = useRef('')
   const [error_export_duty_rate, setErrorExportDutyRate] = useState(false)
   const kode_valuta_tarif = useRef('')

   //result simulasi
   const [result_kurs_hkd, SetResultKursHKD] = useState(0)
   const [result_nilai_pabean, SetResultNilaiPabean] = useState(0)
   const [result_bea_masuk, SetResultBeaMasuk] = useState(0)
   const [result_nilai_impor, SetResultNilaiImpor] = useState(0)
   const [result_ppn, SetResultPPN] = useState(0)
   const [result_pph, SetResultPPH] = useState(0)
   const [result_ppnbm, SetResultPPNBM] = useState(0)
   const [result_total_harga_ekspor, SetResultTotalHargaEkspor] = useState(0)
   const [result_jumlah_barang, SetResultJumlahBarang] = useState(0)
   const [result_bea_keluar, SetResultBeaKeluar] = useState(0)
   const [result_total, SetResultTotal] = useState(0)

   const history = useHistory()

   //style dropdown
   const StyledItem = styled.div`
      color: #555;
      padding: 10px;
      border-radius: 3px;
      margin: 3px;
      cursor: pointer;
      > div {
         display: flex;
         align-items: center;
      }
      :hover {
         background: #f2f2f2;
      }
   `

   const formatRp = (value) => {
      var angka = value.replace('.', ',')
      var split = angka.split(',')
      var sisa = split[0].length % 3
      var rupiah = split[0].substr(0, sisa)
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi)

      if (ribuan) {
         var separator = sisa ? '.' : ''
         rupiah += separator + ribuan.join('.')
      }

      var rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
      var prefix = undefined ? rupiah : rupiah ? 'Rp. ' + rupiah : ''

      return prefix
   }

   const format_pembulatan = (value) => {
      // var hasil = Math.round(value / 1000) * 1000;
      var hasil = Math.ceil(value / 1000) * 1000
      return hasil
   }

   const format_decimal = (value) => {
      var data_value = value
      // console.log('format decimal');
      // console.log(data_value);
      data_value = data_value.replace(/[^0-9]/g, '')

      var split = data_value.split('.')
      var sisa = split[0].length % 3
      var rupiah = split[0].substr(0, sisa)
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi)

      if (ribuan) {
         var separator = sisa ? '.' : ''
         rupiah += separator + ribuan.join('.')
      }

      rupiah = split[1] !== undefined ? rupiah + '.' + split[1] : rupiah

      return rupiah
   }

   const format_decimal_with_dot = (value) => {
      var nilai = value.split('.')[0]
      // console.log('nilai');
      // console.log(nilai);
      var nilai_after_dot = value.split('.')[1]
      var data_value = nilai
      // console.log('format decimal');
      // console.log(data_value);
      data_value = data_value.replace(/[^0-9]/g, '')

      var split = data_value.split('.')
      var sisa = split[0].length % 3
      var rupiah = split[0].substr(0, sisa)
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi)

      if (ribuan) {
         var separator = sisa ? '.' : ''
         rupiah += separator + ribuan.join('.')
      }

      rupiah = split[1] !== undefined ? rupiah + '.' + split[1] : rupiah
      // rupiah = rupiah + ',' + nilai_after_dot;

      return rupiah
   }

   // did mount
   useEffect(() => {
      _get_data_country()
      _get_data_kurs()
      _get_data_moda()
      // _get_data_preference();
   }, [])

   //function
   const filter_input = (input_text) => {
      let filter_text = input_text.replace(/\'|\"|\--/g, '')
      return filter_text
   }

   const percent_input = (value) => {
      // console.log(value);
      var data_value = value
      data_value = data_value.replace(/[^0-9.]/g, '')
      if (data_value.includes('.')) {
         data_value = data_value.replace('..', '.')
         var value_decimal = data_value.substring(0, data_value.indexOf('.'))
         var value_coma = data_value.split('.').pop()
         // console.log('value_decimal');
         // console.log(value_decimal);
         // console.log(value_decimal.length);
         // console.log('value_coma');
         // console.log(value_coma);
         if (value_decimal.length > 3) {
            value_decimal = value_decimal.substring(0, 3)
            // console.log('replace_dot length > 3');
            // console.log(value_decimal);
         }
         if (value_decimal >= 100) {
            value_decimal = 100
            data_value = value_decimal
         } else {
            data_value = value_decimal + '.' + value_coma
         }
      } else {
         if (data_value.length > 3) {
            data_value = data_value.substring(0, 3)
            // console.log(data_value);
         } else if (data_value > 100) {
            data_value = 100
         }
      }
      return data_value
   }

   const _get_data_country = () => {
      setisProgressCountry(true)
      const url = Api.GET_NEGARA
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            // console.log(response.data);
            setisProgressCountry(false)
            if (response.data.code === '01') {
               let data_country = response.data.data
               for (var i = 0; i < response.data.data.length; i++) {
                  data_country[i].label = response.data.data[i].Negara
                  data_country[i].value = response.data.data[i].KodeNegara
               }
               setListSelectCountry(data_country)
               setListSelectCountryTemp(data_country)
            } else {
               setListSelectCountry([])
               setListSelectCountryTemp([])
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgressCountry(false)
            setListSelectCountry([])
            setListSelectCountryTemp([])
         })
   }

   const _get_data_kurs = () => {
      setisProgressKurs(true)
      const url =
         Api.KURS_BY_DATE +
         '/search?date=' +
         moment(plan_date_data.current).format('YYYY-MM-DD')
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            setisProgressKurs(false)
            if (response.data.code === '01') {
               let data_kurs = response.data.data
               for (var i = 0; i < response.data.data.length; i++) {
                  data_kurs[i].label =
                  response.data.data[i].kdKurs +
                  ' - ' +
                  formatRp(response.data.data[i].nilai.toString())
                  data_kurs[i].value = response.data.data[i].kdKurs
               }
               setListSelectKurs(data_kurs)
               for (var j = 0; j < data_kurs.length; j++) {
                  if (data_kurs[j].kdKurs === 'USD') {
                     // console.log()
                     setSelectedKurs(data_kurs[j])
                     setSelectedKursLabel(data_kurs[j].label)
                     // SetResultKursHKD(format_decimal_with_dot(data_kurs[j].nilai));
                  }
               }
            } else {
               setListSelectKurs([])
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgressKurs(false)
            setListSelectKurs([])
         })
   }

   const _get_data_hscode = (value) => {
      setisProgressHscode(true)
      const url = Api.HSCODE_SEARCH + filter_input(value)
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            // console.log(response.data);
            setisProgressHscode(false)
            if (response.data.code === '01') {
               let data_hscode = response.data.data
               for (var i = 0; i < response.data.data.length; i++) {
                  data_hscode[i].label = response.data.data[i].hsCodeFormat
                  data_hscode[i].value = response.data.data[i].hsCodeFormat
               }
               setListSelectHscode(data_hscode)
            } else {
               setListSelectHscode([])
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgressHscode(false)
            setListSelectHscode([])
         })
   }

   const _get_data_moda = () => {
      setisProgressModa(true)
      const url = Api.GET_MODA
      // console.log('url_moda');
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            // console.log(response.data);
            setisProgressModa(false)
            if (response.data.code === '01') {
               let data_moda = response.data.data
               for (var i = 0; i < response.data.data.length; i++) {
                  data_moda[i].label = response.data.data[i].uraianModa
                  data_moda[i].value = response.data.data[i].kodeModa
               }
               setListSelectModa(data_moda)
               for (var j = 0; j < data_moda.length; j++) {
                  if (data_moda[j].kodeModa === '1') {
                     // console.log()
                     setSelectedModa(data_moda[j])
                     setSelectedModaLabel(data_moda[j].label)
                  }
               }
            } else {
               setListSelectModa([])
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgressModa(false)
            setListSelectModa([])
         })
   }

   const _get_data_preference = (kdNegara) => {
      //reset preference
      // setIsErrorPreferensi(false);
      // setErrorMessagePreferensi('');
      setSelectedPreference({})
      setSelectedPreferenceLabel(t('pilih_preferensi'))
      setListSelectPreference([])
      setPlaceHolderPreference('simulasi-dropdown-auto-placeholder')

      // setTarifPreferensi('');
      // setIsLoadingPreferensi(false);

      setBeaMasuk('')
      bea_masuk_data.current = ''
      setIsLoadingBeaMasuk(false)

      setisProgressPreference(true)
      // const url = Api.GET_PREFERENCE;
      const url = Api.GET_PREFERENCE_COUNTRY + '/negara?kd_negara=' + kdNegara
      // console.log('url');
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            // console.log(response.data);
            setisProgressPreference(false)
            if (response.data.code === '01') {
               let data_preference = response.data.data
               for (var i = 0; i < response.data.data.length; i++) {
                  data_preference[i].label =
                     response.data.data[i].noSkep +
                     ' - ' +
                     response.data.data[i].preference
                  data_preference[i].value = response.data.data[i].idPreference
               }
               let obj_preference = {
                  idPreference: '',
                  label: 'Tidak Menggunakan Tarif Preferensi',
                  value: '',
               }
               data_preference.unshift(obj_preference)
               // console.log('data_preference');
               // console.log(data_preference);
               setListSelectPreference(data_preference)
            } else {
               setListSelectPreference([])
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgressPreference(false)
            setListSelectPreference([])
         })
   }

   // const _get_data_fta = (value) => {
   //     setIsLoadingPreferensi(true);
   //     const url = Api.SEARCH_FTA + 'id_preference=' + value + '&hs_code_format=' + selected_hscode.hsCodeFormat;
   //     // console.log(url);
   //     axios({
   //       method: 'get',
   //       url: url,
   //       headers: { 'Authorization': Api.BASIC_AUTH },
   //       timeout: 1000 * 10, // Wait for 10 seconds
   //     })
   //       .then(response => {
   //         console.log('get data fta');
   //         console.log(response.data);
   //         setIsLoadingPreferensi(false);
   //         if (response.data.code === '01') {
   //             setIsErrorPreferensi(false);
   //             setErrorMessagePreferensi('');
   //             if (response.data.data.length === 0) {
   //                 setIsErrorPreferensi(true);
   //                 setErrorMessagePreferensi(t('tarif_preferensi_not_found'));
   //             } else {
   //                 var name_jenis_tarif = '';
   //                 if (response.data.data[0].kd_jenistarif === 1) {
   //                     name_jenis_tarif = 'Advalorum';
   //                 } else {
   //                     name_jenis_tarif = 'Spesifik';
   //                 }
   //                 // console.log('name_jenis_tarif');
   //                 // console.log(name_jenis_tarif);
   //                 setSelectedTarif({
   //                     label: name_jenis_tarif,
   //                     value: response.data.data[0].kd_jenistarif
   //                 });
   //                 setSelectedTarifLabel(name_jenis_tarif);
   //                 setPlaceHolderTarif('simulasi-dropdown-remove-placeholder');

   //                 let tarif_preferensi_data = response.data.data[0];
   //                 // console.log(tarif_preferensi_data);
   //                 var nilai_preferensi = tarif_preferensi_data.nilai.replace('%','');
   //                 setTarifPreferensi(nilai_preferensi);
   //             }
   //         } else {
   //             setIsErrorPreferensi(true);
   //             setErrorMessagePreferensi(t('tarif_preferensi_not_found'));
   //         }
   //       })
   //       .catch(error => {
   //         console.log('error');
   //         console.log(error);
   //         setIsLoadingPreferensi(false);
   //         setIsErrorPreferensi(true);
   //         setErrorMessagePreferensi(t('tarif_preferensi_not_found'));
   //       });
   // }

   const _get_data_fta = (value) => {
      setIsLoadingBeaMasuk(true)
      const url =
         Api.SEARCH_FTA +
         'id_preference=' +
         value +
         '&hs_code_format=' +
         selected_hscode.hsCodeFormat
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            console.log('get data fta')
            console.log(response.data)
            setIsLoadingBeaMasuk(false)
            if (response.data.code === '01') {
               // setIsErrorPreferensi(false);
               // setErrorMessagePreferensi('');
               if (response.data.data.length === 0) {
                  // setIsErrorPreferensi(true);
                  // setErrorMessagePreferensi(t('tarif_preferensi_not_found'));
               } else {
                  var name_jenis_tarif = ''
                  if (response.data.data[0].kd_jenistarif === 1) {
                     name_jenis_tarif = 'Advalorum'
                  } else {
                     name_jenis_tarif = 'Spesifik'
                  }
                  // console.log('name_jenis_tarif');
                  // console.log(name_jenis_tarif);
                  setSelectedTarif({
                     label: name_jenis_tarif,
                     value: response.data.data[0].kd_jenistarif,
                  })
                  setSelectedTarifLabel(name_jenis_tarif)
                  setPlaceHolderTarif('simulasi-dropdown-remove-placeholder')

                  let tarif_preferensi_data = response.data.data[0]
                  // console.log(tarif_preferensi_data);
                  var nilai_preferensi = tarif_preferensi_data.nilai.replace(
                     '%',
                     '',
                  )
                  // setTarifPreferensi(nilai_preferensi);
                  bea_masuk_data.current = nilai_preferensi
                  setBeaMasuk(nilai_preferensi)
               }
            } else {
               // setIsErrorPreferensi(true);
               // setErrorMessagePreferensi(t('tarif_preferensi_not_found'));
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setIsLoadingBeaMasuk(false)
            // setIsErrorPreferensi(true);
            // setErrorMessagePreferensi(t('tarif_preferensi_not_found'));
         })
   }

   // const _get_tarif_bk = (value) => {
   //     setIsHargaReferensi(true);
   //     const url = Api.GET_TARIF_BK + 'hs_code='+ selected_hscode.hsCodeFormat +'&harga_ref=' + value;
   //     // console.log(url);
   //     axios({
   //       method: 'get',
   //       url: url,
   //       headers: { 'Authorization': Api.BASIC_AUTH },
   //       timeout: 1000 * 10, // Wait for 10 seconds
   //     })
   //       .then(response => {
   //         // console.log('response bk');
   //         // console.log(response.data);
   //         setIsHargaReferensi(false);
   //         if (response.data.code === '01') {
   //             setErrorHargaReferensi(false);
   //             setErrorMessageHargaReferensi('');
   //             let tarif_bk = response.data.data[0];
   //             // console.log(tarif_bk);
   //             var name_jenis_tarif = '';
   //             if (tarif_bk.kode_jenistarif === 1) {
   //                 name_jenis_tarif = 'Advalorum';
   //             } else {
   //                 name_jenis_tarif = 'Spesifik';
   //             }
   //             // console.log('name_jenis_tarif');
   //             // console.log(name_jenis_tarif);
   //             setSelectedTarif({
   //                 label: name_jenis_tarif,
   //                 value: tarif_bk.kode_jenistarif
   //             });
   //             setSelectedTarifLabel(name_jenis_tarif);
   //             setPlaceHolderTarif('simulasi-dropdown-remove-placeholder');
   //             setExportDutyRate(tarif_bk.tarif_bea_keluar);
   //             export_duty_rate_data.current = tarif_bk.tarif_bea_keluar;
   //             kode_valuta_tarif.current = tarif_bk.kode_valuta_tarif;
   //         } else {
   //             setErrorHargaReferensi(true);
   //             setErrorMessageHargaReferensi(t('reference_not_found'));
   //         }
   //       })
   //       .catch(error => {
   //         console.log('error');
   //         console.log(error);
   //         setIsHargaReferensi(false);
   //         setErrorHargaReferensi(true);
   //         setErrorMessageHargaReferensi(t('reference_not_found'));
   //       });
   // }

   const _get_tarif_bk_by_hscode = (value_hscode) => {
      const url = Api.GET_TARIF_BK_BY_HSCODE + 'hs_code=' + value_hscode
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            // console.log('response bk by hscode');
            // console.log(response.data);
            if (response.data.code === '01') {
               if (response.data.data.length > 0) {
                  let tarif_bk = response.data.data[0]
                  // console.log(tarif_bk);
                  var name_jenis_tarif = ''
                  if (tarif_bk.kode_jenistarif === 1) {
                     name_jenis_tarif = 'Advalorum'
                  } else {
                     name_jenis_tarif = 'Spesifik'
                  }
                  // console.log('name_jenis_tarif');
                  // console.log(name_jenis_tarif);
                  setSelectedTarif({
                     label: name_jenis_tarif,
                     value: tarif_bk.kode_jenistarif,
                  })
                  setSelectedTarifLabel(name_jenis_tarif)
                  setPlaceHolderTarif('simulasi-dropdown-remove-placeholder')
                  setExportDutyRate(tarif_bk.tarif_bea_keluar)
                  export_duty_rate_data.current = tarif_bk.tarif_bea_keluar
                  kode_valuta_tarif.current = tarif_bk.kode_valuta_tarif
                  console.log('kode_valuta_tarif')
                  console.log(kode_valuta_tarif)
               }
            } else {
               // setErrorHargaReferensi(true);
               // setErrorMessageHargaReferensi(t('reference_not_found'));
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            // setIsHargaReferensi(false);
            // setErrorHargaReferensi(true);
            // setErrorMessageHargaReferensi(t('reference_not_found'));
         })
   }

   const _get_tarif_bm = (value) => {
      setisProgressHscode(true)
      const url = Api.GET_TARIF_BM + value
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            // console.log(response.data);
            setisProgressHscode(false)
            if (response.data.code === '01') {
               var tarif_bm = response.data.data[0]
               // console.log('tarif bm', tarif_bm);
               setBeaMasuk(tarif_bm.bm_mfn)
               bea_masuk_data.current = tarif_bm.bm_mfn
               setPPN(tarif_bm.ppn)
               setPPNBM(tarif_bm.ppnbm)
               setPPH(tarif_bm.pph)
            } else {
               setIsErrorHSCode(true)
               setErrorMessageHSCode(t('failed_rates_bm'))
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgressHscode(false)
            setIsErrorHSCode(true)
            setErrorMessageHSCode(t('failed_rates_bm'))
         })
   }

   const _check_lartas = (value) => {
      setisProgressHscode(true)
      setIsLartas(false)
      // setDescLartas('');
      const url = Api.CHECK_LARTAS + value
      // console.log(url);
      axios({
         method: 'get',
         url: url,
         headers: { Authorization: Api.BASIC_AUTH },
         timeout: 1000 * 10, // Wait for 10 seconds
      })
         .then((response) => {
            // console.log('check lartas');
            // console.log(response.data);
            setisProgressHscode(false)
            if (response.data.code === '01') {
               if (response.data.data.length === 0) {
                  setIsLartas(false)
                  // setDescLartas('');
               } else {
                  setIsLartas(true)
                  // setDescLartas(response.data.data[0].uraianbarang);
               }
            } else {
               setIsLartas(false)
               //   setDescLartas('');
            }
         })
         .catch((error) => {
            console.log('error')
            console.log(error)
            setisProgressHscode(false)
            setIsLartas(false)
            // setDescLartas('');
         })
   }

   const filterList = (filterText) => {
      return list_select_country_temp.filter(function (item) {
         return item.label.toLowerCase().includes(filterText.toLowerCase())
      })
   }

   const reset_data = () => {
      //activity
      // setSelectedActivity({
      //     label: t('import'),
      //     value: 'import'
      // });
      // setSelectedActivityLabel(t('import'));
      // setPlaceHolderActivity('simulasi-dropdown-remove-placeholder');
      //country
      setSelectedCountry({})
      // setSelectedCountryLabel(t('select_country_origin'));
      setSelectedCountryLabel('')
      setPlaceHolderCountry('simulasi-dropdown-auto-placeholder')
      //kurs
      setSelectedKurs({})
      setSelectedKursLabel(t('select_exchange_rate'))
      setPlaceHolderKurs('simulasi-dropdown-auto-placeholder')
      //hscode
      setSelectedHscode({})
      setSelectedHscodeLabel('')
      setListSelectHscode([])
      setIsLartas(false)
      setDescLartas('')
      //jenis tarif
      setSelectedTarif({
         label: 'Advalorum',
         value: 1,
      })
      setSelectedTarifLabel('Advalorum')
      setPlaceHolderTarif('simulasi-dropdown-auto-placeholder')
      //cif fob
      setSelectedCifFob({
         label: 'CIF',
         value: 'cif',
      })
      setSelectedCifFobLabel('CIF')
      setPlaceHolderCifFob('simulasi-dropdown-remove-placeholder')
      //preference
      setSelectedPreference({})
      setSelectedPreferenceLabel(t('pilih_preferensi'))
      setListSelectPreference([])
      setPlaceHolderPreference('simulasi-dropdown-auto-placeholder')
      //set moda
      setSelectedModa({})
      setSelectedModaLabel(t('select_mode'))
      setListSelectModa([])
      setPlaceHolderModa('simulasi-dropdown-auto-placeholder')

      setPlanDate(new Date())
      plan_date_data.current = new Date()
      value_goods.current = ''
      setValueGoodsLabel('')
      shipping_cost.current = ''
      setShippingCostLabel('')
      shipping_fee.current = ''
      setShippingFeeLabel('')

      total_value_goods.current = ''
      setTotalValueGoodsLabel('')
      // harga_referensi.current = '';
      // setHargaReferensiLabel('');
      amount_value_goods.current = ''
      setAmountGoodsLabel('')
      export_duty_rate_data.current = ''
      setExportDutyRate('')

      // setTarifPreferensi('');
      // setIsLoadingPreferensi(false);
      bea_masuk_data.current = ''
      setBeaMasuk('')
      setPPN('')
      setPPNBM('')
      setPPH('')

      //result simulasi
      SetResultKursHKD(0)
      SetResultNilaiPabean(0)
      SetResultBeaMasuk(0)
      SetResultNilaiImpor(0)
      SetResultPPN(0)
      SetResultPPH(0)
      SetResultPPNBM(0)
      SetResultTotalHargaEkspor(0)
      SetResultJumlahBarang(0)
      SetResultBeaKeluar(0)
      SetResultTotal(0)

      reset_error()

      _get_data_country()
      _get_data_kurs()
      _get_data_moda()
      // _get_data_preference();

      window.scrollTo({
         top: ref_activity.current.offsetTop,
         left: 0,
         behavior: 'smooth',
      })
   }

   const reset_error = () => {
      // setErrorHargaReferensi(false);
      // setErrorMessageHargaReferensi('');
      setErrorAmountValueGoods(false)
      setErrorValueGoods(false)
      setErrorExportDutyRate(false)
      setIsErrorKurs(false)
      setIsErrorHSCode(false)
      setErrorMessageHSCode('')
      setIsErrorTarif(false)
      setIsErrorModa(false)
      setIsErrorCountry(false)
      setIsErrorValueGoodsImport(false)
      setIsErrorBeaMasuk(false)
      setIsErrorShippingFee(false)
      // setIsErrorPreferensi(false);
      // setErrorMessagePreferensi('');
   }

   const calculate_simulation = () => {
      reset_error()
      Swal.fire({
         title: t('loading'),
         html: t('please_wait'),
         showConfirmButton: false,
         willOpen: () => {
            Swal.showLoading()
         },
      })
      if (selected_activity.value === 'export') {
         var validate = true
         // console.log('JSON.stringify(selected_hscode)');
         // console.log(JSON.stringify(selected_hscode));
         if (JSON.stringify(selected_kurs) === '{}') {
            setIsErrorKurs(true)
            validate = false
         }
         if (JSON.stringify(selected_hscode) === '{}') {
            setIsErrorHSCode(true)
            validate = false
         }
         if (JSON.stringify(selected_tarif) === '{}') {
            setIsErrorTarif(true)
            validate = false
         }
         // if (harga_referensi.current === '') {
         //     setErrorHargaReferensi(true);
         //     validate = false;
         // }
         // if (kode_valuta_tarif.current === '') {
         //     setErrorHargaReferensi(true);
         //     setErrorMessageHargaReferensi(t('reference_not_found'));
         //     validate = false;
         // }
         if (selected_tarif.value === 2) {
            if (amount_value_goods.current === '') {
               setErrorAmountValueGoods(true)
               validate = false
            }
         }
         if (selected_tarif.value === 1) {
            if (total_value_goods.current === '') {
               setErrorValueGoods(true)
               validate = false
            }
         }
         if (export_duty_rate_data.current === '') {
            setErrorExportDutyRate(true)
            validate = false
         }

         if (validate === true) {
            setTimeout(() => {
               Swal.close()
               if (selected_tarif.value === 1) {
                  var total_harga_export =
                     total_value_goods.current * selected_kurs.nilai
                  SetResultTotalHargaEkspor(
                     format_decimal(total_harga_export.toString()),
                  )
                  SetResultBeaKeluar(export_duty_rate_data.current + ' %')
                  var total_a = Math.ceil(
                     (export_duty_rate_data.current / 100) *
                        (total_value_goods.current * selected_kurs.nilai),
                  )
                  SetResultTotal(format_decimal(total_a.toString()))
               } else {
                  var nilai_conversi = ''
                  for (var i = 0; i < list_select_kurs.length; i++) {
                     if (
                        kode_valuta_tarif.current === list_select_kurs[i].kdKurs
                     ) {
                        nilai_conversi = list_select_kurs[i].nilai
                     }
                  }
                  SetResultJumlahBarang(amount_value_goods.current)
                  var bea_keluar =
                     export_duty_rate_data.current * nilai_conversi
                  SetResultBeaKeluar(format_decimal(bea_keluar.toString()))
                  var total_b = bea_keluar * amount_value_goods.current
                  SetResultTotal(format_decimal(total_b.toString()))
               }
               setTimeout(() => {
                  window.scrollTo({
                     top: ref_activity.current.offsetTop,
                     left: 0,
                     behavior: 'smooth',
                  })
               }, 200)
            }, 2000)
         } else {
            setTimeout(() => {
               Swal.close()
               setTimeout(() => {
                  Swal.fire({
                     icon: 'error',
                     title: t('failed'),
                     text: t('check_data'),
                  }).then(function () {
                     setTimeout(() => {
                        window.scrollTo({
                           top: ref_activity.current.offsetTop,
                           left: 0,
                           behavior: 'smooth',
                        })
                     }, 200)
                  })
               }, 200)
            }, 2000)
         }
      } else {
         var validate_import = true
         if (JSON.stringify(selected_country) === '{}') {
            setIsErrorCountry(true)
            validate_import = false
         }
         if (JSON.stringify(selected_kurs) === '{}') {
            setIsErrorKurs(true)
            validate_import = false
         }
         if (JSON.stringify(selected_hscode) === '{}') {
            setIsErrorHSCode(true)
            validate_import = false
         }
         if (JSON.stringify(selected_tarif) === '{}') {
            setIsErrorTarif(true)
            validate_import = false
         }
         if (selected_tarif.value === 2) {
            if (amount_value_goods.current === '') {
               setErrorAmountValueGoods(true)
               validate_import = false
            }
         }
         if (value_goods.current === '') {
            setIsErrorValueGoodsImport(true)
            validate_import = false
         }
         if (selected_cif_fob.value === 'fob') {
            if (shipping_cost.current === '') {
               setIsErrorShippingFee(true)
               validate_import = false
            }
         }
         if (bea_masuk_data.current === '') {
            setIsErrorBeaMasuk(true)
            validate_import = false
         }

         if (validate_import === true) {
            setTimeout(() => {
               Swal.close()
               var nilai_pabean = 0
               if (selected_cif_fob.value === 'cif') {
                  nilai_pabean = Math.ceil(
                     value_goods.current * selected_kurs.nilai,
                  )
               } else {
                  nilai_pabean =
                     parseInt(value_goods.current) +
                        parseInt(shipping_cost.current) ===
                     undefined
                        ? 0
                        : parseInt(shipping_cost.current) +
                          parseInt(shipping_fee.current) * selected_kurs.nilai
               }
               var total_tarif_bm = 0
               var total_ppn = 0
               var total_ppnbm = 0
               var total_pph = 0
               // if (tarif_preferensi === '') {
               if (selected_tarif.value === 1) {
                  total_tarif_bm = format_pembulatan(
                     Math.ceil((bea_masuk_data.current / 100) * nilai_pabean),
                  )
               } else {
                  total_tarif_bm =
                     bea_masuk_data.current *
                     amount_value_goods.current *
                     selected_kurs.nilai
               }
               // } else {
               //     total_tarif_bm = format_pembulatan(Math.ceil((tarif_preferensi/100) * nilai_pabean));
               // }
               var total_nilai_import =
                  parseInt(nilai_pabean) + parseInt(total_tarif_bm)

               total_ppn = format_pembulatan(
                  Math.ceil(
                     (ppn === 'N/A' ? 0 : ppn / 100) * total_nilai_import,
                  ),
               )
               // total_ppn = Math.ceil((ppn/100) * total_nilai_import);
               total_ppnbm = format_pembulatan(
                  Math.ceil(
                     (ppnbm === 'N/A' ? 0 : ppnbm / 100) * total_nilai_import,
                  ),
               )
               total_pph = format_pembulatan(
                  Math.ceil(
                     (pph === 'N/A' ? 0 : pph / 100) * total_nilai_import,
                  ),
               )

               var total_bm =
                  total_tarif_bm + total_ppn + total_ppnbm + total_pph

               //    console.log('nilai_pabean')
               //    console.log(nilai_pabean)
               //    console.log(total_tarif_bm)
               //    console.log(total_ppn)
               //    console.log(total_ppnbm)
               //    console.log(total_pph)
               //    console.log(total_nilai_import)
               //    console.log(total_bm)

               SetResultKursHKD(
                  format_decimal_with_dot(selected_kurs.nilai.toString()),
               )
               SetResultNilaiPabean(format_decimal(nilai_pabean.toString()))
               SetResultBeaMasuk(format_decimal(total_tarif_bm.toString()))
               SetResultNilaiImpor(
                  format_decimal(total_nilai_import.toString()),
               )
               SetResultPPN(format_decimal(total_ppn.toString()))
               SetResultPPH(format_decimal(total_pph.toString()))
               SetResultPPNBM(format_decimal(total_ppnbm.toString()))
               SetResultTotal(format_decimal(total_bm.toString()))

               setTimeout(() => {
                  window.scrollTo({
                     top: ref_activity.current.offsetTop,
                     left: 0,
                     behavior: 'smooth',
                  })
               }, 200)
            }, 2000)
         } else {
            setTimeout(() => {
               Swal.close()
               setTimeout(() => {
                  Swal.fire({
                     icon: 'error',
                     title: t('failed'),
                     text: t('check_data'),
                  }).then(function () {
                     setTimeout(() => {
                        window.scrollTo({
                           top: ref_activity.current.offsetTop,
                           left: 0,
                           behavior: 'smooth',
                        })
                     }, 200)
                  })
               }, 200)
            }, 2000)
         }
      }
   }

   const searchComodity = () => {
      // history.push('/intr/detail-komoditas', {
      //     data: {
      //         lartas: selected_hscode.hsCodeFormat
      //     }
      // });
      let url =
         window.location.origin +
         '/detail-komoditas/' +
         selected_hscode.hsCodeFormat
      window.open(url, '_blank')
   }

   return (
      <div className="simulasi-page">
         <div className="simulasi-background">
            <div className="simulasi-content-title">
               <div className="simulasi-content-sub-title">
                  <div className="simulasi-text-title">
                     {t('title_cover_simulasi')}
                  </div>
                  <div className="simulasi-text-message">
                     {t('desc_cover_simulasi')}
                  </div>
               </div>
            </div>
         </div>

         <div className="simulasi-content">
            <div className="simulasi-content-input-data">
               <div style={{ marginLeft: '0.5vw' }}>
                  <div ref={ref_activity}>
                     <div style={{ display: 'flex' }}>
                        <div className="simulasi-dropdown-text">
                           {t('select_activities')}
                        </div>{' '}
                        <div style={{ color: '#ff884d' }}>*</div>
                     </div>
                     <Select
                        multi={false}
                        searchable={false}
                        options={list_select_activity}
                        values={[]}
                        style={{
                           borderColor: '#808080',
                           borderRadius: 20,
                           paddingLeft: 10,
                           paddingRight: 10,
                        }}
                        className={place_holder_activity}
                        onChange={(value) => {
                           // console.log(value);
                           setSelectedActivity(value[0])
                           setSelectedActivityLabel(value[0].label)
                           setPlaceHolderActivity(
                              'simulasi-dropdown-remove-placeholder',
                           )
                           reset_data()
                        }}
                        contentRenderer={({ props, state }) => (
                           <div>
                              {selected_activity_label ===
                              t('choose_export_import') ? (
                                 <div
                                    style={{
                                       cursor: 'pointer',
                                       display: 'flex',
                                    }}
                                 >
                                    <div className="simulasi-dropdown-placeholder">
                                       {selected_activity_label}
                                    </div>
                                 </div>
                              ) : (
                                 <div
                                    style={{
                                       cursor: 'pointer',
                                       display: 'flex',
                                    }}
                                 >
                                    <div className="simulasi-input-dropdown">
                                       {selected_activity_label}
                                    </div>
                                 </div>
                              )}
                           </div>
                        )}
                        itemRenderer={({ item, methods }) => (
                           <StyledItem>
                              <div onClick={() => methods.addItem(item)}>
                                 <div className="simulasi-dropdown-item">
                                    {item.label}
                                 </div>
                              </div>
                           </StyledItem>
                        )}
                     />
                  </div>
                  {selected_activity.value === 'import' ? (
                     <div style={{ marginTop: '2vw' }}>
                        <div style={{ display: 'flex' }}>
                           <div className="simulasi-dropdown-text">
                              {t('country_of_origin')}
                           </div>{' '}
                           <div style={{ color: '#ff884d' }}>*</div>
                        </div>
                        <Select
                           multi={false}
                           options={list_select_country}
                           values={[]}
                           style={{
                              borderColor:
                                 is_error_country === true ? 'red' : '#808080',
                              borderRadius: 20,
                              paddingLeft: 10,
                              paddingRight: 10,
                              fontFamily:
                                 "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                              fontSize: 14,
                           }}
                           className="simulasi-dropdown-auto-placeholder"
                           loading={isProgressCountry}
                           onChange={(value) => {
                              console.log(value)
                              if (value.length !== 0) {
                                 setSelectedCountry(value[0])
                                 setSelectedCountryLabel(value[0].label)
                                 // setPlaceHolderCountry('simulasi-dropdown-remove-placeholder');
                                 _get_data_preference(value[0].KodeNegara)
                              }
                           }}
                           dropdownHandleRenderer={({ props, state }) => (
                              <div></div>
                           )}
                           contentRenderer={({ props, state }) => (
                              <input
                                 value={selected_country_label}
                                 placeholder={t('select_country_origin')}
                                 className="simulasi-input-select"
                                 onChange={(e) => {
                                    // console.log(filterList(e.target.value));
                                    setListSelectCountry(
                                       filterList(e.target.value),
                                    )
                                    setSelectedCountryLabel(e.target.value)
                                 }}
                              />
                           )}
                           itemRenderer={({ item, methods }) => (
                              <StyledItem>
                                 <div onClick={() => methods.addItem(item)}>
                                    <div className="simulasi-dropdown-item">
                                       {item.label}
                                    </div>
                                 </div>
                              </StyledItem>
                           )}
                        />
                        {/* <Select
                                    multi={false}
                                    searchable={true}
                                    options={list_select_country}
                                    values={[]}
                                    style={{borderColor: is_error_country === true ? 'red' : '#808080', borderRadius: 20, paddingLeft: 10, paddingRight: 10}}
                                    className={place_holder_country}
                                    loading={isProgressCountry}
                                    onChange={(value) => {
                                        // console.log('nilai', value);
                                        setSelectedCountry(value[0]);
                                        setSelectedCountryLabel(value[0].label);
                                        setPlaceHolderCountry('simulasi-dropdown-remove-placeholder');
                                        _get_data_preference(value[0].KodeNegara);
                                    }}
                                    contentRenderer={({ props, state }) => (
                                        <div>
                                            {selected_country_label === t('select_country_origin') ? 
                                                <div style={{ cursor: 'pointer', display: "flex" }}>
                                                    <div className="simulasi-dropdown-placeholder">{selected_country_label}</div>
                                                </div>
                                                : 
                                                <div style={{ cursor: 'pointer', display: "flex" }}>
                                                    <div className="simulasi-input-dropdown">{selected_country_label}</div>
                                                </div>
                                            }
                                        </div>
                                    )}
                                    itemRenderer={({ item, methods }) => (
                                        <StyledItem>
                                            <div onClick={() => methods.addItem(item)}>
                                                <div className="simulasi-dropdown-item">
                                                    {item.label}
                                                </div>
                                            </div>
                                        </StyledItem>
                                    )}
                                /> */}
                     </div>
                  ) : null}

                  <div style={{ marginTop: '2vw' }}>
                     <div style={{ display: 'flex' }}>
                        <div className="simulasi-dropdown-text">
                           {t('plan_date_export_import')}
                        </div>{' '}
                        <div style={{ color: '#ff884d' }}>*</div>
                     </div>
                     <InputDateRound
                        Date={plan_date}
                        // maxDate={new Date()}
                        onChange={(date) => {
                           setPlanDate(date)
                           plan_date_data.current = date
                           setSelectedKurs({})
                           setSelectedKursLabel(t('select_exchange_rate'))
                           setPlaceHolderKurs(
                              'simulasi-dropdown-auto-placeholder',
                           )
                           _get_data_kurs()
                        }}
                        placeHolder={t('from_date')}
                     />
                  </div>

                  <div style={{ marginTop: '2vw' }}>
                     <div style={{ display: 'flex' }}>
                        <div className="simulasi-dropdown-text">
                           {t('exchange_rate')}
                        </div>{' '}
                        <div style={{ color: '#ff884d' }}>*</div>
                     </div>
                     <Select
                        multi={false}
                        searchable={false}
                        options={list_select_kurs}
                        values={[]}
                        style={{
                           borderColor:
                              is_error_kurs === true ? 'red' : '#808080',
                           borderRadius: 20,
                           paddingLeft: 10,
                           paddingRight: 10,
                        }}
                        className={place_holder_kurs}
                        loading={isProgressKurs}
                        onChange={(value) => {
                           // console.log(value);
                           setSelectedKurs(value[0])
                           setSelectedKursLabel(value[0].label)
                           setPlaceHolderKurs(
                              'simulasi-dropdown-remove-placeholder',
                           )
                           // SetResultKursHKD(format_decimal_with_dot(value[0].nilai));
                        }}
                        contentRenderer={({ props, state }) => (
                           <div>
                              {selected_kurs_label ===
                              t('select_exchange_rate') ? (
                                 <div
                                    style={{
                                       cursor: 'pointer',
                                       display: 'flex',
                                    }}
                                 >
                                    <div className="simulasi-dropdown-placeholder">
                                       {selected_kurs_label}
                                    </div>
                                 </div>
                              ) : (
                                 <div
                                    style={{
                                       cursor: 'pointer',
                                       display: 'flex',
                                    }}
                                 >
                                    <div className="simulasi-input-dropdown">
                                       {selected_kurs_label}
                                    </div>
                                 </div>
                              )}
                           </div>
                        )}
                        itemRenderer={({ item, methods }) => (
                           <StyledItem>
                              <div onClick={() => methods.addItem(item)}>
                                 <div className="simulasi-dropdown-item">
                                    {item.label}
                                 </div>
                              </div>
                           </StyledItem>
                        )}
                     />
                  </div>

                  <div style={{ marginTop: '2vw' }}>
                     <div style={{ display: 'flex' }}>
                        <div className="simulasi-dropdown-text">
                           {t('hscode')}
                        </div>{' '}
                        <div style={{ color: '#ff884d' }}>*</div>
                     </div>
                     <Select
                        multi={false}
                        options={list_select_hscode}
                        values={[]}
                        style={{
                           borderColor:
                              is_error_hscode === true ? 'red' : '#808080',
                           borderRadius: 20,
                           paddingLeft: 10,
                           paddingRight: 10,
                           fontFamily:
                              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
                           fontSize: 14,
                        }}
                        className="simulasi-dropdown-auto-placeholder"
                        loading={isProgressHscode}
                        onChange={(value) => {
                           console.log('hs', value)
                           if (value.length !== 0) {
                              setSelectedHscode(value[0])
                              setSelectedHscodeLabel(value[0].label)
                              _check_lartas(value[0].hsCodeFormat)
                              setDescLartas(value[0].urHeader1)
                              if (selected_activity.value === 'import') {
                                 _get_tarif_bm(value[0].hsCodeFormat)
                              } else {
                                 _get_tarif_bk_by_hscode(value[0].hsCodeFormat)
                              }
                           }
                        }}
                        dropdownHandleRenderer={({ props, state }) => (
                           <div></div>
                        )}
                        contentRenderer={({ props, state }) => (
                           <input
                              value={selected_hscode_label}
                              placeholder={t('select_hscode')}
                              className="simulasi-input-select"
                              onChange={(e) => {
                                 setDescLartas('')
                                 var value = e.target.value
                                 // console.log(value);
                                 if (value.length > 3) {
                                    _get_data_hscode(value)
                                 } else {
                                    setListSelectHscode([])
                                 }
                                 setSelectedHscodeLabel(e.target.value)
                              }}
                           />
                        )}
                        itemRenderer={({ item, methods }) => (
                           <StyledItem>
                              <div onClick={() => methods.addItem(item)}>
                                 <div className="simulasi-dropdown-item">
                                    {item.label}
                                 </div>
                              </div>
                           </StyledItem>
                        )}
                     />
                     <div className="simulasi-text-lartas">{desc_lartas}</div>
                     {is_lartas === true ? (
                        // <div onClick={() => searchComodity()} className="simulasi-text-lartas">{desc_lartas}
                        //     <div>{t('see_lartas')}</div>
                        // </div>
                        <div
                           onClick={() => searchComodity()}
                           className="simulasi-text-lartas-click"
                        >
                           <div>{t('see_lartas')}</div>
                        </div>
                     ) : null}
                     {is_error_hscode === true ? (
                        <div className="simulasi-text-error">
                           {error_message_hscode}
                        </div>
                     ) : null}
                  </div>
                  {/* {selected_activity.value === 'export' ?
                            <div style={{marginTop: '2vw'}}>
                                <div style={{display: "flex"}}>
                                    <div className="simulasi-dropdown-text">{t('reference_price')}</div> 
                                </div>
                                <InputRound
                                    is_loading={is_harga_referensi}
                                    is_error={error_harga_referensi}
                                    error_message={error_message_harga_referensi}
                                    value={harga_referensi_label}
                                    placeholder={t('fill_reference_price')}
                                    onChange={(e) => {
                                        var value = e.target.value;
                                        var value_fill = value.replace(/[^0-9]/g, '');
                                        harga_referensi.current = value_fill;
                                        setHargaReferensiLabel(value_fill)
                                        _get_tarif_bk(value_fill);
                                    }} />
                            </div>
                            : 
                            null
                            // <div style={{marginTop: '2vw'}}>
                            //     <div style={{display: "flex"}}>
                            //         <div className="simulasi-dropdown-text">{t('moda_transport')}</div> <div style={{color: '#ff884d'}}>*</div>
                            //     </div>
                            //     <Select
                            //         multi={false}
                            //         searchable={false}
                            //         options={list_select_moda}
                            //         values={[]}
                            //         style={{borderColor: is_error_moda === true ? 'red' : '#808080', borderRadius: 20, paddingLeft: 10, paddingRight: 10}}
                            //         className={place_holder_moda}
                            //         onChange={(value) => {
                            //             console.log(value);
                            //             setSelectedModa(value[0]);
                            //             setSelectedModaLabel(value[0].label);
                            //             setPlaceHolderModa('simulasi-dropdown-remove-placeholder');
                            //         }}
                            //         contentRenderer={({ props, state }) => (
                            //             <div>
                            //                 {selected_tarif_label === t('select_mode') ? 
                            //                     <div style={{ cursor: 'pointer', display: "flex" }}>
                            //                         <div className="simulasi-dropdown-placeholder">{selected_moda_label}</div>
                            //                     </div>
                            //                     : 
                            //                     <div style={{ cursor: 'pointer', display: "flex" }}>
                            //                         <div className="simulasi-input-dropdown">{selected_moda_label}</div>
                            //                     </div>
                            //                 }
                            //             </div>
                            //         )}
                            //         itemRenderer={({ item, methods }) => (
                            //             <StyledItem>
                            //                 <div onClick={() => methods.addItem(item)}>
                            //                     <div className="simulasi-dropdown-item">
                            //                         {item.label}
                            //                     </div>
                            //                 </div>
                            //             </StyledItem>
                            //         )}
                            //     />
                            // </div>
                        } */}
                  <div style={{ marginTop: '2vw' }}>
                     <div style={{ display: 'flex' }}>
                        <div className="simulasi-dropdown-text">
                           {t('type_rates')}
                        </div>{' '}
                        <div style={{ color: '#ff884d' }}>*</div>
                     </div>
                     <Select
                        multi={false}
                        searchable={false}
                        options={list_select_tarif}
                        values={[]}
                        style={{
                           borderColor:
                              is_error_tarif === true ? 'red' : '#808080',
                           borderRadius: 20,
                           paddingLeft: 10,
                           paddingRight: 10,
                        }}
                        className={place_holder_tarif}
                        onChange={(value) => {
                           // console.log(value);
                           setSelectedTarif(value[0])
                           setSelectedTarifLabel(value[0].label)
                           setPlaceHolderTarif(
                              'simulasi-dropdown-remove-placeholder',
                           )
                        }}
                        contentRenderer={({ props, state }) => (
                           <div>
                              {selected_tarif_label ===
                              t('select_type_rates') ? (
                                 <div
                                    style={{
                                       cursor: 'pointer',
                                       display: 'flex',
                                    }}
                                 >
                                    <div className="simulasi-dropdown-placeholder">
                                       {selected_tarif_label}
                                    </div>
                                 </div>
                              ) : (
                                 <div
                                    style={{
                                       cursor: 'pointer',
                                       display: 'flex',
                                    }}
                                 >
                                    <div className="simulasi-input-dropdown">
                                       {selected_tarif_label}
                                    </div>
                                 </div>
                              )}
                           </div>
                        )}
                        itemRenderer={({ item, methods }) => (
                           <StyledItem>
                              <div onClick={() => methods.addItem(item)}>
                                 <div className="simulasi-dropdown-item">
                                    {item.label}
                                 </div>
                              </div>
                           </StyledItem>
                        )}
                     />
                  </div>

                  {selected_tarif.value === 2 ? (
                     <div style={{ marginTop: '2vw' }}>
                        <div style={{ display: 'flex' }}>
                           <div className="simulasi-dropdown-text">
                              {t('amount_goods')}
                           </div>
                           {selected_tarif.value === 2 ? (
                              <div style={{ color: '#ff884d' }}>*</div>
                           ) : null}
                        </div>
                        <InputRound
                           value={amount_goods_label}
                           is_error={error_amount_value_goods}
                           placeholder={t('amount_goods_placeholder')}
                           onChange={(e) => {
                              var value = e.target.value
                              var value_fill = value.replace(/[^0-9]/g, '')
                              amount_value_goods.current = value_fill
                              setAmountGoodsLabel(value_fill)
                           }}
                        />
                     </div>
                  ) : null}

                  {selected_activity.value === 'export' ? (
                     <div>
                        {selected_tarif.value === 1 ? (
                           <div style={{ marginTop: '2vw' }}>
                              <div style={{ display: 'flex' }}>
                                 <div className="simulasi-dropdown-text">
                                    {JSON.stringify(selected_kurs) !== '{}'
                                       ? t('total_value_goods') +
                                         ' (' +
                                         selected_kurs.value +
                                         ')'
                                       : t('total_value_goods')}
                                 </div>{' '}
                                 <div style={{ color: '#ff884d' }}>*</div>
                              </div>
                              <InputRound
                                 value={total_value_goods_label}
                                 is_error={error_value_goods}
                                 placeholder={t(
                                    'total_value_goods_placeholder',
                                 )}
                                 onChange={(e) => {
                                    var value = e.target.value
                                    var value_fill = value.replace(
                                       /[^0-9]/g,
                                       '',
                                    )
                                    value = format_decimal(value)
                                    total_value_goods.current = value_fill
                                    setTotalValueGoodsLabel(value)
                                 }}
                              />
                           </div>
                        ) : null}

                        <div style={{ marginTop: '2vw' }}>
                           <div style={{ display: 'flex' }}>
                              <div className="simulasi-dropdown-text">
                                 {t('export_duty_rate')}
                              </div>{' '}
                              <div style={{ color: '#ff884d' }}>*</div>
                           </div>
                           <InputRound
                              image_icon={
                                 selected_tarif.value === 2 ? null : ICPercent
                              }
                              value={export_duty_rate}
                              is_error={error_export_duty_rate}
                              onChange={(e) => {
                                 // console.log(selected_tarif.value);
                                 if (selected_tarif.value === 2) {
                                    var value = e.target.value
                                    var value_fill = value.replace(
                                       /[^0-9]/g,
                                       '',
                                    )
                                    value = format_decimal(value)
                                    export_duty_rate_data.current = value_fill
                                    setExportDutyRate(value)
                                 } else {
                                    var data_value = percent_input(
                                       e.target.value,
                                    )
                                    export_duty_rate_data.current = data_value
                                    setExportDutyRate(data_value)
                                 }
                              }}
                           />
                        </div>
                     </div>
                  ) : (
                     <div>
                        <div style={{ marginTop: '2vw' }}>
                           <div style={{ display: 'flex' }}>
                              <div style={{ flex: 1, marginRight: '1vw' }}>
                                 <div style={{ display: 'flex' }}>
                                    <div className="simulasi-dropdown-text">
                                       {t('value_goods')}
                                    </div>{' '}
                                    <div style={{ color: '#ff884d' }}>*</div>
                                 </div>
                                 <InputRound
                                    is_error={is_error_value_goods_import}
                                    value={value_goods_label}
                                    placeholder={t('fill_value_kurs')}
                                    onChange={(e) => {
                                       var value = e.target.value
                                       var value_fill = value.replace(
                                          /[^0-9]/g,
                                          '',
                                       )
                                       value = format_decimal(value)
                                       value_goods.current = value_fill
                                       setValueGoodsLabel(value)
                                    }}
                                 />
                              </div>
                              <div
                                 style={{
                                    display: 'flex',
                                    marginLeft: '1vw',
                                    alignItems: 'flex-end',
                                 }}
                              >
                                 <Select
                                    multi={false}
                                    searchable={false}
                                    options={list_select_cif_fob}
                                    values={[]}
                                    style={{
                                       borderColor: '#808080',
                                       borderRadius: 20,
                                       paddingLeft: 10,
                                       paddingRight: 10,
                                       width: '15vw',
                                    }}
                                    className={place_holder_cif_fob}
                                    onChange={(value) => {
                                       // console.log(value);
                                       setSelectedCifFob(value[0])
                                       setSelectedCifFobLabel(value[0].label)
                                       setPlaceHolderCifFob(
                                          'simulasi-dropdown-remove-placeholder',
                                       )
                                    }}
                                    contentRenderer={({ props, state }) => (
                                       <div>
                                          {selected_cif_fob_label ===
                                          t('choose_cif_fob') ? (
                                             <div
                                                style={{
                                                   cursor: 'pointer',
                                                   display: 'flex',
                                                }}
                                             >
                                                <div className="simulasi-dropdown-placeholder">
                                                   {selected_cif_fob_label}
                                                </div>
                                             </div>
                                          ) : (
                                             <div
                                                style={{
                                                   cursor: 'pointer',
                                                   display: 'flex',
                                                }}
                                             >
                                                <div className="simulasi-input-dropdown">
                                                   {selected_cif_fob_label}
                                                </div>
                                             </div>
                                          )}
                                       </div>
                                    )}
                                    itemRenderer={({ item, methods }) => (
                                       <StyledItem>
                                          <div
                                             onClick={() =>
                                                methods.addItem(item)
                                             }
                                          >
                                             <div className="simulasi-dropdown-item">
                                                {item.label}
                                             </div>
                                          </div>
                                       </StyledItem>
                                    )}
                                 />
                              </div>
                           </div>
                        </div>

                        <div style={{ marginTop: '2vw' }}>
                           <div className="simulasi-border-desc">
                              {/* {t('desc_cif_1')}
                                        <br/>
                                        {t('desc_cif_2')}
                                        <br/>
                                        {t('desc_cif_3')}
                                        <br/>
                                        {t('desc_cif_4')} */}
                              {t('desc_cif_1')}
                              <ol type="a" style={{ marginLeft: 15 }}>
                                 <li>{t('desc_cif_2')}</li>
                                 <li>{t('desc_cif_3')}</li>
                                 <li>{t('desc_cif_4')}</li>
                              </ol>
                           </div>
                        </div>
                        {selected_cif_fob.value === 'fob' ? (
                           <div>
                              <div style={{ marginTop: '2vw' }}>
                                 <div style={{ display: 'flex' }}>
                                    <div className="simulasi-dropdown-text">
                                       {t('shipping_cost')}
                                    </div>
                                 </div>
                                 <InputRound
                                    value={shipping_cost_label}
                                    placeholder={t('fill_cost')}
                                    onChange={(e) => {
                                       var value = e.target.value
                                       var value_fill = value.replace(
                                          /[^0-9]/g,
                                          '',
                                       )
                                       value = format_decimal(value)
                                       shipping_cost.current = value_fill
                                       setShippingCostLabel(value)
                                    }}
                                 />
                              </div>

                              <div style={{ marginTop: '2vw' }}>
                                 <div style={{ display: 'flex' }}>
                                    <div className="simulasi-dropdown-text">
                                       {t('shipping_fee')}
                                    </div>{' '}
                                    <div style={{ color: '#ff884d' }}>*</div>
                                 </div>
                                 <InputRound
                                    value={shipping_fee_label}
                                    is_error={is_error_shipping_fee}
                                    placeholder={t('fill_cost_shipping')}
                                    onChange={(e) => {
                                       var value = e.target.value
                                       var value_fill = value.replace(
                                          /[^0-9]/g,
                                          '',
                                       )
                                       value = format_decimal(value)
                                       shipping_fee.current = value_fill
                                       setShippingFeeLabel(value)
                                    }}
                                 />
                              </div>
                           </div>
                        ) : null}

                        <div style={{ marginTop: '2vw' }}>
                           {/* <div style={{display: "flex"}}>
                                        <div style={{flex: 1}}> */}
                           <div style={{ display: 'flex' }}>
                              <div className="simulasi-dropdown-text">
                                 {t('preferensi')}
                              </div>
                           </div>
                           <Select
                              multi={false}
                              searchable={false}
                              options={list_select_preference}
                              values={[]}
                              style={{
                                 borderColor: '#808080',
                                 borderRadius: 20,
                                 paddingLeft: 10,
                                 paddingRight: 10,
                              }}
                              className={place_holder_preference}
                              onChange={(value) => {
                                 // console.log(value);
                                 setSelectedPreference(value[0])
                                 setSelectedPreferenceLabel(value[0].label)
                                 setPlaceHolderPreference(
                                    'simulasi-dropdown-remove-placeholder',
                                 )
                                 if (value[0].value !== '') {
                                    _get_data_fta(value[0].value)
                                 }
                              }}
                              contentRenderer={({ props, state }) => (
                                 <div>
                                    {selected_preference_label ===
                                    t('pilih_preferensi') ? (
                                       <div
                                          style={{
                                             cursor: 'pointer',
                                             display: 'flex',
                                          }}
                                       >
                                          <div className="simulasi-dropdown-placeholder">
                                             {selected_preference_label}
                                          </div>
                                       </div>
                                    ) : (
                                       <div
                                          style={{
                                             cursor: 'pointer',
                                             display: 'flex',
                                          }}
                                       >
                                          <div className="simulasi-input-dropdown">
                                             {selected_preference_label}
                                          </div>
                                       </div>
                                    )}
                                 </div>
                              )}
                              itemRenderer={({ item, methods }) => (
                                 <StyledItem>
                                    <div onClick={() => methods.addItem(item)}>
                                       <div className="simulasi-dropdown-item">
                                          {item.label}
                                       </div>
                                    </div>
                                 </StyledItem>
                              )}
                           />
                           {/* </div> */}
                           {/* <div style={{flex: 1, marginLeft: '1vw'}}>
                                            <div style={{display: "flex"}}>
                                                <div className="simulasi-dropdown-text">{t('Preferred_rates')}</div>
                                            </div>
                                            <InputRound
                                                image_icon={ICPercent}
                                                is_loading={is_loading_preferensi}
                                                is_error={is_error_preferensi}
                                                error_message={error_message_preferensi}
                                                value={tarif_preferensi}
                                                onChange={(e) => {
                                                    var data_value = percent_input(e.target.value);
                                                    setTarifPreferensi(data_value);
                                                }} 
                                            />
                                        </div>
                                    </div> */}
                        </div>

                        <div style={{ marginTop: '2vw' }}>
                           <div style={{ display: 'flex' }}>
                              <div style={{ flex: 1, marginRight: '1vw' }}>
                                 <div style={{ display: 'flex' }}>
                                    <div className="simulasi-dropdown-text">
                                       {t('bea_masuk')}
                                    </div>{' '}
                                    <div style={{ color: '#ff884d' }}>*</div>
                                 </div>
                                 <InputRound
                                    value={bea_masuk}
                                    is_error={is_error_bea_masuk}
                                    is_loading={is_loading_bea_masuk}
                                    image_icon={
                                       selected_tarif.value === 2
                                          ? null
                                          : ICPercent
                                    }
                                    onChange={(e) => {
                                       if (selected_tarif.value === 2) {
                                          var value = e.target.value
                                          var value_fill = value.replace(
                                             /[^0-9]/g,
                                             '',
                                          )
                                          value = format_decimal(value)
                                          bea_masuk_data.current = value_fill
                                          setBeaMasuk(value)
                                       } else {
                                          var data_value = percent_input(
                                             e.target.value,
                                          )
                                          bea_masuk_data.current = data_value
                                          setBeaMasuk(data_value)
                                       }
                                    }}
                                 />
                              </div>
                              <div style={{ flex: 1, marginLeft: '1vw' }}>
                                 <div style={{ display: 'flex' }}>
                                    <div className="simulasi-dropdown-text">
                                       {t('ppn')}
                                    </div>
                                 </div>
                                 <InputRound
                                    image_icon={ICPercent}
                                    value={ppn}
                                    onChange={(e) => {
                                       let data_value = percent_input(
                                          e.target.value,
                                       )
                                       setPPN(data_value)
                                    }}
                                 />
                              </div>
                           </div>
                        </div>

                        <div style={{ marginTop: '2vw' }}>
                           <div style={{ display: 'flex' }}>
                              <div style={{ flex: 1, marginRight: '1vw' }}>
                                 <div style={{ display: 'flex' }}>
                                    <div className="simulasi-dropdown-text">
                                       {t('ppnbm')}
                                    </div>
                                 </div>
                                 <InputRound
                                    image_icon={ICPercent}
                                    value={ppnbm}
                                    onChange={(e) => {
                                       let data_value = percent_input(
                                          e.target.value,
                                       )
                                       setPPNBM(data_value)
                                    }}
                                 />
                              </div>
                              <div style={{ flex: 1, marginLeft: '1vw' }}>
                                 <div style={{ display: 'flex' }}>
                                    <div className="simulasi-dropdown-text">
                                       {t('pph')}
                                    </div>
                                 </div>
                                 <InputRound
                                    image_icon={ICPercent}
                                    value={pph}
                                    onChange={(e) => {
                                       let data_value = percent_input(
                                          e.target.value,
                                       )
                                       setPPH(data_value)
                                    }}
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  )}

                  <div style={{ marginTop: '4vw' }}>
                     <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, marginRight: '1vw' }}>
                           <Button
                              onClick={() => reset_data()}
                              className="simulasi-button-reset"
                              size="mid"
                           >
                              {t('reset')}
                           </Button>
                        </div>
                        <div style={{ flex: 1, marginLeft: '1vw' }}>
                           <Button
                              onClick={() => calculate_simulation()}
                              className="simulasi-button-calculate"
                              size="mid"
                           >
                              {t('calculate_cost')}
                           </Button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="simulasi-content-result">
               <div>
                  <div className="simulasi-content-result-border-desc">
                     <div className="simulasi-content-result-border-desc-title">
                        {t('disclaimer')}
                     </div>
                     <br />
                     <div className="simulasi-content-result-border-desc-desc">
                        {t('disclaimer_desc')}
                     </div>
                  </div>
               </div>
               <div
                  className="simulasi-content-result-border"
                  style={{ marginTop: '2vw' }}
               >
                  <div className="simulasi-content-result-border-title">
                     {t('result_simulation')}
                  </div>
                  <div style={{ padding: '2vw' }}>
                     {selected_activity.value === 'import' ? (
                        <div>
                           <div style={{ display: 'flex' }}>
                              <div className="simulasi-content-result-border-content">
                                 {t('kurs_hkd_idr') +
                                    ' ' +
                                    selected_kurs.kdKurs +
                                    ' ' +
                                    t('to') +
                                    ' IDR'}
                              </div>
                              <div className="simulasi-content-result-border-currency">
                                 Rp
                              </div>
                              <div className="simulasi-content-result-border-result">
                                 {result_kurs_hkd}
                              </div>
                           </div>
                           <div style={{ display: 'flex', marginTop: '2vw' }}>
                              <div className="simulasi-content-result-border-content">
                                 {t('customs_value')}
                              </div>
                              <div className="simulasi-content-result-border-currency">
                                 Rp
                              </div>
                              <div className="simulasi-content-result-border-result">
                                 {result_nilai_pabean}
                              </div>
                           </div>
                           <div style={{ display: 'flex', marginTop: '2vw' }}>
                              <div className="simulasi-content-result-border-content">
                                 {t('import_value')}
                              </div>
                              <div className="simulasi-content-result-border-currency">
                                 Rp
                              </div>
                              <div className="simulasi-content-result-border-result">
                                 {result_nilai_impor}
                              </div>
                           </div>
                           <div style={{ marginTop: '2vw' }}>
                              <div
                                 style={{
                                    borderBottom: '2px dashed #02275D',
                                 }}
                              ></div>
                           </div>
                           <div style={{ display: 'flex', marginTop: '2vw' }}>
                              <div className="simulasi-content-result-border-content">
                                 {/* {t('import_duty') + (selected_tarif.value === 1 ? ' (' + (tarif_preferensi === '' ? bea_masuk : tarif_preferensi) + '%)' : '')} */}
                                 {t('import_duty') +
                                    (selected_tarif.value === 1
                                       ? ' (' + bea_masuk + '%)'
                                       : '')}
                              </div>
                              <div className="simulasi-content-result-border-currency">
                                 Rp
                              </div>
                              <div className="simulasi-content-result-border-result">
                                 {result_bea_masuk}
                              </div>
                           </div>
                           <div style={{ display: 'flex', marginTop: '2vw' }}>
                              <div className="simulasi-content-result-border-content">
                                 {'PPN (' + ppn + '%)'}
                              </div>
                              <div className="simulasi-content-result-border-currency">
                                 Rp
                              </div>
                              <div className="simulasi-content-result-border-result">
                                 {result_ppn}
                              </div>
                           </div>
                           <div style={{ display: 'flex', marginTop: '2vw' }}>
                              <div className="simulasi-content-result-border-content">
                                 {'PPH (' + pph + '%)'}
                              </div>
                              <div className="simulasi-content-result-border-currency">
                                 Rp
                              </div>
                              <div className="simulasi-content-result-border-result">
                                 {result_pph}
                              </div>
                           </div>
                           <div style={{ display: 'flex', marginTop: '2vw' }}>
                              <div className="simulasi-content-result-border-content">
                                 {'PPn BM (' + ppnbm + '%)'}
                              </div>
                              <div className="simulasi-content-result-border-currency">
                                 Rp
                              </div>
                              <div className="simulasi-content-result-border-result">
                                 {result_ppnbm}
                              </div>
                           </div>
                        </div>
                     ) : (
                        <div>
                           {selected_tarif.value === 1 ? (
                              <div style={{ display: 'flex' }}>
                                 <div className="simulasi-content-result-border-content">
                                    {t('total_export_price')}
                                 </div>
                                 <div className="simulasi-content-result-border-currency">
                                    Rp
                                 </div>
                                 <div className="simulasi-content-result-border-result">
                                    {result_total_harga_ekspor}
                                 </div>
                              </div>
                           ) : (
                              <div style={{ display: 'flex' }}>
                                 <div className="simulasi-content-result-border-content">
                                    {t('amount_goods') + ' (Ton)'}
                                 </div>
                                 <div className="simulasi-content-result-border-currency">
                                    {''}
                                 </div>
                                 <div className="simulasi-content-result-border-result">
                                    {result_jumlah_barang}
                                 </div>
                              </div>
                           )}

                           <div style={{ display: 'flex', marginTop: '2vw' }}>
                              <div className="simulasi-content-result-border-content">
                                 {/* {t('bea_keluar') + ' (' + export_duty_rate + '%)'} */}
                                 {t('bea_keluar')}
                              </div>
                              <div className="simulasi-content-result-border-currency">
                                 {selected_tarif.value === 1 ? '' : 'Rp'}
                              </div>
                              <div className="simulasi-content-result-border-result">
                                 {result_bea_keluar}
                              </div>
                           </div>
                        </div>
                     )}
                     <div style={{ marginTop: '2vw' }}>
                        <div
                           style={{
                              borderBottom: '1px solid #02275D',
                           }}
                        ></div>
                     </div>
                     <div style={{ display: 'flex', marginTop: '2vw' }}>
                        <div className="simulasi-content-result-border-content-lg">
                           {selected_activity.value === 'import'
                              ? 'Total BM dan PDRI'
                              : 'Total Bea Keluar'}
                        </div>
                        <div className="simulasi-content-result-border-currency-lg">
                           Rp
                        </div>
                        <div className="simulasi-content-result-border-result-lg">
                           {result_total}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Simulasi
