import React, { lazy, useEffect, useState } from 'react';

import './carousel.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselItems from '../CarouselItems';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import * as Api from '../../../constants/api';
import LoadProgress from '../LoadProgress';
import ListEmpty from '../ListEmpty';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 1,
    partialVisibilityGutter: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    items: 1
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464
    },
    items: 1
  }
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li
      className={active ? "active-caro" : "inactive-caro"}
      onClick={() => onClick()}
    >
    </li>
  );
};

const CarouselComp = () => {
  const { t } = useTranslation();
  const [is_progress, setisProgress] = useState(true);
  const [list_Banner, setListBanner] = useState([]);
  const [error_message_banner, setErrorMessageBanner] = useState('');

  const width = window.innerWidth;

  //didmount
  useEffect(() => {
    get_data();
  }, []);

  //function
  const get_data = () => {
    setisProgress(true);
    const url = Api.BANNER_SLIDER + "?lang=" + localStorage.getItem('language').toUpperCase();
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        // console.log(response.data.code);
        setisProgress(false);
        if (response.data.code === '01') {
          // console.log('slider', response.data.data);
          let dataArray = (response.data.data);
          // console.log('slide', dataArray);
          if (width < 600){
            dataPartial(dataArray);
          } else {
            setListBanner(dataArray);
          }
        } else {
          setListBanner([]);
          setErrorMessageBanner(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgress(false);
        setListBanner([]);
        setErrorMessageBanner(t('error_message'));
      });
  }

  const dataPartial = (item) => {
    // console.log('banner', item);
    let banner = [];

    for (let i = 0; i < item.length; i++) {
      const slider = item[i].slider;
      let jmlSlider = slider.length;
      // console.log('banner slider' + i, slider);

      if (jmlSlider === 3){
        for (let j = 0; j < slider.length; j++) {
          const sliders = slider[j];
          slider[j].jml = jmlSlider;

          let data = {
            slider : [sliders]
          }
          banner.push(data);
        }
      } else if (jmlSlider === 2){
        for (let j = 0; j < slider.length; j++) {
          const sliders = slider[j];
          slider[j].jml = jmlSlider;

          let data = {
            slider : [sliders]
          }
          banner.push(data);
        }
      } else {
        slider[0].jml = jmlSlider;
        let data = {
          slider : slider
        }
        banner.push(data);
      }
      
    }
    console.log('banner array', banner);
    setListBanner(banner);
  }

  const removeDot = item => {
    var imageUrl = Api.IP_KONG_LNSW + item.slice(1);
    // console.log(imageUrl);
    return imageUrl;
  }

  const slides = list_Banner.map((item) => {
    return (
      <div className="home-banner-content">
        <div className="banner-item">
          {item.slider.map((item_data) =>
            <CarouselItems
              gambar={removeDot(item_data.nama_img)}
              judul={item_data.ur}
              onClick={item_data.url_link}
              data={item.slider}
              jml={item_data.jml}
            />
          )}
        </div>
      </div>
    );
  });

  return (
    <div className="detail-carohome-content">
    {
      is_progress === true ?
      <div style={{height: '25vw'}} className="detail-content-load">
          <LoadProgress />
      </div> 
      :
      list_Banner.length === 0 ?
      <div className="detail-content-load">
          <ListEmpty message_error={error_message_banner}/>
      </div>
      :
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlay={true}
        autoPlaySpeed={5000}
        transitionDuration={1000}
        customTransition="transform 1000ms ease-in-out"
        centerMode={false}
        customDot={<CustomDot />}
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        containerClass={{ width: '100%' }}
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable>
        {slides}
      </Carousel> 
    }
  </div>
  );
}

export default CarouselComp;