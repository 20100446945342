import axios from 'axios'
import * as Api from '../../../constants/api'

export default {
   async getGroupMenu() {
      const res = await axios.get(`${Api.IP_LNSW_CMS}getGroupMenu`, {
         headers: { Authorization: Api.BASIC_AUTH },
      })
      return res.data
   },
   async getMenuNle() {
      const res = await axios.get(`${Api.IP_LNSW_CMS}getMenuNle`, {
         headers: { Authorization: Api.BASIC_AUTH },
      })
      return res.data
   },
}
