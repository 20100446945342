import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../constants/api';
import './index.scss';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { ReactComponent as CalendarIcon } from '../../assets/icon/ic_calendar_gray_news.svg'
import { InputSearch } from '../../components/atoms';
import { useTranslation } from 'react-i18next';
import ReactPlayer from "react-player";
import GridList from 'react-gridlist';
import { useHistory } from 'react-router-dom';
import { ListEmpty, LoadProgress } from '../../components';

const PusatBantuan = () => {
    const [data, setData] = useState([]);
    const [judul, setJudul] = useState([]);
    const [panduan, setPanduan] = useState([]);
    const [faq, setFAQ] = useState([]);
    const [video, setVideo] = useState([]);
    const [search, setSearch] = useState([]);

    const [katFAQ, setKatFAQ] = useState([]);
    const history = useHistory();

    const [is_progress, setisProgress] = useState(false);
    const [error_message, setErrorMessage] = useState('');

    // Grid List
    const width = window.innerWidth;

    const getGridGap = (elementWidth, windowHeight) => (elementWidth > 720 && windowHeight > 480) ? 10 : 30;
    
    let getColumnCount = (elementWidth) => Math.floor(elementWidth / 350); //pembagian column (dibagi untuk 2 column) // awal 400 laptop hilman 377

    if (width < 600) {
        getColumnCount = (elementWidth) => Math.floor(elementWidth / 300);
    }

    const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.5);

    const getItemData = (katFAQ, columnWidth) => {
        // let adjustedHeight = Math.round(columnWidth * 0.4)
        return {
            key: katFAQ.id,
            // height: adjustedHeight,
        }
    }

    ////////////

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        _get_data_panduan();
        // _get_data_faq();
        _get_data_video();
        // searchData();

        _get_data_kat_faq();
    }, []);

    const { t } = useTranslation();

    const CallImage = (image_url) => {
        var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        return url_image;
    }

    const parseHtml = (html) => {
        var text_html = '';
        var text_slice = '';
        if (html !== undefined) {
            text_html = parse(html);
        }

        if (text_html.length > 60) {
            text_slice = text_html.substring(0, 40);
            return text_slice + '...';
        } else {
            text_slice = text_html;
            return text_slice;
        }
    }

    const _get_data_panduan = () => {
        const url = Api.PUSAT_BANTUAN_PANDUAN + '&lang=' + localStorage.getItem('language').toUpperCase();
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    setPanduan(response.data.data);
                } else {
                    setPanduan([]);
                }
            })
            .catch(error => {
                setPanduan([]);
            });
    }

    // const _get_data_faq = () => {
    //     const url = Api.PUSAT_BANTUAN_FAQ + '&lang=' + localStorage.getItem('language').toUpperCase();
    //     axios({
    //         method: 'get',
    //         url: url,
    //         headers: { 'Authorization': Api.BASIC_AUTH },
    //         timeout: 1000 * 10, // Wait for 10 seconds
    //     })
    //         .then(response => {
    //             if (response.data.code === '01') {
    //                 setFAQ(response.data.data);
    //             } else {
    //                 setFAQ([]);
    //             }
    //         })
    //         .catch(error => {
    //             setFAQ([]);
    //         });
    // }

    const _get_data_kat_faq = () => {
        setisProgress(true);
        const url = Api.PUSAT_BANTUAN_KAT_FAQ + '?lang=' + localStorage.getItem('language').toUpperCase();
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                setisProgress(false);
                if (response.data.code === '01') {
                    // console.log('katfaq', response.data.data);
                    setKatFAQ(response.data.data);
                } else {
                    setErrorMessage(t('empty_list'));
                    setKatFAQ([]);
                }
            })
            .catch(error => {
                setisProgress(false);
                setErrorMessage(t('error_message'));
                setKatFAQ([]);
            });
    }

    const _get_data_video = () => {
        const url = Api.PUSAT_BANTUAN_VIDEO + '&lang=' + localStorage.getItem('language').toUpperCase();
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    setVideo(response.data.data);
                } else {
                    setVideo([]);
                }
            })
            .catch(error => {
                setVideo([]);
            });
    }

    const CallVideo = (image_url) => {
        var url_image = '';
        if (image_url !== undefined) {
            url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        }
        return url_image + '#t=0.5';
    }

    const searchData = (e) => {
        if (e.length < 2) {
            _get_data_panduan();
            // _get_data_faq();
            _get_data_video();
        } else {
            const url = Api.PUSAT_BANTUAN_SEARCH + '?lang=' + localStorage.getItem('language').toUpperCase() + '&keyword=' + e;
            axios({
                method: 'get',
                url: url,
                headers: { 'Authorization': Api.BASIC_AUTH },
                timeout: 1000 * 10, // Wait for 10 seconds
            })
                .then(response => {
                    if (response.data.code === '01') {
                        try {
                            var faq_new_list = [];
                            var panduan_new_list = [];
                            var tutorial_new_list = [];
                            let data_res = response.data.data;
                            for (let i = 0; i < data_res.length; i++) {
                                if (data_res[i].kategori === 'faq') {
                                    faq_new_list.push(data_res[i]);
                                } else if (data_res[i].kategori === 'panduan') {
                                    panduan_new_list.push(data_res[i]);
                                } else if (data_res[i].kategori === 'video') {
                                    tutorial_new_list.push(data_res[i]);
                                }
                            }
                            setFAQ(faq_new_list);
                            setPanduan(panduan_new_list);
                            setVideo(tutorial_new_list);
                        } catch {
                            setFAQ([]);
                            setPanduan([]);
                            setVideo([]);
                        }
                    } else {
                        setFAQ([]);
                        setPanduan([]);
                        setVideo([]);
                    }
                })
                .catch(error => {
                    setFAQ([]);
                    setPanduan([]);
                    setVideo([]);
                });
        }
    }

    const removeDot = item => {
        var imageUrl = Api.IP_KONG_LNSW + item.slice(1);
        // console.log(imageUrl);
        return imageUrl;
    }

    const faqDetail = item => {
        console.log('tekan aq '+ item.kd_kategori_faq);
        // history.push('/pusat-bantuan/detail', { kd_kategori: item.kd_kategori_faq });
        history.push('/pusat-bantuan/detail-faq/'+ item.kd_kategori_faq );
        // history.push('/pusat-bantuan/detail/'+ item.kd_kategori_faq );
    }

    return (
        <>
            <div className="pusat-bantuan">
                <div className="pb-content">
                    <h3 className="pb-title">
                        {t("pusat-bantuan")}
                    </h3>
                    <div className="row m-0 justify-content-center">
                        <p className="col-md-6">
                            {t("pusat-bantuan-sub")}
                        </p>
                    </div>
                    <div className="row m-0 justify-content-center">
                        <p className="col-md-6 search-bar">
                            <InputSearch placeholder={t('find_something_pb')}
                                onChange={e => searchData(e.target.value)} onClick={(e) => searchData(e)}
                            />
                        </p>
                    </div>
                </div>


                <div className="pb-content-list-kat-faq">
                    <div className="title-faq">
                        <h5>FAQ</h5>
                        {/* <Link to={{ pathname: "/pusat-bantuan/faq" }}>Lihat Semua</Link> */}
                    </div>
                    <div className="kat-faq-container">
                        {
                            is_progress === true ?
                            <div style={{ height: '25vw' }} className="detail-content-load">
                                <LoadProgress />
                            </div>
                            :
                            katFAQ.length === 0 ?
                                <div className="detail-content-load">
                                    <ListEmpty message_error={error_message} />
                                </div>
                                :
                                <GridList
                                    items={katFAQ}
                                    getGridGap={getGridGap}
                                    getColumnCount={getColumnCount}
                                    getWindowMargin={getWindowMargin}
                                    getItemData={getItemData}
                                    renderItem={(item) => {
                                        return (
                                            <div className="card-kat-faq" onClick={()=>faqDetail(item)}>
                                                <div className="container-kat-img">
                                                    <img src={removeDot(item.file)} className="kat-img" />
                                                </div>
                                                <div className="container-kat-title">
                                                    <div className="kat-title">{item.ur}</div>
                                                </div>
                                            </div>
                                        )
                                    }}
                                />
                        }
                    </div>
                    {/* {
                        faq.length > 0 ?
                            (
                                <div className="row m-0">
                                    {faq.slice(0, 5).map((item, index) =>
                                        <div className="col-md" key={index}>
                                            <div className="card">
                                                <div className="titles">
                                                    {parseHtml(item.header)}
                                                </div>

                                                <div className="content">
                                                    {parseHtml(item.ur)}
                                                </div>
                                                <div className="row m-0 align-items-center subs">
                                                    <div className="col p-0 sub-sub-title">
                                                        FAQ
                                                    </div>
                                                    <div className="col p-0">
                                                        <Link to={{
                                                            pathname: "/pusat-bantuan/faq/detail",
                                                            state: item
                                                        }}>
                                                            <button className="btn btn-primary rounded-pill">Lihat</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="row m-0">
                                    <div className="col">
                                        DATA PENCARIAN TIDAK DITEMUKAN
                                    </div>
                                </div>
                            )
                    } */}
                </div>
                {/* <hr /> */}

                <div className="pb-content-list-faq">
                    <div className="title-faq">
                        <h5>Panduan</h5>
                        <Link to={{ pathname: "/pusat-bantuan/panduan" }}>Lihat Semua</Link>
                    </div>

                    {
                        panduan.length > 0 ?
                            (
                                <div className="row m-0">
                                    {panduan.slice(0, 5).map((item, index) =>
                                        <div className="container-card-panduan col-md" key={index}>
                                            <div className="card-panduan">
                                                <div className="titles">
                                                    {parseHtml(item.header)}
                                                </div>
                                                <div className="content">
                                                    {parseHtml(item.ur)}
                                                </div>
                                                <div className="row m-0 align-items-center subs">
                                                    <div className="col p-0 sub-sub-title">
                                                        Panduan
                                                    </div>
                                                    <div className="col p-0">
                                                        <Link to={{
                                                            pathname: "/pusat-bantuan/panduan/detail",
                                                            // pathname: `/pusat-bantuan/panduan/detail/${index+1}`,
                                                            state: item
                                                        }}>
                                                            <button className="btn btn-primary rounded-pill">Lihat</button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="row m-0">
                                    <div className="col">
                                        DATA PENCARIAN TIDAK DITEMUKAN
                                    </div>
                                </div>
                            )
                    }
                </div>
                <hr />

                <div className="pb-content-list-video">
                    <div className="title-faq">
                        <h5>Video Tutorial</h5>
                        <Link to={{ pathname: "/pusat-bantuan/tutorial" }}>Lihat Semua</Link>
                    </div>
                    {
                        video.length > 0 ?
                            <div className="col-md-12 p-0">
                                <div className="row m-0">
                                    {video.slice(0, 4).map((item, index) =>
                                        <div className="col-md-3" key={index}>
                                            <div className="card">
                                                <div className="video">
                                                    <ReactPlayer url={item.url} width="100%" height="200px" controls={true} />
                                                </div>
                                                <div className="title-data">
                                                    {parseHtml(item.header)}
                                                </div>
                                                <div className="titles align-items-center">
                                                    <p>
                                                        <CalendarIcon className="mr-2" />
                                                        {parseHtml(item.ur)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            : (
                                <div className="row m-0">
                                    <div className="col">
                                        DATA PENCARIAN TIDAK DITEMUKAN
                                    </div>
                                </div>
                            )
                    }
                </div>

            </div>
        </>
    )
}

export default PusatBantuan;
