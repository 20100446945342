import './index.scss';
import axios from 'axios';
import * as Api from '../../../constants/api';
import React, { useState, useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

const ProsedurPengajuanSengketaInformasi = () => {

    const [dataAjuSengketaInformasi, setDataAjuSengketaInformasi] = useState([])

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
         _get_data_prosedur_pengajuan_sengketa_informasi();
    }, []);

    const callImage = (image_url) => {
        if (image_url !== null) {
            const full_image_url = Api.IP_KONG_LNSW + image_url.slice(1);
            return full_image_url;
        }
    }

    const callFile = (file_url) => {
        if (file_url !== null) {
            const full_file_url = Api.IP_KONG_LNSW + file_url.slice(1);
            return full_file_url;
        }
    }

    const _get_data_prosedur_pengajuan_sengketa_informasi = () => {
        const url = Api.PROSEDUR_PENGAJUAN_SENGKETA_INFORMASI + '?lang=ID';
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                const data = response.data.data
                setDataAjuSengketaInformasi(data)
            })
            .catch(error => {
                console.log('error', error);
            });
    }

    return (
        <>             
        {dataAjuSengketaInformasi.map((item, index)=> 
   
                <Container className="my-5" key={index} >
                    <Row className="judul-aju-sengketa-info justify-content-center">
                            <span className="sm-content">
                                <p className="text-center sm-title">
                                    {item.header ? item.header : "Prosedur Pengajuan Keberatan LNSW"}
                                </p>
                            </span>     
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-10 justify-content-center">
                            <Row className="text-center">
                                <Col className="mt-3">
                                    <Image src={item.nama_img ? callImage(item.nama_img) : "http://placehold.it/900x1500"} thumbnail />           
                                </Col>
                            </Row>
                            <Row className="text-center">
                                <Col className="mt-3">
                                    <h5 className="float-left font-weight-bold">
                                        {item.url_image}
                                    </h5>   
                                </Col>
                            </Row>
                            <Row >
                                <Col className="float-left col-lg-12 mb-4">
                                    <h5 className="text-secondary font-weight-bold">
                                        {item.ur ? item.ur : ""}
                                    </h5>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                {item.file_doc &&
                                    <a className="float-left text-primary col-lg-8 m-0 p-0" href={callFile(item.file_doc)} target="_blank" download={"Prosedur Pengajuan Sengketa Informasi.pdf"}>
                                        {item.ur_doc ? item.ur_doc : "Unduh Dokumen Prosedur Pengajuan Sengketa Informasi (pdf)"}
                                    </a>
                                }
                                </Col>
                            </Row>
                            {/* <Row >
                                <Col className="float-left col-lg-8 col-12">
                                    <p className="text-primary font-weight-bold">
                                        {item.ur_doc ? item.ur_doc : ""}
                                    </p>
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>
                </Container>
        )}
        </>
    )
}

export default ProsedurPengajuanSengketaInformasi;
