import React from 'react'
import './timeline.scss'
import { useTranslation } from 'react-i18next'
import { ICCentang, ICSilang } from '../../../assets'

const TimeLine = ({
    status,
    keterangan,
    index,
    totalRow,
    onClick,
    type,
    uraian,
}) => {
    const { t } = useTranslation()

    const Icon = () => {
        if (status !== null) {
            if (status.toLowerCase().match('reject')) {
                return <img src={ICSilang} alt="ic_stat" />
            } else {
                return <img src={ICCentang} alt="ic_stat" />
            }
        } else {
            return <img src={ICCentang} alt="ic_stat" />
        }
    }

    const Line = () => {
        // console.log(index);
        // console.log(totalRow);
        if (totalRow - 1 !== index) {
            return <div className="timeline-border" />
        } else {
            return null
        }
    }

    return (
        <div className="timeline-container">
            <div className="timeline-icon">
                <Icon />
                <Line />
            </div>
            {type === 'kiri' ? (
                <div className="timeline-content">
                    <p className="timeline-status">
                        {status !== null ? status : '-'}
                    </p>
                    <p className="timeline-keterangan">{keterangan}</p>
                    <p className="timeline-view" onClick={onClick}>
                        {t('view_more')}
                    </p>
                </div>
            ) : (
                <div className="timeline-content">
                    <p className="timeline-status">{status}</p>
                    <p className="timeline-keterangan">{uraian}</p>
                    <p className="timeline-keterangan-blue">{keterangan}</p>
                </div>
            )}
        </div>
    )
}

export default TimeLine
