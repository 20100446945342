import React, { useEffect, useState } from 'react';
import './horizontalHistory.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import parse from 'html-react-parser';
import * as Api from '../../../constants/api';
import { SejarahKanan, SejarahKiri } from '../../../assets';
import { Gap } from '../../atoms';

const CallImage = (image_url) => {
    var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    // console.log('url_image', url_image);
    return url_image;
}

const parseHtml = (html) => {
    // console.log('html', html)
    var text_html = '';
    if (html !== undefined) {
      text_html = parse(html);
    }
    // var text_html = parse(html.toString());
    return text_html;
}

const CustomDot = ({ onMove, index, onClick, active, data }) => {
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
    //   <li
    //     className={active ? "active-caro" : "inactive-caro"}
    //     onClick={() => onClick()}
    //   >
    //   </li>
      <div
        className={active ? "timeline-text-selected" : "timeline-text"}
        onClick={() => onClick()}
        >
            {data[index].tahun}
        </div>
    );
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
      <div className="timeline-dot-container">
        <img src={SejarahKiri} className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} />
        <Gap width={20} />
        <img src={SejarahKanan} onClick={() => next()} />
        {/* <div onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </div> */}
      </div>
    );
  };

const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 1,
      partialVisibilityGutter: 1,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 1
    }
  };

const HorizontalHistory = ({data}) => {
    const [listData, setListData] = useState([]);

    // did mount
    useEffect(() => {
        // console.log('content data', data)
        dataPartial(data);
    }, []);

    const dataPartial = (item) => {
        let content_partial = [];
        let content = [];

        for (let i = 0; i < item.length; i++) {
            content_partial.push(item[i]);
            let data = {
                data: content_partial
            }
            content_partial = [];
            content.push(data);
        }
        // console.log('content', content);
        setListData(content);
    }

    const TimeLine = listData.map((item_data) => {
        return (
            <>
            {item_data.data.map((item) =>
                <div className="timeline-content">
                    <img className="timeline-image"
                        src={CallImage(item.nama_img)}
                    />
                    <div className="timeline-content-text">
                        <div className="timeline-year">{item.tahun}</div>
                        <div className="timeline-title">{item.header}</div>
                        <div className="timeline-desc">{parseHtml(item.konten)}</div>
                    </div>
                </div>
            )}
            </>
        )
    })

    return (
        <div className="horizontal-history-component">
            {/* <div className="timeline-container">
            {data.map((item) =>
                <div className="timeline-text">{item.tahun}</div>
                // <div className="timeline-text-selected">2007</div>
            )}
            </div> */}
            <div className="timeline-carousel-container">
            <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlay={false}
                autoPlaySpeed={5000}
                transitionDuration={1000}
                customTransition="transform 1000ms ease-in-out"
                centerMode={false}
                customDot={<CustomDot data={data} />}
                customButtonGroup={<ButtonGroup />}
                dotListClass="timeline-carousel-container"
                draggable
                focusOnSelect={false}
                infinite
                containerClass="timeline-carousel-container"
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={true}
                renderDotsOutside={true}
                responsive={responsive}
                showDots={true}
                sliderClass=""
                slidesToSlide={1}
                swipeable>
                {TimeLine}
            </Carousel>
            </div>
            {/* <div className="timeline-dot-container">
                <div className="timeline-dot">{'<'}</div>
                <div className="timeline-dot">{'>'}</div>
            </div> */}
        </div>
    )
}

export default HorizontalHistory
