import React from 'react';
import { Button } from 'reactstrap';
import { IMPNF } from '../../assets';
import './PageNotFound.scss';
import { useTranslation } from 'react-i18next';

const PageNotFound = () => {
    const { t } = useTranslation();

    const goHome = () => {
        window.open("/", "_self")
    }

    return (
        <div className="page-not-found-container">
            <img className="page-not-found-img" src={IMPNF} alt={"page not found"} />
            <div className="page-not-found-content">
                <div className="page-not-found-content-title">
                    <p className="page-not-found-title">Oops! You’re lost.</p>
                    <p className="page-not-found-subtitle">The page you are looking was not found</p>
                </div>
                <Button className="page-not-found-content-btn" onClick={() => goHome()}>{t('go_home')}</Button>
            </div>
        </div>
    )
}

export default PageNotFound;
