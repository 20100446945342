import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import './sub_faq.scss';
import * as Api from '../../../../constants/api';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { InputSearch } from '../../../../components/atoms';
import { ListEmpty, LoadProgress, SubKatCollapse } from '../../../../components';
import { ICBlueCollapseDown, ICBlueCollapseUp } from '../../../../assets';
import {Collapse} from 'react-collapse';

const SubFaq = () => {
    const location = useLocation();
    const match = useRouteMatch();
    const id_kategori = useRef('');
    const history = useHistory();
    const [subKatFAQ, setSubKatFAQ] = useState([]);
    const [katQuestion, setKatQuestion] = useState([]);
    const { t } = useTranslation();

    const [is_progress, setisProgress] = useState(false);
    const [error_message, setErrorMessage] = useState('');
    const [is_progressQ, setisProgressQ] = useState(false);
    const [error_messageQ, setErrorMessageQ] = useState('');
    const [firstQ, setFirstQ] = useState(0);

    // const [searchState, setSearchState] = useState('');
    const searchState = useRef('');

    // did mount
    useEffect(() => {
        // console.log('match', match);
        // console.log('location', location);
        // console.log(location.state);
        if (location.state === undefined) {
            // console.log('match', match);
            // console.log('location', location);
            // console.log(location.state);
            // history.push('404');
            // setSubKatFAQ([]);
            id_kategori.current = match.params.id_kategori;
            console.log(match.params.id_kategori)
        } else {
            // id_kategori.current = location.state.kd_kategori;
            id_kategori.current = location.state.id_kategori;
            // getSubFaq(id_kategori.current);
            // getSubFaqAndQuestion(id_kategori.current);
        }
        console.log('id_kategori', id_kategori.current); 
        // getSubFaq(id_kategori.current);
        getSubFaqAndQuestion(id_kategori.current);
    }, []);

    const getSubFaqAndQuestion = (item) => {
        setisProgress(true);
        const url = Api.PUSAT_BANTUAN_KAT_FAQ_QUESTION + item + '?lang=' + localStorage.getItem('language').toUpperCase() + '&keyword=' + searchState.current;
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                setisProgress(false);
                if (response.data.code === '01') {
                    // console.log('subKatFaq', response.data.data);
                    setSubKatFAQ(response.data.data);
                } else {
                    setErrorMessage(t('empty_list'));
                    setSubKatFAQ([]);
                }
            })
            .catch(error => {
                setisProgress(false);
                setErrorMessage(t('error_message'));
                setSubKatFAQ([]);
            });
    }

    // const getSubFaq = (item) => {
    //     setisProgress(true);
    //     const url = Api.PUSAT_BANTUAN_KAT_FAQ + '/' + item + '?lang=' + localStorage.getItem('language').toUpperCase();
    //     axios({
    //         method: 'get',
    //         url: url,
    //         headers: { 'Authorization': Api.BASIC_AUTH },
    //         timeout: 1000 * 10, // Wait for 10 seconds
    //     })
    //         .then(response => {
    //             setisProgress(false);
    //             if (response.data.code === '01') {
    //                 // console.log('subKatFaq', response.data.data);
    //                 setSubKatFAQ(response.data.data);
    //             } else {
    //                 setErrorMessage(t('empty_list'));
    //                 setSubKatFAQ([]);
    //             }
    //         })
    //         .catch(error => {
    //             setisProgress(false);
    //             setErrorMessage(t('error_message'));
    //             setSubKatFAQ([]);
    //         });
    // }

    // const getSubFaqQuestion = (item) => {
    //     setisProgressQ(true);
    //     setFirstQ(1);
    //     const url = Api.KAT_FAQ_QUESTION + item + '&lang=' + localStorage.getItem('language').toUpperCase();
    //     axios({
    //         method: 'get',
    //         url: url,
    //         headers: { 'Authorization': Api.BASIC_AUTH },
    //         timeout: 1000 * 10, // Wait for 10 seconds
    //     })
    //         .then(response => {
    //             setisProgressQ(false);
    //             if (response.data.code === '01') {
    //                 // console.log('KatQuestion', response.data.data);
    //                 setKatQuestion(response.data.data);
    //             } else {
    //                 setErrorMessageQ(t('empty_list'));
    //                 setKatQuestion([]);
    //             }
    //         })
    //         .catch(error => {
    //             setisProgressQ(false);
    //             setErrorMessageQ(t('error_message'));
    //             setKatQuestion([]);
    //         });
    // }

    // Sub Collapse
    const [buka, setBuka] = useState(false);
    const klikCollapse = buka => {
        if (buka === false) {
            setBuka(true);
        } else {
            setBuka(false);
        }
    }
    const TypeColl = () => {
        return (
            subKatFAQ.map((data) =>
            <div className="sub-kat-collapse-container">
                <div className="collapse-header" onClick={() => klikCollapse(buka)} >
                    <div className="collapse-text-head">{data.ur}</div>
                    <div className="collapse-icon">
                        {
                            data.subkategori.length !== 0 ?
                            <img src={buka === true ? ICBlueCollapseUp : ICBlueCollapseDown} alt ="icon-collapse" />
                            :
                            null
                        }
                    </div>
                </div>
                <div className="collapse-body">
                    <Collapse isOpened={buka}>
                        {
                            data.subkategori.length !== 0 ?
                            data.subkategori.map((item_child) =>
                            item_child.listFaq.length !== 0 ?
                            <>
                                <div className="coll-bull">
                                    <div
                                        className="collapse-text"
                                        // onClick={() => getSubFaqQuestion(item_child.kd_subkategori_faq)}
                                        onClick={() => setKatQuestion(item_child.listFaq)}
                                        >{item_child.ur}
                                    </div>
                                </div>
                            </>
                            :
                            null
                        )
                        :
                            null
                    }
                    </Collapse>
                </div>
            </div>
            )
        )
    }


    const searchData = (e) => {
        console.log('search', e);
        if (e.length !== 0) {
            setKatQuestion('');
            searchState.current = e;
            getSubFaqAndQuestion(id_kategori.current);
        } else {
            setKatQuestion('');
            searchState.current = '';
            getSubFaqAndQuestion(id_kategori.current);
        }
    }

    return (
        <div className="sub-faq-container">
            <div className="pb-content">
                <h3 className="pb-title">
                    {t("pusat-bantuan")}
                </h3>
                <div className="row m-0 justify-content-center">
                    <p className="col-md-6">
                        {t("pusat-bantuan-sub")}
                    </p>
                </div>
                <div className="row m-0 justify-content-center">
                    <p className="col-md-6 search-bar">
                        <InputSearch placeholder={t('find_something')}
                            onChange={e => searchData(e.target.value)}
                            onClick={() => searchData(searchState.current)}
                            onKeyDown={() => searchData(searchState.current)}
                        />
                    </p>
                </div>
            </div>
            <div className="sub-faq-content">
                <div className="sub-faq-title">{t("select-sub-category")}</div>
                <div className="sub-faq-list-sub">
                    {
                        is_progress === true ?
                        <div style={{ height: '25vw' }} className="detail-content-load">
                            <LoadProgress />
                        </div>
                        :
                        subKatFAQ.length === 0 ?
                            <div className="detail-content-load">
                                <ListEmpty message_error={error_message} />
                            </div>
                            :
                            <TypeColl />
                    }
                </div>
                <div className="sub-faq-list-question">
                {
                    is_progressQ === true ?
                    <div style={{ height: '25vw' }} className="detail-content-load">
                        <LoadProgress />
                    </div>
                    :
                    firstQ === 0 && katQuestion.length === 0 ?
                        null
                        :
                        firstQ === 1 && katQuestion.length === 0 ?
                        <div className="detail-content-load">
                            <ListEmpty message_error={error_messageQ} />
                        </div>
                        :
                        katQuestion.map((item) =>
                            <SubKatCollapse data={item} />
                        )
                }
                </div>
            </div>
        </div>
    )
}

export default SubFaq;
