import RegisterBg from './image/register_bg.jpg'
import LoginBg from './image/login_bg.jpg'
import LogoKemenKeu from './image/Logo-LNSW-Putih.png'
import LogoKemenKeuBlack from './image/Logo-LNSW-Hitam.png'
import LogoOnlyKemenkeu from './image/Logo_kementerian_keuangan_republik_indonesia.png'
import LogoOnlyINSW from './image/Logo_LNSW.png'
import HomeVideoBG from './image/home_video_bg.svg'
import IntrBackground from './image/intr_banner.png'
import ProfilBackground from './image/profil_foto.png'
import Profil1 from './image/profil_1.png'
import Profil2 from './image/profil_2.png'
import Profil3 from './image/profil_3.png'
import Profil4 from './image/profil_4.png'
import Profil5 from './image/profil_5.png'
import Tugas1 from './image/Rectangle_211.png'
import Tugas2 from './image/Rectangle_212.png'
import Tugas3 from './image/Rectangle_214.png'
import Tugas4 from './image/Rectangle_213.png'
import IMKL from './image/home_kemen.png'
//Icon
import ICFb from './icon/logo_fb.svg'
import ICYt from './icon/logo_youtube.svg'
import ICIg from './icon/logo_ig.svg'
import ICTelp from './icon/logo_telp.svg'
import ICGP from './icon/logo_google_play.svg'
import ICAS from './icon/logo_app_store.svg'
import ICISO from './icon/logo_9001+27001.png'
import ICSearch from './icon/ic_search.svg'
import ICSimulasiExport from './icon/ic_simulasi_export.svg'
import ICPeraturan from './icon/ic_peraturan.svg'
import ICDownload from './icon/ic_download.svg'
import ICPDF from './icon/ic_pdf.svg'
import ICSearchGray from './icon/ic_search_gray.svg'
import ImgFooterKurs from './image/img_footer_intr.svg'
import ICAmerika from './icon/ic_amerika.svg'
import ICJepang from './icon/ic_jepang.svg'
import ICNewZeland from './icon/ic_new_zeland.svg'
import ICArrowUp from './icon/ic_arrow_up.svg'
import ICArrowDown from './icon/ic_arrow_down.svg'
import LogoBPOM from './icon/ic_logo_bpom.svg'
import LogoKarantinaIkan from './icon/ic_logo_karantina_ikan.svg'
import LogoKemenperin from './icon/ic_logo_kemenperin.svg'
import LogoKesdm from './icon/ic_logo_kesdm.svg'
import ICRadar from './icon/logo_radar_statistik.svg'
import KementrianBG from './image/KementrianBG.svg'
import IcArrow from './icon/ic_arrow_white.svg'
import IcArroeBorderless from './icon/ic_arrow_white_borderless.svg'
import IcArrowRight from './icon/ic_arrow_white_right.svg'
import ImageNotFound from './image/image_not_found_data.svg'
import IcArrowNext from './icon/ic_arrow_next.svg'
import IcArrowPrev from './icon/ic_arrow_prev.svg'
import IcDotCaro from './icon/ic_dotcarousel.svg'
import ICIntrExporImpor from './image/img_simulasi_expor_impor.svg'
import ICIntrPeraturan from './image/img_peraturan.svg'
import ICIntrAseanTrade from './image/img_asean_trade_repository.svg'
import ICIntrReferensi from './image/img_referensi.svg'
import ICDate from './icon/ic_date.svg'
import ICArrowRightIntr from './icon/ic_arrow_right.svg'
import ICCalendarGray from './icon/ic_calendar_gray.svg'
import ICPercent from './icon/ic_percent.svg'
import ICCollapseUp from './icon/ic_collapse_up.svg'
import ICCollapseDown from './icon/ic_collapse_down.svg'
import ImgImport from './image/img_import.svg'
import ImgLogistik from './image/img_logistik.svg'
import ImgEcommerce from './image/img_ecommerce.svg'
import ICEye from './icon/ic_eye.svg'
import ICTariffNomen from './image/atr/tariff_nomen.svg'
import ICMfnPt from './image/atr/mfn_prefen.svg'
import ICRulesOrigin from './image/atr/rules_origin.svg'
import ICNonTariff from './image/atr/non_tariff.svg'
import ICNationalTrade from './image/atr/national_trade.svg'
import ICProDoc from './image/atr/pro_doc.svg'
import ICAdminRulings from './image/atr/admin_rulings.svg'
import ICBestPractice from './image/atr/best_practice.svg'
import ICListAuth from './image/atr/list_auth.svg'
import ICListData from './image/list_of_data.svg'
import ICListData2 from './image/searching_img.svg'
import ICKemendag from './image/kementrian/kemendag.svg'
import ICKemenprin from './image/kementrian/kemenprin.svg'
import ICKemenhub from './image/kementrian/kemenhub.svg'
import ICKementan from './image/kementrian/kementan.svg'
import ICKemkominfo from './image/kementrian/kemkominfo.svg'
import ICKkkp from './image/kementrian/kemenklau.svg'
import ICKemenkes from './image/kementrian/kemenkes.svg'
import ICKlhk from './image/kementrian/kemen_lh.svg'
import ICEsdm from './image/kementrian/esdm.svg'
import ICKemenhan from './image/kementrian/kemenhan.svg'
import ICPolri from './image/kementrian/polri.svg'
import ICBpom from './image/kementrian/bpom.svg'
import ICBptn from './image/kementrian/bapeten.svg'
import ICBkpm from './image/kementrian/bkpm.svg'
import ICBi from './image/kementrian/bank_indonesia.svg'
import ICBurger from './icon/ic_burger.svg'
import ICArrowDownBlue from './icon/ic_arrow_down_blue.svg'
import ICArrowRightBlue from './icon/ic_arrow_right_blue.svg'
import ICCoin from './image/ic_coin.svg'
import IMVMSatu from './image/visi_misi_1.svg'
import ICRoket from './image/rocket.svg'
import IMVMDua from './image/visi_misi_2.svg'
import ICGlobe from './image/globe.svg'
import IMVMTiga from './image/visi_misi_3.svg'
import ICCalendarGrayNews from './icon/ic_calendar_gray_news.svg'
import ICCentang from './icon/ic_centang.svg'
import ICSilang from './icon/ic_silang.svg'
import ICKosong from './icon/icon_kosong_blue.svg'
import ICCloseP from './icon/ic_close_p.svg'
import IMPNF from './image/ic_page_not_found.svg'
import NewsDefault from './image/news_default.png'
import NewsDefaultPotrait from './image/news_default_potrait.png'
import ICBlueCollapseUp from './icon/Arrow-up.png'
import ICBlueCollapseDown from './icon/Arrow-down.png'
import ICPeraturanUMKM from './icon/UMKM.svg'
import SejarahKiri from './icon/SejarahKiri.svg'
import SejarahKanan from './icon/SejarahKanan.svg'
import GarudaLogo from '../assets/image/garuda.png'
import GarudaLogoUKM from '../assets/image/garuda_ukm.png'
import Penelusuran_BG from '../assets/image/penelusuran_bg.png'
import IconNLE from '../assets/image/icon_nle.png'
import IconPeta from '../assets/image/peta.png'
import IconKalender from '../assets/icon/calendar.png'
import IconPesawatKertas from '../assets/icon/paper-plane.png'

export {
   RegisterBg,
   LoginBg,
   LogoKemenKeu,
   LogoKemenKeuBlack,
   LogoOnlyKemenkeu,
   LogoOnlyINSW,
   ICFb,
   ICYt,
   ICIg,
   ICTelp,
   ICGP,
   ICAS,
   ICISO,
   HomeVideoBG,
   IntrBackground,
   ProfilBackground,
   Profil1,
   Profil2,
   Profil3,
   Profil4,
   Profil5,
   Tugas1,
   Tugas2,
   Tugas3,
   Tugas4,
   ICSearch,
   ICSimulasiExport,
   ICPeraturan,
   ICDownload,
   ICPDF,
   ICSearchGray,
   ImgFooterKurs,
   ICAmerika,
   ICJepang,
   ICNewZeland,
   ICArrowUp,
   ICArrowDown,
   LogoBPOM,
   LogoKarantinaIkan,
   LogoKemenperin,
   LogoKesdm,
   ICRadar,
   IMKL,
   KementrianBG,
   IcArrow,
   IcArroeBorderless,
   IcArrowRight,
   ImageNotFound,
   IcArrowPrev,
   IcArrowNext,
   IcDotCaro,
   ICIntrExporImpor,
   ICIntrPeraturan,
   ICIntrAseanTrade,
   ICIntrReferensi,
   ICDate,
   ICArrowRightIntr,
   ICCalendarGray,
   ICPercent,
   ICCollapseUp,
   ICCollapseDown,
   ImgImport,
   ImgEcommerce,
   ImgLogistik,
   ICEye,
   ICTariffNomen,
   ICMfnPt,
   ICRulesOrigin,
   ICNonTariff,
   ICNationalTrade,
   ICProDoc,
   ICAdminRulings,
   ICBestPractice,
   ICListAuth,
   ICListData,
   ICListData2,
   ICKemendag,
   ICKemenprin,
   ICKemenhub,
   ICKementan,
   ICKemkominfo,
   ICKkkp,
   ICKemenkes,
   ICKlhk,
   ICEsdm,
   ICKemenhan,
   ICPolri,
   ICBpom,
   ICBptn,
   ICBkpm,
   ICBi,
   ICBurger,
   ICArrowDownBlue,
   ICArrowRightBlue,
   ICCoin,
   IMVMSatu,
   ICRoket,
   IMVMDua,
   ICGlobe,
   IMVMTiga,
   ICCalendarGrayNews,
   ICCentang,
   ICSilang,
   ICKosong,
   ICCloseP,
   IMPNF,
   NewsDefault,
   NewsDefaultPotrait,
   ICBlueCollapseUp,
   ICBlueCollapseDown,
   ICPeraturanUMKM,
   SejarahKiri,
   SejarahKanan,
   GarudaLogo,
   GarudaLogoUKM,
   Penelusuran_BG,
   IconNLE,
   IconPeta,
   IconKalender,
   IconPesawatKertas,
}
