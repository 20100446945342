import React, { useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import { ICCloseP } from '../../../assets';
import './modal.scss';
import axios from 'axios';
import * as Api from '../../../constants/api';
import CardPengumuman from '../CardPengumuman';
import { useTranslation } from 'react-i18next';
import LoadProgress from '../LoadProgress';
import ListEmpty from '../ListEmpty';

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 1,
    partialVisibilityGutter: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    items: 1
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464
    },
    items: 1
  }
};

const CustomDot = ({ onMove, index, onClick, active }) => {
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <li
      className={active ? "active-caro" : "inactive-caro"}
      onClick={() => onClick()}
    >
    </li>
  );
};

const ModalPengumuman = ({ showModal, setShowModal }) => {
  const [dataPengumuman, setDataPengumuman] = useState([]);
  const [list_data_partial, setDataPartial] = useState([]);
  const [is_progress, setisProgress] = useState(false);
  const [error_message, setErrorMessage] = useState('');

  const { t } = useTranslation();
  const width = window.innerWidth;

  // did mount
  useEffect(() => {
    if (localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'id');
    }
    _get_data();
  }, []);

  const _get_data = () => {
    setisProgress(true);

    const url = Api.IP_LNSW_CMS + 'pengumuman?lang=' + localStorage.getItem('language').toUpperCase();
    // console.log('url visit');
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        setisProgress(false);
        // console.log('response pengumuman');
        // console.log(response.data.data);
        // console.log('response.data.code');
        // console.log(response.data.code);
        if (response.data.code === '01') {
          // console.log('after if');
          // console.log(response.data.data);
          let dataPengumuman = response.data.data;
          // setDataPengumuman(dataPengumuman);
          dataPartial(dataPengumuman);
        } else {
          setDataPartial([]);
          setErrorMessage(t('empty_list'));
        }
      })
      .catch(error => {
        setisProgress(false);
        setDataPartial([]);
        setErrorMessage(t('empty_list'));
      });
  }

  const dataPartial = (item) => {
    let content_partial = [];
    let content = [];
    var count = 1;
    var jmlData = 1;
    if (width < 600) {
      jmlData = 1;
    }

    for (let i = 0; i < item.length; i++) {
      // console.log(count);
      content_partial.push(item[i]);
      // console.log(item[i].title);
      if (count === jmlData) {
        let data = {
          data: content_partial
        }
        content_partial = [];
        content.push(data);
        count = 1;
      } else {
        // console.log(item.length - i);
        if (item.length - i === 1) {
          let data = {
            data: content_partial
          }
          content_partial = [];
          content.push(data);
          count = count + 1
        } else {
          count = count + 1
        }
      }
    }
    // console.log('content', content);

    setDataPartial(content);
  }

  const removeDot = item => {
    if (item !== null) {
      var imageUrl = Api.IP_KONG_LNSW + item.slice(1);
      // console.log (imageUrl);
      return imageUrl;
    }
    return null;
  }

  const slides = list_data_partial.map((item) => {
    return (
      <div>
        {item.data.map((item_data) =>
          <CardPengumuman
            judul={item_data.header}
            gambar={removeDot(item_data.nama_img)}
            link={item_data.url}
            desc={item_data.ur}
          />
        )}
      </div>
    );
  });
  return (
    <>
      {
        showModal ?
          <div className="modal-pengumuman-container">
            <div className="modal-pengumuman-wrapper" showModal={showModal}>
              <div className="modal-pengumuman-header">
                <p className="text-pengumuman">{t('announcement')}</p>
                <img src={ICCloseP} className="close-pengumuman" alt="close" onClick={() => setShowModal(prev => !prev)} />
              </div>
              <div className="modal-pengumuman-body">
                {
                  is_progress === true ?
                    <div style={{ height: '25vw' }} className="detail-content-load">
                      <LoadProgress />
                    </div>
                    :
                    list_data_partial.length === 0 ?
                      <div className="detail-content-load">
                        <ListEmpty message_error={error_message} />
                      </div>
                      :
                      <>
                        <Carousel
                          additionalTransfrom={0}
                          arrows={false}
                          autoPlay
                          autoPlaySpeed={6000}
                          centerMode={false}
                          customDot={<CustomDot />}
                          dotListClass=""
                          draggable
                          focusOnSelect={false}
                          infinite
                          containerClass={{ width: '100%' }}
                          keyBoardControl
                          minimumTouchDrag={80}
                          renderButtonGroupOutside={false}
                          renderDotsOutside={false}
                          responsive={responsive}
                          showDots
                          sliderClass=""
                          slidesToSlide={1}
                          swipeable>
                          {slides}
                        </Carousel>
                      </>
                }
              </div>
            </div>
          </div>
          : null
      }
    </>
  )
}

export default ModalPengumuman
