import './index.scss';
import axios from 'axios';
import * as Api from '../../../constants/api';
import React, { useState, useEffect } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ProsedurPengajuanKeberatan = () => {

    const [dataAjuKeberatan, setDataAjuKeberatan] = useState([])
    const [dataForm, setDataForm] = useState([])
    const { t } = useTranslation();

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        const get = _get_data_prosedur_pengajuan_keberatan();
        _get_data_form();
    }, []);

    const callImage = (image_url) => {
        if (image_url !== null) {
            const full_image_url = Api.IP_KONG_LNSW + image_url.slice(1);
            console.log('full_image_url', full_image_url);
            return full_image_url;
        }
    }

    const callFile = (file_url) => {
        if (file_url !== null) {
            const full_file_url = Api.IP_KONG_LNSW + file_url.slice(1);
            return full_file_url;
        }
    }

    const _get_data_form = () => {
        const url = Api.FORM_PROSEDUR + '?lang=' + localStorage.getItem('language').toUpperCase();
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    // console.log(response.data.data);
                    setDataForm(response.data.data);
                } else {
                    setDataForm([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setDataForm([]);
            });
    }

    const _get_data_prosedur_pengajuan_keberatan = () => {
        const url = Api.PROSEDUR_PENGAJUAN_KEBERATAN + '?lang=ID';
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                const data = response.data.data
                setDataAjuKeberatan(data)
                console.log('data aju', data[0]);
            })
            .catch(error => {
                console.log('error');
                console.log(error);
            });
    }

    const klik_data = item => {
        var url_image = Api.IP_KONG_LNSW + item.slice(1);
        const win = window.open(url_image, '_blank');
        if (win != null) {
            win.focus();
        }
    }
    
    return (
        <>
            {dataAjuKeberatan.map((item, index) =>
                <Container className="my-5" key={index} >
                    <Row className="judul-aju-sengketa-info justify-content-center">
                        <span className="sm-content">
                            <p className="text-center sm-title">
                                {item.header ? item.header : "Prosedur Pengajuan Keberatan LNSW"}
                            </p>
                        </span>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-10 justify-content-center">
                            <Row className="text-center">
                                <Col className="mt-3">
                                    <Image src={item.nama_img ? callImage(item.nama_img) : "http://placehold.it/900x1500"} thumbnail />
                                </Col>
                            </Row>
                            <Row className="text-center">
                                <Col className="mt-3">
                                    <h5 className="float-left font-weight-bold">
                                        {item.url_image}
                                    </h5>
                                </Col>
                            </Row>
                            <Row >
                                <Col className="float-left col-lg-12 mb-2">
                                    <h5 className="text-secondary font-weight-bold">
                                        {item.ur ? item.ur : ""}
                                    </h5>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                    {item.file_doc &&
                                        <a className="float-left text-primary col-lg-8 m-0 p-0" href={callFile(item.file_doc)} target="_blank" download={"Prosedur Pengajuan Sengketa Informasi.pdf"}>
                                            {item.ur_doc ? item.ur_doc : "Unduh Dokumen Prosedur Pengajuan Sengketa Informasi (pdf)"}
                                        </a>
                                    }
                                </Col>
                            </Row>
                            {/* <Row >
                                <Col className="float-left">
                                    <div className='d-flex align-items-center'>
                                    <p className="text-primary font-weight-bold mr-auto m-0">
                                        {item.ur_doc ? item.ur_doc : ""}
                                    </p>    
                                    {dataForm.map((item, index) => item.header === "Formulir Permintaan Informasi Publik" &&
                                        <div className="d-flex align-items-center ">
                                            <p className='m-0 mr-3'>
                                                {item.header}
                                            </p>
                                            <button
                                                className="btn btn-primary rounded-pill"
                                                onClick={() => klik_data(item.file)}
                                            >{t('download')}
                                            </button>
                                        </div>
                                        // <div className="col-md-6 p-0 part-2">
                                        //     <p>
                                        //         Formulir Keberatan Informasi Publik
                                        //         <button className="btn btn-primary rounded-pill">Unduh</button>
                                        //     </p>
                                        // </div>
                                    )}
                                    </div>
                                </Col>
                            </Row> */}
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    )
}

export default ProsedurPengajuanKeberatan;
