import React from 'react';
import './cardtugas.scss';

const CardTugas = ({ gambar, judul, deskripsi }) => {
    return (
        <div className="card-tugas">
            <div className="card row">
                <img src={gambar} alt={judul} className="gambar-tugas" />
                <div className="card-text">
                    <p className="title">{judul}</p>
                </div>
            </div>
        </div>
    )
}

export default CardTugas;
