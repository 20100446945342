import React, { useState, useEffect, useHistory, useRef } from 'react';
import './detail_perijinan.scss';
import { useLocation } from 'react-router-dom';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { InputCapsule, InputSearch } from '../../../../components/atoms';
import axios from 'axios';
import * as Api from '../../../../constants/api';
import {
	CardPerizinan,
	ListEmpty,
	ListFirst,
	LoadProgress,
	TimeLine,
} from '../../../../components';
import moment from 'moment';
import { Button } from 'reactstrap';

const DetailPerijinan = () => {
	const location = useLocation();
	// const data_pibpeb = location.state.data;
	var route_data = '';
	if (location.state === undefined) {
		route_data = '';
	} else {
		route_data = location.state.data;
	}
	const [data_perijinan, setDataPerijinanAsal] = useState(route_data); // No Asal

	const { t } = useTranslation();
	const [perijinan, SetPerijinan] = useState(''); // No perijinan page Detail
	const [is_progress, setisProgress] = useState(false);
	const [is_progress2, setisProgress2] = useState(false);
	const [dataPerijinan, SetDataPerijinan] = useState([]); // Data perijinan page Detail
	const [error_message, setErrorMessage] = useState('');
	const [dataKiri, setDataKiri] = useState('');

	const [no_npwp, SetNpwp] = useState('');
	const data_npwp = useRef('');

	//Select
	const [selected_ref, setSelectedRef] = useState({
		label: 'IMPOR',
		value: 'impor',
	});
	const [selected_ref_label, setSelectedRefLabel] = useState('IMPOR');
	const [list_select_ref, setListSelectRef] = useState([
		{
			label: 'IMPOR',
			value: 'impor',
		},
		{
			label: 'EKSPOR',
			value: 'ekspor',
		},
	]);
	const [place_holder_ref, setPlaceHolderRef] = useState(
		'penelusuran-dropdown-remove-placeholder'
	);

	//style dropdown
	const StyledItem = styled.div`
		color: #555;
		padding: 10px;
		border-radius: 3px;
		margin: 3px;
		cursor: pointer;
		> div {
			display: flex;
			align-items: center;
		}
		:hover {
			background: #f2f2f2;
		}
	`;

	//function
	const searchPibPeb = (data) => {
		// console.log(data);
		// console.log('jenis_detail');
		// console.log(data.jenis);
		// console.log('no npwp');
		// console.log(data.no_npwp);
		// console.log('pibpeb_detail');
		// console.log(data.nomor);

		setisProgress(true);
		setisProgress2(true);

		let jenisData = data.jenis;
		let nomorNpwp = data.no_npwp;
		let nomorData = filter_input(data.nomor);
		let url = '';

		// let url = Api.TRACKING_DETAIL + 'perijinan?jenis_perijinan=' + jenisData + '&npwp=' + nomorNpwp + '&no_ijin=' + nomorData; //mobile api

		// if (nomorData === '') {
		//     url = Api.IP_LNSW_CMS + 'perijinanNpwp?jenis_perijinan=' + jenisData + '&npwp=' + nomorNpwp; //web api new
		// } else {
		//     url = Api.IP_LNSW_CMS + 'perijinan?jenis_perijinan=' + jenisData + '&no_ijin=' + nomorData + '&npwp=' + nomorNpwp; //web api
		// }

		url =
			Api.IP_LNSW_CMS +
			'perijinan?jenis_perijinan=' +
			jenisData +
			'&no_ijin=' +
			nomorData +
			'&npwp=' +
			nomorNpwp; //req baru harus ada npwp

		// console.log(url);

		axios({
			method: 'get',
			url: url,
			headers: { Authorization: Api.BASIC_AUTH },
			timeout: 1000 * 10, // Wait for 10 seconds
		})
			.then((response) => {
				setisProgress(false);
				setisProgress2(false);
				if (response.data.code === '01') {
					if (response.data.data.length === 0) {
						SetDataPerijinan([]);
						setErrorMessage(t('empty_list'));
					} else {
						// console.log('length !== 0');
						// console.log(response.data.data);
						let data_list = response.data.data;
						for (let i = 0; i < data_list.length; i++) {
							data_list[i].judul = jenisData;
						}
						SetDataPerijinan(data_list);
					}
				} else {
					// console.log('code 02');
					// console.log(response.data.code);
					SetDataPerijinan([]);
					setErrorMessage(t('empty_list'));
				}
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
				setisProgress(false);
				SetDataPerijinan([]);
				setErrorMessage(t('error_message'));
			});
	};

	const filter_input = (input_text) => {
		let filter_text = input_text.replace(/\'|\"|\--/g, '');
		return filter_text;
	};

	const RegexNPWP = (value) => {
		// console.log(value);
		var regex_npwp = value.replace(/[^0-9,]/g, '');
		data_npwp.current = regex_npwp;
		if (regex_npwp.length > 15) {
			regex_npwp = regex_npwp.replace(
				/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})(\d{3})/,
				'$1.$2.$3.$4-$5.$6'
			);
		} else if (regex_npwp.length > 12) {
			regex_npwp = regex_npwp.replace(
				/(\d{2})(\d{3})(\d{3})(\d{1})(\d{3})/,
				'$1.$2.$3.$4-$5.'
			);
		} else if (regex_npwp.length > 9) {
			regex_npwp = regex_npwp.replace(
				/(\d{2})(\d{3})(\d{3})(\d{1})/,
				'$1.$2.$3.$4-'
			);
		} else if (regex_npwp.length > 8) {
			regex_npwp = regex_npwp.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2.$3.');
		} else if (regex_npwp.length > 5) {
			regex_npwp = regex_npwp.replace(/(\d{2})(\d{3})/, '$1.$2.');
		} else if (regex_npwp.length > 2) {
			regex_npwp = regex_npwp.replace(/(\d{2})/, '$1.');
		}
		// console.log(regex_npwp);
		SetNpwp(regex_npwp);
	};

	const see_detail = (item, index) => {
		setisProgress2(true);
		// console.log('item');
		// console.log(item);
		// console.log('index');
		// console.log(index);
		setDataKiri(item);
	};

	//did mount
	useEffect(() => {
		// console.log('data_pibpeb_detail_didmount');
		// console.log(data_pibpeb);

		searchPibPeb(data_perijinan);
	}, []);

	return (
		<div className='detail-perijinan-container'>
			<div className='penelusuran-content-title'>
				<div className='penelusuran-content-sub-title'>
					<div className='penelusuran-text-title'>{t('track-perijinan')}</div>
					<div className='penelusuran-input-search row m-0 mt-5 justify-content-center'>
						<div className='col-md-2 mb-2'>
							<Select
								multi={false}
								searchable={false}
								options={list_select_ref}
								values={[]}
								style={{
									borderColor: '#808080',
									background: '#FFFFFF',
									borderRadius: '30px',
								}}
								className={place_holder_ref}
								onChange={(value) => {
									// console.log(value);
									setSelectedRef(value[0]);
									setSelectedRefLabel(value[0].label);
									setPlaceHolderRef('penelusuran-dropdown-remove-placeholder');
								}}
								contentRenderer={({ props, state }) => (
									<div>
										{selected_ref_label === t('choose_cif_fob') ? (
											<div style={{ cursor: 'pointer', display: 'flex' }}>
												<div className='penelusuran-dropdown-placeholder'>
													{selected_ref_label}
												</div>
											</div>
										) : (
											<div style={{ cursor: 'pointer', display: 'flex' }}>
												<div className='penelusuran-input-dropdown'>
													{selected_ref_label}
												</div>
											</div>
										)}
									</div>
								)}
								itemRenderer={({ item, methods }) => (
									<StyledItem>
										<div onClick={() => methods.addItem(item)}>
											<div className='penelusuran-dropdown-item'>
												{item.label}
											</div>
										</div>
									</StyledItem>
								)}
							/>
						</div>
						<div className='col-md-3 mb-2'>
							<InputCapsule
								placeholder={t('enter_npwp')}
								// onChange={e => SetNpwp(e.target.value)}
								value={no_npwp}
								onChange={(e) => RegexNPWP(e.target.value)}
							/>
						</div>
						<div className='col-md-3 mb-2'>
							<InputCapsule
								placeholder={t('look_the_permission')}
								onChange={(e) => SetPerijinan(e.target.value)}
							/>
						</div>
						<div className='col-md-1 mb-2'>
							<Button
								className='button-track'
								onClick={() =>
									searchPibPeb({
										jenis: selected_ref.value,
										no_npwp: data_npwp.current,
										nomor: perijinan,
									})
								}
							>
								{t('browse')}
							</Button>
						</div>
						{/* <InputSearch
                            placeholder={t('look_the_permission')}
                            onChange={e => SetPerijinan(e.target.value)}
                            onClick={() => searchPibPeb({ 
                                jenis : selected_ref.value,
                                no_npwp : no_npwp,
                                nomor : perijinan
                             })} /> */}
					</div>
				</div>
			</div>
			<div className='detail-perijinan-content'>
				{is_progress === true ? (
					<div style={{ height: '25vw' }} className='detail-content-load'>
						<LoadProgress />
					</div>
				) : dataPerijinan.length === 0 ? (
					<div className='detail-content-load'>
						<ListEmpty message_error={error_message} />
					</div>
				) : (
					<>
						<div className='detail-kiri'>
							{dataPerijinan.map((item, index) => (
								<CardPerizinan
									jenis='perizinan'
									judul={item.judul}
									no_izin={item.no_ijin}
									onClick={() => see_detail(item, index)}
								/>
							))}
						</div>
						<div className='detail-kanan'>
							<div className='detail-atas'>
								<div className='detail-title'>
									<p className='detail-title-text'>{t('detail-perijinan')}</p>
								</div>
							</div>
							<div className='detail-bawah'>
								{is_progress2 === false ? (
									<ListFirst
										message_error={t('atr_me_title')}
										message_error2={t('atr_me_subtitle')}
									/>
								) : (
									<>
										<div className='detail-container'>
											<p className='detail-judul'>{t('no_izin')}</p>
											<p className='detail-hasil'>
												{dataKiri.no_ijin === '' ? '-' : dataKiri.no_ijin}
											</p>
										</div>
										<div className='detail-container'>
											<p className='detail-judul'>{t('jenis_izin')}</p>
											<p className='detail-hasil'>
												{dataKiri.judul === '' ? '-' : dataKiri.judul}
											</p>
										</div>
										<div className='detail-container'>
											<p className='detail-judul'>{t('name_kemen')}</p>
											<p className='detail-hasil'>
												{dataKiri.nama_ga === '' ? '-' : dataKiri.nama_ga}
											</p>
										</div>
										<div className='detail-container'>
											<p className='detail-judul'>{t('tgl_izin')}</p>
											<p className='detail-hasil'>
												{dataKiri.tgl_ijin === '' ? '-' : dataKiri.tgl_ijin}
											</p>
										</div>
										<div className='detail-container'>
											<p className='detail-judul'>{t('perijinan')}</p>
											<p className='detail-hasil'>
												{dataKiri.perijinan === '' ? '-' : dataKiri.perijinan}
											</p>
										</div>
										<div className='detail-container'>
											<p className='detail-judul'>{t('npwp')}</p>
											<p className='detail-hasil'>
												{dataKiri.npwp === '' ? '-' : dataKiri.npwp}
											</p>
										</div>
										<div className='detail-container-last'>
											<p className='detail-judul'>{t('status')}</p>
											<p className='detail-hasil'>
												{dataKiri.status === '' ? '-' : dataKiri.status}
											</p>
										</div>
									</>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default DetailPerijinan;
