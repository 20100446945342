import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Api from '../../../constants/api';
import './index.scss';
import parse from 'html-react-parser';

const StatistikLayanan = () => {

    const [data, setData] = useState([]);

    useEffect(() => {
        if (localStorage.getItem('language') === null) {
            localStorage.setItem('language', 'id');
        }
        _get_data_statistik();
    }, []);

    const CallImage = (image_url) => {
        // var replace_url = image_url.substring(1);
        var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        // console.log(url_image);
        return url_image;
    }

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    const _get_data_statistik = () => {
        const url = Api.STATISTIK + '?lang=' + localStorage.getItem('language').toUpperCase();
        // console.log(url);
        axios({
            method: 'get',
            url: url,
            headers: { 'Authorization': Api.BASIC_AUTH },
            timeout: 1000 * 10, // Wait for 10 seconds
        })
            .then(response => {
                if (response.data.code === '01') {
                    setData(response.data.data);
                } else {
                    setData([]);
                }
            })
            .catch(error => {
                console.log('error');
                console.log(error);
                setData([]);
            });
    }

    return (
        <>
            <div className="statistik-layanan row m-0 justify-content-center text-center">
                <div className="col-md-7">
                    <div className="sl-content">
                        <h3 className="sl-title">
                            Statistik Layanan Informasi Publik
                        </h3>
                    </div>
                </div>

                <div className="text-left sl-content-list row m-0 justify-content-center">
                    {data.map((item, index) =>
                        <img className="profil-contact-img col-md-8 col-xs-12" src={CallImage(item.nama_img)} alt={`${index}`} />
                    )}
                </div>

            </div>
        </>
    )
}

export default StatistikLayanan;
