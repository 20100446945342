import React from 'react'
import { Button } from 'reactstrap'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import './komoditaskonten.scss'

const KomoditasKonten = ({
   hs_code,
   bagian,
   bagian_en,
   bagian_penjelasan,
   bab,
   bab_penjelasan,
   onClick,
   data,
   tampilData,
   CollapseComp,
}) => {
   const bahasa = localStorage.getItem('language')
   const options = {
      noDataText: 'Data Tidak Ditemukan',
   }

   const isExpandableRow = (row) => {
      return row._source.hs_code_format.length === 4 ||
         row._source.hs_code_format.length === 6 ||
         row._source.hs_code === null ? null : true
   }

   const expandComponent = (row) => {
      return <CollapseComp key={row.id} />
   }

   const actionFormat = (cell, row) => {
      return row._source.hs_code_format.length === 4 ||
         row._source.hs_code_format.length === 6 ||
         row._source.hs_code === null ? null : (
         <Button
            color="primary"
            className="komkon-button"
            onClick={() => tampilData(row)}
         >
            Detail
         </Button>
      )
   }

   return (
      <div className="komkon-wrapper">
         <BootstrapTable
            data={data}
            options={options}
            version="4"
            bordered={false}
            expandableRow={isExpandableRow}
            expandComponent={expandComponent}
         >
            <TableHeaderColumn
               width="20%"
               isKey
               headerAlign="center"
               dataAlign="left"
               dataField="_source"
               dataFormat={(cell, row) => row._source.hs_code ? row._source.hs_code_format : ""}
            >
               HS Code
            </TableHeaderColumn>
            <TableHeaderColumn
               width="30%"
               headerAlign="center"
               dataAlign="left"
               dataField="_source"
               tdStyle={{ whiteSpace: 'normal' }}
               dataFormat={(cell, row) => row._source.uraian_id}
            >
               Uraian Barang
               <br />
               (Bahasa)
            </TableHeaderColumn>
            <TableHeaderColumn
               width="30%"
               headerAlign="center"
               dataAlign="left"
               dataField="_source"
               tdStyle={{ whiteSpace: 'normal' }}
               dataFormat={(cell, row) => row._source.uraian_en}
            >
               Uraian Barang
               <br />
               (English)
            </TableHeaderColumn>
            <TableHeaderColumn
               width="20%"
               headerAlign="center"
               dataAlign="center"
               dataFormat={actionFormat}
            >
               Action
            </TableHeaderColumn>
         </BootstrapTable>
      </div>
   )
}

export default KomoditasKonten
