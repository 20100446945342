import React from 'react';
import './listberita.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import parse from 'html-react-parser';
import Ripples from 'react-ripples';
import { NewsDefaultPotrait } from '../../../assets';

const ListBerita = ({gambar, deskripsi, judul, header, onClick}) => {
    return (
        <div className="list-item-berita">
                {/* <div className="list-item-wrapper"> */}
                    {/* <img src={gambar} alt={deskripsi} className="gambar-berita" /> */}
                    <Ripples className="list-item-wrapper" style={{flex: 1}} onClick={onClick}>
                        {/* <LazyLoadImage
                            alt={deskripsi}
                            effect="blur"
                            className="gambar-berita"
                            src={gambar}
                        /> */}
                        <img alt={deskripsi} className="gambar-berita" src={gambar !== null ? gambar : NewsDefaultPotrait} />
                        <div className="content-title">
                            <p className="title">{judul}</p>
                            <p className="subtitle">{parse(header)}</p>
                        </div>
                    </Ripples>
                {/* </div> */}
        </div>
        
    )
}

export default ListBerita;
