import React from 'react';
import './cardberita.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Ripples from 'react-ripples';
import { NewsDefaultPotrait } from '../../../assets';

const CardBerita = ({ gambar, deskripsi, judul, onClick }) => {
	return (
		<div className='card-item-berita'>
			<Ripples onClick={onClick}>
				<div>
					{/* <img src={gambar} alt={deskripsi} className="gambar-berita" /> */}
					{/* <LazyLoadImage
                        // delayMethod='throttle'
                        // delayTime={300}
                        // threshold={100}
                        alt={deskripsi}
                        effect="blur"
                        className="card-item-berita-gambar-berita"
                        src={gambar}
                    /> */}
					<img
						src={gambar !== null ? gambar : NewsDefaultPotrait}
						alt={deskripsi}
						className='card-item-berita-gambar-berita'
					/>
					<div className='card-item-berita-content-title'>
						<p className='card-item-berita-title'>
							{judul.length > 60
								? judul.substring(0, 60) + '...'
								: judul.substring(0, 60)}
						</p>
					</div>
				</div>
			</Ripples>
		</div>
	);
};

export default CardBerita;
