import React, {  useRef, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import './non_tariff.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import DropdownAtr from '../../../../components/molecules/DropdownAtr';
import axios from 'axios';
import * as Api from '../../../../constants/api';
import { ListFirst } from '../../../../components';
import parse from 'html-react-parser';

const NonTariff = () => {
    const { t } = useTranslation();
    const [is_first_load, setisFirstLoad] = useState(true);
    const [dataAkhir, SetDataAkhir] = useState('');

    const location = useLocation();
    var route_id = '';
    if (location.state === undefined) {
        route_id = '';
    } else {
        route_id = location.state.id;
    }
    const [data_id, setDataId] = useState(route_id !== null ? 9 : '');
    const atr_id = 9 // 8 itu id Non Tariff
    const ortu_id = useRef(atr_id);

    //select dropdown
    const [count_dropdown, setCountDropdown] = useState(0);
    const [data_dropdown, SetDataDropdown] = useState([]);
    const select_data_dropdown = useRef([]);
    const count_dropdown_data = useRef(0);
    const [isProgress, setisProgress] = useState(false);
    const dataDrop = useRef();

    //did mount
    useEffect(() => {
        // window.scroll(0, 0);
        // setDataId(location.state.id);
        _push_data_dropdown();
    }, []);

    const _push_data_dropdown = () => {
        let is_data_dropdown = data_dropdown;
        count_dropdown_data.current = count_dropdown_data.current + 1;
        is_data_dropdown.push(component_dropdown(count_dropdown_data.current));
        SetDataDropdown(is_data_dropdown);
        setCountDropdown(count_dropdown_data.current);
    }

    const _remove_data_dropdown = (index_data) => {
        // console.log('check index dropdown')
        // console.log(count_dropdown_data.current);
        // console.log(index_data);
        if (index_data !== count_dropdown_data.current) {
            let is_data_dropdown = data_dropdown;
            let data_value_dropdown = select_data_dropdown.current;
            for (var i = 0; i < count_dropdown_data.current - index_data; i++) {
                is_data_dropdown.pop();
            }
            for (var a = 0; a < count_dropdown_data.current - index_data - 1; a++) {
                data_value_dropdown.pop();
            }
            // console.log(is_data_dropdown);
            count_dropdown_data.current = count_dropdown_data.current - (count_dropdown_data.current - index_data);
            select_data_dropdown.current = data_value_dropdown;
            SetDataDropdown(is_data_dropdown);
            setCountDropdown(count_dropdown_data.current);
        }
    }

    //function
    const getData = (itemData, index_data) => {
        setisProgress(true);
        SetDataAkhir('');
        setisFirstLoad(true);
        const url = Api.ATR + data_id + '&parent_id=' + itemData.id + '&title=';
        // console.log('url axios page');
        // console.log(url);
        axios({
          method: 'get',
          url: url,
          headers: { 'Authorization': Api.BASIC_AUTH },
          timeout: 1000 * 10, // Wait for 10 seconds
        })
          .then(response => {
            // console.log('axios page');
            // console.log(response.data);
            setisProgress(false);
            if (response.data.code === '01') {
                let data_atr = response.data.data;
                // console.log('data_atr');
                // console.log(data_atr);
                dataDrop.current = data_atr;
                if (dataDrop.current.length !== 0) {
                    // console.log('create data value');
                     if (count_dropdown_data.current === index_data) {
                        let data_value_dropdown = select_data_dropdown.current;
                        data_value_dropdown.push(itemData);
                        select_data_dropdown.current = data_value_dropdown;
                        _push_data_dropdown();
                    } else {
                        // console.log('else');
                        let data_value_dropdown = select_data_dropdown.current;
                        data_value_dropdown[index_data-1] = itemData;
                        select_data_dropdown.current = data_value_dropdown;
                        _remove_data_dropdown(index_data);
                        if (dataDrop.current.length !== 0) {
                            setTimeout(() => {
                                _push_data_dropdown();
                            }, 100);
                        }
                    }
                    SetDataAkhir('');
                } else {
                    // console.log('else');
                    let data_value_dropdown = select_data_dropdown.current;
                    data_value_dropdown[index_data-1] = itemData;
                    select_data_dropdown.current = data_value_dropdown;
                    _remove_data_dropdown(index_data);
                    if (dataDrop.current.length !== 0) {
                        setTimeout(() => {
                            _push_data_dropdown();
                        }, 100);
                    }
                    SetDataAkhir(itemData);
                }
            } else {
                dataDrop.current = [];
            }
          })
          .catch(error => {
            console.log('error');
            console.log(error);
            setisProgress(false);
            dataDrop.current = [];
          });
    }

    const component_dropdown = (index) => {
        return(
            <DropdownAtr
                id_ortu={data_id}
                id_anak={ortu_id.current}
                index={index} 
                onChange={(value, index_data) => {
                    // console.log("change data parent");
                    // console.log(count_dropdown_data.current);
                    // console.log(index_data)
                    // console.log(value[0].parent_id);
                    // console.log('parent_id');
                    // console.log(value[0]);
                    ortu_id.current = value[0].id;
                    // console.log('dataDrop.message');
                    // console.log(dataDrop.current);
                    getData(value[0], index_data);
                    // console.log('luar');
                    // console.log(select_data_dropdown.current);
                }}
            />
        );
    }

    const show_data_dropdown = () => {
        return(
            <div>
                {data_dropdown}
            </div>
        )
    }

    const reset_data = () => {
        setisFirstLoad(true);
        // console.log('dropdown',data_dropdown);
        component_dropdown(count_dropdown_data.current)
        ortu_id.current = atr_id;
        _remove_data_dropdown(0);
        setTimeout(() => {
            _push_data_dropdown();
        }, 100);

        SetDataAkhir('');
    }

    const tampil_data = () => {
        if (dataAkhir !== ''){
            setisFirstLoad(false);
        }
    }

    const unduh_data = item => {
        if (item !== null){
            var imageUrl = Api.IP_KONG_LNSW + item.slice(1);
            window.open(imageUrl, "_blank"); //to open new page
        }
    }

    const parseHtml = (html) => {
        // console.log('html');
        // console.log(html)
        var text_html = '';
        if (html !== undefined && html !== null) {
            text_html = parse(html);
        }
        // var text_html = parse(html.toString());

        return text_html;
    }

    return (
        <div className="non-tariff-nomen-container">
            <p className="tariff-title">Non Tariff Measures</p>
            <div className="tariff-content">
                <div className="tariff-topik">
                    <div className="topik-konten">
                        <p className="tariff-topik-title">{t('select_topic_atr')}</p>
                        <div className="tariff-topik-konten">
                            <div>
                                {show_data_dropdown()}
                            </div>
                        </div>
                    </div>
                    <div className="konten-button">
                        <Button className="button-atur" onClick={() => reset_data()}>{t('reset_atr')}</Button>
                        <Button className="button-tampil" onClick={() => tampil_data()}>{t('show_atr')}</Button>
                    </div>
                </div>
                <div className="tariff-regulasi">
                    <div className="tariff-regulasi-konten">
                    {
                        is_first_load === true ?
                        <ListFirst message_error={t('atr_me_title_atr')} message_error2={t('atr_me_subtitle')} />
                        :
                        <>
                        <div className="konten-regulasi">
                            <p className="title">Regulation</p>
                            <p className="desc">
                                {parseHtml(dataAkhir.title)}
                            </p>
                        </div>
                        <div className="konten-desc">
                            <p className="title">Description</p>
                            <p className="desc">
                                {parseHtml(dataAkhir.content)}
                            </p>
                        </div>
                        <div className="konten-button">
                        {
                            dataAkhir.file !== null ?
                            <Button className="button-unduh" onClick={() => unduh_data(dataAkhir.file)}>{t('download_atr')}</Button>
                            :
                            null
                        }
                        </div>
                        </>
                    }
                    </div>
                    <div className="tariff-regulasi-deskripsi">
                        <p className="deskripsi-title">The information contained in the website is for general information purposes only. The information is provided by LNSW and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk. Please contact the respective government agency for further detail information of the related regulation.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NonTariff
