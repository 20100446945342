/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import "./all_berita.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import parse from "html-react-parser";
import GridList from "react-gridlist";
import ReactPaginate from "react-paginate";
import { useLocation, useHistory } from "react-router-dom";
import Ripples from "react-ripples";
import Footer from "../../../KNFP/Footer";

//component
import * as Api from "../../../../constants/api";
import { LoadProgress, ListEmpty, InputSearch } from "../../../../components";
import { NewsDefaultPotrait } from "../../../../assets";
import { getLanguageStorageUpperCaseKNFP } from "../../../../constants/storage";

const AllBerita = () => {
  // variable constant
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [is_progress, setisProgress] = useState(true);
  const [list_berita, setListBerita] = useState([]);
  const [list_berita_slice, setListBeritaSlice] = useState([]);
  const [error_message, setErrorMessage] = useState("");

  const search_berita = useRef("");

  const perPage = 8;
  const [pageCount, setPageCount] = useState("");

  console.log("cek");
  //style
  let styles = {
    container: {
      flex: 1,
    },
  };

  // did mount
  useEffect(() => {
    _get_data_all_berita();
  }, []);

  //function
  // const _get_data_all_berita = () => {
  //   setisProgress(true);
  //   const url = Api.KNFP + "news/web?lang=" + getLanguageStorageUpperCaseKNFP();
  //   axios({
  //     method: "get",
  //     url: url,
  //     headers: { 'insw-key': Api.KNFP_AUTH_KEY, 'Authorization': Api.BASIC_AUTH },
  //     timeout: 1000 * 10, // Wait for 10 seconds
  //   })
  //     .then((response) => {
  //       setisProgress(false);
  //       if (response.data.code === 200) {
  //         if (response.data.data.length === 0) {
  //           setListBerita([]);
  //           setListBeritaSlice([]);
  //           setErrorMessage(t("empty_list"));
  //         } else {
  //           let list_berita_data = response.data.result.data;
  //           console.log(list_berita_data);
  //           setListBerita(list_berita_data);
  //           const slice = list_berita_data.slice(0, perPage);
  //           setPageCount(list_berita_data.length / perPage);
  //           setListBeritaSlice(slice);
  //         }
  //       } else {
  //         setListBerita([]);
  //         setListBeritaSlice([]);
  //         setErrorMessage(t("empty_list"));
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("error");
  //       console.log(error);
  //       setisProgress(false);
  //       setListBerita([]);
  //       setListBeritaSlice([]);
  //       setErrorMessage(t("error_message"));
  //     });
  // };


  const _get_data_all_berita = async () => {
    try {
      setisProgress(true);
      const response = await fetch(Api.KNFP + "news/web?&lang=" + getLanguageStorageUpperCaseKNFP(), {
        headers: {
          'insw-key': Api.KNFP_AUTH_KEY,
          'Authorization': Api.BASIC_AUTH,

        }
      })
      const data = await response.json()
      if (response.code == 200 && data.result.data.length === 0) {
        setListBerita([]);
        setListBeritaSlice([]);
        setErrorMessage(t("empty_list"));
      } else {
        setListBerita(data.result.data);
        let potong = data.result.data.slice(0, perPage);
        setPageCount(data.result.data.length / perPage)
        setListBeritaSlice(potong)
        console.log(data.result.data);
        setisProgress(false);
      }
    } catch (error) {
      console.log(error);
      setisProgress(false);
      setListBerita([]);
      setListBeritaSlice([]);
      setErrorMessage(t("error_message"));
    }
  }



  const CallImage = (image_url) => {
    var url_image = `https://api.insw.go.id/assets/upload/knfp/`
      + image_url;
    console.log(url_image, '<<<<')
    return url_image;
  };

  const _click_view_more = (id_berita_click) => {
    // history.push('/berita/all-berita/detail-berita', { id_berita :  id_berita_click});
    history.push({
      pathname: "/knfp/berita/detail",
      state: { id_berita: id_berita_click },
    });
  };

  const getGridGap = (elementWidth, windowHeight) =>
    elementWidth > 720 && windowHeight > 480 ? 10 : 5;

  const getColumnCount = (elementWidth) => Math.floor(elementWidth / 300); //pembagian column (dibagi untuk 4 column)

  const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.1);

  const getItemData = (list_peraturan, columnWidth) => {
    return {
      key: list_peraturan.id,
    };
  };
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    loadMoreData(offset, offset + perPage);
  };

  const loadMoreData = (of, op) => {
    const data = list_berita;

    const slice = data.slice(of, op);
    setPageCount(data.length / perPage);
    setListBeritaSlice(slice);
  };

  return (
    <div>
      <div className="all-berita-content">
        <div className="berita-terpopuler">
          <div className="berita-terpopuler-title">{t("knfp-all-berita")}</div>
          <div style={{ display: "flex", marginTop: "1vw" }}>
            {is_progress === true ? (
              <div style={{ height: "25vw" }} className="search-content-load">
                <LoadProgress />
              </div>
            ) : list_berita.length === 0 ? (
              <div className="search-content-load">
                <ListEmpty message_error={error_message} />
              </div>
            ) : (
              <div style={{ flex: 1 }}>
                <div style={styles.container}>
                  <GridList
                    items={list_berita_slice}
                    getGridGap={getGridGap}
                    getColumnCount={getColumnCount}
                    getWindowMargin={getWindowMargin}
                    getItemData={getItemData}
                    renderItem={(item) => {
                      return (
                        <Ripples
                          onClick={() => _click_view_more(item.id_berita)}
                        >
                          <div className="berita-content-item-grid">
                            <LazyLoadImage
                              effect="blur"
                              className="berita-content-item-grid-image"
                              src={
                                item.file !== null
                                  ? CallImage(item.file)
                                  : NewsDefaultPotrait
                              }
                            />
                            {/* <img className="berita-content-item-grid-image" src={CallImage(item.file)} /> */}
                            <div className="berita-content-item-grid-title max-line-three">
                              {item.header}
                            </div>
                            <div className="berita-content-item-grid-desc max-line-four">
                              {parse(item.cuplikan)}
                            </div>
                          </div>
                        </Ripples>
                      );
                    }}
                  />
                </div>
                <div className="berita-paging-content-list">
                  <ReactPaginate
                    previousLabel={t("knfp-previous")}
                    nextLabel={t("knfp-next")}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick.bind(this)}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllBerita;
