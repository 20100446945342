import React, { useState, useEffect } from 'react'
import { Row, Col, Popover, PopoverBody } from 'reactstrap'
import Carousel from 'react-multi-carousel'
import * as Api from '../../../constants/api'
import 'react-multi-carousel/lib/styles.css'

const IconMenu = ({ img, name, link_address, idx }) => {
   const [showPin, setShowPin] = useState(false)

   return (
      <>
         <Col
            id={img + idx}
            xs={2}
            style={{ minWidth: '200px' }}
            onMouseEnter={() => {
               setShowPin(true)
            }}
            onMouseLeave={() => setShowPin(false)}
         >
            <a
               target="_blank"
               href={link_address || '#'}
               style={{ color: 'black', textDecoration: 'none' }}
            >
               <div
                  style={{
                     width: '100%',
                     height: '100%',
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'start',
                     justifyContent: 'center',
                  }}
               >
                  <img
                     style={{
                        maxWidth: '150px',
                        height: 'auto',
                        marginBottom: '10px',
                     }}
                     src={`${Api.APP_IMG}${img}.png`}
                  />
                  <span
                     style={{
                        fontSize: '1rem',
                        fontWeight: '500',
                     }}
                  >
                     {name}
                  </span>
               </div>
            </a>
         </Col>
         <Popover
            target={img + idx}
            isOpen={showPin}
            toggle={() => setShowPin(false)}
            placement="bottom"
            trigger="focus"
         >
            <PopoverBody>{name}</PopoverBody>
         </Popover>
      </>
   )
}

const BannerMenu = ({
   maxItem,
   idx = 1,
   bgColor,
   title,
   subTitle,
   desc,
   menu,
}) => {
   const [iconName, setIconName] = useState()
   console.log({
      idx,
   })
   return (
      <div
         style={{
            minWidth: '100vw',
            minHeight: 'auto',
            backgroundColor: bgColor,
            position: 'relative',
            zIndex: maxItem - idx,
            padding: '60px 10px 40px 60px',
         }}
      >
         <div
            style={{
               top: '-45px',
               minWidth: '50vw',
               minHeight: '40px',
               padding: 0,
               margin: '0 25vw',
               borderRadius: '100%',
               border: '0 solid',
               boxShadow: '0 10px 20px #263e66',
               position: 'absolute',
               zIndex: maxItem + idx,
            }}
         />
         <Row
            className="p-0 m-0"
            style={{
               alignItems: 'center',
               justifyContent: 'space-between',
               // maxWidth: '1920px',
               margin: '0 auto',
            }}
         >
            <Col xs={3}>
               <div style={{ maxWidth: '300px' }}>
                  <h1 style={{ fontWeight: 'bolder' }}>{title}</h1>
                  <h6 style={{ fontSize: '1rem', fontWeight: '500' }}>
                     {subTitle}
                  </h6>
                  <p style={{ fontSize: '1rem' }}>{desc}</p>
               </div>
            </Col>
            <Col xs={9}>
               <Row
                  style={{
                     alignItems: 'start',
                     justifyContent: 'start',
                  }}
               >
                  <Carousel
                     additionalTransfrom={0}
                     arrows
                     autoPlaySpeed={30000}
                     centerMode={false}
                     className="p-0 m-0 w-100 h-100"
                     // containerClass={{ width: '100%', height: '100%' }}
                     dotListClass=""
                     draggable
                     focusOnSelect={false}
                     infinite={false}
                     itemClass=""
                     keyBoardControl
                     minimumTouchDrag={80}
                     renderButtonGroupOutside={false}
                     renderDotsOutside={false}
                     responsive={{
                        desktop: {
                           breakpoint: {
                              max: 3000,
                              min: 1024,
                           },
                           items: 6,
                           partialVisibilityGutter: 30,
                        },
                        mobile: {
                           breakpoint: {
                              max: 464,
                              min: 0,
                           },
                           items: 1,
                           partialVisibilityGutter: 30,
                        },
                        tablet: {
                           breakpoint: {
                              max: 1024,
                              min: 464,
                           },
                           items: 3,
                           partialVisibilityGutter: 30,
                        },
                     }}
                     showDots={false}
                     sliderClass=""
                     slidesToSlide={3}
                     swipeable
                  >
                     {menu &&
                        menu.map((item, index) => (
                           <IconMenu
                              key={index}
                              idx={index}
                              img={item.group_detail_icon}
                              name={item.group_detail_name}
                              link_address={item.group_detail_link_address}
                           />
                        ))}
                  </Carousel>
               </Row>
            </Col>
         </Row>
      </div>
   )
}

export default BannerMenu
