/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from 'react';
import './video_document.scss';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import parse from 'html-react-parser';
import GridList from 'react-gridlist';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Ripples from 'react-ripples';
import ReactPlayer from "react-player"

//component
import * as Api from '../../../constants/api';
import { LoadProgress, ListEmpty } from '../../../components';
import { ICCalendarGrayNews, HomeVideoBG } from '../../../assets';
import { useHistory } from 'react-router-dom';

const VideoDocument = () => {

  // variable constant
  const { t } = useTranslation();
  const history = useHistory();
  const [is_progress, setisProgress] = useState(true);
  const [list_video, setListVideo] = useState([]);
  const [list_video_slice, setListVideoSlice] = useState([]);
  const [cover_video, setCoverVideo] = useState({});
  const [error_message, setErrorMessage] = useState('');

  const perPage = 8;
  const [pageCount, setPageCount] = useState('');

  //style
  let styles = {
    container: {
      flex: 1,
    },
  }

  // did mount
  useEffect(() => {
    setTimeout(() => {
      _get_data_video();
    }, 500);
  }, []);

  //function
  const _get_data_video = () => {
    setisProgress(true);
    const url = Api.GET_DATA_VIDEO + '?lang=' + localStorage.getItem('language').toUpperCase();;
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        setisProgress(false);
        if (response.data.code === '01') {
          if (response.data.data.length === 0) {
            setListVideo([]);
            setListVideoSlice([]);
            setCoverVideo({});
            setErrorMessage(t('empty_list'));
          } else {
            let list_foto_data = response.data.data;
            if (list_foto_data.length === 1) {
              setCoverVideo(list_foto_data[0]);
              setListVideo(list_foto_data);
              const slice = list_foto_data.slice(0, perPage);
              setPageCount(list_foto_data.length / perPage);
              setListVideoSlice(slice);
            } else {
              setCoverVideo(list_foto_data[0]);
              list_foto_data = list_foto_data.slice(1)
              setListVideo(list_foto_data);
              const slice = list_foto_data.slice(0, perPage);
              setPageCount(list_foto_data.length / perPage);
              setListVideoSlice(slice);
            }
          }
        } else {
          setListVideo([]);
          setListVideoSlice([]);
          setCoverVideo({});
          setErrorMessage(t('empty_list'));
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setisProgress(false);
        setListVideo([]);
        setListVideoSlice([]);
        setCoverVideo({});
        setErrorMessage(t('error_message'));
      });
  }

  const CallVideo = (image_url) => {
    // console.log('url_image');
    // console.log(image_url);
    var url_image = '';
    if (image_url !== null) {
      if (image_url.slice(0, 1) === '.') {
        if (image_url !== undefined && image_url !== null) {
          url_image = Api.IP_KONG_LNSW + image_url.slice(1);
        }
      } else {
        url_image = image_url;
      }
    }
    // console.log('url_video');
    // console.log(url_image);
    return url_image + '#t=0.5';
  }

  const parseContent = (text) => {
    var text_html = '';
    if (text !== undefined) {
      text_html = parse(text)
    }
    // console.log(text_html);
    return text_html;
  }

  const getGridGap = (elementWidth, windowHeight) => (elementWidth > 720 && windowHeight > 480) ? 10 : 5;

  const getColumnCount = (elementWidth) => Math.floor(elementWidth / 300); //pembagian column (dibagi untuk 4 column)

  const getWindowMargin = (windowHeight) => Math.round(windowHeight * 1.1);

  const getItemData = (list_peraturan, columnWidth) => {
    return {
      key: list_peraturan.id,
    }
  }
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * perPage;

    loadMoreData(offset, offset + perPage);
  };

  const loadMoreData = (of, op) => {
    const data = list_video;

    const slice = data.slice(of, op);
    setPageCount(data.length / perPage);
    setListVideoSlice(slice);
  };

  const _click_detail = (item) => {
    history.push('/video/detail-video', { id_video: item.id_video });
  }

  return (
    <div className="video-document-content row m-0">
      <div className="title-foto col-md-12 p-0 mb-5">{t('document_videos')}</div>
      <div className="col-md-12 p-0">
        {is_progress === true ?
          <div className="search-content-load col-md-12 p-0">
            <LoadProgress />
          </div>
          :
          list_video.length === 0 ?
            <div className="search-content-load col-md-12 p-0">
              <ListEmpty message_error={error_message} />
            </div>
            :
            <div className="cover-foto row m-0">
              <div className="cover-foto-desc col-md-6 col-sm-12 p-0">
                <div className="cover-foto-desc-title" onClick={() => _click_detail(cover_video)}>
                  {cover_video.header}
                </div>
                <div className="cover-foto-desc-date">
                  <img src={ICCalendarGrayNews} style={{ marginRight: '0.7vw' }} />
                  {/* {moment(cover_foto.waktu_publish).format('DD MMMM YYYY') + ' , ' + t('by') + ' ' + cover_artikel.penulis} */}
                  {moment(cover_video.waktu_publish).format('DD MMMM YYYY')}
                </div>
                {
                  cover_video.berita < 500 ?
                    (
                      <div className="cover-foto-desc-desc">
                        {parseContent(cover_video.berita.substring(0, 400))}
                      </div>
                    ) : (
                      <div className="cover-foto-desc-desc">
                        {parseContent(`${cover_video.berita.substring(0, 400)}...`)}
                      </div>
                    )
                }
                {/* <div className="cover-foto-desc-desc">
                  {parseContent(cover_video.berita)}
                  
                </div> */}
              </div>
              <div className="cover-foto-image col-md-6 col-sm-12 p-0">
                {cover_video.file === null ?
                  <ReactPlayer className="image-foto" url={CallVideo(cover_video.url_link)} controls={true} />
                  :
                  <video className="image-foto" controls>
                    <source src={CallVideo(cover_video.file)} type="video/mp4" />
                    <source src={CallVideo(cover_video.file)} type="video/webm" />
                    <source src={CallVideo(cover_video.file)} type="video/ogg" />
                      Your browser does not support the video tag.
                  </video>
                }
              </div>
            </div>
        }
      </div>
      <div className="foto-other col-md-12 p-0">
        <div className="foto-other-title col-md-12 p-0">
          {t('other_videos')}
        </div>
        <div className="col-md-12 mt-3 p-0">
          {is_progress === true ?
            <div className="search-content-load col-md-12 p-0">
              <LoadProgress />
            </div>
            :
            list_video_slice.length === 0 ?
              <div className="search-content-load col-md-12 p-0">
                <ListEmpty message_error={error_message} />
              </div>
              :
              <div className="row m-0">
                <div style={styles.container} className="col-md-12 p-0">
                  <GridList
                    items={list_video_slice}
                    getGridGap={getGridGap}
                    getColumnCount={getColumnCount}
                    getWindowMargin={getWindowMargin}
                    getItemData={getItemData}
                    renderItem={(item) => {
                      return (
                        <Ripples
                          onClick={() => _click_detail(item)}
                        >
                          <div className="foto-other-item-grid">
                            {item.file === null ?
                              <ReactPlayer className="foto-other-item-grid-image" url={CallVideo(item.url_link)} controls={true} />
                              :
                              <video className="foto-other-item-grid-image" controls>
                                <source src={CallVideo(item.file)} type="video/mp4" />
                                <source src={CallVideo(item.file)} type="video/webm" />
                                <source src={CallVideo(item.file)} type="video/ogg" />
                                        Your browser does not support the video tag.
                                    </video>
                            }
                            <div className="foto-other-item-grid-title max-line-three">
                              {item.header}
                            </div>
                            <div className="foto-other-item-date">
                              <img src={ICCalendarGrayNews} style={{ marginRight: '0.7vw' }} />
                              {moment(item.waktu_publish).format('DD MMMM YYYY')}
                            </div>
                            <div className="foto-other-item-grid-desc max-line-four">
                              {parseContent(item.berita)}
                            </div>
                          </div>
                        </Ripples>
                      )
                    }}
                  />

                  <div className="foto-other-content-list">
                    <ReactPaginate
                      previousLabel={t('previous')}
                      nextLabel={t('next')}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick.bind(this)}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                      className="col-md-12 p-0"
                    />
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}

export default VideoDocument;
