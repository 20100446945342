import React, { useState, useRef } from 'react';
import './referensi.scss';
import { useTranslation } from 'react-i18next';
import Select from 'react-dropdown-select';
import styled from '@emotion/styled';
import axios from 'axios';
import { Table, Spinner } from 'reactstrap';
import ReactPaginate from 'react-paginate';
//class function
import { ListEmpty, LoadProgress } from '../../../components';
import * as Api from '../../../constants/api';
import { InputSearch } from '../../../components/atoms';

const Referensi = () => {
	const { t } = useTranslation();
	const [reference, SetReference] = useState('');
	const [is_progress, setisProgress] = useState(false);
	const [is_first_load, setisFirstLoad] = useState(false);
	const [dataReference, SetDataReference] = useState([]);
	const [error_message, setErrorMessage] = useState('');

	//Paginasi
	const [offset, setOffset] = useState(0);
	const [sliceData, setSliceData] = useState([]); //tabledata
	const [perPage, setPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageCount, setPageCount] = useState('');
	const current_page_data = useRef(0);
	const total_page = useRef(0);
	const [intial_page, setInitialPage] = useState(0);

	//Select Referensi
	const [selected_ref, setSelectedRef] = useState({
		label: 'SATUAN',
		value: 'satuan',
	});
	const [selected_ref_label, setSelectedRefLabel] = useState('SATUAN');
	const [list_select_ref, setListSelectRef] = useState([
		{
			label: 'SATUAN',
			value: 'satuan',
		},
		{
			label: 'KEMASAN',
			value: 'kemasan',
		},
		{
			label: 'PELABUHAN',
			value: 'pelabuhan',
		},
	]);
	const [isProgressRef, setisProgressRef] = useState(false);
	const [place_holder_ref, setPlaceHolderRef] = useState(
		'referensi-dropdown-remove-placeholder'
	);

	//style dropdown
	const StyledItem = styled.div`
		color: #555;
		padding: 10px;
		border-radius: 3px;
		margin: 3px;
		cursor: pointer;
		> div {
			display: flex;
			align-items: center;
		}
		:hover {
			background: #f2f2f2;
		}
	`;

	const filter_input = (input_text) => {
		let filter_text = input_text.replace(/\'|\"|\--/g, '');
		return filter_text;
	};

	const searchReference = () => {
		// console.log('jenis');
		// console.log(selected_ref.value);
		// console.log('reference');
		// console.log(reference);

		setisProgress(true);
		setisFirstLoad(true);
		const jenisRef = selected_ref.value;
		console.log('jenisRef');
		console.log(jenisRef);
		let url = '';
		let page = 0;
		if (jenisRef === 'pelabuhan') {
			page = parseInt(current_page_data.current) + 1;
			url =
				Api.KEMASAN_SEARCH +
				jenisRef +
				'/searchLimit?keyword=' +
				reference +
				'&limit=10&page=' +
				page;
		} else {
			url =
				Api.KEMASAN_SEARCH +
				jenisRef +
				'/search?keyword=' +
				filter_input(reference);
		}
		console.log(url);

		axios({
			method: 'get',
			url: url,
			headers: { Authorization: Api.BASIC_AUTH },
			timeout: 1000 * 10, // Wait for 10 seconds
		})
			.then((response) => {
				setisProgress(false);
				if (jenisRef === 'pelabuhan') {
					if (response.data.code === '01') {
						console.log(response.data.data.page);
						console.log(response.data.data.result);
						// if (_total_page !== total_page.current) {
						// 	total_page.current = Math.ceil(response.data.data.count / 10);
						// 	console.log(total_page.current);
						// }
						total_page.current = Math.ceil(response.data.data.count / 10);
						console.log(total_page.current);
						let data_list = response.data.data.result;
						if (data_list.length === 0) {
							if (current_page_data.current === 1) {
								setSliceData([]);
								setErrorMessage(t('empty_list'));
							}
						} else {
							setSliceData(data_list);
						}
					} else {
						if (current_page_data.current === 1) {
							setSliceData([]);
							setErrorMessage(t('empty_list'));
						}
					}
				} else {
					if (response.data.code === '01') {
						if (response.data.data.length === 0) {
							SetDataReference([]);
							setErrorMessage(t('empty_list'));
						} else {
							console.log('response data');
							console.log(response.data.data);
							let data_list = response.data.data;
							SetDataReference(data_list);
							sliceDataDua(data_list);
						}
					} else {
						SetDataReference([]);
						setSliceData([]);
						setErrorMessage(t('empty_list'));
					}
				}
			})
			.catch((error) => {
				console.log('error');
				console.log(error);
				setisProgress(false);
				if (jenisRef === 'pelabuhan') {
					if (current_page_data.current === 1) {
						setSliceData([]);
						setErrorMessage(t('empty_list'));
					}
				} else {
					SetDataReference([]);
					setSliceData([]);
					setErrorMessage(t('empty_list'));
				}
			});
	};

	const sliceDataDua = (item) => {
		const slice = item.slice(offset, offset + perPage);

		setPageCount(item.length / perPage);
		setSliceData(slice);
	};

	const handlePageClick = (e) => {
		if (selected_ref.value === 'pelabuhan') {
			const selectedPage = e.selected;
			console.log(selectedPage);
			current_page_data.current = selectedPage;
			setInitialPage(selectedPage);
			setSliceData([]);
			searchReference();
		} else {
			const selectedPage = e.selected;
			const offset = selectedPage * perPage;

			// console.log(offset);
			// console.log(offset + perPage);
			// console.log(selectedPage);

			setCurrentPage(selectedPage);
			setOffset(offset);

			loadMoreData(offset, offset + perPage);
		}
	};

	const loadMoreData = (of, op) => {
		const data = dataReference;

		const slice = data.slice(of, op);
		setPageCount(data.length / perPage);
		setSliceData(slice);

		// console.log("slice");
		// console.log(slice);
	};

	// const handleKeyDown = (event) => {
	//     if (event.key === 'Enter') {
	//         // console.log('referensi', event.target.value);
	//         searchReference();
	//     }
	// }

	return (
		<div className='referensi-container'>
			<div className='referensi-background'>
				<div className='referensi-content-title'>
					<div className='referensi-content-sub-title'>
						<div className='referensi-text-title'>
							{t('title_cover_reference')}
						</div>
						<div className='referensi-text-message'>
							{t('desc_cover_reference')}
						</div>
						<div className='referensi-input-search row m-0 justify-content-center mt-5'>
							<div className='col-md-2 mb-3'>
								<Select
									multi={false}
									searchable={false}
									options={list_select_ref}
									values={[]}
									style={{
										borderColor: '#808080',
										background: '#FFFFFF',
										borderRadius: '30px',
										paddingLeft: 10,
										paddingRight: 10,
										marginRight: 10,
										height: '45px',
									}}
									className={place_holder_ref}
									onChange={(value) => {
										// console.log(value);
										setSelectedRef(value[0]);
										setSelectedRefLabel(value[0].label);
										setPlaceHolderRef('referensi-dropdown-remove-placeholder');
										// SetDataReference([]);
										setSliceData([]);
										setisFirstLoad(false);
										setCurrentPage(0);
									}}
									contentRenderer={({ props, state }) => (
										<div>
											{selected_ref_label === t('choose_cif_fob') ? (
												<div style={{ cursor: 'pointer', display: 'flex' }}>
													<div className='referensi-dropdown-placeholder'>
														{selected_ref_label}
													</div>
												</div>
											) : (
												<div style={{ cursor: 'pointer', display: 'flex' }}>
													<div className='referensi-input-dropdown'>
														{selected_ref_label}
													</div>
												</div>
											)}
										</div>
									)}
									itemRenderer={({ item, methods }) => (
										<StyledItem>
											<div onClick={() => methods.addItem(item)}>
												<div className='referensi-dropdown-item'>
													{item.label}
												</div>
											</div>
										</StyledItem>
									)}
								/>
							</div>
							<div className='col-md-5 mb-3'>
								<InputSearch
									placeholder={t('look_the_reference')}
									onChange={(e) => SetReference(e.target.value)}
									onClick={() => {
										current_page_data.current = 0;
										total_page.current = 0;
										setInitialPage(0);
										searchReference();
									}}
									onKeyDown={() => {
										current_page_data.current = 0;
										total_page.current = 0;
										setInitialPage(0);
										searchReference();
									}}
									// onKeyDown={handleKeyDown}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{is_first_load === false ? null : is_progress === true ? (
				<div style={{ height: '25vw' }} className='search-content-load'>
					<LoadProgress />
				</div>
			) : sliceData.length === 0 ? (
				<div className='search-content-load'>
					<ListEmpty message_error={error_message} />
				</div>
			) : selected_ref.value === 'kemasan' ? (
				<div className='referensi-table-container'>
					<p className='referensi-table-title'>Daftar Referensi Kemasan</p>
					<Table bordered className='referensi-table'>
						<thead className='referensi-table-header'>
							<tr>
								<th className='referensi-table-kemasan-no'>No</th>
								{/* <th className="referensi-table-kemasan-jr">Jenis Referensi</th> */}
								<th className='referensi-table-kemasan-kd'>Kode</th>
								<th className='referensi-table-kemasan-ur'>Uraian</th>
							</tr>
						</thead>
						<tbody className='referensi-table-body'>
							{sliceData.map((item, i) => (
								<tr>
									<th scope='row'>
										{currentPage === 0
											? i + 1
											: currentPage * perPage + (i + 1)}
									</th>
									{/* <td>Kemasan</td> */}
									<td>{item.KodeKemasan}</td>
									<td>{item.Uraian}</td>
								</tr>
							))}
						</tbody>
					</Table>
					<ReactPaginate
						previousLabel={'Sebelumnya'}
						nextLabel={'Selanjutnya'}
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={pageCount}
						marginPagesDisplayed={2}
						pageRangeDisplayed={5}
						onPageChange={handlePageClick.bind(this)}
						containerClassName={'pagination'}
						subContainerClassName={'pages pagination'}
						activeClassName={'active'}
					/>
				</div>
			) : selected_ref.value === 'satuan' ? (
				<div className='referensi-table-container'>
					<p className='referensi-table-title'>Daftar Referensi Satuan</p>
					<Table bordered className='referensi-table'>
						<thead className='referensi-table-header'>
							<tr>
								<th className='referensi-table-kemasan-no'>No</th>
								{/* <th className="referensi-table-kemasan-jr">Jenis Referensi</th> */}
								<th className='referensi-table-kemasan-kd'>Kode</th>
								<th className='referensi-table-kemasan-ur'>Uraian</th>
							</tr>
						</thead>
						<tbody className='referensi-table-body'>
							{sliceData.map((item, i) => (
								<tr>
									<th scope='row'>
										{currentPage === 0
											? i + 1
											: currentPage * perPage + (i + 1)}
									</th>
									{/* <td>Satuan</td> */}
									<td>{item.kodeSatuan}</td>
									<td>{item.urSatuan}</td>
								</tr>
							))}
						</tbody>
					</Table>
					<ReactPaginate
						previousLabel={'Sebelumnya'}
						nextLabel={'Selanjutnya'}
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={pageCount}
						marginPagesDisplayed={2}
						pageRangeDisplayed={5}
						onPageChange={handlePageClick.bind(this)}
						containerClassName={'pagination'}
						subContainerClassName={'pages pagination'}
						activeClassName={'active'}
					/>
				</div>
			) : selected_ref.value === 'pelabuhan' ? (
				<div className='referensi-table-container'>
					<p className='referensi-table-title'>Daftar Referensi Pelabuhan</p>
					<Table bordered className='referensi-table'>
						<thead className='referensi-table-header'>
							<tr>
								<th className='referensi-table-kemasan-no'>No</th>
								{/* <th className="referensi-table-kemasan-no">Jenis Referensi</th> */}
								<th className='referensi-table-kemasan-no'>Kode Pelabuhan</th>
								<th className='referensi-table-kemasan-no'>Pelabuhan</th>
								<th className='referensi-table-kemasan-no'>Kode Negara</th>
								<th className='referensi-table-kemasan-no'>Negara</th>
							</tr>
						</thead>
						<tbody className='referensi-table-body'>
							{sliceData.map((item, i) => (
								<tr>
									{/* <th scope='row'>
										{currentPage === 0
											? i + 1
											: currentPage * perPage + (i + 1)}
									</th> */}
									<th scope='row'>
										{current_page_data.current === 0
											? i + 1
											: current_page_data.current * perPage + (i + 1)}
									</th>
									{/* <td>Pelabuhan</td> */}
									<td>{item.KodePelabuhan}</td>
									<td>{item.Pelabuhan}</td>
									<td>{item.KodeNegara}</td>
									<td>{item.Negara}</td>
								</tr>
							))}
						</tbody>
					</Table>
					{is_progress === true ? (
						<div
							style={{
								width: '100%',
								flexDirection: 'row',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<Spinner color='#02275D' />
							<div className='load-progress-text'>
								<div>{t('please_wait')}</div>
							</div>
						</div>
					) : null}
					{is_progress === false ? (
						sliceData.length === 0 ? (
							<ListEmpty message_error={error_message} />
						) : null
					) : null}

					{/* <ReactPaginate
						previousLabel={'Sebelumnya'}
						nextLabel={'Selanjutnya'}
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={pageCount}
						marginPagesDisplayed={2}
						pageRangeDisplayed={5}
						onPageChange={handlePageClick.bind(this)}
						containerClassName={'pagination'}
						subContainerClassName={'pages pagination'}
						activeClassName={'active'}
					/> */}
					<ReactPaginate
						previousLabel={'Sebelumnya'}
						nextLabel={'Selanjutnya'}
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={total_page.current}
						forcePage={intial_page}
						marginPagesDisplayed={2}
						pageRangeDisplayed={5}
						onPageChange={handlePageClick.bind(this)}
						containerClassName={'pagination'}
						subContainerClassName={'pages pagination'}
						activeClassName={'active'}
					/>
				</div>
			) : (
				<p>error</p>
			)}
		</div>
	);
};

export default Referensi;
