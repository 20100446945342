/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './load_progress.scss';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const LoadProgress = () => {

    //variable constant
    const { t } = useTranslation();

    return (
        <div style={{position: 'absolute'}} className="load-progress">
            <div className="load-progress-spinner">
                <Spinner color="white" />
            </div>
            <div className="load-progress-text">
                <div>{t('please_wait')}</div>
            </div>
        </div>
    )
}

export default LoadProgress;
