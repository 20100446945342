/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Ripples from 'react-ripples';
import './cardatr.scss';

const CardAtr = ({ image, title, onClick }) => {
	return (
		<Ripples className='card-item-atr' onClick={onClick}>
			<div>
				<img src={image} className='image-card' />
				<div className='content-title'>
					<p className='title'>{title}</p>
				</div>
			</div>
		</Ripples>
	);
};

export default CardAtr;
