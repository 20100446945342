import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import CardTugas from '../../../components/molecules/CardTugas';
import ListProfil from '../../../components/molecules/ListProfil';
import * as Api from '../../../constants/api';
import './tufung.scss';
import parse from 'html-react-parser';


const Profil = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const { t } = useTranslation();

  //variable constant
  const [tufung_data, setTufungData] = useState([]);
  const [detailtufung_data, setDetailTufungData] = useState([]);


  const contentStyle = {
    maxWidth: "1000px",
    width: "100%",
    height: "100%"
  };

  const CallImage = (image_url) => {
    // var replace_url = image_url.substring(1);
    var url_image = Api.IP_KONG_LNSW + image_url.slice(1);
    // console.log(url_image);
    return url_image;
  }

  //did mount
  useEffect(() => {
    if (localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'id');
    }
    _get_data_tufung();
    _get_detail_tufung();
  }, []);

  const _get_data_tufung = () => {
    const url = Api.TUFUNG + '?lang=' + localStorage.getItem('language').toUpperCase();
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        // console.log("tufung", response.data.data);
        if (response.data.code === '01') {
          setTufungData(response.data.data);
        } else {
          setTufungData([]);
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setTufungData([]);
      });
  }

  const _get_detail_tufung = () => {
    const url = Api.DETAIL_TUFUNG + '?lang=' + localStorage.getItem('language').toUpperCase();
    // console.log(url);
    axios({
      method: 'get',
      url: url,
      headers: { 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then(response => {
        // console.log("detail_tufung", response.data.data);
        if (response.data.code === '01') {
          setDetailTufungData(response.data.data);
        } else {
          setDetailTufungData([]);
        }
      })
      .catch(error => {
        console.log('error');
        console.log(error);
        setDetailTufungData([]);
      });
  }
  
  //function
  const parseHtml = (html) => {
    // console.log('html');
    // console.log(html)
    var text_html = '';
    if (html !== undefined) {
    text_html = parse(html);
    }
    // var text_html = parse(html.toString());

    return text_html;
}

  return (
    <div className="tugas-fungsi-design row m-0">
      <div className="tugas-background col-md-12">
        {tufung_data.map((item, index) =>
          <div className="tugas-content-title">
            <div className="tugas-content-sub-title">
              <div className="tugas-text-title">
                {parseHtml(item.header_tugas)}
              </div>
              <div className="tugas-text-message">
                {parseHtml(item.ur_headertugas)}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="fungsi-background col-md-12">
        {tufung_data.map((item, index) =>
          <div className="fungsi-content-title">
            <div className="fungsi-content-sub-title">
              <div className="fungsi-text-title">
                {parseHtml(item.header_fungsi)}
              </div>
              <div className="fungsi-text-message">
                {parseHtml(item.ur_headerfungsi)}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="tufung-background col-md-12">
        {tufung_data.map((item, index) =>
          <div className="tufung-content-title">
            <div className="tufung-content-sub-title">
              <div className="tufung-text-title">
                {parseHtml(item.header_tugasfungsidivisi)}
              </div>
              <div className="tufung-text-message">
                {parseHtml(item.ur_tugasfungsidivisi)}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="tugas-sub-menu col-md-12">
        <div className="prof-sub-menu row m-0">
          {detailtufung_data.map((item, index) =>
            <Popup trigger=
              {
                <div className="p-4">
                  <CardTugas className="col-md-2" gambar={CallImage(item.nama_imgdivision)} judul={item.divisi} />
                </div>
              }
              modal
              closeOnDocumentClick
              onClick={toggle}
              contentStyle={contentStyle}>

              {(close) => (
                <div className="sub-tufung">
                  <a className="closex" onClick={close}>
                    &times;
                  </a>
                  <ListProfil gambar={CallImage(item.nama_imgdivision)} judul={item.divisi} header={parseHtml(item.ur_tugasdivisi)} deskripsi={t(item.ur_riwayat)} />
                </div>
              )}
            </Popup>

          )}
        </div>
        {/* <div className="prof-sub-menu">
          <CardTugas gambar={Tugas2} judul="Direktorat Efisiensi Proses Bisnis" />
        </div>
        <div className="prof-sub-menu">
          <CardTugas gambar={Tugas3} judul="Direktorat Teknologi Informasi" />
        </div>
        <div className="prof-sub-menu">
          <CardTugas gambar={Tugas4} judul="Direktorat Penjaminan Mutu" />
        </div> */}

      </div>
      <br></br>

    </div>

  );
}


export default Profil;
