/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "./beritaKnfp.scss";
import { useTranslation } from "react-i18next";
import "react-lazy-load-image-component/src/effects/blur.css";
import parse from "html-react-parser";
import * as Api from "../../../../constants/api";
import { useHistory, useLocation } from "react-router-dom";
import { Card, CardBody, ListGroup, ListGroupItem } from "reactstrap";
import { ListEmpty } from "../../../../components";
import axios from "axios";
import {
  getLanguageStorageUpperCaseKNFP,
  setLanguageStorage,
} from "../../../../constants/storage";
import { ICCalendarGrayNews } from "../../../../assets";
import Footer from "../../../KNFP/Footer";
import moment from "moment";

// import { dataBerita, dataBanyakBerita } from './dummy'
const NewsUpdate = () => {
  const [dataBerita, setDataBerita] = useState();
  const [dataTopBerita, setDataTopBerita] = useState();
  const [error_message, setErrorMessage] = useState("");
  // variable constant
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;

  const _getDataBerita = async () => {
    const url =
      Api.KNFP +
      `news/web?id=${
        state.id_berita
      }&lang=${getLanguageStorageUpperCaseKNFP()}`;
    await axios({
      method: "get",
      url: url,
      headers: { 'insw-key': Api.KNFP_AUTH_KEY, 'Authorization': Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then((response) => {
        if (response.data.result.code === 200) {
          setDataBerita(response.data.result.data);
        } else {
          setDataBerita([]);
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setDataBerita([]);
      });
  };
  const _getTopNews = async () => {
    const url =
      Api.KNFP +
      `news/web?kategori=top&lang=${getLanguageStorageUpperCaseKNFP()}`;
    await axios({
      method: "get",
      url: url,
      headers: { Authorization: Api.BASIC_AUTH },
      timeout: 1000 * 10, // Wait for 10 seconds
    })
      .then((response) => {
        if (response.data.result.code === 200) {
          setDataTopBerita(response.data.result.data);
        } else {
          setDataTopBerita([]);
        }
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
        setDataTopBerita([]);
      });
  };

  const parseContent = (text) => {
    var text_html = "";
    if (text !== undefined) {
      text_html = parse(text);
    }
    return text_html;
  };

  const parseContentLimited = (text, limitNumber) => {
    var text_html = "";
    if (text !== undefined) {
      text_html = parse(text);
    }
    return text_html.length > limitNumber
      ? text_html.slice(0, limitNumber) + "..."
      : text_html;
  };

  const jenisBeritaFormat = (kdJnsBerita) => {
    if (getLanguageStorageUpperCaseKNFP() === "ID") {
      switch (kdJnsBerita) {
        case 1:
          return "Berita Terkini";
        case 2:
          return "Artikel";
        case 3:
          return "Berita Media";
        case 4:
          return "Press Release";
        default:
          return "Berita Lainnya";
      }
    } else if (getLanguageStorageUpperCaseKNFP() === "EN") {
      switch (kdJnsBerita) {
        case 1:
          return "Latest News";
        case 2:
          return "Article";
        case 3:
          return "Media News";
        case 4:
          return "Press Release";
        default:
          return "Other News";
      }
    } else {
      setLanguageStorage("id");
    }
  };

  const CallImage = (image_url) => {
    var url_image = `https://api.insw.go.id/assets/upload/knfp/` + image_url;
    return url_image;
  };

  useEffect(() => {
    if (getLanguageStorageUpperCaseKNFP() === null) {
      setLanguageStorage("id");
    }
    if (state && state.id_berita) {
      // alert('Masuk')
      _getTopNews();
      _getDataBerita();
    } else {
      setErrorMessage(t("empty_list"));
    }
  }, []);

  useEffect(() => {
    if (getLanguageStorageUpperCaseKNFP() === null) {
      setLanguageStorage("id");
    }
    if (state && state.id_berita) {
      // alert('Masuk')
      _getTopNews();
      _getDataBerita();
    } else {
      setErrorMessage(t("empty_list"));
    }
  }, [state && state.id_berita]);

  const _click_view_more = (id_berita_click) => {
    history.push({
      pathname: "/knfp/berita/detail",
      state: { id_berita: id_berita_click },
    });
  };

  return (
    <div>
      <div>
        {dataBerita && dataBerita.length ? (
          dataBerita.map((berita, index) => (
            <div className="container-berita" key={index}>
              <div className="header-berita">
                <div className="header-berita-title">{berita.header}</div>
                <div className="header-berita-date">
                  <img
                    src={ICCalendarGrayNews}
                    style={{ marginRight: "0.7vw" }}
                  />
                  
                  {moment(berita.waktu_rekam).format(
                      "DD MMMM YYYY"
                    ) +
                      " , " +
                      t("by") +
                      " " +
                     berita.id_user}
                  {/* {berita.waktu_rekam} oleh {berita.id_user}
                  {berita.waktu_publish} oleh {berita.id_user} */}
                </div>
                <div className="header-berita-category">
                  {jenisBeritaFormat(berita.kd_katberita)}
                </div>
              </div>
              <div className="main-berita">
                <img
                  className="main-berita-image"
                  src={CallImage(berita.file)}
                />
                <div className="main-berita-content">
                  {parseContent(berita.berita)}
                </div>
              </div>
              <Card className="footer-berita">
                <CardBody>footers</CardBody>
              </Card>
              <Card className="sidebar-berita">
                <CardBody>
                  <div>
                    <h5 className="sidebar-berita-header">
                      {location.pathname === "/knfp/berita"
                        ? "Berita Lainnya"
                        : "Berita KNFP"}
                    </h5>
                    <ListGroup style={{ borderRadius: "0" }}>
                      {dataTopBerita &&
                        dataTopBerita.map((data, index) => (
                          <ListGroupItem
                            key={index}
                            onClick={() =>
                              _click_view_more(data ? data.id_berita : "")
                            }
                            className={`${
                              state && state.id_berita === data.id_berita
                                ? "current-news"
                                : ""
                            } justify-content-between sidebar-berita-content`}
                          >
                            {parseContentLimited(data.cuplikan, 20)}
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                    <a
                      style={{
                        cursor: "pointer",
                        fontWeight: 700,
                        textDecoration: "underline",
                        color: "#02275d",
                      }}
                      onClick={() => history.push("/knfp/berita")}
                    >
                      Berita lainnya
                    </a>
                  </div>
                  <div>
                    <h5
                      className="sidebar-berita-header"
                      style={{ paddingTop: "1.5rem" }}
                    >
                      {t("knfp_foot_name")}
                    </h5>
                    <div>{t("knfp_foot_email")}</div>
                  </div>
                </CardBody>
              </Card>
            </div>
          ))
        ) : (
          <div className="search-content-load">
            <ListEmpty message_error={error_message} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default NewsUpdate;
