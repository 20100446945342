/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './list_first.scss';
import { ICListData, ICListData2 } from '../../../assets';

const ListFirst = ({message_error, message_error2}) => {
    
    return (
        <div className="list-first">
            <div className="list-first-content">
                <img src={ICListData2} className="list-first-img" />
                <div className="list-first-content-text">{message_error}</div>
                {/* <div className="list-first-content-subtext">{message_error2}</div> */}
            </div>
        </div> 
    )
}

export default ListFirst;
